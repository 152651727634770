import { AddressTypeExtension } from "pbHelpers/AddressType";
import { quack } from "protobuf-ts/quack";

export const I2CWithChannels: AddressTypeExtension = {
  addressType: quack.AddressType.ADDRESS_TYPE_I2C_WITH_CHANNELS,
  friendlyName: "Channel",
  humanReadableAddress: function(componentType: quack.ComponentType, address: number): string {
    const names = new Map<number, string>([
      [(0x40 << 2) + 0, "A"],
      [(0x40 << 2) + 1, "B"],
      [(0x41 << 2) + 0, "C"],
      [(0x41 << 2) + 1, "D"],
      [(0x44 << 2) + 0, "E"],
      [(0x44 << 2) + 1, "F"],
      [(0x45 << 2) + 0, "G"],
      [(0x45 << 2) + 1, "H"]
    ]);
    let name = names.get(address);
    return name ? name : "Unknown";
  },
  singular: false
};

import { pond } from "protobuf-ts/pond";

export interface UpgradeChannelHelper {
  label: string;
  colour: string;
}

const Unknown: UpgradeChannelHelper = {
  label: "Unknown Channel",
  colour: "var(--status-ok)"
};

const Alpha: UpgradeChannelHelper = {
  label: "Alpha",
  colour: "var(--status-risk)"
};

const Beta: UpgradeChannelHelper = {
  label: "Beta",
  colour: "var(--status-unstable)"
};

const Stable: UpgradeChannelHelper = {
  label: "Stable",
  colour: "var(--status-ok)"
};

const Development: UpgradeChannelHelper = {
  label: "Development",
  colour: "var(--status-warning)"
};

export function getUpgradeChannelHelper(channel: pond.UpgradeChannel): UpgradeChannelHelper {
  switch (channel) {
    case pond.UpgradeChannel.UPGRADE_CHANNEL_ALPHA:
      return Alpha;
    case pond.UpgradeChannel.UPGRADE_CHANNEL_BETA:
      return Beta;
    case pond.UpgradeChannel.UPGRADE_CHANNEL_STABLE:
      return Stable;
    case pond.UpgradeChannel.UPGRADE_CHANNEL_DEVELOPMENT:
      return Development;
    default:
      return Unknown;
  }
}

export function getUpgradeChannelLabel(channel: pond.UpgradeChannel): string {
  return getUpgradeChannelHelper(channel).label;
}

export function getUpgradeChannelColour(channel: pond.UpgradeChannel): string {
  return getUpgradeChannelHelper(channel).colour;
}

import { Box, darken, IconButton, List, ListItem, Typography, withStyles } from "@material-ui/core";

import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import TaskCard from "./TaskCard";
import { Task } from "models";
import { getThemeType } from "theme";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import TasksIcon from "products/Construction/TasksIcon";
import moment from "moment";

interface Props {
  tasks: Task[];
  editTaskMethod: (task: Task) => void;
  markComplete: (task: Task) => void;
  deleteTask: (task: Task) => void;
  openTask: (taskId: string) => void;
  reLoad: () => void;
  listHeight?: string | number;
  label?: string;
  dateToView?: Date;
}

export default function TaskList(props: Props) {
  const {
    editTaskMethod,
    markComplete,
    deleteTask,
    openTask,
    reLoad,
    listHeight,
    label,
    dateToView
  } = props;
  const [tasks, setTasks] = useState(props.tasks);
  const [incomplete, setIncomplete] = useState<Task[]>([]);
  const [complete, setComplete] = useState<Task[]>([]);
  const [viewing, setViewing] = useState<"upcoming" | "complete">("upcoming");

  useEffect(() => {
    setTasks(props.tasks);
  }, [setTasks, props.tasks]);
  let location = useLocation().pathname;

  useEffect(() => {
    let incomplete: Task[] = [];
    let complete: Task[] = [];
    tasks.forEach(task => {
      let within = true;
      if (dateToView) {
        within = false;
        let current = moment(dateToView);
        let start = moment(task.start());
        let end = moment(task.end());
        if (current.isBetween(start, end) || current.isSame(start) || current.isSame(end)) {
          within = true;
        }
      }
      if (within) {
        if (!task.complete()) {
          incomplete.push(task);
        } else {
          complete.push(task);
        }
      }
    });
    //TODO sort tasks complete=asc, incomplete=desc
    setIncomplete(incomplete);
    setComplete(complete);
  }, [tasks, dateToView]);

  const StyledToggleButtonGroup = withStyles(theme => ({
    grouped: {
      margin: theme.spacing(-0.5),
      border: "none",
      padding: theme.spacing(1),
      "&:not(:first-child):not(:last-child)": {
        borderRadius: 24,
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5)
      },
      "&:first-child": {
        borderRadius: 24,
        marginLeft: theme.spacing(0.25)
      },
      "&:last-child": {
        borderRadius: 24,
        marginRight: theme.spacing(0.25)
      }
    },
    root: {
      backgroundColor: darken(
        theme.palette.background.paper,
        getThemeType() === "light" ? 0.05 : 0.25
      ),
      borderRadius: 24,
      content: "border-box"
    }
  }))(ToggleButtonGroup);

  const StyledToggle = withStyles({
    root: {
      backgroundColor: "transparent",
      overflow: "visible",
      content: "content-box",
      "&$selected": {
        backgroundColor: "gold",
        color: "black",
        borderRadius: 24,
        fontWeight: "bold"
      },
      "&$selected:hover": {
        backgroundColor: "rgb(255, 255, 0)",
        color: "black",
        borderRadius: 24
      }
    },
    selected: {}
  })(ToggleButton);

  const incompleteTasks = incomplete.map((task, index) => (
    <ListItem divider key={index} disableGutters>
      <TaskCard
        task={task}
        editTaskMethod={editTaskMethod}
        markComplete={(task: Task) => markComplete(task)}
        deleteTask={(task: Task) => deleteTask(task)}
        reLoad={reLoad}
        openTaskPage={(taskId: string) => openTask(taskId)}
      />
    </ListItem>
  ));

  const completeTasks = complete.map((task, index) => (
    <ListItem divider key={index} disableGutters>
      <TaskCard
        task={task}
        editTaskMethod={editTaskMethod}
        markComplete={(task: Task) => markComplete(task)}
        deleteTask={(task: Task) => deleteTask(task)}
        reLoad={reLoad}
        openTaskPage={(taskId: string) => openTask(taskId)}
      />
    </ListItem>
  ));

  return (
    <Box padding="5px" textAlign="center">
      <Typography variant="h5">{label ? label : ""}</Typography>
      <Box textAlign="center">
        <StyledToggleButtonGroup value={viewing} exclusive size="small" aria-label="Bin Mode">
          <StyledToggle
            value={"upcoming"}
            aria-label="upcoming"
            onClick={() => setViewing("upcoming")}>
            Upcoming
          </StyledToggle>
          <StyledToggle
            onClick={() => setViewing("complete")}
            value={"complete"}
            aria-label="complete">
            Complete
          </StyledToggle>
        </StyledToggleButtonGroup>
        {location !== "/tasks" && (
          <IconButton id="tasks" component={Link} to="/tasks">
            <TasksIcon />
          </IconButton>
        )}
      </Box>
      <List style={{ height: listHeight, overflow: "auto" }}>
        {viewing === "upcoming" ? incompleteTasks : completeTasks}
      </List>
    </Box>
  );
}

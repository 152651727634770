import React, { useEffect } from "react";
import { Layer, Source } from "react-map-gl";
import { FeatureCollection } from "geojson";

interface Props {
  featureCollection: FeatureCollection;
  showTitle?: boolean;
  setInteractiveLayers: (layers: string[]) => void;
}

export default function GeoMapLayer(props: Props) {
  const { featureCollection, showTitle, setInteractiveLayers } = props;

  useEffect(() => {
    setInteractiveLayers(["shapefill", "shapeborder"]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Source type="geojson" data={featureCollection} id={"objects"}>
        <Layer
          id="shapefill"
          type="fill"
          paint={{
            "fill-color": ["get", "fill"], //using react-map-gl's data driven expressions you can get data from the properties of the feature
            "fill-opacity": 0.7
          }}
        />
        <Layer
          id="shapeborder"
          type="line"
          layout={{
            "line-join": "round",
            "line-cap": "round"
          }}
          paint={{
            "line-color": "white",
            "line-width": ["get", "lineWidth"]
          }}
        />
        {showTitle && (
          <Layer
            id="shapetitle"
            type="symbol"
            layout={{
              "text-field": ["get", "title"],
              "text-size": 18,
              "text-font": ["Open Sans Bold"],
              "text-anchor": "center"
            }}
            paint={{
              "text-color": "white"
            }}
          />
        )}
      </Source>
    </React.Fragment>
  );
}

import {
  ComponentTypeExtension,
  Summary,
  simpleMeasurements,
  simpleSummaries,
  unitMeasurementSummaries,
  AreaChartData,
  GraphFilters,
  simpleAreaChartData,
  LineChartData,
  simpleLineChartData
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import { convertedUnitMeasurement } from "models/UnitMeasurement";
import { pond } from "protobuf-ts/pond";

export function Voltage(subtype: number = 0): ComponentTypeExtension {
  let voltage = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_VOLTAGE,
    quack.ComponentType.COMPONENT_TYPE_VOLTAGE,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_VOLTAGE,
    subtypes: [],
    friendlyName: "Voltage",
    description: "Measures voltage",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_I2C_WITH_CHANNELS],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(voltage),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, voltage);
    },
    unitMeasurementSummary: (
      measurements: convertedUnitMeasurement,
      excludedNodes?: number[]
    ): Summary[] => {
      return unitMeasurementSummaries(
        measurements,
        quack.ComponentType.COMPONENT_TYPE_VOLTAGE,
        subtype,
        excludedNodes
      );
    },
    areaChartData: (
      measurement: pond.UnitMeasurementsForComponent,
      smoothingAverages?: number,
      filters?: GraphFilters
    ): AreaChartData => {
      return simpleAreaChartData(measurement, smoothingAverages, filters);
    },
    lineChartData: (
      measurement: pond.UnitMeasurementsForComponent,
      smoothingAverages?: number,
      filters?: GraphFilters
    ): LineChartData => {
      return simpleLineChartData(
        quack.ComponentType.COMPONENT_TYPE_VOLTAGE,
        measurement,
        smoothingAverages,
        filters
      );
    },
    minMeasurementPeriodMs: 1000
  };
}

import NexusLightIcon from "assets/products/Construction/nexusIconLight.png";
import NexusDarkIcon from "assets/products/Construction/nexusIconDark.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  size?: number;
  type?: "light" | "dark";
}

export default function NexusSTIcon(props: Props) {
  const themeType = useThemeType();
  const { type, size } = props;

  const src = () => {
    if (type) {
      return type === "light" ? NexusLightIcon : NexusDarkIcon;
    }

    return themeType === "light" ? NexusDarkIcon : NexusLightIcon;
  };

  if (size) {
    return <img width={size} height={size} alt="bins" src={src()} />;
  }

  return <ImgIcon alt="nexus" src={src()} />;
}

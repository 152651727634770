import { useState, useEffect } from "react";

interface Viewport {
  width: number;
  height: number;
}

function getViewport() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

export default function useViewport() {
  const [viewport, setViewport] = useState<Viewport>(getViewport());

  useEffect(() => {
    function handleResize() {
      setViewport(getViewport());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return viewport;
}

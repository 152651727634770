import {
  Box,
  Card,
  CardHeader,
  createStyles,
  Grid,
  LinearProgress,
  makeStyles,
  Typography
} from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
import SingleSetAreaChart, { SSAreaDataPoint } from "charts/SingleSetAreaChart";
import { Gate } from "models/Gate";
import moment, { Moment } from "moment";
import { useGateAPI } from "providers";
import React, { useEffect, useState } from "react";

interface Props {
  gate: Gate;
  device: string | number;
  start: Moment;
  end: Moment;
  ambient?: string;
  newXDomain?: number[] | string[];
  pressureComponent?: string;
  setPCAState: (state: boolean) => void;
  multiGraphZoom?: (domain: number[] | string[]) => void;
}

const useStyles = makeStyles(() => {
  return createStyles({
    calcCard: {
      height: 100,
      display: "flex",
      alignItems: "center"
    },
    calcGrid: {
      marginBottom: 2
    }
  });
});

export default function GateFlowGraph(props: Props) {
  const {
    gate,
    device,
    ambient,
    pressureComponent,
    setPCAState,
    start,
    end,
    newXDomain,
    multiGraphZoom
  } = props;
  const gateAPI = useGateAPI();
  const [flowData, setFlowData] = useState<SSAreaDataPoint[]>([]);
  const [loadingChartData, setLoadingChartData] = useState(false);
  const [recent, setRecent] = useState<SSAreaDataPoint | undefined>();
  const [runtime, setRuntime] = useState<moment.Duration>();
  const classes = useStyles();

  useEffect(() => {
    if (loadingChartData) return;

    if (ambient && pressureComponent) {
      let recent: SSAreaDataPoint | undefined;
      setLoadingChartData(true);
      gateAPI
        .listGateAirflow(
          gate.key,
          device,
          ambient,
          pressureComponent,
          start.toISOString(),
          end.toISOString()
        )
        .then(resp => {
          let data: SSAreaDataPoint[] = [];
          const idleFlow = 2;
          if (resp.data.values) {
            let start: Moment | undefined;
            let stop: Moment | undefined;
            let runtime = 0;
            resp.data.values.forEach((val, i) => {
              let time = moment(val.time);
              let newPoint: SSAreaDataPoint = {
                timestamp: time.valueOf(),
                value: val.airFlow ?? 0
              };

              data.push(newPoint);
              if (!recent || recent.timestamp < newPoint.timestamp) {
                recent = newPoint;
              }

              // set the start time if the values is greater than 1 and start is not already set
              if (val.airFlow >= idleFlow && !start) {
                start = time;
              }
              // set the stop time when off or at the last measurements and the start time is set
              if ((val.airFlow < idleFlow || i === resp.data.values.length - idleFlow) && start) {
                stop = time;
              }
              // if both start and stop are set calculate add the timeframe to the total runtime
              if (start && stop) {
                runtime = runtime + stop.diff(start);
                start = undefined;
                stop = undefined;
              }
            });
            setRuntime(moment.duration(runtime));
            let state = false;
            if (
              data[data.length - 1].value > gate.lowerFlow() &&
              data[data.length - 1].value < gate.upperFlow()
            ) {
              state = true;
            }
            setPCAState(state);
          }
          setFlowData(data);
          setLoadingChartData(false);
          setRecent(recent);
        });
    }
  }, [gateAPI, gate, ambient, pressureComponent, start, end, device, setPCAState]); // eslint-disable-line react-hooks/exhaustive-deps

  const flowChart = () => {
    return (
      <Card raised style={{ padding: 10, marginBottom: 15 }}>
        <CardHeader
          title={<Typography style={{ fontSize: 25, fontWeight: 650 }}>Mass Air Flow</Typography>}
          subheader={
            recent ? (
              <Grid container>
                <Grid item xs={12}>
                  <span>
                    {"Mass Flow Rate: "}
                    <span style={{ color: teal[500], fontWeight: 500 }}>
                      {recent.value.toFixed(2)} kg/s
                    </span>
                    <br />
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="textSecondary" variant={"caption"}>
                    {moment(recent.timestamp).fromNow()}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography variant="body1" color="textPrimary">
                No Data
              </Typography>
            )
          }
        />
        {flowData.length !== 0 ? (
          <SingleSetAreaChart
            data={flowData}
            maxRef={gate.upperFlow()}
            minRef={gate.lowerFlow()}
            newXDomain={newXDomain}
            multiGraphZoom={multiGraphZoom}
          />
        ) : (
          <Box display="flex" flexDirection="column" flexGrow="2">
            <div style={{ display: "flex", flexGrow: 2 }}></div>
            <Typography variant="subtitle1" color="textSecondary" align="center">
              A component may be missing or have no measurements, this data needs the ambient and
              pressure components to be set and measuring
            </Typography>
            <div style={{ display: "flex", flexGrow: 2 }}></div>
          </Box>
        )}
      </Card>
    );
  };

  return (
    <React.Fragment>
      {runtime && (
        <Grid container direction="row" spacing={2} className={classes.calcGrid}>
          <Grid item xs={3}>
            <Card raised className={classes.calcCard}>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>Approximate Runtime:</Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 650, fontSize: 15 }}>
                    {runtime.asHours().toFixed(2)} hr
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card raised className={classes.calcCard}>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>Cost to run PCA:</Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 650, fontSize: 15 }}>
                    $
                    {parseFloat(
                      (runtime.asHours() * gate.settings.hourlyPcaCost).toFixed(2)
                    ).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card raised className={classes.calcCard}>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>Cost to run APU:</Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 650, fontSize: 15 }}>
                    $
                    {parseFloat(
                      (runtime.asHours() * gate.settings.hourlyApuCost).toFixed(2)
                    ).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card raised className={classes.calcCard}>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid>Total Cost:</Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 650, fontSize: 15 }}>
                    $
                    {parseFloat(
                      (
                        runtime.asHours() * gate.settings.hourlyApuCost +
                        runtime.asHours() * gate.settings.hourlyPcaCost
                      ).toFixed(2)
                    ).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item>Estimated Savings:</Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 650, fontSize: 15 }}>
                    $
                    {parseFloat(
                      (runtime.asHours() * gate.settings.hourlyApuCost).toFixed(2)
                    ).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
      {loadingChartData ? <LinearProgress /> : flowChart()}
    </React.Fragment>
  );
}

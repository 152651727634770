import MarketWhite from "assets/marketplaceImages/marketplaceIconLight.png";
import MarketBlack from "assets/marketplaceImages/marketplaceIconDark.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function MarketplaceIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? MarketWhite : MarketBlack;
    }

    return themeType === "light" ? MarketBlack : MarketWhite;
  };

  return <ImgIcon alt="marketplace" src={src()} />;
}

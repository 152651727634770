import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
  Typography,
  Tooltip
} from "@material-ui/core";
import DeleteButton from "common/DeleteButton";
import { cloneDeep } from "lodash";
import { pond } from "protobuf-ts/pond";
import { useMineAPI, useSnackbar } from "providers";
import React, { useState } from "react";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      minHeight: "25vh"
    },
    removeDialog: {
      zIndex: theme.zIndex.modal + 1
    }
  })
);

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mine: pond.MineSettings;
  setMine: React.Dispatch<React.SetStateAction<pond.MineSettings>>;
}

export default function EditorSettings(props: Props) {
  const { open, setOpen, mine, setMine } = props;
  const [nameInput, setNameInput] = useState<string>("");
  const [removeDialog, setRemoveDialog] = useState<boolean>(false);

  const classes = useStyles();
  const mineAPI = useMineAPI();
  const snackbar = useSnackbar();
  const history = useHistory();

  const updateName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNameInput(event.currentTarget.value);
  };

  const apply = () => {
    let m = cloneDeep(mine);
    m.name = nameInput;
    setMine(m);
    setOpen(false);
  };

  const remove = () => {
    mineAPI
      .removeMine(mine.key)
      .then(resp => {
        if (resp.status === 200) snackbar.success("Mine successfully removed");
        setRemoveDialog(false);
        setOpen(false);
        setMine(pond.MineSettings.create());
        history.push("/mines");
      })
      .catch(err => {
        snackbar.error("Could not delete the mine");
      });
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Mine Settings</DialogTitle>
        <DialogContent>
          <TextField label="Name" defaultValue={mine.name} onChange={e => updateName(e)} />
        </DialogContent>
        <DialogActions>
          <Tooltip
            title={
              mine.key === undefined || mine.key === ""
                ? "Can only delete a saved mine"
                : "Delete this mine"
            }>
            <span>
              <DeleteButton
                style={{ marginRight: "auto" }}
                onClick={() => setRemoveDialog(true)}
                disabled={mine.key === undefined || mine.key === ""}>
                Delete
              </DeleteButton>
            </span>
          </Tooltip>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="primary" onClick={apply}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={removeDialog}
        onClose={() => setRemoveDialog(false)}
        aria-labelledby="confirm-remove-device-label"
        aria-describedby="confirm-remove-device-description"
        className={classes.removeDialog}>
        <DialogTitle id="confirm-remove-device-title">Delete {mine.name}?</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" color="error" align="left">
            WARNING:
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" align="left">
            Clicking 'Accept' will remove the device from all users and groups associated with it.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemoveDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={remove} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import {
  Box,
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from "@material-ui/core";
import { blue, grey, red, yellow, orange } from "@material-ui/core/colors";
import BarGraph, { BarData, RefArea } from "charts/BarGraph";
import { Bin } from "models";
import moment, { Moment } from "moment";
import { pond, quack } from "protobuf-ts/pond";
import { useBinAPI } from "providers";
import React, { useEffect, useState } from "react";
import { Legend } from "recharts";
import { getGrainUnit } from "utils";

interface Props {
  binLoading: boolean;
  bin: Bin;
  colour: string;
  fertilizerBin: boolean;
  startDate: Moment;
  endDate: Moment;
  customHeight?: number | string;
}

export default function BinLevelOverTime(props: Props) {
  const { bin, fertilizerBin, colour, startDate, endDate, customHeight } = props;
  const binAPI = useBinAPI();
  const [showCable, setShowCable] = useState(false);
  const [manualData, setManualData] = useState<BarData[]>([]);
  const [cableData, setCableData] = useState<BarData[]>([]);
  const [modeAreas, setModeAreas] = useState<RefArea[]>([]);
  // const defaultDateRange = GetDefaultDateRange();
  // const [startDate, setStartDate] = useState<Moment>(defaultDateRange.start);
  // const [endDate, setEndDate] = useState<Moment>(defaultDateRange.end);
  const [dataLoading, setDataLoading] = useState(false);
  const [cableDataLoading, setCableDataLoading] = useState(false);
  const [capacity, setCapacity] = useState<number | undefined>();

  // const updateDateRange = (newStartDate: any, newEndDate: any) => {
  //   let range = GetDefaultDateRange();
  //   range.start = newStartDate;
  //   range.end = newEndDate;
  //   setStartDate(newStartDate);
  //   setEndDate(newEndDate);
  // };

  const getFill = (mode: pond.BinMode) => {
    let fill = "";
    switch (mode) {
      case pond.BinMode.BIN_MODE_STORAGE:
        fill = yellow[500];
        break;
      case pond.BinMode.BIN_MODE_COOLDOWN:
        fill = orange[500];
        break;
      case pond.BinMode.BIN_MODE_DRYING:
        fill = red[500];
        break;
      case pond.BinMode.BIN_MODE_HYDRATING:
        fill = blue[500];
        break;
      default:
        fill = grey[500];
        break;
    }
    return fill;
  };

  useEffect(() => {
    if (dataLoading || bin.key() === "") return;
    setDataLoading(true);
    let cap = bin.settings.specs?.bushelCapacity;
    if (fertilizerBin && cap) {
      cap = cap * 35.239;
    }
    if (getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT && cap) {
      cap = cap / bin.bushelsPerTonne();
    }
    setCapacity(cap);
    binAPI
      .listHistoryBetween(bin.key(), 500, startDate.toISOString(), endDate.toISOString())
      .then(resp => {
        let data: BarData[] = [];
        let modeAreas: RefArea[] = [];
        let lastBushels = -1;

        //values for ref areas
        let x1 = 0;
        let x2 = 0;
        let y1 = -50;
        let y2 = -200;

        let currentMode: pond.BinMode;

        resp.data.history.forEach(hist => {
          if (hist.settings?.inventory) {
            let settings = pond.BinSettings.fromObject(hist.settings);
            let bushels = hist.settings.inventory.grainBushels ?? 0;
            if (bushels !== lastBushels || currentMode !== settings.mode) {
              x1 = moment(hist.timestamp).valueOf();
              x2 = moment(hist.timestamp).valueOf();
              modeAreas.push({
                x1: x1,
                x2: x2,
                y1: cap ? cap * -0.1 : y1,
                y2: cap ? cap * -0.2 : y2,
                fill: getFill(settings.mode)
              });
              currentMode = settings.mode;
              let newData: BarData = {
                timestamp: moment(hist.timestamp).valueOf(),
                value: fertilizerBin
                  ? Math.round(bushels * 35.239)
                  : getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT
                  ? Math.round((bushels / bin.bushelsPerTonne()) * 100) / 100
                  : bushels
              };
              data.push(newData);
              lastBushels = bushels;
            }
          }
        });
        if (data.length === 0) {
          let bushels = bin.settings.inventory?.grainBushels ?? 0;
          let currentTime = moment().valueOf();
          data.push({
            value: fertilizerBin
              ? Math.round(bushels * 35.239)
              : getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT
              ? Math.round((bushels / bin.bushelsPerTonne()) * 100) / 100
              : bushels,
            timestamp: currentTime
          });

          modeAreas.push({
            x1: currentTime,
            x2: currentTime,
            y1: cap ? cap * -0.1 : y1,
            y2: cap ? cap * -0.2 : y2,
            fill: getFill(bin.settings.mode)
          });
        }
        setManualData(data);
        setModeAreas(modeAreas);
      })
      .finally(() => {
        setDataLoading(false);
      });
  }, [binAPI, bin, startDate, endDate, fertilizerBin]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cableDataLoading || bin.key() === "") return;
    setCableDataLoading(true);
    binAPI
      .listBinMeasurements(bin.key(), startDate, endDate, 500, 0, "asc")
      .then(resp => {
        resp.data.measurements.forEach(objMeasurement => {
          //set the bushel cable measurements
          let m = pond.UnitMeasurementsForObject.fromObject(objMeasurement);
          if (m.measurementType === quack.MeasurementType.MEASUREMENT_TYPE_BUSHEL_CABLE) {
            let cableBarData: BarData[] = [];
            let lastBushels = -1;

            //val will be an array of only a single number since this is not a measurement that involves nodes
            m.values.forEach((val, i) => {
              if (val.values[0] && m.timestamps[i]) {
                if (lastBushels !== val.values[0]) {
                  cableBarData.push({
                    value: val.values[0],
                    timestamp: moment(m.timestamps[i]).valueOf()
                  });
                  lastBushels = val.values[0];
                }
              }
            });
            setCableData(cableBarData);
          }
        });
      })
      .catch(err => {})
      .finally(() => {
        setCableDataLoading(false);
      });
  }, [binAPI, bin, startDate, endDate, fertilizerBin]); // eslint-disable-line react-hooks/exhaustive-deps

  const legend = () => {
    return (
      <Legend
        verticalAlign="bottom"
        payload={[
          { value: "Drying", type: "square", id: "drying", color: red[500] },
          { value: "Cooldown", type: "square", id: "cooldown", color: orange[500] },
          { value: "Storage", type: "square", id: "storage", color: yellow[500] },
          { value: "Hydrating", type: "square", id: "hydrating", color: blue[500] }
        ]}
      />
    );
  };

  const manualChart = () => {
    return (
      <BarGraph
        customHeight={customHeight}
        data={manualData}
        refAreas={modeAreas}
        barColour={colour}
        yMax={capacity}
        graphLegend={legend()}
        labelColour="white"
        labels
        useGradient
      />
    );
  };

  const cableChart = () => {
    if (cableData.length === 0) {
      return (
        <Box height={customHeight} paddingTop={"25%"}>
          <Typography style={{ textAlign: "center", fontSize: 25, fontWeight: 650 }}>
            No cable estimates for selected time frame
          </Typography>
        </Box>
      );
    }
    return (
      <BarGraph
        customHeight={customHeight}
        data={cableData}
        //refAreas={modeAreas}
        barColour={colour}
        yMax={capacity}
        yMin={0}
        graphLegend={legend()}
        labelColour="white"
        labels
        useGradient
      />
    );
  };

  return (
    <Card raised style={{ padding: 5 }}>
      <Grid
        container
        direction="row"
        alignContent="center"
        alignItems="center"
        justify="space-between">
        <Grid item>
          <Typography style={{ fontSize: 25, fontWeight: 650, marginLeft: 20 }}>
            Grain Levels Over Time
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={showCable}
                onChange={(_, checked) => {
                  setShowCable(checked);
                }}
                color="primary"
              />
            }
            label="Cable Estimates"
          />
        </Grid>
      </Grid>

      {dataLoading ? <CircularProgress /> : showCable ? cableChart() : manualChart()}
    </Card>
  );
}

import { AddressTypeExtension } from "pbHelpers/AddressType";
import { quack } from "protobuf-ts/quack";

export const Power: AddressTypeExtension = {
  addressType: quack.AddressType.ADDRESS_TYPE_POWER,
  friendlyName: "Power",
  humanReadableAddress: function(componentType: quack.ComponentType, address: number): string {
    return "";
  },
  singular: true
};

import AviationMapController from "Maps/mapObjectControllers/AviationMapController";
import React from "react";
import { useLocation } from "react-router";
import PageContainer from "./PageContainer";

export default function AviationMap() {
  const location = useLocation();

  return (
    <PageContainer>
      {location.state !== undefined &&
      location.state.long !== undefined &&
      location.state.lat !== undefined ? (
        <AviationMapController
          startingView={{
            longitude: location.state.long,
            latitude: location.state.lat,
            zoom: 18
          }}
        />
      ) : (
        <AviationMapController />
      )}
    </PageContainer>
  );
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import { Scope, User } from "models";
import { pond } from "protobuf-ts/pond";
import { useAuth, usePermissionAPI, useSnackbar } from "providers";
import React from "react";
import { useHistory } from "react-router";

interface Props {
  scope: Scope;
  label: string;
  isDialogOpen: boolean;
  closeDialogCallback: (removed: boolean) => void;
  path?: string;
  numberOfOwners?: number; //including the number of owners will prevent the user from leaving if they are the final owner
}

export default function RemoveSelfFromObject(props: Props) {
  const history = useHistory();
  const permissionAPI = usePermissionAPI();
  const { userID } = useAuth();
  const { error, success, warning } = useSnackbar();
  const { scope, label, isDialogOpen, closeDialogCallback, path, numberOfOwners } = props;

  const close = (removed: boolean) => {
    closeDialogCallback(removed);
  };

  const submit = () => {
    permissionAPI
      .updatePermissions(scope, [
        User.create(
          pond.User.create({
            settings: pond.UserSettings.create({
              id: userID
            }),
            permissions: []
          })
        )
      ])
      .then((response: any) => {
        success("Successfully left " + label);
        close(true);
        history.push("/" + (path !== undefined ? path : scope.kind) + "s");
      })
      .catch((err: any) => {
        err.response.data.error
          ? warning(err.response.data.error)
          : error("Error occured when trying to remove yourself from " + label);
        close(false);
      });
  };

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ UI BEGINS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  const removeSelfDialog = () => {
    return (
      <React.Fragment>
        <DialogTitle id="remove-self-from-object">{"Leave " + label + "?"}</DialogTitle>
        <DialogContent>
          <Typography color="textSecondary" align="left" variant="subtitle1">
            You will no longer be able to access {label}.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={submit} color="primary" autoFocus>
            Leave
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  };

  const onlyOwnerDialog = () => {
    return (
      <React.Fragment>
        <DialogTitle>{"Cannot Leave"}</DialogTitle>
        <DialogContent>
          <Typography color="textSecondary" align="left" variant="subtitle1">
            You are currently the only owner. To leave, share it to another user with full
            permissions to make them an owner first and then leave.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => close(false)}
      aria-labelledby="remove-self-from-object">
      {numberOfOwners && numberOfOwners <= 1 ? onlyOwnerDialog() : removeSelfDialog()}
    </Dialog>
  );
}

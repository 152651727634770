import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useState } from "react";
import ResponsiveDialog from "./ResponsiveDialog";
import { getTableIcons } from "./ResponsiveTable";
import { Document, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useMobile } from "hooks";
import { cloneDeep } from "lodash";

export interface QrCodeKey {
  name: string;
  key: string;
}

interface Props {
  open: boolean;
  close: () => void;
  keyList: QrCodeKey[];
  requiredUrlAffix: string;
  url?: string;
}

export default function(props: Props) {
  const { open, close, url, requiredUrlAffix, keyList } = props;
  const [selectedKeys, setSelectedKeys] = useState<QrCodeKey[]>([]);
  const isMobile = useMobile();
  const codesPerPage = 4;
  const pdfStyle = StyleSheet.create({
    viewerDesktop: {
      height: 500 * 1.44,
      width: 500
    },
    viewerMobile: {
      height: "100%",
      width: "100%"
    },
    page: {
      padding: "3%",
      textAlign: "center",
      fontFamily: "Helvetica-Bold",
      fontSize: 30
    },
    quadrant: {
      width: "50%",
      textAlign: "center",
      //margin: 10,
      borderRadius: 20
    },
    code: {
      flexDirection: "column",
      border: "2px dotted black",
      margin: 10,
      borderRadius: 20
    },
    image: {
      padding: 20,
      paddingBottom: 0
      //height: "65%"
      //border: "1px solid black", //used just to see a representation of where the view is
    },
    text: {
      marginTop: 30,
      marginBottom: 30,
      textAlign: "center",
      justifyContent: "center"
      //border: "1px solid black", //used just to see a representation of where the view is
    }
  });

  const selectionTable = () => {
    return (
      <React.Fragment>
        <MaterialTable
          icons={getTableIcons()}
          title={""}
          data={keyList}
          options={{
            paging: true,
            // pageSize: pageSize,
            // pageSizeOptions: [5, 10, 20],
            padding: "dense",
            showTitle: true,
            toolbar: true,
            sorting: true,
            columnsButton: true,
            search: false,
            selection: true
          }}
          onSelectionChange={(data, rowData) => {
            setSelectedKeys(data);
          }}
          columns={[
            {
              cellStyle: {
                padding: 0
              },
              title: "Name",
              field: "name"
            }
          ]}></MaterialTable>
      </React.Fragment>
    );
  };

  const generatedCodes = () => {
    let numPages = Math.ceil(selectedKeys.length / codesPerPage);
    let keys = cloneDeep(selectedKeys);
    let pages: JSX.Element[] = [];

    let location = url ?? document.URL; //document.URL will append the ending slash to the url string
    if (!location.includes(requiredUrlAffix)) {
      location = location + requiredUrlAffix;
    }

    for (let i = 0; i < numPages; i++) {
      let pageKeys = keys.splice(0, codesPerPage);
      pages.push(
        <React.Fragment key={i}>
          <View style={{ height: "50%", flexDirection: "row" }}>
            {pageKeys[0] && (
              <View style={pdfStyle.quadrant}>
                <View style={pdfStyle.code}>
                  <View style={pdfStyle.image}>
                    <Image
                      src={
                        "https://api.qrserver.com/v1/create-qr-code/?data=" +
                        location +
                        "/" +
                        pageKeys[0].key +
                        "&amp;size=100x100"
                      }
                    />
                  </View>
                  <View style={pdfStyle.text}>
                    <Text>{pageKeys[0].name}</Text>
                  </View>
                </View>
              </View>
            )}
            {pageKeys[1] && (
              <View style={pdfStyle.quadrant}>
                <View style={pdfStyle.code}>
                  <View style={pdfStyle.image}>
                    <Image
                      src={
                        "https://api.qrserver.com/v1/create-qr-code/?data=" +
                        location +
                        "/" +
                        pageKeys[1].key +
                        "&amp;size=100x100"
                      }
                    />
                  </View>
                  <View style={pdfStyle.text}>
                    <Text>{pageKeys[1].name}</Text>
                  </View>
                </View>
              </View>
            )}
          </View>
          <View style={{ height: "50%", flexDirection: "row" }}>
            {pageKeys[2] && (
              <View style={pdfStyle.quadrant}>
                <View style={pdfStyle.code}>
                  <View style={pdfStyle.image}>
                    <Image
                      src={
                        "https://api.qrserver.com/v1/create-qr-code/?data=" +
                        location +
                        "/" +
                        pageKeys[2].key +
                        "&amp;size=100x100"
                      }
                    />
                  </View>
                  <View style={pdfStyle.text}>
                    <Text>{pageKeys[2].name}</Text>
                  </View>
                </View>
              </View>
            )}
            {pageKeys[3] && (
              <View style={pdfStyle.quadrant}>
                <View style={pdfStyle.code}>
                  <View style={pdfStyle.image}>
                    <Image
                      src={
                        "https://api.qrserver.com/v1/create-qr-code/?data=" +
                        location +
                        "/" +
                        pageKeys[3].key +
                        "&amp;size=100x100"
                      }
                    />
                  </View>
                  <View style={pdfStyle.text}>
                    <Text>{pageKeys[3].name}</Text>
                  </View>
                </View>
              </View>
            )}
          </View>
        </React.Fragment>
      );
    }

    return (
      <Document>
        <Page style={pdfStyle.page}>{pages}</Page>
      </Document>
    );
  };

  return (
    <ResponsiveDialog open={open} onClose={close}>
      <DialogTitle>Generate QR Codes</DialogTitle>
      <DialogContent>
        {selectionTable()}
        <Box marginTop={5}>
          <PDFViewer style={isMobile ? pdfStyle.viewerMobile : pdfStyle.viewerDesktop}>
            {generatedCodes()}
          </PDFViewer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={close}>
          Close
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

import React, { useRef, FC } from "react";
interface Props {
  draw: any;
  height: number | string;
  width: number | string;
  onMouseDown?: any;
  onMouseUp?: any;
  onMouseMove?: any;
  onKeyDown?: any;
  onKeyUp?: any;
  onWheel?: any;
  onContextMenu?: any;
}

const Canvas: FC<Props> = ({
  draw,
  height,
  width,
  onMouseDown,
  onMouseUp,
  onMouseMove,
  onKeyDown,
  onKeyUp,
  onWheel,
  onContextMenu
}) => {
  const canvas = useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    const context = canvas.current?.getContext("2d");
    draw(context);
  });

  return (
    <canvas
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onWheel={onWheel}
      tabIndex={-1}
      ref={canvas}
      width={width}
      height={height}
      onContextMenu={onContextMenu}
    />
  );
};

export default Canvas;

import { convertedUnitMeasurement } from "models/UnitMeasurement";
import {
  AreaChartData,
  ComponentTypeExtension,
  GraphFilters,
  LineChartData,
  simpleAreaChartData,
  simpleLineChartData,
  Summary,
  unitMeasurementSummaries
} from "pbHelpers/ComponentType";
import { pond } from "protobuf-ts/pond";
import { quack } from "protobuf-ts/quack";

export function Invalid(subtype: number = 0): ComponentTypeExtension {
  return {
    type: quack.ComponentType.COMPONENT_TYPE_INVALID,
    subtypes: [],
    friendlyName: "Invalid",
    description: "",
    isController: false,
    isSource: false,
    isCalibratable: false,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_INVALID],
    interactionResultTypes: [quack.InteractionResultType.INTERACTION_RESULT_TYPE_INVALID],
    states: [],
    measurements: [],
    measurementSummary: async function(): Promise<Array<Summary>> {
      return Promise.reject();
    },
    unitMeasurementSummary: (
      measurements: convertedUnitMeasurement,
      excludedNodes?: number[]
    ): Summary[] => {
      return unitMeasurementSummaries(
        measurements,
        quack.ComponentType.COMPONENT_TYPE_INVALID,
        subtype,
        excludedNodes
      );
    },
    areaChartData: (
      measurement: pond.UnitMeasurementsForComponent,
      smoothingAverages?: number,
      filters?: GraphFilters
    ): AreaChartData => {
      return simpleAreaChartData(measurement, smoothingAverages, filters);
    },
    lineChartData: (
      measurement: pond.UnitMeasurementsForComponent,
      smoothingAverages?: number,
      filters?: GraphFilters
    ): LineChartData => {
      return simpleLineChartData(
        quack.ComponentType.COMPONENT_TYPE_INVALID,
        measurement,
        smoothingAverages,
        filters
      );
    },
    minMeasurementPeriodMs: 0
  };
}

import { InputAdornment, MenuItem, TextField } from "@material-ui/core";
import { usePrevious } from "hooks";
import React, { useEffect, useState } from "react";
import { TimeUnit, milliToX, bestUnit, xToMilli } from "./duration";

interface Props {
  onChange: (ms: number) => void;
  units: TimeUnit[];
  initialMs?: number;
  id?: string;
  label?: string;
  isDisabled?: boolean;
  isError?: boolean;
  helperText?: string;
}

export default function PeriodSelect(props: Props) {
  const { onChange, id, label, isDisabled, isError, initialMs, helperText, units } = props;
  let initialUnit: TimeUnit = bestUnit(initialMs);
  let initialValue = milliToX(initialMs, initialUnit).toString();
  const [value, setValue] = useState<string>(initialValue);
  const [unit, setUnit] = useState<TimeUnit>(initialUnit);
  const prevUnit = usePrevious(unit);

  useEffect(() => {
    if (prevUnit !== unit) {
      onChange(xToMilli(value, unit));
    }
  }, [unit, prevUnit, onChange, value]);

  const changeValue = (event: any) => {
    let value = event.target.value;
    setValue(value);
    onChange(isNaN(value) ? 0 : xToMilli(value, unit));
  };

  const selectText = (event: any) => {
    event.target.select();
  };

  return (
    <TextField
      id={id}
      label={label}
      disabled={isDisabled === true}
      error={isError === true}
      value={value}
      onChange={changeValue}
      onFocus={selectText}
      margin="normal"
      fullWidth
      helperText={helperText}
      type="text"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <TextField
              id="unitSelect"
              disabled={isDisabled === true}
              error={isError === true}
              select
              value={unit}
              onChange={event => setUnit(event.target.value as TimeUnit)}
              margin="none"
              variant="standard">
              {units.includes("ms") && <MenuItem value="milliseconds">ms</MenuItem>}
              {units.includes("seconds") && <MenuItem value="seconds">sec</MenuItem>}
              {units.includes("minutes") && <MenuItem value="minutes">min</MenuItem>}
              {units.includes("hours") && <MenuItem value="hours">hrs</MenuItem>}
              {units.includes("days") && <MenuItem value="days">days</MenuItem>}
            </TextField>
          </InputAdornment>
        )
      }}
    />
  );
}

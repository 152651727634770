import React from "react";
import AgMapController from "Maps/mapObjectControllers/AgMapController";
import PageContainer from "./PageContainer";
import { useLocation } from "react-router";

export default function Fields() {
  const location = useLocation();

  return (
    <PageContainer>
      {location.state !== undefined &&
      location.state.long !== undefined &&
      location.state.lat !== undefined ? (
        <AgMapController
          startingView={{
            longitude: location.state.long,
            latitude: location.state.lat,
            zoom: 13,
            transitionDuration: 0
          }}
        />
      ) : (
        <AgMapController />
      )}
    </PageContainer>
  );
}

import React, { createContext, PropsWithChildren, useContext } from "react";
import { useHTTP } from "hooks";

interface IGitlabContext {
  page: (project: string, slug: string) => Promise<any>;
}

export const GitlabContext = createContext<IGitlabContext>({} as IGitlabContext);

interface Props {}

export default function GitlabProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get } = useHTTP();

  const url = (partial: string) => {
    return process.env.REACT_APP_GITLAB_URL + partial;
  };

  const page = (project: string, slug: string) => {
    return get(url("/projects/" + project + "/wikis/" + slug));
  };

  return (
    <GitlabContext.Provider
      value={{
        page
      }}>
      {children}
    </GitlabContext.Provider>
  );
}

export const useGitlab = () => useContext(GitlabContext);

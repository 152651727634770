import {
  Breadcrumbs,
  Chip,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  useTheme
} from "@material-ui/core";
import { Replay } from "@material-ui/icons";
import Link, { LinkProps } from "@material-ui/core/Link";
import { Skeleton } from "@material-ui/lab";
import { useMobile } from "hooks";
import { MatchParams } from "navigation/Routes";
import React, { ReactNode } from "react";
import { useLocation, useRouteMatch } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { or } from "utils/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      maxWidth: "150px",
      [theme.breakpoints.up("md")]: {
        maxWidth: "200px"
      }
    },
    textOverflow: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize"
    }
  })
);

interface Props {
  deviceName?: string;
  componentName?: string;
  groupName?: string;
  binName?: string;
  reportTarget?: string;
  loading?: boolean;
  reload?: () => void;
}

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

export default function SmartBreadcrumb(props: Props) {
  const theme = useTheme();
  const location = useLocation();
  const match = useRouteMatch<MatchParams>();
  const isMobile = useMobile();
  const classes = useStyles();
  const { loading, reload } = props;

  const groupID = (): string => {
    return or(match.params.groupID, "0").toString();
  };

  const deviceID = (): string => {
    return or(match.params.deviceID, "0").toString();
  };

  const mineKey = (): string => {
    let result = match.url.match(/\/mines\/([^/]+)/);
    let mineKey = "0";
    if (result?.length && result?.length > 1) mineKey = result[1];
    return or(match.params.mineKey, mineKey).toString();
  };

  const jobsiteKey = (): string => {
    let result = match.url.match(/\/jobsites\/([^/]+)/);
    let jobsiteKey = "0";
    if (result?.length && result?.length > 1) jobsiteKey = result[1];
    return or(match.params.jobsiteKey, jobsiteKey).toString();
  };

  const componentID = (): string => {
    return or(match.params.componentID, "0").toString();
  };

  const reportID = (): string => {
    return or(match.params.reportID, "0").toString();
  };

  const binID = (): string => {
    return or(match.params.binID, "").toString();
  };

  const reportIDShort = (): string => {
    return reportID().split("-")[0];
  };

  // Paths

  const dashboardPath = (): string => {
    return "/";
  };

  const groupsPath = (): string => {
    return "/groups";
  };

  const groupPath = (): string => {
    return groupsPath() + "/" + groupID();
  };

  const devicesPath = (): string => {
    return "/devices";
  };

  const devicePath = (): string => {
    return devicesPath() + "/" + deviceID();
  };

  const minesPath = (): string => {
    return "/mines";
  };

  const minePath = (): string => {
    return minesPath() + "/" + mineKey();
  };

  const jobsitesPath = (): string => {
    return "/jobsites";
  };

  const jobsitePath = (): string => {
    return jobsitesPath() + "/" + jobsiteKey();
  };

  const componentsPath = (): string => {
    return "/components";
  };

  const componentPath = (): string => {
    return componentsPath() + "/" + componentID();
  };

  const securityPath = (): string => {
    return "/security";
  };

  const reportPath = (): string => {
    return securityPath() + "/" + reportID();
  };

  const groupDevicesPath = (): string => {
    return groupPath() + devicesPath();
  };

  const groupDevicePath = (): string => {
    return groupPath() + devicePath();
  };

  const groupComponentsPath = (): string => {
    return groupPath() + componentsPath();
  };

  const groupComponentPath = (): string => {
    return groupPath() + componentPath();
  };

  const historyPath = (): string => {
    return "/history";
  };

  const deviceHistoryPath = (): string => {
    return devicePath() + historyPath();
  };

  const groupDeviceHistoryPath = (): string => {
    return groupDevicePath() + historyPath();
  };

  const firmwarePath = (): string => {
    return "/firmware";
  };

  const usersPath = (): string => {
    return "/users";
  };

  const binsPath = (): string => {
    return "/bins";
  };

  const binPath = (): string => {
    return binsPath() + "/" + binID();
  };

  const getBreadcrumbMap = (): { [key: string]: string } => {
    const { groupName, deviceName, componentName, reportTarget, binName } = props;
    const deviceLabel: string = deviceName ? deviceName : "Device " + deviceID();
    const componentLabel: string = componentName ? componentName : "Component " + componentID();
    const binLabel: string = binName ?? "Bin " + binID();
    const mineLabel: string = "Mine Site";
    const jobsiteLabel: string = "Job Site";

    return {
      [dashboardPath()]: "Dashboard",
      [groupsPath()]: "Groups",
      [groupPath()]: groupName ? groupName : "Group " + groupID(),
      [devicesPath()]: "Devices",
      [devicePath()]: deviceLabel,
      [minesPath()]: "Mines",
      [minePath()]: mineLabel,
      [jobsitesPath()]: "Job Sites",
      [jobsitePath()]: jobsiteLabel,
      [componentsPath()]: "Components",
      [componentPath()]: componentLabel,
      [securityPath()]: "Security",
      [reportPath()]: "Report " + reportIDShort() + (reportTarget ? " (" + reportTarget + ")" : ""),
      [groupDevicePath()]: deviceLabel,
      [groupComponentPath()]: componentLabel,
      [deviceHistoryPath()]: "History",
      [groupDeviceHistoryPath()]: "History",
      [firmwarePath()]: "Firmware",
      [usersPath()]: "Users",
      [binsPath()]: "Bins",
      [binPath()]: binLabel
    };
  };

  const breadcrumbBlacklist = (): string[] => {
    return [dashboardPath(), componentsPath(), groupDevicesPath(), groupComponentsPath()];
  };

  const breadcrumbLinks = (): ReactNode[] => {
    const breadcrumbMap = getBreadcrumbMap();

    let links: ReactNode[] = [];
    const pathnames = location.pathname.split("/").filter((x: any) => x);
    const blacklist = breadcrumbBlacklist();

    if (pathnames.length < 1) {
      return [linkComponent("/", "Dashboard", true)];
    }

    pathnames.forEach((value: any, index: any) => {
      const lastPath = index === pathnames.length - 1;
      const to = `/${pathnames.slice(0, index + 1).join("/")}`;

      let label = breadcrumbMap[to];
      let result = to.split("/").filter(str => str !== "");
      if (!label) {
        if (result.length > 1) {
          if (result[result.length - 1] !== "components")
            label = breadcrumbMap["/" + result[result.length - 1]];
        }
        if (!label && result[result.length - 1] !== "components") {
          label = breadcrumbMap["/" + result[result.length - 2] + "/" + result[result.length - 1]];
        }
      }

      if (!blacklist.includes(to) && label) {
        links.push(linkComponent(to, label, lastPath));
      }
    });

    return links;
  };

  const linkComponent = (to: string, label: string, lastPath: boolean) => {
    return (
      <Chip
        key={to}
        variant={lastPath ? "default" : "outlined"}
        label={
          <LinkRouter
            color={lastPath ? "textPrimary" : "textSecondary"}
            variant="subtitle1"
            to={to}
            className={classes.textOverflow}>
            {label}
          </LinkRouter>
        }
        className={classes.chip}
      />
    );
  };

  const localReload = () => {
    // console.log("reload");
    if (reload) {
      reload();
    } else {
      // console.log("reload undefined?");
    }
  };

  if (loading === true) return <Skeleton variant="text" width="100%" />;

  return (
    <React.Fragment>
      <Breadcrumbs
        maxItems={isMobile ? 1 : 3}
        itemsBeforeCollapse={0}
        aria-label="Location Breadcrumb">
        {breadcrumbLinks()}
      </Breadcrumbs>
      {reload && (
        <IconButton
          color="inherit"
          size="small"
          style={{ marginLeft: theme.spacing(1) }}
          onClick={localReload}>
          <Replay />
        </IconButton>
      )}
    </React.Fragment>
  );
}

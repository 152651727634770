import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  InputAdornment,
  TextField
} from "@material-ui/core";
import ResponsiveDialog from "common/ResponsiveDialog";
import { GetDefaultDateRange } from "common/time/DateRange";
import DateSelect from "common/time/DateSelect";
import { useDeviceAPI } from "hooks";
import { Component, Device } from "models";
import { Moment } from "moment";
import React, { useState } from "react";
import { downloadJSON } from "utils";

interface Props {
  open: boolean;
  close: (refresh: boolean) => void;
  device: Device;
  components: Component[];
}

export default function ArcGISDeviceData(props: Props) {
  const { open, close, device, components } = props;
  const [startDate, setStartDate] = useState<Moment>(GetDefaultDateRange().start);
  const [endDate, setEndDate] = useState<Moment>(GetDefaultDateRange().end);
  const [includedComponents, setIncludedComponents] = useState<Component[]>([]);
  const [filename, setFilename] = useState<string>(GetDefaultDateRange().start.toString());
  const [limit, setLimit] = useState<number>(0);
  const deviceAPI = useDeviceAPI();
  const [useFlat, setUseFlat] = useState(false);
  const handleClose = () => {
    setUseFlat(false);
    close(false);
  };

  const updateDateRange = (start: Moment, end: Moment) => {
    setStartDate(start);
    setEndDate(end);
  };

  const includeComponent = (checked: boolean, comp: Component) => {
    let inComps = includedComponents;
    if (checked) {
      inComps.push(comp);
    } else if (!checked && inComps.includes(comp)) {
      inComps.splice(inComps.indexOf(comp), 1);
    }
    setIncludedComponents(inComps);
  };

  const isFormValid = () => {
    let valid = false;
    if (filename.length > 0) {
      valid = true;
    }
    return valid;
  };

  const componentSelector = () => {
    return components.map(comp => (
      <FormControlLabel
        label={comp.name()}
        key={comp.key()}
        control={
          <Checkbox onChange={e => includeComponent(e.target.checked, comp)} color="primary" />
        }
      />
    ));
  };

  const getMeasurements = () => {
    if (useFlat) {
      deviceAPI.listSimpleJSON(device.id()).then(resp => {
        downloadJSON(resp.data, "Device" + device.id() + ".json");
      });
    } else {
      deviceAPI
        .listJSONMeasurements(
          device.id(),
          includedComponents,
          startDate,
          endDate,
          limit,
          0,
          "asc",
          "timestamp"
        )
        .then(resp => {
          downloadJSON(resp.data, filename + ".json");
        });
    }
  };

  return (
    <ResponsiveDialog open={open} onClose={handleClose}>
      <DialogTitle>Export JSON Data for Device</DialogTitle>
      <DialogContent>
        <FormControlLabel
          label={"Simple Export"}
          control={
            <Checkbox
              value={useFlat}
              onChange={e => setUseFlat(e.target.checked)}
              color="primary"
            />
          }
        />
        <Divider />
        {!useFlat && (
          <React.Fragment>
            <TextField
              id="filename"
              fullWidth
              variant="outlined"
              value={filename}
              label="Filename"
              onChange={e => setFilename(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">.json</InputAdornment>
              }}
            />
            <DateSelect
              startDate={startDate}
              endDate={endDate}
              updateDateRange={updateDateRange}
              label="Extract data from"
            />
            <TextField
              id="limit"
              fullWidth
              variant="outlined"
              label="Maximum number of measurements per component"
              type="number"
              value={limit}
              onChange={e => setLimit(+e.target.value)}
            />
            {componentSelector()}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "red" }}>
          Cancel
        </Button>
        <Button onClick={getMeasurements} color="primary" disabled={!isFormValid()}>
          Get Measurements
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

import {
  defaultOptions,
  get as apiGet,
  put as apiPut,
  post as apiPost,
  del as apiDel
} from "services/restAPI";

var cache = sessionStorage;

function key(url, data = {}) {
  return JSON.stringify({ url: url, data: data });
}

export function get(url, options = defaultOptions) {
  let k = key(url);
  let cached = cache.getItem(k);
  if (cached !== null) {
    return Promise.resolve(JSON.parse(cached));
  }
  return apiGet(url, options).then(result => {
    cache.setItem(k, JSON.stringify(result));
    return result;
  });
}

export function put(url, data, options = defaultOptions) {
  let k = key(url, data);
  let cached = cache.getItem(k);
  if (cached !== null) {
    return Promise.resolve(JSON.parse(cached));
  }
  return apiPut(url, data, options).then(result => {
    cache.setItem(k, JSON.stringify(result));
    return result;
  });
}

export function post(url, data, options = defaultOptions) {
  let k = key(url, data);
  let cached = cache.getItem(k);
  if (cached !== null) {
    return Promise.resolve(JSON.parse(cached));
  }
  return apiPost(url, data, options).then(result => {
    cache.setItem(k, JSON.stringify(result));
    return result;
  });
}

export function del(url, options = defaultOptions) {
  let k = key(url);
  let cached = cache.getItem(k);
  if (cached !== null) {
    return Promise.resolve(JSON.parse(cached));
  }
  return apiDel(url, options).then(result => {
    cache.setItem(k, JSON.stringify(result));
    return result;
  });
}

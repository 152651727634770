import { Box, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import ObjectDefinitionDisplay, { data } from "./objectDefinitionDisplay";

// mining definitions
const mineData: data[] = [
  {
    name: "Mine",
    fields: [
      {
        fieldName: "settings",
        fieldType: "MineSettings",
        fieldDescription: "The settings of the mine"
      },
      {
        fieldName: "status",
        fieldType: "MineStatus",
        fieldDescription: "The status of the mine (contains no properties at this time)"
      }
    ]
  },
  {
    name: "MineSettings",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the mine"
      },
      {
        fieldName: "placeables",
        fieldType: "Placeable (ARRAY)",
        fieldDescription: "Placeable components of the mine"
      },
      {
        fieldName: "devices",
        fieldType: "int (ARRAY)",
        fieldDescription: "The ids of devices that are part of the mine"
      },
      {
        fieldName: "sensors",
        fieldType: "MinSensor (ARRAY)",
        fieldDescription: "Sensors within the mine"
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the mine"
      }
    ]
  },
  // there are no properties in the MineStatus definition of the protobuf
  // {
  //   name: "MineStatus",
  //   fields: [
  //     {
  //       fieldName: "",
  //       fieldType: "",
  //       fieldDescription: ""
  //     },
  //   ]
  // },
  {
    name: "MineSimple",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: ""
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: ""
      }
    ]
  },
  {
    name: "Placeable",
    fields: [
      {
        fieldName: "x",
        fieldType: "float",
        fieldDescription: "The x value on the canvas"
      },
      {
        fieldName: "y",
        fieldType: "float",
        fieldDescription: "The y value on the canvas"
      },
      {
        fieldName: "angle",
        fieldType: "float",
        fieldDescription:
          "The angle to rotate the image, or for corner vents the angle of the curve"
      },
      {
        fieldName: "width",
        fieldType: "float",
        fieldDescription: "The width of the image"
      },
      {
        fieldName: "radius",
        fieldType: "float",
        fieldDescription: "The radius of the image"
      },
      {
        fieldName: "magnitude",
        fieldType: "float",
        fieldDescription: "General measurement for different placeables. Length of vent for example"
      },
      {
        fieldName: "direction",
        fieldType: "int",
        fieldDescription: "The direction the placeable is facing"
      },
      {
        fieldName: "type",
        fieldType: "PlaceableType",
        fieldDescription: "The type of placeable"
      },
      {
        fieldName: "subtype",
        fieldType: "int",
        fieldDescription: "The subtype of the placeable"
      }
    ]
  },
  {
    name: "MineComponentPreferences",
    fields: [
      {
        fieldName: "sensors",
        fieldType: "MineSensorPreferences (ARRAY)",
        fieldDescription: "Assigned preferences for the sensors in relation to the mine"
      }
    ]
  },
  {
    name: "MineSensorPreferences",
    fields: [
      {
        fieldName: "enabled",
        fieldType: "bool",
        fieldDescription: "If the sensor is currently enabled"
      },
      {
        fieldName: "x",
        fieldType: "float",
        fieldDescription: "The x position on the canvas"
      },
      {
        fieldName: "y",
        fieldType: "float",
        fieldDescription: "The y position on the canvas"
      },
      {
        fieldName: "nickname",
        fieldType: "string",
        fieldDescription: "Nickname given to the sensor"
      }
    ]
  },
  {
    name: "MineSensor",
    fields: [
      {
        fieldName: "x",
        fieldType: "float",
        fieldDescription: "The x position on the canvas"
      },
      {
        fieldName: "y",
        fieldType: "float",
        fieldDescription: "The y position on the canvas"
      },
      {
        fieldName: "componentKey",
        fieldType: "string",
        fieldDescription: "The key of the component"
      },
      {
        fieldName: "index",
        fieldType: "int",
        fieldDescription: "The index of the component"
      }
    ]
  }
];

export default function MiningDefinitions() {
  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Mine Definitions
      </Typography>
      <List>
        {mineData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useDeviceAPI, useSnackbar } from "hooks";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      margin: theme.spacing(2)
    }
  })
);

interface Props {
  id: number;
  isOpen: boolean;
  close: (refresh: boolean) => void;
}

export function PauseData(props: Props) {
  const { id, isOpen, close } = props;
  const classes = useStyles();
  const { success, error } = useSnackbar();
  const deviceAPI = useDeviceAPI();

  const pause = () => {
    deviceAPI
      .pause(id)
      .then(() => success("Data will be paused shortly"))
      .catch(() => error("Something went wrong"))
      .then(() => close(true));
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={() => close(false)}
      aria-labelledby="pause-data-dialog">
      <DialogTitle id="pause-data-dialog-title">Pause Data</DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-slide-description">
          Pausing data will disconnect your device from the cellular network. No new status,
          measurements, or configuration will be sent between the device and the cloud until data is
          resumed. Your device will continue to operate offline but support will be limited.
          <br />
          <br />
          You will continue to be charged at a reduced rate to reserve your SIM card. Resuming data
          after it has been paused will result in a reactivation charge.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(false)} color="primary">
          Close
        </Button>
        <Button onClick={pause} color="primary">
          Pause
        </Button>
      </DialogActions>
    </Dialog>
  );
}

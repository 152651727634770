import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  createStyles,
  darken,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { getThemeType } from "theme";

export interface EnumPairs {
  key: string;
  val: number;
}

export interface data {
  name: string;
  pairs: EnumPairs[];
}

interface Props {
  data: data;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cellNarrow: {
      width: "20%",
      padding: 5
    },
    cellWide: {
      width: "80%",
      padding: 5
    },
    dark: {
      backgroundColor: getThemeType() === "light" ? "rgb(245, 245, 245)" : "rgb(50, 50, 50)",
      padding: 0
    },
    light: {
      backgroundColor: getThemeType() === "light" ? "rgb(235, 235, 235)" : "rgb(60, 60, 60)",
      padding: 0
    },
    container: {
      width: "100%",
      border: "2px solid white",
      padding: 5,
      borderRadius: 10
    },
    table: {
      width: "100%"
    },
    header: {
      fontWeight: 650,
      fontSize: 20
    },
    tableHeader: {
      fontSize: 17,
      fontWeight: 650
    },
    tableData: {
      fontSize: 17
    },
    subHeader: {
      fontSize: 14,
      color: darken(theme.palette.text.primary, 0.3)
    }
  })
);

export default function EnumeratorDisplay(props: Props) {
  const { data } = props;
  const classes = useStyles();
  return (
    <Accordion style={{ width: "100%" }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.header}>{data.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.container}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.cellNarrow}>
                  <Typography className={classes.tableHeader}>Enumerator Value</Typography>
                </TableCell>
                <TableCell align="left" className={classes.cellWide}>
                  <Typography className={classes.tableHeader}>Enumerator Key</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.pairs.map((pair, i) => (
                <TableRow className={i % 2 === 0 ? classes.light : classes.dark} key={i}>
                  <TableCell align="left" className={classes.cellNarrow}>
                    <Typography className={classes.tableData}>{pair.val}</Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.cellWide}>
                    <Typography className={classes.tableData}>{pair.key}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

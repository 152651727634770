import {
  AppBar,
  Box,
  Checkbox,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tab,
  Theme,
  Toolbar,
  Typography,
  createStyles,
  makeStyles
} from "@material-ui/core";
import { useGlobalState, useSnackbar, useStripeAPI, useUserAPI } from "providers";
import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ResponsiveDialog from "common/ResponsiveDialog";
import StripeCard from "./StripeCard";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import UserAvatar from "user/UserAvatar";
import StripeCardList from "./StripeCardList";
import StripeCustomer from "./StripeCustomer";
import StripeInvoices from "./StripeInvoices";
import { pond } from "protobuf-ts/pond";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      flex: 1
    },
    cardCollapse: {
      margin: theme.spacing(1),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3)
    },
    gridContainer: {
      display: "flex",
      alignItems: "center"
    }
  });
});

let stripePub = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  ? (process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)
  : "";
const stripePromise = loadStripe(stripePub);

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function StripeDialog(props: Props) {
  const { open, setOpen } = props;

  const stripeAPI = useStripeAPI();
  const userAPI = useUserAPI();
  const snackbar = useSnackbar();
  // const stripe = useStripe();

  const [{ user, as, team, userTeamPermissions }, dispatch] = useGlobalState();
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [value, setValue] = React.useState("1");
  const [defaultSource, setDefaultSource] = useState("");
  const [customer, setCustomer] = useState<any>({});

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  const load = () => {
    setLoading(true);
    setLoadingMessage("Getting customer data");
    stripeAPI
      .getCustomer()
      .then(resp => {
        if (resp.data === "Stripe customer not found") {
          setLoadingMessage("Initializing new customer");
          stripeAPI.newCustomer().then(resp => {});
        } else {
          let obj = JSON.parse(JSON.stringify(resp.data));
          setCustomer(obj);
          setDefaultSource(obj["default_source"]);
        }
      })
      .catch(err => {
        if (err.response.data.error.toString().includes("No such customer")) {
          // if viewing as self, update user status to get rid of customer key
          if (as.length < 1) {
            //let settings = user.settings;
            user.settings.stripeKey = "";
            userAPI
              .updateUser(user.id(), user.protobuf())
              .then(resp => {
                snackbar.warning("New customer initialized, please refresh page.");
              })
              .catch(err => {
                snackbar.error("Customer error, contact us.");
              });
          } else {
            snackbar.error("Customer error, contact us.");
          }
        } else if (err.response.data.error.toString().includes("https://stripe.com")) {
          snackbar.error("Unknown stripe error");
        } else {
          snackbar.error(err.response.data.error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!open) return;
    load();
    // eslint-disable-next-line
  }, [open]);

  const stripeCardContent = () => {
    return (
      <DialogContent>
        {loading ? (
          <Grid container direction="column" className={classes.gridContainer}>
            <Grid item xs>
              <CircularProgress />
            </Grid>
            <Grid item xs>
              <Typography>{loadingMessage}</Typography>
            </Grid>
          </Grid>
        ) : stripePub.length > 0 ? (
          <Elements stripe={stripePromise}>
            <StripeCard setOpen={setOpen} />
          </Elements>
        ) : (
          <Grid container direction="column" className={classes.gridContainer}>
            <Grid item xs>
              <Typography variant="subtitle2" color="textSecondary">
                Public Stripe key invalid
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    );
  };

  const loadingContent = () => {
    return (
      <DialogContent>
        <Grid container direction="column" className={classes.gridContainer}>
          <Grid item xs>
            <CircularProgress />
          </Grid>
          <Grid item xs>
            <Typography>{loadingMessage}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
    );
  };

  const canViewAsTeam =
    user.settings.defaultTeam.length > 0
      ? userTeamPermissions.includes(pond.Permission.PERMISSION_BILLING)
      : false;

  return (
    <ResponsiveDialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close">
            <CloseIcon />
          </IconButton>
          <UserAvatar user={as === team.key() ? team : user} />
          <Typography variant="h6" className={classes.title}>
            {as === team.key() ? team.name() + "'s " : user.name() + "'s "} Billing
          </Typography>
          <MenuItem
            onClick={() => {
              if (as.length > 1) {
                user.settings.useTeam = false;
                dispatch({ key: "as", value: "" });
                userAPI.updateUser(user.id(), user.protobuf()).then(resp => {
                  snackbar.info("Will no longer view as team by default");
                });
              } else {
                user.settings.useTeam = true;
                dispatch({ key: "as", value: team.key() });
                userAPI.updateUser(user.id(), user.protobuf()).then(resp => {
                  snackbar.info("Will now view as " + team.name() + " by default");
                });
              }
            }}
            button
            disabled={!canViewAsTeam}
            aria-label="Open Team Menu"
            dense>
            <ListItemIcon>
              <Checkbox
                disabled={!canViewAsTeam}
                checked={as.length > 1}
                style={{ margin: 0, padding: 0 }}
              />
            </ListItemIcon>
            <ListItemText primary="View as Team" />
          </MenuItem>
        </Toolbar>
      </AppBar>
      <TabContext value={value}>
        <Box style={{ borderBottom: 1, borderColor: "primary" }}>
          <TabList onChange={(event, value) => handleChange(event, value)} variant="fullWidth">
            <Tab label="Customer" value="1" />
            {/* <Tab label="Add Card" value="2"/> */}
            <Tab label="Cards" value="3" />
            <Tab label="Invoices" value="4" />
          </TabList>
        </Box>
        {loading ? (
          loadingContent()
        ) : (
          <React.Fragment>
            <TabPanel value="1">
              <StripeCustomer customer={customer} loading={loading} />
            </TabPanel>
            <TabPanel value="3">
              {stripeCardContent()}
              {stripePromise !== null && (
                <Elements stripe={stripePromise}>
                  <StripeCardList reloadCustomer={load} defaultSource={defaultSource} />
                </Elements>
              )}
            </TabPanel>
            <TabPanel value="4">
              {/* <Elements stripe={stripePromise}> */}
              <StripeInvoices customer={customer} />
              {/* </Elements> */}
            </TabPanel>
          </React.Fragment>
        )}
      </TabContext>
    </ResponsiveDialog>
  );
}

export interface Scope {
  kind: string;
  key: string;
}

export function newScope(kind: string, key: string): Scope {
  return { kind, key };
}

export function componentScope(device: string, component: string): Scope {
  return { kind: "component", key: device + ":" + component };
}

export function deviceScope(key: string): Scope {
  return { kind: "device", key: key };
}

export function groupScope(key: string): Scope {
  return { kind: "group", key: key };
}

export function binScope(key: string): Scope {
  return { kind: "bin", key: key };
}

export function binYardScope(key: string): Scope {
  return { kind: "binyard", key: key };
}

export function teamScope(key: string): Scope {
  return { kind: "team", key: key };
}

export function userScope(key: string): Scope {
  return { kind: "user", key: key };
}

export function noteScope(key: string): Scope {
  return { kind: "note", key: key };
}

export function fieldScope(key: string): Scope {
  return { kind: "field", key: key };
}

export function siteScope(key: string): Scope {
  return { kind: "site", key: key };
}

export function taskScope(key: string): Scope {
  return { kind: "task", key: key };
}

export function mineScope(key: string): Scope {
  return { kind: "mine", key: key };
}

export function planScope(key: string): Scope {
  return { kind: "harvestPlan", key: key };
}

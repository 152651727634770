import { Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { TabContext, TabPanel } from "@material-ui/lab";
import GeneralEndpoints from "apiDocs/endpoints/generalEndpoinst";
import AviationEndpoints from "apiDocs/endpoints/aviationEndpoints";
import AgricultureEndpoints from "apiDocs/endpoints/agricultureendpoints";
import MiningEndpoints from "apiDocs/endpoints/miningEndpoints";
import ConstructionEndpoints from "apiDocs/endpoints/constructionEndpoints";

export default function Endpoints() {
  const [currentTab, setCurrentTab] = useState("general");

  return (
    <TabContext value={currentTab}>
      <Tabs
        value={currentTab}
        onChange={(_, value) => setCurrentTab(value)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth">
        <Tab label={"General"} value={"general"} />
        <Tab label={"Agriculture"} value={"agriculture"} />
        <Tab label={"Aviation"} value={"aviation"} />
        <Tab label={"Mining"} value={"mining"} />
        <Tab label={"Construction"} value={"construction"} />
      </Tabs>
      <TabPanel value="general">
        <GeneralEndpoints />
      </TabPanel>
      <TabPanel value="aviation">
        <AviationEndpoints />
      </TabPanel>
      <TabPanel value="agriculture">
        <AgricultureEndpoints />
      </TabPanel>
      <TabPanel value="mining">
        <MiningEndpoints />
      </TabPanel>
      <TabPanel value="construction">
        <ConstructionEndpoints />
      </TabPanel>
    </TabContext>
  );
}

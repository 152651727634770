import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Theme,
  Typography,
  createStyles
} from "@material-ui/core";
import FieldNamesIcon from "products/AgIcons/FieldNames";
import AddPlaneIcon from "products/AviationIcons/AddPlaneIcon";
import OmniAirDeviceIcon from "products/AviationIcons/OmniAirDeviceIcon";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PlaneIcon from "products/AviationIcons/PlaneIcon";
import { pond } from "protobuf-ts/pond";
import React, { useState } from "react";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      background: theme.palette.background.default,
      margin: 5,
      boxShadow: "4px 4px 10px black",
      width: 50,
      height: 50
    },
    list: {
      background: theme.palette.background.default,
      opacity: 0.75,
      borderRadius: 15,
      marginTop: 10,
      boxShadow: "4px 4px 10px black"
    },
    listItems: {
      borderRadius: "3rem"
    },
    liFont: {
      fontWeight: 700,
      marginLeft: -15
    }
  });
});

interface Props {
  toggleMarkerType: (type: pond.ObjectType) => void;
  toggleMarkerDisplay: (display: boolean) => void;
}

export default function AviationMapTools(props: Props) {
  const classes = useStyles();
  const { toggleMarkerType, toggleMarkerDisplay } = props;
  const [airportMenu, setAirportMenu] = useState<"block" | "none">("none");
  const [siteButtonHovered, setSiteButtonHovered] = useState<boolean>(false);
  const [placeTerminal, setPlaceTerminal] = useState(false);
  const [placeGate, setPlaceGate] = useState(false);
  const [markerDisplay, setMarkerDisplay] = useState(false);

  const toggleAirportMenu = () => {
    if (airportMenu === "none") {
      setAirportMenu("block");
    } else {
      setAirportMenu("none");
      setPlaceTerminal(false);
      setPlaceGate(false);
      toggleMarkerType(pond.ObjectType.OBJECT_TYPE_UNKNOWN);
    }
  };

  return (
    <Box style={{ position: "absolute", top: 5, left: 5, zIndex: 300 }}>
      <Grid container direction="column">
        <Grid item>
          <IconButton
            className={classes.button}
            title={"Terminal"}
            onClick={toggleAirportMenu}
            onMouseOver={() => setSiteButtonHovered(true)}
            onMouseOut={() => setSiteButtonHovered(false)}
            style={
              airportMenu === "block"
                ? { background: "#004f9b" }
                : siteButtonHovered
                ? { background: "grey" }
                : {}
            }>
            <PlaneIcon width={35} height={35} />
          </IconButton>
        </Grid>
        <Grid item style={{ display: airportMenu }}>
          <List className={classes.list}>
            <ListItem
              id="addSite"
              button
              onClick={() => {
                setPlaceTerminal(true);
                setPlaceGate(false);
                toggleMarkerType(pond.ObjectType.OBJECT_TYPE_TERMINAL);
              }}
              className={classes.listItems}
              style={placeTerminal ? { background: "#004f9b" } : {}}>
              <ListItemIcon>
                <AddPlaneIcon height={35} width={35} />
              </ListItemIcon>
              <Typography className={classes.liFont}>Add Terminal</Typography>
            </ListItem>
            <ListItem
              id="addGate"
              button
              onClick={() => {
                setPlaceGate(true);
                setPlaceTerminal(false);
                toggleMarkerType(pond.ObjectType.OBJECT_TYPE_GATE);
              }}
              className={classes.listItems}
              style={placeGate ? { background: "#004f9b" } : {}}>
              <ListItemIcon>
                <OmniAirDeviceIcon size={35} />
              </ListItemIcon>
              <Typography className={classes.liFont}>Add Gate</Typography>
            </ListItem>
            <ListItem
              id="showSiteNames"
              button
              onClick={() => {
                setMarkerDisplay(!markerDisplay);
                toggleMarkerDisplay(!markerDisplay);
              }}
              className={classes.listItems}>
              <ListItemIcon>
                {markerDisplay ? <VisibilityOffIcon /> : <FieldNamesIcon />}
              </ListItemIcon>
              <Typography className={classes.liFont}>Show Marker Names</Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}

import FieldMapWhite from "assets/products/Ag/fieldMapWhite.png";
import FieldMapBlack from "assets/products/Ag/fieldMapBlack.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function FieldMapIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? FieldMapWhite : FieldMapBlack;
    }

    return themeType === "light" ? FieldMapBlack : FieldMapWhite;
  };

  return <ImgIcon alt="fields" src={src()} />;
}

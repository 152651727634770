import { AddressTypeExtension } from "pbHelpers/AddressType";
import { quack } from "protobuf-ts/quack";

export const DAC: AddressTypeExtension = {
  addressType: quack.AddressType.ADDRESS_TYPE_DAC,
  friendlyName: "DAC",
  humanReadableAddress: function(componentType: quack.ComponentType, address: number): string {
    return (address + 1).toString();
  },
  singular: false
};

import {
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  makeStyles
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import CancelIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDebounce, usePrevious } from "hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      width: "100%",
      marginLeft: 0,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25)
      },
      [theme.breakpoints.up("sm")]: {
        width: "auto"
      }
    },
    inputRoot: {
      position: "relative",
      color: "inherit",
      width: "100%"
    },
    searchInput: {
      width: "100%"
    },
    spacingLeft: {
      marginLeft: theme.spacing(1)
    },
    spacingRight: {
      marginRight: theme.spacing(1)
    },
    mutedIcon: {
      color: theme.palette.text.secondary
    },
    roundBar: {
      borderRadius: theme.shape.borderRadius
    },
    squareBar: {
      borderRadius: 0
    }
  })
);

interface Props {
  value: string;
  onChange: (value: string) => void;
  shape?: "round" | "square";
}

export default function SearchBar(props: Props) {
  const classes = useStyles();
  const { value, onChange, shape } = props;
  const prevValue = usePrevious(value);
  const [search, setSearch] = useState(value);
  const debouncedSearch = useDebounce(search, 500);
  const prevDebouncedSearch = usePrevious(debouncedSearch);

  useEffect(() => {
    if (value !== prevValue) {
      setSearch(value);
    }
  }, [prevValue, value]);

  useEffect(() => {
    if (debouncedSearch !== prevDebouncedSearch) {
      onChange(debouncedSearch);
    }
  }, [debouncedSearch, prevDebouncedSearch, onChange]);

  const handleChange = (newSearch: string) => {
    setSearch(newSearch);
  };

  return (
    <Grid
      container
      direction="row"
      className={classNames(
        classes.search,
        shape === "square" ? classes.squareBar : classes.roundBar
      )}>
      <InputBase
        autoFocus={false}
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.searchInput
        }}
        onChange={event => handleChange(event.target.value)}
        value={search}
        fullWidth
        inputProps={{ "aria-label": "Search" }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon className={classes.spacingLeft} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              size="small"
              aria-label="Cancel Search"
              onClick={() => handleChange("")}
              className={classes.spacingRight}>
              <CancelIcon fontSize="small" className={classes.mutedIcon} />
            </IconButton>
          </InputAdornment>
        }
      />
    </Grid>
  );
}

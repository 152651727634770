import { Box, createStyles, darken, makeStyles, Theme, withStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ExtractMoisture } from "grain";
import { GrainCable } from "models/GrainCable";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers";
import React, { useState } from "react";
import { getThemeType } from "theme";

const BIN_ASPECT_RATIO = 0.45;
const GRAIN_COLOUR = "#b5a962";

const useStyles = makeStyles((theme: Theme) => {
  const binAccent = "#b5ae71";
  return createStyles({
    binContainer: {
      fill: theme.palette.type === "light" ? "#373737" : "#292929",
      fillOpacity: 1
    },
    binAccent: {
      fill: binAccent,
      fillOpacity: 0.3
    },
    binInventory: {
      fill: GRAIN_COLOUR,
      fillOpacity: 0.5
    },
    binInvetoryEmpty: {
      fillOpacity: 0
    },
    cableLine: {
      stroke: "#222222",
      strokeWidth: 2,
      strokeOpacity: 0.85
    },
    lidarGrainEstimate: {
      stroke: "#FFFFFF",
      strokeWidth: 2,
      strokeOpacity: 0.85
    },
    cableGrainEstimate: {
      stroke: "orange",
      strokeWidth: 2,
      strokeOpacity: 0.85,
      fill: "transparent"
      //think about using the fill to colour the inventory, doing this we may need to adjust what the manual inventory looks like though
      //fill: GRAIN_COLOUR,
      //fillOpacity: 0.5
    },
    cableNode: {
      fill: "#fff"
    },
    topNode: {
      fill: "red"
    },
    clickableArea: {
      fill: "transparent",
      "&:hover": {
        fill: "lightGrey",
        opacity: 0.3,
        cursor: "pointer"
      }
    },
    tempHum: {
      fontSize: 3.5,
      strokeWidth: 0.25,
      alignmentBaseline: "middle",
      textAnchor: "middle",
      filter: "drop-shadow(20px 20px 20px rgb(0 0 0))"
    },
    smallToggle: {
      position: "relative",
      marginBottom: 10
    },
    fullToggle: {
      position: "inherit",
      marginBottom: 20
    }
  });
});

interface Props {
  height: number;
  binShape?: pond.BinShape;
  fillPercentage: number;
  lidarEstimate?: number;
  //tempNodes?: number[] | null; //node order from top of bin to bottom in Celcius (no longer used)
  hasMinHeight?: boolean;
  cables?: GrainCable[];
  showTempHum?: boolean;
  isFullScreen?: boolean;
  grainType?: pond.Grain;
  highTemp: number;
  lowTemp: number;
  cableEstimate?: boolean;
  cableNodeClicked?: (cable: GrainCable, fillNode: number) => void;
}

interface GrainNodePoint {
  x: number;
  y: number;
}

export default function BinSVG(props: Props) {
  const classes = useStyles();
  const {
    height,
    fillPercentage,
    lidarEstimate,
    binShape,
    hasMinHeight,
    cables,
    showTempHum,
    isFullScreen,
    cableNodeClicked,
    highTemp,
    lowTemp,
    cableEstimate: nodeGrainSeperation
  } = props;
  const [extraDetails, setExtraDetails] = useState<"temps" | "hums">("temps");
  const [{ user }] = useGlobalState();

  const StyledToggleButtonGroup = withStyles(theme => ({
    grouped: {
      margin: theme.spacing(-0.5),
      border: "none",
      padding: theme.spacing(1),
      "&:not(:first-child):not(:last-child)": {
        borderRadius: 24,
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5)
      },
      "&:first-child": {
        borderRadius: 24,
        marginLeft: theme.spacing(0.25)
      },
      "&:last-child": {
        borderRadius: 24,
        marginRight: theme.spacing(0.25)
      }
    },
    root: {
      backgroundColor: darken(
        theme.palette.background.paper,
        getThemeType() === "light" ? 0.05 : 0.25
      ),
      borderRadius: 24,
      content: "border-box"
    }
  }))(ToggleButtonGroup);

  const StyledToggle = withStyles({
    root: {
      backgroundColor: "transparent",
      overflow: "visible",
      content: "content-box",
      "&$selected": {
        backgroundColor: "gold",
        color: "black",
        borderRadius: 24,
        fontWeight: "bold"
      },
      "&$selected:hover": {
        backgroundColor: "rgb(255, 255, 0)",
        color: "black",
        borderRadius: 24
      }
    },
    selected: {}
  })(ToggleButton);

  const maxInventoryY = () => {
    return binShape === pond.BinShape.BIN_SHAPE_FLAT_BOTTOM ? 255.77 : 237.55;
  };

  const maxInteriorY = () => {
    return binShape === pond.BinShape.BIN_SHAPE_FLAT_BOTTOM ? 252.77 : 223.8;
  };

  const lidarGrainEstimate = () => {
    const binTop = 24; //binsvg has the same top position for both shapes
    const binBottom = binShape === pond.BinShape.BIN_SHAPE_FLAT_BOTTOM ? 180 : 155;
    //the y axis for svg starts at the top and goes down so in order to 'flip' the line to use the bottom of the bin subtract it from 100 since the estimate is a percentage
    const grainFillTo =
      ((lidarEstimate ? 100 - lidarEstimate : 0) * (binBottom - binTop)) / 100 + binTop;
    let controlY = grainFillTo - 6;

    return (
      <g key={"estimate"} id={"estimate"} data-name={"estimate"}>
        <path
          d={"M 5 " + grainFillTo + "C 5 " + controlY + ", 80 " + controlY + ", 80 " + grainFillTo}
          className={classes.lidarGrainEstimate}
          strokeDasharray="3"
          fill="transparent"
        />
      </g>
    );
  };

  const cableGrainEstimate = (
    points: GrainNodePoint[],
    cableSpacing: number,
    startY: number,
    endY: number
  ) => {
    let cableGrainPath = "M 4 " + startY; //set the start
    points.forEach((pathPoint, i) => {
      if (i === 0) {
        //for the first point
        cableGrainPath = cableGrainPath + " L " + pathPoint.x + " " + pathPoint.y;
      } else {
        //for all other points
        if (pathPoint.y === points[i - 1].y) {
          //if the elevation has not changed from the last point draw a straight line
          cableGrainPath = cableGrainPath + " L " + pathPoint.x + " " + pathPoint.y;
        } else {
          // if it has changed draw a line with 2 opposite curves
          //get the midpoint for the x and y values to draw the first curve too
          let midX = pathPoint.x - cableSpacing / 2;
          //subtract half the difference between the two points from the current y value
          let midY = pathPoint.y - (pathPoint.y - points[i - 1].y) / 2;

          //the control points x is the mid point
          let controlX = midX;
          //the control points y is where the line is starting
          let controlY = points[i - 1].y;

          //draw the first curve from the previous point to the midpoint
          cableGrainPath =
            cableGrainPath + " Q " + controlX + " " + controlY + ", " + midX + " " + midY;
          /**
           * draw the second curve from the midpoint to the current point
           * using T makes the svg curv derive what the control point needs to be based on the curve before it to create the opposite curve
           * if there is no curve in front (using Q) it should just draw a straight line
           */
          cableGrainPath = cableGrainPath + " T " + pathPoint.x + " " + pathPoint.y;
        }
      }
    });
    cableGrainPath = cableGrainPath + " L 81 " + endY;
    return (
      <g key={"cableGrainLvl"} id={"cableGrainLvl"} data-name={"cableGrainLvl"}>
        <path d={cableGrainPath} className={classes.cableGrainEstimate} />
      </g>
    );
  };

  const binInventorySVG = () => {
    const interiorY1 = 99.93;
    const interiorY2 = maxInteriorY();
    let maxY = maxInventoryY();
    const grainFillTo = (fillPercentage * (interiorY1 - interiorY2)) / 100 + interiorY2;
    return (
      <g id="BinInventory" data-name="BinInventory">
        <path
          d={
            "M1200.64," +
            interiorY2 +
            "a2.88,2.88,0,0,1-1.92,2.74,51,51,0,0,1-9.82,1.89," +
            maxY +
            "," +
            maxY +
            ",0,0,1-27.42,1.4," +
            maxY +
            "," +
            maxY +
            ",0,0,1-27.42-1.4,51,51,0,0,1-9.82-1.89,2.88,2.88,0,0,1-1.92-2.74V" +
            interiorY1 +
            "a2.87,2.87,0,0,1,1.92-2.73,49.9,49.9,0,0,1,9.82-1.9,234.31,234.31,0,0,1,25.44-1.39h4a234.31,234.31,0,0,1,25.44,1.39,49.9,49.9,0,0,1,9.82,1.9,2.87,2.87,0,0,1,1.92,2.73Z"
          }
          transform="translate(-1119.01 -74.06)"
          className={classes.binAccent}
          fillOpacity={0.1}
        />
        <path
          d={
            "M1200.64," +
            interiorY2 +
            "a2.88,2.88,0,0,1-1.92,2.74,51,51,0,0,1-9.82,1.89," +
            maxY +
            "," +
            maxY +
            ",0,0,1-27.42,1.4," +
            maxY +
            "," +
            maxY +
            ",0,0,1-27.42-1.4,51,51,0,0,1-9.82-1.89,2.88,2.88,0,0,1-1.92-2.74V" +
            grainFillTo +
            "a2.87,2.87,0,0,1,1.92-2.73,49.9,49.9,0,0,1,9.82-1.9,234.31,234.31,0,0,1,25.44-1.39h4a234.31,234.31,0,0,1,25.44,1.39,49.9,49.9,0,0,1,9.82,1.9,2.87,2.87,0,0,1,1.92,2.73Z"
          }
          transform="translate(-1119.01 -74.06)"
          className={fillPercentage > 0 ? classes.binInventory : classes.binInvetoryEmpty}
        />
      </g>
    );
  };

  const getGrainGradient = (temperature: number): string => {
    let nodeTemp = temperature;
    if (highTemp === 0 && lowTemp === 0) {
      if (nodeTemp >= 30) return "url(#nodeBurning)";
      else if (nodeTemp >= 25) return "url(#nodeHot)";
      else if (nodeTemp >= 20) return "url(#nodeWarm)";
      else if (nodeTemp >= 15) return "url(#nodeStable)";
      else if (nodeTemp >= 10) return "url(#nodeCool)";
      else if (nodeTemp >= 5) return "url(#nodeCold)";
      else return "url(#nodeFreezing)";
    } else {
      if (nodeTemp >= highTemp + 10) return "url(#nodeBurning)";
      else if (nodeTemp >= highTemp + 5) return "url(#nodeHot)";
      else if (nodeTemp >= highTemp) return "url(#nodeWarm)";
      else if (nodeTemp < highTemp && nodeTemp > lowTemp) return "url(#nodeStable)";
      else if (nodeTemp >= lowTemp) return "url(#nodeCool)";
      else if (nodeTemp >= lowTemp - 5) return "url(#nodeCold)";
      else return "url(#nodeFreezing)";
    }
  };

  const multiCables = () => {
    if (!cables) return;
    //sort the cables by node number so the one with the most nodes is in the middle
    let sortedCables = cables.sort((a, b) => b.temperatures.length - a.temperatures.length);
    let cableLines: JSX.Element[] = [];
    const centerX = 42.5;
    const minCableX = 7;
    const maxCableX = 78;
    const spacingX = (maxCableX - minCableX) / (cables.length + 1);
    const cableY1 = 20;
    const cableY2 = binShape === pond.BinShape.BIN_SHAPE_HOPPER_BOTTOM ? 157 : 184;

    /**
     * variables used for the cable grain line
     */
    //this array will store the path coordinates to build the curved grain estimate using the day/night cycle top node stuff
    let cableGrainPathPoints: GrainNodePoint[] = [];
    let cableGrainStartY = 0;
    let cableGrainEndY = 0;

    let leftCables = 0; //starts at 0 because the first left will be the middle
    let rightCables = 1;

    sortedCables.forEach((cable, cableIndex) => {
      const minNodeY = 17;
      const maxNodeY = binShape === pond.BinShape.BIN_SHAPE_HOPPER_BOTTOM ? 157 : 184;
      const filledToY = (fillPercentage * (minNodeY - maxNodeY)) / 100 + maxNodeY;
      const nodeSpacingY = (maxNodeY - minNodeY) / (cable.temperatures.length + 1);
      let numNodes = cable.temperatures.length;
      let topNode = cable.topNode;

      let cablePos = centerX;
      if (cableIndex % 2 === 0) {
        cablePos = cablePos - spacingX * leftCables;
        leftCables++;
      } else {
        cablePos = cablePos + spacingX * rightCables;
        rightCables++;
      }

      let nodeHeatMap: React.SVGProps<SVGEllipseElement>[] = [];
      let nodes: React.SVGProps<SVGCircleElement | SVGRectElement | SVGElement>[] = [];
      let nodeClickers: React.SVGProps<SVGCircleElement>[] = [];
      let temps: React.SVGProps<SVGTextElement>[] = [];
      let hums: React.SVGProps<SVGTextElement>[] = [];

      cable.temperatures.forEach((n, nodeIndex) => {
        //since the array goes from the top down set the nodeNumber we are currently working with to be the number of nodes we have left
        //ie if we have 2 nodes left to draw we are on node 2
        let nodeNumber = numNodes;
        let displayTemp = n;

        if (user.settings.temperatureUnit === pond.TemperatureUnit.TEMPERATURE_UNIT_FAHRENHEIT) {
          displayTemp = n * 1.8 + 32;
        }

        const nodeY = nodeSpacingY * (nodeIndex + 1) + minNodeY;
        const thresholdOffsetY = 5;
        if (nodeY + thresholdOffsetY > filledToY) {
          nodeHeatMap.push(
            <ellipse
              key={nodeIndex}
              cx={cablePos}
              cy={nodeSpacingY * (nodeIndex + 1) + minNodeY}
              rx="20"
              ry="10"
              fill={getGrainGradient(n)}
            />
          );
        }
        nodes.push(
          showTempHum ? (
            <rect
              key={"node" + nodeIndex}
              x={cablePos - 5}
              y={nodeY - 2.5}
              width="10"
              height="5"
              rx="1.5"
              className={classes.cableNode}></rect>
          ) : nodeNumber === topNode ? (
            <g key={"node" + nodeIndex}>
              <path
                d={
                  "M " +
                  (cablePos - 4) +
                  " " +
                  (nodeY - 3) + //starting point of the curve
                  " C " +
                  (cablePos - 2) +
                  " " +
                  (nodeY - 5) +
                  ", " + //first control point
                  (cablePos + 2) +
                  " " +
                  (nodeY - 5) +
                  ", " + //second control point
                  (cablePos + 4) +
                  " " +
                  (nodeY - 3) // ending point of the curve
                }
                stroke="white"
                fill="transparent"
              />
              <circle cx={cablePos} cy={nodeY} className={classes.cableNode} r="2"></circle>
            </g>
          ) : (
            <circle
              key={"node" + nodeIndex}
              cx={cablePos}
              cy={nodeY}
              className={classes.cableNode}
              r="2"></circle>
          )
        );

        temps.push(
          <text
            filter="drop-shadow( 4px 4px 10px rgba(0, 0, 0, .7))"
            key={"node" + nodeIndex + "temp"}
            x={cablePos}
            y={nodeY + 1}
            stroke="blue"
            className={classes.tempHum}>
            {displayTemp.toFixed(1)}°
          </text>
        );
        hums.push(
          <text
            key={"node" + nodeIndex + "hum"}
            x={cablePos}
            y={nodeY + 1}
            stroke="green"
            className={classes.tempHum}>
            {ExtractMoisture(props.grainType, n, cable.humidities[nodeIndex] ?? 0).toFixed(1)}%
          </text>
        );
        !showTempHum &&
          nodeClickers.push(
            <circle
              key={"node" + nodeIndex}
              onClick={() => {
                if (cable.key() !== "" && cableNodeClicked) {
                  cableNodeClicked(cable, nodeNumber);
                }
              }}
              cx={cablePos}
              cy={nodeY}
              className={classes.clickableArea}
              r="10"></circle>
          );

        //if the current node is the top node of the cable use its x and y to add to the cable grain path
        if (nodeNumber === topNode) {
          //need to determin the correct control points

          //if it is an even cable prepend it to the string, if it is odd append it
          if (cableIndex % 2 === 0) {
            cableGrainStartY = nodeY - nodeSpacingY / 2;
            cableGrainPathPoints.unshift({
              x: cablePos,
              y: nodeY - nodeSpacingY / 2
            });
            //cableGrainPathPoints = (" L " + cablePos + " " + (nodeY - nodeSpacingY/2)) + cableGrainPathPoints
          } else {
            cableGrainEndY = nodeY - nodeSpacingY / 2;
            cableGrainPathPoints.push({
              x: cablePos,
              y: nodeY - nodeSpacingY / 2
            });
            //cableGrainPathPoints = cableGrainPathPoints + (" L " + cablePos + " " + (nodeY - nodeSpacingY/2))
          }
        }
        //decrement the number of nodes left
        numNodes--;
      });

      cableLines.push(
        <g key={"cable" + cableIndex} id={"cable " + cableIndex} data-name={"cable " + cableIndex}>
          {nodeHeatMap}
          <line
            x1={cablePos}
            y1={cableY1}
            x2={cablePos}
            y2={cableY2}
            className={classes.cableLine}
          />
          {nodes}
          {nodeClickers}
          {showTempHum && (extraDetails === "temps" ? temps : hums)}
        </g>
      );
    });
    //for single cable bins the endY would still be 0 so just make it the startY to draw a straight line across the bin
    if (cableGrainEndY === 0) {
      cableGrainEndY = cableGrainStartY;
    }
    if (nodeGrainSeperation && cables.length > 0 && cableGrainPathPoints.length === cables.length) {
      cableLines.unshift(
        cableGrainEstimate(cableGrainPathPoints, spacingX, cableGrainStartY, cableGrainEndY)
      );
    }
    return cableLines;
  };

  const gradients = () => {
    const burning = "#8a1f0d";
    const hot = "#c42605";
    const warm = "#d75719";
    const cool = "#429bb8";
    const cold = "#0575E6";
    const freezing = "#021B79";
    return (
      <React.Fragment>
        <radialGradient id="nodeBurning">
          <stop offset="0%" stopColor={burning} stopOpacity="0.8" />
          <stop offset="60%" stopColor={hot} stopOpacity="0.4" />
          <stop offset="80%" stopColor={warm} stopOpacity="0.2" />
          <stop offset="100%" stopColor={GRAIN_COLOUR} stopOpacity="0" />
        </radialGradient>
        <radialGradient id="nodeHot">
          <stop offset="10%" stopColor={hot} stopOpacity="0.8" />
          <stop offset="55%" stopColor={warm} stopOpacity="0.4" />
          <stop offset="100%" stopColor={GRAIN_COLOUR} stopOpacity="0" />
        </radialGradient>
        <radialGradient id="nodeWarm">
          <stop offset="20%" stopColor={warm} stopOpacity="0.8" />
          <stop offset="100%" stopColor={GRAIN_COLOUR} stopOpacity="0" />
        </radialGradient>
        <radialGradient id="nodeStable">
          <stop offset="0%" stopColor={GRAIN_COLOUR} stopOpacity="0" />
        </radialGradient>
        <radialGradient id="nodeCool">
          <stop offset="20%" stopColor={cool} stopOpacity="0.8" />
          <stop offset="100%" stopColor={GRAIN_COLOUR} stopOpacity="0" />
        </radialGradient>
        <radialGradient id="nodeCold">
          <stop offset="10%" stopColor={cold} stopOpacity="0.8" />
          <stop offset="55%" stopColor={cool} stopOpacity="0.4" />
          <stop offset="100%" stopColor={GRAIN_COLOUR} stopOpacity="0" />
        </radialGradient>
        <radialGradient id="nodeFreezing">
          <stop offset="0%" stopColor={freezing} stopOpacity="0.8" />
          <stop offset="60%" stopColor={cold} stopOpacity="0.4" />
          <stop offset="80%" stopColor={cool} stopOpacity="0.2" />
          <stop offset="100%" stopColor={GRAIN_COLOUR} stopOpacity="0" />
        </radialGradient>
      </React.Fragment>
    );
  };

  const hopperSVG = () => {
    const binBottom = fillPercentage > 0 ? classes.binInventory : classes.binAccent;
    return (
      <React.Fragment>
        <g id="container" data-name="container">
          <path
            className={classes.binContainer}
            d="M1165,74.68a3.8,3.8,0,0,0-1.65-.38h-3.8a3.8,3.8,0,0,0-1.65.38l-36.62,17.69a3.64,3.64,0,0,0-2.06,3.28V225.77a3.1,3.1,0,0,0,1,2.28l37.67,34.54a2.66,2.66,0,0,0,1.88.71l1.68,0,1.68,0a2.66,2.66,0,0,0,1.88-.71l37.67-34.54a3.1,3.1,0,0,0,1-2.28V95.65a3.64,3.64,0,0,0-2.06-3.28Z"
            transform="translate(-1119.01 -74.06)"
          />
        </g>
        <g id="BinTop" data-name="BinTop">
          <path
            className={classes.binAccent}
            d="M1133.79,92.24c2.28-.28,4.79-.51,7.45-.71l15.23-14.87L1124,94.12A50.67,50.67,0,0,1,1133.79,92.24Z"
            transform="translate(-1119.01 -74.06)"
          />
          <path
            className={classes.binAccent}
            d="M1159.23,90.85h1.07V76.37h-.87l-15.32,15C1148.81,91.05,1153.93,90.88,1159.23,90.85Z"
            transform="translate(-1119.01 -74.06)"
          />
          <path
            className={classes.binAccent}
            d="M1163.08,90.85h.12c5.45,0,10.7.21,15.52.51l-15.31-15h-1.11V90.84Z"
            transform="translate(-1119.01 -74.06)"
          />
          <path
            className={classes.binAccent}
            d="M1188.64,92.24c5.89.71,8.83,1.5,10.31,2.07l-32.3-17.37,14.94,14.62C1184.1,91.75,1186.47,92,1188.64,92.24Z"
            transform="translate(-1119.01 -74.06)"
          />
        </g>
        <g id="BinBottom" data-name="BinBottom">
          <path
            className={binBottom}
            d="M1134,231.24c-1.61-.19-3-.39-4.18-.59l9.83,8.84.63.6v-8.23C1138,231.68,1135.89,231.48,1134,231.24Z"
            transform="translate(-1119.01 -74.06)"
          />
          <path
            className={binBottom}
            d="M1142.62,232V242l1.75,1.83,13.26,11.94c.85,0,1.74.09,2.63.1V232.63C1154,232.61,1148,232.41,1142.62,232Z"
            transform="translate(-1119.01 -74.06)"
          />
          <path
            className={binBottom}
            d="M1162.66,232.63v23.21c.89,0,1.77-.05,2.63-.1L1180,242.46V232.05C1174.71,232.41,1168.79,232.61,1162.66,232.63Z"
            transform="translate(-1119.01 -74.06)"
          />
          <path
            className={binBottom}
            d="M1188.8,231.24c-2,.24-4.12.45-6.38.63v8.44l10.77-9.7C1191.94,230.82,1190.5,231,1188.8,231.24Z"
            transform="translate(-1119.01 -74.06)"
          />
        </g>
      </React.Fragment>
    );
  };

  const flatBottomSVG = () => {
    return (
      <g id="Flat Bottom Bin" data-name="Flat Bottom Bin">
        <path
          className={classes.binContainer}
          d="M303.94,92.37,267.32,74.68a3.8,3.8,0,0,0-1.65-.38h-3.8a3.8,3.8,0,0,0-1.65.38L223.6,92.37a3.64,3.64,0,0,0-2.06,3.28V257a3.1,3.1,0,0,0,1,2.28l.23.21c3.67,1.21,15.83,4.39,41,3.79,25.17.6,37.34-2.58,41-3.79l.23-.21a3.1,3.1,0,0,0,1-2.28V95.65A3.64,3.64,0,0,0,303.94,92.37Z"
          transform="translate(-221.3 -74.06)"
        />
        <g id="BinTop" data-name="BinTop">
          <path
            className={classes.binAccent}
            d="M236.08,92.24c2.28-.28,4.79-.51,7.45-.71l15.23-14.87L226.3,94.12A50.67,50.67,0,0,1,236.08,92.24Z"
            transform="translate(-221.3 -74.06)"
          />
          <path
            className={classes.binAccent}
            d="M261.53,90.85h1.07V76.37h-.88l-15.32,15C251.1,91.05,256.22,90.88,261.53,90.85Z"
            transform="translate(-221.3 -74.06)"
          />
          <path
            className={classes.binAccent}
            d="M265.37,90.85h.12c5.45,0,10.7.21,15.52.51l-15.31-15h-1.11V90.84Z"
            transform="translate(-221.3 -74.06)"
          />
          <path
            className={classes.binAccent}
            d="M290.93,92.24c5.89.71,8.84,1.5,10.31,2.07l-32.3-17.37,14.94,14.62C286.39,91.75,288.77,92,290.93,92.24Z"
            transform="translate(-221.3 -74.06)"
          />
        </g>
      </g>
    );
  };

  return (
    <React.Fragment>
      {showTempHum && (
        <Box
          textAlign="center"
          //visibility={showTempHum ? "visible" : "hidden"}
          className={!isFullScreen ? classes.smallToggle : classes.fullToggle}>
          <StyledToggleButtonGroup
            value={extraDetails}
            exclusive
            size="small"
            aria-label="Bin Mode">
            <StyledToggle
              value={"temps"}
              aria-label="Node Temperature"
              onClick={() => setExtraDetails("temps")}>
              {isFullScreen ? "Temperature" : "Temp"}
            </StyledToggle>
            <StyledToggle
              onClick={() => setExtraDetails("hums")}
              value={"hums"}
              aria-label="Node Humidity">
              {props.grainType
                ? isFullScreen
                  ? "Moisture"
                  : "EMC"
                : isFullScreen
                ? "Humidity"
                : "Hum"}
            </StyledToggle>
          </StyledToggleButtonGroup>
        </Box>
      )}
      <Box
        height={height}
        minHeight={hasMinHeight ? "360px" : "none"}
        width={height * BIN_ASPECT_RATIO}
        minWidth={hasMinHeight ? 360 * BIN_ASPECT_RATIO : "none"}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 84.94 189.48"
          height="100%"
          width="100%">
          <defs>{gradients()}</defs>
          <title>Bin</title>
          <g id="Bin" data-name="Bin">
            {binShape === pond.BinShape.BIN_SHAPE_FLAT_BOTTOM ? flatBottomSVG() : hopperSVG()}
            <g id="BinInterior" data-name="BinInterior">
              {binInventorySVG()}
              {cables && multiCables()}
              {lidarEstimate && lidarGrainEstimate()}
            </g>
          </g>
        </svg>
      </Box>
    </React.Fragment>
  );
}

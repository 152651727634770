import { Tag } from "models";

export function filterByTags(searchValue: string, tags: Tag[]): boolean {
  return tags.some(tag => filterByTag(searchValue, tag));
}

export function filterByTag(searchValue: string, tag: Tag): boolean {
  return (
    tag
      .name()
      .toLowerCase()
      .indexOf(
        searchValue
          .replace("tag:", "")
          .trim()
          .toLowerCase()
      ) > -1
  );
}

import Loading from "./Loading";
import React, { useEffect } from "react";
import { useAuth } from "hooks";

export default function Logout() {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return <Loading fullViewport={true} />;
}

import {
  Box,
  Card,
  CardActionArea,
  createStyles,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Typography
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Task } from "models";
import { useGlobalState } from "providers";
import { useUserAPI } from "hooks";
import { Done, MoreVert } from "@material-ui/icons";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { red } from "@material-ui/core/colors";
import { pond } from "protobuf-ts/pond";
import { taskScope } from "models/Scope";
import EventBlocker from "common/EventBlocker";

interface Props {
  task: Task;
  reLoad: () => void;
  editTaskMethod: (task: Task) => void;
  markComplete: (task: Task) => void;
  deleteTask: (task: Task) => void;
  openTaskPage: (taskId: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    red: {
      color: red["500"]
    }
  })
);

export default function TaskCard(props: Props) {
  const monthList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ];
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);
  const classes = useStyles();
  const [permissions, setPermissions] = useState<pond.Permission[]>([]);
  const [{ user }] = useGlobalState();
  const userAPI = useUserAPI();
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

  const loadPermissions = useCallback(() => {
    let scope = taskScope(props.task.key);
    userAPI
      .getUser(user.id(), scope)
      .then(resp => {
        setPermissions(resp.permissions);
      })
      .catch(err => {});
  }, [props.task, userAPI, user]);

  useEffect(() => {
    loadPermissions();
  }, [loadPermissions]);

  const openTaskPage = () => {
    props.openTaskPage(props.task.key);
  };

  useEffect(() => {
    let date = new Date(props.task.start());
    setMonth(date.getUTCMonth());
    setDay(date.getUTCDate());
  }, [props.task]);

  const taskActions = () => {
    return (
      <Menu
        id="viewMenu"
        anchorEl={menuAnchorEl ? menuAnchorEl : null}
        open={menuAnchorEl !== null}
        onClose={() => setMenuAnchorEl(null)}
        disableAutoFocusItem>
        <MenuItem
          onClick={() => {
            props.markComplete(props.task);
            setMenuAnchorEl(null);
          }}>
          <ListItemIcon>
            <Done style={{ color: "green" }} />
          </ListItemIcon>
          <ListItemText primary="Mark Complete" />
        </MenuItem>
        {permissions.includes(pond.Permission.PERMISSION_WRITE) && (
          <MenuItem
            onClick={() => {
              props.editTaskMethod(props.task);
              setMenuAnchorEl(null);
            }}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </MenuItem>
        )}
        {permissions.includes(pond.Permission.PERMISSION_WRITE) && (
          <MenuItem
            onClick={() => {
              props.deleteTask(props.task);
              setMenuAnchorEl(null);
            }}>
            <ListItemIcon>
              <DeleteIcon className={classes.red} />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        )}
      </Menu>
    );
  };

  return (
    <Card style={{ width: "100%" }}>
      <CardActionArea onClick={openTaskPage} component="div">
        <Grid container direction="row" alignItems="center">
          <Grid item xs={2}>
            <Box
              style={{
                border: "1px solid white",
                width: "50px",
                height: "50px",
                borderRadius: 5,
                margin: "auto",
                verticalAlign: "middle",
                lineHeight: "50px",
                textAlign: "center"
              }}>
              <Typography>{monthList[month]}</Typography>
              <Typography style={{ fontWeight: 750 }}>{day}</Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box margin={1}>
              <Typography style={{ fontWeight: 700 }}>{props.task.title()}</Typography>
            </Box>
            <Box margin={1}>
              <Typography noWrap>{props.task.description()}</Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <EventBlocker style={{ margin: "auto" }}>
              <IconButton
                aria-owns={"taskOptions"}
                aria-haspopup="true"
                onClick={event => setMenuAnchorEl(event.currentTarget)}>
                <MoreVert />
              </IconButton>
            </EventBlocker>
          </Grid>
        </Grid>
      </CardActionArea>
      {taskActions()}
    </Card>
  );
}

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useAuth } from "hooks";
import moment from "moment";
import React, { createContext, PropsWithChildren, useContext } from "react";
import BillingProvider from "./billing";
import GitlabProvider from "./gitlab";
import PondProvider from "./pond/pond";
import SecurityProvider from "./security";

interface IHTTPContext {
  get: <T>(url: string, spreadOptions?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  put: <T>(
    url: string,
    data?: any,
    spreadOptions?: AxiosRequestConfig
  ) => Promise<AxiosResponse<T>>;
  post: <T>(
    url: string,
    data?: any,
    spreadOptions?: AxiosRequestConfig
  ) => Promise<AxiosResponse<T>>;
  del: <T>(url: string, spreadOptions?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  options: (demo?: boolean) => AxiosRequestConfig;
}

export const HTTPContext = createContext<IHTTPContext>({} as IHTTPContext);

export default function HTTPProvider(props: PropsWithChildren<any>) {
  const { children } = props;
  const { isAuthenticated, token } = useAuth();

  const defaultOptions = (demo: boolean = false) => {
    if (demo || !isAuthenticated || !token) {
      return {
        headers: {
          "Content-Type": "application/json"
        }
      };
    }

    return {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      }
    };
  };

  function get<T>(url: string, spreadOptions?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return axios.get(url, { ...defaultOptions(), ...spreadOptions });
  }

  function put<T>(
    url: string,
    data?: any,
    spreadOptions?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return axios.put(url, data, { ...defaultOptions(), ...spreadOptions });
  }

  function post<T>(
    url: string,
    data?: any,
    spreadOptions?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return axios.post(url, data, { ...defaultOptions(), ...spreadOptions });
  }

  function del<T>(url: string, spreadOptions?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return axios.delete(url, { ...defaultOptions(), ...spreadOptions });
  }

  return (
    <HTTPContext.Provider
      value={{
        get,
        put,
        post,
        del,
        options: defaultOptions
      }}>
      <BillingProvider>
        <PondProvider>
          <SecurityProvider>
            <GitlabProvider>{children}</GitlabProvider>
          </SecurityProvider>
        </PondProvider>
      </BillingProvider>
    </HTTPContext.Provider>
  );
}

export const useHTTP = () => useContext(HTTPContext);

//returns a string depending on the given start and end to be used in a url query
export function dateRange(start: any, end: any): string {
  start = moment(start).toISOString();
  end = moment(end).toISOString();

  var queryRange = "";
  if (
    typeof start !== "undefined" &&
    start !== null &&
    typeof end !== "undefined" &&
    end !== null
  ) {
    queryRange = "?start=" + start + "&end=" + end;
  } else if (typeof start !== "undefined" && start !== null) {
    queryRange = "?start=" + start;
  } else if (typeof end !== "undefined" && end !== null) {
    queryRange += "?end=" + end;
  }

  return queryRange;
}

import { ConfigurablePin } from "pbHelpers/AddressTypes";
import { DeviceAvailabilityMap, DevicePositions } from "pbHelpers/DeviceAvailability";
import { quack } from "protobuf-ts/pond";

const NexusSTPins: ConfigurablePin[] = [
  { address: 4, label: "1" },
  { address: 8, label: "2" },
  { address: 16, label: "3" },
  { address: 512, label: "C1" }
];

export const NexusSTAvailability: DeviceAvailabilityMap = new Map<
  quack.AddressType,
  DevicePositions
>([
  [quack.AddressType.ADDRESS_TYPE_INVALID, []],
  [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY, NexusSTPins],
  [
    quack.AddressType.ADDRESS_TYPE_I2C,
    new Map<quack.ComponentType, number[]>([
      [quack.ComponentType.COMPONENT_TYPE_PRESSURE, [0x18]],
      [quack.ComponentType.COMPONENT_TYPE_DHT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_VAPOUR_PRESSURE_DEFICIT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_LIDAR, [0x62]],
      [quack.ComponentType.COMPONENT_TYPE_SEN5X, [0x69]]
    ])
  ],
  [quack.AddressType.ADDRESS_TYPE_POWER, [0]],
  [quack.AddressType.ADDRESS_TYPE_GPS, [0]],
  [quack.AddressType.ADDRESS_TYPE_MODEM, [0]]
]);

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useDeviceAPI, useSnackbar } from "hooks";
import { Device } from "models";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      marginTop: theme.spacing(2)
    }
  })
);

interface Props {
  device: Device;
  isDialogOpen: boolean;
  closeDialogCallback: () => void;
  refreshCallback: () => void;
}

export default function SyncDevice(props: Props) {
  const classes = useStyles();
  const { success, error } = useSnackbar();
  const { device, isDialogOpen, closeDialogCallback, refreshCallback } = props;
  const deviceAPI = useDeviceAPI();

  const close = () => {
    closeDialogCallback();
  };

  const sync = () => {
    deviceAPI
      .sync(device.id())
      .then(() => {
        success("Device will sync with the current settings next time it checks in");
        close();
        refreshCallback();
      })
      .catch((err: any) => {
        console.error(err);
        error("Unable to resync device");
        close();
      });
  };

  return (
    <Dialog
      fullWidth
      open={isDialogOpen}
      onClose={closeDialogCallback}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Resync Device
        <Typography variant="body2" color="textSecondary">
          {device.name()}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-slide-description">
          Syncing the device will send all the current settings to the device the next time it
          checks in.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button onClick={sync} color="primary">
          Sync
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { cloneDeep } from "lodash";
import { MarkerData } from "Maps/mapMarkers/Markers";

export class Device {
  public settings: pond.DeviceSettings = pond.DeviceSettings.create();
  public status: pond.DeviceStatus = pond.DeviceStatus.create();

  public static create(pb?: pond.Device): Device {
    let my = new Device();
    if (pb) {
      my.settings = pond.DeviceSettings.fromObject(or(pb.settings, {}));
      my.status = pond.DeviceStatus.fromObject(or(pb.status, {}));
    }
    return my;
  }

  public static any(data: any): Device {
    if (data.device) return Device.create(pond.Device.fromObject(cloneDeep(data.device)));
    return Device.create(pond.Device.fromObject(cloneDeep(data)));
  }

  public static clone(other: Device): Device {
    let my = new Device();
    my.settings = pond.DeviceSettings.fromObject(cloneDeep(other.settings));
    my.status = pond.DeviceStatus.fromObject(cloneDeep(other.status));
    return my;
  }

  public id(): number {
    return Number(this.settings.deviceId);
  }

  public name(): string {
    return this.settings.name !== ""
      ? this.settings.name
      : this.id() > 0
      ? "Device " + this.id()
      : "";
  }

  public location(): pond.Location {
    let loc = pond.Location.create();
    loc.longitude = this.settings.longitude;
    loc.latitude = this.settings.latitude;
    return loc;
  }

  public getMarkerData(
    clickFunc?: (event: React.PointerEvent<HTMLElement>, index: number, isMobile: boolean) => void,
    updateFunc?: (location: pond.Location) => void
  ): MarkerData {
    let m: MarkerData = {
      longitude: this.location()?.longitude ?? 0,
      latitude: this.location()?.latitude ?? 0,
      title: this.name(),
      colour: this.settings.theme?.color ?? "blue",
      clickFunc: clickFunc,
      updateFunc: updateFunc
    };
    return m;
  }
}

import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  createStyles,
  makeStyles
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useMobile } from "hooks";
import moment from "moment";
import React, { useState } from "react";

interface Props {}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    banner: {
      width: "100%",
      minHeight: theme.spacing(7),
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    message: {
      textAlign: "center",
      marginRight: theme.spacing(1)
    },
    textContainer: {
      justifyContent: "center"
    },
    button: {
      position: "absolute",
      right: theme.spacing(0.5)
    }
  });
});

export default function NotificationBanner(props: Props) {
  const classes = useStyles();
  const isMobile = useMobile();

  const [open, setOpen] = useState(true);
  const [date] = useState(moment("2024-07-28 19:00:00").format("LLLL"));

  const close = () => {
    setOpen(false);
  };

  if (moment().isAfter(moment(date))) return null;

  if (!open) return null;

  return (
    <Box className={classes.banner}>
      <Grid
        spacing={2}
        container
        direction="row"
        className={classes.textContainer}
        justify="center">
        <Grid item alignContent="center">
          <Typography className={classes.message} variant="h4">
            <span role="img" aria-label="Default">
              📣
            </span>
          </Typography>
        </Grid>
        <Grid item alignContent="center">
          <Typography className={classes.message} variant="body2" color="textPrimary">
            Database maintenance - {isMobile ? moment(date).format("lll") : date}.
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Web services may be temporarily unavailable.
          </Typography>
        </Grid>
      </Grid>
      <IconButton onClick={close} className={classes.button}>
        <ClearIcon />
      </IconButton>
    </Box>
  );
}

import {
  AppBar,
  Box,
  createStyles,
  Drawer,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useTheme
} from "@material-ui/core";
import { Menu as MenuIcon, Chat as ChatIcon, Notifications } from "@material-ui/icons";
import classNames from "classnames";
import { useMobile, useThemeType } from "hooks";
import { useGlobalState } from "providers";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  getDarkLogo,
  getLightLogo,
  getSignatureAccentColour,
  hasTransparentLogoBG,
  hideLogo
} from "services/whiteLabel";
import UserMenu from "user/UserMenu";
import Chat from "chat/Chat";
import { pond } from "protobuf-ts/pond";
import moment from "moment-timezone";
import NotificationDrawer from "./NotificationDrawer";
import NewTeamNotification from "./NewTeamNotification";
import { Team } from "models";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    toolbar: {
      marginLeft: theme.spacing(0.5),
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(1.5)
      }
    },
    buttonContainer: {
      marginRight: theme.spacing(0.5),
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(1.5)
      }
    },
    button: {
      color: getSignatureAccentColour()
    },
    appBarRight: {
      display: "flex",
      flexDirection: "row",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2)
      }
    },
    logoContainer: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-start"
    },
    logoLink: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "5px",
      backgroundColor: hasTransparentLogoBG()
        ? ""
        : theme.palette.type === "light"
        ? "#fff"
        : "#323232"
    },
    HeaderLogo: {
      objectFit: "cover",
      height: "56px",
      width: "auto",
      padding: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        height: "64px"
      }
    },
    hide: {
      display: "none"
    },
    chatDrawer: {
      padding: theme.spacing(1),
      height: "100%",
      minWidth: theme.spacing(54),
      maxWidth: theme.spacing(54)
    },
    chatDrawerMobile: {
      padding: theme.spacing(1),
      height: "auto",
      width: "100%"
    },
    "@keyframes ripple": {
      from: {
        transform: "scale(.8)",
        opacity: 1
      },
      to: {
        transform: "scale(1.4)",
        opacity: 0
      }
    },
    on: {
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      animationName: "$ripple",
      animationDuration: "1.2s",
      animationTimingFunction: "ease-in-out",
      animationIterationCount: "infinite",
      position: "absolute",
      right: theme.spacing(0.5),
      top: theme.spacing(0.5)
    },
    off: {
      backgroundColor: "transparent"
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start"
    }
  });
});
interface Props {
  toggleTheme: () => void;
  sideIsOpen: boolean;
  openSide: () => void;
  teams: Team[];
  setTeams: React.Dispatch<React.SetStateAction<Team[]>>;
}

export default function Header(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const themeType = useThemeType();
  const isMobile = useMobile();
  const { toggleTheme, sideIsOpen, openSide, teams } = props;
  const [chatDrawerOpen, setChatDrawerOpen] = useState<boolean>(false);
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState<boolean>(false);
  const [{ user, team }] = useGlobalState();
  const hasTeams = user.hasFeature ? user.hasFeature("teams") : false;
  const [hasNewChats, setHasNewChats] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  // const [oldTeam, setOldTeam] = useState<Team | undefined>(undefined)

  // const keys = getContextKeys();
  // const types = getContextTypes();

  // useEffect(() => {
  //   // let keys = getContextKeys()
  //   // let types = getContextTypes()
  //   console.log(types)
  //   if (types.length > 1 && types[0] === "team") {
  //     console.log("Team!: " + keys[0])
  //   }
  // }, [keys, types])

  useEffect(() => {
    if (!team) return;
    if (
      team.preferences.chatViewedTimestamp.length < 1 &&
      team.settings.lastChatTimestamp.length > 1
    ) {
      setHasNewChats(true);
      return;
    }
    let viewMoment = moment(team.preferences.chatViewedTimestamp);
    let chatMoment = moment(team.settings.lastChatTimestamp);
    if (viewMoment.diff(chatMoment) < 0) {
      setHasNewChats(true);
    } else {
      setHasNewChats(false);
    }
  }, [team]);

  useEffect(() => {
    if (!user) return;
    if (!user.status) return;
    if (
      user.status.lastNotificationViewed.length < 1 &&
      user.status.lastNotifiedTimestamp.length > 1
    ) {
      setHasNewNotifications(true);
      return;
    }
    let viewMoment = moment(new Date(user.status.lastNotificationViewed));
    let notiMoment = moment(new Date(user.status.lastNotifiedTimestamp));
    if (viewMoment.diff(notiMoment) < 0) {
      setHasNewNotifications(true);
    } else {
      setHasNewNotifications(false);
    }
  }, [user]);

  // If the drawer has been opened, get rid of the ping
  useEffect(() => {
    if (notificationDrawerOpen) setHasNewNotifications(false);
  }, [notificationDrawerOpen]);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar disableGutters className={classes.toolbar}>
        <Box className={classes.buttonContainer}>
          {!isMobile && (
            <IconButton
              color="inherit"
              aria-label="Open side menu"
              onClick={openSide}
              className={classNames(classes.button, sideIsOpen && classes.hide)}>
              <MenuIcon />
            </IconButton>
          )}
        </Box>
        <Box flexGrow={1} marginLeft={0.5}>
          {!hideLogo() && (
            <div className={classes.logoContainer}>
              <Link to="/" className={classes.logoLink} aria-label="Home">
                <img
                  className={classes.HeaderLogo}
                  src={themeType === "light" ? getDarkLogo() : getLightLogo()}
                  alt={process.env.REACT_APP_WEBSITE_TITLE}
                />
              </Link>
            </div>
          )}
        </Box>
        <div className={classes.appBarRight}>
          <UserMenu toggleTheme={toggleTheme} />
          {hasTeams && team.settings.name && team.settings.key && (
            <div style={{ position: "relative", marginLeft: theme.spacing(1) }}>
              <Tooltip title="Team chat">
                <IconButton
                  style={{ marginLeft: theme.spacing(1) }}
                  onClick={() => {
                    setChatDrawerOpen(true);
                    setHasNewChats(false);
                  }}>
                  <ChatIcon />
                </IconButton>
              </Tooltip>

              {hasNewChats && (
                <Box
                  width="8px"
                  height="8px"
                  borderRadius="50%"
                  style={{ backgroundColor: theme.palette.primary.main }}
                  className={classes.on}
                />
              )}
            </div>
          )}
          {user.settings?.notificationMethods?.includes(
            pond.NotificationMethod.NOTIFICATION_METHOD_APP
          ) && (
            <div style={{ position: "relative", marginLeft: theme.spacing(-1) }}>
              <Tooltip title="Notifications">
                <IconButton
                  style={{ marginLeft: theme.spacing(1) }}
                  onClick={() => {
                    setNotificationDrawerOpen(true);
                  }}>
                  <Notifications />
                </IconButton>
              </Tooltip>
              {hasNewNotifications && (
                <Box
                  width="8px"
                  height="8px"
                  borderRadius="50%"
                  style={{ backgroundColor: theme.palette.primary.main }}
                  className={classes.on}
                />
              )}
            </div>
          )}
          {team && team.settings && team.settings.key && (
            <Drawer
              anchor="right"
              open={chatDrawerOpen}
              onClose={() => setChatDrawerOpen(false)}
              style={{ height: "100%", maxWidth: "100%" }}
              title={team.settings.name ? team.settings.name + "Chat" : "Chat"}>
              <div className={isMobile ? classes.chatDrawerMobile : classes.chatDrawer}>
                <div className={classes.drawerHeader}>
                  <IconButton onClick={() => setChatDrawerOpen(false)}>
                    <ChevronRightIcon />
                  </IconButton>
                  <Typography>{team.name()} Chat</Typography>
                </div>
                <Chat objectKey={team.settings.key} type={pond.NoteType.NOTE_TYPE_TEAM} />
              </div>
            </Drawer>
          )}
          <NotificationDrawer
            setNotificationDrawerOpen={setNotificationDrawerOpen}
            notificationDrawerOpen={notificationDrawerOpen}
          />
        </div>
      </Toolbar>
      <NewTeamNotification teams={teams} />
    </AppBar>
  );
}

import { pond } from "protobuf-ts/pond";
import { Option } from "common/SearchSelect";

interface GrainUseExtension {
  label: string;
  key: pond.GrainUse;
}

const DEFAULT_DESCRIBER = { label: "Unknown", key: pond.GrainUse.GRAIN_USE_UNKNOWN };

const getGrainUseMap = (): Map<pond.GrainUse, GrainUseExtension> => {
  return new Map([
    [pond.GrainUse.GRAIN_USE_UNKNOWN, DEFAULT_DESCRIBER],
    [pond.GrainUse.GRAIN_USE_SEED, { label: "Seed", key: pond.GrainUse.GRAIN_USE_SEED }],
    [
      pond.GrainUse.GRAIN_USE_COMMERCIAL,
      { label: "Commercial", key: pond.GrainUse.GRAIN_USE_COMMERCIAL }
    ],
    [pond.GrainUse.GRAIN_USE_FEED, { label: "Feed", key: pond.GrainUse.GRAIN_USE_FEED }]
  ]);
};

export const GrainUseDescriber = (use: pond.GrainUse): GrainUseExtension => {
  return getGrainUseMap().get(use) ?? DEFAULT_DESCRIBER;
};

export const ToGrainUseOption = (use: pond.GrainUse): Option => {
  let describer = GrainUseDescriber(use);
  return { label: describer.label, value: describer.key };
};

export const GetGrainUseOptions = (): Option[] => {
  let options: Option[] = [];
  Object.values(pond.GrainUse).forEach(grainUse => {
    if (typeof grainUse !== "string" && grainUse !== pond.GrainUse.GRAIN_USE_UNKNOWN) {
      options.push(ToGrainUseOption(grainUse));
    }
  });
  return options;
};

import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { or } from "utils";
import { pondURL } from "./pond";

export interface IFieldAPIContext {
  addField: (field: pond.FieldSettings) => Promise<any>;
  getField: (fieldId: string) => Promise<any>;
  listFields: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean
  ) => Promise<AxiosResponse<pond.ListFieldsResponse>>;
  removeField: (key: string) => Promise<AxiosResponse<pond.RemoveFieldResponse>>;
  updateField: (
    key: string,
    field: pond.FieldSettings,
    asRoot?: true
  ) => Promise<AxiosResponse<pond.UpdateSiteResponse>>;
}

export const FieldAPIContext = createContext<IFieldAPIContext>({} as IFieldAPIContext);

interface Props {}

export default function FieldProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();
  const [{ as }] = useGlobalState();

  const addField = (field: pond.FieldSettings) => {
    if (as) return post<pond.AddFieldResponse>(pondURL("/fields?as=" + as), field);
    return post(pondURL("/fields"), field);
  };

  const getField = (fieldId: string) => {
    if (as) return get(pondURL("/field/" + fieldId + "?as=" + as));
    return get(pondURL("/field/" + fieldId));
  };

  const removeField = (key: string) => {
    if (as) return del<pond.RemoveFieldResponse>(pondURL("/fields/" + key + "?as=" + as));
    return del<pond.RemoveFieldResponse>(pondURL("/fields/" + key));
  };

  const listFields = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean
  ) => {
    return get<pond.ListFieldsResponse>(
      pondURL(
        "/fields" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + or(order, "asc")) +
          ("&by=" + or(orderBy, "key")) +
          (as ? "&as=" + as : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (search ? "&search=" + search : "")
      )
    );
  };

  const updateField = (key: string, field: pond.FieldSettings, asRoot?: boolean) => {
    if (as)
      return put<pond.UpdateFieldResponse>(
        pondURL("/fields/" + key + "?as=" + as + (asRoot ? "&asRoot=" + asRoot.toString() : "")),
        field
      );
    return put<pond.UpdateFieldResponse>(
      pondURL("/fields/" + key + (asRoot ? "?asRoot=" + asRoot.toString() : "")),
      field
    );
  };

  return (
    <FieldAPIContext.Provider
      value={{
        addField,
        getField,
        listFields,
        removeField,
        updateField
      }}>
      {children}
    </FieldAPIContext.Provider>
  );
}

export const useFieldAPI = () => useContext(FieldAPIContext);

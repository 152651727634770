import { FilledInputProps, InputProps, OutlinedInputProps, TextField } from "@material-ui/core";
import React, { useState } from "react";

interface Props {
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  delayMS?: number;
  variant?: "standard" | "filled" | "outlined" | undefined;
  title?: string;
  size?: "small" | "medium" | undefined;
  inputProps?:
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | undefined;
  disabled?: boolean;
}

const DelayedTextField = (props: Props) => {
  const { value, onChange, delayMS, variant, title, size, inputProps, disabled } = props;

  const delay = delayMS ? delayMS : 1000;
  const [inputValue, setInputValue] = useState(value);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>(setTimeout(() => {}, 0));

  //const timeout = useRef(null); //global variable

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    clearTimeout(timeoutId); // Clear previous timeout
    const newValue = event.target.value;
    setInputValue(newValue);

    setTimeoutId(
      setTimeout(() => {
        onChange(newValue); // Update the value after the delay
      }, delay)
    );
  };

  return (
    <TextField
      value={inputValue}
      variant={variant}
      title={title}
      InputProps={inputProps}
      onChange={e => handleInputChange(e)}
      size={size}
      disabled={disabled}
    />
  );
};

export default DelayedTextField;

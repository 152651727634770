import React from "react";
import FirmwareIcon from "@material-ui/icons/Security";

export interface FirmwareVersionHelper {
  description: string;
  icon: any;
  tooltip: string;
  colour: string;
}

const oldFirmwareColour = "var(--status-warning)";
const currentFirmwareColour = "var(--status-ok)";

export function getFirmwareVersionHelper(
  version: string,
  available: string
): FirmwareVersionHelper {
  const firmwareIcon = <FirmwareIcon />;
  const oldFirmwareIcon = <FirmwareIcon style={{ color: oldFirmwareColour }} />;
  const currentFirmwareIcon = <FirmwareIcon style={{ color: currentFirmwareColour }} />;
  let helper = {} as FirmwareVersionHelper;
  if (!version || version === "") {
    helper.description = "Unknown version";
    helper.icon = oldFirmwareIcon;
    helper.tooltip = "We don't know what version your device is, it may behave unexpectedly";
  } else if (available !== "" && version !== available) {
    helper.description = version;
    helper.icon = oldFirmwareIcon;
    helper.tooltip =
      "A firmware upgrade is available, some features may be disabled for out-of-date devices";
  } else if (available === "") {
    helper.description = version;
    helper.icon = firmwareIcon;
    helper.tooltip = "Running version " + version;
  } else {
    helper.description = version;
    helper.icon = currentFirmwareIcon;
    helper.tooltip = "Your device is running the latest firmware";
  }
  return helper;
}

export function getFirmwareVersionColour(outdated: boolean): string {
  return outdated ? oldFirmwareColour : currentFirmwareColour;
}

import { DeviceProductDescriber } from "products/DeviceProduct";
import { pond } from "protobuf-ts/pond";
import { MiPCAV2Availability, OmniAirAvailability } from "./OmniAirAvailability";
import OmniAirLightIcon from "assets/products/Aviation/OmniAirDeviceIconWhite.png";
import OmniAirDarkIcon from "assets/products/Aviation/OmniAirDeviceIconBlack.png";

export const OmniAir: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_OMNIAIR,
  label: "MiPCA V1",
  icon: (_, theme?: "light" | "dark") => {
    return theme === "light" ? OmniAirDarkIcon : OmniAirLightIcon;
  },
  view: "controller",
  tabs: ["components"],
  availability: OmniAirAvailability,
  componentExtension: true
};

/*---V2 Stuff starts here---*/

export const MiPCAV2: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_MIPCA_V2,
  label: "MiPCA V2",
  icon: (_, theme?: "light" | "dark") => {
    return theme === "light" ? OmniAirDarkIcon : OmniAirLightIcon;
  },
  view: "controller",
  tabs: ["components"],
  availability: MiPCAV2Availability,
  componentExtension: true
};

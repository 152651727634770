import { cloneDeep } from "lodash";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

export class HarvestPlan {
  public settings: pond.HarvestPlanSettings = pond.HarvestPlanSettings.create();
  public status: pond.HarvestPlanStatus = pond.HarvestPlanStatus.create();
  public permissions: pond.Permission[] = [];

  public static create(pb?: pond.HarvestPlan): HarvestPlan {
    let my = new HarvestPlan();
    if (pb) {
      my.settings = pond.HarvestPlanSettings.fromObject(cloneDeep(or(pb.settings, {})));
      my.status = pond.HarvestPlanStatus.fromObject(cloneDeep(or(pb.status, {})));
      my.permissions = pb.planPermissions;
    }
    return my;
  }

  public static clone(other?: HarvestPlan): HarvestPlan {
    if (other) {
      return HarvestPlan.create(
        pond.HarvestPlan.fromObject({
          settings: cloneDeep(other.settings),
          status: cloneDeep(other.status)
        })
      );
    }
    return HarvestPlan.create();
  }

  public static any(data: any): HarvestPlan {
    return HarvestPlan.create(pond.HarvestPlan.fromObject(cloneDeep(data)));
  }

  public key(): string {
    return this.settings.key;
  }

  public totalMaterialCost(): number {
    return (
      this.settings.seedMaterials +
      this.settings.preSeedMaterials +
      this.settings.postSeedMaterials +
      this.settings.harvestMaterials +
      this.settings.fallMaterials
    );
  }

  public totalEquipmentCost(): number {
    return (
      this.settings.seedEquipment +
      this.settings.preSeedEquipment +
      this.settings.postSeedEquipment +
      this.settings.harvestEquipment +
      this.settings.fallEquipment
    );
  }

  public field(): string {
    return this.settings.field;
  }

  public createDate(): number {
    return this.settings.createDate;
  }

  public harvestYear(): number {
    return this.settings.harvestYear;
  }

  public cropType(): pond.Grain {
    return this.settings.cropType;
  }

  public customCropType(): string {
    return this.settings.customGrain;
  }

  public grainType(): string {
    return this.settings.grainType;
  }

  public yieldTarget(): number {
    return this.settings.yieldTarget;
  }

  public actualYield(): number {
    return this.settings.actualYield;
  }

  //NOTE all cost are set as per acre
  public seedMaterialsCost(): number {
    return this.settings.seedMaterials;
  }

  public seedEquipmentCost(): number {
    return this.settings.seedEquipment;
  }

  public seedPerAcre(): number {
    return this.settings.seedMaterials + this.settings.seedEquipment;
  }

  public preSeedMaterialsCost(): number {
    return this.settings.preSeedMaterials;
  }

  public preSeedEquipmentCost(): number {
    return this.settings.preSeedEquipment;
  }

  public preSeedPerAcre(): number {
    return this.settings.preSeedMaterials + this.settings.preSeedEquipment;
  }

  public postSeedMaterialCost(): number {
    return this.settings.postSeedMaterials;
  }

  public postSeedEquipmentCost(): number {
    return this.settings.postSeedEquipment;
  }

  public postSeedPerAcre(): number {
    return this.settings.postSeedMaterials + this.settings.postSeedEquipment;
  }

  public harvestMaterialCost(): number {
    return this.settings.harvestMaterials;
  }

  public harvestEquipmentCost(): number {
    return this.settings.harvestEquipment;
  }

  public harvestPerAcre(): number {
    return this.settings.harvestMaterials + this.settings.harvestEquipment;
  }

  public fallMaterialCost(): number {
    return this.settings.fallMaterials;
  }

  public fallEquipmentCost(): number {
    return this.settings.fallEquipment;
  }

  public fallPerAcre(): number {
    return this.settings.fallMaterials + this.settings.fallEquipment;
  }

  public bushelPrice(): number {
    return this.settings.bushelPrice;
  }

  public taskIds(): string[] {
    return this.settings.taskIds;
  }
}

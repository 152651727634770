import * as save from "save-svg-as-png";
import { or } from "./types";
const jsonexport = require("jsonexport");

export function saveSVG(element: string, filename: string, options: Object) {
  save.saveSvgAsPng(document.getElementById(element), filename, options);
}

export function downloadFile(response: any, filename: any) {
  if (!response || !response.data) {
    return;
  }
  var blob = new Blob([response.data], { type: "application/octet-stream" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  setTimeout(function() {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }, 100);
}

export function downloadJSON(data: any, filename: string) {
  var link = document.createElement("a");
  link.href = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  setTimeout(function() {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }, 100);
}

export function downloadURL(url: any) {
  var link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  setTimeout(function() {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }, 100);
}

export function exportDataToCSV(filename: string, data: Array<any>) {
  if (!data || !data.length) {
    return;
  }

  jsonexport(data, function(err: any, csv: any) {
    if (err) return console.log(err);
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = or(filename, "generated") + ".csv";
    document.body.appendChild(link);
    link.click();
    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    }, 100);
  });
}

export function exportJSON(filename: string, data: Array<any>) {
  if (!data || !data.length) {
    return;
  }

  jsonexport(data, function(err: any, csv: any) {
    if (err) return console.log(err);
    var blob = new Blob([jsonexport], { type: "application/json;charset=utf-8;" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = or(filename, "generated") + ".json";
    document.body.appendChild(link);
    link.click();
    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    }, 100);
  });
}

import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  useTheme
} from "@material-ui/core";
import ResponsiveDialog from "common/ResponsiveDialog";
import CloseIcon from "@material-ui/icons/Close";
import { DataUsage } from "@material-ui/icons";
import { useDeviceAPI, useSnackbar, useUsageAPI } from "providers";
import moment from "moment";
import { or } from "utils";
import { Device } from "models";
import { Cell, Legend, Pie, PieChart } from "recharts";
import { grey, orange, red } from "@material-ui/core/colors";
import { getPrimaryColour } from "services/whiteLabel";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import StripeCardList from "billing/StripeCardList";
import { getContextKeys, getContextTypes } from "pbHelpers/Context";

interface ICustomToolip {
  active: any;
  payload: DataAmount[];
  label: any;
}

interface DataAmount {
  dataAmount: number;
  name: string;
  color: string;
}

interface Props {
  device: Device;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  bytesToday?: number;
}

export default function DeviceHologram(props: Props) {
  const { open, setOpen, device } = props;

  const usageAPI = useUsageAPI();
  const deviceAPI = useDeviceAPI();
  const theme = useTheme();
  const snackbar = useSnackbar();

  const [bytesToday, setBytesToday] = useState(props.bytesToday ? props.bytesToday : -1);
  const [bytesTodayLoading, setBytesTodayLoading] = useState(false);
  const [bytesMonth, setBytesMonth] = useState(-1);
  const [bytesMonthLoading, setBytesMonthLoading] = useState(false);
  const [bytesSinceRefill, setBytesSinceRefill] = useState(-1);
  const [bytesSinceLoading, setBytesSinceLoading] = useState(false);

  const [usage, setUsage] = useState<DataAmount[]>([]);

  const [dataSelector, setDataSelector] = useState("15");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [paymentMethodCheck, setPaymentMethodCheck] = useState(0);
  const [selectedCard, setSelectedCard] = useState("");
  // const [loadingCard, setLoadingCard] = useState(false);
  // const [defaultCard, setDefaultCard] = useState<any | undefined>(undefined);

  const loadingCard = false;
  const defaultCard = undefined;

  const bytesPerMonth = 2631579;

  const describeUsage = (bytes: number) => {
    let usageDesc = "";
    if (isNaN(bytes)) {
      usageDesc = "0 B";
    } else if (Math.abs(bytes) < 1000) {
      usageDesc = bytes + " B";
    } else if (Math.abs(bytes) < 1000000) {
      usageDesc = (bytes / 1000.0).toFixed(1) + " KB";
    } else if (Math.abs(bytes) < 1000000000) {
      usageDesc = (bytes / 1000000.0).toFixed(1) + " MB";
    } else {
      usageDesc = (bytes / 1000000000.0).toFixed(1) + " GB";
    }
    return usageDesc;
  };

  const [pieLabel, setPieLabel] = useState("");
  const [pieData, setPieData] = useState("");

  useEffect(() => {
    if (!open) return;
    if (device.id() < 1) return;
    setBytesMonthLoading(true);
    usageAPI
      .getUsage(
        device.id(),
        moment().subtract(1, "months"),
        false,
        getContextKeys(),
        getContextTypes()
      )
      .then(resp => {
        let usage = resp.data;
        let sessions: any[] = [];
        if (usage.sessions) {
          sessions = usage.sessions.filter((session: any) => {
            return moment(session.begin).isAfter(moment().subtract(1, "months"));
          });
        }
        let rCellularUsage = 0;
        sessions.forEach((session: any) => (rCellularUsage += Number(or(session.bytes, 0))));
        setBytesMonth(rCellularUsage);
      })
      .finally(() => {
        setBytesMonthLoading(false);
      });
  }, [usageAPI, device, open]);

  useEffect(() => {
    if (!open) return;
    if (device.id() < 1) return;
    setBytesTodayLoading(true);
    usageAPI
      .getUsage(
        device.id(),
        moment().subtract(1, "days"),
        false,
        getContextKeys(),
        getContextTypes()
      )
      .then(resp => {
        let usage = resp.data;
        let sessions: any[] = [];
        if (usage.sessions) {
          sessions = usage.sessions.filter((session: any) => {
            return moment(session.begin).isAfter(moment().subtract(1, "days"));
          });
        }
        let rCellularUsage = 0;
        sessions.forEach((session: any) => (rCellularUsage += Number(or(session.bytes, 0))));
        setBytesToday(rCellularUsage);
      })
      .finally(() => {
        setBytesTodayLoading(false);
      });
  }, [usageAPI, device, open]);

  useEffect(() => {
    if (!open) return;
    if (device.id() < 1) return;
    if (device.status.bytesTimestamp.length < 1) {
      setUsage([{ dataAmount: 500000, name: "No Data", color: grey[900] }]);
      return;
    }
    setBytesSinceLoading(true);
    usageAPI
      .getUsage(
        device.id(),
        moment(device.status.bytesTimestamp),
        false,
        getContextKeys(),
        getContextTypes()
      )
      .then(resp => {
        let usage = resp.data;
        let sessions: any[] = [];
        if (usage.sessions) {
          sessions = usage.sessions.filter((session: any) => {
            return moment(session.begin).isAfter(moment(device.status.bytesTimestamp));
          });
        }
        let rCellularUsage = 0;
        sessions.forEach((session: any) => (rCellularUsage += Number(or(session.bytes, 0))));
        let bytesUsed = rCellularUsage;
        let monthsSince = moment().diff(device.status.bytesTimestamp, "months");
        bytesUsed = bytesUsed + monthsSince * bytesPerMonth;
        setBytesSinceRefill(bytesUsed);
        setPieLabel("Remaining");
        if (device.status.bytes > bytesUsed) {
          setPieData(describeUsage(device.status.bytes - bytesUsed));
        } else {
          setPieData(describeUsage(0));
        }
      })
      .finally(() => {
        setBytesSinceLoading(false);
      });
  }, [usageAPI, device, open]);

  useEffect(() => {
    if (bytesSinceRefill === -1) return;
    let myColor = getPrimaryColour();
    let bytesUsed = bytesSinceRefill;
    if (bytesUsed / device.status.bytes > 0.75) myColor = orange[800];
    if (bytesUsed / device.status.bytes > 0.875) myColor = red[900];
    let remaining = bytesUsed < device.status.bytes ? device.status.bytes - bytesUsed : 0;
    setUsage([
      { dataAmount: bytesUsed, name: "Used", color: grey[800] },
      { dataAmount: remaining, name: "Remaining", color: myColor }
    ]);
  }, [bytesSinceRefill, setUsage, device.status.bytes]);

  // const CustomTooltip = ({ active, payload, label }: ICustomToolip) => {
  //   if (active) {
  //     return (
  //       <div className="custom-tooltip">
  //           <p className="label">{`${label} : ${payload[0].dataAmount}`}</p>
  //           <p className="desc">Anything you want can be displayed here.</p>
  //       </div>
  //     );
  //   }

  //   return null;
  // }

  const getTimeUntil = (bytesAdded?: number) => {
    let secondsSince = moment().unix() - moment(device.status.bytesTimestamp).unix();
    let bytes = bytesSinceRefill;
    let bytes_per_second = bytes / secondsSince;
    if (bytes_per_second === 0 || isNaN(bytes_per_second)) {
      secondsSince =
        moment().unix() -
        moment()
          .subtract(1, "day")
          .unix();
      bytes_per_second = bytesToday / secondsSince;
      let seconds2 = (device.status.bytes + (bytesAdded ? bytesAdded : 0)) / bytes_per_second;
      let timeUntil = moment()
        .subtract(1, "day")
        .add(seconds2, "seconds")
        .fromNow();
      return timeUntil;
    }
    let seconds2 = (device.status.bytes + (bytesAdded ? bytesAdded : 0)) / bytes_per_second;
    let timeUntil = moment(device.status.bytesTimestamp)
      .add(seconds2, "seconds")
      .fromNow();
    return timeUntil;
  };

  const getNewDataTotal = () => {
    let remaining = device.status.bytes - bytesSinceRefill;
    let newTotal = remaining + parseInt(dataSelector) * 1000000.0;
    return newTotal;
  };

  const getCost = () => {
    let MB = parseInt(dataSelector);
    return (MB * 0.38 * 1.25).toFixed(2);
  };

  const buyData = () => {
    let MB = parseInt(dataSelector);
    let card = selectedCard ? selectedCard : undefined;
    deviceAPI
      .buyData(device.id(), MB, card, getContextKeys(), getContextTypes())
      .then(resp => {
        snackbar.success(dataSelector + " MB successfully added to " + device.name());
      })
      .catch(err => {
        if (err["response"]["data"]["error"].includes("missing a payment method")) {
          let msg = "No payment method. Please add it in billing settings";
          snackbar.error(msg);
        } else {
          snackbar.error(err["response"]["data"]["error"]);
        }
      })
      .finally(() => {
        setConfirmDialog(false);
      });
  };

  if (!open) return null;

  return (
    <ResponsiveDialog open={open} onClose={() => setOpen(false)} fullWidth>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close">
            <CloseIcon />
          </IconButton>
          <Icon style={{ marginLeft: theme.spacing(2) }}>
            <DataUsage />
          </Icon>
          <DialogTitle style={{ marginLeft: theme.spacing(-1) }}>
            Data Usage {device.name().length > 0 ? " - " + device.name() : ""}
          </DialogTitle>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid
          container
          direction="row"
          style={{ margin: theme.spacing(-2), marginLeft: theme.spacing(2) }}
          justify="space-around"
          alignItems="center"
          wrap="nowrap">
          <Grid item zeroMinWidth>
            <DialogContentText>
              Last 24/h: {bytesTodayLoading ? "Loading..." : describeUsage(bytesToday)}
            </DialogContentText>
            <DialogContentText>
              Last Month: {bytesMonthLoading ? "Loading..." : describeUsage(bytesMonth)}
            </DialogContentText>
            <DialogContentText>
              Since Refill:{" "}
              {device.status.bytesTimestamp.length < 1
                ? "N/A"
                : bytesSinceLoading
                ? "Loading..."
                : describeUsage(bytesSinceRefill)}
            </DialogContentText>
            <br />
            <DialogContentText style={{ overflowWrap: "break-word" }}>
              {device.status.bytesTimestamp.length < 1
                ? "This device will soon be paused. Please purchase data to continue using cellular device."
                : "Expires: " + getTimeUntil()}
            </DialogContentText>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <Grid container direction="column" alignItems="center" justify="center">
                  <Grid item xs>
                    {!bytesSinceLoading ? (
                      <PieChart width={275} height={275}>
                        <Legend
                          verticalAlign="middle"
                          content={() => (
                            <Box textAlign="center" marginY={0.5}>
                              <Typography>
                                {device.status.bytesTimestamp.length < 1
                                  ? "No Data"
                                  : bytesSinceLoading
                                  ? "Loading..."
                                  : pieLabel}
                              </Typography>
                              {device.status.bytesTimestamp.length > 1 && (
                                <Typography>{pieData}</Typography>
                              )}
                            </Box>
                          )}
                        />
                        <Pie
                          data={usage}
                          dataKey="dataAmount"
                          nameKey="name"
                          innerRadius={60}
                          outerRadius={100}
                          cornerRadius={6}>
                          {usage.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={entry.color}
                              onMouseOver={() => {
                                setPieLabel(entry.name);
                                setPieData(describeUsage(entry.dataAmount));
                              }}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    ) : (
                      <PieChart width={275} height={275}></PieChart>
                    )}
                  </Grid>
                  {/* <Grid item >
                    hi
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Box style={{ margin: theme.spacing(2) }} alignItems={"center"}>
          <Typography variant="h5">Purchase Data</Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ marginTop: theme.spacing(1) }}>
            Data will be added to your current data pool of{" "}
            {describeUsage(device.status.bytes - bytesSinceRefill)}. Active devices are charged a
            small fee monthly, which is taken from this devices data pool and is accounted for in
            the time remaining estimate.
          </Typography>
          <br />
          <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid item>
              <Typography>Select amount:</Typography>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <ToggleButtonGroup
                    value={dataSelector}
                    exclusive
                    onChange={(event, newValue) => {
                      setDataSelector(newValue);
                    }}>
                    <ToggleButton value={"15"}>15 MB</ToggleButton>
                    <ToggleButton value={"50"}>50 MB</ToggleButton>
                    <ToggleButton value={"100"}>100 MB</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                  <Box width={150}>
                    <TextField fullWidth value={dataSelector + " MB"} disabled variant="outlined" />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography>=</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography>${getCost()}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2" color="textSecondary">
                        {describeUsage(getNewDataTotal())} expires{" "}
                        {getTimeUntil(parseInt(dataSelector) * 1000000)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => setConfirmDialog(true)}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <ResponsiveDialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogTitle>
          <Typography>Confirm ${getCost()} Purchase</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" color="textSecondary">
            You are about to purchase {dataSelector} MB of data, which will expire{" "}
            {getTimeUntil(parseInt(dataSelector) * 1000000).replace("in", "in approximately")}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentMethodCheck === 0}
                  onChange={() => {
                    setSelectedCard("");
                    setPaymentMethodCheck(0);
                  }}
                />
              }
              label="Use default payment method"
            />
            {loadingCard && <CircularProgress />
            // : (
            //   paymentMethodCheck === 0 && (
            //     <Typography variant="subtitle2" color="textSecondary" style={{ marginLeft: 36 }}>
            //       {!defaultCard
            //         ? "Default Card undefined"
            //         : defaultCard.brand +
            //           ", **** " +
            //           defaultCard.last4 +
            //           ", exp: " +
            //           defaultCard.exp_month +
            //           " / " +
            //           defaultCard.exp_year}
            //     </Typography>
            //   )
            // )
            }
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentMethodCheck === 1}
                  onChange={() => setPaymentMethodCheck(1)}
                />
              }
              label="Choose payment method"
            />
          </FormGroup>

          {paymentMethodCheck === 1 && (
            <StripeCardList
              selectMode
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              defaultSource={defaultCard}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog(false)}>Cancel</Button>
          <Button onClick={buyData}>Confirm</Button>
        </DialogActions>
      </ResponsiveDialog>
    </ResponsiveDialog>
  );
}

import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography
} from "@material-ui/core";
import ResponsiveDialog from "common/ResponsiveDialog";
import Tour from "common/Tour";
import { Team } from "models/Team";
import { useGlobalState, useSnackbar, useUserAPI } from "providers";
import React, { useState } from "react";
import Emoji from "react-emoji-render";
import { Step } from "react-joyride";
import { Link } from "react-router-dom";

interface Props {
  teams: Team[];
}

export default function NewTeamNotification(props: Props) {
  const { teams } = props;
  const [remember, setRemember] = useState(false);
  const [enabling, setEnabling] = useState(false);
  const [closed, setClosed] = useState(false);
  const [{ user }, dispatch] = useGlobalState();
  const { error, success } = useSnackbar();
  const [isTourRunning, setIsTourRunning] = useState(true);
  const userAPI = useUserAPI();

  const endTour = () => {
    setIsTourRunning(false);
    localStorage.setItem("tourComeplete", "true");
  };

  const getTourOver = () => {
    if (localStorage.getItem("tourComeplete") === "true") return true;
    return false;
  };

  const getIgnoreForever = () => {
    if (localStorage.getItem("ignoreTeam") === "true") return true;
    return false;
  };

  const ignore = () => {
    if (!remember) {
      setClosed(true);
      return;
    }

    localStorage.setItem("ignoreTeam", "true");
    setClosed(false);
  };

  const enable = () => {
    setEnabling(true);
    user.settings.features.push("teams");
    userAPI
      .updateUser(user.id(), user.protobuf())
      .then(resp => {
        success("Enabled teams");
        dispatch({ key: "user", value: user });
      })
      .catch(err => {
        error("Failed to enable teams");
      })
      .finally(() => {
        setEnabling(false);
        setClosed(true);
      });
  };

  if (!user.hasFeature("teams") && teams.length > 0 && !getIgnoreForever())
    return (
      <ResponsiveDialog open={!closed} onClose={() => setClosed(true)}>
        <DialogTitle>
          <Typography variant="h6" style={{ flex: 1 }}>
            You've Been Added to a Team!
          </Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            It looks like you've been added to a team, but don't have the teams feature enabled.
            Would you like to enable it now?
            <br />
            <br />
            Utilizing a team will allow you to communicate with other team members through a chat
            menu. It will also give you the option to view the site from the team's perspective.
            This means that you can choose to see devices, bins, etc... that belong to the team,
            rather than your own. This feature can be toggled on/off in the user menu.
            <br />
            <br />
            You can also enable this feature yourself manually in the{" "}
            <Link to="/userFeatures">feature marketplace</Link>
            (dont worry, it's free!). You can also disable the feature from the same page.
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={remember}
                onChange={(e, checked) => {
                  setRemember(checked);
                }}
              />
            }
            label={<Typography>Don't remind me again</Typography>}
          />
        </DialogContent>

        <DialogActions>
          {!enabling && <Button onClick={ignore}>Ignore</Button>}
          {enabling ? (
            <CircularProgress />
          ) : (
            <Button onClick={enable} color="primary">
              Enable Teams
            </Button>
          )}
        </DialogActions>
      </ResponsiveDialog>
    );

  if (user.settings.defaultTeam.length > 0 || getTourOver()) return null;

  const getTourSteps = (): Step[] => {
    let steps: Step[] = [
      {
        title: (
          <React.Fragment>
            Teams
            <Emoji text=" 🎉" />
          </React.Fragment>
        ),
        content: (
          <React.Fragment>
            <Typography variant="subtitle2" gutterBottom>
              {"Hello " + user.name()}
              <Emoji text=" 👋" />
            </Typography>
            <Typography variant="body2" paragraph>
              Visit the teams page to view your teams and create new ones.
            </Typography>
          </React.Fragment>
        ),
        target: "#tour-teams",
        placement: "right",
        disableBeacon: true
      },
      {
        title: (
          <React.Fragment>
            Using Teams
            <Emoji text=" 🎉" />
          </React.Fragment>
        ),
        content: (
          <React.Fragment>
            <Typography variant="body2" paragraph>
              Select a default team in the user menu.
            </Typography>
          </React.Fragment>
        ),
        target: "#tour-user-menu",
        placement: "bottom",
        disableBeacon: true
      },
      {
        title: (
          <React.Fragment>
            Using Teams
            <Emoji text=" 🎉" />
          </React.Fragment>
        ),
        content: (
          <React.Fragment>
            <Typography variant="body2" paragraph>
              Once you've selected a team, you can view the site as the team by checking that option
              in the same menu.
            </Typography>
          </React.Fragment>
        ),
        target: "#tour-user-menu",
        placement: "bottom",
        disableBeacon: true
      }
    ];
    return steps;
  };

  return <Tour run={isTourRunning} steps={getTourSteps()} endTourCallback={endTour} />;
}

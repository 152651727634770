import White from "assets/products/Aviation/AirportMapWhite.png";
import Black from "assets/products/Aviation/AirportMapBlack.png";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  width?: number;
  height?: number;
  type?: "light" | "dark";
}

export default function AirportMapIcon(props: Props) {
  const themeType = useThemeType();
  const { type, width, height } = props;

  const src = () => {
    if (type) {
      return type === "light" ? White : Black;
    }

    return themeType === "light" ? Black : White;
  };

  return <img alt="airportMap" src={src()} width={width ?? 25} height={height ?? 25} />;
}

import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  createStyles,
  FormControlLabel,
  //FormControlLabel,
  Grid,
  makeStyles,
  //Switch,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import { ZoomOut } from "@material-ui/icons";
import { GetDefaultDateRange } from "common/time/DateRange";
import TimeBar from "common/time/TimeBar";
import GrainDescriber from "grain/GrainDescriber";
import { useMobile, useThemeType } from "hooks";
import { Bin, Component } from "models";
import { GrainCable } from "models/GrainCable";
import { Plenum } from "models/Plenum";
import { Pressure } from "models/Pressure";
import { UnitMeasurement } from "models/UnitMeasurement";
import moment, { Moment } from "moment";
import { pond } from "protobuf-ts/pond";
import { useBinAPI, useGlobalState } from "providers";
import React, { useEffect, useState } from "react";
import { stringToMaterialColour } from "utils";
import BinGraphsTrending from "./BinGraphsTrending";
import BinGraphsVPD from "./BinGraphsVPD";
import BinLevelOverTime from "./BinLevelOverTime";
import BinWaterLevel from "./BinWaterLevel";
import VPDLight from "assets/products/Ag/dryingLight.png";
import VPDDark from "assets/products/Ag/dryingDark.png";
import WaterLight from "assets/products/Ag/waterContentLight.png";
import WaterDark from "assets/products/Ag/waterContentDark.png";
import TrendLight from "assets/products/Ag/trendingLight.png";
import TrendDark from "assets/products/Ag/trendingDark.png";
import { GrainDryingPoint } from "charts/GrainDryingChart";
import { blue, orange, teal } from "@material-ui/core/colors";
import { DataPoint, TrendPoint } from "charts/TrendingChart";
import { DataPoint as WaterPoint } from "charts/WaterLevelChart";
import { cloneDeep } from "lodash";
import { Controller } from "models/Controller";
import BinComponentGraph from "./BinComponentGraph";
import BinCompositionGraph from "./BinCompositionGraph";

interface Props {
  bin: Bin;
  plenums: Plenum[];
  cables: GrainCable[];
  pressures: Pressure[];
  fans: Controller[];
  componentDevices: Map<string, number>;
  compositionNameMap: Map<string, string>;
  compMap: Map<string, Component>;
  binLoading: boolean;
  display: "inventory" | "sensors" | "analytics";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      position: "relative",
      display: "flex",
      height: "100%",
      flexDirection: "column",
      overflow: "visible"
    },
    cardHeader: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      marginRight: 10
    },
    avatarIcon: {
      width: 33,
      height: 33
    }
  })
);

export default function BinGraphs(props: Props) {
  const {
    bin,
    plenums,
    cables,
    pressures,
    binLoading,
    display,
    componentDevices,
    compositionNameMap,
    compMap,
    fans
  } = props;
  // map using the component key and the unitmeasurements for that component
  const [compMeasurements, setCompMeasurements] = useState<Map<string, UnitMeasurement[]>>(
    new Map<string, UnitMeasurement[]>()
  );
  const defaultDateRange = GetDefaultDateRange();
  const [startDate, setStartDate] = useState<Moment>(defaultDateRange.start);
  const [endDate, setEndDate] = useState<Moment>(defaultDateRange.end);
  const binAPI = useBinAPI();
  const [xDomain, setXDomain] = useState<string[] | number[]>(["dataMin", "dataMax"]);
  const [zoomed, setZoomed] = useState(false);
  const themeType = useThemeType();
  const classes = useStyles();
  const [recentVPD, setRecentVPD] = useState<GrainDryingPoint | undefined>();
  const [recentPlenumTrend, setRecentPlenumTrend] = useState<TrendPoint | undefined>();
  const [recentCableTrend, setRecentCableTrend] = useState<DataPoint | undefined>();
  const [recentWaterContent, setRecentWaterContent] = useState<WaterPoint | undefined>();
  const [{ user }] = useGlobalState();
  const [measurementsLoading, setMeasurementsLoading] = useState(false);
  const isMobile = useMobile();
  const [{ showErrors }, dispatch] = useGlobalState();
  //const [includeCFM, setIncludeCFM] = useState(false);

  const zoomOut = () => {
    setXDomain(["dataMin", "dataMax"]);
    setZoomed(false);
  };

  const zoomIn = (domain: string[] | number[]) => {
    if (!isMobile) {
      setXDomain(domain);
      setZoomed(true);
    }
  };

  useEffect(() => {
    let measurementMap: Map<string, UnitMeasurement[]> = new Map<string, UnitMeasurement[]>();
    if (display !== "inventory" && !measurementsLoading) {
      setMeasurementsLoading(true);
      //check if the bin has a fan to use the cfm in the drying graph
      // if (bin.settings.fan?.type !== pond.FanType.FAN_TYPE_UNKNOWN) {
      //   setIncludeCFM(true);
      // }
      binAPI
        .listBinComponentsMeasurements(
          bin.key(),
          startDate.toISOString(),
          endDate.toISOString(),
          showErrors
        )
        .then(resp => {
          resp.data.measurements.forEach((um: any) => {
            let unitMeasurement = UnitMeasurement.any(um, user);
            let entry = measurementMap.get(unitMeasurement.componentId);
            if (entry) {
              entry.push(unitMeasurement);
            } else {
              measurementMap.set(unitMeasurement.componentId, [unitMeasurement]);
            }
          });
          setCompMeasurements(measurementMap);
          setMeasurementsLoading(false);
        });
    }
  }, [bin, binAPI, startDate, endDate, display, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateDateRange = (newStartDate: any, newEndDate: any) => {
    let range = GetDefaultDateRange();
    range.start = newStartDate;
    range.end = newEndDate;
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const determineGrainColour = () => {
    let col = "yellow";
    let binInv = bin.settings.inventory;
    if (binInv) {
      if (binInv.grainType === pond.Grain.GRAIN_CUSTOM) {
        col = stringToMaterialColour(binInv.customTypeName);
      } else if (binInv.grainType !== pond.Grain.GRAIN_NONE) {
        col = GrainDescriber(binInv.grainType).colour;
      }
    }
    return col;
  };

  const inventoryGraph = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BinLevelOverTime
            customHeight={575}
            startDate={startDate}
            endDate={endDate}
            binLoading={binLoading}
            bin={bin}
            colour={determineGrainColour()}
            fertilizerBin={bin.settings.storage === pond.BinStorage.BIN_STORAGE_FERTILIZER}
          />
        </Grid>
        {user.hasFeature("grain-composition") && (
          <Grid item xs={12}>
            <BinCompositionGraph bin={bin} nameMap={compositionNameMap} />
          </Grid>
        )}
      </Grid>
    );
  };

  //will use the compMeasurments to show all the graphs for all connected sensors, as it goes through the plenums/pressures/cables it will remove them from
  //the temp measurments map so that it will show the remaining components at the bottom
  const sensorGraphs = () => {
    let toShow = cloneDeep(compMeasurements);
    return (
      <Box>
        {plenums.map(p => {
          let measurements = toShow.get(p.key());
          toShow.delete(p.key());
          return (
            <BinComponentGraph
              key={p.key()}
              component={p.asComponent()}
              isLoading={measurementsLoading}
              measurements={measurements ?? []}
              xDomain={xDomain}
              zoomIn={zoomIn}
            />
          );
        })}
        {pressures.map(p => {
          let measurements = toShow.get(p.key());
          toShow.delete(p.key());
          return (
            <BinComponentGraph
              key={p.key()}
              component={p.asComponent()}
              isLoading={measurementsLoading}
              measurements={measurements ?? []}
              xDomain={xDomain}
              zoomIn={zoomIn}
            />
          );
        })}
        {cables.map(c => {
          let measurements = toShow.get(c.key());
          toShow.delete(c.key());
          return (
            <BinComponentGraph
              key={c.key()}
              component={c.asComponent()}
              isLoading={measurementsLoading}
              measurements={measurements ?? []}
              xDomain={xDomain}
              zoomIn={zoomIn}
            />
          );
        })}
        {Array.from(toShow.values()).map((compMeasurement, i) => {
          let c = Component.create();
          if (compMeasurement[0]) {
            c = compMap.get(compMeasurement[0].componentId) ?? Component.create();
          }
          return (
            <BinComponentGraph
              key={c.key()}
              component={c}
              isLoading={measurementsLoading}
              measurements={compMeasurement}
              xDomain={xDomain}
              zoomIn={zoomIn}
            />
          );
        })}
      </Box>
    );
  };

  const waterGraph = () => {
    let moistureCables: GrainCable[] = [];
    cables.forEach(cable => {
      if (cable.humidities.length > 0) {
        moistureCables.push(cable);
      }
    });
    if (plenums[0] && moistureCables[0] && pressures[0] && bin.supportedGrain()) {
      return (
        <Box>
          <BinWaterLevel
            bin={bin}
            range={{ start: startDate, end: endDate }}
            plenumKey={componentDevices.get(plenums[0].key()) + ":" + plenums[0].key()}
            cableKey={componentDevices.get(moistureCables[0].key()) + ":" + moistureCables[0].key()}
            pressureKey={componentDevices.get(pressures[0].key()) + ":" + pressures[0].key()}
            fanKey={
              fans.length > 0
                ? componentDevices.get(fans[0].key()) + ":" + fans[0].key()
                : undefined
            }
            newXDomain={xDomain}
            multiGraphZoom={zoomIn}
            multiGraphZoomOut
            returnLast={lastWater => {
              setRecentWaterContent(lastWater);
            }}
          />
        </Box>
      );
    } else {
      return (
        <Box minHeight={100} display="flex" justifyContent="center" alignItems="center">
          <Typography style={{ fontWeight: 650 }}>
            Plenum with pressure and moisture cable as well as an initial moisture and supported
            grain type set in the bin settings required to calculate Water Content
          </Typography>
        </Box>
      );
    }
  };

  const analyticGraphs = () => {
    return (
      <Box>
        <Card raised style={{ padding: 10, marginBottom: 15 }}>
          <CardHeader
            avatar={
              <Avatar
                variant="square"
                src={themeType === "light" ? VPDDark : VPDLight}
                className={classes.avatarIcon}
                alt={"Drying Score"}
              />
            }
            title={
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography style={{ fontSize: 25, fontWeight: 650 }}>
                    Drying vs. Hydrating
                  </Typography>
                </Grid>
                {/* {bin.settings.fan?.type !== pond.FanType.FAN_TYPE_UNKNOWN && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={includeCFM}
                          onChange={(_, checked) => {
                            setIncludeCFM(checked);
                          }}
                          name="include CFM"
                        />
                      }
                      label="Include CFM"
                      labelPlacement="start"
                    />
                  </Grid>
                )} */}
              </Grid>
            }
            subheader={
              recentVPD ? (
                <Grid container>
                  <Grid item xs={12}>
                    <span>
                      <span style={{ color: recentVPD.dryScore > 0 ? orange[500] : blue[500] }}>
                        {recentVPD.dryScore > 0 ? "drying" : "hydrating"}
                      </span>
                      <span>{" : "}</span>
                      <span
                        style={{
                          color: recentVPD.dryScore > 0 ? orange[500] : blue[500],
                          fontWeight: 500
                        }}>
                        {recentVPD.dryScore.toFixed(2)}
                      </span>
                      <br />
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant={"caption"}>
                      {moment(recentVPD.timestamp).fromNow()}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography variant="body1" color="textPrimary">
                  No Data
                </Typography>
              )
            }
          />
          {cables.length > 0 && plenums.length > 0 ? (
            <BinGraphsVPD
              cables={cables}
              plenums={plenums}
              measurementMap={compMeasurements}
              newXDomain={xDomain}
              multiGraphZoom={zoomIn}
              multiGraphZoomOut
              //perBushelCFM={includeCFM ? bin.status.cfmPerBushel : undefined}
              returnLastVPD={lastVPD => {
                setRecentVPD(lastVPD);
              }}
            />
          ) : (
            <Box minHeight={100} display="flex" justifyContent="center" alignItems="center">
              <Typography style={{ fontWeight: 650 }}>
                Plenum and moisture cable required to calculate VPD
              </Typography>
            </Box>
          )}
        </Card>
        <Card raised style={{ padding: 10, marginBottom: 15 }}>
          <CardHeader
            avatar={
              <Avatar
                variant="square"
                src={themeType === "light" ? TrendDark : TrendLight}
                className={classes.avatarIcon}
                alt={"VPD"}
              />
            }
            title={
              <Typography style={{ fontSize: 25, fontWeight: 650 }}>Moisture Trending</Typography>
            }
            subheader={
              recentPlenumTrend && recentCableTrend ? (
                <Grid container>
                  <Grid item xs={12}>
                    <span>
                      {"Plenum Moisture: "}
                      <span style={{ color: orange[500], fontWeight: 500 }}>
                        {recentPlenumTrend.trend.toFixed(2)}
                      </span>
                      <br />
                    </span>
                    <span>
                      {"Grain Moisture: "}
                      <span style={{ color: teal[500], fontWeight: 500 }}>
                        {recentCableTrend.moisture.toString()}
                      </span>
                      <br />
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant={"caption"}>
                      {recentPlenumTrend.timestamp > recentCableTrend.timestamp
                        ? moment(recentPlenumTrend.timestamp).fromNow()
                        : moment(recentCableTrend.timestamp).fromNow()}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography variant="body1" color="textPrimary">
                  No Data
                </Typography>
              )
            }
          />
          {cables.length > 0 && plenums.length > 0 ? (
            <BinGraphsTrending
              cables={cables}
              plenums={plenums}
              measurementMap={compMeasurements}
              bin={bin}
              newXDomain={xDomain}
              multiGraphZoom={zoomIn}
              multiGraphZoomOut
              returnLastMeasurements={(lastData, lastTrend) => {
                setRecentCableTrend(lastData);
                setRecentPlenumTrend(lastTrend);
              }}
            />
          ) : (
            <Box minHeight={100} display="flex" justifyContent="center" alignItems="center">
              <Typography style={{ fontWeight: 650 }}>
                Plenum and moisture cable required to calculate trending data
              </Typography>
            </Box>
          )}
        </Card>
        <Card raised style={{ padding: 10 }}>
          <CardHeader
            avatar={
              <Avatar
                variant="square"
                src={themeType === "light" ? WaterDark : WaterLight}
                className={classes.avatarIcon}
                alt={"VPD"}
              />
            }
            title={
              <Typography style={{ fontSize: 25, fontWeight: 650 }}>Grain Water Content</Typography>
            }
            subheader={
              recentWaterContent &&
              bin.settings.inventory &&
              bin.settings.inventory.initialMoisture > 0 ? (
                <Grid container>
                  <Grid item xs={12}>
                    <span>
                      {"Water Content: "}
                      <span style={{ color: blue[500], fontWeight: 500 }}>
                        {recentWaterContent.liters && !isNaN(recentWaterContent.liters)
                          ? recentWaterContent.liters.toFixed(2)
                          : ""}
                      </span>
                      <br />
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant={"caption"}>
                      {moment(recentWaterContent.timestamp).fromNow()}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography variant="body1" color="textPrimary">
                  No Data
                </Typography>
              )
            }
          />
          {waterGraph()}
        </Card>
      </Box>
    );
  };

  const showGraphs = () => {
    switch (display) {
      case "inventory":
        return inventoryGraph();
      case "sensors":
        return sensorGraphs();
      case "analytics":
        return analyticGraphs();
      default:
        return <Box>Unknown Graph Type Selected</Box>;
    }
  };

  return (
    <React.Fragment>
      <Grid container justify="space-between">
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
          <TimeBar updateDateRange={updateDateRange} startDate={startDate} endDate={endDate} />
          {display !== "inventory" && zoomed && (
            <Tooltip title="Zoom Out Graphs">
              <Button variant="outlined" onClick={zoomOut}>
                <ZoomOut />
              </Button>
            </Tooltip>
          )}
        </Box>
        <Grid item>
          <FormControlLabel
            label="Show Errors"
            control={
              <Checkbox
                checked={showErrors}
                onChange={() => {
                  //setShowErrors(!showErrors);
                  dispatch({ key: "showErrors", value: !showErrors });
                }}
              />
            }
          />
        </Grid>
      </Grid>
      {showGraphs()}
    </React.Fragment>
  );
}

import { createStyles, List, ListItem, ListItemText } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React from "react";
import { Group } from "models";
import { pond } from "protobuf-ts/pond";

const styles = (theme: Theme) =>
  createStyles({
    listDirectionRow: {
      display: "flex",
      flexDirection: "row",
      padding: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    listDirectionColumn: {
      display: "flex",
      flexDirection: "column",
      padding: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    }
  });

interface Props extends WithStyles<typeof styles> {
  group: Group;
  devices?: pond.ComprehensiveDevice[];
  variant?: "row" | "column";
}

interface State {}

class GroupOverview extends React.Component<Props, State> {
  render() {
    const { classes, variant, group, devices } = this.props;
    const numDevices = devices?.length ? devices.length : 0;
    return (
      <List
        disablePadding
        dense
        className={variant === "row" ? classes.listDirectionRow : classes.listDirectionColumn}>
        {numDevices > 0 && (
          <ListItem>
            <ListItemText primary={"# of Devices: " + numDevices.toString()} />
          </ListItem>
        )}
        {group.settings.description && (
          <ListItem>
            <ListItemText primary="Description" secondary={group.settings.description} />
          </ListItem>
        )}
      </List>
    );
  }
}

export default withStyles(styles)(GroupOverview);

import { pond } from "protobuf-ts/pond";
import moment from "moment-timezone";
import { User } from "models";

export function userRoleFromPermissions(permissions: Array<pond.Permission>): string {
  //only be an owner if they have all 5
  if (
    permissions.includes(pond.Permission.PERMISSION_USERS) &&
    permissions.includes(pond.Permission.PERMISSION_READ) &&
    permissions.includes(pond.Permission.PERMISSION_WRITE) &&
    permissions.includes(pond.Permission.PERMISSION_SHARE) &&
    permissions.includes(pond.Permission.PERMISSION_FILE_MANAGEMENT)
  )
    return "Owner";
  if (permissions.includes(pond.Permission.PERMISSION_WRITE)) return "Admin";
  return "User";
}

export function sortUsersByRole(users: User[]): User[] {
  let sortedUsers: User[] = users.slice(0).sort((a: User, b: User) => {
    if (a.permissions.length === b.permissions.length) {
      let aName: string = a.name().toLowerCase();
      let bName: string = b.name().toLowerCase();
      return aName > bName ? 1 : bName > aName ? -1 : 0;
    }
    return a.permissions.length - b.permissions.length;
  });

  return sortedUsers;
}

export function defaultUser(): User {
  return User.any({
    settings: {
      notifyByDefault: true,
      notificationMethods: [pond.NotificationMethod.NOTIFICATION_METHOD_EMAIL],
      phoneNumber: "",
      timezone: moment.tz.guess()
    }
  });
}

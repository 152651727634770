import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  createStyles,
  Tooltip,
  Theme,
  Box
} from "@material-ui/core";
import ObjectTeamsIcon from "@material-ui/icons/SupervisedUserCircle";
import FieldsIcon from "products/AgIcons/FieldsIcon";
import React, { useState } from "react";
import DeviceIcon from "products/Bindapt/BindaptIcon";
import { Device, Scope } from "models";
import ObjectTeams from "teams/ObjectTeams";
import { pond } from "protobuf-ts/pond";
import { Link } from "@material-ui/icons";
import { useHistory } from "react-router";

interface Props {
  actions: JSX.Element;
  objectButton?: JSX.Element;
  mapFunction?: () => void;
  linkDeviceFunction?: () => void;
  notesButton?: JSX.Element;
  teamScope?: Scope;
  permissions?: pond.Permission[];
  devices?: Device[];
}

const useStyles = makeStyles((theme: Theme) => {
  const themeType = theme.palette.type;
  return createStyles({
    avatar: {
      color: themeType === "light" ? theme.palette.common.black : theme.palette.common.white,
      backgroundColor: "transparent",
      width: theme.spacing(5),
      height: theme.spacing(5),
      border: "1px solid",
      borderColor: theme.palette.divider
    }
  });
});

export default function ObjectControls(props: Props) {
  const {
    mapFunction,
    linkDeviceFunction,
    teamScope,
    permissions,
    actions,
    devices,
    notesButton,
    objectButton
  } = props;
  const [openTeams, setOpenTeams] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const goToDevice = (dev: Device) => {
    history.push("/devices/" + dev.id());
  };

  const dialogs = () => {
    return (
      <React.Fragment>
        {teamScope && permissions && (
          <ObjectTeams
            scope={teamScope}
            label="Teams"
            permissions={permissions}
            isDialogOpen={openTeams}
            refreshCallback={() => {}}
            closeDialogCallback={() => {
              setOpenTeams(false);
            }}
          />
        )}
      </React.Fragment>
    );
  };

  const deviceNavButton = () => {
    if (devices) {
      if (devices.length > 1) {
        return (
          <Tooltip key="devices" title="devices">
            <IconButton
              key="devButton"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setAnchorEl(event.currentTarget)
              }
              size="small"
              style={{
                marginTop: "0.3625rem",
                marginBottom: "0.3625rem",
                marginRight: "0.5rem"
              }}
              className={classes.avatar}
              component="span">
              <DeviceIcon />
            </IconButton>
          </Tooltip>
        );
      } else {
        return devices.map(dev => (
          <Tooltip key={dev.id()} title={dev.name()}>
            <IconButton
              key={dev.id()}
              onClick={() => goToDevice(dev)}
              size="small"
              style={{
                marginTop: "0.3625rem",
                marginBottom: "0.3625rem",
                marginRight: "0.5rem"
              }}
              className={classes.avatar}
              component="span">
              <DeviceIcon />
            </IconButton>
          </Tooltip>
        ));
      }
    }
  };

  const deviceMenu = () => {
    return (
      <Menu
        id="groupMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        keepMounted
        //classes={{ paper: classes.menuPaper }}
        disableAutoFocusItem>
        {devices &&
          devices.map(dev => (
            <MenuItem key={dev.id()} onClick={() => goToDevice(dev)}>
              <ListItemIcon>
                <DeviceIcon />
              </ListItemIcon>
              <ListItemText primary={dev.name()} />
            </MenuItem>
          ))}
      </Menu>
    );
  };

  return (
    <Box style={{ padding: 10 }}>
      {dialogs()}
      <Grid container direction="row" justify="space-between">
        {/* buttons on the left side start here */}
        <Grid item>
          <Grid container direction="row">
            {objectButton}
            {notesButton}
            {deviceNavButton()}
            {deviceMenu()}
            {mapFunction && (
              <Grid item>
                <IconButton
                  onClick={mapFunction}
                  size="small"
                  style={{
                    marginTop: "0.3625rem",
                    marginBottom: "0.3625rem",
                    marginRight: "0.5rem"
                  }}
                  className={classes.avatar}
                  component="span">
                  <FieldsIcon />
                </IconButton>
              </Grid>
            )}
            {teamScope && permissions && (
              <Grid item>
                <IconButton
                  onClick={() => {
                    setOpenTeams(true);
                  }}
                  size="small"
                  style={{
                    marginTop: "0.3625rem",
                    marginBottom: "0.3625rem",
                    marginRight: "0.5rem"
                  }}
                  className={classes.avatar}
                  component="span">
                  <ObjectTeamsIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* bottons on the right side start here */}
        <Grid item>
          {linkDeviceFunction && (
            <Tooltip title="Link Devices">
              <IconButton onClick={linkDeviceFunction}>
                <Link />
              </IconButton>
            </Tooltip>
          )}
          {actions}
        </Grid>
      </Grid>
    </Box>
  );
}

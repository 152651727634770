import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { cloneDeep } from "lodash";

export class Tag {
  public settings: pond.TagSettings = pond.TagSettings.create();

  public static create(pb?: pond.Tag): Tag {
    let my = new Tag();
    if (pb) {
      my.settings = pond.TagSettings.fromObject(cloneDeep(or(pb.settings, {})));
    }
    return my;
  }

  public static clone(other?: Tag): Tag {
    if (other) {
      return Tag.create(pond.Tag.fromObject({ settings: cloneDeep(other.settings) }));
    }
    return Tag.create();
  }

  public static any(data: any): Tag {
    return Tag.create(pond.Tag.fromObject(cloneDeep(data)));
  }

  public key(): string {
    return this.settings.key;
  }

  public name(): string {
    return this.settings.name !== "" ? this.settings.name : "Tag " + this.settings.key;
  }
}

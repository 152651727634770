import PestsLight from "assets/products/Ag/pestsLight.png";
import PestsDark from "assets/products/Ag/pestsDark.png";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  width: number;
  height: number;
  type?: "light" | "dark";
}

export default function PestsIcon(props: Props) {
  const themeType = useThemeType();
  const { width, height, type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? PestsLight : PestsDark;
    }

    return themeType === "light" ? PestsDark : PestsLight;
  };

  return <img width={width} height={height} alt="pestsIcon" src={src()} />;
}

import {
  red,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  yellow,
  amber,
  orange,
  deepOrange
} from "@material-ui/core/colors";

export function lowercaseStart(s: string): string {
  if (s === "" || s.length <= 0) return "";
  return s.charAt(0).toLowerCase() + s.substring(1);
}

export function capitalize(s: string): string {
  return s.replace(/\b\w/g, c => c.toUpperCase());
}

/**
 * Takes in a string and uses the first character of the frist word to determin colour and the first character of the second to determin shade
 * if a string with only one word is passed will use default shade, words past 2 are not used
 * @param s any string
 * @returns hex colour string
 */
export function stringToMaterialColour(s: string): string {
  const colours = [
    red,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    yellow,
    amber,
    orange,
    deepOrange
  ];
  const shades = [
    "50",
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "A100",
    "A200",
    "A400",
    "A700"
  ];
  let strings = s.split(" ");
  let colourVal = 0;
  let shadeVal = 5;
  if (strings[0]) {
    let val = Number(strings[0].charCodeAt(0));
    while (val > 13) {
      val = val - 13;
    }
    colourVal = val;
  }

  if (strings[1]) {
    let val = Number(strings[1].charCodeAt(0));
    while (val > 13) {
      val = val - 13;
    }
    shadeVal = val;
  }

  let colour: any = colours[colourVal];
  let shade: any = shades[shadeVal];
  return colour[shade];
}

import { useHTTP, usePermissionAPI } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { User, groupScope } from "models";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";
import { useGlobalState } from "providers";

export interface IGroupAPIContext {
  addGroup: (group: pond.GroupSettings) => Promise<any>;
  addDevice: (group: number, device: number) => Promise<any>;
  removeDevice: (group: number, device: number) => Promise<any>;
  updateGroup: (id: number, group: pond.GroupSettings) => Promise<any>;
  removeGroup: (id: number) => Promise<any>;
  getGroup: (id: number) => Promise<any>;
  listGroups: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean
  ) => Promise<any>;
  listGroupDevices: (
    id: number,
    limit: number,
    offset: number,
    order: "asc" | "desc",
    search?: string,
    comprehensive?: boolean
  ) => Promise<AxiosResponse<pond.GetMultiDeviceResponse>>;
  updateGroupPermissions: (id: number, users: User[]) => Promise<any>;
}

export const GroupAPIContext = createContext<IGroupAPIContext>({} as IGroupAPIContext);

interface Props {}

export default function DeviceProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, post, put, del } = useHTTP();
  const permissionAPI = usePermissionAPI();
  const [{ as }] = useGlobalState();

  const addGroup = (group: pond.GroupSettings) => {
    if (as) return post(pondURL("/groups?as=" + as), group);
    return post(pondURL("/groups"), group);
  };

  const addDevice = (group: number, device: number) => {
    let url = "/groups/" + group + "/devices/" + device + "/add";
    if (as) return post(pondURL(url + as), group);
    return post(pondURL(url), group);
  };

  const removeDevice = (group: number, device: number) => {
    let url = "/groups/" + group + "/devices/" + device + "/remove";
    if (as) return post(pondURL(url + as), group);
    return post(pondURL(url), group);
  };

  const updateGroup = (id: number, group: pond.GroupSettings) => {
    if (as) return put(pondURL("/groups/" + id + "?as=" + as), group);
    return put(pondURL("/groups/" + id), group);
  };

  const removeGroup = (id: number) => {
    if (as) return del(pondURL("/groups/" + id + "?as=" + as));
    return del(pondURL("/groups/" + id));
  };

  const getGroup = (id: number) => {
    if (as) return get(pondURL("/groups/" + id + "?as=" + as));
    return get(pondURL("/groups/" + id));
  };

  const listGroups = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean
  ) => {
    return get(
      pondURL(
        "/groups" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + or(order, "asc")) +
          ("&orderBy=" + or(orderBy, "id")) +
          (search ? "&search=" + search : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (as ? "&as=" + as.toString() : "")
      )
    );
  };

  const listGroupDevices = (
    id: number,
    limit: number,
    offset: number,
    order: "asc" | "desc",
    search?: string,
    comprehensive: boolean = false
  ) => {
    return get<pond.GetMultiDeviceResponse>(
      pondURL(
        "/groups/" +
          id +
          "/devices?limit=" +
          limit +
          "&offset=" +
          offset +
          "&order=" +
          order +
          (search ? "&search=" + search : "") +
          (comprehensive ? "&comprehensive=" + comprehensive.toString() : "") +
          (as ? "&as=" + as.toString() : "")
      )
    );
  };

  const updateGroupPermissions = (id: number, users: User[]) => {
    return permissionAPI.updatePermissions(groupScope(id.toString()), users);
  };

  return (
    <GroupAPIContext.Provider
      value={{
        addGroup,
        addDevice,
        removeDevice,
        updateGroup,
        removeGroup,
        getGroup,
        listGroups,
        listGroupDevices,
        updateGroupPermissions
      }}>
      {children}
    </GroupAPIContext.Provider>
  );
}

export const useGroupAPI = () => useContext(GroupAPIContext);

import {
  Collapse,
  createStyles,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
  useTheme
} from "@material-ui/core";
import React, { useState } from "react";
import { pond } from "protobuf-ts/pond";
import LeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import DeviceIcon from "assets/editor/device.png";
import { ImgIcon } from "common/ImgIcon";
import ComponentCards from "./ComponentCards";
import { Component } from "models";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    topText: {
      //textAlign: "center",
      //alignContent: "center"
    },
    bottom: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    bottomButtons: {
      marginLeft: "auto",
      justifyContent: "center",
      alignContent: "center"
    },
    divider: {
      margin: theme.spacing(2)
    },
    topTextContainer: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      padding: theme.spacing(1),
      marginBottom: 0,
      paddingBottom: 0
    },
    deviceCard: {
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      height: theme.spacing(8)
    },
    skeletonCard: {
      marginTop: theme.spacing(1),
      height: theme.spacing(8),
      borderRadius: "6px"
    },
    fanIcon: {
      height: "100%",
      objectFit: "contain"
    },
    iconContainer: {
      height: "100%",
      width: theme.spacing(8)
    },
    deviceInfo: {
      display: "block",
      margin: "auto",
      marginLeft: theme.spacing(1),
      padding: 0
    },
    checkbox: {
      marginLeft: "auto",
      marginTop: "auto",
      marginBottom: "auto"
    }
  });
});

interface Props {
  addBlockRef: any;
  selectedDevices: pond.Device[];
  setSelectedDevices: any;
  mineComponents: Component[];
  setMineComponents: React.Dispatch<React.SetStateAction<Component[]>>;
  mineComponentPreferences: Map<string, pond.MineComponentPreferences>;
  setMineComponentPreferences: React.Dispatch<
    React.SetStateAction<Map<string, pond.MineComponentPreferences>>
  >;
  mineKey: string;
}

export default function SensorDrawer(props: Props) {
  //const isMobile = useMobile();
  const {
    selectedDevices,
    mineComponents,
    setMineComponents,
    mineKey,
    mineComponentPreferences,
    setMineComponentPreferences
  } = props;
  const classes = useStyles();
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [expanded, setExpanded] = useState<number>(0);
  const theme = useTheme();

  const back = () => {
    if (offset - limit < 0) {
      setOffset(0);
    } else {
      setOffset(offset - limit);
    }
  };

  const forward = () => {
    if (offset + limit > selectedDevices.length) {
      setOffset(selectedDevices.length - limit);
    } else {
      setOffset(offset + limit);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.topTextContainer}>
        <Typography className={classes.topText} color="textSecondary" variant="caption">
          Devices selected for this vent system are listed below. Click them to view attached
          sensors and components.
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <List disablePadding>
        <Divider />
        {selectedDevices.map((device: pond.Device) => {
          return (
            <React.Fragment key={device.settings?.deviceId}>
              <ListItem
                button
                onClick={() => {
                  if (device.settings?.deviceId === expanded) {
                    setExpanded(0);
                  } else {
                    setExpanded(device.settings ? device.settings.deviceId : 0);
                  }
                }}>
                <ListItemIcon>
                  <ImgIcon src={DeviceIcon} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    device.settings && device.settings.name
                      ? device.settings.name
                      : "Device " + device.settings?.deviceId
                  }
                />
                {device.settings?.deviceId === expanded ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={device.settings?.deviceId === expanded} timeout="auto" unmountOnExit>
                {device.settings?.deviceId === expanded && (
                  <React.Fragment>
                    <div style={{ marginTop: theme.spacing(1) }} />
                    <ComponentCards
                      deviceId={device.settings ? device.settings.deviceId : 0}
                      showMobile={true}
                      mineComponents={mineComponents}
                      setMineComponents={setMineComponents}
                      mineComponentPreferences={mineComponentPreferences}
                      setMineComponentPreferences={setMineComponentPreferences}
                      keys={[mineKey]}
                      types={["mine"]}
                    />
                  </React.Fragment>
                )}
              </Collapse>
              <div style={{ marginTop: theme.spacing(1) }} />
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
      <div className={classes.bottom}>
        {offset + 1} - {limit + offset} of {selectedDevices.length}
        <div className={classes.bottomButtons}>
          <Grid container justify="center">
            <IconButton onClick={back} size="small">
              <LeftIcon />
            </IconButton>
            <Select value={limit} onChange={event => setLimit(event.target.value as number)}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
            <IconButton onClick={forward} size="small">
              <RightIcon />
            </IconButton>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}

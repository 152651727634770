import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import { TooltipProps } from "recharts";

interface Props extends TooltipProps<any, any> {
  valueFormatter?: (
    value: React.ReactText | string | number | readonly (string | number)[]
  ) => string;
}
export default function MaterialChartTooltip(props: Props) {
  const { active, label, payload, labelFormatter, valueFormatter } = props;
  const formattedLabel = () => {
    if (!label) return "Unknown";
    return labelFormatter ? labelFormatter(label, payload ?? []) : label;
  };

  const payloadContent = (p: any) => {
    const { name, value, dataKey } = p;
    //if (!value) return null; // this was preventing 0's from displaying correctly
    return (
      <Typography color="textPrimary" variant="subtitle1" key={dataKey}>
        {p.payload.name ?? name ?? "Unknown"}
        {": "}
        <Box component="span">{valueFormatter ? valueFormatter(value) : value.toString()}</Box>
      </Typography>
    );
  };

  if (active) {
    return (
      <Paper variant="outlined" style={{ opacity: 0.9 }}>
        <Box padding={1}>
          {payload && payload.map(p => payloadContent(p))}
          <Typography color="textSecondary" variant="caption">
            {formattedLabel()}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return null;
}

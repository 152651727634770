import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField
} from "@material-ui/core";
import ResponsiveDialog from "common/ResponsiveDialog";
import { Field, HarvestPlan, Task } from "models";
import moment from "moment";
import { pond } from "protobuf-ts/pond";
import { useHarvestPlanAPI, useTaskAPI } from "providers";
import React, { useCallback, useEffect, useState } from "react";

interface Props {
  open: boolean;
  close: () => void;
  fields: Field[];
  plan: HarvestPlan;
}

export default function DuplicateHarvestPlan(props: Props) {
  const { open, close, fields, plan } = props;
  const [newFieldIndex, setNewFieldIndex] = useState(0);
  const [planTasks, setPlanTasks] = useState<Task[]>([]);
  const [title, setTitle] = useState("");
  const harvestPlanAPI = useHarvestPlanAPI();
  const taskAPI = useTaskAPI();
  const [ready, setReady] = useState(false);

  const loadTasks = useCallback(() => {
    taskAPI.listTasks(50, 0, "asc", "start", plan.key()).then(resp => {
      setPlanTasks(resp.data.tasks.map(t => Task.any(t)));
      setReady(true);
    });
  }, [taskAPI, plan]);

  useEffect(() => {
    loadTasks();
  }, [loadTasks]);

  const duplicate = () => {
    let planSettings = HarvestPlan.clone(plan).settings;
    planSettings.field = fields[newFieldIndex].key();
    planSettings.createDate = moment.now();
    if (title !== "") {
      planSettings.title = title;
    }
    harvestPlanAPI.addHarvestPlan(planSettings).then(resp => {
      let tasks = planTasks;
      tasks.forEach(task => {
        task.settings.objectKey = resp.data.harvestPlan;
        taskAPI.addTask(task.settings);
      });
    });
    close();
  };
  return (
    <ResponsiveDialog open={open} onClose={close}>
      <DialogTitle>Duplicate Crop Plan</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          fullWidth
          select
          label="Field"
          value={newFieldIndex}
          onChange={e => setNewFieldIndex(+e.target.value)}
          color="primary">
          {fields.map((option, i) => (
            <MenuItem key={option.key()} value={i}>
              {option.fieldName()}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          margin="normal"
          type="text"
          label="Title"
          value={title}
          helperText={"Leave blank to use the same title*"}
          onChange={e => setTitle(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          variant="contained"
          style={{ color: "black", backgroundColor: "red" }}>
          Cancel
        </Button>
        <Button
          onClick={duplicate}
          variant="contained"
          color="primary"
          disabled={
            !ready ||
            (fields[newFieldIndex] &&
              !fields[newFieldIndex].permissions.includes(pond.Permission.PERMISSION_WRITE))
          }>
          Duplicate
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

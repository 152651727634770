import React from "react";
import { Chip } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Cancel";
import { Tag as TagModel } from "models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteIconLightBG: {
      color: "rgba(0, 0, 0, 0.26)",
      "&:hover": {
        color: "rgba(0, 0, 0, 0.4)"
      }
    },
    deleteIconDarkBG: {
      color: "rgba(255, 255, 255, 0.26)",
      "&:hover": {
        color: "rgba(255, 255, 255, 0.4)"
      }
    }
  })
);

interface Props {
  tag?: TagModel;
  onClick?: () => void;
  onDelete?: () => void;
}

export function Tag(props: Props) {
  const classes = useStyles();
  const { tag, onClick, onDelete } = props;
  const colour = tag ? tag.settings.colour : undefined;

  const getBrightnessLevel = () => {
    if (!colour) return false;
    const hex = colour.replace("#", "");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness;
  };

  const isLightBG = getBrightnessLevel() > 169;

  return (
    <Chip
      label={tag ? tag.name() : "Unknown"}
      onClick={onClick}
      onDelete={onDelete}
      style={{
        backgroundColor: colour,
        color: isLightBG ? "#000" : "#FFF"
      }}
      deleteIcon={
        <DeleteIcon className={isLightBG ? classes.deleteIconLightBG : classes.deleteIconDarkBG} />
      }
    />
  );
}

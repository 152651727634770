import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
  useTheme
} from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { PaletteColor } from "@material-ui/core/styles/createPalette";
import RemoveUserIcon from "@material-ui/icons/RemoveCircle";
import ShareIcon from "@material-ui/icons/Share";
import ResponsiveDialog from "common/ResponsiveDialog";
import { usePermissionAPI, usePrevious, useSnackbar } from "hooks";
import { cloneDeep, isEqual } from "lodash";
import { Scope, Team, User } from "models";
import { userRoleFromPermissions } from "pbHelpers/User";
import { pond } from "protobuf-ts/pond";
import React, { useCallback, useEffect, useState } from "react";
import { or } from "utils/types";
import RemoveSelfFromObject from "user/RemoveSelfFromObject";
import { useGlobalState, useTeamAPI } from "providers";
import ShareWithTeam from "./ShareWithTeam";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) => {
  const avatarBG = theme.palette.secondary["700" as keyof PaletteColor];
  return createStyles({
    dialogContent: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      [theme.breakpoints.up("sm")]: {
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
      }
    },
    userAvatar: {
      color: "#fff",
      backgroundColor: avatarBG
    },
    userPermissions: {
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(1)
    },
    textOverflowEllipsis: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    removeIcon: {
      color: red["500"],
      "&:hover": {
        color: red["600"]
      }
    },
    verticalSpacing: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3)
    },
    lessSpacing: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    iconButton: {
      margin: theme.spacing(1)
    },
    shareIcon: {
      color: blue["600"],
      "&:hover": {
        color: blue["700"]
      }
    }
  });
});

interface Props {
  scope: Scope;
  label: string;
  permissions: pond.Permission[];
  isDialogOpen: boolean;
  closeDialogCallback: Function;
  refreshCallback: Function;
  userCallback?: (users?: User[] | Team[] | undefined) => void;
  dialog?: string;
  cardMode?: boolean;
}

export default function ObjectTeams(props: Props) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const permissionAPI = usePermissionAPI();
  const [{ user, as }] = useGlobalState();
  const canProvision = user.allowedTo("provision");
  const teamAPI = useTeamAPI();
  const { error, success, warning } = useSnackbar();
  const {
    scope,
    label,
    permissions,
    isDialogOpen,
    closeDialogCallback,
    refreshCallback,
    userCallback,
    dialog,
    cardMode
  } = props;
  const prevPermissions = usePrevious(permissions);
  const prevIsDialogOpen = usePrevious(isDialogOpen);
  const [initialUsers, setInitialUsers] = useState<Team[]>([]);
  const [users, setUsers] = useState<Team[]>([]);
  const [canManageUsers, setCanManageUsers] = useState<boolean>(
    permissions.includes(pond.Permission.PERMISSION_USERS)
  );
  const [canShare, setCanShare] = useState<boolean>(
    permissions.includes(pond.Permission.PERMISSION_SHARE)
  );
  const [removedUsers, setRemovedUsers] = useState<string[]>([]);
  const [removeSelfDialogIsOpen, setRemoveSelfDialogIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShareObjectDialogOpen, setIsShareObjectDialogOpen] = useState<boolean>(false);

  const setDefaultState = () => {
    setInitialUsers([]);
    setUsers([]);
    setRemovedUsers([]);
  };

  const load = useCallback(() => {
    setIsLoading(true);
    teamAPI
      .listObjectTeams(scope, as)
      .then((response: any) => {
        let rTeams: Team[] = [];
        or(response.data, { teams: [] }).teams.forEach((user: any) => {
          rTeams.push(Team.any(user));
        });
        rTeams = rTeams.filter(u => u.permissions.length > 0);
        setInitialUsers(cloneDeep(rTeams));
        setUsers(rTeams);
      })
      .catch((err: any) => {
        setInitialUsers([]);
        setUsers([]);
      })
      .finally(() => setIsLoading(false));
  }, [scope, teamAPI, as]);

  useEffect(() => {
    if (!prevIsDialogOpen && isDialogOpen) {
      load();
    }
    if (prevPermissions !== permissions) {
      setCanManageUsers(permissions.includes(pond.Permission.PERMISSION_USERS));
      setCanShare(permissions.includes(pond.Permission.PERMISSION_SHARE));
    }
  }, [isDialogOpen, load, permissions, prevIsDialogOpen, prevPermissions]);

  const closeRemoveSelfDialog = () => {
    setRemoveSelfDialogIsOpen(false);
  };

  const close = () => {
    closeRemoveSelfDialog();
    closeDialogCallback();
    setDefaultState();
  };

  const submit = () => {
    permissionAPI
      .updatePermissions(scope, users)
      .then((response: any) => {
        success("Users were sucessfully updated for " + label);
        close();
        refreshCallback();
        userCallback && userCallback(users);
        if (cardMode) {
          load();
        }
      })
      .catch((err: any) => {
        err.response.data.error
          ? warning(err.response.data.error)
          : error("Error occured when updating users for " + label);
        close();
      });
  };

  const changeUserPermissions = (user: pond.ITeam) => (event: any) => {
    let updatedUsers = cloneDeep(users);
    let permissionMapping = new Map<string, pond.Permission>([
      ["2", pond.Permission.PERMISSION_READ],
      ["3", pond.Permission.PERMISSION_WRITE],
      ["4", pond.Permission.PERMISSION_SHARE],
      ["1", pond.Permission.PERMISSION_USERS],
      ["6", pond.Permission.PERMISSION_FILE_MANAGEMENT]
    ]);
    let permission = or(
      permissionMapping.get(event.target.value),
      pond.Permission.PERMISSION_INVALID
    );
    for (let i = 0; i < updatedUsers.length; i++) {
      let currUser = updatedUsers[i];
      if (
        user &&
        user.settings &&
        currUser &&
        currUser.settings &&
        currUser.settings.key === user.settings.key
      ) {
        let permissions = user.permissions ? cloneDeep(user.permissions) : [];
        if (permissions.includes(permission)) {
          permissions = permissions.filter(
            (curPermission: pond.Permission) => curPermission !== permission
          );
        } else {
          permissions.push(permission);
        }
        updatedUsers[i].permissions = permissions;
        break;
      }
    }
    setUsers(updatedUsers);
  };

  const removeUser = (user: string) => {
    let updatedUsers = cloneDeep(users);
    let updatedRemovedUsers = cloneDeep(removedUsers);
    for (let i = 0; i < updatedUsers.length; i++) {
      let currUser = updatedUsers[i];
      if (currUser && currUser.settings && currUser.settings.key === user) {
        updatedRemovedUsers.push(currUser.settings.key);
        updatedUsers[i].permissions = [];
        break;
      }
    }
    setUsers(updatedUsers);
    setRemovedUsers(updatedRemovedUsers);
  };

  const checkIsSelf = (checkUser: pond.ITeam): boolean => {
    const id = checkUser && checkUser.settings ? checkUser.settings.key : "";
    return user.id() === id;
  };

  const userIsRemoved = (user: pond.ITeam): boolean => {
    const id = user && user.settings ? user.settings.key : "";
    return removedUsers.includes(or(id, ""));
  };

  const objectUsersUnchanged = (): boolean => {
    return isEqual(initialUsers, users);
  };

  const openRemoveSelfDialog = () => {
    setRemoveSelfDialogIsOpen(true);
  };

  const openShareObjectDialog = () => {
    setIsShareObjectDialogOpen(true);
  };

  const closeShareObjectDialog = (shared: boolean | undefined) => {
    setIsShareObjectDialogOpen(false);
    if (shared) {
      load();
    }
  };

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ UI BEGINS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  const title = () => {
    return (
      <Grid container direction="column" justify="space-between">
        <Grid container direction="row" justify="space-between">
          <Grid item xs={8}>
            Teams
            <Typography variant="body2" color="textSecondary">
              {label}
            </Typography>
          </Grid>
          <Grid item xs={4} container justify="flex-end">
            {canShare && (
              <Tooltip title={"Share " + label}>
                <IconButton
                  aria-label="Share"
                  className={classes.iconButton}
                  onClick={openShareObjectDialog}>
                  <ShareIcon className={classes.shareIcon} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        {dialog && (
          <Typography variant="body2" color="textSecondary" style={{ marginTop: "4px" }}>
            {dialog}
          </Typography>
        )}
      </Grid>
    );
  };

  const userPermissionManagement = (user: Team) => {
    let permissions = user.permissions;
    if (
      !canManageUsers ||
      userIsRemoved(user) ||
      //check they have all permissions
      (permissions.includes(pond.Permission.PERMISSION_USERS) &&
        permissions.includes(pond.Permission.PERMISSION_READ) &&
        permissions.includes(pond.Permission.PERMISSION_WRITE) &&
        permissions.includes(pond.Permission.PERMISSION_SHARE) &&
        permissions.includes(pond.Permission.PERMISSION_FILE_MANAGEMENT))
    ) {
      return null;
    }
    const canRead = permissions.includes(pond.Permission.PERMISSION_READ);
    return (
      <FormControl component="fieldset" fullWidth className={classes.userPermissions}>
        <FormLabel component="legend"></FormLabel>
        <FormGroup>
          <Grid container direction="row" justify="flex-start">
            <Grid item xs={6} sm={3} container justify="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.includes(pond.Permission.PERMISSION_READ)}
                    onChange={changeUserPermissions(user)}
                    value={pond.Permission.PERMISSION_READ as pond.Permission}
                  />
                }
                label="View"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={6} sm={3} container justify="center">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!canRead}
                    checked={permissions.includes(pond.Permission.PERMISSION_WRITE)}
                    onChange={changeUserPermissions(user)}
                    value={pond.Permission.PERMISSION_WRITE as pond.Permission}
                  />
                }
                label="Edit"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={6} sm={3} container justify="center">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!canRead}
                    checked={permissions.includes(pond.Permission.PERMISSION_SHARE)}
                    onChange={changeUserPermissions(user)}
                    value={pond.Permission.PERMISSION_SHARE as pond.Permission}
                  />
                }
                label="Share"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={6} sm={3} container justify="center">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!canRead}
                    checked={permissions.includes(pond.Permission.PERMISSION_USERS)}
                    onChange={changeUserPermissions(user)}
                    value={pond.Permission.PERMISSION_USERS as pond.Permission}
                  />
                }
                label="Manage Users"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={6} sm={3} container justify="center">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!canRead}
                    checked={permissions.includes(pond.Permission.PERMISSION_FILE_MANAGEMENT)}
                    onChange={changeUserPermissions(user)}
                    value={pond.Permission.PERMISSION_FILE_MANAGEMENT as pond.Permission}
                  />
                }
                label="File Management"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
    );
  };

  const goToTeam = (teamKey: string) => {
    history.push("/teams/" + teamKey);
  };

  const userListItem = (user: Team) => {
    let isSelf = checkIsSelf(user);
    let isRemoved = userIsRemoved(user);
    let name = user.name();
    let permissions = user.permissions;
    let userSettings = pond.TeamSettings.create(user.settings !== null ? user.settings : undefined);

    return (
      <ListItem alignItems="flex-start" disabled={isRemoved}>
        <ListItemAvatar>
          <Avatar
            alt={name}
            src={
              userSettings.avatar && userSettings.avatar !== "" ? userSettings.avatar : undefined
            }
            className={classes.userAvatar}>
            {!(userSettings.avatar && userSettings.avatar !== "") && name}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          className={classes.textOverflowEllipsis}
          disableTypography
          primary={
            <Link
              onClick={() => {
                goToTeam(user.key());
              }}>
              <Typography
                component="div"
                variant="body1"
                color="textPrimary"
                style={{ cursor: "pointer" }}>
                {name + (isSelf ? " (you)" : "")}
              </Typography>
            </Link>
          }
          secondary={
            <React.Fragment>
              <Typography component="div" variant="caption" color="textSecondary">
                {isRemoved ? "REMOVED " : userRoleFromPermissions(permissions)}
              </Typography>
            </React.Fragment>
          }
        />
        {isSelf ? (
          <ListItemSecondaryAction>
            <Tooltip title={"Remove yourself from " + label}>
              <IconButton
                edge="end"
                aria-label={"Remove yourself from " + label}
                onClick={openRemoveSelfDialog}>
                <RemoveUserIcon className={classes.removeIcon} />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        ) : (
          canManageUsers &&
          !isRemoved &&
          (!permissions.includes(pond.Permission.PERMISSION_USERS) || canProvision) && (
            <ListItemSecondaryAction>
              <Tooltip title="Revoke user's access">
                <IconButton
                  edge="end"
                  aria-label="Remove user from device"
                  onClick={() => removeUser(or(user.settings, { key: "" }).key)}>
                  <RemoveUserIcon className={classes.removeIcon} />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          )
        )}
      </ListItem>
    );
  };

  const objectUsersList = () => {
    //const sortedUsers = sortUsersByRole(users);

    let userListItems: any = [];
    for (var i = 0; i < users.length; i++) {
      let user = users[i];
      if (user) {
        userListItems.push(
          <React.Fragment key={i}>
            {userListItem(user)}
            {userPermissionManagement(user)}
            <Divider />
          </React.Fragment>
        );
      }
    }

    return <List>{userListItems}</List>;
  };

  const content = () => {
    if (isLoading) {
      return (
        <Grid container justify="center" alignContent="center" className={classes.verticalSpacing}>
          <CircularProgress />
        </Grid>
      );
    }

    if (users.length < 1) {
      return (
        <React.Fragment>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            className={!cardMode ? classes.verticalSpacing : ""}
            style={{ padding: cardMode ? 0 : "" }}>
            No teams found.
          </Typography>
        </React.Fragment>
      );
    }

    return objectUsersList();
  };

  const actions = () => {
    return (
      <Grid container direction="row" justify="space-between">
        <Grid item xs={4}></Grid>
        <Grid item xs={8} container justify="flex-end">
          {!cardMode && (
            <Button onClick={close} color="primary">
              Cancel
            </Button>
          )}
          {canManageUsers && (
            <Button onClick={submit} color="primary" disabled={objectUsersUnchanged()}>
              {cardMode ? "Update" : "Submit"}
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  const dialogs = () => {
    return (
      <React.Fragment>
        <RemoveSelfFromObject
          scope={scope}
          label={label}
          isDialogOpen={removeSelfDialogIsOpen}
          closeDialogCallback={closeRemoveSelfDialog}
        />
        <ShareWithTeam
          scope={scope}
          label={label}
          permissions={permissions}
          isDialogOpen={isShareObjectDialogOpen}
          closeDialogCallback={closeShareObjectDialog}
        />
      </React.Fragment>
    );
  };

  if (cardMode) {
    return (
      <React.Fragment>
        <Typography variant="h6">{title()}</Typography>
        <div style={{ overflowY: "scroll" }}>
          <Divider />
          {content()}
          {actions()}
          {dialogs()}
          <div style={{ marginTop: theme.spacing(2) }} />
        </div>
      </React.Fragment>
    );
  }

  return (
    <ResponsiveDialog
      fullWidth
      maxWidth="xs"
      open={props.isDialogOpen}
      onClose={close}
      aria-labelledby="object-users-dialog">
      <DialogTitle id="object-users-title">{title()}</DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>{content()}</DialogContent>
      <DialogActions>{actions()}</DialogActions>
      {dialogs()}
    </ResponsiveDialog>
  );
}

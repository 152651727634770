import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Slider,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  useTheme
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ResponsiveDialog from "common/ResponsiveDialog";
import { useMobile } from "hooks";
import { pond } from "protobuf-ts/pond";

interface Props {
  type: pond.ShaftType | undefined;
  setType: any;
  setDirection: any;
}

export default function VentDialog(props: Props) {
  const { type, setType, setDirection } = props;
  const [ventType, setVentType] = useState<string>("Layflat");
  const [ventSubType, setVentSubType] = useState<string>("Round");

  const isMobile = useMobile();
  const theme = useTheme();

  return (
    <ResponsiveDialog
      maxWidth={"xs"}
      fullWidth
      fullScreen={isMobile}
      open={Boolean(type)}
      scroll="paper"
      onClose={() => {
        setType(undefined);
      }}>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" style={{ flex: 1 }}>
            Editing Vent Shaft
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setType(undefined)}
            aria-label="close">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      {type === pond.ShaftType.SHAFT_TYPE_CORNER && (
        <React.Fragment>
          <div style={{ margin: theme.spacing(2) }}>
            <Typography variant="subtitle1" style={{ marginBottom: theme.spacing(1) }}>
              Direction
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={"clockwise"}
              name="radio-buttons-group">
              <FormControlLabel
                value={"clockwise"}
                control={<Radio />}
                label="Clockwise"
                onClick={() => setDirection(0)}
              />
              <FormControlLabel
                value={"counterclockwise"}
                control={<Radio />}
                label="Counter Clockwise"
                onClick={() => setDirection(1)}
              />
            </RadioGroup>
          </div>
          <Divider />
        </React.Fragment>
      )}
      <Grid container style={{ padding: theme.spacing(2) }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ marginBottom: theme.spacing(1) }}>
            Specifications (coming soon)
          </Typography>
          <Grid item xs>
            <Typography variant="body2">Length:</Typography>
            <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
          </Grid>
          <Typography variant="body2">Diameter:</Typography>
          <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
        </Grid>
      </Grid>
      <Divider />
      <Grid container style={{ padding: theme.spacing(2) }}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Ventilation Type</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={ventType}>
            <FormControlLabel
              value="Layflat"
              control={<Radio />}
              label="Layflat"
              onClick={() => {
                setVentType("Layflat");
                setVentSubType("Round");
              }}
            />
            <FormControlLabel
              value="Rigid"
              control={<Radio />}
              label="Rigid"
              onClick={() => {
                setVentType("Rigid");
                setVentSubType("Hardline");
              }}
            />
          </RadioGroup>
        </FormControl>
        {ventType === "Layflat" ? (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Subtype</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ventSubType}
              label="Ventilation Subtybe"
              onChange={event => setVentSubType(event.target.value as string)}>
              <MenuItem value={"Round"}>Round</MenuItem>
              <MenuItem value={"Twin"}>Twin</MenuItem>
              <MenuItem value={"M"}>M</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Subtype</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ventSubType}
              label="Ventilation Subtybe"
              onChange={event => setVentSubType(event.target.value as string)}>
              <MenuItem value={"Hardline"}>Hardline</MenuItem>
              <MenuItem value={"Steel"}>Steel</MenuItem>
              <MenuItem value={"Fiberglass"}>Fiberglass</MenuItem>
            </Select>
          </FormControl>
        )}
      </Grid>
    </ResponsiveDialog>
  );
}

import { createStyles, Dialog, makeStyles, Slide, Theme } from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog";
import { TransitionProps } from "@material-ui/core/transitions";
import { useMobile } from "hooks";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bg: {
      background: theme.palette.background.default
    }
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//renders a fullscren dialog when a mobile viewport is detected
export default function ResponsiveDialog(props: DialogProps) {
  const classes = useStyles();
  const isMobile = useMobile();
  const fullScreen = props.fullScreen ?? isMobile;

  return (
    <Dialog
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      {...props}
      PaperProps={{
        classes: { root: classes.bg }
      }}>
      {props.children}
    </Dialog>
  );
}

import { useAuth } from "hooks";
import { usePrevious } from "hooks/usePrevious";
import Terminals from "pages/Terminals";
import AviationMap from "pages/AviationMap";
import Callback from "pages/Callback";
import Fields from "pages/Fields";
import Loading from "pages/Loading";
import Login from "pages/Login";
import Logout from "pages/Logout";
import PageNotFound from "pages/PageNotFound";
//import Team from "pages/Team";
//import Teams from "pages/Teams";
import Gate from "pages/Gate";
import VentEditor from "pages/VentEditor";
import { isShareableLink } from "pbHelpers/Device";
import { useGlobalState } from "providers";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  hasTutorialPlaylist,
  IsAdaptiveAgriculture,
  IsAdCon,
  isBXT,
  IsMiVent,
  IsOmniAir
} from "services/whiteLabel";
import { isDevelopment, isOffline } from "utils/environment";
import Sites from "pages/Sites";
import Site from "pages/Site";
import Mines from "pages/Mines";
import JohnDeere from "pages/JohnDeere";
import CNHi from "pages/CNHi";
import APIDocs from "pages/APIDocs";

// Routes (NOTE: some routes are better off being imported directly (Callback, PageNotFound, Login, Logout))
const Devices = lazy(() => import("pages/Devices"));
const Device = lazy(() => import("pages/Device"));
const Docs = lazy(() => import("pages/Docs"));
const Group = lazy(() => import("pages/Group"));
const DeviceComponent = lazy(() => import("pages/DeviceComponent"));
const Home = lazy(() => import("pages/Home"));
const Firmware = lazy(() => import("pages/Firmware"));
const Data = lazy(() => import("pages/Data"));
const Hardware = lazy(() => import("pages/Hardware"));
const Security = lazy(() => import("pages/Security"));
const Report = lazy(() => import("pages/Report"));
const Welcome = lazy(() => import("pages/Welcome"));
const DeviceHistory = lazy(() => import("pages/DeviceHistory"));
const Demo = lazy(() => import("pages/Demo"));
const Users = lazy(() => import("pages/Users"));
const Teams = lazy(() => import("pages/Teams"));
const Logs = lazy(() => import("pages/Logs"));
const Team = lazy(() => import("pages/Team"));
const Nfc = lazy(() => import("pages/Nfc"));
const Sandbox = lazy(() => import("pages/Sandbox"));
const Tutorial = lazy(() => import("pages/Tutorial"));
const Bins = lazy(() => import("pages/Bins"));
const Bin = lazy(() => import("pages/Bin"));
const Admin = lazy(() => import("pages/Admin"));
const ConstructionSiteMap = lazy(() => import("pages/ConstructionSiteMap"));
const Tasks = lazy(() => import("pages/Tasks"));
const Task = lazy(() => import("pages/Task"));
const MyFields = lazy(() => import("pages/MyFields"));
const Marketplace = lazy(() => import("pages/Marketplace"));
const Heaters = lazy(() => import("pages/Heaters"));
const Heater = lazy(() => import("pages/Heater"));
const GrainBag = lazy(() => import("pages/grainBag"));
const Transactions = lazy(() => import("pages/Transactions"));
const ObjectSearchTable = lazy(() => import("pages/ObjectSearchTable"));
const CableEstimator = lazy(() => import("pages/BinCableEstimator"));
const Contracts = lazy(() => import("pages/Contracts"));
const Contract = lazy(() => import("pages/Contract"));

export interface MatchParams {
  groupID: string;
  deviceID: string;
  componentID: string;
  reportID: string;
  binID: string;
  teamID: string;
  taskID: string;
  gateID: string;
  siteID: string;
  heaterID: string;
  mineKey: string;
  jobsiteKey: string;
  bagID: string;
  contractKey: string;
}

export default function Routes() {
  const history = useHistory();
  const location = useLocation();
  const [{ user }] = useGlobalState();
  const prevPath = usePrevious(location.pathname);
  const offline = isOffline();
  const { isAuthenticated } = useAuth();
  const [authorized, setAuthorized] = useState(isAuthenticated || offline);
  const showBXTRoutes = isBXT();
  const showAdaptiveAgricultureRoutes = IsAdaptiveAgriculture();
  const showMiVentRoutes = IsMiVent();
  const showAdConRoutes = IsAdCon();
  const showOmniAirRoutes = IsOmniAir();

  useEffect(() => {
    setAuthorized(isAuthenticated || offline);
    if (isAuthenticated && !user.empty() && !user.status.finishedIntro) {
      history.push("/welcome");
    }
  }, [history, isAuthenticated, offline, user]);

  return (
    <Suspense fallback={<Loading isCenterCenter />}>
      <Switch>
        {/* ---- Redirects ---- */}
        <Redirect exact from="/index.html" to="/" />
        <Redirect from="/beta" to="/" />
        <Redirect exact from="/device/:deviceID" to="/devices/:deviceID" />
        <Redirect exact from="/team/:teamKey" to="/teams/:teamKey" />
        <Redirect exact from="/groups" to="/devices" />
        <Redirect
          exact
          from="/device/:deviceID/component/:componentID"
          to="/devices/:deviceID/components/:componentID"
        />
        {/* ---- Standard Routes---- */}
        <Route
          path="/"
          exact
          render={() =>
            authorized ? (
              showAdaptiveAgricultureRoutes ? (
                <Bins />
              ) : showMiVentRoutes ? (
                <VentEditor />
              ) : showAdConRoutes ? (
                <Sites />
              ) : (
                <Devices />
              )
            ) : showBXTRoutes ? (
              <Home />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        {user.hasFeature("admin") && (
          <Route
            path="/firmware"
            exact
            render={() => (authorized ? <Firmware /> : <Redirect to="/login" />)}
          />
        )}
        {user.hasFeature("admin") && (
          <Route
            path="/objects"
            exact
            render={() => (authorized ? <ObjectSearchTable /> : <Redirect to="/login" />)}
          />
        )}
        {user.hasFeature("admin") && (
          <Route
            path="/admin"
            exact
            render={() => (authorized ? <Admin /> : <Redirect to="/login" />)}
          />
        )}
        {user.hasFeature("admin") && (
          <Route
            path="/users"
            exact
            render={() => (authorized ? <Users /> : <Redirect to="/users" />)}
          />
        )}
        <Route
          path="*/devices/:deviceID/components/:componentID/"
          render={props => {
            let deviceID = props.match.params.deviceID;
            return authorized ? (
              <DeviceComponent />
            ) : isShareableLink(deviceID) ? (
              <Redirect to={"/demo/" + deviceID} />
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
        <Route
          path="/devices/:deviceID/history"
          render={props => {
            let deviceID = props.match.params.deviceID;
            return authorized ? (
              <DeviceHistory />
            ) : isShareableLink(deviceID) ? (
              <Redirect to={"/demo/" + deviceID} />
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
        <Route
          exact
          path="*/groups/:groupID/devices"
          render={() => (authorized ? <Group /> : <Redirect to="/login" />)}
        />
        <Route
          exact
          path="*/devices"
          render={() => (authorized ? <Devices /> : <Redirect to="/login" />)}
        />
        <Route
          exact
          path="/transactions"
          render={() => (authorized ? <Transactions /> : <Redirect to="/login" />)}
        />
        <Route
          exact
          path="/marketplace"
          render={() => (authorized ? <Marketplace /> : <Redirect to="/login" />)}
        />
        <Route
          path="*/devices/:deviceID"
          render={props => {
            let deviceID = props.match.params.deviceID;
            return authorized ? (
              <Device />
            ) : isShareableLink(deviceID) ? (
              <Redirect to={"/demo/" + deviceID} />
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
        <Route
          path="/groups/:groupID/devices/:deviceID/components/:componentID"
          render={() => (authorized ? <DeviceComponent /> : <Redirect to="/login" />)}
        />
        <Route
          path="/groups/:groupID/devices/:deviceID/history"
          render={() => (authorized ? <DeviceHistory /> : <Redirect to="/login" />)}
        />
        <Route
          path="/groups/:groupID/devices/:deviceID"
          render={() => (authorized ? <Device /> : <Redirect to="/login" />)}
        />

        <Route
          path="/groups/:groupID"
          render={() => (authorized ? <Group /> : <Redirect to="/login" />)}
        />
        <Route
          path="/welcome"
          exact
          render={() => (authorized ? <Welcome /> : <Redirect to="/login" />)}
        />
        {/* ---- Routes disabled for the offline pond ---- */}
        <Route
          path="/callback"
          exact
          render={() => (!offline ? <Callback /> : <Redirect to="404" />)}
        />
        <Route
          path="/login"
          exact
          render={() => (!offline ? <Login prevPath={prevPath} /> : <Redirect to="404" />)}
        />
        <Route
          path="/logout"
          exact
          render={() => (!offline ? <Logout /> : <Redirect to="404" />)}
        />
        <Route
          path="/docs"
          exact
          render={() => (!offline && user.hasFeature("docs") ? <Docs /> : <Redirect to="404" />)}
        />
        <Route
          path="/apidocs"
          exact
          render={() =>
            user.hasAdmin() || user.hasFeature("developer") ? <APIDocs /> : <Redirect to="404" />
          }
        />
        <Route
          path="/tutorial"
          exact
          render={() => (!offline && hasTutorialPlaylist() ? <Tutorial /> : <Redirect to="404" />)}
        />
        {/* Adaptive Agriculture routes */}
        <Route
          path="/contracts"
          exact
          render={() =>
            //contracts were built to be generic but for the moment grain contracts are our only type so restrict this page to ag
            showAdaptiveAgricultureRoutes ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Contracts />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/contracts/:contractKey"
          exact
          render={() =>
            //contracts were built to be generic but for the moment grain contracts are our only type so restrict this page to ag
            showAdaptiveAgricultureRoutes ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Contract />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/bins"
          exact
          render={() =>
            showAdaptiveAgricultureRoutes ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Bins />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        {user.hasFeature("installer") && (
          <Route
            path="/binCableEstimator"
            exact
            render={() =>
              showAdaptiveAgricultureRoutes ||
              window.location.origin.includes("staging") ||
              window.location.origin.includes("localhost") ? (
                authorized ? (
                  <CableEstimator />
                ) : (
                  <Redirect to="/login" />
                )
              ) : (
                <Redirect to="404" />
              )
            }
          />
        )}
        <Route
          path="/bins/:binID"
          render={() =>
            showAdaptiveAgricultureRoutes ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Bin />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/grainbags/:bagID"
          render={() =>
            showAdaptiveAgricultureRoutes ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <GrainBag />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/fields"
          render={() =>
            showAdaptiveAgricultureRoutes ? (
              authorized ? (
                <Fields />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/johndeere"
          render={() =>
            showAdaptiveAgricultureRoutes ? (
              authorized ? (
                <JohnDeere />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/cnhi"
          render={() =>
            showAdaptiveAgricultureRoutes ? (
              authorized ? (
                <CNHi />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />

        <Route
          path="/myFields"
          render={() =>
            showAdaptiveAgricultureRoutes ? (
              authorized ? (
                <MyFields />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />

        {/* Adaptive SiteMap routes*/}
        {user.hasFeature("maps") && (
          <Route
            path="/constructionsitemap"
            exact
            render={() =>
              showAdConRoutes ||
              window.location.origin.includes("staging") ||
              window.location.origin.includes("localhost") ? (
                authorized ? (
                  <ConstructionSiteMap />
                ) : (
                  <Redirect to="/login" />
                )
              ) : (
                <Redirect to="404" />
              )
            }
          />
        )}
        <Route
          path="/jobsites"
          exact
          render={() =>
            showAdConRoutes ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Sites />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/objectHeaters"
          exact
          render={() =>
            showAdConRoutes ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Heaters />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/objectHeaters/:heaterID"
          render={() =>
            showAdConRoutes ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Heater />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/jobsites/:siteID"
          render={() =>
            showAdConRoutes ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Site />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        {user.hasFeature("maps") && (
          <Route
            path="/aviationmap"
            exact
            render={() =>
              showOmniAirRoutes ||
              window.location.origin.includes("staging") ||
              window.location.origin.includes("localhost") ? (
                authorized ? (
                  <AviationMap />
                ) : (
                  <Redirect to="/login" />
                )
              ) : (
                <Redirect to="404" />
              )
            }
          />
        )}
        <Route
          path="/terminals"
          exact
          render={() =>
            showOmniAirRoutes ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Terminals />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/terminals/:gateID"
          render={() =>
            showOmniAirRoutes ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Gate />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        {user.hasFeature("tasks") && (
          <Route
            path="/tasks"
            exact
            render={() => (authorized ? <Tasks /> : <Redirect to="/login" />)}
          />
        )}
        {user.allowedTo("provision") && (
          <Route
            path="/tasks/:taskID"
            exact
            render={() =>
              showAdConRoutes || showAdaptiveAgricultureRoutes ? (
                authorized ? (
                  <Task />
                ) : (
                  <Redirect to="/login" />
                )
              ) : (
                <Redirect to="404" />
              )
            }
          />
        )}

        {/* MiVent routes */}
        <Route
          path="/ventilation"
          exact
          render={() =>
            showMiVentRoutes ||
            user.hasFeature("admin") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <VentEditor />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/mines/:mineKey"
          exact
          render={() =>
            showMiVentRoutes ||
            window.location.origin.includes("staging") ||
            user.hasFeature("admin") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <VentEditor />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/mines"
          exact
          render={() =>
            showMiVentRoutes ||
            window.location.origin.includes("staging") ||
            user.hasFeature("admin") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Mines />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />
        {/* Teams routes */}
        {true && (
          <Route
            path="/teams"
            exact
            render={() => (authorized ? <Teams /> : <Redirect to="/login" />)}
          />
        )}
        {true && (
          <Route
            path="/teams/:teamID"
            render={() => (authorized ? <Team /> : <Redirect to="/login" />)}
          />
        )}
        {user.hasFeature("admin") && (
          <Route
            path="/logs"
            exact
            render={() => (authorized ? <Logs /> : <Redirect to="/login" />)}
          />
        )}
        {/* BXT specific routes */}
        <Route
          path="/security/:reportID"
          render={() => (authorized && showBXTRoutes ? <Report /> : <Redirect to="/login" />)}
        />
        <Route
          path="/security"
          exact
          render={() =>
            !offline && user.hasFeature("security") && showBXTRoutes ? (
              <Security />
            ) : (
              <Redirect to="404" />
            )
          }
        />
        <Route
          path="/hardware"
          exact
          render={() => (showBXTRoutes ? <Hardware /> : <Redirect to="/404" />)}
        />
        <Route
          path="/data"
          exact
          render={() => (showBXTRoutes ? <Data /> : <Redirect to="/404" />)}
        />
        <Route
          path="/demo/:deviceID"
          render={() => (showBXTRoutes ? <Demo /> : <Redirect to="/404" />)}
        />
        <Route
          path="/demo"
          exact
          render={() => (showBXTRoutes ? <Demo /> : <Redirect to="/404" />)}
        />
        <Route
          path="/sandbox"
          exact
          render={() => (isDevelopment() ? <Sandbox /> : <Redirect to="/404" />)}
        />
        <Route
          path="/nfc"
          exact
          render={() =>
            user.hasFeature("admin") ||
            window.location.origin.includes("staging") ||
            window.location.origin.includes("localhost") ? (
              authorized ? (
                <Nfc />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <Redirect to="404" />
            )
          }
        />

        {/* ---- 404 ---- */}
        <Route render={() => <PageNotFound />} />
      </Switch>
    </Suspense>
  );
}

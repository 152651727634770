import { quack } from "protobuf-ts/quack";

export class ComponentType {
  static invalid = quack.ComponentType.COMPONENT_TYPE_INVALID;
  static power = quack.ComponentType.COMPONENT_TYPE_POWER;
  static temperature = quack.ComponentType.COMPONENT_TYPE_TEMPERATURE;
  static booleanOutput = quack.ComponentType.COMPONENT_TYPE_BOOLEAN_OUTPUT;
  static edgeTriggered = quack.ComponentType.COMPONENT_TYPE_EDGE_TRIGGERED;
  static grainCable = quack.ComponentType.COMPONENT_TYPE_GRAIN_CABLE;
  static pressure = quack.ComponentType.COMPONENT_TYPE_PRESSURE;
  static gps = quack.ComponentType.COMPONENT_TYPE_GPS;
  static booleanInput = quack.ComponentType.COMPONENT_TYPE_BOOLEAN_INPUT;
  static dht = quack.ComponentType.COMPONENT_TYPE_DHT;
  static modem = quack.ComponentType.COMPONENT_TYPE_MODEM;
  static light = quack.ComponentType.COMPONENT_TYPE_LIGHT;
  static co2 = quack.ComponentType.COMPONENT_TYPE_CO2;
  static stepperMotor = quack.ComponentType.COMPONENT_TYPE_STEPPER_MOTOR;
  static analogInput = quack.ComponentType.COMPONENT_TYPE_ANALOG_INPUT;
  static o2 = quack.ComponentType.COMPONENT_TYPE_O2;
  static ethylene = quack.ComponentType.COMPONENT_TYPE_ETHYLENE;
  static orp = quack.ComponentType.COMPONENT_TYPE_ORP;
  static ph = quack.ComponentType.COMPONENT_TYPE_PH;
  static voltage = quack.ComponentType.COMPONENT_TYPE_VOLTAGE;
  static calcium = quack.ComponentType.COMPONENT_TYPE_CALCIUM;
  static nitrate = quack.ComponentType.COMPONENT_TYPE_NITRATE;
  static potassium = quack.ComponentType.COMPONENT_TYPE_POTASSIUM;
  static conductivity = quack.ComponentType.COMPONENT_TYPE_CONDUCTIVITY;
  static capacitance = quack.ComponentType.COMPONENT_TYPE_CAPACITANCE;
  static vpd = quack.ComponentType.COMPONENT_TYPE_VAPOUR_PRESSURE_DEFICIT;
  static weight = quack.ComponentType.COMPONENT_TYPE_WEIGHT;
}

export class Measurement {
  static invalid = quack.MeasurementType.MEASUREMENT_TYPE_INVALID;
  static temperature = quack.MeasurementType.MEASUREMENT_TYPE_TEMPERATURE;
  static boolean = quack.MeasurementType.MEASUREMENT_TYPE_BOOLEAN;
  static voltage = quack.MeasurementType.MEASUREMENT_TYPE_VOLTAGE;
  static percent = quack.MeasurementType.MEASUREMENT_TYPE_PERCENT;
  static pressure = quack.MeasurementType.MEASUREMENT_TYPE_PRESSURE;
  static rssi = quack.MeasurementType.MEASUREMENT_TYPE_RSSI;
  static light = quack.MeasurementType.MEASUREMENT_TYPE_LIGHT;
  static gas = quack.MeasurementType.MEASUREMENT_TYPE_PPM;
  static analog = quack.MeasurementType.MEASUREMENT_TYPE_ANALOG;
  static orp = quack.MeasurementType.MEASUREMENT_TYPE_ORP;
  static ph = quack.MeasurementType.MEASUREMENT_TYPE_PH;
  static concentration = quack.MeasurementType.MEASUREMENT_TYPE_PPB;
  static conductivity = quack.MeasurementType.MEASUREMENT_TYPE_CONDUCTIVITY;
  static capacitance = quack.MeasurementType.MEASUREMENT_TYPE_CAPACITANCE;
  static weight = quack.MeasurementType.MEASUREMENT_TYPE_WEIGHT;
}

export class Operator {
  static invalid = quack.RelationalOperator.RELATIONAL_OPERATOR_INVALID;
  static equals = quack.RelationalOperator.RELATIONAL_OPERATOR_EQUAL_TO;
  static less = quack.RelationalOperator.RELATIONAL_OPERATOR_LESS_THAN;
  static greater = quack.RelationalOperator.RELATIONAL_OPERATOR_GREATER_THAN;
}

export class Result {
  static invalid = quack.InteractionResultType.INTERACTION_RESULT_TYPE_INVALID;
  static report = quack.InteractionResultType.INTERACTION_RESULT_TYPE_REPORT;
  static toggle = quack.InteractionResultType.INTERACTION_RESULT_TYPE_TOGGLE;
  static run = quack.InteractionResultType.INTERACTION_RESULT_TYPE_RUN;
  static set = quack.InteractionResultType.INTERACTION_RESULT_TYPE_SET;
}

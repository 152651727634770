import { Box, List, ListItem, Typography } from "@material-ui/core";
import EndpointDisplay, { EndPointData } from "apiDocs/endpoints/endpointDisplay";
import React from "react";

/**
        {
            requestType: "",
            url: "https://api.brandxtech.ca/v1/",
            description: "",
            urlOptions: [],
            urlParams: [],
            requestBody: "",
            responseParams: []
        }, 
 */

export default function AviationEndpoints() {
  //note any requests that are not a get are commented out as our backend only allows get requests through the API for the moment
  //if you are here to put the other requests types into the api docs make sure to finish the documentation for each endpoint

  const terminalEndpoints: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/terminals",
      description: "gets a list of terminals",
      urlOptions: [
        "limit - the number of terminals to load",
        "offset - the number of terminals to skip over before loading",
        "order - the sort order",
        "by - what to sort by in the terminals settings",
        "search - a string to search the terminals settings and return matches"
      ],
      requestBody: "",
      responseParams: ["repeated Terminal terminals", "uint32 next_offset", "uint32 total"]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/terminals",
    //     description: "add a new terminal",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/terminals/:terminal",
    //     description: "update an existing terminal",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/terminals/:terminal",
    //     description: "remove an existing terminal",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];
  const gateEndpoints: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/gates",
      description: "gets list of gates",
      urlOptions: [
        "limit - the number of gates to load",
        "offset - the number of gates to skip before starting to load",
        "order - the sort order",
        "by - what to sort by in the gate settings",
        "search - a string to search the gate settings for and "
      ],
      responseParams: ["repeated Gate gates", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/gates/:gate/measurements",
      description: "get the measurements for the components linked to the gate",
      urlOptions: ["start - RFC3339 formatted timestamp", "end - RFC3339 formatted timestamp"],
      urlParams: [":gate - the key of the gate"],
      responseParams: ["repeated UnitMeasurementsForComponent measurements"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/gates/:gate",
      description: "get a specified gate",
      urlParams: [":gate - the key of the gate"],
      responseParams: ["Gate gate"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/gates/:gate/airflow",
      description: "get the estimated mass airflow of the gate",
      urlOptions: [
        "device - the id of the device",
        "ambient - the key of the component assigned to ambient temp",
        "pressure - the key of the component assigned to pressure",
        "start - RFC3339 formatted timestamp for the start of the measurement window",
        "start - RFC3339 formatted timestamp for the end of the measurement window"
      ],
      urlParams: [":gate - the key of the gate"],
      responseParams: ["repeated FlowAt values"]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/gates",
    //     description: "add a new gate",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // may restrict this one as it effectively changes permissions a gate has to a device
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/gates/:gate/link",
    //     description: "update the devices linked to the gate",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/gates/:gate",
    //     description: "update an existing gates settings",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/gatePrefs/:gate",
    //     description: "update the preferences of an existing gate",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/gates/:gate",
    //     description: "delete an existing gate",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];

  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for retrieving terminal data
      </Typography>
      <List>
        {terminalEndpoints.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for retrieving gate data
      </Typography>
      <List>
        {gateEndpoints.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

import { Card, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import DeviceLinkDrawer from "common/DeviceLinkDrawer";
import MapCard from "common/MapCard";
import ObjectControls from "common/ObjectControls";
import { useUserAPI, useMobile } from "hooks";
import SiteActions from "jobsite/SiteActions";
import { Device, Site as ISite, siteScope, teamScope } from "models";
import { ObjectHeater } from "models/ObjectHeater";
import { MatchParams } from "navigation/Routes";
import ObjectHeaterCard from "objectHeater/ObjectHeaterCard";
import { pond } from "protobuf-ts/pond";
import { useGlobalState, useObjectHeaterAPI, useSiteAPI } from "providers";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect, useHistory, useRouteMatch } from "react-router";
import ObjectUsers from "user/ObjectUsers";
import PageContainer from "./PageContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1),
      height: 520,
      overflowY: "scroll"
    }
  })
);

export default function Site() {
  //const location = useLocation();
  const match = useRouteMatch<MatchParams>();
  const siteKey = match.params.siteID;
  const history = useHistory();
  const classes = useStyles();
  const [site, setSite] = useState<ISite>();
  const [devices, setDevices] = useState<Map<string, pond.ComprehensiveDevice>>(
    new Map<string, pond.ComprehensiveDevice>()
  );
  const [heaters, setHeaters] = useState<pond.ObjectHeaterData[]>([]);
  //const deviceAPI = useDeviceAPI();
  const userAPI = useUserAPI();
  const siteAPI = useSiteAPI();
  const [{ as, user }] = useGlobalState();
  const [openLinkDrawer, setOpenLinkDrawer] = useState(false);
  const [permissions, setPermissions] = useState<pond.Permission[]>([]);
  const [loadingSite, setLoadingSite] = useState(false);
  const isMobile = useMobile();
  const [heaterToLink, setHeaterToLink] = useState<ObjectHeater>();
  const [invalid, setInvalid] = useState(false);
  const objectHeaterAPI = useObjectHeaterAPI();

  const loadSitePageData = useCallback(() => {
    if (!loadingSite) {
      setLoadingSite(true);
      siteAPI
        .getSitePage(siteKey)
        .then(resp => {
          setSite(ISite.any(resp.data.site));
          setHeaters(resp.data.heaterData);
          setLoadingSite(false);
        })
        .catch(err => {
          console.log(err);
          setInvalid(true);
        });
    }
  }, [siteAPI]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadSitePageData();
    let scope = siteScope(siteKey);
    if (as) {
      scope = teamScope(as);
    }
    userAPI.getUser(user.id(), scope).then(resp => {
      setPermissions(resp.permissions);
    });
  }, [as, user, userAPI, loadSitePageData]); //eslint-disable-line react-hooks/exhaustive-deps

  const goToMap = () => {
    history.push("/constructionsitemap", {
      long: site?.longitude(),
      lat: site?.latitude()
    });
  };

  const mobileView = () => {
    return (
      <React.Fragment>
        {heaters.map(h => (
          <ObjectHeaterCard
            key={h.heater?.key}
            heaterData={h}
            linkDevice={(heater, linkedDevices) => {
              setHeaterToLink(heater);
              setOpenLinkDrawer(true);
              let d = new Map<string, pond.ComprehensiveDevice>();
              linkedDevices.forEach(compDev => {
                let device = Device.any(compDev.device);
                d.set(device.id().toString(), compDev);
              });
              setDevices(d);
            }}
          />
        ))}
      </React.Fragment>
    );
  };

  const desktopView = () => {
    return (
      <React.Fragment>
        <Grid container direction="row" justify="flex-start" spacing={2} style={{ padding: 10 }}>
          <Grid item xs={4}>
            <Card className={classes.card}>
              {heaters.map(h => (
                <ObjectHeaterCard
                  key={h.heater?.key}
                  heaterData={h}
                  linkDevice={(heater, linkedDevices) => {
                    setHeaterToLink(heater);
                    setOpenLinkDrawer(true);
                    let d = new Map<string, pond.ComprehensiveDevice>();
                    linkedDevices.forEach(compDev => {
                      let device = Device.any(compDev.device);
                      d.set(device.id().toString(), compDev);
                    });
                    setDevices(d);
                  }}
                />
              ))}
            </Card>
          </Grid>
          <Grid item xs={4}>
            {site && (
              <MapCard
                key={"map"}
                path={[{ latitude: site.latitude(), longitude: site.longitude(), timestamp: "" }]}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.card}>
              {site && (
                <ObjectUsers
                  scope={siteScope(site.key())}
                  label={"Users For " + site.siteName()}
                  permissions={permissions}
                  isDialogOpen={true}
                  refreshCallback={() => {}}
                  closeDialogCallback={() => {}}
                  cardMode
                />
              )}
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  if (invalid) {
    return <Redirect to="/404" />;
  }
  return (
    <PageContainer>
      <DeviceLinkDrawer
        open={openLinkDrawer}
        close={() => {
          setOpenLinkDrawer(false);
        }}
        linkedDevices={devices}
        updateLinkedDevices={(dev, linked) => {
          let deviceID = dev.device?.settings?.deviceId;
          if (deviceID && heaterToLink) {
            if (linked) {
              objectHeaterAPI
                .updateLink(heaterToLink.key, "objectHeater", deviceID.toString(), "device", [
                  "read",
                  "write",
                  "grant",
                  "revoke"
                ])
                .then(resp => {
                  if (deviceID) {
                    devices.set(deviceID.toString(), dev);
                    setOpenLinkDrawer(false);
                    loadSitePageData();
                  }
                });
            }
          }
        }}
      />
      <ObjectControls
        actions={
          <SiteActions
            site={site ?? ISite.create()}
            permissions={permissions}
            refreshCallback={() => {}}
          />
        }
        mapFunction={goToMap}
        // linkDeviceFunction={() => {
        //   setOpenLinkDrawer(true);
        // }}
        teamScope={isMobile && site ? siteScope(site.key()) : undefined}
        permissions={permissions}
      />
      <Typography variant="h5" style={{ margin: 10, fontWeight: 650 }}>
        {site?.siteName()}
      </Typography>
      {isMobile ? mobileView() : desktopView()}
    </PageContainer>
  );
}

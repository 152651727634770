import bins from "common/DataImports/BinCables/bin_cable_data_with_count.json";
import brackets from "common/DataImports/BinCables/bracketData.json";

//note all length measurements are in feet
export interface Cable {
  Orbit: number; //the orbit the cable is on in the bin
  Length: number; //length of the cable
  Nodes: number; //number of nodes on the cable
  Type: number; //cable being temp or moisture
  Count: number; //the number of similar cables
}

export interface CableSum {
  Orbit: number;
  NumberOfCables: number;
  DistanceFromCenter: number;
  Bracket: number;
}

export interface jsonBin {
  ID: number;
  Manufacturer: string;
  Type: string;
  Bottom: string;
  Diameter: number;
  Name: string;
  Model: string;
  Capacity: number;
  Rings: number;
  Sidewall: number;
  Peak: number;
  EaveToPeak: number;
  RoofAngle: number;
  HopperAngle: number;
  CableSums: CableSum[];
  Cables: Cable[];
}

export interface CableBracket {
  ID: number;
  Name: string;
  DealerPrice: number;
}

export function ImportBins() {
  return bins;
}

export function ImportBinMap() {
  return new Map(bins.map(bin => [bin.ID, bin]));
}

export function getBinModel(id: number) {
  return ImportBinMap().get(id);
}

export function BracketMap() {
  let bMap = new Map<number, CableBracket>();
  brackets.forEach(b => {
    bMap.set(b.ID, b);
  });
  return bMap;
}

export function getBracket(bracketModel: number) {
  return BracketMap().get(bracketModel);
}

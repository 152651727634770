import { createStyles, Icon, makeStyles, Theme } from "@material-ui/core";
import Image from "material-ui-image";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      textAlign: "center",
      margin: "1px"
    },
    img: {
      height: "100%",
      width: "auto !important"
    }
  })
);

interface Props {
  src?: string;
  alt?: string;
  style?: any;
}

export function ImgIcon(props: Props) {
  const { src, alt, style } = props;
  const classes = useStyles();

  if (!src) return null;

  return (
    <Icon className={classes.icon}>
      <Image
        alt={alt}
        className={classes.img}
        src={src}
        disableSpinner
        disableTransition
        color="transparent"
        imageStyle={style}
      />
    </Icon>
  );
}

import moment from "moment";

type Language = "en";

export const LoadLocale = (language: Language) => {
  switch (language) {
    default:
      moment.updateLocale("en", {
        relativeTime: {
          future: "in %s",
          past: "%s ago",
          s: "seconds",
          ss: "%ss",
          m: "a minute",
          mm: "%dmin",
          h: "an hour",
          hh: "%dh",
          d: "a day",
          dd: "%dd",
          M: "a month",
          MM: "%dmo",
          y: "a year",
          yy: "%d years"
        }
      });
  }
};

import HomeIconLight from "assets/products/Ag/HomeIconLight.png";
import HomeIconDark from "assets/products/Ag/HomeIconDark.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function HomeIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? HomeIconLight : HomeIconDark;
    }

    return themeType === "light" ? HomeIconDark : HomeIconLight;
  };

  return <ImgIcon alt="fields" src={src()} />;
}

import SmartBreadcrumb from "common/SmartBreadcrumb";
import MaterialTable, { Column, MTableToolbar } from "material-table";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import PageContainer from "./PageContainer";
import {
  makeStyles,
  Theme,
  createStyles,
  Tooltip,
  Chip,
  withStyles,
  useTheme,
  Box,
  Grid
} from "@material-ui/core";
import { getTableIcons } from "common/ResponsiveTable";
import { pond } from "protobuf-ts/pond";
import { useMineAPI, useMobile } from "hooks";
import { useHistory } from "react-router";
import AddMine from "ventilation/AddMine";
import { Add } from "@material-ui/icons";
import SearchBar from "common/SearchBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gutter: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
      }
    },
    actionToolbar: {
      paddingLeft: 0,
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        paddingRight: theme.spacing(2)
      }
    },
    densePaddingY: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    mobileContent: {
      maxWidth: "100%",
      margin: 0,
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`
    },
    tableContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
      },
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
      }
    }
  })
);

interface MineRow {
  mine: pond.Mine;
}

const mineColumns: Column<MineRow>[] = [
  {
    title: "Name",
    field: "name",
    render: row => {
      let key = row.mine.settings?.key ? row.mine.settings?.key : "No Key found";
      let name = row.mine.settings?.name ? row.mine.settings?.name : "Mine";
      return (
        <Tooltip title={"ID: " + key}>
          <Chip variant="outlined" label={name} />
        </Tooltip>
      );
    }
  }
];

export default function Mines() {
  const classes = useStyles();
  const history = useHistory();
  const mineAPI = useMineAPI();
  const isMobile = useMobile();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mineTableData, setMineTableData] = useState<MineRow[]>([]);
  const [minesTotal, setMinesTotal] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [mineDialog, setMineDialog] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const ColorAdd = withStyles((theme: Theme) => ({
    root: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.main,
      marginTop: "auto",
      marginBottom: "auto",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        cursor: "pointer"
      },
      "&:disabled": {
        backgroundColor: "rgba(0,0,0,0)",
        color: theme.palette.getContrastText(theme.palette.primary.main)
      }
    }
  }))(Add);

  const load = useCallback(() => {
    setIsLoading(true);
    mineAPI
      .listMines(pageSize, pageSize * page, "desc", undefined, searchValue, false)
      .then(resp => {
        let mineData: MineRow[] = [];
        resp.data.mines.forEach(mine => {
          mineData.push({
            mine: mine
          });
        });
        setMineTableData(mineData);
        setMinesTotal(resp.data.total);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pageSize, page, mineAPI, setIsLoading, searchValue]);

  useEffect(() => {
    load();
  }, [load]);

  const changeSearchValue = (newValue: string) => {
    let search = "";
    newValue.split(" ").forEach((substr, i) => {
      if (i === 0) {
        search = substr;
      } else if (substr.length > 0) {
        search = search + " <-> " + substr;
      }
    });
    setSearchValue(search);
  };

  const minesTable = () => {
    return (
      <MaterialTable
        isLoading={isLoading}
        key="minesList"
        icons={getTableIcons()}
        columns={mineColumns}
        data={mineTableData}
        page={page}
        totalCount={minesTotal}
        onChangePage={page => {
          setPage(page);
          //loadMore(page * pageSize);
        }}
        options={{
          paging: true,
          pageSize: pageSize,
          pageSizeOptions: [5, 10, 20],
          padding: "dense",
          showTitle: true,
          toolbar: true,
          sorting: true,
          columnsButton: true,
          search: false
          // searchText: or(new URLSearchParams(location.search).get("q"), undefined)
        }}
        localization={{
          body: { emptyDataSourceMessage: isLoading ? "" : "No mines found" }
        }}
        components={{
          Toolbar: props => (
            <React.Fragment>
              <MTableToolbar {...props} />
              {actionToolbar()}
            </React.Fragment>
          )
        }}
        title={<SmartBreadcrumb />}
        onRowClick={(event: any, row?: MineRow) => {
          if (row && row.mine && row.mine.settings && row.mine.settings.key) {
            history.push("/mines/" + row.mine.settings.key);
          }
        }}
        onChangeRowsPerPage={pageSize => setPageSize(pageSize)}
      />
    );
  };

  const actionToolbar = () => {
    return (
      <Box width="100%" paddingX={isMobile ? 1 : 3}>
        {isMobile && <SearchBar value={searchValue} onChange={changeSearchValue} />}
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          alignContent="center"
          style={{ marginTop: theme.spacing(2) }}>
          <Grid item xs={4}>
            <Tooltip title="New Mine">
              <ColorAdd onClick={() => setMineDialog(true)} />
            </Tooltip>
          </Grid>
          <Grid item xs={8} container justify="flex-end" alignItems="center">
            {!isMobile && <SearchBar value={searchValue} onChange={changeSearchValue} />}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const tableContent = () => {
    return <div className={classNames(classes.gutter, classes.tableContainer)}>{minesTable()}</div>;
  };

  const closeCallback = () => {
    setMineDialog(false);
  };

  return (
    <PageContainer>
      {tableContent()}
      <AddMine open={mineDialog} closeCallback={closeCallback} refreshCallback={load} />
    </PageContainer>
  );
}

import { Button, Typography } from "@material-ui/core";
import { withStyles, Theme, WithStyles, createStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import PageContainer from "./PageContainer";
import React from "react";
import { Link } from "react-router-dom";

const styles = (theme: Theme) =>
  createStyles({
    homeButton: {
      marginTop: theme.spacing(3)
    }
  });

interface Props extends WithStyles<typeof styles> {}

class PageNotFound extends React.Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <PageContainer fullViewport isCenterCenter>
        <Typography variant="h2" gutterBottom>
          404
        </Typography>
        <Typography variant="h4" gutterBottom>
          PAGE NOT FOUND
        </Typography>
        <Button
          variant="contained"
          color="primary"
          aria-label="Home"
          component={Link}
          to="/"
          className={classes.homeButton}>
          <HomeIcon /> Back Home
        </Button>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(PageNotFound);

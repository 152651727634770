import { makeStyles, createStyles, Theme, Button, ButtonProps } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Delete } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: "#fff",
      background: red["500"],
      "&:hover": {
        background: red["600"]
      }
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

const DeleteButton = (props: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      size="small"
      {...props}
      className={classes.deleteButton}
      aria-label="Delete">
      {props.children}
      <Delete className={classes.rightIcon} fontSize="small" />
    </Button>
  );
};

export default DeleteButton;

import { Box, BoxProps, createStyles, makeStyles, Theme } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import React, { PropsWithChildren } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      position: "absolute",
      left: 0,
      top: 0,
      transform: "translate(0, 24px) scale(1)"
    },
    notchedOutline: {
      borderRadius: theme.shape.borderRadius,
      opacity: theme.palette.action.activatedOpacity
    }
  })
);

interface Props extends BoxProps {
  label: string | React.ReactNode;
}

const OutlinedBox = (props: PropsWithChildren<Props>) => {
  const { label, children } = props;
  const classes = useStyles();
  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef<HTMLLabelElement>(null);
  React.useEffect(() => {
    const labelNode = labelRef.current;
    setLabelWidth(labelNode != null ? labelNode.offsetWidth : 0);
  }, [label]);

  return (
    <Box position="relative" width="100%" {...props}>
      <InputLabel ref={labelRef} variant="outlined" className={classes.inputLabel} shrink>
        {label}
      </InputLabel>
      <NotchedOutline notched labelWidth={labelWidth} classes={{ root: classes.notchedOutline }} />
      <Box position="relative">{children}</Box>
    </Box>
  );
};

export default OutlinedBox;

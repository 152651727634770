import { Card, createStyles, Divider, makeStyles, Theme, Typography } from "@material-ui/core";
import InlineIcon from "assets/editor/inlineCentrifugal.png";
import FullIcon from "assets/editor/fullCentrifugal.png";
import LowIcon from "assets/editor/lowCentrifugal.png";
import Fan1Icon from "assets/editor/fan1.png";
import Fan2Icon from "assets/editor/fan2.png";
import Fan3Icon from "assets/editor/fan3.png";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { Block } from "ventilation/drawable/Block";
import { pond } from "protobuf-ts/pond";
import { VentShaft } from "ventilation/drawable/VentShaft";
import { VentCorner } from "ventilation/drawable/VentCorner";
import { VentTBreak } from "ventilation/drawable/VentTBreak";

const ducts = [
  {
    name: "Duct Line",
    img: InlineIcon,
    editorImg: Fan1Icon,
    beta: false
  },
  {
    name: "Corner",
    img: FullIcon,
    editorImg: Fan2Icon,
    beta: false
  },
  {
    name: "T-Break",
    img: LowIcon,
    editorImg: Fan3Icon,
    beta: false
  },
  {
    name: "Y-Break (coming soon)",
    img: LowIcon,
    editorImg: Fan3Icon,
    beta: true
  }
];

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    topText: {
      //textAlign: "center",
      //alignContent: "center"
    },
    divider: {
      margin: theme.spacing(2)
    },
    topTextContainer: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      padding: theme.spacing(1),
      marginBottom: 0,
      paddingBottom: 0
    },
    fanCard: {
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      height: theme.spacing(10)
    },
    fanIcon: {
      height: "100%",
      objectFit: "contain"
    },
    fanIconContainer: {
      height: "100%",
      width: theme.spacing(8)
    },
    ductInfo: {
      display: "block",
      marginLeft: theme.spacing(1),
      marginTop: "auto",
      marginBottom: "auto"
    },
    addIcon: {
      //justifySelf: "flex-end",
      marginLeft: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: theme.spacing(1),
      "&:hover": {
        cursor: "pointer",
        background: "rgba(200,200,200, 0.25)"
      }
    }
  });
});

interface Props {
  addBlockRef: any;
}

export default function DuctingDrawer(props: Props) {
  //const isMobile = useMobile();
  //const { isOpen, children } = props;
  const classes = useStyles();
  const addBlockRef = props.addBlockRef;

  const addDuct = () => {
    let block = VentShaft.create({
      settings: {
        x: 64,
        y: 64,
        width: 64,
        height: 64,
        angle: 0
      }
    } as pond.Block);
    addBlockRef.current(block);
  };

  const addTBreak = () => {
    let block = VentTBreak.create({
      settings: {
        x: 64,
        y: 64,
        width: 64,
        height: 64,
        angle: 0
      }
    } as pond.Block);
    addBlockRef.current(block);
  };

  const addCorner = () => {
    let block = VentCorner.create(64, 64, 128, 1 * Math.PI);
    addBlockRef.current(block);
  };

  return (
    <React.Fragment>
      <div className={classes.topTextContainer}>
        <Typography className={classes.topText} color="textSecondary" variant="caption">
          Place a duct into the editor. If a duct or fan is selected in the editor, the new duct
          will be placed in line with it.
        </Typography>
      </div>
      <Divider className={classes.divider} />
      {ducts.map(duct => {
        return (
          <Card key={duct.name} className={classes.fanCard}>
            <div className={classes.fanIconContainer}>
              <img src={duct.img} alt={duct.name} className={classes.fanIcon} />
            </div>
            <div className={classes.ductInfo}>
              <Typography display="block" variant="body2">
                {duct.name}
              </Typography>
            </div>
            {!duct.beta && duct.name === "Duct Line" && (
              <AddIcon
                className={classes.addIcon}
                onClick={() => (addBlockRef.current ? addDuct() : undefined)}
              />
            )}
            {!duct.beta && duct.name === "Corner" && (
              <AddIcon
                className={classes.addIcon}
                onClick={() => (addBlockRef.current ? addCorner() : undefined)}
              />
            )}
            {!duct.beta && duct.name === "T-Break" && (
              <AddIcon
                className={classes.addIcon}
                onClick={() => (addBlockRef.current ? addTBreak() : undefined)}
              />
            )}
          </Card>
        );
      })}
    </React.Fragment>
  );
}

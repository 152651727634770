import { Placeable } from "./Placeable";

export function isPointInRectangle(
  x: number,
  y: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number
) {
  const minX = Math.min(x1, x2, x3, x4);
  const maxX = Math.max(x1, x2, x3, x4);
  const minY = Math.min(y1, y2, y3, y4);
  const maxY = Math.max(y1, y2, y3, y4);

  if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
    const d1 = (x - x1) * (y2 - y1) - (y - y1) * (x2 - x1);
    const d2 = (x - x2) * (y3 - y2) - (y - y2) * (x3 - x2);
    const d3 = (x - x3) * (y4 - y3) - (y - y3) * (x4 - x3);
    const d4 = (x - x4) * (y1 - y4) - (y - y4) * (x1 - x4);

    return (d1 >= 0 && d2 >= 0 && d3 >= 0 && d4 >= 0) || (d1 <= 0 && d2 <= 0 && d3 <= 0 && d4 <= 0);
  }

  return false;
}

export function getRectangularPoints(
  x: number,
  y: number,
  width: number,
  height: number,
  angle: number
) {
  let x1 = x + (Math.cos((angle - 90) * (Math.PI / 180)) * height) / 2;
  let y1 = y - (Math.sin((angle - 90) * (Math.PI / 180)) * height) / 2;
  let x2 = x1 + Math.cos(angle * (Math.PI / 180)) * width;
  let y2 = y1 - Math.sin(angle * (Math.PI / 180)) * width;
  let x3 = x2 + Math.cos((angle + 90) * (Math.PI / 180)) * height;
  let y3 = y2 - Math.sin((angle + 90) * (Math.PI / 180)) * height;
  let x4 = x3 + Math.cos((angle - 180) * (Math.PI / 180)) * width;
  let y4 = y3 - Math.sin((angle - 180) * (Math.PI / 180)) * width;
  /*console.log((angle - 90) * (Math.PI / 180))
  console.log((angle) * (Math.PI / 180))
  console.log((angle + 90) * (Math.PI / 180))
  console.log("(" + x1 + ", " + y1 + ")")
  console.log("(" + x2 + ", " + y2 + ")")
  console.log("(" + x3 + ", " + y3 + ")")
  console.log("(" + x4 + ", " + y4 + ")")*/
  return { x1, y1, x2, y2, x3, y3, x4, y4 };
}

export interface SnapPoint {
  x: number;
  y: number;
  angle: number;
  startPoint: boolean;
  occupant: Placeable | undefined;
}

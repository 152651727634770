import { useSnackbar, useStripeAPI } from "providers";
import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CreateTokenCardData } from "@stripe/stripe-js";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { grey, common } from "@material-ui/core/colors";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function StripeCard(props: Props) {
  const [loading, setLoading] = useState(false);

  const stripeAPI = useStripeAPI();

  const stripe = useStripe();
  const elements = useElements();
  const snackbar = useSnackbar();

  const options = {
    style: {
      base: {
        iconColor: grey[200],
        color: common.white,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        // fontSize: theme.typography.fontSize.toString(),
        fontSize: "16px",
        "::placeholder": {
          color: grey[500]
        }
      }
    }
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    event.preventDefault();

    const cardElement = elements.getElement("card");
    const cardData: CreateTokenCardData = {};

    // cardData.name = name;
    // cardData.address_city = city;
    // cardData.address_country = country;
    // cardData.address_line1 = address1;
    // cardData.address_line2 = address2;
    // cardData.address_state = state;

    setLoading(true);
    stripe
      .createToken(cardElement!, cardData)
      .then(payload => {
        if (!payload.error) {
          stripeAPI
            .newCard(payload.token.id)
            .then(resp => {
              if (resp.data === "Card was not created" || resp.data === "User not found") {
                snackbar.error("Card was not created");
              } else {
                snackbar.success("Card successfully created");
              }
            })
            .catch(err => {
              if (err.response.data.error.toString().includes("No such customer")) {
                snackbar.error("Customer error, contact us");
              } else if (err.response.data.error.toString().includes("https://stripe.com")) {
                snackbar.error("Unknown stripe error");
              } else {
                snackbar.error(err.response.data.error);
              }
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs>
            <CardElement options={options} />
          </Grid>
          <Grid item>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" color="primary" disabled={!stripe}>
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
        {/* <br />
        <Typography style={{ marginBottom: theme.spacing(0.5) }}>
          Cardholder Information (optional)
        </Typography>

        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            <Grid container direction="row" spacing={1}>
              <Grid item xs>
                <TextField
                  placeholder="Cardholder Name"
                  variant="outlined"
                  value={name}
                  onChange={e => setName(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
              <Grid item xs>
                <TextField
                  placeholder="Cardholder City"
                  variant="outlined"
                  value={city}
                  onChange={e => setCity(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={8}>
                <TextField
                  placeholder="Address Line 1"
                  variant="outlined"
                  value={address1}
                  onChange={e => setAddress1(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  placeholder="Address Line 2"
                  variant="outlined"
                  value={address2}
                  onChange={e => setAddress2(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={8}>
                <TextField
                  placeholder="Country"
                  variant="outlined"
                  value={country}
                  onChange={e => setCountry(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  placeholder="State/Province"
                  variant="outlined"
                  value={state}
                  onChange={e => setState(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="flex-end">
            <Box marginTop={theme.spacing(0.25)}>
              <Button onClick={() => setOpen(false)}>Close</Button>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button type="submit" color="primary" disabled={!stripe}>
                  Submit
                </Button>
              )}
            </Box>
          </Grid>
        </Grid> */}
      </form>
    </React.Fragment>
  );
}

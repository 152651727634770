import {
  ComponentTypeExtension,
  Subtype,
  Summary,
  simpleMeasurements,
  simpleSummaries,
  unitMeasurementSummaries,
  AreaChartData,
  GraphFilters,
  simpleAreaChartData,
  simpleLineChartData,
  LineChartData
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import TempDark from "assets/components/temperatureDark.png";
import TempLight from "assets/components/temperatureLight.png";
import { convertedUnitMeasurement } from "models/UnitMeasurement";
import { pond } from "protobuf-ts/pond";

export function Temperature(subtype: number = 0): ComponentTypeExtension {
  let temperature = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_TEMPERATURE,
    quack.ComponentType.COMPONENT_TYPE_TEMPERATURE,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_TEMPERATURE,
    subtypes: [
      {
        key: quack.TemperatureSubtype.TEMPERATURE_SUBTYPE_NONE,
        value: "TEMPERATURE_SUBTYPE_NONE",
        friendlyName: "Temperature"
      } as Subtype,
      {
        key: quack.TemperatureSubtype.TEMPERATURE_SUBTYPE_ONEWIRE_DS18,
        value: "TEMPERATURE_SUBTYPE_ONEWIRE_DS18",
        friendlyName: "Onewire DS18"
      } as Subtype,
      {
        key: quack.TemperatureSubtype.TEMPERATURE_SUBTYPE_ANALOG,
        value: "TEMPERATURE_SUBTYPE_ANALOG",
        friendlyName: "Analog Temperature"
      } as Subtype
    ],
    friendlyName: "Temperature",
    description: "Measures the temperature",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(temperature),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, temperature);
    },
    icon: (theme?: "light" | "dark") => {
      return theme === "light" ? TempDark : TempLight;
    },
    unitMeasurementSummary: (
      measurements: convertedUnitMeasurement,
      excludedNodes?: number[]
    ): Summary[] => {
      return unitMeasurementSummaries(
        measurements,
        quack.ComponentType.COMPONENT_TYPE_TEMPERATURE,
        subtype,
        excludedNodes
      );
    },
    areaChartData: (
      measurement: pond.UnitMeasurementsForComponent,
      smoothingAverages?: number,
      filters?: GraphFilters
    ): AreaChartData => {
      return simpleAreaChartData(measurement, smoothingAverages, filters);
    },
    lineChartData: (
      measurement: pond.UnitMeasurementsForComponent,
      smoothingAverages?: number,
      filters?: GraphFilters
    ): LineChartData => {
      return simpleLineChartData(
        quack.ComponentType.COMPONENT_TYPE_TEMPERATURE,
        measurement,
        smoothingAverages,
        filters
      );
    },
    minMeasurementPeriodMs: 1000
  };
}

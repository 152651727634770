import { useHTTP } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pond } from "protobuf-ts/pond";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";
import { useGlobalState } from "providers";
import { GrainBag } from "models/GrainBag";

export interface IGrainBagInterface {
  addGrainBag: (
    name: string,
    settings: pond.GrainBagSettings
  ) => Promise<AxiosResponse<pond.AddGrainBagResponse>>;
  listGrainBags: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    keys?: string[],
    types?: string[],
    numerical?: boolean,
    specificUser?: string
  ) => Promise<AxiosResponse<pond.ListGrainBagsResponse>>;
  updateGrainBag: (
    key: string,
    name: string,
    settings: pond.GrainBagSettings
  ) => Promise<AxiosResponse<pond.UpdateGrainBagResponse>>;
  bulkUpdateGrainBags: (
    bags: pond.GrainBag[]
  ) => Promise<AxiosResponse<pond.BulkGrainBagUpdateResponse>>;
  getGrainBag: (key: string) => Promise<AxiosResponse<pond.GetGrainBagResponse>>;
  removeGrainBag: (key: string) => Promise<AxiosResponse<pond.RemoveGrainBagResponse>>;
  listHistory: (
    id: string,
    limit: number,
    offset: number,
    start?: string,
    end?: string
  ) => Promise<AxiosResponse<pond.ListGrainBagHistoryResponse>>;
}

export const GrainBagAPIcontext = createContext<IGrainBagInterface>({} as IGrainBagInterface);

interface Props {}

export default function GrainBagProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();
  const [{ as }] = useGlobalState();

  const addGrainBag = (name: string, settings: pond.GrainBagSettings) => {
    if (as) {
      return post<pond.AddGrainBagResponse>(
        pondURL("/grainbags?name=" + name + "&as=" + as),
        settings
      );
    }
    return post<pond.AddGrainBagResponse>(pondURL("/grainbags?name=" + name), settings);
  };

  const listGrainBags = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    keys?: string[],
    types?: string[],
    numerical?: boolean,
    specificUser?: string
  ) => {
    let asText = "";
    if (as) asText = "&as=" + as;
    if (specificUser) asText = "&as=" + specificUser;
    return get<pond.ListGrainBagsResponse>(
      pondURL(
        "/grainbags?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + (order ? order : "asc")) +
          ("&by=" + (orderBy ? orderBy : "key")) +
          (numerical ? "&numerical=true" : "") +
          (search ? "&search=" + search : "") +
          (keys ? "&keys=" + keys.toString() : "") +
          (types ? "&types=" + types.toString() : "") +
          asText
      )
    );
  };

  const updateGrainBag = (key: string, name: string, settings: pond.GrainBagSettings) => {
    if (as) {
      return put<pond.UpdateGrainBagResponse>(
        pondURL("/grainbags/" + key + "?as=" + as + "&name=" + name),
        settings
      );
    }
    return put<pond.UpdateGrainBagResponse>(
      pondURL("/grainbags/" + key + "?name=" + name),
      settings
    );
  };

  const bulkUpdateGrainBags = (bags: pond.GrainBag[]) => {
    if (as)
      return put<pond.BulkGrainBagUpdateResponse>(pondURL("/bulkGrainBags/update?as=" + as), {
        bags: bags
      });
    return put<pond.BulkGrainBagUpdateResponse>(pondURL("/bulkGrainBags/update"), { bags: bags });
  };

  const removeGrainBag = (key: string) => {
    if (as) {
      return del<pond.RemoveGrainBagResponse>(pondURL("/grainbags/" + key + "?as=" + as));
    }
    return del<pond.RemoveGrainBagResponse>(pondURL("/grainbags/" + key + "?as=" + as));
  };

  const getGrainBag = (key: string) => {
    if (as) {
      return get<pond.GetGrainBagResponse>(pondURL("/grainbags/" + key + "?as=" + as));
    }
    return get<pond.GetGrainBagResponse>(pondURL("/grainbags/" + key));
  };

  const listHistory = (id: string, limit: number, offset: number, start?: string, end?: string) => {
    return get<pond.ListGrainBagHistoryResponse>(
      pondURL(
        "/grainbags/" +
          id +
          "/history?limit=" +
          limit +
          "&offset=" +
          offset +
          (start && "&start=" + start) +
          (end && "&end=" + end) +
          (as && "&as=" + as)
      )
    );
  };

  return (
    <GrainBagAPIcontext.Provider
      value={{
        addGrainBag,
        listGrainBags,
        updateGrainBag,
        bulkUpdateGrainBags,
        removeGrainBag,
        getGrainBag,
        listHistory
      }}>
      {children}
    </GrainBagAPIcontext.Provider>
  );
}

export const useGrainBagAPI = () => useContext(GrainBagAPIcontext);

import { createStyles, Theme, makeStyles, useTheme } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";
import { getDarkLogo, getLightLogo } from "services/whiteLabel";

const useStyles = makeStyles((theme: Theme) => {
  const smallSize = theme.breakpoints.down("sm") ? 32 : theme.breakpoints.up("lg") ? 64 : 48;

  return createStyles({
    loader: {
      height: "20vh",
      width: "20vh",
      position: "relative"
    },
    "@keyframes spin": {
      from: {
        transform: "rotate(0deg)"
      },
      to: {
        transform: "rotate(360deg)"
      }
    },
    spinner: {
      position: "absolute",
      width: "20vh",
      height: "20vh",
      lineHeight: "20vh",
      margin: "0 auto",
      borderRadius: "50%",
      zIndex: 9,
      animation: "$spin 0.75s linear infinite"
    },
    lightSpinner: {
      borderTop: "0.25rem solid #fff"
    },
    darkSpinner: {
      borderTop: "0.25rem solid #404040"
    },
    spinnerLogo: {
      display: "block",
      height: "8vh",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)"
    },
    smallLoader: {
      height: smallSize,
      width: smallSize
    },
    smallSpinner: {
      width: smallSize,
      height: smallSize,
      lineHeight: smallSize,
      borderTopWidth: theme.spacing(0.25)
    },
    smallSpinnerLogo: {
      height: smallSize / 2
    }
  });
});

interface Props {
  size?: "small" | "default";
}

export default function Loader(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const { size } = props;
  const isDarkTheme = theme.palette.type === "dark";
  return (
    <div className={classnames(classes.loader, size === "small" && classes.smallLoader)}>
      <div
        className={classnames(
          classes.spinner,
          isDarkTheme ? classes.lightSpinner : classes.darkSpinner,
          size === "small" && classes.smallSpinner
        )}
      />
      <img
        className={classnames(classes.spinnerLogo, size === "small" && classes.smallSpinnerLogo)}
        src={isDarkTheme ? getLightLogo() : getDarkLogo()}
        alt={process.env.REACT_APP_WEBSITE_TITLE + "-loader"}
      />
    </div>
  );
}

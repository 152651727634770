import React, { useEffect, useRef } from "react";
import ChatMessage from "./ChatMessage";
import { Box, Button } from "@material-ui/core";

import { Note } from "models";
import { pond } from "protobuf-ts/pond";

interface Props {
  messages: Note[];
  attachmentMap?: Map<string, pond.FileReference[]>;
  totalMessages: number;
  scrollPos: number;
  removeNoteMethod: (index: number) => void;
  loadMore: () => void;
}

/**
 * will load chat messages for the passed in object and display as a list of chat messages
 * @returns List of chat messages
 */
export default function ChatOutput(props: Props) {
  const ScrollTo = () => {
    const elementRef = useRef<null | HTMLDivElement>(null);
    useEffect(() =>
      elementRef?.current?.scrollIntoView({
        block: "end"
      })
    );
    let elem = <div ref={elementRef} />;
    return elem;
  };

  // const LoadMore = () => {
  //   const elementRef = useRef<null | HTMLDivElement>(null);

  //   let elem =
  //     <div style={{textAlign: "center"}} ref={elementRef}>
  //       <CircularProgress />
  //     </div>;

  //     if(CheckVisible(elementRef, "0px"))
  //     {
  //       props.loadMore()
  //     }

  //   return elem;
  // }

  const seeMore = () => {
    props.loadMore();
  };

  const displayMessages = () => {
    let display = props.messages.map((curMessage, index) => (
      <div key={index}>
        <ChatMessage
          note={curMessage}
          index={index}
          attachments={props.attachmentMap?.get(curMessage.key())}
          removeNoteMethod={props.removeNoteMethod}
        />
        {props.scrollPos !== 0 && index === props.scrollPos && <ScrollTo />}
      </div>
    ));
    return display;
  };

  return (
    <Box
      //height="85%"
      height="auto"
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
        borderRadius: "4px"
      }}>
      {props.messages.length < props.totalMessages && (
        <div style={{ textAlign: "center" }}>
          <Button onClick={seeMore}>See More</Button>
        </div>
      )}
      {displayMessages()}
      {props.scrollPos === 0 && <ScrollTo />}
    </Box>
  );
}

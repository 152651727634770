function getEnvironment() {
  switch (process.env.NODE_ENV) {
    case "development":
      return "development";
    case "production":
      if (window.location.hostname.includes("staging")) {
        return "staging";
      } else {
        return "production";
      }
    case "test":
      return "test";
    default:
      return "";
  }
}

export function isOffline() {
  return process.env.REACT_APP_IS_OFFLINE === "true";
}

export function isDevelopment() {
  return getEnvironment() === "development";
}

export function isProduction() {
  return getEnvironment() === "production";
}

export function isStaging() {
  return getEnvironment() === "staging";
}

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Theme,
  Typography,
  createStyles,
  makeStyles
} from "@material-ui/core";
import { useSnackbar, useStripeAPI } from "providers";
import React, { useEffect, useState } from "react";
import { Card } from "@stripe/stripe-js";
import { ExpandLess, ExpandMore, CreditCard } from "@material-ui/icons";
import DeleteButton from "common/DeleteButton";
import { preventDefault } from "@fullcalendar/react";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      flex: 1
    },
    cardCollapse: {
      margin: theme.spacing(1),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3)
    },
    center: {
      display: "flex",
      alignItems: "center"
    }
  });
});

interface Props {
  defaultSource?: string;
  reloadCustomer?: () => void;
  selectMode?: boolean;
  selectedCard?: string;
  setSelectedCard?: React.Dispatch<React.SetStateAction<string>>;
}

export default function StripeCardList(props: Props) {
  const { defaultSource, reloadCustomer, selectMode, selectedCard, setSelectedCard } = props;

  const stripeAPI = useStripeAPI();

  const classes = useStyles();
  const snackbar = useSnackbar();

  const [loadingCards, setLoadingCards] = useState<boolean>(false);
  const [expandedCard, setExpandedCard] = useState(-1);
  const [cardList, setCardList] = useState<Card[]>([]);
  const [removingCard, setRemovingCard] = useState(false);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  useEffect(() => {
    setLoadingCards(true);
    stripeAPI
      .listCards()
      .then(resp => {
        setCardList((resp.data as unknown) as Card[]);
      })
      .finally(() => {
        setLoadingCards(false);
      });
  }, [stripeAPI, setLoadingCards]);

  const removeCard = (id: string, index: number) => {
    setRemovingCard(true);
    stripeAPI
      .removeCard(id)
      .then(resp => {
        if (resp.data.length < 1) {
          snackbar.success("Card successfully removed");
          let newCardList = cardList;
          newCardList.splice(index, 1);
          setCardList(newCardList);
          setExpandedCard(-1);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setRemovingCard(false);
      });
  };

  const setDefault = (source: string) => {
    stripeAPI.setDefaultSource(source).then(resp => {
      if (resp.data === "success") {
        if (reloadCustomer) reloadCustomer();
      }
    });
  };

  return (
    <React.Fragment>
      {loadingCards ? (
        <Box className={classes.center}>
          <CircularProgress style={{ marginLeft: "auto", marginRight: "auto" }} />
        </Box>
      ) : (
        <React.Fragment>
          <List
            component="nav"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {cardList.length > 0 ? "Your Credit Cards" : "You have no cards added"}
              </ListSubheader>
            }>
            {cardList.map((card, cardIndex) => {
              let cardExp = months[card.exp_month];
              cardExp = cardExp + " " + card.exp_year;
              // cardExp = cardExp + " / " + card.exp_year;
              let isDefault = card.id === defaultSource;
              return (
                <React.Fragment key={"card-list-" + cardIndex}>
                  <ListItem
                    button
                    onClick={() => {
                      if (selectMode) {
                        setSelectedCard && setSelectedCard(card.id);
                      } else {
                        if (cardIndex !== expandedCard) {
                          setExpandedCard(cardIndex);
                        } else {
                          setExpandedCard(-1);
                        }
                      }
                    }}>
                    <ListItemIcon>
                      {!selectMode ? (
                        <CreditCard />
                      ) : (
                        <Checkbox
                          checked={card.id === selectedCard}
                          onChange={event => {
                            preventDefault(event);
                            setSelectedCard && setSelectedCard(card.id);
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={!isDefault ? card.brand : "⭐ " + card.brand}
                      secondary={"**** " + card.last4}
                    />
                    <ListItemText primary={"Expiry"} secondary={cardExp} />
                    {expandedCard === cardIndex ? <ExpandLess /> : !selectMode && <ExpandMore />}
                  </ListItem>
                  <Collapse in={expandedCard === cardIndex} timeout="auto" unmountOnExit>
                    <Box className={classes.cardCollapse}>
                      <Grid container direction="row">
                        <Grid item xs={4}>
                          {isDefault && (
                            <Typography variant="subtitle2" color="textSecondary">
                              This is your default
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={8}>
                          <Grid container justify="flex-end">
                            {removingCard ? (
                              <CircularProgress />
                            ) : (
                              <React.Fragment>
                                {!isDefault && (
                                  <Button
                                    variant="contained"
                                    onClick={() => setDefault(card.id)}
                                    style={{ marginRight: 3 }}>
                                    Set as Default
                                  </Button>
                                )}
                                <DeleteButton
                                  onClick={() => {
                                    removeCard(card.id, cardIndex);
                                  }}>
                                  Remove Card
                                </DeleteButton>
                              </React.Fragment>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Collapse>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

import { Box } from "@material-ui/core";
import React from "react";
import GeneralEnums from "./enumerators/generalEnums";

export default function Enumerators() {
  //const [currentTab, setCurrentTab] = useState("general");

  /* 
    for now all of the enums are in general since the most of the industry specific groupings dont have very many, 
    consider spliting them up when they have more 
  */
  return (
    <Box>
      {/* 
      <TabContext value={currentTab}>
  
        <Tabs
          value={currentTab}
          onChange={(_, value) => setCurrentTab(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth">
          <Tab label={"General"} value={"general"} />
          <Tab label={"Agriculture"} value={"agriculture"} />
          <Tab label={"Aviation"} value={"aviation"} />
          <Tab label={"Mining"} value={"mining"} />
          <Tab label={"Construction"} value={"construction"} />
        </Tabs>
        <TabPanel value="general">
          <GeneralEnums />
        </TabPanel>
        <TabPanel value="agriculture">
          <AgricultureEnums />
        </TabPanel>
        <TabPanel value="aviation">
          <AviationEnums />
        </TabPanel>
        <TabPanel value="mining">
          <MiningEnums />
        </TabPanel>
        <TabPanel value="construction">
          <ConstructionEnums />
        </TabPanel> 
      </TabContext>
      */}
      <GeneralEnums />
    </Box>
  );
}

import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { cloneDeep } from "lodash";

export class Backpack {
  public settings: pond.BackpackSettings = pond.BackpackSettings.create();
  public static create(pb?: pond.Backpack): Backpack {
    let my = new Backpack();
    if (pb) {
      my.settings = pond.BackpackSettings.fromObject(cloneDeep(or(pb.settings, {})));
    }
    return my;
  }

  public static any(data: any): Backpack {
    return Backpack.create(pond.Backpack.fromObject(cloneDeep(data)));
  }

  public id(): number {
    return Number(this.settings.backpackId);
  }

  public name(): string {
    return this.settings.name !== "" ? this.settings.name : "Device Profile " + this.id();
  }
}

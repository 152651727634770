import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import JDAccess from "integrations/JohnDeere/JDAccess";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers";
import { useJohnDeereProxyAPI } from "providers/pond/johnDeereProxyAPI";
import React, { useEffect, useState } from "react";
import PageContainer from "./PageContainer";

export default function JohnDeere() {
  const jdAPI = useJohnDeereProxyAPI();
  const [{ as }] = useGlobalState();
  const [organizations, setOrganizations] = useState<Map<string, pond.JDAccount>>(new Map());
  const [currentOrg, setCurrentOrg] = useState<string>("");
  //booleans for what is selected for import with the jd organization
  //const [fields, setFields] = useState(false);
  const [dataOptions, setDataOptions] = useState<pond.DataOption[]>([]);

  //accordions status
  const [fieldAccordion, setFieldAccordion] = useState(false);
  // const [equipmentAccordion, setEquipmentAccordion] = useState(false);
  // const [additivesAccordion, setAdditivesAccordion] = useState(false);
  // const [managementAccordion, setManagementAccordion] = useState(false);

  //load organizations for the user
  useEffect(() => {
    setCurrentOrg("");
    jdAPI
      .listAccounts(0, 0, as)
      .then(resp => {
        let tempOrgs: Map<string, pond.JDAccount> = new Map();
        resp.data.accounts.forEach(org => {
          let organization = pond.JDAccount.fromObject(org);
          tempOrgs.set(organization.key, organization);
        });
        setOrganizations(tempOrgs);
      })
      .catch(err => {});
  }, [jdAPI, as]);

  useEffect(() => {
    let organization = organizations.get(currentOrg);
    if (organization) {
      let currentOptions: pond.DataOption[] = organization.options ?? [];
      setDataOptions(currentOptions);
    }
  }, [currentOrg, organizations]);

  const updateOrgData = (checked: boolean, option: pond.DataOption) => {
    // let organization = organizations.get(currentOrg);
    // if (organization) {
    //   if (checked && !organization.options.includes(option)) {
    //     organization.options.push(option);
    //   } else if (!checked && organization.options.includes(option)) {
    //     organization.options.splice(organization.options.indexOf(option), 1);
    //   }
    // }
    let currentOps: pond.DataOption[] = dataOptions;
    if (checked && !currentOps.includes(option)) {
      currentOps.push(option);
    } else if (!checked && currentOps.includes(option)) {
      currentOps.splice(currentOps.indexOf(option), 1);
    }
    setDataOptions([...currentOps]);
  };

  const submit = () => {
    jdAPI.updateAccount(currentOrg, dataOptions, as ?? undefined).then(resp => {
      //update the organization in the map to have the correct dataOptions
      let org = organizations.get(currentOrg);
      if (org) {
        org.options = dataOptions;
      }
    });
  };

  const fieldOptions = () => {
    return (
      <React.Fragment>
        <Accordion
          expanded={fieldAccordion}
          onChange={(_, expanded) => {
            setFieldAccordion(expanded);
          }}>
          <AccordionSummary expandIcon={<ExpandMore />}>Field Data</AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" spacing={2} alignItems="center" alignContent="center">
              <Grid item xs={6}>
                <FormControlLabel
                  label="Field Boundaries"
                  control={
                    <Checkbox
                      checked={dataOptions.includes(pond.DataOption.DATA_OPTION_FIELDS)}
                      onChange={(_, checked) => {
                        //setFields(!fields);
                        updateOrgData(checked, pond.DataOption.DATA_OPTION_FIELDS);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                View Field Boundaries from John Deere and set up harvest plans and track tasks
              </Grid>
              {/* not yet implemented in the backend */}
              {/* <Grid item xs={6}>
                <FormControlLabel
                  label="Field Flags"
                  control={
                    <Checkbox
                      checked={dataOptions.includes(pond.DataOption.DATA_OPTION_FLAGS)}
                      onChange={(_, checked) => {
                        updateOrgData(checked, pond.DataOption.DATA_OPTION_FLAGS);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                Plots your flags as markers on your Visual Farm
              </Grid> */}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  };

  // const equipmentOptions = () => {
  //   return (
  //     <React.Fragment>
  //       <Accordion
  //         expanded={equipmentAccordion}
  //         onChange={(_, expanded) => {
  //           setEquipmentAccordion(expanded);
  //         }}>
  //         <AccordionSummary expandIcon={<ExpandMore />}>Equipment</AccordionSummary>
  //         <AccordionDetails>
  //           <Grid container direction="row" spacing={2} alignItems="center" alignContent="center">
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Machinery"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_MACHINES)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_MACHINES);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               View information about your machinery from John Deere
  //             </Grid>
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Implements"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_IMPLEMENTS)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_IMPLEMENTS);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               Implement Information
  //             </Grid>
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Maintenance"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_MAINTENENCE)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_MAINTENENCE);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               Equipment Maitenance details
  //             </Grid>
  //           </Grid>
  //         </AccordionDetails>
  //       </Accordion>
  //     </React.Fragment>
  //   );
  // };

  // const additivesOptions = () => {
  //   return (
  //     <React.Fragment>
  //       <Accordion
  //         expanded={additivesAccordion}
  //         onChange={(_, expanded) => {
  //           setAdditivesAccordion(expanded);
  //         }}>
  //         <AccordionSummary expandIcon={<ExpandMore />}>Additives</AccordionSummary>
  //         <AccordionDetails>
  //           <Grid container direction="row" spacing={2} alignItems="center" alignContent="center">
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Chemicals"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_CHEMICALS)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_CHEMICALS);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               View information about chemicals sprayed on fields
  //             </Grid>
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Varieties"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_VARIETIES)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_VARIETIES);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               Not sure what these are, may be grain varieties in which case this should go into
  //               the field data most likely
  //             </Grid>
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Fertilizers"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_FERTILIZER)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_FERTILIZER);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               Information about fetilizer used on fields
  //             </Grid>
  //           </Grid>
  //         </AccordionDetails>
  //       </Accordion>
  //     </React.Fragment>
  //   );
  // };

  // const managementOptions = () => {
  //   return (
  //     <React.Fragment>
  //       <Accordion
  //         expanded={managementAccordion}
  //         onChange={(_, expanded) => {
  //           setManagementAccordion(expanded);
  //         }}>
  //         <AccordionSummary expandIcon={<ExpandMore />}>Management</AccordionSummary>
  //         <AccordionDetails>
  //           <Grid container direction="row" spacing={2} alignItems="center" alignContent="center">
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Staff"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_STAFF)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_STAFF);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               View information about farm staff
  //             </Grid>
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="files"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_FILES)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_FILES);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               Not sure what this pulls
  //             </Grid>
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Tasks"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_TASKS)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_TASKS);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               Pull tasks into our task management system
  //             </Grid>
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Notifications"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_NOTIFICATIONS)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_NOTIFICATIONS);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               View Notifications and events
  //             </Grid>
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Assets"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_ASSETS)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_ASSETS);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               View Assets registered with john deere
  //             </Grid>
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Operators"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_OPERATORS)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_OPERATORS);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               View Operators
  //             </Grid>
  //             <Grid item xs={6}>
  //               <FormControlLabel
  //                 label="Clients"
  //                 control={
  //                   <Checkbox
  //                     checked={dataOptions.includes(pond.DataOption.DATA_OPTION_CLIENTS)}
  //                     onChange={(_, checked) => {
  //                       updateOrgData(checked, pond.DataOption.DATA_OPTION_CLIENTS);
  //                     }}
  //                   />
  //                 }
  //               />
  //             </Grid>
  //             <Grid item xs={6}>
  //               View Client information
  //             </Grid>
  //           </Grid>
  //         </AccordionDetails>
  //       </Accordion>
  //     </React.Fragment>
  //   );
  // };

  return (
    <PageContainer>
      <JDAccess />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignContent="center"
        alignItems="center"
        style={{ padding: 10 }}>
        <Grid item>
          <Select
            //style={{ maxWidth: 110 }}
            title="John Deer Account"
            displayEmpty
            disableUnderline={true}
            value={currentOrg}
            onChange={(event: any) => {
              setCurrentOrg(event.target.value);
            }}>
            <MenuItem key={""} value={""}>
              Select account to adjust accessable data
            </MenuItem>
            {Array.from(organizations.values()).map(org => {
              return (
                <MenuItem key={org.key} value={org.key}>
                  {org.username}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item>
          <Button onClick={submit} variant="contained" color="primary">
            Update
          </Button>
        </Grid>
      </Grid>

      <Box style={{ padding: 10 }}>
        {/* Field data accordion */}
        {fieldOptions()}
        {/* these three are not yet implemented TODO: reveal these options once implemented in the backend */}
        {/* 
        {equipmentOptions()}
        {additivesOptions()}
        {managementOptions()} 
        */}
      </Box>
    </PageContainer>
  );
}

import { cyan } from "@material-ui/core/colors";
import AcceptedIcon from "@material-ui/icons/CloudDone";
import PendingIcon from "@material-ui/icons/CloudQueueTwoTone";
import RejectedIcon from "@material-ui/icons/SmsFailed";
import React from "react";

export interface StatusHelper {
  description: string;
  icon: any;
}

const Unknown: StatusHelper = {
  description: "Status unknown",
  icon: null
};

const Pending: StatusHelper = {
  description: "Pending changes",
  icon: <PendingIcon style={{ color: cyan["600"] }} />
};

const Stale: StatusHelper = {
  description: "Stale update",
  icon: null
};

const Accepted: StatusHelper = {
  description: "Settings synced",
  icon: <AcceptedIcon style={{ color: "var(--status-ok)" }} />
};

const Rejected: StatusHelper = {
  description: "Update failed",
  icon: <RejectedIcon style={{ color: "var(--status-warning)" }} />
};

const Received: StatusHelper = {
  description: "Settings synced",
  icon: <AcceptedIcon style={{ color: "var(--status-ok)" }} />
};

const STATUS_MAP = new Map<string, StatusHelper>([
  ["pending", Pending],
  ["stale", Stale],
  ["accepted", Accepted],
  ["rejected", Rejected],
  ["received", Received]
]);

export function getStatusHelper(status: string): StatusHelper {
  const statuses = Array.from(STATUS_MAP.keys());
  for (var i = 0; i < statuses.length; i++) {
    let key = statuses[i];
    if (status === key) {
      return STATUS_MAP.get(key) as StatusHelper;
    }
  }

  return Unknown;
}

export function getStatusDescription(status: string): string {
  return getStatusHelper(status).description;
}

export function getStatusIcon(status: string): any {
  return getStatusHelper(status).icon;
}

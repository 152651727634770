import {
  Avatar,
  Button,
  Checkbox,
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@material-ui/core";
import { lightBlue, purple } from "@material-ui/core/colors";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { PaletteColor } from "@material-ui/core/styles/createPalette";
import {
  CreditCard,
  ExitToApp,
  FileCopy,
  Lock,
  LockOpen,
  Person,
  PersonAdd,
  Settings,
  SupervisedUserCircle as TeamIcon
} from "@material-ui/icons";
import ThemeIcon from "common/ThemeIcon";
import { useAuth, useSnackbar, useUserAPI } from "hooks";
import { useGlobalState } from "providers";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { isOffline } from "utils/environment";
import { getSignatureAccentColour } from "services/whiteLabel";
import AccessObject from "./AccessObject";
import UserSettings from "./UserSettings";
import TeamDialog from "teams/TeamDialog";
import UserAvatar from "./UserAvatar";
import HelpIcon from "@material-ui/icons/Help";
import { User } from "models";
import ResponsiveDialog from "common/ResponsiveDialog";
import StripeDialog from "billing/StripeDialog";
import { pond } from "protobuf-ts/pond";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    white: {
      color: "#fff",
      borderColor: "#fff"
    },
    signIn: {
      color: getSignatureAccentColour(),
      borderColor: getSignatureAccentColour()
    },
    userAvatar: {
      color: "#fff",
      backgroundColor: theme.palette.secondary["700" as keyof PaletteColor],
      [theme.breakpoints.down("xs")]: {
        width: "32px",
        height: "32px"
      }
    },
    userAvatar2: {
      color: "#fff",
      backgroundColor: theme.palette.secondary["700" as keyof PaletteColor],
      [theme.breakpoints.down("xs")]: {
        width: "30px",
        height: "30px"
      },
      position: "relative",
      top: -6,
      left: 0
    },
    teamAvatar: {
      color: "#fff",
      backgroundColor: theme.palette.secondary["700" as keyof PaletteColor],
      [theme.breakpoints.down("xs")]: {
        width: "30px",
        height: "30px"
      },
      position: "absolute",
      top: -34,
      left: 8
    },
    accessIcon: {
      color: "var(--status-ok)"
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    copyIcon: {
      color: lightBlue["700"]
    },
    profileButton: {
      maxWidth: "50vw"
    },
    profileName: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
      color: getSignatureAccentColour(),
      marginBottom: -3,
      marginTop: -3
    },
    red: {
      color: "var(--status-alert)"
    }
  })
);

interface Props {
  toggleTheme: () => void;
}

export default function UserMenu(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { userID } = useAuth();
  const { error } = useSnackbar();
  const { info, warning } = useSnackbar();
  const { toggleTheme } = props;
  const { isAuthenticated, token } = useAuth();
  const [{ user, team, as, newStructure, userTeamPermissions }, dispatch] = useGlobalState();
  const [userMenuIsOpen, setUserMenuIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [lockIsHovered, setLockIsHovered] = useState<boolean>(false);
  const [teamDialogIsOpen, setTeamDialogIsOpen] = useState<boolean>(false);
  const [userSettingsIsOpen, setUserSettingsIsOpen] = useState<boolean>(false);
  const [accessDialogOpen, setAccessDialogOpen] = useState<boolean>(false);
  const [tourDialog, setTourDialog] = useState<boolean>(false);
  const [billingDialog, setBillingDialog] = useState<boolean>(false);

  const userAPI = useUserAPI();
  const snackbar = useSnackbar();

  const openUserMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
    setUserMenuIsOpen(true);
  };

  const closeUserMenu = () => {
    setUserMenuIsOpen(false);
    setAnchorEl(null);
  };

  const lockHover = () => {
    setLockIsHovered(true);
  };

  const lockNoHover = () => {
    setLockIsHovered(false);
  };

  const handleLogin = () => {
    closeUserMenu();
    history.push("/login");
  };

  const handleLogout = () => {
    closeUserMenu();
    history.push("/logout");
  };

  const openUserSettingsDialog = () => {
    setUserSettingsIsOpen(true);
    closeUserMenu();
  };

  const closeUserSettingsDialog = () => {
    setUserSettingsIsOpen(false);
  };

  const openTeamDialog = () => {
    setTeamDialogIsOpen(true);
    closeUserMenu();
  };

  const openAccessObject = () => {
    setAccessDialogOpen(true);
    closeUserMenu();
  };

  const openBilling = () => {
    setBillingDialog(true);
    closeUserMenu();
  };

  const copyToken = () => {
    if (!token) {
      warning("Unable to copy token to clipboard");
      return;
    }

    navigator.clipboard.writeText(token.toString());
    info("Token copied to clipboard");
  };

  const dialogs = () => (
    <React.Fragment>
      <UserSettings isOpen={userSettingsIsOpen} closeDialogCallback={closeUserSettingsDialog} />
      <AccessObject
        isOpen={accessDialogOpen}
        closeDialogCallback={() => setAccessDialogOpen(false)}
      />
      <TeamDialog open={teamDialogIsOpen} setOpen={setTeamDialogIsOpen} />
      <ResponsiveDialog open={tourDialog} onClose={() => setTourDialog(false)}>
        <DialogTitle>Restart Tutorials?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Clicking "yes" will again take you through the introduction tour of the app. Would you
            like to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTourDialog(false)}>No</Button>
          <Button onClick={restartTours} color="primary">
            Yes
          </Button>
        </DialogActions>
      </ResponsiveDialog>
      <StripeDialog open={billingDialog} setOpen={setBillingDialog} />
    </React.Fragment>
  );

  const restartTours = () => {
    if (user) {
      closeUserMenu();
      setTourDialog(false);
      let u = user.protobuf();
      if (u.status) {
        u.status.finishedBinIntro = "";
        u.status.finishedIntro = "";
        userAPI
          .updateUser(userID, u)
          .then(() => {
            dispatch({ key: "user", value: User.any(u) });
          })
          .catch((err: any) => error(err));
      }
    }
  };

  const authenticatedUserMenu = () => {
    const name = user.name();
    const picture = user.settings.avatar;
    const hasAdmin = user.hasFeature ? user.hasFeature("admin") : false;
    const allowedToCopyToken = user.allowedTo("copy-token");
    const hasTeams = user.hasFeature ? user.hasFeature("teams") : false;
    const hasBilling = user.settings.useTeam
      ? userTeamPermissions.includes(pond.Permission.PERMISSION_BILLING)
      : true;

    return (
      <React.Fragment>
        <Button
          id="tour-user-menu"
          aria-owns={userMenuIsOpen ? "userMenu" : undefined}
          aria-haspopup="true"
          onClick={openUserMenu}
          className={classes.profileButton}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography className={classes.profileName} variant="button">
              {name}
            </Typography>
            {team.settings.name && hasTeams && (
              <Typography className={classes.profileName} variant="button">
                ({team.settings.name})
              </Typography>
            )}
          </div>
          <div style={{ marginLeft: theme.spacing(1) }}>
            {picture && hasTeams ? (
              <div>
                <UserAvatar
                  user={user}
                  className={classes.userAvatar2}
                  style={{ zIndex: as === "" ? 1000 : 0 }}
                />
                <div style={{ position: "relative" }}>
                  <Avatar
                    src={team.settings.avatar}
                    className={classes.teamAvatar}
                    style={{ background: purple[500] }}>
                    {/* Draw the team icon id src icon errors */}
                    <TeamIcon style={{ fontSize: 38 }} />
                  </Avatar>
                </div>
              </div>
            ) : picture ? (
              <Avatar alt={name} src={picture} className={classes.userAvatar}>
                {!picture && name}
              </Avatar>
            ) : (
              <Avatar alt={name} className={classes.userAvatar}>
                <Person />
              </Avatar>
            )}
          </div>
        </Button>
        <Menu
          id="userMenu"
          anchorEl={anchorEl}
          open={userMenuIsOpen}
          onClose={closeUserMenu}
          disableAutoFocusItem>
          <MenuItem onClick={openUserSettingsDialog} button aria-label="Open User Settings" dense>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="User Settings" />
          </MenuItem>
          {hasTeams && (
            <MenuItem onClick={openTeamDialog} button aria-label="Open Team Menu" dense>
              <ListItemIcon>
                <TeamIcon />
              </ListItemIcon>
              <ListItemText primary="Select Team" />
            </MenuItem>
          )}
          {hasTeams && (
            <MenuItem
              onClick={() => {
                if (as.length > 1) {
                  //userAp
                  //let settings = user.settings
                  user.settings.useTeam = false;
                  dispatch({ key: "as", value: "" });
                  userAPI.updateUser(user.id(), user.protobuf()).then(resp => {
                    snackbar.info("Will no longer view as team by default");
                  });
                } else {
                  //let settings = user.settings
                  user.settings.useTeam = true;
                  dispatch({ key: "as", value: team.key() });
                  userAPI.updateUser(user.id(), user.protobuf()).then(resp => {
                    snackbar.info("Will now view as " + team.name() + " by default");
                  });
                }
              }}
              button
              disabled={user.settings.defaultTeam.length < 1}
              aria-label="Open Team Menu"
              dense>
              <ListItemIcon>
                <Checkbox
                  disabled={user.settings.defaultTeam.length < 1}
                  checked={as.length > 1}
                  style={{ margin: 0, padding: 0 }}
                />
              </ListItemIcon>
              <ListItemText primary="View Site as Team" />
            </MenuItem>
          )}
          {/* TODO remove this when the old structure is no longer used */}
          {user.hasAdmin() && (
            <MenuItem
              onClick={() => {
                dispatch({ key: "newStructure", value: !newStructure });
              }}
              button
              dense>
              <ListItemIcon>
                <Checkbox checked={newStructure} style={{ margin: 0, padding: 0 }} />
              </ListItemIcon>
              <ListItemText primary="Use New Measurement Structure" />
            </MenuItem>
          )}
          {user.hasFeature("billing") && (
            <Tooltip title="Billing">
              <MenuItem onClick={() => openBilling()} disabled={!hasBilling} dense>
                <ListItemIcon>
                  <CreditCard />
                </ListItemIcon>
                <ListItemText primary="Billing" />
              </MenuItem>
            </Tooltip>
          )}
          <MenuItem onClick={toggleTheme} button aria-label="Toggle Theme" dense>
            <ListItemIcon>
              <ThemeIcon />
            </ListItemIcon>
            <ListItemText primary="Toggle Theme" />
          </MenuItem>
          <MenuItem onClick={() => setTourDialog(true)} button aria-label="Restart Tutorials" dense>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Restart Tutorials" />
          </MenuItem>
          <Divider />
          {hasAdmin && (
            <Tooltip title="Access Object">
              <MenuItem onClick={() => openAccessObject()} aria-label="Access Object" dense>
                <ListItemIcon>
                  <PersonAdd className={classes.accessIcon} />
                </ListItemIcon>
                <ListItemText primary="Access Object" />
              </MenuItem>
            </Tooltip>
          )}
          {allowedToCopyToken && (
            <MenuItem onClick={copyToken} button aria-label="Copy Token" dense>
              <ListItemIcon>
                <FileCopy className={classes.copyIcon} />
              </ListItemIcon>
              <ListItemText primary="Copy Token" />
            </MenuItem>
          )}
          {(hasAdmin || allowedToCopyToken) && <Divider />}
          {!isOffline() && (
            <MenuItem onClick={handleLogout} button aria-label="Sign Out" dense>
              <ListItemIcon>
                <ExitToApp className={classes.red} />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </MenuItem>
          )}
        </Menu>
        {dialogs()}
      </React.Fragment>
    );
  };

  const unauthenticatedUserMenu = () => {
    return (
      <React.Fragment>
        <Button
          variant="outlined"
          aria-label="Sign In"
          onClick={handleLogin}
          onMouseEnter={lockHover}
          onMouseLeave={lockNoHover}
          size="small"
          className={classes.signIn}>
          Sign In
          {lockIsHovered ? (
            <LockOpen className={classes.rightIcon} fontSize="small" />
          ) : (
            <Lock className={classes.rightIcon} fontSize="small" />
          )}
        </Button>

        <IconButton onClick={toggleTheme} className={classes.rightIcon} aria-label="Toggle Theme">
          <ThemeIcon />
        </IconButton>
      </React.Fragment>
    );
  };

  return isAuthenticated ? authenticatedUserMenu() : unauthenticatedUserMenu();
}

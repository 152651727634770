import moment, { Moment } from "moment";

export type DateRangePreset =
  | "live"
  | "pastHour"
  | "pastTwelveHours"
  | "pastDay"
  | "pastWeek"
  | "pastMonth"
  | "selectRange";

export interface DateRange {
  start: Moment;
  end: Moment;
  live?: boolean;
}

export const GetDefaultDateRange = (): DateRange => {
  let preset: DateRangePreset = getDefaultPreset();
  const now = moment();
  let start = moment().subtract(1, "days");
  let end = now;
  let live = false;
  switch (preset) {
    case "live":
      live = true;
      break;
    case "pastTwelveHours":
      start = moment().subtract(12, "hours");
      end = now;
      break;
    case "pastDay":
      start = moment().subtract(1, "days");
      end = now;
      break;
    case "pastWeek":
      start = moment().subtract(7, "days");
      end = now;
      break;
    case "pastMonth":
      start = moment().subtract(1, "months");
      end = now;
      break;
    default:
      //default to past hour
      start = moment().subtract(1, "hours");
      end = now;
      break;
  }
  return { start, end, live } as DateRange;
};

function getDefaultPreset(): DateRangePreset {
  let preset: string | null = localStorage.getItem("dateRangePreset");
  return preset ? (preset as DateRangePreset) : "pastHour";
}

export function SetDefaultPreset(preset: DateRangePreset) {
  localStorage.setItem("dateRangePreset", preset);
}

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { AccountCircle, MoreVert, Settings } from "@material-ui/icons";
import { isOffline } from "utils/environment";
import { mineScope } from "models";
import { pond } from "protobuf-ts/pond";
import React, { useState } from "react";
import ObjectUsers from "user/ObjectUsers";
import { or } from "utils";
import EditorSettings from "./EditorSettings";
import ObjectTeams from "teams/ObjectTeams";
import ObjectTeamsIcon from "@material-ui/icons/SupervisedUserCircle";
import { useGlobalState } from "providers";

interface Props {
  mine: pond.MineSettings;
  setMine: React.Dispatch<React.SetStateAction<pond.MineSettings>>;
  permissions: any;
  refreshCallback: () => void;
}

export default function EditorActions(props: Props) {
  const { mine, setMine, permissions, refreshCallback } = props;
  const [settingsDialog, setSettingsDialog] = useState(false);
  const [userDialog, setUserDialog] = useState(false);
  const [teamDialog, setTeamDialog] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
  const [{ user }] = useGlobalState();

  const menu = () => {
    const canManageUsers = permissions.includes(pond.Permission.PERMISSION_USERS);
    const canManageTeams = canManageUsers && user.hasFeature("teams");
    return (
      <Menu
        anchorEl={menuAnchorEl ? menuAnchorEl : null}
        open={menuAnchorEl !== null}
        onClose={() => setMenuAnchorEl(null)}
        disableAutoFocusItem>
        {/* Users menu item */}
        {!isOffline() && canManageUsers && (
          <MenuItem onClick={() => setUserDialog(true)} button dense>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText secondary="Users" />
          </MenuItem>
        )}
        {!isOffline() && canManageTeams && (
          <MenuItem onClick={() => setTeamDialog(true)} button dense>
            <ListItemIcon>
              <ObjectTeamsIcon />
            </ListItemIcon>
            <ListItemText secondary="Teams" />
          </MenuItem>
        )}
      </Menu>
    );
  };

  const users = () => {
    return (
      <ObjectUsers
        scope={mineScope(or(mine.key, ""))}
        label={or(mine.name, "No Name")}
        permissions={or(permissions, [])}
        isDialogOpen={or(userDialog, false)}
        closeDialogCallback={() => setUserDialog(false)}
        refreshCallback={refreshCallback}
      />
    );
  };

  const buttons = () => {
    return (
      <React.Fragment>
        <Tooltip title="Settings">
          <IconButton onClick={() => setSettingsDialog(true)}>
            <Settings />
          </IconButton>
        </Tooltip>
        <Tooltip title={mine.key ? "More" : "Save or load mine first"}>
          <span>
            <IconButton
              aria-owns={"deviceMenu"}
              aria-haspopup="true"
              disabled={mine.key === undefined || mine.key === ""}
              onClick={event => setMenuAnchorEl(event.currentTarget)}>
              <MoreVert />
            </IconButton>
          </span>
        </Tooltip>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {buttons()}
      {menu()}
      {users()}
      <EditorSettings
        open={settingsDialog}
        setOpen={setSettingsDialog}
        mine={mine}
        setMine={setMine}
      />
      <ObjectTeams
        scope={mineScope(mine.key)}
        label="Teams"
        permissions={permissions}
        isDialogOpen={teamDialog}
        refreshCallback={refreshCallback}
        closeDialogCallback={() => setTeamDialog(false)}
      />
    </React.Fragment>
  );
}

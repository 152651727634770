import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";

const drawer = {
  "&": {
    bottom: 0,
    margin: 0,
    //padding: 0,
    //maxHeight: "auto",
    backgroundColor: "rgba(35, 35, 35, 1)",
    overflow: "hidden",
    overflowY: "auto"
  }
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    drawer: {
      ...drawer,
      height: "calc(100% - " + theme.spacing(7) + "px)",
      minWidth: theme.spacing(42),
      padding: theme.spacing(2)
    },
    closed: {
      ...drawer,
      height: "calc(100% - " + theme.spacing(7) + "px)",
      width: theme.spacing(0)
    }
  });
});

interface Props {
  isOpen: boolean;
  children: any;
}

export default function EditorDrawer(props: Props) {
  //const isMobile = useMobile();
  const { isOpen, children } = props;
  const classes = useStyles();

  return <div className={isOpen ? classes.drawer : classes.closed}>{children}</div>;
}

import { useEffect, useState } from "react";
import moment from "moment";
import { useAuth } from "hooks";
import { useGlobalState } from "providers";
import { isStaging } from "utils";

const longURL = (
  partial: string,
  token: string,
  after: string,
  rate: number,
  as?: string,
  keys?: string[],
  types?: string[]
): string => {
  let url = process.env.REACT_APP_WS_URL;
  if (isStaging()) url = process.env.REACT_APP_STAGING_WS_URL;
  return (
    url +
    partial +
    "?token=" +
    token +
    "&after=" +
    after +
    "&rate=" +
    rate +
    (as ? "&as=" + as : "") +
    (keys ? "?keys=" + keys.toString() : "") +
    (types ? "&types=" + types.toString() : "")
  );
};

export const useWebsocket = (
  path: string,
  transformer: (m: MessageEvent) => any,
  emitter: (m: any) => void,
  rate: number = 0,
  keys?: string[],
  types?: string[]
) => {
  const { token } = useAuth();
  const [{ as }] = useGlobalState();
  const [after] = useState(
    moment()
      .utc()
      .format()
  );
  useEffect(() => {
    const ws = new WebSocket(longURL(path, token, after, rate, as, keys, types));
    ws.onerror = (e: Event) => {
      console.error(e);
    };
    ws.onmessage = (e: MessageEvent) => {
      if (e) {
        emitter(transformer(e));
      }
    };
    return function cleanup() {
      ws.close();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

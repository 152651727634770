import MineDarkIcon from "assets/components/mineIconDark.png";
import MineLightIcon from "assets/components/mineIconLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function VentilationIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? MineLightIcon : MineDarkIcon;
    }

    return themeType === "light" ? MineDarkIcon : MineLightIcon;
  };

  return <ImgIcon alt="mine" src={src()} />;
}

import { cloneDeep } from "lodash";
import { MarkerData } from "Maps/mapMarkers/Markers";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

export class Terminal {
  public settings: pond.TerminalSettings = pond.TerminalSettings.create();
  public name: string = "";
  public key: string = "";

  public static create(pb?: pond.Terminal): Terminal {
    let my = new Terminal();
    if (pb) {
      my.settings = pond.TerminalSettings.fromObject(cloneDeep(or(pb.settings, {})));
      my.name = pb.name;
      my.key = pb.key;
    }
    return my;
  }

  public static clone(other?: Terminal): Terminal {
    if (other) {
      return Terminal.create(
        pond.Terminal.fromObject({
          settings: cloneDeep(other.settings)
        })
      );
    }
    return Terminal.create();
  }

  public static any(data: any): Terminal {
    return Terminal.create(pond.Terminal.fromObject(cloneDeep(data)));
  }

  public longitude(): number {
    return this.settings.longitude;
  }

  public latitude(): number {
    return this.settings.latitude;
  }

  public getMarkerData(
    clickFunc?: (event: React.PointerEvent<HTMLElement>, index: number, isMobile: boolean) => void,
    updateFunc?: (location: pond.Location) => void
  ): MarkerData {
    let m: MarkerData = {
      longitude: this.longitude(),
      latitude: this.latitude(),
      title: this.name,
      colour: this.settings.theme?.color ?? "#004f9b",
      visibleLevels: { max: 15 },
      clickFunc: clickFunc,
      updateFunc: updateFunc
    };
    return m;
  }
}

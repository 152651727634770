import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";
import { useGlobalState } from "providers";

type InvoiceStatus = "open" | "paid";

export interface IStripeAPIContext {
  getCustomer: () => Promise<AxiosResponse<string>>;
  newCustomer: () => Promise<AxiosResponse<string>>;
  makePayment: (amount: number) => Promise<AxiosResponse<string>>;
  payInvoice: (invoice: string, paymentMethod?: string) => Promise<AxiosResponse<string>>;
  listCards: () => Promise<AxiosResponse<string>>;
  getCard: (id: string) => Promise<AxiosResponse<string>>;
  listInvoices: (status: InvoiceStatus) => Promise<AxiosResponse<any>>;
  newCard: (token: string) => Promise<AxiosResponse<string>>;
  removeCard: (token: string) => Promise<AxiosResponse<string>>;
  setDefaultSource: (source: string) => Promise<AxiosResponse<string>>;
  updateCustomer: (
    source: string,
    city: string,
    country: string,
    line1: string,
    line2: string,
    postal: string,
    state: string
  ) => Promise<AxiosResponse<string>>;
}

export const StripeAPIContext = createContext<IStripeAPIContext>({} as IStripeAPIContext);

interface Props {}

export default function StripeProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, post, del } = useHTTP();
  const [{ as, team }] = useGlobalState();

  const getCustomer = () => {
    if (as === team.key()) {
      return get<string>(pondURL(`/billing/customer?team=${team.key()}`));
    }
    return get<string>(pondURL("/billing/customer"));
  };

  const newCustomer = () => {
    if (as === team.key()) {
      return post<string>(pondURL(`/billing/newCustomer?team=${team.key()}`));
    }
    return post<string>(pondURL("/billing/newCustomer"));
  };

  const newCard = (token: string) => {
    if (as === team.key()) {
      return post<string>(pondURL(`/billing/newCard?token=${token}&team=${team.key()}`));
    }
    return post<string>(pondURL(`/billing/newCard?token=${token}`));
  };

  const getCard = (id: string) => {
    let partial = `/billing/getCard?card=${id}`;
    if (as === team.key()) {
      partial = `${partial}&team=${team.key()}`;
    }
    return get<string>(pondURL(partial));
  };

  const listCards = () => {
    if (as === team.key()) {
      return get<string>(pondURL(`/billing/listCards?team=${team.key()}`));
    }
    return get<string>(pondURL("/billing/listCards"));
  };

  const listInvoices = (status: string) => {
    let partial = `/billing/listInvoices?status=${status}`;
    if (as === team.key()) partial = `${partial}&team=${team.key()}`;
    return get<string>(pondURL(partial));
  };

  const removeCard = (token: string) => {
    if (as === team.key()) {
      return del<string>(pondURL(`/billing/removeCard?token=${token}&team=${team.key()}`));
    }
    return del<string>(pondURL(`/billing/removeCard?token=${token}`));
  };

  const makePayment = (amount: number) => {
    if (as === team.key()) {
      return post<string>(pondURL(`/billing/makePayment?amount=${amount}&team=${team.key()}`));
    }
    return post<string>(pondURL(`/billing/makePayment?amount=${amount}`));
  };

  const payInvoice = (invoice: string, paymentMethod?: string) => {
    let paymentString = paymentMethod ? `&paymentMethod=${paymentMethod}` : "";
    return post<string>(pondURL(`/billing/payInvoice?invoice=${invoice}${paymentString}`));
  };

  const setDefaultSource = (source: string) => {
    let partial = `/billing/setDefaultSource?source=${source}`;
    if (as === team.key()) {
      partial = `${partial}&team=${team.key()}`;
    }
    return post<string>(pondURL(partial));
  };

  const updateCustomer = (
    source = "",
    city = "",
    country = "",
    line1 = "",
    line2 = "",
    postal = "",
    state = ""
  ) => {
    let partial = `/billing/updateCustomer?source=${source}`;
    partial = `${partial}&country=${country}&city=${city}&line1=${line1}&line2=${line2}`;
    partial = `${partial}&postal=${postal}&state=${state}`;
    if (as === team.key()) {
      partial = `${partial}&team=${team.key()}`;
    }
    return post<string>(pondURL(partial));
  };

  return (
    <StripeAPIContext.Provider
      value={{
        getCustomer,
        newCustomer,
        newCard,
        getCard,
        listCards,
        removeCard,
        makePayment,
        payInvoice,
        setDefaultSource,
        listInvoices,
        updateCustomer
      }}>
      {children}
    </StripeAPIContext.Provider>
  );
}

export const useStripeAPI = () => useContext(StripeAPIContext);

export type TimeUnit = "ms" | "seconds" | "minutes" | "hours" | "days";

export function milliToX(ms: number | undefined, unit: TimeUnit): number {
  if (!ms || isNaN(ms)) {
    return 0;
  }
  switch (unit) {
    case "days":
      return parseFloat(ms.toString()) / 86400000;
    case "hours":
      return parseFloat(ms.toString()) / 3600000;
    case "minutes":
      return parseFloat(ms.toString()) / 60000;
    case "seconds":
      return parseFloat(ms.toString()) / 1000;
    default:
      return parseFloat(ms.toString());
  }
}

export function xToMilli(value: string, unit: TimeUnit): number {
  if (isNaN(parseInt(value)) || value === "") {
    return 0;
  }
  switch (unit) {
    case "days":
      return parseFloat(value.toString()) * 86400000;
    case "hours":
      return parseFloat(value.toString()) * 3600000;
    case "minutes":
      return parseFloat(value.toString()) * 60000;
    case "seconds":
      return parseFloat(value.toString()) * 1000;
    default:
      return parseInt(value);
  }
}

export function bestUnit(ms?: number): TimeUnit {
  if (!ms || ms === 0) {
    return "minutes";
  }

  return milliToX(ms, "days") >= 1
    ? "days"
    : milliToX(ms, "hours") >= 1
    ? "hours"
    : milliToX(ms, "minutes") >= 1
    ? "minutes"
    : milliToX(ms, "seconds") >= 1
    ? "seconds"
    : "ms";
}

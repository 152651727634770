import BinDarkIcon from "assets/products/bindapt/binDark.png";
import BinLightIcon from "assets/products/bindapt/binLight.png";
import BinsDarkIcon from "assets/products/bindapt/binsDark.png";
import BinsLightIcon from "assets/products/bindapt/binsLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  singleBin?: boolean;
  type?: "light" | "dark";
  size?: number;
}

export default function BinsIcon(props: Props) {
  const themeType = useThemeType();
  const { type, singleBin, size } = props;

  const lightIcon = () => {
    return singleBin ? BinLightIcon : BinsLightIcon;
  };

  const darkIcon = () => {
    return singleBin ? BinDarkIcon : BinsDarkIcon;
  };

  const src = () => {
    if (type) {
      return type === "light" ? lightIcon() : darkIcon();
    }

    return themeType === "light" ? darkIcon() : lightIcon();
  };

  if (size) {
    return <img width={size} height={size} alt="bins" src={src()} />;
  }

  return <ImgIcon alt="bins" src={src()} />;
}

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AddCircleOutline as AddIconCircle } from "@material-ui/icons";
import SearchBar from "common/SearchBar";
import { Tag as TagUI } from "common/Tag";
import TagSettings from "common/TagSettings";
import { Device, Tag } from "models";
import { filterByTag } from "pbHelpers/Tag";
import { useDeviceAPI, useGlobalState, useSnackbar } from "providers";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addIcon: {
      color: "var(--status-ok)"
    }
  })
);

interface AddDeviceTagProps {
  device: Device;
  deviceTags: string[];
  addTagToDevice: (tag: Tag) => void;
}

function AddDeviceTag(props: AddDeviceTagProps) {
  const classes = useStyles();
  const { device, deviceTags, addTagToDevice } = props;
  const [open, setOpen] = useState(false);
  const [createNewOpen, setCreateNewOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [{ tags }] = useGlobalState();

  const tagItems = tags
    .filter(tag => !deviceTags.some(key => key === tag.settings.key))
    .filter(tag => filterByTag(searchValue, tag))
    .sort((a, b) => (a.name().toLowerCase() > b.name().toLowerCase() ? 1 : -1))
    .map((tag, index, array) => (
      <ListItem key={tag.settings.key} button divider={index === array.length - 1}>
        <TagUI tag={tag} onClick={() => addTagToDevice(tag)} />
      </ListItem>
    ));
  return (
    <React.Fragment>
      <IconButton aria-label="add tag" onClick={() => setOpen(true)} size="small">
        <AddIconCircle className={classes.addIcon} />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} scroll="paper">
        <DialogTitle>
          Select tags to add
          <Typography variant="body2" color="textSecondary">
            {device.name()}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <SearchBar
            value={searchValue}
            onChange={(newSearchValue: string) => setSearchValue(newSearchValue)}
          />
          <List>
            {tagItems.length > 0 ? (
              <React.Fragment>{tagItems}</React.Fragment>
            ) : (
              <ListItem divider>
                <ListItemText secondary={"No tags found"} />
              </ListItem>
            )}
            <ListItem button onClick={() => setCreateNewOpen(true)}>
              <ListItemIcon>
                <AddIconCircle className={classes.addIcon} />
              </ListItemIcon>
              <ListItemText>Create tag</ListItemText>
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
      <TagSettings open={createNewOpen} mode="add" onClose={() => setCreateNewOpen(false)} />
    </React.Fragment>
  );
}

interface DeviceTagProps {
  tag: Tag;
  removeTagFromDevice: (tag: Tag) => void;
}

function DeviceTag(props: DeviceTagProps) {
  const { tag, removeTagFromDevice } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TagUI tag={tag} onClick={() => setOpen(true)} onDelete={() => removeTagFromDevice(tag)} />
      <TagSettings open={open} onClose={() => setOpen(false)} tag={tag} mode="update" />
    </React.Fragment>
  );
}

interface DeviceTagsProps {
  device: Device;
  disableAdd?: boolean;
}

export default function DeviceTags(props: DeviceTagsProps) {
  const { device, disableAdd } = props;
  const [{ tags }] = useGlobalState();
  const deviceAPI = useDeviceAPI();
  const { error } = useSnackbar();
  const [deviceTags, setDeviceTags] = useState<string[]>(device.status.tagKeys);
  const previousDeviceRef = useRef(device);

  useEffect(() => {
    if (previousDeviceRef.current !== device) {
      setDeviceTags(device.status.tagKeys);
      previousDeviceRef.current = device;
    }
  }, [device]);

  const addTag = (tag: Tag) => {
    if (!deviceTags.some(dt => dt === tag.key())) {
      deviceAPI
        .tag(device.id(), tag.key())
        .then(() => {
          setDeviceTags([...deviceTags, tag.key()]);
        })
        .catch(() => error("Failed to tag device as " + tag.name()));
    }
  };

  const removeTagFromDevice = (tag: Tag) => {
    if (deviceTags.some(dt => dt === tag.key())) {
      deviceAPI
        .untag(device.id(), tag.key())
        .then(() => {
          setDeviceTags(deviceTags.filter(t => tag.key() !== t));
        })
        .catch(() => error("Failed to remove tag " + tag.name() + " from device"));
    }
  };

  if (!device || !tags) {
    return null;
  }

  return (
    <React.Fragment>
      {deviceTags.map(key => {
        let tag = tags.find(t => t.settings.key === key);
        if (!tag) {
          return null;
        }
        return (
          <Grid item key={tag.settings.key}>
            <DeviceTag tag={tag} removeTagFromDevice={removeTagFromDevice} />
          </Grid>
        );
      })}
      {disableAdd === undefined || disableAdd === false ? (
        <Grid item>
          <AddDeviceTag device={device} deviceTags={deviceTags} addTagToDevice={addTag} />
        </Grid>
      ) : null}
    </React.Fragment>
  );
}

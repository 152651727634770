import DataDuckDark from "assets/common/dataDuckDark.png";
import DataDuckLight from "assets/common/dataDuckLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function DataDuckIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? DataDuckLight : DataDuckDark;
    }

    return themeType === "light" ? DataDuckDark : DataDuckLight;
  };

  return <ImgIcon alt="dataDuck" src={src()} />;
}

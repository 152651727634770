import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";

export interface IKeyManagerAPIContext {
  addKey: (teamID: string) => Promise<any>;
  listKeys: (teamID: string) => Promise<AxiosResponse<pond.ListAPIKeysResponse>>;
  removeKey: (teamID: string, key: string) => Promise<AxiosResponse<pond.RemoveAPIKeyResponse>>;
}

export const KeyManagerAPIContext = createContext<IKeyManagerAPIContext>(
  {} as IKeyManagerAPIContext
);

interface Props {}

export default function KeyManagerProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post } = useHTTP();

  const addKey = (teamID: string) => {
    return post(pondURL("/teams/" + teamID + "/apiKeys"));
  };

  const removeKey = (teamID: string, key: string) => {
    return del<pond.RemoveAPIKeyResponse>(pondURL("/teams/" + teamID + "/apiKeys?key=" + key));
  };

  const listKeys = (teamID: string) => {
    return get<pond.ListAPIKeysResponse>(pondURL("/teams/" + teamID + "/apiKeys"));
  };

  return (
    <KeyManagerAPIContext.Provider
      value={{
        addKey,
        listKeys,
        removeKey
      }}>
      {children}
    </KeyManagerAPIContext.Provider>
  );
}

export const useKeyManagerAPI = () => useContext(KeyManagerAPIContext);

import {
  Box,
  createStyles,
  darken,
  Drawer,
  List,
  ListItem,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import React, { useState } from "react";
import PageContainer from "./PageContainer";
import Endpoints from "apiDocs/endpoints";
import Definitions from "apiDocs/definitions";
import Information from "apiDocs/information";
import Enumerators from "apiDocs/enumerators";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerContainer: {
      overflow: "auto"
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    selected: {
      background: darken(theme.palette.background.paper, 0.25)
    }
  })
);

export default function APIDocs() {
  const [currentView, setCurrentView] = useState("info");
  const classes = useStyles();

  return (
    <PageContainer>
      <Box className={classes.root}>
        <Box className={classes.content} marginBottom={5}>
          <Box>
            <Typography variant="h3" style={{ fontWeight: 650 }}>
              API Documentation
            </Typography>
          </Box>
          <Box>
            {currentView === "info" && <Information />}
            {currentView === "endpoints" && <Endpoints />}
            {currentView === "definitions" && <Definitions />}
            {currentView === "enums" && <Enumerators />}
          </Box>
        </Box>
        <Drawer
          anchor="right"
          variant="permanent"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}>
          <Toolbar />
          <Box className={classes.drawerContainer}>
            <List>
              <ListItem
                className={currentView === "info" ? classes.selected : undefined}
                button
                onClick={() => {
                  setCurrentView("info");
                }}>
                <Typography>Information</Typography>
              </ListItem>
              <ListItem
                className={currentView === "endpoints" ? classes.selected : undefined}
                button
                onClick={() => {
                  setCurrentView("endpoints");
                }}>
                <Typography>Endpoints</Typography>
              </ListItem>
              <ListItem
                className={currentView === "definitions" ? classes.selected : undefined}
                button
                onClick={() => {
                  setCurrentView("definitions");
                }}>
                <Typography>Definitions</Typography>
              </ListItem>
              <ListItem
                className={currentView === "enums" ? classes.selected : undefined}
                button
                onClick={() => {
                  setCurrentView("enums");
                }}>
                <Typography>Enumerators</Typography>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Box>
    </PageContainer>
  );
}

import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";

export interface IBackpackAPI {
  addBackpack: (backpack: pond.Backpack) => Promise<any>;
  updateBackpack: (backpackID: number | Long, backpack: pond.Backpack) => Promise<any>;
  removeBackpack: (backpackID: number | Long) => Promise<any>;
  getBackpack: (backpackID: number | Long) => Promise<any>;
  listBackpacks: () => Promise<any>;
}

export const BackpackAPIContext = createContext<IBackpackAPI>({} as IBackpackAPI);

export default function BackpackProvider(props: PropsWithChildren<any>) {
  const { children } = props;
  const { get, post, put, del } = useHTTP();

  const addBackpack = (backpack: pond.Backpack) => {
    const url = pondURL("/backpacks");
    return post(url, backpack);
  };

  const updateBackpack = (backpackID: number | Long, backpack: pond.Backpack) => {
    const url = pondURL("/backpacks/" + backpackID);
    return put(url, backpack);
  };

  const removeBackpack = (backpackID: number | Long) => {
    const url = pondURL("/backpacks/" + backpackID);
    return del(url);
  };

  const getBackpack = (backpackID: number | Long) => {
    const url = pondURL("/backpacks/" + backpackID);
    return get(url);
  };

  const listBackpacks = () => {
    const url = pondURL("/backpacks");
    return get(url);
  };

  return (
    <BackpackAPIContext.Provider
      value={{
        addBackpack,
        updateBackpack,
        removeBackpack,
        getBackpack,
        listBackpacks
      }}>
      {children}
    </BackpackAPIContext.Provider>
  );
}

export const useBackpackAPI = () => useContext(BackpackAPIContext);

import { getThemeType } from "./themeType";

export function getTextPrimary() {
  let themeType = getThemeType();
  return themeType === "light" ? "rgba(0, 0, 0, 0.87)" : "#FFF";
}

export function getTextSecondary() {
  let themeType = getThemeType();
  return themeType === "light" ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.7)";
}

import { IconButton, InputAdornment, TextField, useTheme } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React from "react";

interface Props {
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
}

export default function Zoom(props: Props) {
  const { scale, setScale } = props;
  const theme = useTheme();

  const onTextInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let value = parseInt(e.currentTarget.value);
    if (value < 1000 && value > 0) {
      setScale(value / 100);
    }
  };

  const zoomOut = () => {
    if (scale > 0.1) setScale(scale - 0.05);
  };

  const zoomIn = () => {
    if (scale < 5) setScale(scale + 0.05);
  };

  return (
    <React.Fragment>
      <IconButton onClick={zoomOut}>
        <Remove />
      </IconButton>

      <TextField
        style={{
          paddingTop: theme.spacing(1),
          width: "48px"
        }}
        value={Math.round(scale * 100)}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
        onChange={e => onTextInput(e)}
      />

      <IconButton onClick={zoomIn}>
        <Add />
      </IconButton>
    </React.Fragment>
  );
}

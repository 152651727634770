import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";

export interface IFieldMarkerAPIContext {
  addFieldMarker: (
    fieldMarker: pond.FieldMarkerSettings
  ) => Promise<AxiosResponse<pond.AddFieldMarkerResponse>>;
  getFieldMarker: (fieldMarkerID: string) => Promise<AxiosResponse<pond.FieldMarker>>;
  listFieldMarkers: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean,
    as?: string
  ) => Promise<AxiosResponse<pond.ListFieldMarkersResponse>>;
  removeFieldMarker: (
    fieldMarkerID: string
  ) => Promise<AxiosResponse<pond.RemoveFieldMarkerResponse>>;
  updateFieldMarker: (
    key: string,
    fieldMarker: pond.FieldMarkerSettings,
    asRoot?: true
  ) => Promise<AxiosResponse<pond.UpdateFieldMarkerResponse>>;
}

export const FieldMarkersAPIContext = createContext<IFieldMarkerAPIContext>(
  {} as IFieldMarkerAPIContext
);

interface Props {}

export default function FieldMarkerProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();
  const [{ as }] = useGlobalState();

  const addFieldMarker = (fieldMarker: pond.FieldMarkerSettings) => {
    if (as)
      return post<pond.AddFieldMarkerResponse>(pondURL("/fieldMarkers?as=" + as), fieldMarker);
    return post<pond.AddFieldMarkerResponse>(pondURL("/fieldMarkers"), fieldMarker);
  };

  const getFieldMarker = (fieldMarkerId: string) => {
    if (as) return get<pond.FieldMarker>(pondURL("/fieldMarkers/" + fieldMarkerId + "?as=" + as));
    return get<pond.FieldMarker>(pondURL("/fieldMarkers/" + fieldMarkerId));
  };

  const removeFieldMarker = (key: string) => {
    if (as)
      return del<pond.RemoveFieldMarkerResponse>(pondURL("/fieldMarkers/" + key + "?as=" + as));
    return del<pond.RemoveFieldMarkerResponse>(pondURL("/fieldMarkers/" + key));
  };

  const listFieldMarkers = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean,
    as?: string
  ) => {
    return get<pond.ListFieldMarkersResponse>(
      pondURL(
        "/fieldMarkers" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + (order ? order : "asc")) +
          ("&by=" + (orderBy ? orderBy : "key")) +
          (search ? "&search=" + search : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (as ? "&as=" + as : "")
      )
    );
  };

  const updateFieldMarker = (key: string, hm: pond.FieldMarkerSettings, asRoot?: boolean) => {
    if (as)
      return put<pond.UpdateFieldMarkerResponse>(
        pondURL(
          "/fieldMarkers/" + key + "?as=" + as + (asRoot ? "&asRoot=" + asRoot.toString() : "")
        ),
        hm
      );
    return put<pond.UpdateFieldMarkerResponse>(
      pondURL("/fieldMarkers/" + key + (asRoot ? "?asRoot=" + asRoot.toString() : "")),
      hm
    );
  };

  return (
    <FieldMarkersAPIContext.Provider
      value={{
        addFieldMarker,
        getFieldMarker,
        removeFieldMarker,
        listFieldMarkers,
        updateFieldMarker
      }}>
      {children}
    </FieldMarkersAPIContext.Provider>
  );
}

export const useFieldMarkerAPI = () => useContext(FieldMarkersAPIContext);

import React, { createContext, PropsWithChildren, useContext } from "react";
import { useAuth } from "./auth";
import { useHTTP } from "./http";
import { recluse } from "protobuf-ts/recluse";
import { get as cachedGet } from "services/cache";
import { getFieldMaskQuery } from "services/filterAPI";

interface ISecurityContext {
  listReports: (paths?: string[]) => Promise<any>;
  getReport: (id: string) => Promise<any>;
  scan: (body: recluse.Options) => Promise<any>;
}

export const SecurityContext = createContext<ISecurityContext>({} as ISecurityContext);

interface Props {}

export default function SecurityProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, post } = useHTTP();
  const { isAuthenticated, token } = useAuth();

  const tokenOptions = () => {
    return {
      headers: {
        Authorization: isAuthenticated ? "Bearer " + token : "",
        "Content-Type": "application/json"
      }
    };
  };

  const options = (token: string) => {
    return {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      }
    };
  };

  const getToken = () => {
    return cachedGet(url("/token"), tokenOptions());
  };

  const url = (partial: string): string => {
    return process.env.REACT_APP_RECLUSE_URL + partial;
  };

  const listReports = (paths?: string[]) => {
    const fieldMask = getFieldMaskQuery(paths);
    return getToken().then((response: any) => {
      return get(
        url("/reports" + (fieldMask !== "" ? "?" + fieldMask : "")),
        options(response.data.token)
      );
    });
  };

  const getReport = (id: string) => {
    return getToken().then((response: any) => {
      return get(url("/reports/" + id), options(response.data.token));
    });
  };

  const scan = (body: recluse.Options) => {
    return getToken().then((response: any) => {
      return post(url("/scan"), body, options(response.data.token));
    });
  };

  return (
    <SecurityContext.Provider
      value={{
        listReports,
        getReport,
        scan
      }}>
      {children}
    </SecurityContext.Provider>
  );
}

export const useSecurity = () => useContext(SecurityContext);

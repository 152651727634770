import { Backpack } from "models";
import { pond } from "protobuf-ts/pond";
import { Option } from "common/SearchSelect";

export function backpackOptions(backpacks: Backpack[]): Option[] {
  return backpacks.map(b => {
    return { value: b.settings.backpackId, label: b.name() } as Option;
  });
}

export function genericBackpacks(): Backpack[] {
  let basic = pond.Backpack.fromObject({
    settings: {
      backpackId: 0,
      name: "Basic",
      device: pond.DeviceSettings.fromObject({
        name: "Basic",
        pondCheckPeriodS: 60,
        upgradeChannel: pond.UpgradeChannel.UPGRADE_CHANNEL_STABLE,
        automaticallyUpgrade: false
      })
    }
  });
  return [Backpack.create(basic)];
}

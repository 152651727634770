import { RedirectLoginOptions } from "@auth0/auth0-react";
import { useAuth } from "hooks";
import queryString from "query-string";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import Loading from "./Loading";

interface Props {
  prevPath?: string;
}

export default function Login(props: Props) {
  const { prevPath } = props;
  const location = useLocation();
  const { loginWithRedirect } = useAuth();

  const setRouteBeforeLogin = useCallback((): Promise<string> => {
    return new Promise(function(resolve) {
      localStorage.setItem("routeBeforeLogin", prevPath ? prevPath : "/");
      return resolve("success");
    });
  }, [prevPath]);

  useEffect(() => {
    let options: RedirectLoginOptions = {};
    if (location && location.search) {
      let parsed = queryString.parse(location.search);

      if (parsed.signup === "true") {
        options.mode = "signUp";
      }

      if (parsed.email && parsed.email !== "") {
        options.login_hint = parsed.email.toString();
      }
    }

    setRouteBeforeLogin().finally(() => {
      loginWithRedirect(options);
    });
  }, [location, loginWithRedirect, setRouteBeforeLogin]);

  return <Loading fullViewport={true} />;
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useDeviceAPI, useSnackbar } from "hooks";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      margin: theme.spacing(2)
    }
  })
);

interface Props {
  id: number;
  isOpen: boolean;
  close: (refresh: boolean) => void;
}

export function ResumeData(props: Props) {
  const { id, isOpen, close } = props;
  const classes = useStyles();
  const { success, error } = useSnackbar();
  const deviceAPI = useDeviceAPI();

  const resume = () => {
    deviceAPI
      .resume(id)
      .then(() => success("Data will resume shortly"))
      .catch(() => error("Something went wrong"))
      .then(() => close(true));
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={() => close(false)}
      aria-labelledby="resume-data-dialog">
      <DialogTitle id="resume-data-dialog-title">Resume Data</DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-slide-description">
          Resuming data will reconnect your device to the cellular network. It may take up to an
          hour before communication resumes. Resuming data will result in a reactivation charge.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(false)} color="primary">
          Close
        </Button>
        <Button onClick={resume} color="primary">
          Resume
        </Button>
      </DialogActions>
    </Dialog>
  );
}

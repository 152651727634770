import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { Scope, User } from "models";
import { Team } from "models/Team";
import { permissionToString } from "pbHelpers/Permission";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { objectQueryParams, pondURL } from "./pond";

export interface IPermissionAPIContext {
  getPermissions: (user: string, scope: Scope) => Promise<AxiosResponse<any>>;
  removePermissions: (user: string, scope: Scope) => Promise<AxiosResponse<any>>;
  updatePermissions: (scope: Scope, users: User[] | Team[]) => Promise<AxiosResponse<any>>;
  updateRelativePermissions: (
    parentScope: Scope,
    childScope: Scope,
    permissions: pond.Permission[]
  ) => Promise<AxiosResponse<any>>;
  shareObject: (
    scope: Scope,
    email: string,
    permissions: pond.Permission[],
    useImitation?: boolean
  ) => Promise<AxiosResponse<any>>;
  shareObjectByKey: (
    scope: Scope,
    key: string,
    permissions: pond.Permission[]
  ) => Promise<AxiosResponse<any>>;
  addShareableLink: (scope: Scope, expiration: string) => Promise<AxiosResponse<any>>;
  removeShareableLink: (scope: Scope, code: string) => Promise<AxiosResponse<any>>;
  listShareableLinks: (scope: Scope) => Promise<AxiosResponse<any>>;
  getShareableLink: (scope: Scope, code: string) => Promise<AxiosResponse<any>>;
}

export const PermissionAPIContext = createContext<IPermissionAPIContext>(
  {} as IPermissionAPIContext
);

interface Props {}

export default function PermissionProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, put, post } = useHTTP();
  const [{ as }] = useGlobalState();

  const getPermissions = (user: string, scope: Scope) => {
    return get(pondURL("/users/" + user + "/permissions" + objectQueryParams(scope)));
  };

  const removePermissions = (user: string, scope: Scope) => {
    return del(pondURL("/users/" + user + "/permissions" + objectQueryParams(scope)));
  };

  const updatePermissions = (scope: Scope, users: User[] | Team[]) => {
    interface user {
      id: string;
      permissions: string[];
    }
    interface request {
      users: user[];
    }
    let body: request = { users: [] };
    users.forEach((user: User | Team) => {
      body.users.push({
        id: user.id(),
        permissions: user.permissions.map(permission => permissionToString(permission))
      });
    });
    return put(pondURL("/" + scope.kind + "s/" + scope.key + "/permissions"), body);
  };

  const shareObject = (
    scope: Scope,
    email: string,
    permissions: pond.Permission[],
    useImitation = true
  ) => {
    let sql = "/" + scope.kind + "s/" + scope.key + "/share";
    if (as && useImitation) sql = sql + "?as=" + as;
    return post(pondURL(sql), {
      email: email,
      permissions: permissions.map(permission => permissionToString(permission))
    });
  };

  const updateRelativePermissions = (
    parentScope: Scope,
    childScope: Scope,
    permissions: pond.Permission[]
  ) => {
    let sql = "/updateRelativePermissions";
    if (as) sql = sql + "?as=" + as;
    return put(pondURL(sql), {
      parent: parentScope.key,
      parentType: parentScope.kind,
      child: childScope.key,
      childType: childScope.kind,
      permissions: permissions.map(permission => permissionToString(permission))
    });
  };

  const shareObjectByKey = (scope: Scope, key: string, permissions: pond.Permission[]) => {
    if (as)
      return post(pondURL(`/${scope.kind}s/${scope.key}/shareByKey?as=${as}`), {
        key: key,
        permissions: permissions.map(permission => permissionToString(permission))
      });
    return post(pondURL("/" + scope.kind + "s/" + scope.key + "/shareByKey"), {
      key: key,
      permissions: permissions.map(permission => permissionToString(permission))
    });
  };

  const addShareableLink = (scope: Scope, expiration: string) => {
    let shareBody: pond.ShareableLinkSettings = pond.ShareableLinkSettings.create({
      expiration: expiration
    });
    return post(pondURL("/" + scope.kind + "s/" + scope.key + "/links"), shareBody);
  };

  const removeShareableLink = (scope: Scope, code: string) => {
    return del(pondURL("/" + scope.kind + "s/" + scope.key + "/links/" + code));
  };

  const listShareableLinks = (scope: Scope) => {
    return get(pondURL("/" + scope.kind + "s/" + scope.key + "/links"));
  };

  const getShareableLink = (scope: Scope, code: string) => {
    return get(pondURL("/" + scope.kind + "s/" + scope.key + "/links/" + code));
  };

  return (
    <PermissionAPIContext.Provider
      value={{
        getPermissions,
        removePermissions,
        updatePermissions,
        updateRelativePermissions,
        shareObject,
        shareObjectByKey,
        addShareableLink,
        removeShareableLink,
        listShareableLinks,
        getShareableLink
      }}>
      {children}
    </PermissionAPIContext.Provider>
  );
}

export const usePermissionAPI = () => useContext(PermissionAPIContext);

import { Scope } from "models";
import React, { PropsWithChildren } from "react";
import BackpackProvider, { useBackpackAPI } from "./backpackAPI";
import BinProvider, { useBinAPI } from "./binAPI";
import BinYardProvider, { useBinYardAPI } from "./binYardAPI";
import NoteProvider, { useNoteAPI } from "./noteAPI";
import SiteProvider, { useSiteAPI } from "./siteAPI";
import ComponentProvider, {
  useComponentAPI,
  useComponentsWebsocket,
  useComponentWebsocket,
  useMeasurementsWebsocket
} from "./componentAPI";
import DeviceProvider, { useDeviceAPI, useDeviceWebsocket } from "./deviceAPI";
import FieldProvider, { useFieldAPI } from "./fieldAPI";
import FirmwareProvider, { useFirmwareAPI } from "./firmwareAPI";
import GroupProvider, { useGroupAPI } from "./groupAPI";
import InteractionsProvider, { useInteractionsAPI } from "./interactionsAPI";
import MetricProvider, { useMetricAPI } from "./metricAPI";
import PermissionProvider, { usePermissionAPI } from "./permissionAPI";
import PreferenceProvider, { usePreferenceAPI } from "./preferenceAPI";
import TagProvider, { useTagAPI } from "./tagAPI";
import TeamProvider, { useTeamAPI } from "./teamAPI";
import TaskProvider, { useTaskAPI } from "./taskAPI";
import UsageProvider, { useUsageAPI } from "./usageAPI";
import UserProvider, { useUserAPI } from "./userAPI";
import StripeProvider, { useStripeAPI } from "./stripeAPI";
import HarvestPlanProvider, { useHarvestPlanAPI } from "./harvestPlanAPI";
import HarvestYearProvider, { useHarvestYearAPI } from "./harvestYearAPI";
import HomeMarkerProvider, { useHomeMarkerAPI } from "./homeMarkerAPI";
import FieldMarkerProvider, { useFieldMarkerAPI } from "./fieldMarkerAPI";
import ImagekitProvider, { useImagekitAPI } from "./imagekitAPI";
import DataDogProvider, { useDataDogProxyAPI } from "./datadogProxyAPI";
import MineProvider, { useMineAPI } from "./mineAPI";
import ObjectProvider, { useObjectAPI } from "./objectAPI";
import { isStaging } from "utils";
import TerminalProvider, { useTerminalAPI } from "./terminalAPI";
import TransactionProvider, { useTransactionAPI } from "./transactionAPI";
import GateProvider, { useGateAPI } from "./gateAPI";
import GrainBagProvider, { useGrainBagAPI } from "./grainBagAPI";
import KeyManagerProvider, { useKeyManagerAPI } from "./keyManagerAPI";
import NotificationProvider, { useNotificationAPI } from "./notificationAPI";
import ObjectHeaterProvider, { useObjectHeaterAPI } from "./ObjectHeaterAPI";
import MutationProvider, { useMutationAPI } from "./mutationAPI";
import ContractProvider, { useContractAPI } from "./contractAPI";
import JohnDeereProvider, { useJohnDeereProxyAPI } from "./johnDeereProxyAPI";
import CNHiProvider, { useCNHiProxyAPI } from "./cnhiProxyAPI";
import FileControllerProvider, { useFileControllerAPI } from "./fileControllerAPI";
import DevicePresetProvider, { useDevicePresetAPI } from "./devicePresetAPI";

export const pondURL = (partial: string, demo: boolean = false): string => {
  let url = process.env.REACT_APP_API_URL + (demo ? "/demo" : "") + partial;
  if (isStaging()) url = process.env.REACT_APP_STAGING_API_URL + (demo ? "/demo" : "") + partial;
  return url;
};

export const objectQueryParams = (scope: Scope) => {
  return "?" + scope.kind + "_id=" + scope.key;
};

export default function PondProvider(props: PropsWithChildren<any>) {
  const { children } = props;

  return (
    <BackpackProvider>
      <PermissionProvider>
        <DeviceProvider>
          <ComponentProvider>
            <FieldProvider>
              <FirmwareProvider>
                <GroupProvider>
                  <HarvestPlanProvider>
                    <NoteProvider>
                      <BinYardProvider>
                        <BinProvider>
                          <InteractionsProvider>
                            <MetricProvider>
                              <PreferenceProvider>
                                <TagProvider>
                                  <TeamProvider>
                                    <TaskProvider>
                                      <SiteProvider>
                                        <UsageProvider>
                                          <NotificationProvider>
                                            <ImagekitProvider>
                                              <MineProvider>
                                                <HomeMarkerProvider>
                                                  <HarvestYearProvider>
                                                    <FieldMarkerProvider>
                                                      <DataDogProvider>
                                                        <KeyManagerProvider>
                                                          <ObjectProvider>
                                                            <TerminalProvider>
                                                              <GateProvider>
                                                                <ObjectHeaterProvider>
                                                                  <UserProvider>
                                                                    <MutationProvider>
                                                                      <GrainBagProvider>
                                                                        <TransactionProvider>
                                                                          <JohnDeereProvider>
                                                                            <CNHiProvider>
                                                                              <ContractProvider>
                                                                                <FileControllerProvider>
                                                                                  <StripeProvider>
                                                                                    <DevicePresetProvider>
                                                                                      {children}
                                                                                    </DevicePresetProvider>
                                                                                  </StripeProvider>
                                                                                </FileControllerProvider>
                                                                              </ContractProvider>
                                                                            </CNHiProvider>
                                                                          </JohnDeereProvider>
                                                                        </TransactionProvider>
                                                                      </GrainBagProvider>
                                                                    </MutationProvider>
                                                                  </UserProvider>
                                                                </ObjectHeaterProvider>
                                                              </GateProvider>
                                                            </TerminalProvider>
                                                          </ObjectProvider>
                                                        </KeyManagerProvider>
                                                      </DataDogProvider>
                                                    </FieldMarkerProvider>
                                                  </HarvestYearProvider>
                                                </HomeMarkerProvider>
                                              </MineProvider>
                                            </ImagekitProvider>
                                          </NotificationProvider>
                                        </UsageProvider>
                                      </SiteProvider>
                                    </TaskProvider>
                                  </TeamProvider>
                                </TagProvider>
                              </PreferenceProvider>
                            </MetricProvider>
                          </InteractionsProvider>
                        </BinProvider>
                      </BinYardProvider>
                    </NoteProvider>
                  </HarvestPlanProvider>
                </GroupProvider>
              </FirmwareProvider>
            </FieldProvider>
          </ComponentProvider>
        </DeviceProvider>
      </PermissionProvider>
    </BackpackProvider>
  );
}

export {
  useBackpackAPI,
  useBinAPI,
  useBinYardAPI,
  useNoteAPI,
  usePermissionAPI,
  useDeviceAPI,
  useDeviceWebsocket,
  useComponentAPI,
  useComponentWebsocket,
  useComponentsWebsocket,
  useMeasurementsWebsocket,
  useFieldAPI,
  useFieldMarkerAPI,
  useFirmwareAPI,
  useGroupAPI,
  useHarvestPlanAPI,
  useHarvestYearAPI,
  useHomeMarkerAPI,
  useInteractionsAPI,
  useImagekitAPI,
  useMetricAPI,
  usePreferenceAPI,
  useSiteAPI,
  useTagAPI,
  useTeamAPI,
  useTaskAPI,
  useUsageAPI,
  useUserAPI,
  useDataDogProxyAPI,
  useMineAPI,
  useKeyManagerAPI,
  useObjectAPI,
  useTerminalAPI,
  useGateAPI,
  useNotificationAPI,
  useObjectHeaterAPI,
  useMutationAPI,
  useGrainBagAPI,
  useTransactionAPI,
  useStripeAPI,
  useContractAPI,
  useJohnDeereProxyAPI,
  useCNHiProxyAPI,
  useFileControllerAPI,
  useDevicePresetAPI
};

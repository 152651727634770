import { DeviceProductDescriber } from "products/DeviceProduct";
import { pond } from "protobuf-ts/pond";
import { NexusSTAvailability } from "./NexusAvailability";
import NexusLightIcon from "assets/products/Construction/nexusIconLight.png";
import NexusDarkIcon from "assets/products/Construction/nexusIconDark.png";

export const NexusST: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_NEXUS_ST,
  label: "Nexus ST",
  icon: (_, theme?: "light" | "dark") => {
    return theme === "light" ? NexusDarkIcon : NexusLightIcon;
  },
  view: "controller",
  tabs: ["components"],
  availability: NexusSTAvailability,
  componentExtension: true
};

import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import React, { createContext, PropsWithChildren, useContext } from "react";
//import { or } from "utils";
import { pondURL } from "./pond";

export interface ICNHiProxyAPIContext {
  //add new organization
  addAccount: (
    teamKey: string,
    userID: string,
    cnhiCode: string,
    cnhiUsername: string
  ) => Promise<AxiosResponse<pond.AddCNHiAccountResponse>>;
  //list organizations
  listAccounts: (
    limit: number,
    offset: number,
    as?: string,
    keys?: string[],
    types?: string[]
  ) => Promise<AxiosResponse<pond.ListCNHiAccountsResponse>>;
  updateAccount: (
    key: string,
    options: pond.DataOption[],
    as?: string
  ) => Promise<AxiosResponse<pond.UpdateCNHiAccountResponse>>;
  listFields: (
    limit: number,
    offset: number,
    // order?: "asc" | "desc",
    // orderBy?: string,
    // search?: string,
    as?: string,
    asRoot?: boolean
  ) => Promise<AxiosResponse<pond.ListCNHiFieldsResponse>>;
}

export const CNHiProxyAPIContext = createContext<ICNHiProxyAPIContext>({} as ICNHiProxyAPIContext);

interface Props {}

export default function CNHiProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { post, get, put } = useHTTP();

  const addAccount = (teamKey: string, userID: string, cnhiCode: string, cnhiUsername: string) => {
    return post<pond.AddCNHiAccountResponse>(
      pondURL(
        "/cnhiAccounts?team=" +
          teamKey +
          "&user=" +
          userID +
          "&code=" +
          cnhiCode +
          "&cnhiUsername=" +
          cnhiUsername
      )
    );
  };

  const listAccounts = (
    limit: number,
    offset: number,
    as?: string,
    keys?: string[],
    types?: string[]
  ) => {
    return get<pond.ListCNHiAccountsResponse>(
      pondURL(
        "/cnhiAccounts?limit=" +
          limit +
          "&offset=" +
          offset +
          (as ? "&as=" + as : "") +
          (keys ? "&keys=" + keys.join(",") : "") +
          (types ? "&types=" + types.join(",") : "")
      )
    );
  };

  const listFields = (
    limit: number,
    offset: number,
    // order?: "asc" | "desc",
    // orderBy?: string,
    // search?: string,
    as?: string,
    asRoot?: boolean
  ) => {
    return get<pond.ListFieldsResponse>(
      pondURL(
        "/cnhiFields" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          (as ? "&as=" + as : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "")
      )
    );
  };

  const updateAccount = (key: string, options: pond.DataOption[], as?: string) => {
    return put<pond.UpdateCNHiAccountResponse>(
      pondURL("/cnhiAccounts/" + key + "?options=" + options.toString() + (as ? "&as=" + as : ""))
    );
  };

  return (
    <CNHiProxyAPIContext.Provider
      value={{
        addAccount,
        listAccounts,
        updateAccount,
        listFields
      }}>
      {children}
    </CNHiProxyAPIContext.Provider>
  );
}

export const useCNHiProxyAPI = () => useContext(CNHiProxyAPIContext);

import { Box, Button, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Bin } from "models";
import React, { useRef, useState } from "react";
import BinCard from "./BinCard";
import ScrollMenu from "react-horizontal-scroll-menu";
import { useHistory } from "react-router";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import { useMobile } from "hooks";

interface Props {
  bins: Bin[];
  duplicateBin: (bin: Bin) => void;
  title?: string;
  gridView?: boolean;
  loadMore?: (newTranslation: number) => void;
  startingTranslate: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridListTile: {
      position: "relative",
      minHeight: "184px",
      height: "auto !important",
      width: "184px",
      padding: 2
    },
    hidden: {
      visibility: "hidden"
    }
  })
);

export default function BinsList(props: Props) {
  const { bins, duplicateBin, title, gridView, loadMore, startingTranslate } = props;
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMobile();
  const [duplicate, setDuplicate] = useState(false);
  const scrollRef = useRef<any>(null);

  const goToBin = (i: number) => {
    let path = "/bins/" + bins[i].key();
    history.push(path, {
      bin: bins[i]
    });
  };

  const scroll = () => {
    return (
      <ScrollMenu
        wheel={false}
        ref={scrollRef}
        alignCenter={false}
        inertiaScrolling
        translate={startingTranslate}
        hideArrows
        hideSingleArrow
        arrowDisabledClass={classes.hidden}
        onUpdate={params => {
          if (scrollRef.current.state) {
            if (scrollRef.current.getOffsetAtEnd() === params.translate && loadMore) {
              loadMore(params.translate);
            }
          }
        }}
        onSelect={e => {
          if (!duplicate) {
            goToBin(e as number);
          }
        }}
        arrowLeft={
          <Button style={{ height: 184, display: isMobile ? "none" : "block" }}>
            <ArrowBack />
          </Button>
        }
        arrowRight={
          <Button style={{ height: 184, display: isMobile ? "none" : "block" }}>
            <ArrowForward />
          </Button>
        }
        data={bins.map((b, i) => (
          <Box key={i} className={classes.gridListTile}>
            <BinCard bin={b} duplicateBin={duplicateBin} dupHovered={setDuplicate} />
          </Box>
        ))}
      />
    );
  };

  const grid = () => {
    return (
      <Grid container direction="row">
        {bins.map((b, i) => (
          <Box key={i} className={classes.gridListTile} onClick={() => goToBin(i)}>
            <BinCard bin={b} duplicateBin={duplicateBin} dupHovered={setDuplicate} />
          </Box>
        ))}
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {title && <Typography>{title}</Typography>}
      {gridView ? grid() : scroll()}
    </React.Fragment>
  );
}

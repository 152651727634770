import graphDark from "assets/common/graphDark.png";
import graphLight from "assets/common/graphLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function GraphIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? graphLight : graphDark;
    }

    return themeType === "light" ? graphDark : graphLight;
  };

  return <ImgIcon alt="graph" src={src()} />;
}

import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
      width: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      height: `calc(100vh - 112px)`,
      [theme.breakpoints.up("sm")]: {
        height: `calc(100vh - 64px)`
      }
    },
    fullViewportContainer: {
      position: "fixed",
      top: 0,
      left: 0,
      height: "100vh",
      width: "100vw",
      backgroundColor: theme.palette.background.default,
      zIndex: 2000,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    centerCenter: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }
  })
);

interface Props {
  fullViewport?: boolean;
  isCenterCenter?: boolean;
}

export const PageContainer: React.FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { children, fullViewport, isCenterCenter } = props;
  return (
    <Container
      className={classNames(
        fullViewport ? classes.fullViewportContainer : classes.pageContainer,
        isCenterCenter && classes.centerCenter
      )}
      disableGutters
      maxWidth={false}
      children={<React.Fragment>{children}</React.Fragment>}
    />
  );
};

export default PageContainer;

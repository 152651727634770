import { Box, BoxProps } from "@material-ui/core";
import React from "react";

export const EventBlocker: React.FunctionComponent<BoxProps> = props => {
  const { children } = props;

  const stopPropagation = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
  };

  return (
    <Box
      width="100%"
      component="div"
      onClick={stopPropagation}
      onFocus={stopPropagation}
      onTouchStart={stopPropagation}
      onTouchEnd={stopPropagation}
      onMouseDown={stopPropagation}
      onMouseEnter={stopPropagation}
      onMouseLeave={stopPropagation}
      onMouseMove={stopPropagation}
      onMouseOut={stopPropagation}
      onMouseOver={stopPropagation}
      onMouseUp={stopPropagation}
      onPointerEnter={stopPropagation}
      onPointerLeave={stopPropagation}
      {...props}>
      {children}
    </Box>
  );
};

export default EventBlocker;

import { Gate } from "models/Gate";
import { Box, Card, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useMobile } from "hooks";
import MaterialTable from "material-table";
import { getTableIcons } from "common/ResponsiveTable";
import { Terminal } from "models/Terminal";

interface Props {
  gates: Gate[];
  terminals: Terminal[];
  useMobile: boolean;
  //duplicateGate: (gate: Gate) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridListTile: {
      minHeight: "184px",
      height: "auto !important",
      width: "184px",
      padding: 2
    },
    hidden: {
      visibility: "hidden"
    },
    gateCard: {
      marginBottom: 10,
      paddingLeft: 15
    }
  })
);

export default function GateList(props: Props) {
  const { gates, terminals } = props;
  const history = useHistory();
  const isMobile = useMobile();
  const classes = useStyles();
  const [terminalMap, setTerminalMap] = useState<Map<string, string>>(new Map<string, string>());

  const goToGate = (gate: Gate) => {
    let path = "/terminals/" + gate.key;
    history.push(path, {
      gate: gate
    });
  };

  useEffect(() => {
    let map = new Map<string, string>();
    terminals.forEach(t => {
      map.set(t.key, t.name);
    });
    setTerminalMap(map);
  }, [terminals]);

  const desktopView = () => {
    return (
      <MaterialTable
        columns={[
          {
            title: "Gate",
            field: "name",
            headerStyle: {
              fontWeight: 650,
              fontSize: 20
            }
          },
          {
            title: "Terminals",
            field: "settings.terminal",
            headerStyle: {
              fontWeight: 650,
              fontSize: 20
            },
            render: rowData => terminalMap.get(rowData.terminal())
          },
          {
            title: "Duct Type",
            field: "settings.ductName",
            headerStyle: {
              fontWeight: 650,
              fontSize: 20
            }
          },
          {
            title: "Duct Size(mm)",
            field: "settings.ductDiameter",
            headerStyle: {
              fontWeight: 650,
              fontSize: 20
            }
          },
          {
            title: "Duct Length(m)",
            field: "settings.ductLength",
            headerStyle: {
              fontWeight: 650,
              fontSize: 20
            }
          },
          {
            title: "PCA Unit",
            field: "settings.pcaType",
            headerStyle: {
              fontWeight: 650,
              fontSize: 20
            }
          }
        ]}
        data={gates}
        icons={getTableIcons()}
        onRowClick={(_, gate) => {
          gate && goToGate(gate);
        }}
        title={""}
        options={{
          pageSize: 10
        }}
      />
    );
  };

  const mobileView = () => {
    return (
      <React.Fragment>
        {gates.map((gate, i) => (
          <Card
            key={i}
            className={classes.gateCard}
            onClick={() => {
              goToGate(gate);
            }}>
            <Box margin={2} fontSize={20} fontWeight={650}>
              {gate.name}
            </Box>
          </Card>
        ))}
      </React.Fragment>
    );
  };

  return isMobile || props.useMobile ? mobileView() : desktopView();
}

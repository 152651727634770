import {
  Avatar,
  Box,
  Card,
  createStyles,
  Drawer,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Slider,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Done, Edit, MoreVert } from "@material-ui/icons";
import DisplayDrawer from "common/DisplayDrawer";
import { Task } from "models";
import React, { useEffect, useState } from "react";
import NotesIcon from "@material-ui/icons/Notes";
import DeleteIcon from "@material-ui/icons/Delete";
import Chat from "chat/Chat";
import { pond } from "protobuf-ts/pond";
import { useMobile, useUserAPI } from "hooks";
import { getThemeType } from "theme";

interface Props {
  task: Task;
  open: boolean;
  closeCallback: () => void;
  editTask: (task: Task) => void;
  deleteTask: (task: Task) => void;
  completeTask: (task: Task) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    avatar: {
      color: getThemeType() === "light" ? theme.palette.common.black : theme.palette.common.white,
      backgroundColor: "transparent",
      width: theme.spacing(5),
      height: theme.spacing(5),
      border: "1px solid",
      borderColor: theme.palette.divider
    }
  });
});

export default function TaskDrawer(props: Props) {
  const monthList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ];
  const { task, open, closeCallback, completeTask, deleteTask, editTask } = props;
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
  const [openNote, setOpenNote] = useState(false);
  const isMobile = useMobile();
  const classes = useStyles();
  const userAPI = useUserAPI();
  const [worker, setWorker] = useState<pond.UserProfile>();

  useEffect(() => {
    if (task.start()) {
      let date = new Date(task.start());
      setMonth(date.getUTCMonth());
      setDay(date.getUTCDate());
    }
    userAPI.getProfile(task.worker()).then(resp => {
      setWorker(resp);
    });
  }, [task, userAPI]);

  const taskActions = () => {
    return (
      <Menu
        id="viewMenu"
        anchorEl={menuAnchorEl ? menuAnchorEl : null}
        open={menuAnchorEl !== null}
        onClose={() => setMenuAnchorEl(null)}
        disableAutoFocusItem>
        <MenuItem
          onClick={() => {
            editTask(props.task);
            setMenuAnchorEl(null);
          }}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteTask(props.task);
            setMenuAnchorEl(null);
            closeCallback();
          }}>
          <ListItemIcon>
            <DeleteIcon style={{ color: "red" }} />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
    );
  };

  const bodyButtons = () => {
    return (
      <Grid container direction="row" alignItems="center" justify="space-between">
        <Grid item>
          <Tooltip title="Task Notes">
            <IconButton className={classes.avatar} onClick={() => setOpenNote(true)}>
              <NotesIcon />
            </IconButton>
          </Tooltip>
          <IconButton style={{ visibility: "hidden" }}>3</IconButton> {/** go to bin/field */}
        </Grid>
        <Grid item>
          <Tooltip title="Mark Complete">
            <IconButton className={classes.avatar} onClick={() => completeTask(task)}>
              <Done style={{ color: task.complete() ? "green" : "white" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="More">
            <IconButton
              className={classes.avatar}
              onClick={event => setMenuAnchorEl(event.currentTarget)}>
              <MoreVert />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const noteDrawer = () => {
    return (
      <Drawer
        anchor={isMobile ? "bottom" : "right"}
        open={openNote}
        onClose={() => setOpenNote(false)}>
        <Box height={isMobile ? "50vh" : "100vh"} padding={2}>
          <Typography style={{ fontWeight: 650 }}>Notes</Typography>
          <Chat type={pond.NoteType.NOTE_TYPE_TASK} objectKey={task.key} />
        </Box>
      </Drawer>
    );
  };

  const taskCard = () => {
    return (
      <Card style={{ height: "35%" }}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={2}>
            <Box
              style={{
                border: "1px solid white",
                width: "50px",
                height: "50px",
                borderRadius: 5,
                margin: "auto",
                verticalAlign: "middle",
                lineHeight: "50px",
                textAlign: "center"
              }}>
              <Typography>{monthList[month]}</Typography>
              <Typography style={{ fontWeight: 750 }}>{day}</Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Typography style={{ fontWeight: 700, fontSize: 30 }}>{task.title()}</Typography>
          </Grid>
        </Grid>
        <Box padding={3}>{task.description()}</Box>
      </Card>
    );
  };

  const taskTime = () => {
    let start = task
      ? new Date(task.start() + "T" + task.startTime()).valueOf()
      : new Date().valueOf();
    let end = task ? new Date(task.end() + "T" + task.endTime()).valueOf() : new Date().valueOf();
    return (
      <Box style={{ height: "30%" }}>
        <Grid container direction="row" alignItems="center" style={{ height: "100%" }}>
          <Grid item xs={3}>
            <Box style={{ textAlign: "center" }}>
              <Typography>{task.start()}</Typography>
              <Typography>{task.startTime()}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Slider min={start} max={end} value={new Date().valueOf()} />
          </Grid>
          <Grid item xs={3}>
            <Box style={{ textAlign: "center" }}>
              <Typography>{task.end()}</Typography>
              <Typography>{task.endTime()}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const assignees = () => {
    return (
      <Card style={{ height: "35%" }}>
        <Box padding={2}>
          <Typography style={{ fontWeight: 700, fontSize: 30 }}>Assigned To:</Typography>
          {/* TODO: change this to get the user and display the user name and avatar */}
          {worker && (
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Avatar
                  alt={worker.name}
                  src={worker.avatar && worker.avatar !== "" ? worker.avatar : undefined}
                />
              </Grid>
              <Grid item>
                <Box marginLeft={2}>{worker.name}</Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Card>
    );
  };

  const drawerBody = () => {
    return (
      <Box padding={2}>
        {bodyButtons()}
        <Box marginTop={3} style={{ height: "65vh" }}>
          {taskCard()}
          {taskTime()}
          {assignees()}
        </Box>
        {taskActions()}
        {noteDrawer()}
      </Box>
    );
  };

  return (
    <DisplayDrawer
      width={500}
      title={task.title()}
      displayNext={() => {}}
      displayPrev={() => {}}
      drawerBody={drawerBody()}
      onClose={closeCallback}
      open={open}
    />
  );
}

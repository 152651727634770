import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@material-ui/core";
import ResponsiveDialog from "common/ResponsiveDialog";
import { Bin } from "models";
import { useBinAPI, useSnackbar } from "providers";
import React, { useEffect, useState } from "react";

interface Props {
  open: boolean;
  bin: Bin;
  closeDialog: () => void;
  refreshCallback: () => void;
}

export default function BinDuplication(props: Props) {
  const { open, bin, closeDialog, refreshCallback } = props;
  const [binDupName, setBinDupName] = useState("(copy of) " + bin.name());
  const binAPI = useBinAPI();
  const { openSnack } = useSnackbar();

  useEffect(() => {
    setBinDupName("(copy of) " + bin.name());
  }, [bin]);

  const duplicate = () => {
    let settings = bin.settings;
    settings.name = binDupName;
    binAPI
      .addBin(settings)
      .then(resp => {
        openSnack("Successfully duplicated bin");
      })
      .catch(err => {
        openSnack("Failed to duplicate bin");
      });
    refreshCallback();
    closeDialog();
  };

  return (
    <ResponsiveDialog open={open} onClose={closeDialog}>
      <DialogTitle>Create Duplicate Bin</DialogTitle>
      <DialogContent>
        <Typography>Be Aware:</Typography>
        <Typography>
          ALL Information will be duplicated (inventory, attached sensors, etc..)
        </Typography>
        <TextField
          id={"duplicateName"}
          fullWidth
          margin="normal"
          label="Name of Copy"
          value={binDupName}
          onChange={e => setBinDupName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} style={{ color: "red" }}>
          Cancel
        </Button>
        <Button onClick={duplicate} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

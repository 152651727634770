import BinyardDisplay from "bin/BinyardDisplay";
import DisplayDrawer from "common/DisplayDrawer";
import MapMarkerSettings from "Maps/MapMarkerSettings";
//import Bins from "pages/Bins";
import { pond } from "protobuf-ts/pond";
import { useBinYardAPI, useSnackbar } from "providers";
import React, { useEffect, useState } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  selectedYard: string;
  yards: Map<string, pond.BinYardSettings>;
  removeMarker: (key: string) => void;
  updateMarker: (key: string, objectSettings: pond.BinYardSettings) => void;
  moveMap: (long: number, lat: number) => void;
}

export default function BinYardDrawer(props: Props) {
  const { open, onClose, selectedYard, yards, removeMarker, updateMarker, moveMap } = props;
  const [yard, setYard] = useState<pond.BinYardSettings>(pond.BinYardSettings.create());
  const [openMarkerSettings, setOpenMarkerSettings] = useState(false);
  const { openSnack } = useSnackbar();
  const binyardAPI = useBinYardAPI();

  useEffect(() => {
    let yard = yards.get(selectedYard);
    if (yard) {
      setYard(yard);
    }
  }, [selectedYard, yards]);

  const closeDrawer = () => {
    onClose();
  };

  const displayNext = () => {
    let yardArr = Array.from(yards.values());
    let index = yardArr.indexOf(yard);
    let found = false;
    do {
      if (index === yardArr.length - 1) {
        index = 0;
      } else {
        index++;
      }
      let nextYard = yardArr[index];
      if (
        nextYard.latitude !== 0 &&
        nextYard.longitude !== 0 &&
        nextYard.latitude !== undefined &&
        nextYard.longitude !== undefined
      ) {
        setYard(nextYard);
        moveMap(nextYard.longitude, nextYard.latitude);
        found = true;
      }
    } while (!found);
  };

  const displayPrev = () => {
    let yardArr = Array.from(yards.values());
    let index = yardArr.indexOf(yard);
    let found = false;
    do {
      if (index === 0) {
        index = yardArr.length - 1;
      } else {
        index--;
      }
      let nextYard = yardArr[index];
      if (
        nextYard.latitude !== 0 &&
        nextYard.longitude !== 0 &&
        nextYard.latitude !== undefined &&
        nextYard.longitude !== undefined
      ) {
        setYard(nextYard);
        moveMap(nextYard.longitude, nextYard.latitude);
        found = true;
      }
    } while (!found);
  };

  const drawerBody = () => {
    //return <Bins insert yardFilter={yard.key} />;
    return <BinyardDisplay insert yardKey={yard.key} />;
  };

  /**
   * function to remove the marker and coordinates from the object
   */
  const remove = () => {
    //set the long/lat of the yard to 0 and call an update
    let settings = yard;
    settings.longitude = 0;
    settings.latitude = 0;
    binyardAPI
      .updateBinYard(yard.key, settings)
      .then(resp => {
        openSnack("Marker Removed");
        //then use the removeMarker prop function to update the markers in the parent map
        removeMarker(yard.key);
      })
      .catch(resp => {
        openSnack("there was a problem removing the marker");
      });
  };

  /**
   * function to update the settings of the marker
   */
  const update = () => {
    setOpenMarkerSettings(true);
  };

  return (
    <React.Fragment>
      <DisplayDrawer
        open={open}
        onClose={closeDrawer}
        displayNext={displayNext}
        displayPrev={displayPrev}
        title={yard.name}
        width={"40vw"}
        drawerBody={drawerBody()}
        updateElement={update}
        removeElement={remove}
      />
      <MapMarkerSettings
        close={() => {
          setOpenMarkerSettings(false);
        }}
        open={openMarkerSettings}
        theme={yard.theme ?? pond.ObjectTheme.create()}
        colourControl
        updateObject={newTheme => {
          let settings = yard;
          settings.theme = newTheme;
          binyardAPI
            .updateBinYard(yard.key, settings)
            .then(resp => {
              openSnack("marker settings updated");
              updateMarker(yard.key, settings);
            })
            .catch(() => {
              openSnack("failed to update marker settings");
            });
        }}
      />
    </React.Fragment>
  );
}

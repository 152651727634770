import DisplayDrawer from "common/DisplayDrawer";
import { Terminal as ITerminal } from "models/Terminal";
import Terminal from "pages/Terminals";
import { useSnackbar, useTerminalAPI } from "providers";
import React, { useState, useEffect } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  terminals: Map<string, ITerminal>;
  selectedKey: string;
  moveMap: (longitude: number, latitude: number) => void;
  removeMarker: (key: string) => void;
}

export default function TerminalDrawer(props: Props) {
  const { terminals, selectedKey, open, onClose, moveMap, removeMarker } = props;
  const [terminal, setTerminal] = useState<ITerminal>(ITerminal.create());
  const terminalAPI = useTerminalAPI();
  const { openSnack } = useSnackbar();

  useEffect(() => {
    let t = terminals.get(selectedKey);
    if (t) {
      setTerminal(t);
    }
  }, [selectedKey, terminals]);

  const closeDrawer = () => {
    onClose();
  };

  const displayNext = () => {
    let termArr = Array.from(terminals.values());
    let index = termArr.indexOf(terminal);
    let found = false;
    do {
      if (index === termArr.length - 1) {
        index = 0;
      } else {
        index++;
      }
      let next = termArr[index];
      if (
        next.longitude() !== 0 &&
        next.longitude() !== 0 &&
        next.latitude() !== undefined &&
        next.longitude() !== undefined
      ) {
        setTerminal(next);
        moveMap(next.longitude(), next.latitude());
        found = true;
      }
    } while (!found);
  };

  const displayPrev = () => {
    let termArr = Array.from(terminals.values());
    let index = termArr.indexOf(terminal);
    let found = false;
    do {
      if (index === 0) {
        index = termArr.length - 1;
      } else {
        index--;
      }
      let next = termArr[index];
      if (
        next.longitude() !== 0 &&
        next.latitude() !== 0 &&
        next.latitude() !== undefined &&
        next.longitude() !== undefined
      ) {
        setTerminal(next);
        moveMap(next.longitude(), next.latitude());
        found = true;
      }
    } while (!found);
  };

  const drawerBody = () => {
    return <Terminal terminal={terminal} />;
  };

  /**
   * function to remove the marker and coordinates from the object
   */
  const remove = () => {
    //set the long/lat of the yard to 0 and call an update
    let settings = terminal.settings;
    settings.longitude = 0;
    settings.latitude = 0;
    terminalAPI
      .updateTerminal(terminal.key, terminal.name, settings)
      .then(resp => {
        openSnack("Marker Removed");
        //then use the removeMarker prop function to update the markers in the parent map
        removeMarker(terminal.key);
      })
      .catch(err => {
        openSnack("Failed to remove marker");
      });
  };

  return (
    <React.Fragment>
      <DisplayDrawer
        open={open}
        onClose={closeDrawer}
        title={terminal.name}
        width="40vw"
        displayNext={displayNext}
        displayPrev={displayPrev}
        drawerBody={drawerBody()}
        removeElement={remove}
      />
    </React.Fragment>
  );
}

import React, { createContext, PropsWithChildren, useContext } from "react";
import { useHTTP, useAuth } from "hooks";

interface IBillingContext {
  getCustomer: (customer: any) => Promise<any>;
  listInvoices: (customer: any) => Promise<any>;
  listProducts: () => Promise<any>;
  setDefaultSource: (customer: any, source: any) => Promise<any>;
  deleteSource: (customer: any, source: any) => Promise<any>;
  createCard: (customer: any, source: any) => Promise<any>;
  payInvoice: (customer: any, invoice: any, source: any) => Promise<any>;
  setCollectionMethod: (customer: any, subscription: any, method: any) => Promise<any>;
}

export const BillingContext = createContext<IBillingContext>({} as IBillingContext);

interface Props {}

export default function BillingProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, post, del } = useHTTP();
  const { isAuthenticated, token } = useAuth();

  const options = () => {
    if (!isAuthenticated || !token) {
      return {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ` + token
        }
      };
    }

    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ` + token
      }
    };
  };

  const url = (partial: string): string => {
    return process.env.REACT_APP_BILLING_URL + partial;
  };

  const getCustomer = (customer: any) => {
    return get(url("/customers/" + customer), options());
  };

  const listInvoices = (customer: any) => {
    return get(url("/customers/" + customer + "/invoices?limit=100"), options());
  };

  const listProducts = () => {
    return get(url("/products?limit=100"), options());
  };

  const setDefaultSource = (customer: any, source: any) => {
    return post(url("/customers/" + customer + "/sources/" + source + "/default"), {}, options());
  };

  const deleteSource = (customer: any, source: any) => {
    return del(url("/customers/" + customer + "/sources/" + source), options());
  };

  const createCard = (customer: any, source: any) => {
    return post(url("/customers/" + customer + "/sources/" + source), {}, options());
  };

  const payInvoice = (customer: any, invoice: any, source: any) => {
    return post(
      url("/customers/" + customer.id + "/invoices/" + invoice + "/pay"),
      {
        source: source
      },
      options()
    );
  };

  const setCollectionMethod = (customer: any, subscription: any, method: any) => {
    return post(
      url("/customers/" + customer + "/subscriptions/" + subscription + "/method"),
      {
        method: method
      },
      options()
    );
  };

  return (
    <BillingContext.Provider
      value={{
        getCustomer,
        listInvoices,
        listProducts,
        setDefaultSource,
        deleteSource,
        createCard,
        payInvoice,
        setCollectionMethod
      }}>
      {children}
    </BillingContext.Provider>
  );
}

export const useBilling = () => useContext(BillingContext);

import React, { useContext, useReducer } from "react";
import { Actions, newContext } from "./generic";
import { Tag } from "models";

export interface TagsState {
  tags: Tag[];
}

export const TagsContext = newContext<TagsState>();

export const useTags = () => useContext(TagsContext);

export const TagsProvider = (props: { children?: any }) => {
  const reducer = (state: TagsState, action: Actions<TagsState>): TagsState => {
    return { ...state, [action.key]: action.value };
  };
  return (
    <TagsContext.Provider value={useReducer(reducer, {} as TagsState)}>
      {props.children}
    </TagsContext.Provider>
  );
};

import FullCalendar, { EventClickArg } from "@fullcalendar/react";
import DayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import React, { useState } from "react";
import { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Task } from "models";

interface Props {
  dateClickMethod: (args: DateClickArg) => void;
  taskClickMethod: (arg: EventClickArg) => void;
  tasks: Task[];
  changeDateCallback: (date: Date) => void;
  initialView?: string;
  initialDate?: Date;
  centerToolbar?: string;
  calHeight?: string | number;
  hideToday?: boolean;
  hideTitle?: boolean;
  hideCal?: boolean;
  hideCalCallback?: () => void;
}

interface Event {
  id: string;
  title: string;
  start: Date;
  end: Date;
  backgroundColor: string;
}

export default function TaskCalendar(props: Props) {
  const {
    dateClickMethod,
    taskClickMethod,
    hideCalCallback,
    changeDateCallback,
    tasks,
    initialView,
    centerToolbar,
    calHeight,
    hideToday,
    hideTitle,
    hideCal
  } = props;
  const [events, setEvents] = useState<Event[]>([]);
  let ref = React.createRef<FullCalendar>();

  useEffect(() => {
    setEvents([]);
    let e: Event[] = [];
    tasks.forEach(task => {
      let event: Event = {
        id: task.key,
        title: task.title(),
        start: new Date(task.start() + "T" + task.startTime()),
        end: new Date(task.end() + "T" + task.endTime()),
        backgroundColor: task.settings.colour
      };
      e.push(event);
      setEvents([...e]);
    });
  }, [tasks]);

  useEffect(() => {
    if (ref.current) {
      let api = ref.current.getApi();
      api.gotoDate(props.initialDate);
    }
  }, [props.initialDate, ref]);

  return (
    <Box>
      <FullCalendar
        contentHeight={hideCal ? 0 : calHeight}
        headerToolbar={{
          start: hideTitle ? "" : "title",
          center: centerToolbar,
          end: hideToday ? "back,forward" : "today back,forward"
        }}
        titleFormat={{ month: "short", year: "numeric" }}
        plugins={[DayGridPlugin, timeGridPlugin, interactionPlugin]}
        //options for view dayGridWeek, dayGridMonth, timeGridWeek, timeGridMonth, timeGridDay
        initialView={initialView}
        dateClick={dateClickMethod}
        eventClick={taskClickMethod}
        events={events}
        allDaySlot={false}
        slotEventOverlap={false}
        ref={ref}
        nowIndicator
        dayHeaders={!hideCal}
        customButtons={{
          hide: {
            text: "Hide",
            click: () => {
              if (hideCalCallback) {
                hideCalCallback();
              }
            }
          },
          back: {
            text: "<",
            click: () => {
              if (ref.current) {
                let api = ref.current.getApi();
                api.prev();
                changeDateCallback(api.getDate());
              }
            }
          },
          forward: {
            text: ">",
            click: () => {
              if (ref.current) {
                let api = ref.current.getApi();
                api.next();
                changeDateCallback(api.getDate());
              }
            }
          }
        }}
      />
    </Box>
  );
}

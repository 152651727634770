import { useAuth, useSnackbar, useUserAPI } from "hooks";
import { User } from "models";
import { useGlobalState } from "providers";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Loading from "./Loading";

interface Props {}

export default function Callback(props: Props) {
  const history = useHistory();
  const { isAuthenticated, userID } = useAuth();
  const { error } = useSnackbar();
  const [{ user }, dispatch] = useGlobalState();
  const userAPI = useUserAPI();

  const baseURL = (): string => {
    return process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "";
  };

  const getRouteBeforeLogin = (): string => {
    let route = localStorage.getItem("routeBeforeLogin");
    return route ? route : "";
  };

  useEffect(() => {
    if (!isAuthenticated || !userID) {
      error("Unexpected error occured while attempting to log in.");
      history.push(baseURL());
      return;
    }

    let url = getRouteBeforeLogin();
    if (
      !url ||
      url.startsWith("/team") ||
      url.startsWith("/hardware") ||
      url.startsWith("/data") ||
      url.startsWith("/login") ||
      url.startsWith("/callback") ||
      url.startsWith("/demo")
    ) {
      url = baseURL();
    }

    if (user.id() !== userID)
      userAPI
        .getUser(userID)
        .then((u: User) => {
          dispatch({ key: "user", value: u });
          if (u.settings.defaultTeam && u.settings.useTeam) {
            dispatch({ key: "as", value: u.settings.defaultTeam });
          }
          if (!u.status.finishedIntro) {
            history.push("/welcome");
          } else {
            history.push(url);
          }
        })
        .catch((err: any) => {
          error("Unexpected error occurred while attempting to log in.");
          history.push(baseURL());
        });
  }, [dispatch, history, isAuthenticated, userAPI, userID, error, user]);

  return <Loading initial fullViewport />;
}

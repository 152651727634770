export function getContextKeys(): string[] {
  // Get the url entries without any zero length strings
  let entries = document.location.pathname.split("/").filter(key => key.length > 0);
  let cutTail = entries.length % 2 === 0;

  // Filter out every other entry
  entries = entries.filter((_, index) => index % 2 === 1);

  if (cutTail) return entries.slice(0, -1);

  return entries;
}

export function getContextTypes(): string[] {
  // Get the url entries without any zero length strings
  let entries = document.location.pathname.split("/").filter(key => key.length > 0);

  // Filter out every other entry
  entries = entries.filter((_, index) => index % 2 === 0);

  // jobsite should just be site and none should end in s anymore
  entries = entries.slice(0, -1).map(str => (str.endsWith("s") ? str.slice(0, -1) : str));
  entries = entries.map(item => (item === "jobsite" ? "site" : item));

  // Return all types but the last (it is the object being loaded, not the context)
  return entries;
}

import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CheckboxProps,
  createStyles,
  darken,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
  withStyles
} from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import HumidityIcon from "component/HumidityIcon";
import TemperatureIcon from "component/TemperatureIcon";
import GrainDescriber from "grain/GrainDescriber";
import useViewport from "hooks/useViewport";
import { round } from "lodash";
import { Bin, Component, Device, Interaction } from "models";
import { GetComponentIcon } from "pbHelpers/ComponentType";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import { useMobile } from "hooks";
import { pond } from "protobuf-ts/pond";
import { quack } from "protobuf-ts/quack";
import { useGlobalState } from "providers";
import React, { useCallback, useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import BinSVG from "./BinSVG";
import moment, { Moment } from "moment";
import ResponsiveDialog from "common/ResponsiveDialog";
import { getThemeType } from "theme";
import { getGrainUnit, getTemperatureUnit, or } from "utils";
import { yellow } from "@material-ui/core/colors";
import { useBinAPI } from "providers/pond/binAPI";
import CableIcon from "products/Bindapt/CableIcon";
import BindaptIcon from "products/Bindapt/BindaptIcon";
import { CheckCircleOutline, Error, InfoOutlined, Warning } from "@material-ui/icons";
import { ExtractMoisture } from "grain";
import DevicePresetsFromPicker from "device/DevicePresetsFromPicker";
import { Plenum } from "models/Plenum";
import { Pressure } from "models/Pressure";
import { GrainCable } from "models/GrainCable";
import GrainNodeInteractions from "./GrainNodeInteractions";
import GrainTransaction from "grain/GrainTransaction";
import { DevicePreset } from "models/DevicePreset";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum zIndexPriority {
  low = 1,
  medium = 2,
  high = 3
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    checkbox: {
      marginTop: theme.spacing(-0.75),
      paddingTop: 0,
      paddingRight: 0,
      fontSize: 10
    },
    cardContent: {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(0)
    },
    binSVG: {
      height: "40vh"
    },
    bgItem: {
      background: darken(theme.palette.background.paper, getThemeType() === "light" ? 0.05 : 0.25),
      zIndex: zIndexPriority.low,
      borderRadius: theme.shape.borderRadius,
      position: "relative",
      width: "auto",
      marginRight: theme.spacing(1)
    },
    days: {
      margin: theme.spacing(0.5),
      fontSize: "16px"
    },
    listSubheader: {
      lineHeight: "normal"
    },
    listItemAvatar: {
      minWidth: 0
    },
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginTop: "auto",
      marginBottom: "auto"
    },
    avatarIcon: {
      background: "transparent",
      width: theme.spacing(3),
      height: theme.spacing(3),
      margin: "auto",
      marginBottom: "auto"
    },
    centerVertical: {
      top: theme.spacing(12)
    },
    spacingItem: {
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(1),
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    },
    spacer: {
      paddingLeft: theme.spacing(0.25)
    },
    bigSpacer: {
      paddingLeft: theme.spacing(3.2)
    },
    selected: {
      backgroundColor: "gold",
      "&.selected": {
        backgroundColor: "gold"
      }
    },
    measurement: {
      margin: 0,
      transform: "translateX(-0.25rem)"
    },
    extraDetails: {
      padding: 1,
      marginTop: theme.spacing(1),
      display: "flex",
      width: "100%",
      flexGrow: 1,
      height: "100%"
    },
    smallFont: {
      fontSize: "10px"
    },
    smallMeasurement: {
      fontSize: "13px"
    },
    numbers: {
      transform: "translateY(-0.5rem)",
      marginTop: theme.spacing(0.5)
    },
    pointerLeft: {
      borderBottom: "1px solid",
      borderLeft: "1px solid",
      borderColor: theme.palette.text.primary,
      width: "50%",
      height: theme.spacing(0.7)
    },
    pointerRight: {
      borderBottom: "1px solid white",
      borderLeft: "1px solid white",
      borderRight: "1px solid white",
      borderColor: theme.palette.text.primary,
      width: "50%",
      height: theme.spacing(0.7)
    },
    grainOVerlay: {
      position: "absolute",
      height: "10%",
      width: "100%",
      textAlign: "center",
      textShadow: "4px 4px 10px black"
    },
    grainDiff: {
      position: "absolute",
      top: "10%"
    }
  });
});

const average = (arr: any) => arr.reduce((p: any, c: any) => p + c, 0) / arr.length;

interface Props {
  bin: Bin;
  loading: boolean;
  components: Map<string, Component>;
  componentDevices?: Map<string, number>;
  devices: Device[];
  plenum?: Plenum;
  cables?: GrainCable[];
  pressure?: Pressure;
  interactions?: Interaction[];
  //changeBinMode: (binMode: pond.BinMode) => boolean;
  refresh: (showSnack?: boolean) => void;
  afterUpdate?(): void;
  preferences?: Map<string, pond.BinComponentPreferences>;
  permissions?: pond.Permission[];
  updateComponentCallback: (componentKey: string) => void;
  binPresets: DevicePreset[];
}

export default function BinVisualizer(props: Props) {
  const {
    bin,
    //changeBinMode,
    //changeGrainAmount,
    plenum,
    pressure,
    loading,
    cables,
    components,
    devices,
    preferences,
    componentDevices,
    permissions,
    //interactions,
    refresh,
    updateComponentCallback,
    binPresets
  } = props;
  const binAPI = useBinAPI();
  const isMobile = useMobile();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreenHandler = useFullScreenHandle();
  const viewport = useViewport();
  const [fillPercentage, setFillPercentage] = useState<number | null>(0);
  const [lidarBushels, setLidarBushels] = useState<number | undefined>();
  const [lidarPercentage, setLidarPercentage] = useState<number | undefined>();
  const [pendingGrainAmount, setPendingGrainAmount] = useState<number | undefined>();
  const tempColour = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_TEMPERATURE
  ).colour();
  const humidColour = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PERCENT,
    quack.ComponentType.COMPONENT_TYPE_DHT
  ).colour();
  const pressColour = describeMeasurement(quack.MeasurementType.MEASUREMENT_TYPE_PRESSURE).colour();
  const [lastGrainCable, setLastGrainCable] = useState<GrainCable>();
  const [showInputMoisture, setShowInputMoisture] = useState<boolean>(false);
  const [moistureInput, setMoistureInput] = useState<string>("");
  const [tempInput, setTempInput] = useState<string>("");
  const [outdoorHumidityInput, setOutdoorHumidityInput] = useState<string>("");
  const [modeTime, setModeTime] = useState<Moment>(moment());
  const [grainUpdate, setGrainUpdate] = useState<boolean>(false);

  const [sliderColour, setSliderCoulour] = useState("gold");
  const [grainDiff, setGrainDiff] = useState<number>(0);

  const [grainCables, setGrainCables] = useState<GrainCable[]>([]);
  const [showMulti, setShowMulti] = useState(true);
  const [cableIndex, setCableIndex] = React.useState<number>(0);
  const iOS = typeof window !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [nodeDetails, setNodeDetails] = useState(false);
  const [cfmLowOpen, setCFMLowOpen] = useState(false);
  const [cfmHighOpen, setCFMHighOpen] = useState(false);
  const [{ user }] = useGlobalState();
  const [newPreset, setNewPreset] = useState<pond.BinMode>(pond.BinMode.BIN_MODE_NONE);
  const [selectedCable, setSelectedCable] = useState<GrainCable>();
  const [openNodeDialog, setOpenNodeDialog] = useState(false);
  const [cableDevice, setCableDevice] = useState<Device | undefined>();
  const [selectedNode, setSelectedNode] = useState(0);
  const [devMap, setDevMap] = useState<Map<number, Device>>(new Map<number, Device>());

  const StyledToggle = withStyles({
    root: {
      backgroundColor: "transparent",
      overflow: "visible",
      content: "content-box",
      "&$selected": {
        backgroundColor: "gold",
        color: "black",
        borderRadius: 24,
        fontWeight: "bold"
      },
      "&$selected:hover": {
        backgroundColor: "rgb(255, 255, 0)",
        color: "black",
        borderRadius: 24
      }
    },
    selected: {}
  })(ToggleButton);

  const StyledToggleButtonGroup = withStyles(theme => ({
    grouped: {
      margin: theme.spacing(-0.5),
      border: "none",
      padding: theme.spacing(1),
      "&:not(:first-child):not(:last-child)": {
        borderRadius: 24,
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5)
      },
      "&:first-child": {
        borderRadius: 24,
        marginLeft: theme.spacing(0.25)
      },
      "&:last-child": {
        borderRadius: 24,
        marginRight: theme.spacing(0.25)
      }
    },
    root: {
      backgroundColor: darken(
        theme.palette.background.paper,
        getThemeType() === "light" ? 0.05 : 0.25
      ),
      borderRadius: 24,
      content: "border-box"
    }
  }))(ToggleButtonGroup);

  useEffect(() => {
    setModeTime(moment(bin.status.lastModeChange));
  }, [bin.status.lastModeChange]);

  useEffect(() => {
    if (bin.settings.inventory) setMoistureInput(bin.settings.inventory.initialMoisture.toString());
    if (bin.settings) {
      let t = bin.settings.outdoorTemp;
      if (user.settings.temperatureUnit === pond.TemperatureUnit.TEMPERATURE_UNIT_FAHRENHEIT) {
        t = CtoF(t);
      }
      setTempInput(t.toString());
    }
    if (bin.settings) setOutdoorHumidityInput(bin.settings.outdoorHumidity.toString());
  }, [bin, user]);

  useEffect(() => {
    setGrainCables(cables ?? []);
    let cm = 0;
    //just pull the number from the status
    if (bin.status.distance && bin.status.distance > 0) {
      //note that no conversions are happening as the unit measurement model is not being used so the value will be in cm
      cm = bin.status.distance;
      let height = or(bin.settings.specs?.heightCm, 0);
      let ratio = 1 - cm / height;
      let capacity = or(bin.settings.specs?.bushelCapacity, 0);
      let lidarEstimate = Math.round(capacity * ratio);
      setLidarBushels(lidarEstimate);
      setLidarPercentage(Math.round((lidarEstimate / capacity) * 100));
    } else {
      setLidarBushels(undefined);
      setLidarPercentage(undefined);
    }
  }, [cables, components, bin]);

  useEffect(() => {
    let newMap = new Map<number, Device>();
    devices.forEach(dev => {
      newMap.set(dev.id(), dev);
    });
    setDevMap(newMap);
  }, [devices]);

  const resetFillPercentage = useCallback(() => {
    if (bin.settings.inventory && bin.settings.specs) {
      const isEmpty = bin.settings.inventory?.empty === true;
      const capacity = bin.settings.specs.bushelCapacity;
      const grainBushels = bin.settings.inventory.grainBushels;
      setSliderCoulour("gold");
      if (!capacity) {
        setFillPercentage(null);
      } else if (isEmpty || !grainBushels) {
        setFillPercentage(0);
      } else {
        setFillPercentage(Math.round((grainBushels / capacity) * 100));
      }
    }
  }, [bin.settings.specs, bin.settings.inventory]);

  useEffect(() => {
    resetFillPercentage();
  }, [resetFillPercentage]);

  const GreenCheckbox = withStyles({
    root: {
      color: yellow[400],
      "&$checked": {
        color: yellow[600]
      }
      //padding: theme.spacing(1),
      //marginTop: theme.spacing(-0.5),
    },
    checked: {}
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  const getVolume = (val: number) => {
    if (bin.storage() === pond.BinStorage.BIN_STORAGE_FERTILIZER) {
      return Math.round(val * 35.239 * 100) / 100;
    } else {
      if (getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT && bin.bushelsPerTonne() > 1) {
        return Math.round((val / bin.bushelsPerTonne()) * 100) / 100;
      }
    }
    return val;
  };

  const getCapacityDisplay = (bushelCapacity: number) => {
    let c = 1;
    if (bushelCapacity !== 0) {
      c = bushelCapacity;
    }
    let capacity = getVolume(c);
    if (bin.storage() === pond.BinStorage.BIN_STORAGE_FERTILIZER) {
      return " / " + capacity.toLocaleString() + " L";
    }
    if (getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT && bin.bushelsPerTonne() > 1) {
      return "mT (" + bin.fillPercent() + "%)";
    } else {
      return " / " + capacity.toLocaleString() + " bu";
    }
  };

  const inventoryOverview = () => {
    const capacity = bin.settings.specs?.bushelCapacity ?? 0;
    const grainBushels = bin.settings.inventory?.grainBushels ?? 0;
    const isEmpty = bin.settings.inventory?.empty === true || !grainBushels || grainBushels <= 0;
    const grainType = bin.settings.inventory?.grainType;
    const grainTypeName = isEmpty || !grainType ? "" : GrainDescriber(grainType).name;
    const customTypeName = bin.settings.inventory?.customTypeName;
    const grainSubtype = bin.settings.inventory?.grainSubtype;
    return (
      <Box width={1} position="absolute" top={0}>
        <Typography variant="subtitle2" color="textPrimary" style={{ fontWeight: 800 }}>
          {bin.storage() === pond.BinStorage.BIN_STORAGE_SUPPORTED_GRAIN
            ? grainTypeName
            : customTypeName}
          {grainSubtype !== "" ? " - " + grainSubtype : ""}
        </Typography>
        <Box className={classes.bgItem} padding={1}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="body2" style={{ fontWeight: "bold", marginRight: "4px" }}>
              {isEmpty ? "Empty" : (grainBushels ? getVolume(grainBushels) : 0).toLocaleString()}
            </Typography>
            <Typography variant="body2">{isEmpty ? "" : getCapacityDisplay(capacity)}</Typography>
          </div>
          {!isEmpty && lidarBushels && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="body2">
                {(lidarBushels ? getVolume(lidarBushels) : 0).toLocaleString()} /{" "}
                {(capacity ? getVolume(capacity) : 0).toLocaleString()} bu (est)
              </Typography>
            </div>
          )}
        </Box>
      </Box>
    );
  };

  const CtoF = (celsius: number) => {
    return Math.round((celsius * (9 / 5) + 32) * 100) / 100;
  };

  const FtoC = (fahrenheit: number) => {
    return Math.round((fahrenheit - 32) * (5 / 9) * 100) / 100;
  };

  const getTemp = (temp: number): string => {
    if (!isFinite(temp) || isNaN(temp)) {
      return "--";
    }
    if (user.settings.temperatureUnit === pond.TemperatureUnit.TEMPERATURE_UNIT_FAHRENHEIT) {
      temp = temp * (9 / 5) + 32;
    }
    return temp.toFixed(1);
  };

  const getHum = (hum: number): string => {
    if (!isFinite(hum) || isNaN(hum)) {
      return "--";
    }
    return hum.toFixed(1);
  };

  useEffect(() => {
    if (cables) {
      setLastGrainCable(cables[cableIndex]);
    }
  }, [cableIndex, cables]);

  const grainCableOverview = () => {
    return (
      <Box width={1} position="absolute" className={classes.centerVertical}>
        {grainCables.length > 0 ? (
          <FormControl variant="standard">
            <Grid container direction="row" alignItems="center">
              <Grid item xs>
                <Select
                  style={{ maxWidth: 110 }}
                  disableUnderline={true}
                  value={cableIndex}
                  onChange={(event: any) => {
                    setCableIndex(Number(event.target.value));
                  }}>
                  {grainCables.map((cable, index) => {
                    return (
                      <MenuItem key={index} value={index}>
                        <Typography
                          variant="caption"
                          color="textPrimary"
                          style={{ fontWeight: 600 }}>
                          {cable.name()}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs>
                <Tooltip arrow title={"Show all cables attached to bin"}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={showMulti}
                        onChange={e => setShowMulti(e.target.checked)}
                        name="multiCables"
                        size="small"
                      />
                    }
                    label={<Typography variant="caption">All</Typography>}
                    className={classes.checkbox}
                    disabled={grainCables.length < 2}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </FormControl>
        ) : (
          <Typography variant="subtitle2" color="textPrimary" style={{ fontWeight: 600 }}>
            Grain Cable
          </Typography>
        )}
        <Box className={classes.bgItem} padding={1}>
          {lastGrainCable ? (
            <Box>
              <Box display="flex">
                <Box>
                  <Avatar variant="square" className={classes.avatarIcon}>
                    <TemperatureIcon />
                  </Avatar>
                </Box>
                {viewport.width > 390 && (
                  <Box className={classes.measurement} style={{ paddingTop: "3px" }}>
                    <Typography variant="body2" color="textSecondary">
                      {user.settings.temperatureUnit ===
                      pond.TemperatureUnit.TEMPERATURE_UNIT_CELSIUS
                        ? "°C"
                        : "°F"}
                    </Typography>
                  </Box>
                )}
                <Grid container justify="space-between" className={classes.numbers}>
                  <Grid item>
                    <Typography variant="body2" style={{ color: tempColour }}>
                      {getTemp(Math.min(...lastGrainCable.temperatures))}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ color: tempColour }}>
                      {getTemp(Math.min(...lastGrainCable.temperatures))}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ color: tempColour }}>
                      {getTemp(Math.max(...lastGrainCable.temperatures))}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <React.Fragment>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: theme.spacing(-1.2),
                    marginLeft: viewport.width > 390 ? theme.spacing(7) : theme.spacing(4.5),
                    marginRight: theme.spacing(1.5)
                  }}>
                  <div className={classes.pointerLeft} />
                  <div className={classes.pointerRight} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: viewport.width > 390 ? theme.spacing(5) : theme.spacing(2.5)
                  }}></div>
              </React.Fragment>

              <Box marginY={1}>
                <Divider style={{ background: "gold" }} />
              </Box>
              <Box display="flex">
                <Box>
                  <Avatar variant="square" className={classes.avatarIcon}>
                    <HumidityIcon />
                  </Avatar>
                </Box>
                {viewport.width > 390 && (
                  <Box className={classes.measurement} style={{ paddingTop: "3px" }}>
                    <Typography variant="body2" color="textSecondary">
                      %
                    </Typography>
                  </Box>
                )}
                <Grid container className={classes.numbers} justify="space-between">
                  <Grid item>
                    <Typography variant="body2" style={{ color: humidColour }}>
                      {getHum(
                        Math.min(
                          ...lastGrainCable.humidities.map((h, i) =>
                            ExtractMoisture(bin.grain(), lastGrainCable.temperatures[i] ?? 0, h)
                          )
                        )
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ color: humidColour }}>
                      {getHum(
                        average(
                          lastGrainCable.humidities.map((h, i) =>
                            ExtractMoisture(bin.grain(), lastGrainCable.temperatures[i] ?? 0, h)
                          )
                        )
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ color: humidColour }}>
                      {getHum(
                        Math.max(
                          ...lastGrainCable.humidities.map((h, i) =>
                            ExtractMoisture(bin.grain(), lastGrainCable.temperatures[i] ?? 0, h)
                          )
                        )
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Box display="flex" alignItems="flex-end" className={classes.numbers}>
                  <Box className={classes.spacingItem}>
                    <Typography variant="body2" style={{ color: humidColour }}>
                      {Math.min(
                        ...lastGrainCable.humidities.map((h, i) =>
                          ExtractMoisture(bin.grain(), lastGrainCable.temperatures[i] ?? 0, h)
                        )
                      ).toFixed(1)}
                    </Typography>
                  </Box>
                  <Box className={classes.spacingItem}>
                    <Typography variant="body2" style={{ color: humidColour }}>
                      {average(
                        lastGrainCable.humidities.map((h, i) =>
                          ExtractMoisture(bin.grain(), lastGrainCable.temperatures[i] ?? 0, h)
                        )
                      ).toFixed(1)}
                    </Typography>
                  </Box>
                  <Box className={classes.spacingItem}>
                    <Typography variant="body2" style={{ color: humidColour }}>
                      {Math.max(
                        ...lastGrainCable.humidities.map((h, i) =>
                          ExtractMoisture(bin.grain(), lastGrainCable.temperatures[i] ?? 0, h)
                        )
                      ).toFixed(1)}
                    </Typography>
                  </Box>
                </Box> */}
              </Box>
              <React.Fragment>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: theme.spacing(-1),
                    marginLeft: viewport.width > 390 ? theme.spacing(7) : theme.spacing(4.5),
                    marginRight: theme.spacing(1.5)
                  }}>
                  <div className={classes.pointerLeft} />
                  <div className={classes.pointerRight} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: viewport.width > 390 ? theme.spacing(5) : theme.spacing(2.5)
                  }}></div>
              </React.Fragment>
            </Box>
          ) : (
            <Box display="flex" flexDirection="row">
              <Avatar variant="square" className={classes.avatarIcon}>
                <CableIcon />
              </Avatar>
              <Box paddingTop={2} paddingBottom={2} textAlign="center" width={"85%"}>
                <Link href="https://www.bindapt.com/product/moisture-cables-bindapt-ready/">
                  Need a Grain Cable?
                </Link>
              </Box>
            </Box>
          )}
        </Box>
        {lastGrainCable && (
          <Box
            display="flex"
            flexDirection="row"
            marginLeft={theme.spacing(0.77)}
            marginRight={theme.spacing(0.3)}>
            <Grid container direction="row" wrap="nowrap" justify="space-between">
              <Grid item>
                <Typography variant="body2" color="textSecondary" className={classes.smallFont}>
                  Low
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary" className={classes.smallFont}>
                  Avg.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary" className={classes.smallFont}>
                  High
                </Typography>
              </Grid>
            </Grid>
            {/* <Box className={[classes.spacer].join(" ")}>
              <Typography variant="body2" color="textSecondary" className={classes.smallFont}>
                Low
              </Typography>
            </Box>
            <Box className={[classes.bigSpacer].join(" ")}>
              <Typography variant="body2" color="textSecondary" className={classes.smallFont}>
                Avg.
              </Typography>
            </Box>
            <Box className={[classes.bigSpacer].join(" ")}>
              <Typography variant="body2" color="textSecondary" className={classes.smallFont}>
                High
              </Typography>
            </Box> */}
          </Box>
        )}
      </Box>
    );
  };

  const plenumOverview = () => {
    let classString = classes.spacer;
    if (isMobile) {
      classString = classString + " " + classes.smallMeasurement;
    }

    return (
      <Box padding="0" width={1} position="absolute" top={theme.spacing(28)}>
        <Typography variant="subtitle2" color="textPrimary" style={{ fontWeight: 600 }}>
          Plenum
        </Typography>
        <Box className={classes.bgItem} display="flex" flexDirection={"column"}>
          {plenum ? (
            <React.Fragment>
              <Box flexDirection={"row"} padding={1} display="flex">
                <Avatar
                  variant="square"
                  src={GetComponentIcon(
                    quack.ComponentType.COMPONENT_TYPE_DHT,
                    undefined,
                    theme.palette.type
                  )}
                  className={classes.avatar}
                />

                <Box marginLeft={1} className={classes.bgItem}>
                  <React.Fragment>
                    <Typography
                      className={classString}
                      variant="body2"
                      display="inline"
                      style={{ color: tempColour }}>
                      {plenum.getTempString(user.settings.temperatureUnit)}
                    </Typography>
                    {", "}
                    <Typography
                      className={classString}
                      variant="body2"
                      display="inline"
                      style={{ color: humidColour }}>
                      {plenum.getHumidityString()}
                    </Typography>
                  </React.Fragment>
                </Box>
              </Box>
              <Divider
                style={{
                  marginLeft: theme.spacing(1),
                  background: "gold",
                  marginRight: theme.spacing(1)
                }}
              />
              {pressureOverview()}
            </React.Fragment>
          ) : (
            <Box flexDirection={"row"} padding={1} display="flex">
              <Avatar variant="square" className={classes.avatarIcon}>
                <BindaptIcon />
              </Avatar>
              <Box paddingTop={2} paddingBottom={2} textAlign="center" width={"85%"}>
                <Link href="https://www.bindapt.com/bins/">View Smart Bin Devices</Link>
              </Box>
            </Box>
          )}
        </Box>
        {pressure && pressure.fanId === 0 && !bin.settings.fan?.type && bin.fanID() === 0 && (
          <Typography variant="subtitle2" color="textPrimary" style={{ fontWeight: 600 }}>
            No fans found to calculate CFM
          </Typography>
        )}
      </Box>
    );
  };

  const cfmDryWarning = (cfm: number) => {
    if (cfm > 2) {
      return (
        <IconButton onClick={() => setCFMHighOpen(true)}>
          <Error style={{ color: "yellow" }} />
        </IconButton>
      );
    } else if (cfm < 1) {
      return (
        <IconButton onClick={() => setCFMLowOpen(true)}>
          <Warning style={{ color: "red" }} />
        </IconButton>
      );
    } else {
      return (
        <IconButton disabled>
          <CheckCircleOutline style={{ color: "green" }} />
        </IconButton>
      );
    }
  };

  const cfmHighDialog = () => {
    return (
      <ResponsiveDialog
        open={cfmHighOpen}
        onClose={() => {
          setCFMHighOpen(false);
        }}>
        <DialogTitle>CFM Warning</DialogTitle>
        <DialogContent>
          <Typography>Your CFM seems abnormally high</Typography>
          Your fan may have a loose connection
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setCFMHighOpen(false);
            }}>
            Close
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    );
  };

  const cfmLowDialog = () => {
    return (
      <ResponsiveDialog
        open={cfmLowOpen}
        onClose={() => {
          setCFMLowOpen(false);
        }}>
        <DialogTitle>CFM Warning</DialogTitle>
        <DialogContent>
          <Typography>Your CFM is low</Typography>
          Your bin may be too full for your fan, try reducing inventory.
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setCFMLowOpen(false);
            }}>
            Close
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    );
  };

  const pressureOverview = () => {
    let classString = classes.spacer;
    if (isMobile) {
      classString = classString + " " + classes.smallMeasurement;
    }
    return (
      <Box padding={1} display="flex">
        <Avatar
          variant="square"
          src={GetComponentIcon(
            quack.ComponentType.COMPONENT_TYPE_PRESSURE,
            undefined,
            theme.palette.type
          )}
          className={classes.avatar}
        />
        <Box marginLeft={1}>
          {pressure ? (
            <React.Fragment>
              <Typography
                className={classString}
                variant="body2"
                display="inline"
                style={{ color: pressColour }}>
                {pressure.getPressureString(user.settings.pressureUnit)}
              </Typography>
              {", "}
              <Typography
                className={classString}
                variant="body2"
                display="inline"
                style={{ color: pressColour }}>
                {bin.status.fanCfm.toFixed(0) + "CFM"}
              </Typography>
            </React.Fragment>
          ) : (
            <Typography variant="body2" display="inline" color="textSecondary">
              N/A
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  const overview = () => {
    return (
      <React.Fragment>
        {inventoryOverview()}
        {grainCableOverview()}
        {plenumOverview()}
      </React.Fragment>
    );
  };

  const getBinSVGHeight = () => {
    if (isMobile) {
      return fullScreenHandler.active ? viewport.height * 0.8 : viewport.width * 0.8;
    }
    return fullScreenHandler.active ? viewport.height * 0.8 : viewport.height * 0.4;
  };

  const getBinSpacerHeight = () => {
    if (isMobile) {
      return 420 - viewport.width;
    }
    return 0;
  };

  const visual = () => {
    let diffDisplay = grainDiff;
    let pendingDisplay = pendingGrainAmount;
    if (bin.storage() === pond.BinStorage.BIN_STORAGE_FERTILIZER) {
      diffDisplay = diffDisplay * 35.239;
      if (pendingDisplay) {
        pendingDisplay = pendingDisplay * 35.239;
      }
    }

    if (getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT && bin.bushelsPerTonne() > 1) {
      diffDisplay = diffDisplay / bin.bushelsPerTonne();
      if (pendingDisplay) {
        pendingDisplay = pendingDisplay / bin.bushelsPerTonne();
      }
    }

    return (
      <Box display="flex" width={1} justifyContent="flex-end">
        <FullScreen handle={fullScreenHandler}>
          <Box
            position="relative"
            height={1}
            width={1}
            bgcolor={theme.palette.background.paper}
            display="flex"
            justifyContent="center"
            alignItems="center"
            alignContent="flex-end">
            {fullScreenHandler.active && (
              <Box position="absolute" bottom={10} right={10} zIndex={zIndexPriority.high}>
                <IconButton onClick={fullScreenHandler.exit}>
                  <FullscreenExitIcon />
                </IconButton>
              </Box>
            )}
            <Box zIndex={zIndexPriority.high}>
              <Box>
                {grainDiff > 0 && (
                  <div
                    className={classes.grainOVerlay}
                    style={{
                      top: bin.binShape() === pond.BinShape.BIN_SHAPE_HOPPER_BOTTOM ? "30%" : "35%",
                      color: "#5CE422"
                    }}>
                    <Typography variant={"h5"} style={{ fontWeight: 750 }}>
                      +{diffDisplay.toLocaleString()}
                    </Typography>
                  </div>
                )}
                <div
                  className={classes.grainOVerlay}
                  style={{
                    top: bin.binShape() === pond.BinShape.BIN_SHAPE_HOPPER_BOTTOM ? "40%" : "45%"
                  }}>
                  <Typography variant={"h4"} style={{ fontWeight: 750 }}>
                    {pendingDisplay?.toLocaleString()}
                  </Typography>
                </div>
                {grainDiff < 0 && (
                  <div
                    className={classes.grainOVerlay}
                    style={{
                      top: bin.binShape() === pond.BinShape.BIN_SHAPE_HOPPER_BOTTOM ? "50%" : "55%",
                      color: "#E42222"
                    }}>
                    <Typography variant={"h5"} style={{ fontWeight: 750 }}>
                      {diffDisplay.toLocaleString()}
                    </Typography>
                  </div>
                )}
              </Box>
              <BinSVG
                height={getBinSVGHeight()}
                binShape={bin.settings.specs?.shape}
                fillPercentage={fillPercentage ?? 0}
                lidarEstimate={lidarPercentage}
                cables={showMulti ? grainCables : lastGrainCable ? [lastGrainCable] : []} //TODO code a better method of determining which cables to show
                showTempHum={iOS ? nodeDetails : fullScreenHandler.active}
                highTemp={bin.settings.highTemp}
                lowTemp={bin.settings.lowTemp}
                isFullScreen={fullScreenHandler.active}
                grainType={bin.settings.inventory?.grainType}
                cableEstimate={bin.settings.autoGrainNode}
                cableNodeClicked={(cable, fillNode) => {
                  if (cable) {
                    let devId = componentDevices?.get(cable.key());
                    if (devId) {
                      let device = devMap.get(devId);
                      if (device) {
                        setSelectedCable(cable);
                        setSelectedNode(fillNode);
                        setCableDevice(device);
                        setOpenNodeDialog(true);
                      }
                    }
                  }
                }}
              />
              <div
                style={{
                  width: "12px",
                  height: getBinSpacerHeight()
                }}></div>
            </Box>

            <Box
              zIndex={zIndexPriority.medium}
              position="absolute"
              height={1}
              width={"50%"}
              bottom={0}
              right={0}
              bgcolor={theme.palette.background.paper}
            />
          </Box>
        </FullScreen>
      </Box>
    );
  };

  const controls = () => {
    if (bin.settings.inventory?.empty === true) return null;
    return (
      <Box
        height={1}
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignContent="flex-end">
        <IconButton
          onClick={() => {
            setGrainUpdate(true);
          }}
          style={{
            backgroundColor: "gold",
            color: "black",
            height: 40,
            width: 40,
            marginBottom: 10
          }}>
          +/-
        </IconButton>
        <Box
          height={0.7}
          width={1}
          display="flex"
          justifyContent="center"
          alignContent="flex-end"
          zIndex={zIndexPriority.high}>
          {fillPercentage !== null && (
            <Slider
              orientation="vertical"
              value={fillPercentage}
              style={{ color: sliderColour }}
              min={0}
              max={100}
              valueLabelDisplay="auto"
              valueLabelFormat={value => value.toString() + "%"}
              onChange={(_, value) => {
                setFillPercentage(value as number);
                const capacity = bin.settings.specs ? bin.settings.specs.bushelCapacity : 0;
                const current = bin.settings.inventory ? bin.settings.inventory.grainBushels : 0;
                let grainAmount = ((value as number) / 100) * capacity;

                if (grainAmount < current) {
                  setSliderCoulour("#E42222");
                } else if (grainAmount > current) {
                  setSliderCoulour("#5CE422");
                } else {
                  setSliderCoulour("gold");
                }
                setGrainDiff(round(grainAmount - current, 0));
                setPendingGrainAmount(round(grainAmount, 0));
              }}
              onChangeCommitted={() => {
                setGrainUpdate(true);
              }}
              aria-labelledby="grain-amount"
            />
          )}
        </Box>
        <Box height={0.2} width={1} position="relative">
          <Box width={1} position="absolute" bottom="0" textAlign="center">
            {iOS ? (
              <IconButton size="small" onClick={() => setNodeDetails(!nodeDetails)}>
                <InfoOutlined />
              </IconButton>
            ) : (
              <IconButton size="small" onClick={fullScreenHandler.enter}>
                <FullscreenIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const binMode = () => {
    const mode = bin.settings.mode;
    return (
      <Box
        display="flex"
        paddingTop={2}
        flexGrow={1}
        paddingX={1}
        height={"100%"}
        width={1}
        justifyContent="space-between"
        alignItems="center"
        alignSelf="flex-end">
        <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
          Bin Mode
        </Typography>
        <StyledToggleButtonGroup
          id="tour-bin-mode"
          value={mode}
          exclusive
          size="small"
          aria-label="Bin Mode">
          <StyledToggle
            value={pond.BinMode.BIN_MODE_STORAGE}
            aria-label="Storage Mode"
            onClick={setModeStorage}>
            Storage
          </StyledToggle>
          <StyledToggle
            onClick={setModeCooldown}
            value={pond.BinMode.BIN_MODE_COOLDOWN}
            aria-label="Off">
            Cooldown
          </StyledToggle>
          {bin.settings.inventory &&
          bin.settings.inventory?.initialMoisture < bin.settings.inventory?.targetMoisture ? (
            <StyledToggle
              onClick={() => setShowInputMoisture(true)}
              value={pond.BinMode.BIN_MODE_HYDRATING}
              aria-label="Hydrating Mode">
              Hydrating
            </StyledToggle>
          ) : (
            <StyledToggle
              onClick={() => setShowInputMoisture(true)}
              value={pond.BinMode.BIN_MODE_DRYING}
              aria-label="Drying Mode">
              Drying
            </StyledToggle>
          )}
        </StyledToggleButtonGroup>
      </Box>
    );
  };

  const showDays = (time: Moment) => {
    let now = moment();
    let duration = moment.duration(time.diff(now));
    let days = duration.asDays();
    days = Math.abs(days);
    duration.subtract(moment.duration(days, "days"));
    let hours = duration.hours();
    hours = Math.abs(hours);
    //if (hours !== 0) hours = 24 - hours;
    //hours = 24 - hours;

    if (bin.settings.mode === pond.BinMode.BIN_MODE_NONE) {
      return (
        <Typography variant="subtitle2" className={classes.days}>
          Select Mode
        </Typography>
      );
    }

    if (days > 50 && bin.settings.mode === pond.BinMode.BIN_MODE_DRYING) {
      return (
        <Typography variant="subtitle2" className={classes.days}>
          Calculating...
        </Typography>
      );
    }

    return (
      <Typography variant="subtitle2" className={classes.days} style={{ fontWeight: 700 }}>
        {Math.floor(days)}d {hours}h
      </Typography>
    );
  };

  const dryingEstimate = () => {
    if (
      bin.settings.mode === pond.BinMode.BIN_MODE_DRYING ||
      bin.settings.mode === pond.BinMode.BIN_MODE_HYDRATING
    ) {
      let time = moment(bin.status.targetMoistureEstimation);
      return (
        <Box className={classes.extraDetails}>
          <Box style={{ position: "relative", transform: "translate(50%, 25%)" }}>
            <IconButton style={{ position: "absolute" }} size="small" onClick={() => refresh(true)}>
              <RefreshIcon />
            </IconButton>
          </Box>
          <Box padding={0} style={{ flex: 1, textAlign: "center" }}>
            <Typography variant="body2" style={{ margin: theme.spacing(0.5) }}>
              Time Estimate:
            </Typography>

            {showDays(time)}
          </Box>
          <Divider flexItem={true} orientation="vertical" style={{ background: "gold" }} />
          <Box padding={0} style={{ flex: 1, textAlign: "center", flexDirection: "column" }}>
            <Typography variant="body2" style={{ margin: theme.spacing(0.5) }}>
              CFM per Bushel
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ margin: theme.spacing(0.5), fontSize: "16px", fontWeight: 700 }}>
              {bin.status.cfmPerBushel.toFixed(2)} CFM/bu {cfmDryWarning(bin.status.cfmPerBushel)}
            </Typography>
          </Box>
        </Box>
      );
    }
    if (bin.settings.mode === pond.BinMode.BIN_MODE_COOLDOWN) {
      return (
        <Box className={classes.extraDetails}>
          <Box style={{ position: "relative", transform: "translate(50%, 25%)" }}>
            <IconButton style={{ position: "absolute" }} size="small" onClick={() => refresh(true)}>
              <RefreshIcon />
            </IconButton>
          </Box>
          <Box padding={0} style={{ flex: 1, textAlign: "center" }}>
            <Typography variant="body2" style={{ margin: theme.spacing(0.5) }}>
              Cooling:
            </Typography>
            {showDays(modeTime)}
          </Box>
          <Divider flexItem={true} orientation="vertical" style={{ background: "gold" }} />
          <Box padding={0} style={{ flex: 1, textAlign: "center", flexDirection: "column" }}>
            <Typography variant="body2" style={{ margin: theme.spacing(0.5) }}>
              CFM per Bushel
            </Typography>
            <Typography variant="subtitle2" className={classes.days}>
              {bin.status.cfmPerBushel.toFixed(2)} CFM/bu
            </Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Box className={classes.extraDetails}>
        <Box style={{ position: "relative", transform: "translate(50%, 25%)" }}>
          <IconButton style={{ position: "absolute" }} size="small" onClick={() => refresh(true)}>
            <RefreshIcon />
          </IconButton>
        </Box>
        <Box padding={0} style={{ flex: 1, textAlign: "center" }}>
          <Typography variant="body2" style={{ margin: theme.spacing(0.5) }}>
            {bin.settings.mode === pond.BinMode.BIN_MODE_STORAGE ? "In storage for:" : "^"}
          </Typography>
          {showDays(modeTime)}
        </Box>
        <Divider flexItem={true} orientation="vertical" style={{ background: "gold" }} />
        <Box padding={0} style={{ flex: 1, textAlign: "center", flexDirection: "column" }}>
          <Typography variant="body2" style={{ margin: theme.spacing(0.5) }}>
            CFM per Bushel
          </Typography>
          <Typography variant="subtitle2" className={classes.days}>
            {bin.status.cfmPerBushel.toFixed(2)} CFM/bu
          </Typography>
        </Box>
      </Box>
    );
  };

  const moistureLevels = () => {
    return (
      <Box className={classes.extraDetails}>
        <Box padding={0} style={{ flex: 1, textAlign: "center", flexDirection: "column" }}>
          <Typography variant="body2" style={{ margin: theme.spacing(0.5) }}>
            Initial Moisture
          </Typography>
          <Typography variant="subtitle2" className={classes.days} style={{ fontWeight: 700 }}>
            {bin.settings.inventory?.initialMoisture}%
          </Typography>
        </Box>
        <Divider flexItem={true} orientation="vertical" style={{ background: "gold" }} />
        <Box padding={0} style={{ flex: 1, textAlign: "center", flexDirection: "column" }}>
          <Typography variant="body2" style={{ margin: theme.spacing(0.5) }}>
            Moisture
          </Typography>
          <Typography variant="subtitle2" className={classes.days} style={{ fontWeight: 700 }}>
            {bin.status.grainMoisture.toFixed(2)}%
          </Typography>
        </Box>
        <Divider flexItem={true} orientation="vertical" style={{ background: "gold" }} />
        <Box padding={0} style={{ flex: 1, textAlign: "center", flexDirection: "column" }}>
          <Typography variant="body2" style={{ margin: theme.spacing(0.5) }}>
            Target Moisture
          </Typography>
          <Typography variant="subtitle2" className={classes.days} style={{ fontWeight: 700 }}>
            {bin.settings.inventory?.targetMoisture}%
          </Typography>
        </Box>
      </Box>
    );
  };

  const setModeStorage = () => {
    // if (bin.settings.mode === pond.BinMode.BIN_MODE_STORAGE) {
    //   return;
    // }
    setNewPreset(pond.BinMode.BIN_MODE_STORAGE);
    /*interactions.forEach(int => {
      interactionAPI.removeInteraction(device.settings.deviceId, int.settings.key).catch(err => {
        error(err);
      });
    });*/
    //changeBinMode(pond.BinMode.BIN_MODE_STORAGE);
  };

  const setModeCooldown = () => {
    if (bin.settings.mode === pond.BinMode.BIN_MODE_COOLDOWN) {
      return;
    }
    setNewPreset(pond.BinMode.BIN_MODE_COOLDOWN);
    /*let componentValues = [...components.values()];
    let heaterComponents = FindHeaters(componentValues);
    if (heaterComponents.length < 1) {
      error("No heater attached");
      return;
    }
    if (changeBinMode(pond.BinMode.BIN_MODE_COOLDOWN)) {
      setPreset("cooldown");
    }*/
  };

  const setModeDrying = () => {
    if (
      bin.settings.inventory &&
      bin.settings.inventory?.initialMoisture < bin.settings.inventory?.targetMoisture
    ) {
      setNewPreset(pond.BinMode.BIN_MODE_HYDRATING);
    } else {
      setNewPreset(pond.BinMode.BIN_MODE_DRYING);
    }
    /*let componentValues = [...components.values()];
    let heaterComponents = FindHeaters(componentValues);
    setShowInputMoisture(false);
    if (heaterComponents.length < 1) {
      error("No heater attached");
      return;
    }
    updateBin();
    if (changeBinMode(pond.BinMode.BIN_MODE_DRYING)) {
      setPreset("drying");
    }
    closeMoistureDialog();*/
  };

  const closeMoistureDialog = () => {
    setNewPreset(0);
    setShowInputMoisture(false);
  };

  const moistureDialog = () => {
    return (
      <ResponsiveDialog open={showInputMoisture} onClose={closeMoistureDialog}>
        <DialogTitle>Input new grain moisture</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingBottom: theme.spacing(0) }}>
            Updating the current grain moisture will calibrate the estimate for more accurate
            results. The outdoor temperature will have some effect on the estimate as well; consider
            updating with drastic changes in the weather or using a predicted average.
          </DialogContentText>
          <TextField
            label={"Grain Moisture"}
            value={moistureInput}
            onChange={event => setMoistureInput(event.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            style={{ marginTop: theme.spacing(2) }}
            fullWidth
            variant="outlined"
          />
          <Typography variant="body1" style={{ marginTop: theme.spacing(2) }}>
            Weather
          </Typography>
          <TextField
            label={"Outdoor Temperature"}
            value={tempInput}
            onChange={event => setTempInput(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getTemperatureUnit() === pond.TemperatureUnit.TEMPERATURE_UNIT_FAHRENHEIT
                    ? "°F"
                    : "℃"}
                </InputAdornment>
              )
            }}
            style={{ marginTop: theme.spacing(2) }}
            fullWidth
            variant="outlined"
          />
          <TextField
            label={"Outdoor Humidity"}
            value={outdoorHumidityInput}
            onChange={event => setOutdoorHumidityInput(event.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            style={{ marginTop: theme.spacing(2) }}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNewPreset(0);
              setShowInputMoisture(false);
            }}
            color="primary">
            Cancel
          </Button>
          <Button onClick={setModeDrying} color="primary">
            Update
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    );
  };

  if (loading) {
    return <Skeleton variant="rect" height={460} />;
  }

  const callback = (success: boolean) => {
    setShowInputMoisture(false);
    if (!success) return;

    let b = bin;
    if (b.settings.inventory?.initialMoisture)
      b.settings.inventory.initialMoisture = Number(moistureInput);
    if (b.settings.outdoorTemp !== undefined) {
      let t = Number(tempInput);
      if (user.settings.temperatureUnit === pond.TemperatureUnit.TEMPERATURE_UNIT_FAHRENHEIT) {
        t = FtoC(Number(tempInput));
      }
      b.settings.outdoorTemp = t;
    }
    if (b.settings.outdoorHumidity !== undefined)
      b.settings.outdoorHumidity = Number(outdoorHumidityInput);
    b.settings.mode = newPreset;
    binAPI.updateBin(bin.key(), b.settings).then(resp => {
      refresh();
    });
  };

  return (
    <Card raised className={classes.cardContent}>
      {moistureDialog()}
      {cfmLowDialog()}
      {cfmHighDialog()}
      {/*<DevicePresets
        device={device}
        components={components}
        interactions={interactions}
        canWrite={bin.permissions.includes(pond.Permission.PERMISSION_WRITE)}
        refreshCallback={loadBinComponents}
        hide={true}
        parentPreset={preset}
        grain={bin.settings.inventory?.grainType}
      />*/}
      <DevicePresetsFromPicker
        preferences={preferences}
        binKey={bin.key()}
        devices={devices}
        refreshCallback={callback}
        parentPreset={newPreset}
        grain={bin.settings.inventory?.grainType}
        binCables={cables}
        compDevMap={componentDevices}
        presets={binPresets}
      />
      <Box paddingRight={1}>
        {/* <Typography noWrap variant="subtitle1" color="textPrimary" style={{ fontWeight: 800 }}>
          {bin.settings.name}
        </Typography> */}
        <Grid container justify="flex-start" alignItems="stretch">
          <Grid item xs sm md style={{ position: "relative" }}>
            {overview()}
          </Grid>
          <Grid item style={{ position: "relative" }}>
            {visual()}
          </Grid>
          <Grid item>{controls()}</Grid>
        </Grid>

        {binMode()}
        {dryingEstimate()}
        {bin.settings.storage !== pond.BinStorage.BIN_STORAGE_FERTILIZER && moistureLevels()}
      </Box>
      <GrainTransaction
        open={grainUpdate}
        mainObject={bin}
        grainAdjustment={grainDiff}
        close={() => {
          setGrainUpdate(false);
          setPendingGrainAmount(undefined);
          resetFillPercentage();
          setGrainDiff(0);
        }}
        callback={props.afterUpdate}
      />
      {selectedCable && cableDevice && (
        <GrainNodeInteractions
          binKey={bin.key()}
          interactionComponents={Array.from(components.values())}
          grain={bin.grain()}
          cable={selectedCable}
          open={openNodeDialog}
          device={cableDevice}
          selectedNode={selectedNode}
          close={() => {
            setOpenNodeDialog(false);
          }}
          permissions={permissions ?? []}
          updateComponentCallback={updateComponentCallback}
        />
      )}
      <div style={{ bottom: "0px" }}></div>
    </Card>
  );
}

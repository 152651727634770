import { Button, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import ResponsiveDialog from "common/ResponsiveDialog";
import React, { useEffect, useState } from "react";
import { useTerminalAPI } from "providers";
import { useSnackbar } from "hooks";
import { pond } from "protobuf-ts/pond";
import { Terminal } from "models/Terminal";

interface Props {
  open: boolean;
  closeDialog: (newTerminal?: Terminal, updateTerminal?: Terminal) => void;
  long?: number;
  lat?: number;
  terminal?: Terminal;
}

export default function TerminalSettings(props: Props) {
  const { open, closeDialog, terminal, long, lat } = props;
  const terminalAPI = useTerminalAPI();
  const [name, setName] = useState("");
  const { openSnack } = useSnackbar();

  useEffect(() => {
    if (terminal) {
      setName(terminal.name);
    } else {
      setName("");
    }
  }, [terminal]);

  const confirmChanges = () => {
    if (terminal) {
      //change settings here, currently no settings to change as the long/lat are changed from the map

      terminal.name = name;
      //note: changing the terminal object passed in WILL in fact change it in the parent
      terminalAPI
        .updateTerminal(terminal.key, terminal.name, terminal.settings)
        .then(resp => {
          openSnack("Terminal updated");
          closeDialog();
        })
        .catch(err => {
          openSnack("There was a problem updating the terminal");
        });
    } else {
      let settings: pond.TerminalSettings = pond.TerminalSettings.create({
        longitude: long,
        latitude: lat
      });
      terminalAPI
        .addTerminal(name, settings)
        .then(resp => {
          openSnack("New terminal added");
          let newTerminal = Terminal.create(
            pond.Terminal.create({ key: resp.data.key, name: name, settings: settings })
          );
          closeDialog(newTerminal);
        })
        .catch(err => {
          openSnack("There was a problem adding the terminal");
        });
    }
  };

  return (
    <ResponsiveDialog open={open} onClose={() => closeDialog()} fullWidth>
      <DialogTitle>Terminal Settings</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          id="name"
          type="text"
          label="Terminal Name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeDialog();
          }}>
          Cancel
        </Button>
        <Button onClick={confirmChanges} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

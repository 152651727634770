import { useHTTP } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";

export interface IMetricAPIContext {
  getMetrics: () => Promise<any>;
}

export const MetricAPIContext = createContext<IMetricAPIContext>({} as IMetricAPIContext);

interface Props {}

export default function MetricProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get } = useHTTP();

  const getMetrics = () => {
    return get(pondURL("/metrics"));
  };

  return (
    <MetricAPIContext.Provider
      value={{
        getMetrics
      }}>
      {children}
    </MetricAPIContext.Provider>
  );
}

export const useMetricAPI = () => useContext(MetricAPIContext);

import { createStyles, makeStyles, Theme, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import FansIcon from "assets/editor/fans.png";
import FansYellowIcon from "assets/editor/fansYellow.png";
import DevicesIcon from "assets/editor/devices.png";
import DuctingIcon from "assets/editor/ducting.png";
import SensorsIcon from "assets/editor/sensors.png";
import SensorsYellowIcon from "assets/editor/sensorsYellow.png";
import DuctingYellowIcon from "assets/editor/ductingYellow.png";
import EditorDrawer from "./EditorDrawer";
import FansDrawer from "./drawers/FansDrawer";
import DuctingDrawer from "./drawers/DuctingDrawer";
import DeviceDrawer from "./drawers/DeviceDrawer";
import { pond } from "protobuf-ts/pond";
import SensorDrawer from "./drawers/SensorDrawer";
import { Component } from "models";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    sidebar: {
      maxHeight: "auto",
      width: theme.spacing(10),
      background: "rgba(25, 25, 25, 1)",
      margin: 0,
      padding: 0
    },
    sideButtonInactive: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      padding: theme.spacing(2),
      opacity: 0.4,
      "&:hover": {
        cursor: "default"
      }
    },
    sideButton: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      padding: theme.spacing(2),
      "&:hover": {
        background: "rgba(200,200,200,0.15)",
        cursor: "pointer"
      }
    },
    sideButtonSelected: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      padding: theme.spacing(2),
      zIndex: 40,
      overflow: "visible",
      background: "rgba(35, 35, 35, 1)",
      //backgroundImage:
      //  "radial-gradient(circle at 38px 80px, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 40px, blue 40px)",
      color: "yellow",
      "&:hover": {
        background: "rgba(200,200,200,0.15)"
      },
      "&:before": {
        width: "80px",
        height: "80px",
        borderRadius: "0 40px 40px 0",
        backgroundColor: "#FFF",
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: "10px",
        content: ""
      }
    },
    sideButtonImage: {
      width: "100%",
      height: "100%",
      margin: 0,
      padding: 0,
      marginTop: theme.spacing(-1),
      color: "yellow"
    },
    sideButtonText: {
      textAlign: "center",
      margin: 0,
      padding: 0,
      marginTop: theme.spacing(-1)
    }
  });
});

enum Selection {
  NONE,
  FANS,
  DUCTING,
  DEVICES,
  SENSORS
}

interface Props {
  addBlockRef: any;
  setDevices: any;
  devices: pond.Device[];
  components: Component[];
  setComponents: React.Dispatch<React.SetStateAction<Component[]>>;
  mine: pond.MineSettings;
  setMine: React.Dispatch<React.SetStateAction<pond.MineSettings>>;
  mineComponentPreferences: Map<string, pond.MineComponentPreferences>;
  setMineComponentPreferences: React.Dispatch<
    React.SetStateAction<Map<string, pond.MineComponentPreferences>>
  >;
}

export default function EditorSidebar(props: Props) {
  const {
    addBlockRef,
    setDevices,
    devices,
    setComponents,
    mine,
    setMine,
    components,
    mineComponentPreferences,
    setMineComponentPreferences
  } = props;
  const classes = useStyles();
  const [selected, setSelected] = useState<Selection>(Selection.NONE);
  const [expanded, setExpanded] = useState<boolean>(false);

  const select = (s: Selection) => {
    if (s === selected) {
      setSelected(Selection.NONE);
      setExpanded(false);
    } else {
      setSelected(s);
      setExpanded(true);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.sidebar}>
        <div
          className={
            mine.key.length < 1
              ? classes.sideButtonInactive
              : selected === Selection.FANS
              ? classes.sideButtonSelected
              : classes.sideButton
          }
          onClick={() => select(Selection.FANS)}>
          <img
            className={classes.sideButtonImage}
            alt={"Fan"}
            src={selected === Selection.FANS ? FansYellowIcon : FansIcon}
          />
          <div className={classes.sideButtonText}>Fans</div>
        </div>
        <div
          className={
            mine.key.length < 1
              ? classes.sideButtonInactive
              : selected === Selection.DUCTING
              ? classes.sideButtonSelected
              : classes.sideButton
          }
          onClick={() => select(Selection.DUCTING)}>
          <img
            className={classes.sideButtonImage}
            alt={"Ducting"}
            src={selected === Selection.DUCTING ? DuctingYellowIcon : DuctingIcon}
          />
          <div className={classes.sideButtonText}>Ducting</div>
        </div>
        <Tooltip
          title={mine.key.length < 1 ? "Save current configuration or load a mine first" : ""}>
          <div
            className={
              mine.key.length < 1
                ? classes.sideButtonInactive
                : selected === Selection.DEVICES
                ? classes.sideButtonSelected
                : classes.sideButton
            }
            onClick={() => {
              if (mine.key.length < 1) return;
              select(Selection.DEVICES);
            }}>
            <img
              className={classes.sideButtonImage}
              alt={"Devices"}
              src={selected === Selection.DEVICES ? DevicesIcon : DevicesIcon}
            />
            <div className={classes.sideButtonText}>Devices</div>
          </div>
        </Tooltip>
        <Tooltip
          title={mine.key.length < 1 ? "Save current configuration or load a mine first" : ""}>
          <div
            className={
              mine.key.length < 1
                ? classes.sideButtonInactive
                : selected === Selection.SENSORS
                ? classes.sideButtonSelected
                : classes.sideButton
            }
            onClick={() => {
              if (mine.key.length < 1) return;
              select(Selection.SENSORS);
            }}>
            <img
              className={classes.sideButtonImage}
              alt={"Sensors"}
              src={selected === Selection.SENSORS ? SensorsYellowIcon : SensorsIcon}
            />
            <div className={classes.sideButtonText}>Sensors</div>
          </div>
        </Tooltip>
      </div>

      <EditorDrawer isOpen={expanded}>
        {selected === Selection.FANS ? (
          <FansDrawer addBlockRef={addBlockRef} />
        ) : selected === Selection.DUCTING ? (
          <DuctingDrawer addBlockRef={addBlockRef} />
        ) : selected === Selection.DEVICES ? (
          <DeviceDrawer
            addBlockRef={addBlockRef}
            selectedDevices={devices}
            setSelectedDevices={setDevices}
            mine={mine}
            setMine={setMine}
          />
        ) : selected === Selection.SENSORS ? (
          <SensorDrawer
            addBlockRef={addBlockRef}
            selectedDevices={devices}
            setSelectedDevices={setDevices}
            setMineComponents={setComponents}
            mineComponents={components}
            mineKey={mine.key}
            mineComponentPreferences={mineComponentPreferences}
            setMineComponentPreferences={setMineComponentPreferences}
          />
        ) : (
          "Coming soon :)"
        )}
      </EditorDrawer>
    </React.Fragment>
  );
}

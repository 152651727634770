import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import moment, { Moment } from "moment";
import { DateRange as DateIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import ResponsiveDialog from "common/ResponsiveDialog";
import { DateRange, DateRangeDelimiter, StaticDateRangePicker } from "@material-ui/pickers";
import { DateRangePreset, GetDefaultDateRange, SetDefaultPreset } from "./DateRange";
import { useThemeType } from "hooks";

interface Props {
  startDate: Moment;
  endDate: Moment;
  updateDateRange: (start: Moment, end: Moment, live: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeButtonLight: {
      border: "1px solid black"
    },
    acitveButtonDark: {
      border: "1px solid white"
    }
  })
);

export default function TimeBar(props: Props) {
  const { updateDateRange, startDate, endDate } = props;
  const defaultDateRange = GetDefaultDateRange();
  const [dateRangeDialog, setDateRangeDialog] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange<Moment>>([
    defaultDateRange.start,
    defaultDateRange.end
  ]);

  const [activeView, setActiveView] = useState<DateRangePreset>();
  const classes = useStyles();
  const themeType = useThemeType();

  const submitDateRange = () => {
    const startDate = dateRange[0] ? dateRange[0].clone() : moment();
    const endDate = dateRange[1] ? dateRange[1].clone() : moment();
    updateDateRange(startDate, endDate, false);
    setDateRangeDialog(false);
    setActiveView("selectRange");
  };

  useEffect(() => {
    let hourDiff = moment(endDate)
      .diff(moment(startDate), "hours", true)
      .toFixed(4);
    let dayDiff = moment(endDate)
      .diff(moment(startDate), "days", true)
      .toFixed(4);
    let monthDiff = moment(endDate)
      .diff(moment(startDate), "months", true)
      .toFixed(4);
    if (hourDiff === "1.0000") {
      setActiveView("pastHour");
    } else if (dayDiff === "1.0000") {
      setActiveView("pastDay");
    } else if (dayDiff === "7.0000") {
      setActiveView("pastWeek");
    } else if (monthDiff === "1.0000") {
      setActiveView("pastMonth");
    } else {
      setActiveView("selectRange");
    }
  }, [startDate, endDate]);

  const datePickerDialog = () => {
    return (
      <ResponsiveDialog
        open={dateRangeDialog}
        onClose={() => setDateRangeDialog(false)}
        aria-labelledby="date-range-dialog">
        <DialogContent>
          <StaticDateRangePicker
            disableFuture
            value={dateRange}
            onChange={date => setDateRange(date)}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} />
                <DateRangeDelimiter> to </DateRangeDelimiter>
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDateRangeDialog(false)} color="primary">
            Close
          </Button>
          <Button onClick={submitDateRange} color="primary">
            Submit
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    );
  };

  return (
    <React.Fragment>
      {datePickerDialog()}
      <Grid container direction="row" spacing={1} wrap="nowrap">
        <Grid item>
          <Button
            style={{ borderRadius: "25px" }}
            className={
              activeView === "pastHour"
                ? themeType === "light"
                  ? classes.activeButtonLight
                  : classes.acitveButtonDark
                : ""
            }
            onClick={() => {
              updateDateRange(moment().subtract(1, "hour"), moment(), false);
              SetDefaultPreset("pastHour");
            }}>
            <Typography style={{ fontWeight: 650 }}>1H</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ borderRadius: "25px" }}
            className={
              activeView === "pastDay"
                ? themeType === "light"
                  ? classes.activeButtonLight
                  : classes.acitveButtonDark
                : ""
            }
            onClick={() => {
              updateDateRange(moment().subtract(1, "day"), moment(), false);
              SetDefaultPreset("pastDay");
            }}>
            <Typography style={{ fontWeight: 650 }}>1D</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ borderRadius: "25px" }}
            className={
              activeView === "pastWeek"
                ? themeType === "light"
                  ? classes.activeButtonLight
                  : classes.acitveButtonDark
                : ""
            }
            onClick={() => {
              updateDateRange(moment().subtract(1, "week"), moment(), false);
              SetDefaultPreset("pastWeek");
            }}>
            <Typography style={{ fontWeight: 650 }}>1W</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ borderRadius: "25px" }}
            className={
              activeView === "pastMonth"
                ? themeType === "light"
                  ? classes.activeButtonLight
                  : classes.acitveButtonDark
                : ""
            }
            onClick={() => {
              updateDateRange(moment().subtract(1, "month"), moment(), false);
              SetDefaultPreset("pastMonth");
            }}>
            <Typography style={{ fontWeight: 650 }}>1M</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ borderRadius: "25px" }}
            className={
              activeView === "selectRange"
                ? themeType === "light"
                  ? classes.activeButtonLight
                  : classes.acitveButtonDark
                : ""
            }
            onClick={() => {
              setDateRangeDialog(true);
            }}>
            <DateIcon />
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

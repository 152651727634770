import { cloneDeep } from "lodash";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

export class FieldMarker {
  public settings: pond.FieldMarkerSettings = pond.FieldMarkerSettings.create();
  public status: pond.FieldMarkerStatus = pond.FieldMarkerStatus.create();

  public static create(hm?: pond.FieldMarker): FieldMarker {
    let my = new FieldMarker();
    if (hm) {
      my.settings = pond.FieldMarkerSettings.fromObject(cloneDeep(or(hm.settings, {})));
      my.status = pond.FieldMarkerStatus.fromObject(cloneDeep(or(hm.status, {})));
    }
    return my;
  }

  public static clone(other?: FieldMarker): FieldMarker {
    if (other) {
      return FieldMarker.create(
        pond.FieldMarker.fromObject({
          settings: cloneDeep(other.settings),
          status: cloneDeep(other.status)
        })
      );
    }
    return FieldMarker.create();
  }

  public static any(data: any): FieldMarker {
    return FieldMarker.create(pond.FieldMarker.fromObject(cloneDeep(data)));
  }

  public key(): string {
    return this.settings.key;
  }

  public long(): number {
    return this.settings.longitude;
  }

  public lat(): number {
    return this.settings.latitude;
  }

  public description(): string {
    return this.settings.description;
  }

  public name(): string {
    return this.settings.name;
  }

  public severity(): number {
    return this.settings.severity;
  }

  public severityColour(): string {
    switch (this.settings.severity) {
      case 1:
        return "#58d68d";
      case 2:
        return "#FFC300";
      case 3:
        return "#FF5733";
      case 4:
        return "#FF0000";
      default:
        return "#229954";
    }
  }

  public severityString(): string {
    switch (this.settings.severity) {
      case 1:
        return "Mild";
      case 2:
        return "Moderate";
      case 3:
        return "High";
      case 4:
        return "Severe";
      default:
        return "Low";
    }
  }

  public area(): number {
    return this.settings.affectedArea;
  }

  public type(): pond.FieldMarkerType {
    return this.settings.type;
  }

  public typeString(): string {
    switch (this.settings.type) {
      case pond.FieldMarkerType.FIELD_MARKER_TYPE_WEEDS:
        return "Weeds";
      case pond.FieldMarkerType.FIELD_MARKER_TYPE_PESTS:
        return "Pests";
      case pond.FieldMarkerType.FIELD_MARKER_TYPE_DISEASE:
        return "Disease";
      case pond.FieldMarkerType.FIELD_MARKER_TYPE_OTHER:
        return "Other";
      case pond.FieldMarkerType.FIELD_MARKER_TYPE_ROCKS:
        return "Rocks";
      default:
        return "Invalid Type";
    }
  }

  public defaultColor(): string {
    switch (this.settings.type) {
      case pond.FieldMarkerType.FIELD_MARKER_TYPE_WEEDS:
        return "green";
      case pond.FieldMarkerType.FIELD_MARKER_TYPE_PESTS:
        return "blue";
      case pond.FieldMarkerType.FIELD_MARKER_TYPE_DISEASE:
        return "yellow";
      case pond.FieldMarkerType.FIELD_MARKER_TYPE_ROCKS:
        return "grey";
      default:
        return "red";
    }
  }
}

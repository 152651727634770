export class Circle {
  public radius = 1;
  public x = 0;
  public y = 0;
  public selected = true;
  public img: HTMLImageElement | undefined = undefined;

  public static create(x: number, y: number, radius: number) {
    let c = new Circle();
    c.x = x;
    c.y = y;
    c.radius = radius;
    return c;
  }

  public draw(context: CanvasRenderingContext2D, offsetX?: number, offsetY?: number) {
    offsetX = offsetX ? offsetX : 0;
    offsetY = offsetY ? offsetY : 0;
    let oldStyle = context.fillStyle;
    context.beginPath();
    context.arc(this.x + offsetX, this.y + offsetY, this.radius, 0, 2 * Math.PI);
    if (this.selected) {
      context.fillStyle = "grey";
      context.fill();
    }
    context.stroke();
    context.fillStyle = oldStyle;
  }

  public clickCheck(x: number, y: number, scale = 1) {
    let dx = Math.abs(x - this.x);
    let dy = Math.abs(y - this.y);
    let distance = Math.sqrt(dx * dx + dy * dy);
    if (distance <= this.radius) {
      this.selected = true;
      return true;
    }
    this.selected = false;
    return false;
  }
}

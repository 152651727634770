import React, { useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

interface Props {
  max: number;
  current: number;
  colour: string;
  size: number;
}

interface Data {
  key: string;
  value: number;
}

export default function MiniPie(props: Props) {
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    let data: Data[] = [];
    data.push({ key: "current", value: props.current });
    setData(data);
  }, [props]);

  return (
    <ResponsiveContainer width={props.size}>
      <PieChart style={{ cursor: "pointer" }}>
        <Pie
          outerRadius={"100%"}
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          startAngle={-90}
          endAngle={(props.current / props.max) * -360 - 90}>
          {data.map((entry: Data, index: number) => (
            <Cell key={`cell-${index}`} fill={props.colour} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

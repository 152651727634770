import { Button, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import ResponsiveDialog from "common/ResponsiveDialog";
import { useMineAPI } from "hooks";
import { pond } from "protobuf-ts/pond";
import React, { useState } from "react";

interface Props {
  open: boolean;
  closeCallback: () => void;
  refreshCallback: () => void;
}

export default function AddMine(props: Props) {
  const { open, closeCallback, refreshCallback } = props;
  const [name, setName] = useState("");
  const mineAPI = useMineAPI();

  const addMine = () => {
    let newMine = pond.MineSettings.create();
    newMine.name = name;
    mineAPI.addMine(newMine).finally(() => {
      closeCallback();
      refreshCallback();
    });
  };

  return (
    <ResponsiveDialog open={open} onClose={closeCallback}>
      <DialogTitle>Create new Mine</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          label="Name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeCallback}>Close</Button>
        <Button onClick={addMine} color={"primary"} disabled={name.length < 1}>
          Submit
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

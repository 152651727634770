import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Slider,
  TextField
} from "@material-ui/core";
import ResponsiveDialog from "common/ResponsiveDialog";
import { FieldMarker } from "models";
import { pond } from "protobuf-ts/pond";
import { useFieldMarkerAPI, useSnackbar } from "providers";
import React, { useState, useEffect } from "react";

interface Props {
  open: boolean;
  close: (refresh?: boolean) => void;
  longitude?: number;
  latitude?: number;
  fieldMarker?: FieldMarker;
}

const fieldMarkerTypes = [
  {
    value: pond.FieldMarkerType.FIELD_MARKER_TYPE_OTHER,
    label: "Other"
  },
  {
    value: pond.FieldMarkerType.FIELD_MARKER_TYPE_WEEDS,
    label: "Weeds"
  },
  {
    value: pond.FieldMarkerType.FIELD_MARKER_TYPE_DISEASE,
    label: "Disease"
  },
  {
    value: pond.FieldMarkerType.FIELD_MARKER_TYPE_PESTS,
    label: "Pests"
  },
  {
    value: pond.FieldMarkerType.FIELD_MARKER_TYPE_ROCKS,
    label: "Rocks"
  }
];

export default function FieldMarkerSettings(props: Props) {
  const { open, close, fieldMarker, longitude, latitude } = props;
  const fieldMarkerAPI = useFieldMarkerAPI();
  const { openSnack } = useSnackbar();
  const [title, setTitle] = useState("Field Marker");
  const [type, setType] = useState<pond.FieldMarkerType>(
    pond.FieldMarkerType.FIELD_MARKER_TYPE_OTHER
  );
  const [severity, setSeverity] = useState(0);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (fieldMarker) {
      setTitle(fieldMarker.name());
      setType(fieldMarker.type());
      setSeverity(fieldMarker.severity());
      setDescription(fieldMarker.description());
    }
  }, [fieldMarker]);

  const saveNewFieldMarker = () => {
    let newFM: FieldMarker = FieldMarker.create();
    newFM.settings.name = title;
    newFM.settings.type = type;
    newFM.settings.description = description;
    newFM.settings.latitude = latitude ?? 0;
    newFM.settings.longitude = longitude ?? 0;
    newFM.settings.severity = severity;
    //newFM.settings.affectedArea = fmAffectedArea;
    fieldMarkerAPI
      .addFieldMarker(newFM.settings)
      .then(resp => {
        openSnack("New marker has been saved");
        close(true);
      })
      .catch(err => openSnack("There was a problem saving your marker"));
  };

  const updateFieldMarker = (fieldMarker: FieldMarker) => {
    let fm = fieldMarker;
    fm.settings.description = description;
    fm.settings.severity = severity;
    //fm.settings.affectedArea = fmAffectedArea;
    fieldMarkerAPI
      .updateFieldMarker(fm.key(), fm.settings)
      .then(resp => {
        openSnack("Updated Field Marker");
        close(true);
      })
      .catch(resp => {
        openSnack("Failed to update Field Marker");
      });
  };

  const removeFieldMarker = (fieldMarker: FieldMarker) => {
    fieldMarkerAPI
      .removeFieldMarker(fieldMarker.key())
      .then(resp => {
        openSnack("Field Marker Removed");
        close(true);
      })
      .catch(() => {
        openSnack("Failed to remove Field Marker");
      });
  };

  return (
    <ResponsiveDialog open={open} onClose={() => close()} fullScreen={false}>
      <DialogTitle>{fieldMarker ? "Update Marker" : "Place Marker"}</DialogTitle>
      <DialogContent>
        <Box width={300}>
          <TextField
            id={"fieldMarkerName"}
            fullWidth
            margin="normal"
            label="Name"
            value={title}
            disabled={fieldMarker !== undefined}
            onChange={e => setTitle(e.target.value)}
          />
          <TextField
            id={"FieldMarkerType"}
            fullWidth
            margin="normal"
            label="type"
            value={type}
            onChange={e => setType(+e.target.value)}
            select
            disabled={fieldMarker !== undefined}>
            {fieldMarkerTypes.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          Severity
          <Slider
            value={severity}
            min={0}
            max={4}
            step={1}
            onChange={(e, val) => setSeverity(val as number)}
          />
          {/* Not doing affected area until i figure out how to resize with zoom */}
          {/* Affected Area
            <Slider
              value={fmAffectedArea}
              min={0}
              max={1000}
              onChange={(e, val) => setFMAffectedArea(val as number)}
            /> */}
          <TextField
            id={"fieldMarkerDescription"}
            fullWidth
            margin="normal"
            label="Description"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            {fieldMarker !== undefined && (
              <Button
                variant="contained"
                style={{ color: "black", backgroundColor: "red", margin: 5 }}
                onClick={() => removeFieldMarker(fieldMarker)}>
                Delete
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              style={{ margin: 5 }}
              variant="contained"
              color="primary"
              onClick={() => close()}>
              Cancel
            </Button>
            {fieldMarker !== undefined ? (
              <Button
                style={{ margin: 5 }}
                variant="contained"
                color="primary"
                onClick={() => updateFieldMarker(fieldMarker)}>
                Update
              </Button>
            ) : (
              <Button
                style={{ margin: 5 }}
                variant="contained"
                color="primary"
                onClick={saveNewFieldMarker}>
                Plot Field Marker
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </ResponsiveDialog>
  );
}

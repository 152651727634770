import React from "react";
import {
  WiDaySunny,
  WiDayCloudy,
  WiDaySunnyOvercast,
  WiDayLightning,
  WiDayStormShowers,
  WiDayThunderstorm,
  WiDaySprinkle,
  WiDayRainMix,
  WiDayRain,
  WiDayShowers,
  WiDayRainWind,
  WiDaySleet,
  WiDaySnow,
  WiDayFog,
  WiDayHaze,
  WiDust,
  WiSmoke,
  WiVolcano,
  WiTornado
} from "weather-icons-react";

interface Props {
  openweatherIconId: number;
  size?: number;
}

export default function WeatherIcon(props: Props) {
  const size = props.size ? props.size : 25;
  const iconId = props.openweatherIconId;

  switch (iconId) {
    case 200:
    case 210:
    case 221:
    case 230:
      return <WiDayLightning size={size} />;
    case 201:
    case 211:
    case 231:
      return <WiDayStormShowers size={size} />;
    case 202:
    case 212:
    case 232:
      return <WiDayThunderstorm size={size} />;
    case 300:
    case 301:
    case 302:
    case 500:
      return <WiDaySprinkle size={size} />;
    case 310:
    case 311:
    case 312:
    case 501:
      return <WiDayRainMix size={size} />;
    case 502:
    case 503:
    case 522:
      return <WiDayRain size={size} />;
    case 314:
    case 321:
    case 520:
    case 521:
    case 531:
      return <WiDayShowers size={size} />;
    case 504:
      return <WiDayRainWind size={size} />;
    case 511:
    case 611:
    case 612:
    case 613:
    case 615:
    case 616:
    case 620:
    case 621:
    case 622:
      return <WiDaySleet size={size} />;
    case 600:
    case 601:
    case 602:
      return <WiDaySnow size={size} />;
    case 701:
    case 741:
      return <WiDayFog size={size} />;
    case 711:
      return <WiSmoke size={size} />;
    case 721:
      return <WiDayHaze size={size} />;
    case 731:
    case 751:
    case 761:
      return <WiDust size={size} />;
    case 762:
      return <WiVolcano size={size} />;
    case 771:
    case 781:
      return <WiTornado size={size} />;
    case 801:
    case 802:
      return <WiDaySunnyOvercast size={size} />;
    case 803:
    case 804:
      return <WiDayCloudy size={size} />;
    default:
      return <WiDaySunny size={size} />;
  }
}

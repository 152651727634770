import { useHTTP } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pond } from "protobuf-ts/pond";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";
import { useGlobalState } from "providers";

export interface IGateInterface {
  addGate: (
    name: string,
    settings: pond.GateSettings
  ) => Promise<AxiosResponse<pond.AddGateResponse>>;
  listGates: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    pcaStatus?: boolean,
    keys?: string[],
    types?: string[],
    numerical?: boolean,
    specificUser?: string
  ) => Promise<AxiosResponse<pond.ListGatesResponse>>;
  updateGate: (
    key: string,
    name: string,
    settings: pond.GateSettings
  ) => Promise<AxiosResponse<pond.UpdateGateResponse>>;
  getGate: (key: string) => Promise<AxiosResponse<pond.GetGateResponse>>;
  removeGate: (key: string) => Promise<AxiosResponse<pond.RemoveGateResponse>>;
  updateLink: (
    parentKey: string,
    parentType: string,
    objectID: string,
    objectType: string,
    permissions: string[]
  ) => Promise<any>;
  updatePrefs: (
    gateKey: string,
    childType: string,
    childKey: string,
    gatePref: pond.GateComponentType | pond.GateDeviceType,
    keys?: string[],
    types?: string[]
  ) => Promise<AxiosResponse<pond.UpdateGatePreferencesResponse>>;
  getGatePageData: (key: string) => Promise<AxiosResponse<pond.GetGatePageDataResponse>>;
  listGateAirflow: (
    gate: string,
    device: number | string,
    ambientComponent: string,
    pressureComponent: string,
    start: string,
    end: string,
    keys?: string[],
    types?: string[]
  ) => Promise<AxiosResponse<pond.ListGateAirflowResponse>>;
  listGateMeasurements: (
    key: string,
    start: string,
    end: string
  ) => Promise<AxiosResponse<pond.ListGateMeasurementsResponse>>;
}

export const GateAPIcontext = createContext<IGateInterface>({} as IGateInterface);

interface Props {}

export default function GateProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();
  const [{ as }] = useGlobalState();

  const addGate = (name: string, settings: pond.GateSettings) => {
    if (as) {
      return post<pond.AddGateResponse>(pondURL("/gates?name=" + name + "&as=" + as), settings);
    }
    return post<pond.AddGateResponse>(pondURL("/gates?name=" + name + "&as=" + as), settings);
  };

  const listGates = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    pcaStatus?: boolean,
    keys?: string[],
    types?: string[],
    numerical?: boolean,
    specificUser?: string
  ) => {
    let asText = "";
    if (as) asText = "&as=" + as;
    if (specificUser) asText = "&as=" + specificUser;
    return get<pond.ListGatesResponse>(
      pondURL(
        "/gates?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + (order ? order : "asc")) +
          ("&by=" + (orderBy ? orderBy : "key")) +
          (numerical ? "&numerical=true" : "") +
          (search ? "&search=" + search : "") +
          (pcaStatus ? "&getPCA=" + pcaStatus.toString() : "") +
          (keys ? "&keys=" + keys.toString() : "") +
          (types ? "&types=" + types.toString() : "") +
          asText
      )
    );
  };

  const updateGate = (key: string, name: string, settings: pond.GateSettings) => {
    if (as) {
      return put<pond.UpdateGateResponse>(
        pondURL("/gates/" + key + "?as=" + as + "&name=" + name),
        settings
      );
    }
    return put<pond.UpdateGateResponse>(pondURL("/gates/" + key + "?name=" + name), settings);
  };

  const removeGate = (key: string) => {
    if (as) {
      return del<pond.RemoveGateResponse>(pondURL("/gates/" + key + "?as=" + as));
    }
    return del<pond.RemoveGateResponse>(pondURL("/gates/" + key + "?as=" + as));
  };

  const getGate = (key: string) => {
    if (as) {
      return get<pond.GetGateResponse>(pondURL("/gates/" + key + "?as=" + as));
    }
    return get<pond.GetGateResponse>(pondURL("/gates/" + key));
  };

  const getGatePageData = (key: string) => {
    if (as) {
      return get<pond.GetGatePageDataResponse>(pondURL("/gatePage/" + key + "?as=" + as));
    }
    return get<pond.GetGatePageDataResponse>(pondURL("/gatePage/" + key));
  };

  const updateLink = (
    parentID: string,
    parentType: string,
    objectID: string,
    objectType: string,
    permissions: string[]
  ) => {
    if (as)
      return post(pondURL(`/gates/` + parentID + `/link?as=${as}`), {
        Key: objectID,
        Type: objectType,
        Parent: parentID,
        ParentType: parentType,
        Permissions: permissions
      });
    return post(pondURL(`/gates/` + parentID + `/link`), {
      Key: objectID,
      Type: objectType,
      Parent: parentID,
      ParentType: parentType,
      Permissions: permissions
    });
  };

  const updatePrefs = (
    gateKey: string,
    childType: string,
    childKey: string,
    gatePref: pond.GateComponentType | pond.GateDeviceType,
    keys?: string[],
    types?: string[]
  ) => {
    if (as) {
      return put<pond.UpdateGatePreferencesResponse>(
        pondURL(
          `/gatePrefs/` +
            gateKey +
            "?childType=" +
            childType +
            "&childKey=" +
            childKey +
            "&gatePreference=" +
            gatePref +
            `&as=` +
            as +
            (keys ? "&keys=" + keys.toString() : "") +
            (types ? "&types=" + types.toString() : "")
        )
      );
    }
    return put<pond.UpdateGatePreferencesResponse>(
      pondURL(
        `/gatePrefs/` +
          gateKey +
          "?childType=" +
          childType +
          "&childKey=" +
          childKey +
          "&gatePreference=" +
          gatePref +
          (keys ? "&keys=" + keys.toString() : "") +
          (types ? "&types=" + types.toString() : "")
      )
    );
  };

  const listGateAirflow = (
    gate: string,
    device: number | string,
    ambientComponent: string,
    pressureComponent: string,
    start: string,
    end: string,
    keys?: string[],
    types?: string[]
  ) => {
    return get<pond.ListGateAirflowResponse>(
      pondURL(
        "/gates/" +
          gate +
          "/airflow?device=" +
          device +
          "&ambient=" +
          ambientComponent +
          "&pressure=" +
          pressureComponent +
          "&start=" +
          start +
          "&end=" +
          end +
          (as ? "&as=" + as : "") +
          (keys ? "&keys=" + keys.toString() : "") +
          (types ? "&types=" + types.toString() : "")
      )
    );
  };

  const listGateMeasurements = (key: string, start: string, end: string) => {
    return get<pond.ListGateMeasurementsResponse>(
      pondURL("/gates/" + key + "/measurements?start=" + start + "&end=" + end + "&as=" + as)
    );
  };

  return (
    <GateAPIcontext.Provider
      value={{
        addGate,
        listGates,
        updateGate,
        removeGate,
        getGate,
        updateLink,
        getGatePageData,
        updatePrefs,
        listGateAirflow,
        listGateMeasurements
      }}>
      {children}
    </GateAPIcontext.Provider>
  );
}

export const useGateAPI = () => useContext(GateAPIcontext);

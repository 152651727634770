import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { cloneDeep } from "lodash";

export function latestFirmwareVersion(
  versions: Map<string, Firmware>,
  platform: pond.DevicePlatform,
  channel: pond.UpgradeChannel
): string {
  const firmware = versions.get(platform + ":" + channel);
  if (firmware) {
    return firmware.settings.version;
  }
  return "";
}

export class Firmware {
  public settings: pond.FirmwareSettings = pond.FirmwareSettings.create();
  public status: pond.FirmwareStatus = pond.FirmwareStatus.create();

  public static create(pb?: pond.Firmware): Firmware {
    let my = new Firmware();
    if (pb) {
      my.settings = pond.FirmwareSettings.fromObject(cloneDeep(or(pb.settings, {})));
      my.status = pond.FirmwareStatus.fromObject(cloneDeep(or(pb.status, {})));
    }
    return my;
  }

  public static clone(other?: Firmware): Firmware {
    let my = new Firmware();
    if (other) {
      my.settings = pond.FirmwareSettings.fromObject(cloneDeep(other.settings));
      my.status = pond.FirmwareStatus.fromObject(cloneDeep(other.status));
    }
    return my;
  }

  public static any(data: any): Firmware {
    return Firmware.create(pond.Firmware.fromObject(cloneDeep(data)));
  }
}

import { BottomNavigation, BottomNavigationAction, Box, useTheme } from "@material-ui/core";
import {
  Dashboard as DevicesIcon,
  Home,
  MoreHoriz,
  Security,
  //SupervisedUserCircle,
  ShowChart as DemoIcon
} from "@material-ui/icons";
import { useAuth, usePrevious } from "hooks";
import BindaptIcon from "products/Bindapt/BindaptIcon";
import BinsIcon from "products/Bindapt/BinsIcon";
import VentilationIcon from "products/ventilation/VentilationIcon";
import { useGlobalState } from "providers";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IsAdaptiveAgriculture, isBXT, IsMiVent, IsAdCon, IsOmniAir } from "services/whiteLabel";
import FieldsIcon from "products/AgIcons/FieldsIcon";
import NexusSTIcon from "products/Construction/NexusSTIcon";
import OmniAirDeviceIcon from "products/AviationIcons/OmniAirDeviceIcon";
import AirportMapIcon from "products/AviationIcons/AirportMapIcon";
import PlaneIcon from "products/AviationIcons/PlaneIcon";
import JobsiteIcon from "products/Construction/JobSiteIcon";

interface Props {
  sideIsOpen: boolean;
  openSide: () => void;
}

export default function BottomNavigator(props: Props) {
  const theme = useTheme();
  const { sideIsOpen, openSide } = props;
  const history = useHistory();
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const { isAuthenticated } = useAuth();
  const [{ user }] = useGlobalState();
  const [route, setRoute] = useState(sideIsOpen ? "side" : "");
  const isAdaptive = IsAdaptiveAgriculture();
  const isMiVent = IsMiVent();
  const isAdCon = IsAdCon();
  const isOmni = IsOmniAir();

  const reRoute = useCallback(
    (path: string) => {
      if (path === "") {
        if (isAdaptive) {
          return "bins";
        }
        if (isMiVent) {
          return "ventilation";
        }
        return "devices";
      }

      const deviceRoutes = ["groups", "devices", "demo"];
      if (deviceRoutes.find(r => path === r) !== undefined) {
        return "devices";
      }

      const moreRoutes = [
        "firmware",
        "users",
        "docs",
        "tutorial",
        "hardware",
        "data",
        "team",
        "sandbox"
      ];
      if (moreRoutes.find(r => path === r) !== undefined) {
        return "more";
      }
      return path;
    },
    [isAdaptive, isMiVent]
  );

  const autoDetectRoute = useCallback(() => {
    let path = location.pathname.split("/")[1];
    setRoute(reRoute(path));
  }, [location.pathname, reRoute]);

  useEffect(() => {
    if (!prevLocation || location !== prevLocation) {
      autoDetectRoute();
    }
  }, [location, prevLocation, autoDetectRoute]);

  const handleRouteChange = (newRoute: string) => {
    if (newRoute === "more") {
      openSide();
    } else {
      history.push(`/${newRoute}`);
      setRoute(newRoute);
    }
  };

  const authenticatedNavigation = () => {
    return (
      <BottomNavigation value={route} onChange={(_, newValue) => handleRouteChange(newValue)}>
        {isAdaptive && (
          <BottomNavigationAction label="Farm" icon={<FieldsIcon type="light" />} value="fields" />
        )}
        {isAdaptive && (
          <BottomNavigationAction label="Bins" icon={<BinsIcon type="light" />} value="bins" />
        )}
        {isAdCon && (
          <BottomNavigationAction
            label="Site Map"
            icon={<FieldsIcon type="light" />}
            value="constructionsiteMap"
          />
        )}
        {isOmni && (
          <BottomNavigationAction
            label="Map"
            icon={<AirportMapIcon type="light" />}
            value="aviationMap"
          />
        )}
        {isOmni && (
          <BottomNavigationAction
            label="Terminals"
            icon={<PlaneIcon type="light" />}
            value="terminals"
          />
        )}

        <BottomNavigationAction
          label="Devices"
          icon={
            isAdaptive ? (
              <BindaptIcon type="light" />
            ) : isAdCon ? (
              <NexusSTIcon type="light" />
            ) : isOmni ? (
              <OmniAirDeviceIcon type="light" />
            ) : (
              <DevicesIcon />
            )
          }
          value="devices"
        />
        {isAdCon && (
          <BottomNavigationAction
            label="Sites"
            icon={<JobsiteIcon type="light" />}
            value="jobsites"
          />
        )}
        {isMiVent && (
          <BottomNavigationAction
            label="Ventilation"
            icon={<VentilationIcon type="light" />}
            value="ventilation"
          />
        )}

        {isBXT() && user.hasFeature("security") && (
          <BottomNavigationAction label="Security" icon={<Security />} value="security" />
        )}
        <BottomNavigationAction label="More" icon={<MoreHoriz />} value="more" />
      </BottomNavigation>
    );
  };

  // BXT specific navigation
  const unauthenticatedNavigation = () => {
    return (
      <BottomNavigation value={route} onChange={(_, newValue) => handleRouteChange(newValue)}>
        <BottomNavigationAction label="Demo" value="demo" icon={<DemoIcon />} />
        <BottomNavigationAction label="Home" icon={<Home />} value="" />
        <BottomNavigationAction label="More" icon={<MoreHoriz />} value="more" />
      </BottomNavigation>
    );
  };

  return (
    <Box
      position="fixed"
      bottom={0}
      width="100vw"
      zIndex={theme.zIndex.appBar}
      style={{
        overflow:
          window.location.origin.includes("staging") || window.location.origin.includes("localhost")
            ? "scroll"
            : "visible"
      }}>
      {isAuthenticated ? authenticatedNavigation() : unauthenticatedNavigation()}
    </Box>
  );
}

import { Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import React, { useState } from "react";
import AgricultureDefinitions from "./definitions/agricultureDefinitions";
import AviationDefinitions from "./definitions/aviationDefinitions";
import ConstructionDefinitions from "./definitions/constructionDefinitions";
import GeneralDefinitions from "./definitions/generalDefinitions";
import MiningDefinitions from "./definitions/miningDefinitions";

export default function Definitions() {
  const [currentTab, setCurrentTab] = useState("general");

  return (
    <TabContext value={currentTab}>
      <Tabs
        value={currentTab}
        onChange={(_, value) => setCurrentTab(value)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth">
        <Tab label={"General"} value={"general"} />
        <Tab label={"Agriculture"} value={"agriculture"} />
        <Tab label={"Aviation"} value={"aviation"} />
        <Tab label={"Mining"} value={"mining"} />
        <Tab label={"Construction"} value={"construction"} />
      </Tabs>
      <TabPanel value="general">
        <GeneralDefinitions />
      </TabPanel>
      <TabPanel value="agriculture">
        <AgricultureDefinitions />
      </TabPanel>
      <TabPanel value="aviation">
        <AviationDefinitions />
      </TabPanel>
      <TabPanel value="mining">
        <MiningDefinitions />
      </TabPanel>
      <TabPanel value="construction">
        <ConstructionDefinitions />
      </TabPanel>
    </TabContext>
  );
}

import { Box, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import ObjectDefinitionDisplay, { data } from "./objectDefinitionDisplay";

// template
/**
 *     
    {
      name: "",
      fields: [
        {
          fieldName: "",
          fieldType: "",
          fieldDescription: ""
        },
      ]
    },
 */

// aviation definitions
const terminalData: data[] = [
  {
    name: "Terminal",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the terminal"
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the terminal"
      },
      {
        fieldName: "settings",
        fieldType: "TerminalSettings",
        fieldDescription: "The settings of the terminal"
      }
    ]
  },
  {
    name: "TerminalSettings",
    fields: [
      {
        fieldName: "longitude",
        fieldType: "float",
        fieldDescription: "The longitude coordinate"
      },
      {
        fieldName: "latitude",
        fieldType: "float",
        fieldDescription: "The latitude coordinate"
      },
      {
        fieldName: "theme",
        fieldType: "ObjectTheme",
        fieldDescription: "Theme for display purposes"
      }
    ]
  }
];

const gateData: data[] = [
  {
    name: "Gate",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the gate"
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the gate"
      },
      {
        fieldName: "settings",
        fieldType: "GateSettings",
        fieldDescription: "The settings of the gate"
      },
      {
        fieldName: "componentPreferences",
        fieldType: "map<string, GateComponentType(ENUM)>",
        fieldDescription:
          "A map using the component keys of linked components to the component type for the gate"
      },
      {
        fieldName: "gateMutations",
        fieldType: "map<string, GatMutations>",
        fieldDescription:
          "A map using device ids to store the mutated measurement estimates based on the sensors actual readings from that device"
      },
      {
        fieldName: "pcaState",
        fieldType: "PCAState (ENUM)",
        fieldDescription: "The state of the connected pca unit"
      }
    ]
  },
  {
    name: "GateSettings",
    fields: [
      {
        fieldName: "longitude",
        fieldType: "float",
        fieldDescription: "The longitude coordinate"
      },
      {
        fieldName: "latitude",
        fieldType: "float",
        fieldDescription: "The latitude coordinate"
      },
      {
        fieldName: "terminal",
        fieldType: "string",
        fieldDescription: "The key of the terminal this gate belongs to"
      },
      {
        fieldName: "ductDiameter",
        fieldType: "float",
        fieldDescription: "The diameter of the ducting for the gate in millimeters"
      },
      {
        fieldName: "ductLength",
        fieldType: "float",
        fieldDescription: "The length of the ducting in meters"
      },
      {
        fieldName: "frictionFactor",
        fieldType: "float",
        fieldDescription: "The friction coefficient of the ducting"
      },
      {
        fieldName: "thermalConductivity",
        fieldType: "float",
        fieldDescription: "The thermal conductivity of the ducting"
      },
      {
        fieldName: "thermalResistance",
        fieldType: "float",
        fieldDescription: "The thermal resistance of the ducting"
      },
      {
        fieldName: "upperFlow",
        fieldType: "float",
        fieldDescription: "The upper limit of acceptable mass air flow"
      },
      {
        fieldName: "lowerFlow",
        fieldType: "float",
        fieldDescription: "The lower limit of acceptable mass air flow"
      },
      {
        fieldName: "pcaType",
        fieldType: "string",
        fieldDescription: "The type of pca on the gate"
      },
      {
        fieldName: "ductName",
        fieldType: "string",
        fieldDescription: "The name of the ducting"
      },
      // {
      //   fieldName: "airport",
      //   fieldType: "",
      //   fieldDescription: ""
      // },
      {
        fieldName: "letterIdentifier",
        fieldType: "string",
        fieldDescription: "The letter assigned to the gate"
      },
      {
        fieldName: "numberIdentifier",
        fieldType: "string",
        fieldDescription: "The number assigned to the gate"
      },
      {
        fieldName: "hourlyApuCost",
        fieldType: "float",
        fieldDescription: "The cost to run the apu of an aircraft for an hour at the gate"
      },
      {
        fieldName: "hourlyPcaCost",
        fieldType: "float",
        fieldDescription: "The cost to run the pca unit for an hour at the gate"
      },
      {
        fieldName: "theme",
        fieldType: "ObjectTheme",
        fieldDescription: "Them for display purposes"
      }
    ]
  },
  {
    name: "GateMutations",
    fields: [
      {
        fieldName: "cfm",
        fieldType: "float",
        fieldDescription: "The cfm value in the ducting calculated from the pressure sensors"
      },
      {
        fieldName: "temp",
        fieldType: "float",
        fieldDescription: "The estimated final temp in the aircraft cab"
      }
    ]
  },
  {
    name: "FlowAt",
    fields: [
      {
        fieldName: "airFlow",
        fieldType: "float",
        fieldDescription: "The Mass air flow value"
      },
      {
        fieldName: "time",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for the mass airflow measurement"
      }
    ]
  }
];

export default function AviationDefinition() {
  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Terminal Definitions
      </Typography>
      <List>
        {terminalData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Gate Definitions
      </Typography>
      <List>
        {gateData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

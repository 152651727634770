import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

export function permissionToString(permission: pond.Permission): string {
  return or(
    new Map<pond.Permission, string>([
      [pond.Permission.PERMISSION_INVALID, "PERMISSION_INVALID"],
      [pond.Permission.PERMISSION_READ, "PERMISSION_READ"],
      [pond.Permission.PERMISSION_SHARE, "PERMISSION_SHARE"],
      [pond.Permission.PERMISSION_USERS, "PERMISSION_USERS"],
      [pond.Permission.PERMISSION_WRITE, "PERMISSION_WRITE"],
      [pond.Permission.PERMISSION_FILE_MANAGEMENT, "PERMISSION_FILE_MANAGEMENT"],
      [pond.Permission.PERMISSION_BILLING, "PERMISSION_BILLING"]
    ]).get(permission),
    "PERMISSION_INVALID"
  );
}

export function canWrite(permissions: pond.Permission[]): boolean {
  return permissions.includes(pond.Permission.PERMISSION_WRITE);
}

import { AddressTypeExtension } from "pbHelpers/AddressType";
import { quack } from "protobuf-ts/quack";

export const I2C: AddressTypeExtension = {
  addressType: quack.AddressType.ADDRESS_TYPE_I2C,
  friendlyName: "I²C",
  humanReadableAddress: function(componentType: quack.ComponentType, address: number): string {
    const offsets = new Map<quack.ComponentType, number>([
      [quack.ComponentType.COMPONENT_TYPE_PRESSURE, 0x17],
      [quack.ComponentType.COMPONENT_TYPE_LIGHT, 0x28],
      [quack.ComponentType.COMPONENT_TYPE_CO2, 0x60],
      [quack.ComponentType.COMPONENT_TYPE_STEPPER_MOTOR, 0x13],
      [quack.ComponentType.COMPONENT_TYPE_PH, 0x3f],
      [quack.ComponentType.COMPONENT_TYPE_DHT, 0x3f],
      [quack.ComponentType.COMPONENT_TYPE_VAPOUR_PRESSURE_DEFICIT, 0x3f],
      [quack.ComponentType.COMPONENT_TYPE_CAPACITANCE, 0x4f],
      [quack.ComponentType.COMPONENT_TYPE_CAPACITOR_CABLE, 0x4f],
      [quack.ComponentType.COMPONENT_TYPE_SEN5X, 0x68]
    ]);

    const offset = offsets.get(componentType);
    if (!offset) {
      return "0";
    }

    return (address - offset).toString();
  },
  singular: false
};

import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";

export interface INoteAPIContext {
  addNote: (note: pond.NoteSettings, attachments?: string[]) => Promise<any>;
  getNote: (noteID: string) => Promise<any>;
  updateNote: (note: pond.NoteSettings) => Promise<any>;
  listNotes: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean,
    as?: string
  ) => Promise<AxiosResponse<pond.ListNotesResponse>>;
  removeNote: (noteID: string) => Promise<AxiosResponse<pond.RemoveBinResponse>>;
}

export const NoteAPIContext = createContext<INoteAPIContext>({} as INoteAPIContext);

interface Props {}

export default function NoteProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();

  const addNote = (note: pond.NoteSettings, attachments?: string[]) => {
    return post(
      pondURL("/notes" + (attachments ? "?attachments=" + attachments.toString() : "")),
      note
    );
  };

  const getNote = (noteID: string) => {
    return get(pondURL("/notes/" + noteID));
  };

  const updateNote = (note: pond.NoteSettings) => {
    return put(pondURL("/notes/"), note);
  };

  const removeNote = (key: string) => {
    return del<pond.RemoveNoteResponse>(pondURL("/notes/" + key));
  };

  const listNotes = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean,
    as?: string
  ) => {
    return get<pond.ListNotesResponse>(
      pondURL(
        "/notes" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + (order ? order : "asc")) +
          ("&by=" + (orderBy ? orderBy : "key")) +
          (search ? "&search=" + search : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (as ? "&as=" + as : "")
      )
    );
  };

  return (
    <NoteAPIContext.Provider
      value={{
        addNote,
        getNote,
        updateNote,
        listNotes,
        removeNote
      }}>
      {children}
    </NoteAPIContext.Provider>
  );
}

export const useNoteAPI = () => useContext(NoteAPIContext);

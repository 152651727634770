import {
  Avatar,
  CircularProgress,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useTheme
} from "@material-ui/core";
import { useImagekitAPI } from "providers/pond/imagekitAPI";
import React, { useState } from "react";
import { SupervisedUserCircle as TeamIcon } from "@material-ui/icons";

const defaults = [
  "",
  "https://ik.imagekit.io/adaptive/defaults/bird_ty8BTljNA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663103520016",
  "https://ik.imagekit.io/adaptive/defaults/leaf_rF3z5r2tZ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663212283945",
  "https://ik.imagekit.io/adaptive/defaults/flower_Uc7QzEQs4R.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663214712629",
  "https://ik.imagekit.io/adaptive/defaults/snowleopard_KbnacTZMB.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663103172262",
  "https://ik.imagekit.io/adaptive/defaults/car_r5hNMcv7Y.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663212283392",
  "https://ik.imagekit.io/adaptive/defaults/sunflower_Pg8sac7NK.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663214713051",
  "https://ik.imagekit.io/adaptive/defaults/deer__O5DKbszp.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663102676111",
  "https://ik.imagekit.io/adaptive/defaults/polarbear_5PMEyQvwH.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663208045638",
  "https://ik.imagekit.io/adaptive/defaults/beach_i2CM4_uB3.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663212283346",
  "https://ik.imagekit.io/adaptive/defaults/bunny_ofnih4I6k.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663208045524",
  "https://ik.imagekit.io/adaptive/defaults/fox_4CPQjzeSw.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663208044904",
  "https://ik.imagekit.io/adaptive/defaults/frog_An4L9CIIj.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663208044711",
  "https://ik.imagekit.io/adaptive/defaults/lighthouse_U56RsSQws.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663212280873",
  "https://ik.imagekit.io/adaptive/defaults/ball_fpYXIQarg.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663214713217",
  "https://ik.imagekit.io/adaptive/defaults/dog_bpolRB5HP.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663208041090",
  "https://ik.imagekit.io/adaptive/defaults/turtle__kcJnFkt1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1663207995148"
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      textAlign: "center",
      margin: "1px"
    },
    img: {
      height: "100%",
      width: "auto !important"
    },
    avatar: {
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        width: "32px",
        height: "32px"
      },
      [theme.breakpoints.down("lg")]: {
        width: "64px",
        height: "64px"
      }
    },
    avatarBig: {
      [theme.breakpoints.down("lg")]: {
        width: "128px",
        height: "128px"
      }
    },
    selected: {
      backgroundColor: "rgba(150, 150, 150, 0.25)"
    }
  })
);

interface Props {
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  id: string;
  style?: any;
  title?: string;
}

export function IconPicker(props: Props) {
  const { setUrl, id, title, url } = props;
  const classes = useStyles();
  const theme = useTheme();
  const imagekitAPI = useImagekitAPI();

  const [loading, setLoading] = useState<boolean>(false);

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setLoading(true);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(event?.target?.result);
      };
      reader.onerror = err => {
        reject(err);
      };
      reader.readAsDataURL(file);
    }).then((resp: any) => {
      let r: string = resp.toString();
      imagekitAPI
        .uploadImage("icon", r, "/" + id + "/")
        .then(resp => {
          setUrl(resp.data.url + "?v=" + Math.random());
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
    });
  };

  const top = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row"
        }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginRight: theme.spacing(1)
          }}>
          <Typography variant="h6">{title ? title : "Icon"}</Typography>
          <Avatar
            src={url}
            style={{
              margin: theme.spacing(1)
            }}
            className={classes.avatarBig}>
            <TeamIcon style={{ fontSize: 56 }} />
          </Avatar>
        </div>
        {sources()}
      </div>
    );
  };

  const picker = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          flexFlow: "row wrap"
        }}>
        {defaults.map((src, index) => {
          return (
            <div
              key={index}
              style={{
                padding: theme.spacing(1),
                borderRadius: "4px",
                display: "inline-block"
              }}
              onClick={() => {
                setUrl(src);
              }}
              className={url === src ? classes.selected : undefined}>
              <Avatar src={src} variant="rounded" className={classes.avatar}>
                <TeamIcon style={{ fontSize: 48 }} />
              </Avatar>
            </div>
          );
        })}
      </div>
    );
  };

  const sources = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <input
          name="Upload Image"
          type="file"
          onChange={event => onChangeFile(event)}
          style={{
            margin: theme.spacing(1)
          }}
        />
        {loading && (
          <CircularProgress
            style={{
              marginLeft: theme.spacing(1),
              width: theme.spacing(3),
              height: theme.spacing(3)
            }}
          />
        )}
        <TextField
          id="outlined-basic"
          label="Image URL"
          variant="outlined"
          fullWidth
          style={{ margin: theme.spacing(1) }}
          onChange={event => {
            setUrl(event.currentTarget.value);
          }}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      {top()}
      <Typography
        variant="h6"
        style={{
          margin: theme.spacing(1),
          textAlign: "center"
        }}>
        Pick one
      </Typography>
      {picker()}
    </React.Fragment>
  );
}

import RocksLight from "assets/products/Ag/rocksLight.png";
import RocksDark from "assets/products/Ag/rocksDark.png";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  width: number;
  height: number;
  type?: "light" | "dark";
}

export default function RocksIcon(props: Props) {
  const themeType = useThemeType();
  const { width, height, type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? RocksLight : RocksDark;
    }

    return themeType === "light" ? RocksDark : RocksLight;
  };

  return <img width={width} height={height} alt="rocksIcon" src={src()} />;
}

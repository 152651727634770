import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { cloneDeep } from "lodash";

export class Upgrade {
  public settings: pond.UpgradeSettings = pond.UpgradeSettings.create();
  public status: pond.UpgradeStatus = pond.UpgradeStatus.create();

  public static create(pb?: pond.Upgrade): Upgrade {
    let my = new Upgrade();
    if (pb) {
      my.settings = pond.UpgradeSettings.fromObject(or(pb.settings, {}));
      my.status = pond.UpgradeStatus.fromObject(or(pb.status, {}));
    }
    return my;
  }

  public static any(data: any): Upgrade {
    return Upgrade.create(pond.Upgrade.fromObject(cloneDeep(data)));
  }

  public static clone(other: Upgrade): Upgrade {
    let my = new Upgrade();
    my.settings = pond.UpgradeSettings.fromObject(cloneDeep(other.settings));
    my.status = pond.UpgradeStatus.fromObject(cloneDeep(other.status));
    return my;
  }

  public key(): string {
    return this.settings.key;
  }
}

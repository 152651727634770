import {
  Box,
  Button,
  createStyles,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Theme
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import MoreIcon from "@material-ui/icons/MoreVert";
import ShareObjectIcon from "@material-ui/icons/Share";
import ObjectUsersIcon from "@material-ui/icons/AccountCircle";
import ObjectTeamsIcon from "@material-ui/icons/SupervisedUserCircle";
import { pond } from "protobuf-ts/pond";
import React, { useState } from "react";
import ObjectUsers from "user/ObjectUsers";
import ShareObject from "user/ShareObject";
import { isOffline } from "utils/environment";
import ObjectTeams from "teams/ObjectTeams";
import { planScope, HarvestPlan, Field } from "models";
import HarvestSettings from "./HarvestSettings";
import DuplicateHarvestPlan from "./DuplicateHarvestPlan";
import { Edit } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    shareIcon: {
      color: blue["500"],
      "&:hover": {
        color: blue["600"]
      }
    },
    removeIcon: {
      color: "var(--status-alert)"
    },
    red: {
      color: "var(--status-alert)"
    }
  });
});

interface Props {
  plan: HarvestPlan;
  planField: Field;
  fieldList: Field[];
  permissions: pond.Permission[];
  refreshCallback: (updatedPlan?: HarvestPlan) => void;
  hideNew?: boolean;
}

interface OpenState {
  share: boolean;
  users: boolean;
  teams: boolean;
  settings: boolean;
  removeSelf: boolean;
  duplicate: boolean;
}

export default function HarvestPlanActions(props: Props) {
  const classes = useStyles();
  const { plan, planField, fieldList, permissions, refreshCallback, hideNew } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openState, setOpenState] = useState<OpenState>({
    share: false,
    users: false,
    teams: false,
    settings: false,
    removeSelf: false,
    duplicate: false
  });
  const [updatePlan, setUpdatePlan] = useState(false);

  const groupMenu = () => {
    const canShare = permissions.includes(pond.Permission.PERMISSION_SHARE);
    const canManageUsers = permissions.includes(pond.Permission.PERMISSION_USERS);
    return (
      <Menu
        id="groupMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        keepMounted
        disableAutoFocusItem>
        {!isOffline() &&
          planField.permissions.includes(pond.Permission.PERMISSION_WRITE) &&
          !hideNew && (
            <MenuItem
              dense
              onClick={() => {
                setUpdatePlan(false);
                setOpenState({ ...openState, settings: true });
                setAnchorEl(null);
              }}
              button>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="New Plan" />
            </MenuItem>
          )}
        {!isOffline() && planField.permissions.includes(pond.Permission.PERMISSION_WRITE) && (
          <MenuItem
            dense
            onClick={() => {
              setOpenState({ ...openState, duplicate: true });
              setAnchorEl(null);
            }}
            button>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Duplicate Plan" />
          </MenuItem>
        )}
        {!isOffline() && canShare && (
          <MenuItem
            dense
            onClick={() => {
              setOpenState({ ...openState, share: true });
              setAnchorEl(null);
            }}
            button>
            <ListItemIcon>
              <ShareObjectIcon className={classes.shareIcon} />
            </ListItemIcon>
            <ListItemText primary="Share" />
          </MenuItem>
        )}
        {!isOffline() && canManageUsers && (
          <MenuItem
            dense
            onClick={() => {
              setOpenState({ ...openState, users: true });
              setAnchorEl(null);
            }}
            button>
            <ListItemIcon>
              <ObjectUsersIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </MenuItem>
        )}
        {!isOffline() && canManageUsers && (
          <MenuItem
            dense
            onClick={() => {
              setOpenState({ ...openState, teams: true });
              setAnchorEl(null);
            }}
            button>
            <ListItemIcon>
              <ObjectTeamsIcon />
            </ListItemIcon>
            <ListItemText primary="Teams" />
          </MenuItem>
        )}
      </Menu>
    );
  };

  const dialogs = () => {
    const key = plan.key();
    const label = plan.settings.title;
    return (
      <React.Fragment>
        <HarvestSettings
          field={planField}
          update={updatePlan}
          open={openState.settings}
          plan={plan.key() !== "" ? plan : undefined}
          close={(refresh, updatedPlan) => {
            setOpenState({ ...openState, settings: false });
            if (refresh) {
              refreshCallback(updatedPlan);
            }
          }}
        />
        <DuplicateHarvestPlan
          open={openState.duplicate}
          close={() => {
            setOpenState({ ...openState, duplicate: false });
            refreshCallback();
          }}
          fields={fieldList}
          plan={plan}
        />
        <ShareObject
          scope={planScope(key)}
          label={label}
          permissions={permissions}
          isDialogOpen={openState.share}
          closeDialogCallback={() => setOpenState({ ...openState, share: false })}
        />
        <ObjectUsers
          scope={planScope(key)}
          label={label}
          permissions={permissions}
          isDialogOpen={openState.users}
          closeDialogCallback={() => setOpenState({ ...openState, users: false })}
          refreshCallback={refreshCallback}
        />
        <ObjectTeams
          scope={planScope(key)}
          label="Teams"
          permissions={permissions}
          isDialogOpen={openState.teams}
          refreshCallback={refreshCallback}
          closeDialogCallback={() => setOpenState({ ...openState, teams: false })}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        {plan.key() !== "" && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setUpdatePlan(true);
              setOpenState({ ...openState, settings: true });
            }}>
            <Edit /> Edit or Add Activity
          </Button>
        )}
        <IconButton
          aria-owns={anchorEl ? "groupMenu" : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget)
          }>
          <MoreIcon />
        </IconButton>
      </Box>

      {groupMenu()}
      {dialogs()}
    </React.Fragment>
  );
}

import { Box, Card, CircularProgress, Typography } from "@material-ui/core";
import BarGraph, { BarData } from "charts/BarGraph";
import GrainDescriber from "grain/GrainDescriber";
import { GrainBag } from "models/GrainBag";
import moment, { Moment } from "moment";
import { pond } from "protobuf-ts/pond";
import { useGrainBagAPI, useSnackbar } from "providers";
import React, { useEffect, useState } from "react";
import { getGrainUnit } from "utils";

interface Props {
  grainBag: GrainBag;
  startDate: Moment;
  endDate: Moment;
  customHeight?: number | string;
}

export default function GrainBagInventoryGraph(props: Props) {
  const { grainBag, startDate, endDate, customHeight } = props;
  const grainBagAPI = useGrainBagAPI();
  const [data, setData] = useState<BarData[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const { openSnack } = useSnackbar();

  useEffect(() => {
    if (grainBag.key() && !loadingData) {
      setLoadingData(true);
      grainBagAPI
        .listHistory(grainBag.key(), 500, 0, startDate.toISOString(), endDate.toISOString())
        .then(resp => {
          let barData: BarData[] = [];
          let lastBushels = 0;
          resp.data.history.forEach(hist => {
            if (hist.object?.grainBagSettings) {
              //let time = hist.timestamp;
              let val = hist.object.grainBagSettings.currentBushels ?? 0;
              if (
                getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT &&
                grainBag.bushelsPerTonne() > 1
              ) {
                val = Math.round((val / grainBag.bushelsPerTonne()) * 100) / 100;
              }
              if (val !== lastBushels) {
                lastBushels = val;
                barData.push({
                  timestamp: moment(hist.timestamp).valueOf(),
                  value: val
                });
              }
            }
          });
          if (barData.length === 0) {
            barData.push({
              timestamp: moment.now().valueOf(),
              value:
                getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT &&
                grainBag.bushelsPerTonne() > 1
                  ? Math.round((grainBag.bushels() / grainBag.bushelsPerTonne()) * 100) / 100
                  : grainBag.bushels()
            });
          }
          setData(barData);
        })
        .catch(err => {
          openSnack("There was a problem retrieving inventory information");
        })
        .finally(() => {
          setLoadingData(false);
        });
    }
  }, [grainBagAPI, grainBag, startDate, endDate, openSnack]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card raised>
      <Box padding={2}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Inventory over Time
        </Typography>
      </Box>
      {!loadingData && data.length > 0 ? (
        <BarGraph
          barColour={
            grainBag.storage() === pond.BinStorage.BIN_STORAGE_SUPPORTED_GRAIN
              ? GrainDescriber(grainBag.grain()).colour
              : "yellow"
          }
          data={data}
          yMin={0}
          yMax={
            getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT
              ? grainBag.capacity() / grainBag.bushelsPerTonne()
              : grainBag.capacity()
          }
          customHeight={customHeight}
          useGradient
          labels
          labelColour="white"
        />
      ) : (
        <Box height={400} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </Card>
  );
}

import TemperatureDarkIcon from "assets/components/temperatureDark.png";
import TemperatureLightIcon from "assets/components/temperatureLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
  heightWidth?: number;
}

export default function TemperatureIcon(props: Props) {
  const themeType = useThemeType();
  const { type, heightWidth } = props;

  const lightIcon = () => {
    return TemperatureLightIcon;
  };

  const darkIcon = () => {
    return TemperatureDarkIcon;
  };

  const src = () => {
    if (type) {
      return type === "light" ? lightIcon() : darkIcon();
    }

    return themeType === "light" ? darkIcon() : lightIcon();
  };

  if (heightWidth) {
    return <img alt="tempIcon" src={src()} height={heightWidth} width={heightWidth} />;
  }

  return <ImgIcon alt="bins" src={src()} />;
}

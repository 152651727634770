import White from "assets/products/Aviation/OmniAirDeviceIconWhite.png";
import Black from "assets/products/Aviation/OmniAirDeviceIconBlack.png";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  size?: number;
  type?: "light" | "dark";
}

export default function OmniAirDeviceIcon(props: Props) {
  const themeType = useThemeType();
  const { type, size } = props;

  const src = () => {
    if (type) {
      return type === "light" ? White : Black;
    }

    return themeType === "light" ? Black : White;
  };

  return <img alt="OmniAirDevice" src={src()} width={size ?? 25} height={size ?? 25} />;
}

import {
  Add,
  ArrowDownward,
  Cancel,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from "@material-ui/icons";
import { useWidth } from "hooks";
import InfiniteScroll from "react-infinite-scroller";
import { Grid, Typography } from "@material-ui/core";
import Loader from "./Loader";
import SmartBreadcrumb from "./SmartBreadcrumb";
import React, { useState } from "react";
import MaterialTable, { Icons, MTableToolbar, Column } from "material-table";
import { Query, QueryResult } from "material-table/types";

export function getTableIcons(): Icons {
  return {
    Add: Add,
    Check: Check,
    Delete: DeleteOutline,
    DetailPanel: ChevronRight,
    Export: SaveAlt,
    Filter: FilterList,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
    ResetSearch: Clear,
    Search: Search,
    SortArrow: ArrowDownward,
    ThirdStateCheck: Remove,
    ViewColumn: ViewColumn,
    Edit: Edit,
    Clear: Cancel
  } as Icons;
}

interface Props {
  kind: string;
  data: object[] | ((query: Query<object>) => Promise<QueryResult<object>>);
  viewLayout: "cozy" | "compact";
  columns: Column<object>[];
  onRowClick?:
    | ((
        event?: React.MouseEvent<Element, MouseEvent> | undefined,
        rowData?: object | undefined,
        toggleDetailPanel?: ((panelIndex?: number | undefined) => void) | undefined
      ) => void)
    | undefined;
}

// ResponsiveList is a component that either displays a table or an infinite scroll list depending on the viewport
export function ResponsiveTable(props: Props) {
  const width = useWidth();
  const { viewLayout, kind, data, columns, onRowClick } = props;
  const [total] = useState<number>(0);
  // const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [objects] = useState<Array<any>>([]);

  const infiniteContent = () => {
    if (data.length < 1) {
      return (
        <Typography variant="h5" align="center" color="textPrimary">
          {"No " + kind + "'s found"}
        </Typography>
      );
    }

    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={viewLayout === "cozy" ? 4 : 2}>
        {/* {data.map((d, i) => (
          <Grid key={i} item xs={12}></Grid>
        ))} */}
      </Grid>
    );
  };

  const list = () => {
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {}}
        hasMore={objects.length < total}
        loader={
          <Grid item xs={12} container justify="center" key={"loading"}>
            <Loader size="small" />
          </Grid>
        }>
        {infiniteContent()}
      </InfiniteScroll>
    );
  };

  const table = () => {
    return (
      <MaterialTable
        icons={getTableIcons()}
        columns={columns}
        data={data}
        options={{
          paging: true,
          pageSize: pageSize,
          pageSizeOptions: [5, 10, 20],
          padding: viewLayout === "compact" ? "dense" : "default",
          showTitle: true,
          toolbar: true,
          sorting: true,
          filtering: true
        }}
        localization={{
          body: { emptyDataSourceMessage: "No " + kind + "'s found" }
        }}
        components={{
          Toolbar: props => (
            <React.Fragment>
              <MTableToolbar {...props} />
            </React.Fragment>
          )
        }}
        title={<SmartBreadcrumb />}
        onRowClick={onRowClick}
        onChangeRowsPerPage={newPageSize => setPageSize(newPageSize)}
      />
    );
  };

  return width === "xs" ? list() : table();
}

import { Chip, Tooltip } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React from "react";
import { getFirmwareVersionHelper } from "pbHelpers/FirmwareVersion";

const styles = (theme: Theme) => ({});

interface Props extends WithStyles<typeof styles> {
  version: string;
  available: string;
}

interface State {}

class VersionChip extends React.Component<Props, State> {
  render() {
    const { version, available } = this.props;
    const firmwareVersionHelper = getFirmwareVersionHelper(version, available);
    return (
      <Tooltip title={firmwareVersionHelper.tooltip}>
        <Chip
          variant="outlined"
          label={firmwareVersionHelper.description}
          icon={firmwareVersionHelper.icon}
        />
      </Tooltip>
    );
  }
}

export default withStyles(styles)(VersionChip);

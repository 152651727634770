import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";

export interface IHomeMarkerAPIContext {
  addHomeMarker: (
    homeMarker: pond.HomeMarkerSettings
  ) => Promise<AxiosResponse<pond.AddHomeMarkerResponse>>;
  getHomeMarker: (homeMarkerID: string) => Promise<AxiosResponse<pond.HomeMarker>>;
  listHomeMarkers: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean,
    as?: string
  ) => Promise<AxiosResponse<pond.ListHomeMarkersResponse>>;
  removeHomeMarker: (homeMarkerID: string) => Promise<AxiosResponse<pond.RemoveHomeMarkerResponse>>;
  updateHomeMarker: (
    key: string,
    homeMarker: pond.HomeMarkerSettings,
    asRoot?: true
  ) => Promise<AxiosResponse<pond.UpdateHomeMarkerResponse>>;
}

export const HomeMarkersAPIContext = createContext<IHomeMarkerAPIContext>(
  {} as IHomeMarkerAPIContext
);

interface Props {}

export default function HomeMarkerProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();
  const [{ as }] = useGlobalState();

  const addHomeMarker = (homeMarker: pond.HomeMarkerSettings) => {
    if (as) return post<pond.AddHomeMarkerResponse>(pondURL("/homeMarkers?as=" + as), homeMarker);
    return post<pond.AddHomeMarkerResponse>(pondURL("/homeMarkers"), homeMarker);
  };

  const getHomeMarker = (homeMarkerId: string) => {
    return get<pond.HomeMarker>(pondURL("/homeMarkers/" + homeMarkerId));
  };

  const removeHomeMarker = (key: string) => {
    return del<pond.RemoveHomeMarkerResponse>(pondURL("/homeMarkers/" + key));
  };

  const listHomeMarkers = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean,
    as?: string
  ) => {
    return get<pond.ListHomeMarkersResponse>(
      pondURL(
        "/homeMarkers" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + (order ? order : "asc")) +
          ("&by=" + (orderBy ? orderBy : "key")) +
          (search ? "&search=" + search : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (as ? "&as=" + as : "")
      )
    );
  };

  const updateHomeMarker = (key: string, hm: pond.HomeMarkerSettings, asRoot?: boolean) => {
    if (as)
      return put<pond.UpdateHomeMarkerResponse>(
        pondURL(
          "/homeMarkers/" + key + "?as=" + as + (asRoot ? "&asRoot=" + asRoot.toString() : "")
        ),
        hm
      );
    return put<pond.UpdateHomeMarkerResponse>(
      pondURL("/homeMarkers/" + key + (asRoot ? "?asRoot=" + asRoot.toString() : "")),
      hm
    );
  };

  return (
    <HomeMarkersAPIContext.Provider
      value={{
        addHomeMarker,
        getHomeMarker,
        removeHomeMarker,
        listHomeMarkers,
        updateHomeMarker
      }}>
      {children}
    </HomeMarkersAPIContext.Provider>
  );
}

export const useHomeMarkerAPI = () => useContext(HomeMarkersAPIContext);

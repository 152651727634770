import DisplayDrawer from "common/DisplayDrawer";
import { Gate as IGate } from "models/Gate";
import Gate from "pages/Gate";
import { useGateAPI, useSnackbar } from "providers";
import React, { useEffect, useState } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  gates: Map<string, IGate>;
  selectedGate: string;
  moveMap: (longitude: number, latitude: number) => void;
  removeMarker: (key: string) => void;
}

export default function GateDrawer(props: Props) {
  const { open, onClose, gates, selectedGate, moveMap, removeMarker } = props;
  const [gate, setGate] = useState(IGate.create());
  const gateAPI = useGateAPI();
  const { openSnack } = useSnackbar();

  useEffect(() => {
    let g = gates.get(selectedGate);
    if (g) {
      setGate(g);
    }
  }, [selectedGate, gates]);

  const closeDrawer = () => {
    onClose();
  };

  const displayNext = () => {
    let gateArr = Array.from(gates.values());
    let index = gateArr.indexOf(gate);
    let found = false;
    do {
      if (index === gateArr.length - 1) {
        index = 0;
      } else {
        index++;
      }
      let next = gateArr[index];
      if (
        next.longitude() !== 0 &&
        next.longitude() !== 0 &&
        next.latitude() !== undefined &&
        next.longitude() !== undefined
      ) {
        setGate(next);
        moveMap(next.longitude(), next.latitude());
        found = true;
      }
    } while (!found);
  };

  const displayPrev = () => {
    let gateArr = Array.from(gates.values());
    let index = gateArr.indexOf(gate);
    let found = false;
    do {
      if (index === 0) {
        index = gateArr.length - 1;
      } else {
        index--;
      }
      let next = gateArr[index];
      if (
        next.longitude() !== 0 &&
        next.latitude() !== 0 &&
        next.latitude() !== undefined &&
        next.longitude() !== undefined
      ) {
        setGate(next);
        moveMap(next.longitude(), next.latitude());
        found = true;
      }
    } while (!found);
  };

  const drawerBody = () => {
    return <Gate gateID={gate.key} useMobile />;
  };

  /**
   * function to remove the marker and coordinates from the object
   */
  const remove = () => {
    //set the long/lat of the yard to 0 and call an update
    let settings = gate.settings;
    settings.longitude = 0;
    settings.latitude = 0;
    gateAPI
      .updateGate(gate.key, gate.name, settings)
      .then(resp => {
        openSnack("Marker Removed");
        //then use the removeMarker prop function to update the markers in the parent map
        removeMarker(gate.key);
      })
      .catch(err => {
        openSnack("Failed to remove marker");
      });
  };

  return (
    <React.Fragment>
      <DisplayDrawer
        displayNext={displayNext}
        displayPrev={displayPrev}
        drawerBody={drawerBody()}
        removeElement={remove}
        onClose={closeDrawer}
        open={open}
        title={gate.name}
        width={"40vw"}
      />
    </React.Fragment>
  );
}

import { ControlPosition, useMap } from "react-map-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { useEffect } from "react";

export interface GeocoderObject {
  id: string;
  place_name: string;
  center: number[];
  place_type: string[];
}

interface GeocoderResult {
  result: GeocoderObject;
}
interface Props {
  mapboxAccessToken: string;
  position: ControlPosition;
  customEntries?: GeocoderObject[];
  customTransition?: (objectResult: GeocoderObject) => void;
  resultFunction?: (objectResult: GeocoderObject) => void;
}

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const geoCoder = new MapboxGeocoder({
  accessToken: MAPBOX_TOKEN,
  flyTo: true,
  marker: false,
  zoom: 18
});

export default function Geocoder(props: Props) {
  const { mapboxAccessToken, position, customEntries, resultFunction, customTransition } = props;
  const { current: map } = useMap();

  const localSearch = (query: string) => {
    const matchesCoords = query.match(
      /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    );
    let matchingResults: GeocoderObject[] = [];
    if (matchesCoords) {
      const coord1 = Number(matchesCoords[1]);
      const coord2 = Number(matchesCoords[2]);
      const matchingCoords: GeocoderObject[] = [];

      if (coord1 < -90 || coord1 > 90) {
        // coord1 is longitude
        matchingCoords.push({
          id: "Lat: " + coord2 + " Lng: " + coord1,
          place_name: "Lat: " + coord2 + " Lng: " + coord1,
          center: [coord1, coord2],
          place_type: ["coordinate"]
        });
      }

      if (coord2 < -90 || coord2 > 90) {
        // coord2 is longitude
        matchingCoords.push({
          id: "Lat: " + coord1 + " Lng: " + coord2,
          place_name: "Lat: " + coord1 + " Lng: " + coord2,
          center: [coord2, coord1],
          place_type: ["coordinate"]
        });
      }

      if (matchingCoords.length === 0) {
        // else could be either lng, lat or lat, lng
        matchingCoords.push({
          id: "Lat: " + coord2 + " Lng: " + coord1,
          place_name: "Lat: " + coord2 + " Lng: " + coord1,
          center: [coord1, coord2],
          place_type: ["coordinate"]
        });
        matchingCoords.push({
          id: "Lat: " + coord1 + " Lng: " + coord2,
          place_name: "Lat: " + coord1 + " Lng: " + coord2,
          center: [coord2, coord1],
          place_type: ["coordinate"]
        });
      }

      matchingResults = matchingResults.concat(matchingCoords);
    }

    customEntries?.forEach(obj => {
      if (obj.place_name.toLowerCase().includes(query.toLowerCase())) {
        matchingResults.push(obj);
      }
    });
    return matchingResults;
  };

  //set the geocoder options
  useEffect(() => {
    //connects the localSearch function to the geocoder if there are custom entries passed in
    if (customEntries) {
      geoCoder.options.localGeocoder = localSearch;
    }
    //disables the transition that the geocoder would fire if a custom transition was passed in
    if (customTransition) {
      geoCoder.options.flyTo = false;
    }
  }, [customEntries, customTransition]); // eslint-disable-line react-hooks/exhaustive-deps

  //attach custom functions to event handlers
  useEffect(() => {
    //events that fire as the search bar is typed into, note the function will run AFTER the event fires

    //event is fired when the search bar is cleared
    geoCoder.on("clear", () => {});

    //event is fired when starting to load the results
    geoCoder.on("loading", () => {});

    //event is fired when the results come back from a search
    geoCoder.on("results", () => {});

    //event is fired when there is an error
    geoCoder.on("error", () => {});

    //event is fired when an option is selected from the results
    geoCoder.on("result", (res: GeocoderResult) => {
      if (resultFunction) {
        resultFunction(res.result);
      }
      if (customTransition) {
        customTransition(res.result);
      }
    });
    //once the map ref and access token are set up add the control to the map
    if (map && mapboxAccessToken) {
      map.addControl(geoCoder, position);
    }
  }, [map, mapboxAccessToken, position]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {}, []);

  return null;
}

import { Box, List, ListItem, Typography } from "@material-ui/core";
import EndpointDisplay, { EndPointData } from "apiDocs/endpoints/endpointDisplay";
import React from "react";

const genericListOptions = [
  "limit - the number to load",
  "offset - how many to skip over before starting to load",
  "order - the sort order",
  "by - what to sort by in the data",
  "search - a string to search the data for a match"
];

export default function GeneralEndpoints() {
  //all of the endpoint that are not a get are commented out for now as users with the api keys can only hit get endpoints
  const apiKeys: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/teams/:team/apiKeys",
      description: "gets all of the api keys associated with a team",
      urlParams: [":team - the unique id for a team"],
      responseParams: ["repeated string keys"]
    }
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/teams/:team/apiKeys",
    //   description: "add a new api key for the team",
    //   urlParams: [":team - the unique id for a team"],
    //   responseParams: ["string key"]
    // },
    // {
    //   requestType: "DEL",
    //   url: "https://api.brandxtech.ca/v1/teams/:team/apiKeys",
    //   description: "add a new api key for the team",
    //   urlParams: [":team - the unique id for a team"],
    //   urlOptions: ["key - the api key to remove"]
    // }
  ];
  const notifications: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/notifications",
      description: "gets a list of notifications",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search the data for a match"
      ],
      responseParams: ["repeated Notification notifications", "uint32 nextOffset", "uint32 total"]
    }
  ];
  const notes: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/notes",
      description: "gets a list of notes",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search the data for a match"
      ],
      responseParams: ["repeated Note notes", "uint32 nextOffset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/note/:note",
      description: "gets a list of notes",
      urlParams: [":note - the key of the note"],
      responseParams: ["NoteSettings settings", "NoteStatus status"]
    }
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/notes",
    //   description: "adds a new note",
    //   requestBody: "NoteSettings",
    //   responseParams: ["string note"]
    // },
    // {
    //   requestType: "PUT",
    //   url: "https://api.brandxtech.ca/v1/notes",
    //   description: "updates an existing note",
    //   requestBody: "NoteSettings"
    // },
    // {
    //   requestType: "DEL",
    //   url: "https://api.brandxtech.ca/v1/notes/:note",
    //   description: "deletes a note",
    //   urlParams: [":note - the key of the note"]
    // }
  ];
  const mapMarkers: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/homeMarkers",
      description: "get the home markers set for a team",
      urlOptions: genericListOptions,
      urlParams: [],
      requestBody: "",
      responseParams: ["repeated HomeMarker homeMarker", "uint32 nextOffset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/homeMarkers/:homeMarker",
      description: "Get a specific Home Marker based on the key",
      urlParams: [":homeMarker - the key of the home marker"],
      responseParams: ["HomeMarkerSettings settings", "HomeMarkerStatus status"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/fieldMarkers",
      description: "list all field markers",
      urlOptions: genericListOptions,
      responseParams: ["repeated FieldMarker fieldMarker", "uint32 nextOffset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/fieldMarkers/:fieldMarker",
      description: "get a specified field marker",
      urlParams: [":fieldMarker - the key of the field marker"],
      responseParams: ["FieldMarkerSettings settings", "FieldMarkerStatus status"]
    }
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/homeMarkers",
    //   description: "add a new home marker",
    //   requestBody: "HomeMarkerSettings",
    //   responseParams: ["string homeMarker"]
    // },
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/fieldMarkers",
    //   description: "add a new field marker",
    //   requestBody: "FieldMarkerSettings",
    //   responseParams: ["string fieldMarker"]
    // },
    // {
    //   requestType: "PUT",
    //   url: "https://api.brandxtech.ca/v1/homeMarkers/:homeMarker",
    //   description: "update an existing home marker",
    //   urlOptions: [":homeMarker - the key for the marker"],
    //   urlParams: ["HomeMarkerSettings"]
    // },
    // {
    //   requestType: "DEL",
    //   url: "https://api.brandxtech.ca/v1/homeMarkers/:homeMarker",
    //   description: "Delete an existing home marker",
    //   urlParams: [":homeMarker - the key of the home marker"]
    // },
    // {
    //   requestType: "PUT",
    //   url: "https://api.brandxtech.ca/v1/fieldMarkers/:fieldMarker",
    //   description: "update an existing field marker",
    //   urlParams: [":fieldMarker - the key for the field marker"],
    //   requestBody: "FieldMarkerSettings"
    // },
    // {
    //   requestType: "DEL",
    //   url: "https://api.brandxtech.ca/v1/fieldMarkers/:fieldMarker",
    //   description: "delete an existing field marker",
    //   urlParams: [":fieldMarker - the key for the marker"]
    // }
  ];
  const tasks: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/tasks",
      description: "get a list of tasks",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search the data for a match",
        "from - RFC3339 formatted timestamp to get all tasks with start after (to be used in conjunction with to)",
        "to - RFC3339 formatted timestamp to get all tasks with start before (to be used in conjunction with from)"
      ],
      responseParams: ["repeated Task tasks", "uint32 nextOffset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/tasks/:task",
      description: "get an existing task",
      urlParams: [":task - the key for the task"],
      responseParams: ["TaskSettings settigns", "string key"]
    }
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/tasks",
    //   description: "add a new task",
    //   requestBody: "TaskSettings",
    //   responseParams: ["string task"]
    // },
    // {
    //   requestType: "DEL",
    //   url: "https://api.brandxtech.ca/v1/tasks/:task",
    //   description: "delete an existing task",
    //   urlParams: [":task - the key of the task"]
    // },
    // {
    //   requestType: "PUT",
    //   url: "https://api.brandxtech.ca/v1/tasks/:task",
    //   description: "update an existing task",
    //   urlParams: [":task - the key of the task"],
    //   requestBody: "TaskSettings"
    // }
  ];
  const devices: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices",
      description: "get a list of devices",
      urlOptions: [
        "limit - the number of devices to load",
        "offset - the number of devices to skip over before starting to load",
        "order - what to sort the devices by",
        "by - how to order them (ASC/DESC)",
        "search - what to search in the devices for",
        "ids - comma seperated string of device ids to get",
        "comprehensive - boolean value of whether to get the components for the loaded devices",
        "measurements - boolean value of wether to get the measurements when loading comprehensive devices",
        "hologram - boolean value of whehter to get the hologram data for cellular devices"
      ],
      responseParams: [
        "repeated Device devices",
        "uint32 next_offset",
        "uint32 total",
        "repeated ComprehensiveDevice comprehensive_devices",
        "repeated HologramDevice hologram_devices"
      ]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/multidevices",
      description: "get a list of devices based on given ids",
      urlOptions: ["devices - comma seperated string of device ids"],
      responseParams: ["repeated Device devices", "uint32 nextOffset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device",
      description: "get a specified device",
      urlParams: [":device - the id of the device"],
      responseParams: ["DeviceSettings settings", "DeviceStatus status"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/geojson",
      description: "get the geojson data of a device such as longitude and latitude",
      urlParams: [":device - the id of the device"],
      responseParams: ["DeviceGeoJson"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/geojson/devices",
      description: "list the geojson data for devices",
      urlOptions: ["devices - comma seperated string of device ids"],
      responseParams: ["repeated DeviceGeoJSON geojsonDevices"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/history",
      description: "get the history of changes to the device",
      urlParams: [":device - the device id"],
      responseParams: ["repeated DeviceHistory history", "uint32 nextOffset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/completeHistory",
      description: "get the history of a device and all its attached components and interactions",
      urlParams: [":device - the device id"],
      responseParams: ["repeated ObjectHistory history", "uint32 nextOffset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/usage",
      description: "get data the usage of a device from a starting point to now",
      urlOptions: ["start - the date to start from"],
      urlParams: [":device - the id of the device"],
      responseParams: [
        "string status",
        "repeated Session sessions",
        "float latitude",
        "float longitude"
      ]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/paused",
      description: "get the pause state of the device",
      urlParams: [":device - the id of the device"],
      responseParams: ["bool paused"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/datacap",
      description: "get the monthly data cap for a device",
      urlParams: [":device - the id of the device"],
      responseParams: ["int64 overlimit"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/overlimit",
      description: "Get whether the datacap has been hit for a device",
      urlParams: [":device - the id of the device"],
      responseParams: ["bool isover"]
    }
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/devices/:device/clearPending",
    //   description: "clears pending device requests",
    //   urlParams: [":device - the id of the device"]
    // },
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/devices/:device/pause",
    //   description: "Pause a cellular device",
    //   urlParams: [":device - the id of the device"]
    // },
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/bulkPauseDevices",
    //   description: "pause or resume a list of devices",
    //   urlOptions: [
    //     "ids - comma seperated list of device ids",
    //     "paused - boolean to pause or unpause"
    //   ],
    //   responseParams: ["repeated DeviceSuccess devicesPaused"]
    // },
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/bulkChangeDataCap",
    //   description: "change the data cap for multiple devices",
    //   urlOptions: [
    //     "ids - comma seperated list of device ids",
    //     "datacap - the value for the new datacap"
    //   ],
    //   responseParams: ["repeated uint64", "uint64 bytes"]
    // },
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/devices/:device/resume",
    //   description: "resume a cellular device",
    //   urlParams: [":device - the id of the device"]
    // },
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/devices/:device/sync",
    //   description:
    //     "syncronizes the configuration on the device with the configuration saved in the database",
    //   urlParams: [":device - the id of the device"]
    // },
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/devices/:device/upgrade",
    //   description: "tell a device to upgrade to the latest firmware on its channel",
    //   urlParams: [":device - the id of the device"],
    //   responseParams: ["string key"]
    // },
    // {
    //   requestType: "POST",
    //   url: "https://api.brandxtech.ca/v1/devices/:device/datacap",
    //   description: "set the monthly datacap for a device",
    //   urlOptions: ["limit - the new datacap for the device"],
    //   urlParams: [":device - the id of the device"]
    // },
    // {
    //   requestType: "PUT",
    //   url: "https://api.brandxtech.ca/v1/devices/:device/tags/:tag",
    //   description: "sets a tag on the device",
    //   urlParams: [":device - the id if the device", ":tag - the tag to set on the device"]
    // },
    // {
    //   requestType: "PUT",
    //   url: "https://api.brandxtech.ca/v1/devices/:device/update",
    //   description: "update a device",
    //   urlParams: [":device - the id of the device"],
    //   requestBody: "DeviceSettings"
    // },
    //I think users should be able to use this one but im not sure
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/devices/:device/wifi",
    //     description: "update the wifi connection of a device",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    //thi one i dont think users should be able to do
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/devices/:device",
    //     description: "remove an existing device",
    //     urlParams: [
    //         ":device - the id of the device"
    //     ],
    // },
    // {
    //   requestType: "DEL",
    //   url: "https://api.brandxtech.ca/v1/devices/:device/tags/:tag",
    //   description: "remove a tag from a device",
    //   urlParams: [":device - the id of the device", ":tag - the tag to remove from the device"]
    // }
  ];
  const components: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/components/forObject",
      description: "gets all the components linked to an object such as a bin",
      urlOptions: [
        "keys - comma seperated list of keys, the final key in the list is the object that will be used to load the components, all keys before are for the permissions chain",
        "types - the corresponding object type for the key in the same position in the keys option"
      ],
      responseParams: ["repeated Component components", "map<string, uint64> component_devices"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/components",
      description: "Gets all components for a device",
      urlOptions: [
        "comprehensive - boolean whether to get the other related information for a component such as measurements"
      ],
      urlParams: [":device - the device id"],
      responseParams: ["repeated Component components", "map<string, uint64> component_devices"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/components/:component",
      description: "get a specified component on a device",
      urlParams: [":device - the device id", ":component - the key of the component"],
      responseParams: [
        "ComponentSettings settings",
        "ComponentStatus status",
        "repeated UnitMeasurementsForComponent last_measurement",
        "repeated Permission permissions"
      ]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/components/:component/history",
      description: "get the history of changes to a devices component",
      urlOptions: [
        "limit - the number of records to retrieve",
        "offset - the number of records to pass over before starting retrieval"
      ],
      urlParams: [":device - the device id", ":components - the key of the component"],
      responseParams: ["repeated ComponentHistory history", "uint32 nextOffset", "uint32 total"]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/devices/:device/components",
    //     description: "add a new component to a device",
    //     urlParams: [
    //         ":device - the device id"
    //     ],
    //     requestBody: "ComponentSettings"
    // },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/devices/:device/multiComponents",
    //     description: "add multiple components to a device",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/devices/:device/components/:component/preferences",
    //     description: "update a components preferences",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/devices/:device/components/:component/update",
    //     description: "update the settings of a component",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/devices/:device/components/:component",
    //     description: "removes an existing component",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];
  const interactions: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/interactions",
      description: "list the interactions for a device",
      urlParams: [":device - the device id"],
      responseParams: ["repeated Interaction interactions"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/components/:component/interactions",
      description: "list the interactions for a specified component on a device",
      urlParams: [":device - the id of the device", ":component - the key of the component"],
      responseParams: ["repeated Interaction interactions"]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/devices/:device/interactions",
    //     description: "add a new interaction to a device",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/devices/:device/interactions/multi",
    //     description: "add multiple interactions to a device",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/devices/:device/interactions",
    //     description: "update an existing interaction on a device",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/devices/:device/interactions/:interaction/interactionPondSettings",
    //     description: `update the settings of an interaction in the pond without touching the interaction on the device,
    //                   use this only for pond specific settings that the device does not need`,
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/devices/:device/interactions/:interaction",
    //     description: "remove an existing interaction from a device",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];
  const measurements: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/devices/:device/components/:component/unitMeasurements",
      description: "list the unit measurements for a component",
      urlOptions: [
        "limit - the maximum number of measurements to get",
        "offset - the number of records to skip over before starting retrieval",
        "order - what to order the measurements by",
        "type - enum value of measurement type to include if you only want a specific type ie. only get temperature and not humidity for a grain cable you would include type=1",
        "start - RFC3339 formatted timestamp for the start of the window",
        "end - RFC3339 formatted timestamp for the end of the window"
      ],
      urlParams: [":device - the id of the device", ":component - the key of the component"],
      responseParams: [
        "repeated UnitMeasurementsForComponent measurements",
        "uint32 next_offset",
        "uint32 total"
      ]
    },
    {
      requestType: "GET",
      url:
        "https://api.brandxtech.ca/v1/devices/:device/components/:component/measurements/sampleUnit",
      description: "get a list of sample unit measurements",
      urlOptions: [
        "start - RFC3339 formatted timestamp for the start of the window",
        "end - RFC3339 formatted timestamp for the end of the window",
        "size - the number of records to get for the sample"
      ],
      urlParams: [":device - the id of the device", ":component - the key of the component"],
      responseParams: ["repeated UnitMeasurementsForComponent measurements"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/objects/:object/:type/unitMeasurements",
      description:
        "gets the measurements on object can estimate of itself using components that are attached to it",
      urlOptions: [
        "limit - the maximum number of measurements to get",
        "offset - the number of records to skip over before starting retrieval",
        "order - what to order the measurements by",
        "start - RFC3339 formatted timestamp for the start of the window",
        "end - RFC3339 formatted timestamp for the end of the window"
      ],
      urlParams: [":object - the key of the object", ":type - enum value of the type of the object"]
    }
  ];
  const groups: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/groups",
      description: "lis device groups",
      urlOptions: genericListOptions,
      responseParams: ["repeated Group groups", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/groups/:group",
      description: "get a specific device group",
      urlParams: [":group - the id of the group"],
      responseParams: ["GroupSettings settings", "GroupStatus status"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/groups/:group/devices",
      description: "list the devices from a specific group",
      urlOptions: [
        "limit - the number of records to load from the group",
        "offset - the number of records to pass before starting retrieval",
        "order - a key in the device settings to order them by",
        "search - a string to search the data for and only return matching devices",
        "comprehensive - boolean value on whether to load the device with their components as well"
      ],
      urlParams: [":group - the id of the group"],
      responseParams: [
        "repeated Device devices",
        "uint32 next_offset",
        "uint32 total",
        "repeated ComprehensiveDevice comprehensive_devices",
        "repeated HologramDevice hologram_devices"
      ]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/groups",
    //     description: "add a new group",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/groups/:group/devices/:device/add",
    //     description: "add a device to an existing group",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/groups/:group/devices/:device/remove",
    //     description: "remove a device from an existing group",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/groups/:group",
    //     description: "update an existing group",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/groups/:group",
    //     description: "removes an exisitng group",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];
  const transactions: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/transactions",
      description: "list all transactions between two dates",
      urlOptions: ["start - RFC3339 formatted timestamp", "end - RFC3339 formatted timestamp"],
      responseParams: ["repeated Transaction transactions", "uint32 next_offset", "uint32 total"]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/transactions",
    //     description: "create a new transaction",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/transactions/:transaction/revoke",
    //     description: "revoke an existing transaction",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/transactions/:transaction/update",
    //     description: "update an existing transaction",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];

  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for managing API keys
      </Typography>
      <List>
        {apiKeys.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for managing notifications
      </Typography>
      <List>
        {notifications.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for managing notes
      </Typography>
      <List>
        {notes.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for managing map markers
      </Typography>
      <List>
        {mapMarkers.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for managing tasks
      </Typography>
      <List>
        {tasks.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for managing devices
      </Typography>
      <List>
        {devices.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for managing components
      </Typography>
      <List>
        {components.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for managing interactions
      </Typography>
      <List>
        {interactions.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for managing measurements
      </Typography>
      <List>
        {measurements.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for managing groups
      </Typography>
      <List>
        {groups.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for managing transactions
      </Typography>
      <List>
        {transactions.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

import { Box } from "@material-ui/core";
import DisplayDrawer from "common/DisplayDrawer";
import MapMarkerSettings from "Maps/MapMarkerSettings";
import { GrainBag as BagModel } from "models/GrainBag";
import GrainBag from "pages/grainBag";
import { pond } from "protobuf-ts/pond";
import { useGrainBagAPI, useSnackbar } from "providers";
import React, { useEffect, useState } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  selectedBag: string;
  grainBags: Map<string, BagModel>;
  removeMarker: (key: string) => void;
  updateMarker: (key: string, newSettings: pond.GrainBagSettings) => void;
  moveMap: (long: number, lat: number) => void;
}

export default function GrainBagDrawer(props: Props) {
  const { open, onClose, selectedBag, grainBags, removeMarker, updateMarker, moveMap } = props;
  const [bag, setBag] = useState<BagModel>(BagModel.create());
  const [openMarkerSettings, setOpenMarkerSettings] = useState(false);
  const bagAPI = useGrainBagAPI();
  const { openSnack } = useSnackbar();

  useEffect(() => {
    let b = grainBags.get(selectedBag);
    if (b) {
      setBag(b);
    }
  }, [selectedBag, grainBags]);

  const closeDrawer = () => {
    onClose();
  };

  const displayNext = () => {
    let bagArr = Array.from(grainBags.values());
    let index = bagArr.indexOf(bag);
    let found = false;
    do {
      if (index === bagArr.length - 1) {
        index = 0;
      } else {
        index++;
      }
      let nextBag = bagArr[index];
      let location = nextBag.centerLocation();
      if (
        location !== null &&
        location !== undefined &&
        location.longitude !== 0 &&
        location.longitude !== 0
      ) {
        setBag(nextBag);
        moveMap(location.longitude, location.latitude);
        found = true;
      }
    } while (!found);
  };

  const displayPrev = () => {
    let bagArr = Array.from(grainBags.values());
    let index = bagArr.indexOf(bag);
    let found = false;
    do {
      if (index === 0) {
        index = bagArr.length - 1;
      } else {
        index--;
      }
      let nextBag = bagArr[index];
      let location = nextBag.centerLocation();
      if (
        location !== null &&
        location !== undefined &&
        location.longitude !== 0 &&
        location.longitude !== 0
      ) {
        setBag(nextBag);
        moveMap(location.longitude, location.latitude);
        found = true;
      }
    } while (!found);
  };

  const drawerBody = () => {
    return <Box>{bag.key() !== "" && <GrainBag bagKey={bag.key()} mobileView />}</Box>;
  };

  const update = () => {
    setOpenMarkerSettings(true);
  };

  const remove = () => {
    let settings = bag.settings;
    settings.startLocation = undefined;
    settings.endLocation = undefined;
    bagAPI
      .updateGrainBag(bag.key(), bag.name(), settings)
      .then(resp => {
        openSnack("Grain bag location removed");
        removeMarker(bag.key());
      })
      .catch(err => {
        openSnack("Failed to remove grain bag location");
      });
  };

  return (
    <React.Fragment>
      <DisplayDrawer
        open={open}
        onClose={closeDrawer}
        displayNext={displayNext}
        displayPrev={displayPrev}
        title={bag.name()}
        width={"40vw"}
        drawerBody={drawerBody()}
        updateElement={update}
        removeElement={remove}
      />
      <MapMarkerSettings
        close={() => {
          setOpenMarkerSettings(false);
        }}
        open={openMarkerSettings}
        colourControl
        theme={bag.settings.theme ?? pond.ObjectTheme.create()}
        updateObject={newTheme => {
          let settings = bag.settings;
          settings.theme = newTheme;
          bagAPI
            .updateGrainBag(bag.key(), bag.name(), settings)
            .then(resp => {
              openSnack("marker settings updated");
              updateMarker(bag.key(), settings);
            })
            .catch(() => {
              openSnack("failed to update marker settings");
            });
        }}
      />
    </React.Fragment>
  );
}

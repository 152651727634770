import DiseaseLight from "assets/products/Ag/diseaseLight.png";
import DiseaseDark from "assets/products/Ag/diseaseDark.png";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  width: number;
  height: number;
  type?: "light" | "dark";
}

export default function DiseaseIcon(props: Props) {
  const themeType = useThemeType();
  const { width, height, type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? DiseaseLight : DiseaseDark;
    }

    return themeType === "light" ? DiseaseDark : DiseaseLight;
  };

  return <img width={width} height={height} alt="diseaseIcon" src={src()} />;
}

import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { or } from "utils";
import { pondURL } from "./pond";

export interface IHarvestYearAPIContext {
  addHarvestYear: (harvestYear: pond.HarvestYearSettings) => Promise<any>;
  getHarvestYear: (harvestYearId: string) => Promise<any>;
  listHarvestYears: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean
  ) => Promise<AxiosResponse<pond.ListHarvestYearsResponse>>;
  removeHarvestYear: (
    harvestYearId: string
  ) => Promise<AxiosResponse<pond.RemoveHarvestYearResponse>>;
  updateHarvestYear: (
    key: string,
    harvestYear: pond.HarvestYearSettings,
    asRoot?: true
  ) => Promise<AxiosResponse<pond.UpdateHarvestYearResponse>>;
}

export const HarvestYearAPIContext = createContext<IHarvestYearAPIContext>(
  {} as IHarvestYearAPIContext
);

interface Props {}

export default function HarvestYearProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();

  const addHarvestYear = (harvestYear: pond.HarvestYearSettings) => {
    return post(pondURL("/harvestYears"), harvestYear);
  };

  const getHarvestYear = (harvestYearId: string) => {
    return get(pondURL("/harvestYear/" + harvestYearId));
  };

  const removeHarvestYear = (key: string) => {
    return del<pond.RemoveHarvestYearResponse>(pondURL("/harvestYears/" + key));
  };

  const listHarvestYears = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean
  ) => {
    return get<pond.ListHarvestYearsResponse>(
      pondURL(
        "/harvestYears" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + or(order, "asc")) +
          ("&by=" + or(orderBy, "key")) +
          (as ? "&as=" + as : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (search ? "&search=" + search : "")
      )
    );
  };

  const updateHarvestYear = (
    key: string,
    harvestYear: pond.HarvestYearSettings,
    asRoot?: boolean
  ) => {
    return put<pond.UpdateHarvestYearResponse>(
      pondURL("/harvestYears/" + key + (asRoot ? "?asRoot=" + asRoot.toString() : "")),
      harvestYear
    );
  };

  return (
    <HarvestYearAPIContext.Provider
      value={{
        addHarvestYear,
        getHarvestYear,
        removeHarvestYear,
        listHarvestYears,
        updateHarvestYear
      }}>
      {children}
    </HarvestYearAPIContext.Provider>
  );
}

export const useHarvestYearAPI = () => useContext(HarvestYearAPIContext);

import GrainCableDarkIcon from "assets/components/grainCableDark.png";
import GrainCableLightIcon from "assets/components/grainCableLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function CableIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? GrainCableLightIcon : GrainCableDarkIcon;
    }

    return themeType === "light" ? GrainCableDarkIcon : GrainCableLightIcon;
  };

  return <ImgIcon alt="bins" src={src()} />;
}

import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";

export interface ITagAPIContext {
  addTag: (tag: pond.TagSettings) => Promise<any>;
  updateTag: (tagID: string, tag: pond.TagSettings) => Promise<any>;
  removeTag: (tagID: string) => Promise<any>;
  getTag: (tagID: string) => Promise<any>;
  listTags: () => Promise<any>;
}

export const TagAPIContext = createContext<ITagAPIContext>({} as ITagAPIContext);

interface Props {}

export default function TagProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, put, post } = useHTTP();

  const addTag = (tag: pond.TagSettings) => {
    return post(pondURL("/tags"), tag);
  };

  const updateTag = (tagID: string, tag: pond.TagSettings) => {
    return put(pondURL("/tags/" + tagID), tag);
  };

  const removeTag = (tagID: string) => {
    return del(pondURL("/tags/" + tagID));
  };

  const getTag = (tagID: string) => {
    return get(pondURL("/tags/" + tagID));
  };

  const listTags = () => {
    return get(pondURL("/tags"));
  };

  return (
    <TagAPIContext.Provider
      value={{
        addTag,
        updateTag,
        removeTag,
        getTag,
        listTags
      }}>
      {children}
    </TagAPIContext.Provider>
  );
}

export const useTagAPI = () => useContext(TagAPIContext);

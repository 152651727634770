import { Chip, Tooltip } from "@material-ui/core";
import { getStatusHelper } from "pbHelpers/Status";
import React from "react";

interface Props {
  status: string;
  size?: "small" | "medium";
}

export default function StatusChip(props: Props) {
  const { status, size } = props;
  const statusHelper = getStatusHelper(status);
  const icon = statusHelper.icon;
  const description = statusHelper.description;

  return icon !== null ? (
    <Tooltip title="Your most recent changes will be synced to the device as soon as possible">
      <Chip variant="outlined" label={description} icon={icon} size={size} />
    </Tooltip>
  ) : null;
}

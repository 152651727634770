import { isOffline } from "utils/environment";
import DefaultDarkLogo from "assets/whitelabels/darkLogo.png";
import DefaultLightLogo from "assets/whitelabels/lightLogo.png";
import AdapativeAgLogo from "assets/whitelabels/AdaptiveAgriculture/logo.png";
import AdaptiveAgThumbnail from "assets/whitelabels/AdaptiveAgriculture/thumbnail.jpg";
import AdConLogo from "assets/whitelabels/AdaptiveConstruction/AdConLogoWhite.png";
import BXTLightLogo from "assets/whitelabels/BXT/lightLogo.png";
import BXTDarkLogo from "assets/whitelabels/BXT/darkLogo.png";
import AeroGrowDarkLogo from "assets/whitelabels/AeroGrow/darkLogo.png";
import AeroGrowLightLogo from "assets/whitelabels/AeroGrow/lightLogo.png";
import MiVentLightLogo from "assets/whitelabels/MiVent/lightLogo.png";
//import OmniAirLogo from "assets/whitelabels/OmniAir/OmniAirLogo.png";
import MiPCALogo from "assets/whitelabels/OmniAir/MiPCALogo.png";

const protips: string[] = [
  "You can see the latest measurements for a device by starring its components!",
  "You can search for devices based on keywords in their name, description, and many other settings!",
  "You can quickly change the set points for an interaction with the sliders on a component card!",
  "Make sure your devices have the newest features and improvements by enabling automatic upgrades!",
  "Make sure your devices don't miss a measurement by ensuring they have a strong network connection! You can monitor this with the modem component!",
  "Starring your components will show you the latest measurement in all sorts of places so you can keep a close eye on what you care about!",
  "Want to share what your device is doing with friends or family? Create a shareable link and send it to them!",
  "Keep an eye on your data usage! Only set components to report as often as you need.",
  "Changes pending? Make sure your device is checking in every 10 minutes or so.",
  "Our development team is fully remote capable, so you can be sure we're working hard even during a pandemic.",
  "Our devices are proudly designed and assembled in Canada.",
  "Want to receive text messages about your devices? Update your phone number and enable SMS notifications!"
];

interface WhiteLabel {
  name: any;
  primaryColour: any; //must be a MATERIAL UI colour, used for various UI elements
  secondaryColour: any; //must be a MATERIAL UI colour, used for a few UI elements (less importance)
  signatureColour: any; //hex or RGB
  signatureAccentColour: any; //hex or RGB
  auth0ClientId: any;
  redirectOnLogout: boolean;
  logoutRedirectTarget: string;
  darkLogo: any;
  lightLogo: any;
  transparentLogoBG: boolean; //determines whether the background of the logo should be transparent or not
  blacklist: string[];
  hotjarID?: string;
  docs: string;
  protips: string[];
  tutorialPlaylistID?: string;
  thumbnail?: string;
  tutorialFiles?: { name: string; url: string }[];
}

const DEFAULT_WHITELABEL: WhiteLabel = {
  name: process.env.REACT_APP_WEBSITE_TITLE,
  primaryColour: process.env.REACT_APP_PRIMARY_COLOUR,
  secondaryColour: process.env.REACT_APP_SECONDARY_COLOUR,
  signatureColour: process.env.REACT_APP_SIGNATURE_COLOUR,
  signatureAccentColour: "#fff",
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectOnLogout: false,
  logoutRedirectTarget: "",
  darkLogo: DefaultDarkLogo,
  lightLogo: DefaultLightLogo,
  transparentLogoBG: false,
  blacklist: [],
  hotjarID: process.env.REACT_APP_HOTJAR_ID_BXT,
  docs: "Platform",
  protips: protips,
  tutorialPlaylistID: ""
};

const STAGING_WHITELABEL: WhiteLabel = {
  name: process.env.REACT_APP_WEBSITE_TITLE,
  primaryColour: process.env.REACT_APP_PRIMARY_COLOUR,
  secondaryColour: process.env.REACT_APP_SECONDARY_COLOUR,
  signatureColour: process.env.REACT_APP_SIGNATURE_COLOUR,
  signatureAccentColour: "#fff",
  auth0ClientId: process.env.REACT_APP_AUTH0_STAGING_CLIENT_ID,
  redirectOnLogout: false,
  logoutRedirectTarget: "",
  darkLogo: DefaultDarkLogo,
  lightLogo: DefaultLightLogo,
  transparentLogoBG: false,
  blacklist: [],
  hotjarID: process.env.REACT_APP_HOTJAR_ID_BXT,
  docs: "Platform",
  protips: protips,
  tutorialPlaylistID: ""
};

const BXT_WHITE_LABEL: WhiteLabel = {
  name: "Brand X Technologies",
  primaryColour: "blue",
  secondaryColour: "yellow",
  signatureColour: "#005bb0",
  signatureAccentColour: "#fff",
  auth0ClientId: process.env.REACT_APP_AUTH0_STAGING_CLIENT_ID,
  redirectOnLogout: false,
  logoutRedirectTarget: "",
  darkLogo: BXTDarkLogo,
  lightLogo: BXTLightLogo,
  transparentLogoBG: false,
  blacklist: [],
  hotjarID: process.env.REACT_APP_HOTJAR_ID_BXT,
  docs: "Platform",
  protips: protips
};

export function isBXT(): boolean {
  return getName() === "Brand X Technologies";
}

const ADAPTIVE_AGRICULTURE_WHITE_LABEL: WhiteLabel = {
  name: "Adaptive Agriculture",
  primaryColour: "green",
  secondaryColour: "yellow",
  signatureColour: "#272727",
  signatureAccentColour: "#fff",
  auth0ClientId: process.env.REACT_APP_AUTH0_ADAPTIVE_AGRICULTURE_CLIENT_ID,
  redirectOnLogout: true,
  logoutRedirectTarget: "https://adaptiveagriculture.ca",
  darkLogo: AdapativeAgLogo,
  lightLogo: AdapativeAgLogo,
  transparentLogoBG: true,
  blacklist: ["cost"],
  hotjarID: process.env.REACT_APP_HOTJAR_ID_ADAPTIVE_AGRICULTURE,
  docs: "AdaptiveAg",
  protips: protips.concat([]),
  tutorialPlaylistID: "PLpLmJnI66Jfl5FXME31ckGam-sD8gB1s2",
  thumbnail: AdaptiveAgThumbnail,
  tutorialFiles: [
    {
      name: "Bindapt+",
      url: "https://adaptiveagriculture.ca/wp-content/uploads/2023/08/Bindapt-Set-Up-Guide.pdf"
    },
    {
      name: "Adapter Plate",
      url:
        "https://adaptiveagriculture.ca/wp-content/uploads/2023/08/Bindapt-Adapter-Plate-Set-Up-Guide.pdf"
    }
  ]
};

export function IsAdaptiveAgriculture(): boolean {
  return (
    getName() === "Adaptive Agriculture" ||
    window.location.origin.includes("staging") ||
    window.location.origin.includes("localhost")
  );
}

const AEROGROW_WHITE_LABEL: WhiteLabel = {
  name: "AeroGrow",
  primaryColour: "green",
  secondaryColour: "cyan",
  signatureColour: "#fff",
  signatureAccentColour: "#000",
  auth0ClientId: process.env.REACT_APP_AUTH0_AEROGROW_CLIENT_ID,
  redirectOnLogout: true,
  logoutRedirectTarget: "https://www.aerogrowmanufacturing.com",
  darkLogo: AeroGrowDarkLogo,
  lightLogo: AeroGrowLightLogo,
  transparentLogoBG: true,
  blacklist: [],
  hotjarID: process.env.REACT_APP_HOTJAR_ID_AEROGROW,
  docs: "Platform",
  protips: protips
};

export function IsMiVent(): boolean {
  return (
    getName() === "MiVent" ||
    window.location.origin.includes("staging") ||
    window.location.origin.includes("localhost")
  );
}

const MIVENT_WHITE_LABEL: WhiteLabel = {
  name: "MiVent",
  primaryColour: "green",
  secondaryColour: "yellow",
  signatureColour: "#272727",
  signatureAccentColour: "#fff",
  auth0ClientId: process.env.REACT_APP_AUTH0_MIVENT_CLIENT_ID,
  redirectOnLogout: true,
  logoutRedirectTarget: "https://mivent.ca",
  darkLogo: MiVentLightLogo,
  lightLogo: MiVentLightLogo,
  transparentLogoBG: true,
  blacklist: ["cost"],
  hotjarID: process.env.REACT_APP_HOTJAR_ID_MIVENT,
  docs: "Platform",
  protips: protips.concat([])
};

const ADAPTIVE_CONSTRUCTION_WHITE_LABEL: WhiteLabel = {
  name: "Adaptive Construction",
  primaryColour: "blue",
  secondaryColour: "blue",
  signatureColour: "#272727",
  signatureAccentColour: "#fff",
  auth0ClientId: process.env.REACT_APP_AUTH0_ADAPTIVE_CONSTRUCTION_CLIENT_ID,
  redirectOnLogout: true,
  logoutRedirectTarget: "https://adaptiveconstruction.ca",
  darkLogo: AdConLogo,
  lightLogo: AdConLogo,
  transparentLogoBG: true,
  blacklist: ["cost"],
  hotjarID: process.env.REACT_APP_HOTJAR_ID_ADAPTIVE_CONSTRUCTION,
  docs: "AdaptiveConstruction",
  protips: protips.concat([])
};

export function IsAdCon(): boolean {
  return (
    getName() === "Adaptive Construction" ||
    window.location.origin.includes("staging") ||
    window.location.origin.includes("localhost")
  );
}

const OMNIAIR_WHITE_LABEL: WhiteLabel = {
  name: "OmniAir",
  primaryColour: "#004f9b",
  secondaryColour: "yellow",
  signatureColour: "#272727",
  signatureAccentColour: "#fff",
  auth0ClientId: process.env.REACT_APP_AUTH0_OMNIAIR_CLIENT_ID,
  redirectOnLogout: true,
  logoutRedirectTarget: "https://omniairsystems.com",
  darkLogo: MiPCALogo,
  lightLogo: MiPCALogo,
  transparentLogoBG: true,
  blacklist: ["cost"],
  //hotjarID: process.env.REACT_APP_HOTJAR_ID_OMNIAIR, testing what happens if this is excluded
  docs: "OmniAir",
  protips: protips.concat([])
};

export function IsOmniAir(): boolean {
  return (
    getName() === "OmniAir" ||
    window.location.origin.includes("staging") ||
    window.location.origin.includes("localhost")
  );
}

const whitelabels = new Map<string, WhiteLabel>([
  ["adaptiveag", ADAPTIVE_AGRICULTURE_WHITE_LABEL],
  ["adaptiveagriculture", ADAPTIVE_AGRICULTURE_WHITE_LABEL],
  ["brandxducks", BXT_WHITE_LABEL],
  ["brandxtech", BXT_WHITE_LABEL],
  ["aerogrowmanufacturing", AEROGROW_WHITE_LABEL],
  ["localhost", ADAPTIVE_AGRICULTURE_WHITE_LABEL],
  ["staging", STAGING_WHITELABEL],
  ["10.0", ADAPTIVE_CONSTRUCTION_WHITE_LABEL],
  ["mivent", MIVENT_WHITE_LABEL],
  ["adaptiveconstruction", ADAPTIVE_CONSTRUCTION_WHITE_LABEL],
  ["omniair", OMNIAIR_WHITE_LABEL]
]);

export function getWhitelabel(): WhiteLabel {
  if (isOffline()) {
    return DEFAULT_WHITELABEL;
  }

  const hostname = window.location.hostname;
  if (window.location.origin.includes("staging") || process.env.REACT_APP_IS_STAGING) {
    return STAGING_WHITELABEL;
  }
  const whiteLabelKeys = Array.from(whitelabels.keys());
  for (var i = 0; i < whiteLabelKeys.length; i++) {
    let key = whiteLabelKeys[i];
    if (hostname.includes(key)) {
      return whitelabels.get(key) as WhiteLabel;
    }
  }
  return DEFAULT_WHITELABEL;
}

export function getPrimaryColour(): any {
  return getWhitelabel().primaryColour;
}

export function getSecondaryColour(): any {
  return getWhitelabel().secondaryColour;
}

export function getSignatureColour(): any {
  return getWhitelabel().signatureColour;
}

export function getSignatureAccentColour(): any {
  return getWhitelabel().signatureAccentColour;
}

export function getAuth0ClientId(): any {
  return getWhitelabel().auth0ClientId;
}

export function redirectOnLogout(): boolean {
  return getWhitelabel().redirectOnLogout;
}

export function getLogoutRedirectTarget(): string {
  return getWhitelabel().logoutRedirectTarget;
}

export function getDarkLogo(): any {
  return getWhitelabel().darkLogo;
}

export function getLightLogo(): any {
  return getWhitelabel().lightLogo;
}

export function hideLogo(): boolean {
  if (isOffline()) {
    return false;
  }

  return getWhitelabel().name === "";
}

export function hasTransparentLogoBG(): any {
  return getWhitelabel().transparentLogoBG;
}

export function getName(): string {
  return getWhitelabel().name;
}

export function isBlacklisted(context: string): boolean {
  return getWhitelabel().blacklist.includes(context);
}

export function getHotjarID(): string | undefined {
  return getWhitelabel().hotjarID;
}

export function hasTutorialPlaylist(): boolean {
  return getWhitelabel().tutorialPlaylistID !== undefined;
}

export function getTutorialPlaylistID(): string {
  const id = getWhitelabel().tutorialPlaylistID;
  return id ? id : "";
}

export function hasThumbnail(): boolean {
  return getWhitelabel().thumbnail !== undefined;
}

export function getThumbnail(): string {
  const thumbnail = getWhitelabel().thumbnail;
  return thumbnail ? thumbnail : "";
}

export function getTutorialFiles() {
  const files = getWhitelabel().tutorialFiles;
  return files ? files : [];
}

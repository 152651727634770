import React, { useContext, useReducer } from "react";
import { Actions, newContext } from "./generic";
import { User } from "models";

export interface UsersState {
  me: User;
}
export const UsersContext = newContext<UsersState>();

export const useUsers = () => useContext(UsersContext);

export const UsersProvider = (props: { children?: any }) => {
  const reducer = (state: UsersState, action: Actions<UsersState>): UsersState => {
    return { ...state, [action.key]: action.value };
  };
  return (
    <UsersContext.Provider value={useReducer(reducer, {} as UsersState)}>
      {props.children}
    </UsersContext.Provider>
  );
};

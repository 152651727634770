import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as Sentry from "@sentry/react";
import App from "app/App";
import { LoadLocale } from "common/time/locale";
import "fontsource-roboto/300.css";
import "fontsource-roboto/400.css";
import "fontsource-roboto/500.css";
import "fontsource-roboto/700.css";
import "typeface-open-sans/index.css";
import React from "react";
import ReactDOM from "react-dom";
import { register } from "services/serviceWorker";
import { isProduction } from "utils";

if (isProduction()) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    debug: false,
    environment: "production"
  });
}

LoadLocale("en");

ReactDOM.render(<App />, document.getElementById("root"));
register();

import {
  ComponentTypeExtension,
  Summary,
  simpleSummaries,
  simpleMeasurements,
  unitMeasurementSummaries,
  AreaChartData,
  GraphFilters,
  simpleAreaChartData,
  simpleLineChartData,
  LineChartData
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import { convertedUnitMeasurement } from "models/UnitMeasurement";
import { pond } from "protobuf-ts/pond";

export function Capacitance(subtype: number = 0): ComponentTypeExtension {
  let capacitance = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_CAPACITANCE,
    quack.ComponentType.COMPONENT_TYPE_CAPACITANCE,
    subtype
  );
  let addressTypes = [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY];
  if (subtype === quack.CapacitanceSubtype.CAPACITANCE_SUBTYPE_FDC) {
    addressTypes = [quack.AddressType.ADDRESS_TYPE_I2C];
  }
  return {
    type: quack.ComponentType.COMPONENT_TYPE_CAPACITANCE,
    subtypes: [
      {
        key: quack.CapacitanceSubtype.CAPACITANCE_SUBTYPE_NONE,
        value: "CAPACITANCE_SUBTYPE_NONE",
        friendlyName: "Capacitance"
      },
      {
        key: quack.CapacitanceSubtype.CAPACITANCE_SUBTYPE_FDC,
        value: "CAPACITANCE_SUBTYPE_FDC",
        friendlyName: "FDC"
      }
    ],
    friendlyName: "Capacitance",
    description: "Measures capacitance",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: addressTypes,
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(capacitance),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, capacitance);
    },
    unitMeasurementSummary: (
      measurements: convertedUnitMeasurement,
      excludedNodes?: number[]
    ): Summary[] => {
      return unitMeasurementSummaries(
        measurements,
        quack.ComponentType.COMPONENT_TYPE_CAPACITANCE,
        subtype,
        excludedNodes
      );
    },
    areaChartData: (
      measurement: pond.UnitMeasurementsForComponent,
      smoothingAverages?: number,
      filters?: GraphFilters
    ): AreaChartData => {
      return simpleAreaChartData(measurement, smoothingAverages, filters);
    },
    lineChartData: (
      measurement: pond.UnitMeasurementsForComponent,
      smoothingAverages?: number,
      filters?: GraphFilters
    ): LineChartData => {
      return simpleLineChartData(
        quack.ComponentType.COMPONENT_TYPE_CAPACITANCE,
        measurement,
        smoothingAverages,
        filters
      );
    },
    minMeasurementPeriodMs: 1000
  };
}

import { AddressTypeExtension } from "pbHelpers/AddressType";
import { quack } from "protobuf-ts/quack";

export const SPI: AddressTypeExtension = {
  addressType: quack.AddressType.ADDRESS_TYPE_SPI,
  friendlyName: "SPI", //Serial Peripheral Interface
  humanReadableAddress: function(componentType: quack.ComponentType, address: number): string {
    const offsets = new Map<quack.ComponentType, number>([
      [quack.ComponentType.COMPONENT_TYPE_VIBRATION_CHAIN, 0x0]
    ]);

    const offset = offsets.get(componentType);
    if (!offset) {
      return "0";
    }

    return (address - offset).toString();
  },
  singular: false
};

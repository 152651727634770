import { useSnackbar, useStripeAPI } from "providers";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useTheme
} from "@material-ui/core";

interface Props {
  customer: any;
  loading: boolean;
}

export default function StripeCard(props: Props) {
  const { customer, loading } = props;

  const [name, setName] = useState(customer.name ? customer.name : "");
  const [city, setCity] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [postal, setPostal] = useState("");
  const [updating, setUpdating] = useState(false);

  const stripeAPI = useStripeAPI();
  const theme = useTheme();
  const snackbar = useSnackbar();

  useEffect(() => {
    setName(customer.name);
    if (customer.address) {
      setCity(customer.address.city);
      setAddress1(customer.address.line1);
      setAddress2(customer.address.line2);
      setCountry(customer.address.country);
      setPostal(customer.address.postal_code);
      setState(customer.address.state);
    }
  }, [customer]);

  const submit = () => {
    setUpdating(true);
    stripeAPI
      .updateCustomer("", city, country, address1, address2, postal, state)
      .then(resp => {
        console.log(resp.data);
        if (resp.data === "success") snackbar.success("Customer data updated successfully");
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  if (loading)
    return (
      <Grid container justify="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );

  return (
    <React.Fragment>
      <Box padding={1}>
        <Typography style={{ marginBottom: theme.spacing(0.5) }}>Cardholder Information</Typography>

        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            <Grid container direction="row" spacing={1}>
              <Grid item xs>
                <TextField
                  placeholder="Customer Name"
                  variant="outlined"
                  value={name}
                  onChange={e => setName(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <br />

          <Grid item xs>
            <Grid container direction="row" spacing={1}>
              <Grid item xs>
                <TextField
                  placeholder="Customer City"
                  variant="outlined"
                  value={city}
                  onChange={e => setCity(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  placeholder="Postal Code"
                  variant="outlined"
                  value={postal}
                  onChange={e => setPostal(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={8}>
                <TextField
                  placeholder="Address Line 1"
                  variant="outlined"
                  value={address1}
                  onChange={e => setAddress1(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  placeholder="Address Line 2"
                  variant="outlined"
                  value={address2}
                  onChange={e => setAddress2(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={8}>
                <TextField
                  placeholder="Country"
                  variant="outlined"
                  value={country}
                  onChange={e => setCountry(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  placeholder="State/Province"
                  variant="outlined"
                  value={state}
                  onChange={e => setState(e.currentTarget.value)}
                  color="primary"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="flex-end">
            <Box marginTop={theme.spacing(0.25)}>
              {updating ? (
                <CircularProgress />
              ) : (
                <Button type="submit" color="primary" onClick={submit}>
                  Submit
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

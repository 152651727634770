import { Box, Card, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { MatchParams } from "navigation/Routes";
import DiffHistory, { ListResult, Record } from "common/DiffHistory";
import { useSnackbar, useMobile } from "hooks";
import { useBinAPI } from "providers";
import { Bin } from "models";
import { TranslateKey, TranslateValue } from "pbHelpers/Bin";
import { pond } from "protobuf-ts/pond";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { or } from "utils/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gutter: {
      maxHeight: "480px",
      //display: "flex",
      flexDirection: "column"
    },
    gutterMobile: {
      height: "auto"
    },
    title: {
      padding: theme.spacing(1),
      fontWeight: 600
    }
  })
);

interface Props {
  binID?: string;
  drawer?: boolean;
}

export default function BinHistory(props: Props) {
  const params = useParams<MatchParams>();
  const classes = useStyles();
  const { openSnack } = useSnackbar();
  const binAPI = useBinAPI();
  const binID = props.binID ?? params.binID;
  const [bin, setBin] = useState(Bin.any({ settings: { binId: binID } }));
  const binName = bin.name();
  const isMobile = useMobile();

  useEffect(() => {
    binAPI
      .getBin(binID)
      .then((response: any) => {
        setBin(Bin.any(response.data));
      })
      .catch(err => {
        openSnack("There was a problem loading your bin");
      });
  }, [binAPI, binID, openSnack]);

  let list = (limit: number, offset: number): Promise<ListResult> => {
    return new Promise(resolve => {
      binAPI
        .listHistory(binID, limit, offset)
        .then((res: any) => {
          let records: Record[] = or(res.data.history, []).map((record: any) => {
            return {
              timestamp: or(record.timestamp, ""),
              user: or(record.user, ""),
              data: or(record.settings, {}),
              status: or(record.progress, "Unknown")
            } as Record;
          });
          resolve({
            records: records,
            total: or(res.data.total, 0),
            offset: or(res.data.nextOffset, 0)
          });
        })
        .catch((err: any) => {
          resolve({
            records: [] as Record[],
            total: 0,
            offset: 0
          });
        });
    });
  };

  let translateKey = (key: keyof any): string => {
    return TranslateKey(key as keyof pond.BinSettings);
  };

  let translateValue = (key: keyof any, obj: any): string => {
    return TranslateValue(key as keyof pond.BinSettings, pond.BinSettings.fromObject(obj));
  };

  return (
    <Card className={isMobile || props.drawer ? classes.gutterMobile : classes.gutter}>
      <Typography variant="subtitle2" color="textPrimary" className={classes.title}>
        {binName} History
      </Typography>
      <Box style={{ overflowY: "scroll", flexShrink: 2 }}>
        <DiffHistory
          name={binName}
          kind="bin"
          list={list}
          translateKey={translateKey}
          translateValue={translateValue}
          cellStyle={{ margin: "0px", padding: "0.5rem" }}
          showTitle={false}
          headerStyle={{ margin: "0px" }}
          drawer={props.drawer}
        />
      </Box>
    </Card>
  );
}

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  TextField
} from "@material-ui/core";
import ColourPicker from "common/ColourPicker";
import ResponsiveDialog from "common/ResponsiveDialog";
import { pond } from "protobuf-ts/pond";
import React, { useEffect, useState } from "react";

interface Props {
  open: boolean;
  close: () => void;
  theme: pond.ObjectTheme;
  sizeControl?: boolean;
  currentSize?: number;
  colourControl?: boolean;
  updateObject: (newTheme: pond.ObjectTheme) => void;
}

export default function MapMarkerSettings(props: Props) {
  const { theme, sizeControl, colourControl, updateObject, currentSize } = props;
  //const [objectSettings, setObjectSettings] = useState(props.objectSettings);
  const [newColour, setNewColour] = useState("");
  const [newSize, setNewSize] = useState(50);
  const markerMin = 40;
  const markerMax = 125;

  useEffect(() => {
    if (currentSize) {
      setNewSize(currentSize);
    } else {
      setNewSize(50);
    }
  }, [currentSize]);

  const reSize = (val: number) => {
    let size = val;
    if (val > markerMax) {
      size = markerMax;
    }
    if (val < markerMin) {
      size = markerMin;
    }
    setNewSize(size);
  };

  const update = () => {
    let newTheme = theme;
    newTheme.color = newColour;
    newTheme.height = newSize;
    newTheme.width = newSize;
    updateObject(newTheme);
  };

  return (
    <ResponsiveDialog open={props.open} onClose={props.close}>
      <DialogTitle>Change Marker Settings</DialogTitle>
      <DialogContent>
        {sizeControl && (
          <Box paddingTop={1.5}>
            Marker Size
            <Slider
              value={newSize}
              min={markerMin}
              max={markerMax}
              onChange={(e, val) => reSize(val as number)}
              valueLabelDisplay="auto"
            />
            <TextField type="number" value={newSize} onChange={e => reSize(+e.target.value)} />
          </Box>
        )}
        {colourControl && (
          <Box>
            Marker Colour
            <ColourPicker colour={newColour} onChange={color => setNewColour(color)} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "red" }} onClick={props.close}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            update();
            props.close();
          }}>
          Update Marker
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import { useInteractionsAPI, useSnackbar } from "hooks";
import { Device, Interaction } from "models";
import React from "react";

interface Props {
  device: Device;
  interaction: Interaction;
  isDialogOpen: boolean;
  closeDialogCallback: Function;
  refreshCallback: Function;
}

export default function RemoveInteraction(props: Props) {
  const { device, interaction, isDialogOpen, closeDialogCallback, refreshCallback } = props;
  const interactionsAPI = useInteractionsAPI();
  const { success, error, warning } = useSnackbar();

  const closeDialog = () => {
    closeDialogCallback();
  };

  const submit = () => {
    interactionsAPI
      .removeInteraction(device.id(), interaction.key())
      .then((response: any) => {
        success("Interaction was successfully removed");
        closeDialog();
        refreshCallback();
      })
      .catch((err: any) => {
        err ? warning(err) : error("Error occured when removing a interaction");
        closeDialog();
      });
  };

  return (
    <Dialog
      open={isDialogOpen}
      fullWidth
      onClose={closeDialog}
      aria-labelledby="remove-interaction-label"
      aria-describedby="remove-interaction-description">
      <DialogTitle id="remove-interaction-title">
        Remove Interaction
        <Typography variant="body2" color="textSecondary">
          {device.name()}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography color="textSecondary" align="left" variant="subtitle1">
          Are you sure you want to remove this interaction?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          No
        </Button>
        <Button onClick={submit} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Converts degrees to radians
 * @param angle angle in degrees
 * @returns angle in radians
 */
export function DegreesToRadians(angle: number) {
  return angle * (Math.PI / 180);
}

/**
 * Converts radians to degrees
 * @param angle angle in radians
 * @returns angle in degrees
 */
export function RadiansToDegrees(angle: number) {
  return angle * (180 / Math.PI);
}

/**
 * Takes in the one side of a right triangle its adjacent angle to determine the length of the opposite line
 * @param length the length of the known side
 * @param angle in degrees of the angle adjacent to the know side
 * @returns the length of the line opposite the angle
 */
export function TriangleOppositeLength(length: number, angle: number) {
  return length * Math.tan(DegreesToRadians(angle));
}

/**
 * calculates the cubic volume of a cone, NOTE: radius and height must have the same units
 * @param radius the radius of the base of the cone
 * @param height the height at the peak of the cone
 * @returns the cubic volume
 */
export function ConeVolume(radius: number, height: number) {
  return Math.PI * Math.pow(radius, 2) * (height / 3);
}

/**
 * calculates the cubic volume of a cylinder, NOTE: radius and height must have the same units
 * @param radius the radius of the cylinder
 * @param height the height of the cylinder
 * @returns the cubic volume
 */
export function CylinderVolume(radius: number, height: number) {
  return Math.PI * Math.pow(radius, 2) * height;
}

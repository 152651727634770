import React from "react";
import { TwitterPicker } from "react-color";
import AutoSizer from "react-virtualized-auto-sizer";

interface Props {
  colour?: string;
  onChange: (colour: string) => void;
}

export default function ColourPicker(props: Props) {
  const { colour, onChange } = props;

  return (
    <AutoSizer disableHeight>
      {({ width }: any) => (
        <TwitterPicker
          color={colour}
          onChangeComplete={colour => onChange(colour.hex)}
          width={width}
          triangle="hide"
          colors={[
            "#f44336",
            "#e91e63",
            "#9c27b0",
            "#673ab7",
            "#3f51b5",
            "#2196f3",
            "#03a9f4",
            "#00bcd4",
            "#009688",
            "#4caf50",
            "#8bc34a",
            "#cddc39",
            "#ffeb3b",
            "#ffc107",
            "#ff9800",
            "#ff5722"
          ]}
        />
      )}
    </AutoSizer>
  );
}

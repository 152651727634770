import { pond } from "protobuf-ts/pond";
import { Option } from "common/SearchSelect";
export interface InsulationGrade {
  grade: string;
  thermalResistance: number;
}
export interface DuctExtension {
  name: string;
  frictionFactor: number;
  thermalConductivity: number;
  thermalResistance: number;
}

const defaultDuct: DuctExtension = {
  name: "",
  frictionFactor: 0.0032,
  thermalResistance: 9.343,
  thermalConductivity: 0.036
};

export const DuctExtensions: Map<pond.DuctType, DuctExtension> = new Map([
  [pond.DuctType.DUCT_TYPE_INVALID, defaultDuct],
  [pond.DuctType.DUCT_TYPE_NONE, defaultDuct],
  [
    pond.DuctType.DUCT_TYPE_LAYFLAT,
    {
      name: "AvroDuct Layflat",
      frictionFactor: 0.0032,
      thermalResistance: 9.343,
      thermalConductivity: 0.036
    }
  ],
  [
    pond.DuctType.DUCT_TYPE_SPIRAL,
    {
      name: "AvroDuct Spiral",
      frictionFactor: 0.0065,
      thermalResistance: 9.343,
      thermalConductivity: 0.036
    }
  ],
  [
    pond.DuctType.DUCT_TYPE_AVROLITE,
    {
      name: "Avrolite",
      frictionFactor: 0.0032,
      thermalResistance: 10.132,
      thermalConductivity: 0.036
    }
  ],
  [
    pond.DuctType.DUCT_TYPE_AVROTUBE,
    {
      name: "AvroTube",
      frictionFactor: 0.0032,
      thermalResistance: 9.343,
      thermalConductivity: 0.036
    }
  ]
]);

export default function DuctDescriber(type: pond.DuctType): DuctExtension {
  let describer = DuctExtensions.get(type);
  //console.log(describer)
  if (describer?.name === "None") {
    //console.trace()
  }
  return describer ? describer : defaultDuct;
}

export const ToDuctOption = (ductType: pond.DuctType): Option => {
  let duct = DuctDescriber(ductType);
  return {
    value: ductType,
    label: duct.name
  } as Option;
};

export function DuctOptions(): Option[] {
  let options: Option[] = [];
  Object.values(pond.DuctType).forEach(ductType => {
    if (
      typeof ductType !== "string" &&
      ductType !== pond.DuctType.DUCT_TYPE_INVALID &&
      ductType !== pond.DuctType.DUCT_TYPE_NONE
    ) {
      options.push(ToDuctOption(ductType));
    }
  });
  return options;
}

export function FindDuctType(tRes: number, tCond: number, friction: number): pond.DuctType {
  let ductType = pond.DuctType.DUCT_TYPE_NONE;
  DuctExtensions.forEach((duct, type) => {
    if (
      tRes === duct.thermalResistance &&
      tCond === duct.thermalConductivity &&
      friction === duct.frictionFactor
    ) {
      ductType = type;
    }
  });
  return ductType;
}

import { Fab, Theme, createStyles, makeStyles } from "@material-ui/core";
import Icon from "assets/products/Aviation/AddPlaneIconBlack.png";
import { ImgIcon } from "common/ImgIcon";
import { useMobile } from "hooks";
import React from "react";

interface Props {
  onClick: () => void;
  pulse: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
  const colour = theme.palette.primary.main;
  return createStyles({
    "@keyframes pulsate": {
      to: {
        boxShadow: "0 0 0 16px" + colour + "00"
      }
    },
    fab: {
      background: colour,
      "&:hover": {
        background: colour
      },
      "&:focus": {
        background: colour
      },
      position: "fixed",
      bottom: theme.spacing(8), //for mobile navigator
      right: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        bottom: theme.spacing(2)
      }
    },
    pulse: {
      boxShadow: "0 0 0 0 " + colour + "75",
      animation: "$pulsate 1.75s infinite cubic-bezier(0.66, 0.33, 0, 1)"
    }
  });
});

export default function AddFab(props: Props) {
  const { onClick, pulse } = props;
  const classes = useStyles();
  const isMobile = useMobile();

  const pulseString = pulse ? classes.pulse : "";
  const classString = classes.fab + " " + pulseString;

  return (
    <Fab
      onClick={onClick}
      aria-label="Add New Gate"
      className={classString}
      size={isMobile ? "medium" : "large"}>
      <ImgIcon alt="Add New Gate" src={Icon} />
    </Fab>
  );
}

import { Grid, List, ListItem, Typography } from "@material-ui/core";
import React from "react";

export interface EndPointData {
  requestType: "GET" | "POST" | "PUT" | "DEL";
  url: string;
  description: string;
  urlParams?: string[];
  urlOptions?: string[];
  requestBody?: string;
  responseParams?: string[];
}

interface Props {
  endpoint: EndPointData;
}

export default function EndpointDisplay(props: Props) {
  const { endpoint } = props;
  return (
    <Grid container spacing={2} style={{ border: "2px solid white" }}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {endpoint.requestType} {endpoint.url}
        </Typography>
        <Typography>{endpoint.description}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" style={{ fontWeight: 650 }}>
          Request
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" style={{ fontWeight: 650 }}>
          Response
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {endpoint.urlParams && (
          <React.Fragment>
            <Typography>URL Parameters</Typography>
            <List>
              {endpoint.urlParams.map((param, i) => (
                <ListItem key={i}>{param}</ListItem>
              ))}
            </List>
          </React.Fragment>
        )}
        {endpoint.urlOptions && (
          <React.Fragment>
            <Typography>URL Options</Typography>
            <List>
              {endpoint.urlOptions.map((op, i) => (
                <ListItem key={i}>{op}</ListItem>
              ))}
            </List>
          </React.Fragment>
        )}
        {endpoint.requestBody && (
          <React.Fragment>
            <Typography>Request Body</Typography>
            <List>
              <ListItem key={"body"}>{endpoint.requestBody}</ListItem>
            </List>
          </React.Fragment>
        )}
      </Grid>
      <Grid item xs={6}>
        {"{"}
        {endpoint.responseParams && (
          <List>
            {endpoint.responseParams.map((op, i) => (
              <ListItem key={i}>{op}</ListItem>
            ))}
          </List>
        )}
        {"}"}
      </Grid>
    </Grid>
  );
}

import { Box, makeStyles, Theme, createStyles } from "@material-ui/core";
import React from "react";

const GRAIN_COLOUR = "#b5a962";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    bag: {
      fill: theme.palette.type === "light" ? "#373737" : "#292929",
      fillOpacity: 1
    },
    inventory: {
      fill: GRAIN_COLOUR,
      fillOpacity: 0.5
    }
  });
});

interface Props {
  fillPercent: number;
  height: number;
}

export default function GrainBagSVG(props: Props) {
  const { fillPercent, height } = props;
  const classes = useStyles();

  const bagFillSVG = () => {
    const topY = 173;
    const bottomY = 235;
    const fillLevel = ((bottomY - topY) * (100 - fillPercent)) / 100 + topY;
    return (
      <g>
        {/* starts drawing top right corner */}
        <path
          className={classes.inventory}
          d={
            "M 193.27593," +
            fillLevel + //increase the y value to make the drawing start lower
            //this command makes the top curve
            "c -0.23147,-0.43237 -0.46296,-0.84552 -0.71457,-1.24905 -2.15378,-2.45005 -6.6425,-4.13146 -11.83573,-4.13146 -5.19323,0 -9.68195,1.68141 -11.83574,4.12186 -0.25161,0.40353 -0.48309,0.82628 -0.71457,1.24904 " +
            //this command draws down from the top left corner after finishing the first curve
            "V 235 " +
            //these 3 commands make the bottom curve
            "c 0.7045,0.21137 1.44927,0.41314 2.22423,0.58609 0,0 0,0 0.0101,0 0.11071,0.01 0.20128,0.0192 0.2516,0.0288 0.27175,0.0769 0.55355,0.14412 0.84542,0.20177 0.0503,0.01 0.11071,0.0192 0.16103,0.0288 0.26167,0.048 0.53341,0.0961 0.80515,0.14412 0.0302,0 0.0503,0 0.0704,0.01 2.06321,0.31707 4.72021,0.43236 8.1421,0.43236 " +
            "h 0.0906 " +
            "c 3.41183,0 6.06883,-0.11529 8.1421,-0.43236 0.0201,0 0.0503,0 0.0704,-0.01 0.27174,-0.048 0.54348,-0.0961 0.80515,-0.14412 0.0503,0 0.10065,-0.0192 0.16104,-0.0288 0.30193,-0.0576 0.58373,-0.13451 0.84541,-0.20177 0.0503,0 0.13083,-0.0192 0.25161,-0.0288 0,0 0,0 0.0201,0 0.77497,-0.17295 1.50967,-0.37472 2.21417,-0.58609 " +
            //this command connects where the line is at and the starting point
            "z"
          }
          id="path275"
        />
      </g>
    );
  };

  return (
    <Box height={height} width={height * 0.5}>
      <svg width="100%" height="100%" viewBox="0 0 10 20">
        <g id="layer1" transform="translate(-104.92152,-72.241116)">
          <g id="g121" transform="matrix(0.26458333,0,0,0.26458333,62.153011,29.46019)">
            {bagFillSVG()}
            <g id="g339" transform="matrix(0.93700864,0,0,0.92692889,161.64576,161.68242)">
              {/* this path draws the bag */}
              <path
                className={classes.bag}
                d="M 38.95,75.7 C 38.57,74.77 38.45,73.76 38.63,72.77 39.06,70.37 39.12,67.29 39.12,63.36 39.12,56.26 37.16,13.93 33.97,9.14 33.19,7.97 32.9,6.54 33.21,5.17 33.38,4.45 33.47,3.66 33.51,2.84 33.54,2.26 32.97,1.84 32.42,2.02 30.3,2.7 22.38,0.74 20.22,0.74 20.2,0.74 20.18,0.74 20.16,0.74 20.14,0.74 20.12,0.74 20.1,0.74 17.93,0.74 10.02,2.7 7.9,2.02 7.35,1.84 6.78,2.26 6.81,2.84 6.85,3.66 6.94,4.45 7.11,5.17 7.43,6.54 7.13,7.97 6.35,9.14 3.16,13.93 1.21,56.25 1.21,63.35 1.21,67.29 1.27,70.36 1.7,72.76 1.88,73.75 1.77,74.76 1.38,75.69 0.98,76.65 0.8,77.87 0.73,79.13 0.7,79.71 1.26,80.14 1.81,79.96 3.78,79.33 7.32,79.72 7.79,79.77 10.57,80.57 14.51,80.83 20.11,80.83 20.13,80.83 20.15,80.83 20.17,80.83 20.19,80.83 20.21,80.83 20.23,80.83 25.83,80.83 29.77,80.57 32.55,79.77 33.02,79.71 36.55,79.32 38.53,79.96 39.08,80.14 39.64,79.71 39.61,79.13 39.55,77.87 39.36,76.66 38.96,75.69 Z M 32.63,75.79 C 31.93,76.01 31.2,76.22 30.43,76.4 30.43,76.4 30.42,76.4 30.41,76.4 30.29,76.41 30.21,76.42 30.16,76.43 29.89,76.51 29.61,76.58 29.32,76.64 29.27,76.65 29.22,76.66 29.16,76.67 28.9,76.72 28.63,76.77 28.36,76.82 28.34,76.82 28.31,76.82 28.29,76.83 26.24,77.16 23.6,77.28 20.2,77.28 H 20.11 C 16.72,77.28 14.08,77.16 12.02,76.83 12,76.83 11.98,76.83 11.95,76.82 11.68,76.77 11.41,76.72 11.15,76.67 11.1,76.67 11.05,76.65 10.99,76.64 10.69,76.58 10.41,76.5 10.15,76.43 10.1,76.43 10.02,76.41 9.9,76.4 9.9,76.4 9.9,76.4 9.89,76.4 9.12,76.22 8.38,76.01 7.68,75.79 
           V 13.2 C 7.91,12.75 8.14,12.32 8.39,11.9 10.53,9.36 14.99,7.61 20.15,7.61 25.31,7.61 29.77,9.36 31.91,11.9 32.16,12.32 32.39,12.76 32.62,13.2 
           V 75.78 Z"
                id="path329"
              />
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );
}

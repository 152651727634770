import { pond } from "protobuf-ts/pond";
import { getThemeType } from "theme";
import inLineDarkIcon from "assets/components/inLineAerationDark.png";
import inLineLightIcon from "assets/components/inLineAerationLight.png";
import lowSpeedDark from "assets/components/lowSpeedCentrifugalFanDark.png";
import lowSpeedLight from "assets/components/lowSpeedCentrifugalFanLight.png";
import highSpeedDark from "assets/components/fullCentrifugalFanDark.png";
import highSpeedLight from "assets/components/fullCentrifugalFanLight.png";

interface FanSizeDescriber {
  key: pond.FanHp;
  label: string;
  numerical: number;
}

const getFanSizeDescribers = (): FanSizeDescriber[] => {
  return [
    { key: pond.FanHp.FAN_HP_UNKNOWN, label: "Unknown", numerical: 0 },
    { key: pond.FanHp.FAN_HP_THREE, label: "3HP", numerical: 3 },
    { key: pond.FanHp.FAN_HP_FIVE, label: "5HP", numerical: 5 },
    { key: pond.FanHp.FAN_HP_SEVENPOINTFIVE, label: "7HP", numerical: 7 },
    { key: pond.FanHp.FAN_HP_SEVEN, label: "7.5HP", numerical: 7.5 },
    { key: pond.FanHp.FAN_HP_TEN, label: "10HP", numerical: 10 },
    { key: pond.FanHp.FAN_HP_FIFTEEN, label: "15HP", numerical: 15 },
    { key: pond.FanHp.FAN_HP_TWENTY, label: "20HP", numerical: 20 },
    { key: pond.FanHp.FAN_HP_TWENTYFIVE, label: "25HP", numerical: 25 },
    { key: pond.FanHp.FAN_HP_THIRTY, label: "30HP", numerical: 30 }
  ];
};

interface FanTypeDescriber {
  key: pond.FanType;
  label: string;
  sizes: pond.FanHp[];
}

const getFanTypeDescribers = (): FanTypeDescriber[] => {
  return [
    { key: pond.FanType.FAN_TYPE_UNKNOWN, label: "Unknown", sizes: [] },
    {
      key: pond.FanType.FAN_TYPE_CENTRIFUGAL_INLINE,
      label: "In-line Aeration",
      sizes: [
        pond.FanHp.FAN_HP_THREE,
        pond.FanHp.FAN_HP_FIVE,
        pond.FanHp.FAN_HP_SEVEN,
        pond.FanHp.FAN_HP_TEN
      ]
    },
    {
      key: pond.FanType.FAN_TYPE_CENTRIFUGAL_HIGH_SPEED,
      label: "High Speed Centrifugal",
      sizes: [
        pond.FanHp.FAN_HP_THREE,
        pond.FanHp.FAN_HP_FIVE,
        pond.FanHp.FAN_HP_SEVENPOINTFIVE,
        pond.FanHp.FAN_HP_TEN
      ]
    },
    {
      key: pond.FanType.FAN_TYPE_CENTRIFUGAL_LOW_SPEED,
      label: "Low Speed Centrifugal",
      sizes: [
        pond.FanHp.FAN_HP_FIVE,
        pond.FanHp.FAN_HP_SEVENPOINTFIVE,
        pond.FanHp.FAN_HP_TEN,
        pond.FanHp.FAN_HP_FIFTEEN,
        pond.FanHp.FAN_HP_TWENTY,
        pond.FanHp.FAN_HP_TWENTYFIVE,
        pond.FanHp.FAN_HP_THIRTY
      ]
    }
  ];
};

export function GetFanTypes(): FanTypeDescriber[] {
  return getFanTypeDescribers();
}

function getFanTypeDescriber(type: pond.FanType): FanTypeDescriber | undefined {
  const fanTypes = getFanTypeDescribers();

  return fanTypes.find(t => t.key === type);
}

export function GetFanTypeSizes(type: pond.FanType): FanSizeDescriber[] {
  let options: FanSizeDescriber[] = [];
  let typeDesc = getFanTypeDescriber(type);
  if (typeDesc) {
    let matches = typeDesc.sizes;
    options = getFanSizeDescribers().filter(s => matches.includes(s.key));
  }

  return options;
}

export function getFanIcon(type: pond.FanType): string {
  switch (type) {
    case pond.FanType.FAN_TYPE_CENTRIFUGAL_INLINE:
      if (getThemeType() === "light") return inLineLightIcon;
      return inLineDarkIcon;
    case pond.FanType.FAN_TYPE_CENTRIFUGAL_HIGH_SPEED:
      if (getThemeType() === "light") return highSpeedLight;
      return highSpeedDark;
    case pond.FanType.FAN_TYPE_CENTRIFUGAL_LOW_SPEED:
      if (getThemeType() === "light") return lowSpeedLight;
      return lowSpeedDark;
  }
  return "";
}

import { Box, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import ObjectDefinitionDisplay, { data } from "./objectDefinitionDisplay";

// template
/**
 *     
    {
        name: "",
        fields: [
            {
                fieldName: "",
                fieldType: "",
                fieldDescription: ""
            },
        ]
    },
 */
const notificationData: data[] = [
  {
    name: "Notification",
    fields: [
      {
        fieldName: "settings",
        fieldType: "NotificationSettings",
        fieldDescription: "The settings of the notification"
      },
      {
        fieldName: "status",
        fieldType: "NotificationStatus",
        fieldDescription: "The status of the notification"
      }
    ]
  },
  {
    name: "Notification Settings",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The unique identifier for the notification"
      },
      {
        fieldName: "title",
        fieldType: "string",
        fieldDescription: "The title of the notification"
      },
      {
        fieldName: "subtitle",
        fieldType: "string",
        fieldDescription: "The subtitle of the notification"
      },
      {
        fieldName: "message",
        fieldType: "string",
        fieldDescription: "The message the notification conveys to the user"
      },
      {
        fieldName: "sourceType",
        fieldType: "string",
        fieldDescription: "The type of object that triggered the notification"
      },
      {
        fieldName: "sourceKey",
        fieldType: "string",
        fieldDescription: "The key of the object that triggered the notification"
      },
      {
        fieldName: "componentSource",
        fieldType: "ComponentID",
        fieldDescription: "For device notifications the id of the component is included"
      },
      {
        fieldName: "logo",
        fieldType: "string",
        fieldDescription: "For team notifications the url to the logo image is included"
      }
    ]
  },
  {
    name: "NotificationStatus",
    fields: [
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "The timstamp the notification was created in RFC3339 format"
      }
    ]
  }
];

const noteData: data[] = [
  {
    name: "Note",
    fields: [
      {
        fieldName: "settings",
        fieldType: "NoteSettings",
        fieldDescription: "The settings for a note object"
      },
      {
        fieldName: "status",
        fieldType: "NoteStatus",
        fieldDescription: "The Status for a note object"
      }
    ]
  },
  {
    name: "NoteSettings",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The unique identifier for a note"
      },
      {
        fieldName: "userId",
        fieldType: "string",
        fieldDescription: "The id of the user that created the note"
      },
      {
        fieldName: "content",
        fieldType: "string",
        fieldDescription: "The content/message of the note"
      },
      {
        fieldName: "objectType",
        fieldType: "int",
        fieldDescription: "The type of object the note is linked to"
      },
      {
        fieldName: "objectKey",
        fieldType: "string",
        fieldDescription: "The key of the object the note is linked to"
      },
      {
        fieldName: "timestamp",
        fieldType: "int",
        fieldDescription: "The time in milliseconds since the epoch"
      }
    ]
  },
  {
    name: "NoteStatus",
    fields: [
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "The timestamp in RFC3339 format of when the note was created"
      }
    ]
  }
];

const homeMarkerData: data[] = [
  {
    name: "HomeMarker",
    fields: [
      {
        fieldName: "settings",
        fieldType: "HomeMarkerSettings",
        fieldDescription: "The settings of a Home Marker"
      },
      {
        fieldName: "status",
        fieldType: "HomeMarkerStatus",
        fieldDescription: "The status of a Home Marker"
      }
    ]
  },
  {
    name: "HomeMarkerSettings",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The unique id of the home marker"
      },
      {
        fieldName: "userId",
        fieldType: "string",
        fieldDescription: "The id of the user or team the home marker belongs too"
      },
      {
        fieldName: "longitude",
        fieldType: "float",
        fieldDescription: "The longitude coordinate of the home marker"
      },
      {
        fieldName: "latitude",
        fieldType: "float",
        fieldDescription: "The latitude coordinate of the home marker"
      }
    ]
  },
  {
    name: "HomeMarkerStatus",
    fields: [
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "The timestamp in RFC3339 format of when the note was created"
      }
    ]
  }
];

const fieldMarkerData: data[] = [
  {
    name: "FieldMarker",
    fields: [
      {
        fieldName: "settings",
        fieldType: "FieldMarkerSettings",
        fieldDescription: "The settings of a field marker"
      },
      {
        fieldName: "status",
        fieldType: "FieldMarkerStatus",
        fieldDescription: "The status of a field marker"
      }
    ]
  },
  {
    name: "FieldMarkerSettings",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The unique id of the field marker"
      },
      {
        fieldName: "longitude",
        fieldType: "float",
        fieldDescription: "The longitude coordinate of the field marker"
      },
      {
        fieldName: "latitude",
        fieldType: "float",
        fieldDescription: "the latitude coordinate of the field marker"
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name given to the field marker"
      },
      {
        fieldName: "description",
        fieldType: "string",
        fieldDescription: "The user entered description of the field marker"
      },
      {
        fieldName: "theme",
        fieldType: "ObjectTheme",
        fieldDescription: "The visual theme of the field marker"
      },
      {
        fieldName: "type",
        fieldType: "FieldMarkerType (ENUM)",
        fieldDescription: "The type of the field marker"
      },
      {
        fieldName: "severity",
        fieldType: "int",
        fieldDescription: "The numerical value of severity of the subject the marker represents"
      },
      {
        fieldName: "affectedArea",
        fieldType: "int",
        fieldDescription: "Numerical value to represent the area affected"
      }
    ]
  },
  {
    name: "FieldMarkerStatus",
    fields: [
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "The timestamp in RFC3339 format of when the note was created"
      }
    ]
  }
];

const taskData: data[] = [
  {
    name: "Task",
    fields: [
      {
        fieldName: "settings",
        fieldType: "TaskSettings",
        fieldDescription: "The settings of the task"
      },
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "the unique id of the task"
      }
    ]
  },
  {
    name: "TaskSettings",
    fields: [
      {
        fieldName: "title",
        fieldType: "string",
        fieldDescription: "The title given to the task"
      },
      {
        fieldName: "description",
        fieldType: "string",
        fieldDescription: "Description given to the task"
      },
      {
        fieldName: "type",
        fieldType: "string",
        fieldDescription: "the type of task it is"
      },
      {
        fieldName: "worker",
        fieldType: "string",
        fieldDescription: "The id of the user assigned to complete the task"
      },
      {
        fieldName: "start",
        fieldType: "string",
        fieldDescription: "Date in RFC3339 format"
      },
      {
        fieldName: "startTime",
        fieldType: "string",
        fieldDescription: "Time (hour-minute-second) in RFC3339 format"
      },
      {
        fieldName: "end",
        fieldType: "string",
        fieldDescription: "Date in RFC3339 format"
      },
      {
        fieldName: "endTime",
        fieldType: "string",
        fieldDescription: "Time (hour-minute-second) in RFC3339 format"
      },
      {
        fieldName: "cost",
        fieldType: "float",
        fieldDescription: "Monetary value associated with the task"
      },
      {
        fieldName: "secondaryCost",
        fieldType: "float",
        fieldDescription: "Monetary value associated with the task"
      },
      {
        fieldName: "colour",
        fieldType: "string",
        fieldDescription: "Hex value for the colour to display with the task"
      },
      {
        fieldName: "userId",
        fieldType: "string",
        fieldDescription: "The id of the user who created the task"
      },
      {
        fieldName: "objectKey",
        fieldType: "string",
        fieldDescription: "The key of the object the task is linked to"
      }
    ]
  }
];

const deviceData: data[] = [
  {
    name: "Device",
    fields: [
      {
        fieldName: "settings",
        fieldType: "DeviceSettings",
        fieldDescription: "The settings of the device"
      },
      {
        fieldName: "status",
        fieldType: "DeviceStatus",
        fieldDescription: "The status of the device"
      }
    ]
  },
  {
    name: "ComprehensiveDevice",
    fields: [
      {
        fieldName: "device",
        fieldType: "Device",
        fieldDescription: "The device object"
      },
      {
        fieldName: "components",
        fieldType: "Component (ARRAY)",
        fieldDescription: "A list of components on the device"
      },
      {
        fieldName: "interactions",
        fieldType: "Interaction (ARRAY)",
        fieldDescription: "A list of interactions on the device"
      }
    ]
  },
  {
    name: "HologramDevice",
    fields: [
      {
        fieldName: "device",
        fieldType: "Device",
        fieldDescription: "The device object"
      },
      {
        fieldName: "paused",
        fieldType: "DeviceActivity (ENUM)",
        fieldDescription: "The state of the device"
      },
      {
        fieldName: "dataLimit",
        fieldType: "int",
        fieldDescription: "The monthly limit for data usage in bytes"
      },
      {
        fieldName: "periodUsage",
        fieldType: "int",
        fieldDescription: "The used amount of data for this period in bytes"
      },
      {
        fieldName: "overLimit",
        fieldType: "LimitStatus (ENUM)",
        fieldDescription: "Whether the device is over or under its data limit"
      }
    ]
  },
  {
    name: "DeviceSettings",
    fields: [
      {
        fieldName: "deviceId",
        fieldType: "int",
        fieldDescription: "the id of the device"
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The assigned name of the device"
      },
      {
        fieldName: "description",
        fieldType: "string",
        fieldDescription: "The description of the device"
      },
      {
        fieldName: "sleepType",
        fieldType: "SleepType",
        fieldDescription: "The type of sleep mode for the device"
      },
      {
        fieldName: "sleepDurationS",
        fieldType: "int",
        fieldDescription: "The seconds to sleep for when triggered"
      },
      {
        fieldName: "sleepDelayMs",
        fieldType: "int",
        fieldDescription: "How long to stay awake for in milliseconds before returning to sleep"
      },
      {
        fieldName: "pondCheckPeriodS",
        fieldType: "int",
        fieldDescription: "The time in seconds to elapse before the device to checks in"
      },
      {
        fieldName: "platform",
        fieldType: "DevicePlatform (ENUM)",
        fieldDescription: "The platform of the device that determines its firmware"
      },
      {
        fieldName: "automaticallyUpgrade",
        fieldType: "bool",
        fieldDescription:
          "Whether the device is set to automatically update its firmware when a new version is released"
      },
      {
        fieldName: "product",
        fieldType: "DeviceProduct (ENUM)",
        fieldDescription: "The product type of the device"
      },
      {
        fieldName: "upgradeChannel",
        fieldType: "UpgradeChannel (ENUM)",
        fieldDescription: "The channel used by the device to perform upgrades"
      },
      {
        fieldName: "latitude",
        fieldType: "float",
        fieldDescription: "The latitude coordinate of the device"
      },
      {
        fieldName: "longitude",
        fieldType: "float",
        fieldDescription: "The longitude coordinate of the device"
      },
      {
        fieldName: "theme",
        fieldType: "ObjectTheme",
        fieldDescription: "The the visuals used to display"
      },
      {
        fieldName: "extensionComponent",
        fieldType: "string (ARRAY)",
        fieldDescription: "May contain a list of component keys"
      },
      {
        fieldName: "mutations",
        fieldType: "LinearMutation (ARRAY)",
        fieldDescription:
          "Any mutations to perform using components attached to the device with their measurements and combining them to convert into other measurements using a linear regression formula"
      }
    ]
  },
  {
    name: "DeviceStatus",
    fields: [
      {
        fieldName: "power",
        fieldType: "DevicePower",
        fieldDescription: "The power state of the device"
      },
      {
        fieldName: "state",
        fieldType: "DeviceState (ENUM)",
        fieldDescription: "The general state of the device"
      },
      {
        fieldName: "synced",
        fieldType: "bool",
        fieldDescription: "Has the device synced"
      },
      {
        fieldName: "lastSynced",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format of when the device last synced"
      },
      {
        fieldName: "sim",
        fieldType: "string",
        fieldDescription: "The id of the sim card for cellular devices"
      },
      {
        fieldName: "cellularId",
        fieldType: "string",
        fieldDescription: "The unique identifier for cellular devices"
      },
      {
        fieldName: "lastActive",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for when the device last checked in"
      },
      {
        fieldName: "firwareVersion",
        fieldType: "string",
        fieldDescription: "Current version of firmware running on the device"
      },
      {
        fieldName: "quackCount (deprecated as of v16)",
        fieldType: "int",
        fieldDescription:
          "The count of number of times the device has sent a message, if this gets out of sync with the devices internal quack count there may be delays in measurements being reported."
      },
      {
        fieldName: "address",
        fieldType: "string",
        fieldDescription: "The address of the device"
      },
      {
        fieldName: "tagKeys",
        fieldType: "string (ARRAY)",
        fieldDescription: "List of tag keys"
      },
      {
        fieldName: "tagNames",
        fieldType: "sring (ARRAY)",
        fieldDescription: "List of tag names"
      },
      {
        fieldName: "paused",
        fieldType: "bool",
        fieldDescription: "Whether the device is paused or not"
      },
      {
        fieldName: "lastChanged",
        fieldType: "string",
        fieldDescription:
          "Timestamp in RFC3339 format for the last time changes were made to the device"
      },
      {
        fieldName: "imei",
        fieldType: "string",
        fieldDescription: "The International Mobile Equipment Identity for cellular devices"
      },
      //dont have a full understanding of what this is
      //   {
      //     fieldName: "arkles",
      //     fieldType: "",
      //     fieldDescription: ""
      //   },
      {
        fieldName: "quackCountTx (v16+)",
        fieldType: "int",
        fieldDescription:
          "The count of number of times the device has sent a message, if this gets out of sync with the devices internal count there may be delays in measurements being reported"
      },
      {
        fieldName: "quackCountRx (v16+)",
        fieldType: "int",
        fieldDescription:
          "The count of the number of times the device recieved a message, if this gets out of sync with the devices internal count there may be issues with device functionality"
      },
      {
        fieldName: "userCost",
        fieldType: "float",
        fieldDescription:
          "The estimated cost to the user of running the device based on data transfer rates from Hologram"
      },
      {
        fieldName: "bytes",
        fieldType: "int",
        fieldDescription: "The amount od data in bytes the device has used for a period"
      },
      {
        fieldName: "bytesTimestamp",
        fieldType: "string",
        fieldDescription:
          "The Timestamp in RFC3339 format for when the used bytes for the device was last checked"
      }
    ]
  },
  {
    name: "DevicePower",
    fields: [
      {
        fieldName: "type",
        fieldType: "PowerSubtype (ENUM)",
        fieldDescription: "The type of power the device runs off of"
      },
      {
        fieldName: "inputVoltage",
        fieldType: "float",
        fieldDescription: "the voltage the device is receiving"
      },
      {
        fieldName: "chargePercent",
        fieldType: "float",
        fieldDescription: "The charge value of the device if it runs on a battery"
      }
    ]
  },
  {
    name: "DeviceGeoJson",
    fields: [
      {
        fieldName: "type",
        fieldType: "string",
        fieldDescription: "The type of GeoJson object, will most likely say 'Feature'"
      },
      {
        fieldName: "geometry",
        fieldType: "DeviceGeometry",
        fieldDescription: "The geometry data of the device in GeoJson format"
      },
      {
        fieldName: "properties",
        fieldType: "DeviceProperties",
        fieldDescription: "The properties of the device in GeoJson format"
      }
    ]
  },
  {
    name: "DeviceGeometry",
    fields: [
      {
        fieldName: "type",
        fieldType: "string",
        fieldDescription: "The GeoJson shape type"
      },
      {
        fieldName: "coordinates",
        fieldType: "float (ARRAY)",
        fieldDescription: "The coordinates of the GeoJson object"
      }
    ]
  },
  {
    name: "DeviceProperties",
    fields: [
      {
        fieldName: "deviceId",
        fieldType: "int",
        fieldDescription: "The id of the device"
      },
      {
        fieldName: "deviceName",
        fieldType: "string",
        fieldDescription: "The name of the device"
      },
      {
        fieldName: "lastSynced",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format of the last time the device synced"
      },
      {
        fieldName: "lastActive",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 fomrat of the last time the device checked in"
      },
      {
        fieldName: "firmwareVersion",
        fieldType: "string",
        fieldDescription: "The current firmware version of the device"
      },
      {
        fieldName: "lastChanged",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 fomrat of the last time the device was modified"
      }
    ]
  },
  {
    name: "DeviceHistory",
    fields: [
      {
        fieldName: "device",
        fieldType: "DeviceSettings",
        fieldDescription: "Snapshot of the device settings before the current change"
      },
      {
        fieldName: "user",
        fieldType: "string",
        fieldDescription: "The id of the user who made the change"
      },
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format of when the change was made"
      },
      {
        fieldName: "progress",
        fieldType: "string",
        fieldDescription: "Current progress of the change"
      }
    ]
  }
];

const componentData: data[] = [
  {
    name: "ComponentID",
    fields: [
      {
        fieldName: "type",
        fieldType: "ComponentType (ENUM)",
        fieldDescription: "The type of component"
      },
      {
        fieldName: "addressType",
        fieldType: "AddressType (ENUM)",
        fieldDescription: "The type of address used by the component"
      },
      {
        fieldName: "address",
        fieldType: "int",
        fieldDescription: "The byte address the component has on a device"
      }
    ]
  },
  {
    name: "Component",
    fields: [
      {
        fieldName: "settings",
        fieldType: "ComponentSettings",
        fieldDescription: "The settings of the component"
      },
      {
        fieldName: "status",
        fieldType: "ComponentStatus",
        fieldDescription: "The status of the component"
      },
      {
        fieldName: "lastMeasurement",
        fieldType: "UnitMeasurementForComponent (ARRAY)",
        fieldDescription: "The last measurement the component took"
      },
      {
        fieldName: "permissions",
        fieldType: "Permission (ARRAY)",
        fieldDescription:
          "The permission to the component based on the context of how it was loaded"
      }
    ]
  },
  {
    name: "ComponentSettings",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The unique key for the component"
      },
      {
        fieldName: "type",
        fieldType: "ComponentType (ENUM)",
        fieldDescription: "The type of component"
      },
      {
        fieldName: "subtype",
        fieldType: "int",
        fieldDescription: "the component subtype"
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the component"
      },
      {
        fieldName: "reportPeriodMs",
        fieldType: "int",
        fieldDescription:
          "The time in milliseconds before a components measurements are reported to the server"
      },
      {
        fieldName: "measurementPeriodMs",
        fieldType: "int",
        fieldDescription:
          "The time in milliseconds before a component takes a measurement that get stored internally on the device"
      },
      {
        fieldName: "defaultOutputState",
        fieldType: "int",
        fieldDescription: "The default state for controller components (0 = auto, 1 = on, 2 = off)"
      },
      {
        fieldName: "minCycleTimeMs",
        fieldType: "int",
        fieldDescription:
          "The minimum time in milliseconds a controller must remain in its current state before changing again"
      },
      {
        fieldName: "addressType",
        fieldType: "AddressType",
        fieldDescription: "The type of address the component uses"
      },
      {
        fieldName: "address",
        fieldType: "int",
        fieldDescription: "The actual byte address of the component"
      },
      {
        fieldName: "calibrate",
        fieldType: "bool",
        fieldDescription: "Whether to use values to calibrate measurements"
      },
      {
        fieldName: "calibrationCoefficient",
        fieldType: "float",
        fieldDescription: "Coefficient used to calibrate measurements"
      },
      {
        fieldName: "calibrationOffset",
        fieldType: "float",
        fieldDescription: "Offset used to calibrate measurements"
      },
      {
        fieldName: "grainType",
        fieldType: "Grain (ENUM)",
        fieldDescription: "The grain type that is set for grain cable component types"
      },
      {
        fieldName: "grainFilledTo",
        fieldType: "int",
        fieldDescription: "The node the grain is filled to on grain cables"
      },
      {
        fieldName: "hasOverlays",
        fieldType: "bool",
        fieldDescription:
          "Whether the overlays are turned on for displaying on the measurement graph"
      },
      {
        fieldName: "overlays",
        fieldType: "ComponentOverlays (ARRAY)",
        fieldDescription: "The overlays that are displayed on the components measurement graphs"
      },
      {
        fieldName: "defaultMutation",
        fieldType: "Mutator (ENUM, ARRAY)",
        fieldDescription:
          "List of mutators set on the component to create more measurements, an example would be using the temp and humidity on grain cables to calculate EMC(Equalized Moisture Content) for the set grain type"
      },
      {
        fieldName: "fanId",
        fieldType: "int",
        fieldDescription:
          "The id that corresponds to our internal list of fans for pressure components to be able to calculate their cfm"
      },
      //   {
      //     fieldName: "customCurve",
      //     fieldType: "",
      //     fieldDescription: ""
      //   },
      {
        fieldName: "smoothingAverages",
        fieldType: "int",
        fieldDescription:
          "Number that can be used to average the stated number of measurements into a single measurement for display"
      }
    ]
  },
  {
    name: "ComponentStatus",
    fields: [
      {
        fieldName: "",
        fieldType: "",
        fieldDescription: ""
      }
    ]
  },
  {
    name: "ComponentOverlays",
    fields: [
      {
        fieldName: "lastUpdate",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for when the component was last updated"
      },
      {
        fieldName: "synced",
        fieldType: "bool",
        fieldDescription: "Whether the component has successfully synced"
      },
      {
        fieldName: "lastSynced",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for the last time the component synced"
      },
      {
        fieldName: "lastMeasurement (Deprecated)",
        fieldType: "Measurement (Deprecated)",
        fieldDescription:
          "Is the last measurement that the component took using the old structure we had for measurements"
      },
      {
        fieldName: "measurement",
        fieldType: "UnitMeasurementForComponent (ARRAY)",
        fieldDescription:
          "The the last measurement that the component took using the current structure for measurements"
      },
      {
        fieldName: "lastGoodMeasurement",
        fieldType: "UnitMeasurementForComponent (ARRAY)",
        fieldDescription:
          "The most recent measurement for a component that does not contain any errors"
      }
    ]
  },
  {
    name: "ComponentHistory",
    fields: [
      {
        fieldName: "Component",
        fieldType: "ComponentSettings",
        fieldDescription: "Snapshot of the settings before the change"
      },
      {
        fieldName: "user",
        fieldType: "string",
        fieldDescription: "The id of the user who made the change"
      },
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for when the change occurred"
      },
      {
        fieldName: "parentDevice",
        fieldType: "string",
        fieldDescription: "The device that the component belongs to"
      },
      {
        fieldName: "progress",
        fieldType: "string",
        fieldDescription: "The current state of the change"
      }
    ]
  }
];

const interactionData: data[] = [
  {
    name: "Interaction",
    fields: [
      {
        fieldName: "settings",
        fieldType: "InteractionSettings",
        fieldDescription: "The setting for an interaction"
      },
      {
        fieldName: "status",
        fieldType: "InteractionStatus",
        fieldDescription: "The status for an interaction"
      }
    ]
  },
  {
    name: "InteractionSettings",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The unique key for an interaction"
      },
      {
        fieldName: "source",
        fieldType: "ComponentID",
        fieldDescription: "The id of the component that interaction uses for its conditions"
      },
      {
        fieldName: "sink",
        fieldType: "ComponentID",
        fieldDescription:
          "The id of the component that the interaction changes based on its conditions"
      },
      {
        fieldName: "instance",
        fieldType: "int",
        fieldDescription: "The instance of the interaction"
      },
      {
        fieldName: "conditions",
        fieldType: "InteractionCondition (ARRAY)",
        fieldDescription:
          "Maximum of two conditions that BOTH must be met in order for an interaction to trigger"
      },
      {
        fieldName: "result",
        fieldType: "InteractionResult",
        fieldDescription: "The result of triggering an interaction"
      },
      {
        fieldName: "notifications",
        fieldType: "InteractionNotifications",
        fieldDescription: "The notifications to send when an interaction triggers"
      },
      {
        fieldName: "schedule",
        fieldType: "InteractionSchedule",
        fieldDescription: "The schedule that the interaction will only trigger on specified times"
      },
      {
        fieldName: "priority",
        fieldType: "int",
        fieldDescription: "The priority in relation to other interactions on the device"
      },
      {
        fieldName: "subtype",
        fieldType: "int",
        fieldDescription: "The subtype of the interaction"
      },
      {
        fieldName: "nodeOne",
        fieldType: "int",
        fieldDescription:
          "For use when comparing nodes or using specific nodes this is the primary node"
      },
      {
        fieldName: "nodeTwo",
        fieldType: "int",
        fieldDescription:
          "For use when comparing two nodes this is only used to compare to node one"
      },
      {
        fieldName: "sortPriority",
        fieldType: "int",
        fieldDescription: "The sorting priority in relation to other interaction"
      }
    ]
  },
  {
    name: "InteractionStatus",
    fields: [
      {
        fieldName: "lastUpdate",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for when it was last updated"
      },
      {
        fieldName: "synced",
        fieldType: "bool",
        fieldDescription: "Whether it has synced yet"
      },
      {
        fieldName: "lastSynced",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for when it was last synced"
      }
    ]
  },
  {
    name: "InteractionCondition",
    fields: [
      {
        fieldName: "measurementType",
        fieldType: "MeasurementType (ENUM)",
        fieldDescription: "The type of measurement for the condition"
      },
      {
        fieldName: "comparison",
        fieldType: "RelationalOperator (ENUM)",
        fieldDescription: "The operator for the condition"
      },
      {
        fieldName: "value",
        fieldType: "int",
        fieldDescription:
          "The value for the condition. Note the value is stored using a coefficient to maintain the decimal value, see the value storage chart for each measurement types coefficient"
      }
    ]
  },
  {
    name: "InteractionResult",
    fields: [
      {
        fieldName: "type",
        fieldType: "InteractionResultType (ENUM)",
        fieldDescription: "The type of the interaction result"
      },
      {
        fieldName: "value",
        fieldType: "int",
        fieldDescription:
          "For Set or Toggle types a value of 1 is for on and 0 or nil is for off. For Report types the value will always be 1. For Run types the value represents how many seconds to run for"
      },
      {
        fieldName: "mode",
        fieldType: "int",
        fieldDescription:
          "When the interaction is for a motor control, the determines the direction 0 for clockwise and 1 for counter clockwise"
      },
      {
        fieldName: "dutyCycle",
        fieldType: "int",
        fieldDescription:
          "Only used for Run type interactions this is the cycle time between results"
      }
    ]
  },
  {
    name: "InteractionNotifications",
    fields: [
      {
        fieldName: "reports",
        fieldType: "bool",
        fieldDescription:
          "When true the component will force the device to report whenever the sensor is within the interaction conditions"
      },
      {
        fieldName: "notify",
        fieldType: "bool",
        fieldDescription: "When true will send notifications when the interaction triggers"
      }
    ]
  },
  {
    name: "InteractionSchedule",
    fields: [
      {
        fieldName: "weekdays",
        fieldType: "string (ARRAY)",
        fieldDescription: "The days of the week for the interaction to be able to trigger"
      },
      {
        fieldName: "timeOfDayStart",
        fieldType: "string",
        fieldDescription:
          "Time in RFC3339 format for the opening of the window for interactions for each day"
      },
      {
        fieldName: "timeOfDayEnd",
        fieldType: "string",
        fieldDescription:
          "Time in RFC3339 format for the closing of the window for interactions for each day"
      },
      {
        fieldName: "timezone",
        fieldType: "string",
        fieldDescription: "Timezone to use for the schedule"
      }
    ]
  }
];

const groupData: data[] = [
  {
    name: "Group",
    fields: [
      {
        fieldName: "settings",
        fieldType: "GroupSettings",
        fieldDescription: "The settings for a group"
      },
      {
        fieldName: "status",
        fieldType: "GroupStatus",
        fieldDescription: "The status for a group"
      }
    ]
  },
  {
    name: "GroupSettings",
    fields: [
      {
        fieldName: "groupId",
        fieldType: "int",
        fieldDescription: "The id of the group"
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the group"
      },
      {
        fieldName: "description",
        fieldType: "string",
        fieldDescription: "Description of the group"
      },
      {
        fieldName: "devices",
        fieldType: "int (ARRAY)",
        fieldDescription: "The ids of the devices that belong to the group"
      },
      {
        fieldName: "longitude",
        fieldType: "float",
        fieldDescription: "The longitude coordinate of the group"
      },
      {
        fieldName: "latitude",
        fieldType: "float",
        fieldDescription: "The latitude coordinate of the group"
      },
      {
        fieldName: "theme",
        fieldType: "ObjectTheme",
        fieldDescription: "Theme information for display"
      }
    ]
  },
  {
    name: "GroupStatus",
    fields: [
      {
        fieldName: "lastActive",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format of when the group was last active"
      },
      {
        fieldName: "tagKeys",
        fieldType: "sring (ARRAY)",
        fieldDescription: "A list of keys for the tags attached to the group"
      },
      {
        fieldName: "tagName",
        fieldType: "string (ARRAY)",
        fieldDescription: "A list of the names of tags attached to the group"
      }
    ]
  }
];

const transactionData: data[] = [
  {
    name: "Transaction",
    fields: [
      {
        fieldName: "fromObject",
        fieldType: "ObjectType",
        fieldDescription: `The type of object the inventory came from. For corrections (state = 2) that increased inventory and revoked correction (state = 4) this value will be nil or 0`
      },
      {
        fieldName: "fromKey",
        fieldType: "string",
        fieldDescription:
          "The key for the object the inventory came from. For corrections (state = 2) that increased inventory this value will be nil, for revoked correction (state = 4) this will be the key for the transaction that was revoked"
      },
      {
        fieldName: "toObject",
        fieldType: "ObjectType",
        fieldDescription:
          "The type of object the inventory moved to. For corrections (state = 2) that decreased the inventory this will be nil or 0"
      },
      {
        fieldName: "toKey",
        fieldType: "string",
        fieldDescription:
          "The key for the object the inventory moved to. For corrections (state = 2) that decreased the inventory this will be nil"
      },
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for when the transaction was created"
      },
      {
        fieldName: "transaction",
        fieldType: "TransactionData",
        fieldDescription: "The data of the transaction"
      },
      {
        fieldName: "state",
        fieldType: "TransactionState",
        fieldDescription: "The state of the transaction"
      },
      {
        fieldName: "userId",
        fieldType: "string",
        fieldDescription: "The user who created the transaction"
      },
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the transaction"
      }
    ]
  },
  {
    name: "TransactionData", //once other transaction types are implemented explain that only one field will be populated at a time in the data
    fields: [
      {
        fieldName: "grainTransaction",
        fieldType: "GrainTransaction",
        fieldDescription: "The information for a grain transaction"
      }
      // {
      //   fieldName: "fuelTransaction",
      //   fieldType: "FuelTransaction",
      //   fieldDescription: "The information for a fuel transaction"
      // },
    ]
  },
  {
    name: "GrainTransaction",
    fields: [
      {
        fieldName: "grainType",
        fieldType: "Grain (ENUM)",
        fieldDescription: "The type of grain"
      },
      {
        fieldName: "customGrain",
        fieldType: "string",
        fieldDescription:
          "If the object use a custom entered grain and not a supported grain type this will contain the name they entered"
      },
      {
        fieldName: "subType",
        fieldType: "string",
        fieldDescription: "A variant of the grain retrieved from the objects"
      },
      {
        fieldName: "bushels",
        fieldType: "float",
        fieldDescription: "The total amount of bushels adjusted"
      },
      {
        fieldName: "message",
        fieldType: "string",
        fieldDescription: "A message entered by the user at the time of creating the transaction"
      },
      {
        fieldName: "bushelsPerTonne",
        fieldType: "float",
        fieldDescription:
          "The amount of bushels that make up a metric Tonne based on the grain type or for custom types is entered by the user"
      },
      {
        fieldName: "sourceCompositionAdjustment",
        fieldType: "map<string, float>",
        fieldDescription: "Record of how much grain came from each original source"
      },
      {
        fieldName: "objectAdjustment",
        fieldType: "map<string, float>",
        fieldDescription: "Record of how much grain came from each previous location"
      }
    ]
  }
];

const generalData: data[] = [
  {
    name: "ObjectTheme",
    fields: [
      {
        fieldName: "color",
        fieldType: "string",
        fieldDescription: "The color for different display purposes such as a marker on a map"
      },
      {
        fieldName: "height",
        fieldType: "int",
        fieldDescription: "Number for the height of different display components"
      },
      {
        fieldName: "width",
        fieldType: "int",
        fieldDescription: "Number for the width of different display components"
      }
    ]
  },
  // I dont know what a lot of the stuff in the session message in protobuf is
  // {
  //   name: "Session",
  //   fields: [
  //     {
  //       fieldName: "",
  //       fieldType: "",
  //       fieldDescription: ""
  //     },
  //   ]
  // },
  // UnitMeasurementsForComponent
  {
    name: "UnitMeasurementsForComponent",
    fields: [
      {
        fieldName: "componentId",
        fieldType: "string",
        fieldDescription: "the id of the component (type-addressType-address)"
      },
      {
        fieldName: "values",
        fieldType: "ValueArray (ARRAY)",
        fieldDescription:
          "List of measurements, each entry in the array corresponds to a measurement taken by the component that the position matches to the position in the timestamps array for when it was taken"
      },
      {
        fieldName: "timestamps",
        fieldType: "string (ARRAY)",
        fieldDescription:
          "List if Timestamps in RFC3339 format that correspond to a measurement being taken. The position in this array matches with the position in the values array for when that measurement was taken"
      },
      {
        fieldName: "type",
        fieldType: "MeasurementType (ENUM)",
        fieldDescription: "The type of measurement"
      },
      {
        fieldName: "componentType",
        fieldType: "ComponentType (ENUM)",
        fieldDescription: "The type of component"
      }
    ]
  },
  {
    name: "UnitMeasurementsForObject",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the object the measurement is for"
      },
      {
        fieldName: "objectType",
        fieldType: "ObjectType",
        fieldDescription: "The type of object the measurement is for"
      },
      {
        fieldName: "values",
        fieldType: "ValueArray (ARRAY)",
        fieldDescription:
          "List of measurements, each entry in the array corresponds to a measurement taken by the object. The position matches with the position in the timestamps array for when that measurement was taken"
      },
      {
        fieldName: "timestamps",
        fieldType: "string (ARRAY)",
        fieldDescription:
          "List if Timestamps in RFC3339 format that correspond to a measurement being taken. The position matches with the position in the values array for what that measurement was"
      },
      {
        fieldName: "measurementType",
        fieldType: "MeasurementType (ENUM)",
        fieldDescription: "The type of the measurement"
      }
    ]
  },
  {
    name: "ValueArray",
    fields: [
      {
        fieldName: "values",
        fieldType: "float (ARRAY)",
        fieldDescription:
          "The values for each node when a measurement was taken, sensors without nodes will still be an array but only have the one value"
      },
      {
        fieldName: "error",
        fieldType: "bool",
        fieldDescription: "Whether there was an error in the measurement that was taken"
      }
    ]
  },
  {
    name: "Location",
    fields: [
      {
        fieldName: "longitude",
        fieldType: "float",
        fieldDescription: "The longitude coordinate point"
      },
      {
        fieldName: "latitude",
        fieldType: "float",
        fieldDescription: "The latitude coordinate point"
      }
    ]
  }
];

export default function GeneralDefinitions() {
  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Component Definitions
      </Typography>
      <List>
        {componentData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Device Definitions
      </Typography>
      <List>
        {deviceData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Field Marker Definitions
      </Typography>
      <List>
        {fieldMarkerData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        General Definitions
      </Typography>
      <List>
        {generalData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Group Definitions
      </Typography>
      <List>
        {groupData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Home Marker Definitions
      </Typography>
      <List>
        {homeMarkerData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Interaction Definitions
      </Typography>
      <List>
        {interactionData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Note Definitions
      </Typography>
      <List>
        {noteData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Notification Definitions
      </Typography>
      <List>
        {notificationData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Task Definitions
      </Typography>
      <List>
        {taskData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Transaction Definitions
      </Typography>
      <List>
        {transactionData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

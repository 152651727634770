import { LogoutOptions, RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "hooks";
import Loading from "pages/Loading";
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { getAuth0ClientId, getLogoutRedirectTarget, redirectOnLogout } from "services/whiteLabel";

interface IAuthContext {
  isAuthenticated: boolean;
  userID: string;
  token: string;
  loginWithRedirect: (o?: RedirectLoginOptions) => Promise<void>;
  logout: (o?: LogoutOptions) => void;
}

const defaultAuthContext: IAuthContext = {
  isAuthenticated: false,
  userID: "",
  token: "",
  loginWithRedirect: (...o) => Promise.resolve(),
  logout: (...o) => null
};

export const AuthContext = createContext<IAuthContext>(defaultAuthContext);

const getRedirectTarget = () => {
  return window.location.origin + "/callback";
};

interface AuthUser {
  userID: string;
  token: string;
}

export default function AuthWrapper(props: PropsWithChildren<{}>) {
  const { children } = props;
  const {
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently
  } = useAuth0();
  const [initialized, setInitialized] = useState(false);
  const [authUser, setAuthUser] = useState<AuthUser | undefined>();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated && !error) {
        async function fetchToken() {
          const newToken = await getAccessTokenSilently();
          setAuthUser({
            userID: user ? user.sub : "",
            token: newToken
          });
          setInitialized(true);
        }
        fetchToken();
      } else {
        if (error) snackbar.error("Authentication failed");
        setAuthUser(undefined);
        setInitialized(true);
      }
    }
  }, [isLoading, error, isAuthenticated, user, snackbar, getAccessTokenSilently]);

  const userID = () => {
    return authUser ? authUser.userID : "";
  };

  const token = () => {
    return authUser ? authUser.token : "";
  };

  if (isLoading || !initialized) {
    return <Loading fullViewport />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userID: userID(),
        token: token(),
        loginWithRedirect: (...o) =>
          loginWithRedirect({
            ...o,
            redirect_uri: getRedirectTarget()
          }),

        logout: (...o) =>
          logout({
            ...{
              returnTo:
                redirectOnLogout() && process.env.REACT_APP_AUTH0_CLIENT_DOMAIN
                  ? getLogoutRedirectTarget()
                  : window.location.origin,
              client_id: getAuth0ClientId()
            },
            ...o
          })
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);

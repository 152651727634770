import BindaptDarkIcon from "assets/products/bindapt/bindaptPlusDark.png";
import BindaptLightIcon from "assets/products/bindapt/bindaptPlusLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
  size?: number;
}

export default function BindaptIcon(props: Props) {
  const themeType = useThemeType();
  const { type, size } = props;

  const src = () => {
    if (type) {
      return type === "light" ? BindaptLightIcon : BindaptDarkIcon;
    }

    return themeType === "light" ? BindaptDarkIcon : BindaptLightIcon;
  };

  if (size) {
    return <img width={size} height={size} alt="bins" src={src()} />;
  }

  return <ImgIcon alt="bins" src={src()} />;
}

import { useTheme } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  LabelList,
  ReferenceArea,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";

export interface BarData {
  timestamp: number;
  value: number;
}

export interface RefArea {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  fill: string;
}

export interface AxisLabel {
  value: string;
  //must be a valid label position, for a list of possible position see Recharts documentation
  position: string;
  angle?: number;
  offset?: number;
  fill?: string;
}

interface Props {
  data: BarData[];
  barColour: string;
  refAreas?: RefArea[];
  yMin?: number;
  yMax?: number;
  graphLegend?: JSX.Element;
  labels?: boolean;
  labelSize?: number;
  useGradient?: boolean;
  labelColour?: string;
  customHeight?: number | string;
  xAxisType?: "number" | "category";
  barGap?: number | string;
  barSize?: number;
  yLabel?: AxisLabel;
  xLabel?: AxisLabel;
}

export default function BarGraph(props: Props) {
  const now = moment();
  const theme = useTheme();
  const {
    data,
    barColour,
    refAreas,
    yMin,
    yMax,
    graphLegend,
    labels,
    labelSize,
    useGradient,
    labelColour,
    customHeight,
    xAxisType,
    barGap,
    barSize,
    yLabel,
    xLabel
  } = props;
  const [referenceAreas, setReferenceAreas] = useState<JSX.Element[]>([]);

  useEffect(() => {
    let ras: JSX.Element[] = [];
    if (refAreas && refAreas.length > 0) {
      refAreas.forEach((ra, i) => {
        ras.push(
          <ReferenceArea
            key={"area#" + i}
            x1={ra.x1}
            x2={ra.x2}
            y1={ra.y1}
            y2={ra.y2}
            fill={ra.fill}
            fillOpacity={1}
            ifOverflow="extendDomain"
          />
        );
      });
    }
    setReferenceAreas(ras);
  }, [refAreas]);

  return (
    <ResponsiveContainer width={"100%"} height={customHeight ?? 350}>
      <BarChart data={data} barGap={barGap}>
        {graphLegend}
        <YAxis
          domain={[yMin ?? "dataMin", yMax ?? "auto"]}
          interval="preserveStartEnd"
          scale={"linear"}
          label={yLabel}
        />
        <XAxis
          dataKey={"timestamp"}
          domain={["dataMin", "dataMax"]}
          name="Time"
          scale={xAxisType === "number" ? "time" : "auto"}
          type={xAxisType}
          label={xLabel}
          tickFormatter={tick => {
            if (!isNaN(tick)) {
              let t = moment(tick);
              return now.isSame(t, "day") ? t.format("LT") : t.format("MMM DD");
            } else {
              return tick;
            }
          }}
          tick={{ fill: theme.palette.text.primary }}
          stroke={theme.palette.divider}
          interval="preserveStartEnd"
        />
        <defs>
          <linearGradient id="gradientColour" x1="0" y1="1" x2="0" y2="0">
            <stop offset="0%" stopColor={barColour} stopOpacity="0%" />
            <stop offset="100%" stopColor={barColour} stopOpacity="90%" />
          </linearGradient>
        </defs>
        <Bar
          dataKey={"value"}
          fill={useGradient ? "url(#gradientColour)" : barColour}
          barSize={barSize}>
          {labels && (
            <LabelList
              fill={labelColour}
              dataKey="value"
              position="center"
              style={{ fontWeight: 750, fontSize: labelSize }}
            />
          )}
        </Bar>
        {referenceAreas}
      </BarChart>
    </ResponsiveContainer>
  );
}

import ScoutLight from "assets/products/Ag/scoutLight.png";
import ScoutDark from "assets/products/Ag/scoutDark.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function ScoutIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? ScoutLight : ScoutDark;
    }

    return themeType === "light" ? ScoutDark : ScoutLight;
  };

  return <ImgIcon alt="fields" src={src()} />;
}

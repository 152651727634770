import { pond } from "protobuf-ts/pond";
import { quack } from "protobuf-ts/pond";
import { or } from "utils/types";
import { cloneDeep } from "lodash";
import { Component } from "models";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export class Controller {
  public settings: pond.ComponentSettings = pond.ComponentSettings.create();
  public status: pond.ComponentStatus = pond.ComponentStatus.create();
  public on = false;

  public static create(comp: Component): Controller {
    let my = new Controller();
    my.settings = comp.settings;
    my.status = comp.status;

    my.on = Boolean(my.status.lastMeasurement?.measurement?.booleanOutput?.value);

    return my;
  }

  public static createPond(comp: pond.Component): Controller {
    let my = new Controller();
    my.settings = comp.settings ? comp.settings : pond.ComponentSettings.create();
    my.status = comp.status ? comp.status : pond.ComponentStatus.create();

    my.on = Boolean(my.status.lastMeasurement?.measurement?.booleanOutput?.value);

    return my;
  }

  public static any(data: any): Controller {
    let comp = pond.Component.fromObject(cloneDeep(data));
    let my = Controller.createPond(comp);
    if (data && data.status && data.status.lastMeasurement) {
      my.status.lastMeasurement = data.status.lastMeasurement;
    }
    return my;
  }

  public colour() {
    return describeMeasurement(quack.MeasurementType.MEASUREMENT_TYPE_BOOLEAN).colour();
  }

  public color() {
    return describeMeasurement(quack.MeasurementType.MEASUREMENT_TYPE_BOOLEAN).colour();
  }

  public update(other: Controller) {
    this.settings = other.settings;
    this.status = other.status;
  }

  public name(): string {
    return this.settings.name !== "" ? this.settings.name : "Component " + this.key();
  }

  public key(): string {
    return this.settings.key;
  }

  public location(): quack.ComponentID {
    return quack.ComponentID.fromObject({
      type: this.settings.type,
      addressType: this.settings.addressType,
      address: this.settings.address
    });
  }

  public locationString(): string {
    return (
      or(this.settings.type, 0).toString() +
      "-" +
      or(this.settings.addressType, 0).toString() +
      "-" +
      or(this.settings.address, 0).toString()
    );
  }

  public type(): quack.ComponentType {
    return this.settings.type;
  }

  public subType(): number {
    return this.settings.subtype;
  }
}

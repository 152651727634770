import { createMuiTheme, responsiveFontSizes, Theme, ThemeOptions } from "@material-ui/core";
import * as Colours from "@material-ui/core/colors";
import {
  getPrimaryColour,
  getSecondaryColour,
  getSignatureAccentColour,
  getSignatureColour
} from "services/whiteLabel";

export function CreateTheme(themeType: "light" | "dark"): Theme {
  return responsiveFontSizes(createMuiTheme(options(themeType)));
}

function options(themeType: "light" | "dark"): ThemeOptions {
  const signature = getSignatureColour();
  const accent = getSignatureAccentColour();
  return {
    palette: {
      primary: Colours[getPrimaryColour() as keyof typeof Colours],
      secondary: Colours[getSecondaryColour() as keyof typeof Colours],
      background: Colours.grey,
      type: themeType,
      bxt: {
        primaryBlue: "#005bb0",
        lightBlue: "#1a86ec",
        darkBlue: "#081d55",
        rubberDuckYellow: "#ffd45c"
      },
      status: {
        succes: "#4caf50",
        unknown: "0288d1",
        unstable: "rgb(189, 200, 33)",
        risk: "#ffd642",
        warning: "#ffb74d",
        alert: "#f44336"
      }
    },
    typography: {
      fontFamily: [
        "Open Sans",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(",")
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: signature,
          color: accent
        }
      },
      MuiBottomNavigation: {
        root: {
          backgroundColor: signature
        }
      },
      MuiBottomNavigationAction: {
        root: {
          backgroundColor: signature,
          color: Colours["grey"][500],
          "&$selected": {
            color: accent
          }
        }
      },
      MuiCard: {
        root: {
          borderRadius: "5px",
          "@media (min-width: 600px)": {
            borderRadius: "7px"
          }
        }
      },
      MuiChip: {
        root: {
          "@media (max-width: 600px)": {
            height: "24px"
          }
        },
        avatar: {
          "@media (max-width: 600px)": {
            height: "24px",
            width: "24px"
          }
        }
      },
      MuiDialog: {
        paperFullScreen: {
          overflowX: "hidden"
        }
      },
      MuiSlider: {
        root: {
          height: 5
        },
        thumb: {
          height: 18,
          width: 18,
          marginTop: -6,
          marginLeft: -9,
          "&:focus,&:hover,&$active": {
            boxShadow: "inherit"
          }
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 2px)"
        },
        track: {
          height: 7,
          borderRadius: 4
        },
        rail: {
          height: 7,
          borderRadius: 4
        },
        vertical: {
          "& .MuiSlider-thumb": {
            marginLeft: "-8px !important"
          }
        }
      },
      MuiStepper: {
        root: {
          background: "transparent"
        }
      },
      MuiSwitch: {
        switchBase: {
          color: Colours.grey[400],
          "&$checked": {
            color: Colours.grey[100]
          },
          "&$checked + $track": {
            color: Colours.grey[100]
          }
        },
        checked: {},
        track: {}
      }
    }
  } as ThemeOptions;
}

export function isPercent(value: number): boolean {
  return value >= 0 && value <= 100;
}

export function avg(values: number[]): number {
  return values.reduce((a, b) => a + b, 0) / values.length;
}

export function roundTo(value: number, places: number): number {
  return parseFloat(value.toFixed(places));
}

export function abbreviateNum(value: number): string {
  if (isNaN(value)) return value.toString();
  if (value >= 1000000 || value <= -1000000) return Math.round(value / 1000000) + "m";
  if (value >= 1000 || value <= -1000) return Math.round(value / 1000) + "k";
  return value.toString();
}

import { useHTTP } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pond } from "protobuf-ts/pond";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";
import { useGlobalState } from "providers";

export interface IMutationAPIContext {
  linearMutation: (
    linearMutation: pond.LinearMutation,
    start: string,
    end: string
  ) => Promise<AxiosResponse<pond.LinearMutationResponse>>;
}

export const MutationAPIContext = createContext<IMutationAPIContext>({} as IMutationAPIContext);

interface Props {}

export default function MutationProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { post } = useHTTP();
  //const permissionAPI = usePermissionAPI();
  const [{ as }] = useGlobalState();

  const linearMutation = (linearMutation: pond.LinearMutation, start: string, end: string) => {
    if (as)
      return post<pond.LinearMutationResponse>(
        pondURL("/linearMutation?start=" + start + "&end=" + end + "&as=" + as),
        linearMutation
      );
    return post<pond.LinearMutationResponse>(
      pondURL("/linearMutation?start=" + start + "&end=" + end),
      linearMutation
    );
  };

  return (
    <MutationAPIContext.Provider
      value={{
        linearMutation
      }}>
      {children}
    </MutationAPIContext.Provider>
  );
}

export const useMutationAPI = () => useContext(MutationAPIContext);

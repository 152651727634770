import {
  CheckCircle as OKIcon,
  Error as AlarmingIcon,
  Help as UnknownIcon,
  Warning as WarningIcon
} from "@material-ui/icons";
import { pond } from "protobuf-ts/pond";
import React from "react";
import { getTextSecondary } from "theme";

export interface DeviceStateHelper {
  description: string;
  icon: any;
  colour: string;
}

const Unknown: DeviceStateHelper = {
  description: "Unknown",
  icon: <UnknownIcon style={{ color: "var(--status-unknown)" }} />,
  colour: "var(--status-unknown)"
};

const OK: DeviceStateHelper = {
  description: "OK",
  icon: <OKIcon style={{ color: "var(--status-ok)" }} />,
  colour: "var(--status-ok)"
};

const LowPower: DeviceStateHelper = {
  description: "Low Power",
  icon: <WarningIcon style={{ color: "var(--status-warning)" }} />,
  colour: "var(--status-warning)"
};

const Missing: DeviceStateHelper = {
  description: "Missing",
  icon: <AlarmingIcon style={{ color: getTextSecondary() }} />,
  colour: getTextSecondary()
};

const DEVICE_STATE_MAP = new Map<pond.DeviceState, DeviceStateHelper>([
  [pond.DeviceState.DEVICE_STATE_UNKNOWN, Unknown],
  [pond.DeviceState.DEVICE_STATE_OK, OK],
  [pond.DeviceState.DEVICE_STATE_LOW_POWER, LowPower],
  [pond.DeviceState.DEVICE_STATE_MISSING, Missing]
]);

export function getDeviceStateHelper(state: pond.DeviceState): DeviceStateHelper {
  if (state === pond.DeviceState.DEVICE_STATE_OK) {
    return OK;
  }
  const deviceStates = Array.from(DEVICE_STATE_MAP.keys());
  for (var i = 0; i < deviceStates.length; i++) {
    let key = deviceStates[i];
    if (state === key) {
      return DEVICE_STATE_MAP.get(key) as DeviceStateHelper;
    }
  }
  return Unknown;
}

export function getDeviceStateDescription(state: pond.DeviceState): string {
  return getDeviceStateHelper(state).description;
}

export function getDeviceStateIcon(state: pond.DeviceState): any {
  return getDeviceStateHelper(state).icon;
}

export function getDeviceStateColour(state: pond.DeviceState): any {
  return getDeviceStateHelper(state).colour;
}

export function filterByDeviceState(searchValue: string, state: pond.DeviceState) {
  let matches: string[] = [];
  switch (state) {
    case pond.DeviceState.DEVICE_STATE_OK:
      matches = ["okay", "good", "safe", "stable"];
      break;
    case pond.DeviceState.DEVICE_STATE_MISSING:
      matches = ["alarming", "alarmed", "bad", "error", "alert"];
      break;
    case pond.DeviceState.DEVICE_STATE_LOW_POWER:
      matches = ["warning", "caution", "low power"];
      break;
    default:
      matches = ["unknown", "?"];
      break;
  }

  return matches.some(match => match.indexOf(searchValue.replace("state:", "").trim()) > -1);
}

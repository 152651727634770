import { Box } from "@material-ui/core";
import { toAttach } from "providers/pond/fileControllerAPI";
import React, { useEffect, useState } from "react";
import FileSelector from "./FileSelector";

interface Props {
  startingSelectorCount?: number;
  toAttach?: toAttach;
  uploadStart: () => void;
  uploadEnd: (fileID?: string, fileName?: string) => void;
  keys?: string[];
  types?: string[];
}

export default function FileUploader(props: Props) {
  const { uploadStart, uploadEnd, startingSelectorCount, toAttach, keys, types } = props;
  const [numSelectors, setNumSelectors] = useState(startingSelectorCount || 1);
  const [selectors, setSelectors] = useState<JSX.Element[]>([]);

  useEffect(() => {
    let currentSelectors: JSX.Element[] = [];
    for (let i = 0; i < numSelectors; i++) {
      currentSelectors.push(
        <Box key={i} margin={2}>
          <FileSelector
            toAttach={toAttach}
            keys={keys}
            types={types}
            uniqueID={"fileSelector" + i}
            uploadStart={uploadStart}
            uploadEnd={(fileID, fileName) => {
              setNumSelectors(numSelectors + 1);
              uploadEnd(fileID, fileName);
            }}
          />
        </Box>
      );
    }
    setSelectors([...currentSelectors]);
  }, [numSelectors, uploadEnd, uploadStart, toAttach, keys, types]);

  return <React.Fragment>{selectors}</React.Fragment>;
}

import {
  AppBar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  Typography
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, Close, Fullscreen } from "@material-ui/icons";
import { useMobile } from "hooks";
import DeleteIcon from "products/AgIcons/Delete";
import GearMarkerIcon from "products/AgIcons/GearMarker";
import React, { useState } from "react";

interface Props {
  open: boolean;
  onClose: (refresh?: boolean) => void;
  displayPrev: () => void;
  displayNext: () => void;
  title: string;
  subheader?: JSX.Element;
  drawerBody: JSX.Element;
  removeElement?: () => void;
  updateElement?: () => void;
  objectActions?: JSX.Element;
  width: string | number;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    drawerMobile: {
      borderRadius: 30
    },
    drawerDesktop: {
      position: "relative",
      top: 64
    },
    header: {
      position: "static",
      background: theme.palette.background.default,
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 15,
      zIndex: 101
    },
    appBar: {
      position: "static",
      borderRadius: 30
    },
    drawerPaper: {
      overflowX: "hidden"
    }
  });
});

export default function DisplayDrawer(props: Props) {
  const {
    open,
    onClose,
    displayPrev,
    displayNext,
    title,
    drawerBody,
    removeElement,
    updateElement,
    objectActions,
    width
  } = props;
  const isMobile = useMobile();
  const classes = useStyles();
  const [fullScreen, setFullScreen] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState(60);

  const toggleFullScreen = () => {
    if (!fullScreen) {
      setDrawerHeight(94);
      setFullScreen(true);
    } else {
      setDrawerHeight(60);
      setFullScreen(false);
    }
  };

  return (
    <Drawer
      open={open}
      anchor={isMobile ? "bottom" : "right"}
      BackdropProps={{ invisible: true }}
      variant={isMobile ? "temporary" : "persistent"}
      classes={{ paper: classes.drawerPaper }}>
      <Box
        className={isMobile ? classes.drawerMobile : classes.drawerDesktop}
        style={{ width: isMobile ? "100%" : width, height: isMobile ? drawerHeight + "vh" : 0 }}>
        <Box className={classes.header} style={{ width: isMobile ? "100%" : width }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <IconButton onClick={() => onClose()}>
                <Close />
              </IconButton>
            </Box>
            <Box>
              {updateElement && (
                <IconButton onClick={updateElement}>
                  <GearMarkerIcon />
                </IconButton>
              )}
              {removeElement && (
                <IconButton onClick={removeElement}>
                  <DeleteIcon />
                </IconButton>
              )}
              {objectActions && objectActions}
              {isMobile && (
                <IconButton onClick={() => toggleFullScreen()}>
                  <Fullscreen />
                </IconButton>
              )}
            </Box>
          </Box>
          <AppBar className={classes.appBar} color="secondary">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap">
              <Grid item>
                <Button onClick={() => displayPrev()}>
                  <ArrowBackIos style={{ color: "black" }} />
                </Button>
              </Grid>
              <Grid item>
                <Box>
                  <Typography align="center" variant="h5">
                    {title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Button onClick={() => displayNext()}>
                  <ArrowForwardIos style={{ color: "black" }} />
                </Button>
              </Grid>
            </Grid>
          </AppBar>
        </Box>
        <Box>{drawerBody}</Box>
      </Box>
    </Drawer>
  );
}

import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";

export interface IDataDogProxyAPIContext {
  listLogs: (
    from: string,
    to: string,
    sort: string,
    limit: number,
    level?: string
  ) => Promise<AxiosResponse<pond.DataDogLogsResponse>>;
  listDeviceLogs: (
    device: string | number,
    from: string,
    to: string,
    sort: string,
    limit: number,
    level?: string
  ) => Promise<AxiosResponse<pond.DataDogLogsResponse>>;
}

export const DataDogProxyAPIContext = createContext<IDataDogProxyAPIContext>(
  {} as IDataDogProxyAPIContext
);

interface Props {}

export default function DataDogProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get } = useHTTP();

  const listLogs = (from: string, to: string, sort: string, limit: number, query?: string) => {
    query = query ? query : "";
    // if (level && level !== "all") {
    //   query = query + " @level:" + level;
    // }
    return get<pond.DataDogLogsResponse>(
      pondURL(
        "/datadog/logs?query=" +
          query +
          "&from=" +
          from +
          "&to=" +
          to +
          "&sort=" +
          sort +
          "&limit=" +
          limit
      )
    );
  };

  const listDeviceLogs = (
    device: string | number,
    from: string,
    to: string,
    sort: string,
    limit: number,
    level?: string
  ) => {
    let query = "@device:" + device;
    if (level && level !== "all") {
      query = query + " @level:" + level;
    }
    // console.log(
    //   "/datadog/logs?query=" +
    //     query +
    //     "&from=" +
    //     from +
    //     "&to=" +
    //     to +
    //     "&sort=" +
    //     sort +
    //     "&limit=" +
    //     limit
    // );
    return get<pond.DataDogLogsResponse>(
      pondURL(
        "/datadog/logs?query=" +
          query +
          "&from=" +
          from +
          "&to=" +
          to +
          "&sort=" +
          sort +
          "&limit=" +
          limit
      )
    );
  };

  return (
    <DataDogProxyAPIContext.Provider
      value={{
        listLogs,
        listDeviceLogs
      }}>
      {children}
    </DataDogProxyAPIContext.Provider>
  );
}

export const useDataDogProxyAPI = () => useContext(DataDogProxyAPIContext);

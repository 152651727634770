import { Tooltip, WithStyles, createStyles, Theme, withStyles, WithTheme } from "@material-ui/core";
import DarkThemeIcon from "@material-ui/icons/Brightness3";
import LightThemeIcon from "@material-ui/icons/Brightness7";
import React from "react";
import classNames from "classnames";

const styles = (theme: Theme) =>
  createStyles({
    darkThemeIcon: {
      borderRadius: "50%",
      padding: "1px",
      backgroundColor: "#003366",
      color: "#fefcd7 !important"
    },
    lightThemeIcon: {
      color: "#fdb813 !important"
    }
  });

interface Props extends WithStyles<typeof styles>, WithTheme {}

class ToggleTheme extends React.Component<Props> {
  lightThemeIcon = () => {
    const { classes } = this.props;
    return <DarkThemeIcon className={classNames(classes.darkThemeIcon)} fontSize="small" />;
  };

  darkThemeIcon = () => {
    const { classes } = this.props;
    return <LightThemeIcon className={classes.lightThemeIcon} fontSize="small" />;
  };

  render() {
    const { theme } = this.props;
    if (theme.palette.type === "light") {
      return (
        <Tooltip title="Switch to Dark Theme" placement="bottom-end">
          {this.darkThemeIcon()}
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Switch to Light Theme" placement="bottom-end">
          {this.lightThemeIcon()}
        </Tooltip>
      );
    }
  }
}

export default withStyles(styles, { withTheme: true })(ToggleTheme);

import { pond } from "protobuf-ts/pond";

const restrictions = new Map<pond.UpgradeChannel, string[]>([
  [pond.UpgradeChannel.UPGRADE_CHANNEL_DEVELOPMENT, ["better-controls", "wifi-creds"]],
  [pond.UpgradeChannel.UPGRADE_CHANNEL_ALPHA, ["better-controls", "wifi-creds"]],
  [pond.UpgradeChannel.UPGRADE_CHANNEL_BETA, ["better-controls", "wifi-creds"]],
  [pond.UpgradeChannel.UPGRADE_CHANNEL_STABLE, ["better-controls", "wifi-creds"]]
]);

export function hasDeviceFeature(channel: pond.UpgradeChannel, feature: string) {
  const features = restrictions.get(channel);
  return features ? features.includes(feature) : false;
}

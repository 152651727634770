import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { cloneDeep } from "lodash";

export class Group {
  public settings: pond.GroupSettings = pond.GroupSettings.create();
  public status: pond.GroupStatus = pond.GroupStatus.create();

  public static create(pb?: pond.Group): Group {
    let my = new Group();
    if (pb) {
      my.settings = pond.GroupSettings.fromObject(cloneDeep(or(pb.settings, {})));
      my.status = pond.GroupStatus.fromObject(cloneDeep(or(pb.status, {})));
    }
    return my;
  }

  public static clone(other?: Group): Group {
    if (other) {
      return Group.create(
        pond.Group.fromObject({
          settings: cloneDeep(other.settings),
          status: cloneDeep(other.status)
        })
      );
    }
    return Group.create();
  }

  public static any(data: any): Group {
    return Group.create(pond.Group.fromObject(cloneDeep(data)));
  }

  public id(): number {
    return Number(this.settings.groupId);
  }

  public name(): string {
    return this.settings.name !== "" ? this.settings.name : "Group " + this.id();
  }
}

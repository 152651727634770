import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import Loader from "common/Loader";
import React from "react";
import PageContainer from "./PageContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      marginTop: theme.spacing(2)
    }
  })
);

interface Props {
  initial?: boolean;
  fullViewport?: boolean;
  isCenterCenter?: boolean;
}

const Loading = (props: Props) => {
  const { initial, fullViewport, isCenterCenter } = props;
  const classes = useStyles();

  return (
    <PageContainer fullViewport={fullViewport} isCenterCenter={isCenterCenter}>
      <Loader />
      {initial && fullViewport && (
        <Typography variant="h6" color="primary" className={classes.marginTop}>
          Setting up your personal dashboard....
        </Typography>
      )}
    </PageContainer>
  );
};

export default Loading;

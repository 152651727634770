import {
  Button,
  createStyles,
  //DialogActions,
  //DialogContent,
  //DialogTitle,
  Grid,
  //IconButton,
  makeStyles,
  TextareaAutosize,
  Theme,
  Typography,
  useTheme
} from "@material-ui/core";
//import { pond } from "protobuf-ts/pond";
import { useGlobalState, useNoteAPI, useSnackbar } from "providers";
import React, { useState } from "react";
import { getThemeType } from "theme";
import ChatIcon from "@material-ui/icons/Chat";
import { Note } from "models";
import { pond } from "protobuf-ts/pond";
//import { AttachFile } from "@material-ui/icons";
//import ResponsiveDialog from "common/ResponsiveDialog";
//import FileUploader from "common/FileUploads/FileUploader";
//import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconBox: {
      width: 36,
      height: 36,
      margin: 8,
      marginRight: 12
    },
    textAreaClass: {
      backgroundColor: getThemeType() === "light" ? "rgb(245, 245, 245)" : "rgb(50, 50, 50)",
      borderColor: getThemeType() === "light" ? "rgb(255, 255, 255)" : "rgb(60, 60, 60)",
      color: getThemeType() === "light" ? "rgb(10, 10, 10)" : "rgb(245, 245, 245)",
      borderRadius: "5px",
      width: "100%"
    }
  })
);

interface Props {
  objectKey: string;
  newNoteMethod: (note: Note) => void;
  type?: pond.NoteType;
}

/**
 * renders the text area to enter you message and the button to submit it
 * @returns text area to enter your message
 */
export default function ChatInput(props: Props) {
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const { objectKey, type } = props;
  const [{ user }] = useGlobalState();
  const noteAPI = useNoteAPI();
  const { openSnack } = useSnackbar();
  const [shift, setShift] = useState(false);
  const theme = useTheme();
  //const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
  //const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<Map<string, string>>(new Map());

  const clearEntry = () => {
    setMessage("");
  };

  const onKeyDown = (event: any) => {
    if (event.key === "Shift") {
      setShift(true);
    }
    if (event.key === "Enter" && !shift) {
      event.preventDefault();
    }
  };

  const onKeyUp = (event: any) => {
    if (event.key === "Shift") {
      setShift(false);
    }
    if (event.key === "Enter" && !shift) {
      validateMessage();
    }
  };

  const validateMessage = () => {
    let valid = false;
    let i = 0;
    while (!valid && i < message.length) {
      if (message[i] !== " " && message[i] !== "\n" && message[i] !== undefined) {
        valid = true;
      }
      i++;
    }
    valid ? submit() : openSnack("Invalid Message");
    clearEntry();
    setUploadedFiles(new Map());
  };

  const submit = () => {
    if (message !== "" && message !== "\n") {
      let newNote = Note.create();
      newNote.settings.objectKey = objectKey;
      newNote.settings.userId = user.id();
      //newNote.settings.objectType = pond.NoteType.NOTE_TYPE_TEAM not using type at the moment
      if (type) {
        newNote.settings.objectType = type;
      }
      newNote.settings.timestamp = Date.now();
      newNote.settings.content = message;

      noteAPI
        .addNote(newNote.settings, Array.from(uploadedFiles.keys()))
        .then(resp => {
          props.newNoteMethod(newNote);
          openSnack("Message Sent");
        })
        .catch(err => {
          openSnack("Message Failed to send");
        });
    }
    clearEntry();
  };

  /**
   * Dialog to select attachments
   * postponing note attachments until notes can be overhauled to be more in line with the rest of the objects
   * @returns
   */
  // const attachmentDialog = () => {
  //   return (
  //     <ResponsiveDialog
  //       open={attachmentDialogOpen}
  //       onClose={() => {
  //         setAttachmentDialogOpen(false);
  //       }}>
  //       <DialogTitle>Add Attachments</DialogTitle>
  //       <DialogContent>
  //         <FileUploader
  //           uploadStart={() => {
  //             setUploadingFile(true);
  //           }}
  //           uploadEnd={(fileID, fileName) => {
  //             setUploadingFile(false);
  //             if (fileID && fileName) {
  //               let f = cloneDeep(uploadedFiles);
  //               f.set(fileID, fileName);
  //               setUploadedFiles(f);
  //             }
  //           }}
  //         />
  //       </DialogContent>
  //       <DialogActions>
  //         <Button
  //           disabled={uploadingFile}
  //           onClick={() => {
  //             setAttachmentDialogOpen(false);
  //           }}>
  //           Close
  //         </Button>
  //       </DialogActions>
  //     </ResponsiveDialog>
  //   );
  // };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row-reverse"
        alignItems="center"
        alignContent="center"
        justify="space-between"
        spacing={1}
        style={{ marginTop: theme.spacing(1) }}>
        <Grid item>
          <Button onClick={validateMessage} variant="contained" color="primary">
            <ChatIcon />
          </Button>
        </Grid>
        <Grid item xs={9}>
          <TextareaAutosize
            value={message}
            className={classes.textAreaClass}
            onChange={e => setMessage(e.target.value)}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
          />
        </Grid>
        {/* <Grid item xs={2}>
          <IconButton
            onClick={() => {
              setAttachmentDialogOpen(true);
            }}
            color="primary">
            <AttachFile />
          </IconButton>
        </Grid> */}
      </Grid>
      <Typography>{Array.from(uploadedFiles.values()).toString()}</Typography>
    </React.Fragment>
  );
}

import AddFieldWhite from "assets/products/Ag/addFieldWhite.png";
import AddFieldBlack from "assets/products/Ag/addFieldBlack.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function AddFieldIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? AddFieldWhite : AddFieldBlack;
    }

    return themeType === "light" ? AddFieldBlack : AddFieldWhite;
  };

  return <ImgIcon alt="fields" src={src()} />;
}

import { useHTTP } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pond } from "protobuf-ts/pond";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";
import { useGlobalState } from "providers";

export interface ITransactionAPIContext {
  addTransaction: (
    transaction: pond.Transaction,
    fileIDs?: string[]
  ) => Promise<AxiosResponse<pond.AddTransactionResponse>>;
  transactionPageData: (
    start: string,
    end: string
  ) => Promise<AxiosResponse<pond.TransactionPageDataResponse>>;
  listTransactions: (
    start: string,
    end: string,
    fromObject?: pond.ObjectType,
    toObject?: pond.ObjectType,
    fromKey?: string,
    toKey?: string
  ) => Promise<AxiosResponse<pond.ListTransactionsResponse>>;
  revokeTransaction: (key: string) => Promise<AxiosResponse<pond.RevokeTransactionResponse>>;
  updateTransaction: (
    key: string,
    data: pond.TransactionData
  ) => Promise<AxiosResponse<pond.UpdateTransactionResponse>>;
}

export const TransactionAPIContext = createContext<ITransactionAPIContext>(
  {} as ITransactionAPIContext
);

interface Props {}

export default function TransactionProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, post, put } = useHTTP();
  const [{ as }] = useGlobalState();

  const addTransaction = (transaction: pond.Transaction, fileIDs?: string[]) => {
    if (as)
      return post<pond.AddTransactionResponse>(
        pondURL(
          "/transactions?as=" + as + (fileIDs ? "&fileAttachments=" + fileIDs.toString() : "")
        ),
        transaction
      );
    return post<pond.AddTransactionResponse>(
      pondURL("/transactions" + (fileIDs ? "?fileAttachments=" + fileIDs.toString() : "")),
      transaction
    );
  };

  const listTransactions = (
    start: string,
    end: string,
    fromObject?: pond.ObjectType,
    toObject?: pond.ObjectType,
    fromKey?: string,
    toKey?: string
  ) => {
    if (as) {
      return get<pond.ListTransactionsResponse>(
        pondURL("/transactions?as=" + as + "&start=" + start + "&end=" + end)
      );
    }
    return get<pond.ListTransactionsResponse>(
      pondURL(
        "/transactions?start=" +
          start +
          "&end=" +
          end +
          (fromObject ? "&fromObject=" + fromObject : "") +
          (toObject ? "&toObject=" + toObject : "") +
          (fromKey ? "&fromKey=" + fromKey : "") +
          (toKey ? "&toKey=" + toKey : "")
      )
    );
  };

  const updateTransaction = (key: string, data: pond.TransactionData) => {
    if (as)
      return post<pond.UpdateTransactionResponse>(
        pondURL("/transactions/" + key + "/update?as=" + as),
        data
      );
    return post<pond.UpdateTransactionResponse>(pondURL("/transactions/" + key + "/update"), data);
  };

  const transactionPageData = (start: string, end: string) => {
    if (as) {
      return get<pond.TransactionPageDataResponse>(
        pondURL("/transactionsPage?as=" + as + "&start=" + start + "&end=" + end)
      );
    }
    return get<pond.TransactionPageDataResponse>(
      pondURL("/transactionsPage?start=" + start + "&end=" + end)
    );
  };

  const revokeTransaction = (key: string) => {
    if (as) {
      return put<pond.RevokeTransactionResponse>(
        pondURL("/transactions/" + key + "/revoke?as=" + as)
      );
    }
    return put<pond.TransactionPageDataResponse>(pondURL("/transactions/" + key + "/revoke"));
  };

  return (
    <TransactionAPIContext.Provider
      value={{
        addTransaction,
        listTransactions,
        updateTransaction,
        transactionPageData,
        revokeTransaction
      }}>
      {children}
    </TransactionAPIContext.Provider>
  );
}

export const useTransactionAPI = () => useContext(TransactionAPIContext);

import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";

export interface IImagekitAPIContext {
  uploadImage: (
    filename: string,
    file: any,
    filepath?: string
  ) => Promise<AxiosResponse<pond.UploadImageResponse>>;
}

export const ImagekitAPIContext = createContext<IImagekitAPIContext>({} as IImagekitAPIContext);

interface Props {}

export default function ImagekitProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { post } = useHTTP();
  const [{ as }] = useGlobalState();

  const uploadImage = (filename: string, file: any, filepath = "/") => {
    let request = new pond.UploadImageRequest();
    request.file = file;
    request.fileName = filename;
    request.filePath = filepath;

    if (as) return post<pond.UploadImageResponse>(pondURL("/images/upload?as=" + as), request);
    return post<pond.UploadImageResponse>(pondURL("/images/upload"), request);
  };

  return (
    <ImagekitAPIContext.Provider
      value={{
        uploadImage
      }}>
      {children}
    </ImagekitAPIContext.Provider>
  );
}

export const useImagekitAPI = () => useContext(ImagekitAPIContext);

import AerationFanDarkIcon from "assets/components/aerationFanDark.png";
import AerationFanLightIcon from "assets/components/aerationFanLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
  size?: number;
}

export default function AerationFanIcon(props: Props) {
  const themeType = useThemeType();
  const { type, size } = props;

  const src = () => {
    if (type) {
      return type === "light" ? AerationFanLightIcon : AerationFanDarkIcon;
    }

    return themeType === "light" ? AerationFanDarkIcon : AerationFanLightIcon;
  };

  if (size) {
    return <img width={size} height={size} alt="fan" src={src()} />;
  }

  return <ImgIcon alt="fan" src={src()} />;
}

import { Box, Card, Grid, Typography } from "@material-ui/core";
import ObjectControls from "common/ObjectControls";
import { GetDefaultDateRange } from "common/time/DateRange";
import TimeBar from "common/time/TimeBar";
import GrainBagActions from "grainBag/grainBagActions";
import GrainBagInventoryGraph from "grainBag/grainBagInventoryGraph";
import GrainBagVisualizer from "grainBag/grainBagVisualizer";
import { Scope } from "models";
import { GrainBag as IGrainBag } from "models/GrainBag";
import { Moment } from "moment";
import { MatchParams } from "navigation/Routes";
import { pond } from "protobuf-ts/pond";
import { useGlobalState, useGrainBagAPI, useSnackbar, useUserAPI } from "providers";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import PageContainer from "./PageContainer";

interface Props {
  bagKey?: string;
  mobileView?: boolean;
}

export default function GrainBag(props: Props) {
  const { bagKey, mobileView } = props;
  const match = useRouteMatch<MatchParams>();
  const bagID = bagKey ?? match.params.bagID;
  const grainBagAPI = useGrainBagAPI();
  const [{ user, as }] = useGlobalState();
  const userAPI = useUserAPI();
  const [permissions, setPermissions] = useState<pond.Permission[]>([]);
  const [grainBag, setGrainBag] = useState<IGrainBag>(IGrainBag.create());
  const defaultDateRange = GetDefaultDateRange();
  const [startDate, setStartDate] = useState<Moment>(defaultDateRange.start);
  const [endDate, setEndDate] = useState<Moment>(defaultDateRange.end);
  const { openSnack } = useSnackbar();

  useEffect(() => {
    grainBagAPI
      .getGrainBag(bagID)
      .then(resp => {
        if (resp.data.grainBag) {
          setGrainBag(IGrainBag.create(resp.data.grainBag));
        }
      })
      .catch(err => {
        openSnack("There was a problem loading the Grain Bag");
      });
  }, [bagID, grainBagAPI, openSnack]);

  useEffect(() => {
    let key = bagID;
    let kind = "grainbag";
    if (as) {
      key = as;
      kind = "team";
    }
    userAPI.getUser(user.id(), { key: key, kind: kind } as Scope).then(resp => {
      setPermissions(resp.permissions);
    });
  }, [as, bagID, userAPI, user]);

  const updateDateRange = (newStartDate: any, newEndDate: any) => {
    let range = GetDefaultDateRange();
    range.start = newStartDate;
    range.end = newEndDate;
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  return (
    <PageContainer>
      <ObjectControls
        actions={
          <GrainBagActions
            grainBag={grainBag}
            refreshCallback={updatedBag => {
              if (updatedBag) {
                setGrainBag(updatedBag);
              }
              // grainBagAPI
              //   .getGrainBag(bagID)
              //   .then(resp => {
              //     if (resp.data.grainBag) {
              //       setGrainBag(IGrainBag.create(resp.data.grainBag));
              //     }
              //   })
              //   .catch(err => {
              //     openSnack("There was a problem loading the Grain Bag");
              //   });
            }}
            permissions={permissions}
          />
        }
      />
      <Card style={{ padding: 15, margin: 15 }}>
        <Grid container direction="row" spacing={3} style={{ padding: 5 }}>
          <Grid item xs={12} sm={12} md={mobileView ? 12 : 6} lg={mobileView ? 12 : 4}>
            <Box
              paddingLeft={1}
              display="flex"
              justifyContent="space-between"
              alignItems="left"
              marginBottom={2}>
              <Typography style={{ fontWeight: 650, fontSize: 25 }}>{grainBag.name()}</Typography>
            </Box>
            <GrainBagVisualizer grainBag={grainBag} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={mobileView ? 12 : 8}>
            <Box
              paddingLeft={1}
              display="flex"
              justifyContent="space-between"
              alignItems="left"
              marginBottom={2}>
              <TimeBar startDate={startDate} endDate={endDate} updateDateRange={updateDateRange} />
            </Box>
            <GrainBagInventoryGraph
              grainBag={grainBag}
              startDate={startDate}
              endDate={endDate}
              customHeight={500}
            />
          </Grid>
        </Grid>
      </Card>
    </PageContainer>
  );
}

import { Box, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import EnumeratorDisplay, { data } from "./enumeratorDisplay";

//general enums start here
const addressType: data = {
  name: "AddressType",
  pairs: [
    {
      key: "ADDRESS_TYPE_INVALID",
      val: 0
    },
    {
      key: "ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY",
      val: 1
    },
    {
      key: "ADDRESS_TYPE_I2C",
      val: 2
    },
    {
      key: "ADDRESS_TYPE_DAC",
      val: 3
    },
    {
      key: "ADDRESS_TYPE_POWER",
      val: 4
    },
    {
      key: "ADDRESS_TYPE_GPS",
      val: 5
    },
    {
      key: "ADDRESS_TYPE_MODEM",
      val: 6
    },
    {
      key: "ADDRESS_TYPE_I2C_WITH_CHANNELS",
      val: 7
    },
    {
      key: "ADDRESS_TYPE_INCREMENTAL",
      val: 8
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET1",
      val: 9
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET2",
      val: 10
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET3",
      val: 11
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET4",
      val: 12
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET5",
      val: 13
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET6",
      val: 14
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET7",
      val: 15
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET8",
      val: 16
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET9",
      val: 17
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET10",
      val: 18
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET11",
      val: 19
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET12",
      val: 20
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET13",
      val: 21
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET14",
      val: 22
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET15",
      val: 23
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET16",
      val: 24
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET17",
      val: 25
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET18",
      val: 26
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET19",
      val: 27
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET20",
      val: 28
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET21",
      val: 29
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET22",
      val: 30
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET23",
      val: 31
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET24",
      val: 32
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET25",
      val: 33
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET26",
      val: 34
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET27",
      val: 35
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET28",
      val: 36
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET29",
      val: 37
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET30",
      val: 38
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET31",
      val: 39
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET32",
      val: 40
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET33",
      val: 41
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET34",
      val: 42
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET35",
      val: 43
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET36",
      val: 44
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET37",
      val: 45
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET38",
      val: 46
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET39",
      val: 47
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET40",
      val: 48
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET41",
      val: 49
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET42",
      val: 50
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET43",
      val: 51
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET44",
      val: 52
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET45",
      val: 53
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET46",
      val: 54
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET47",
      val: 55
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET48",
      val: 56
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET49",
      val: 57
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET50",
      val: 58
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET51",
      val: 59
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET52",
      val: 60
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET53",
      val: 61
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET54",
      val: 62
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET55",
      val: 63
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET56",
      val: 64
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET57",
      val: 65
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET58",
      val: 66
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET59",
      val: 67
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET60",
      val: 68
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET61",
      val: 69
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET62",
      val: 70
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET63",
      val: 71
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET64",
      val: 72
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET65",
      val: 73
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET66",
      val: 74
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET67",
      val: 75
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET68",
      val: 76
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET69",
      val: 77
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET70",
      val: 78
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET71",
      val: 79
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET72",
      val: 80
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET73",
      val: 81
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET74",
      val: 82
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET75",
      val: 83
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET76",
      val: 84
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET77",
      val: 85
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET78",
      val: 86
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET79",
      val: 87
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET80",
      val: 88
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET81",
      val: 89
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET82",
      val: 90
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET83",
      val: 91
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET84",
      val: 92
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET85",
      val: 93
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET86",
      val: 94
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET87",
      val: 95
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET88",
      val: 96
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET89",
      val: 97
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET90",
      val: 98
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET91",
      val: 99
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET92",
      val: 100
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET93",
      val: 101
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET94",
      val: 102
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET95",
      val: 103
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET96",
      val: 104
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET97",
      val: 105
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET98",
      val: 106
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET99",
      val: 107
    },
    {
      key: "ADDRESS_TYPE_PIN_OFFSET100",
      val: 108
    },
    {
      key: "ADDRESS_TYPE_INTERNAL_SLEEP",
      val: 109
    },
    {
      key: "ADDRESS_TYPE_SPI",
      val: 110
    }
  ]
};

const componentType: data = {
  name: "ComponentType",
  pairs: [
    {
      key: "COMPONENT_TYPE_INVALID",
      val: 0
    },
    {
      key: "COMPONENT_TYPE_POWER",
      val: 1
    },
    {
      key: "COMPONENT_TYPE_TEMPERATURE",
      val: 2
    },
    {
      key: "COMPONENT_TYPE_BOOLEAN_OUTPUT",
      val: 3
    },
    {
      key: "COMPONENT_TYPE_EDGE_TRIGGERED",
      val: 4
    },
    {
      key: "COMPONENT_TYPE_GRAIN_CABLE",
      val: 5
    },
    {
      key: "COMPONENT_TYPE_PRESSURE",
      val: 6
    },
    {
      key: "COMPONENT_TYPE_GPS",
      val: 7
    },
    {
      key: "COMPONENT_TYPE_BOOLEAN_INPUT",
      val: 8
    },
    {
      key: "COMPONENT_TYPE_DHT",
      val: 9
    },
    {
      key: "COMPONENT_TYPE_MODEM",
      val: 10
    },
    {
      key: "COMPONENT_TYPE_LIGHT",
      val: 11
    },
    {
      key: "COMPONENT_TYPE_CO2",
      val: 12
    },
    {
      key: "COMPONENT_TYPE_STEPPER_MOTOR",
      val: 13
    },
    {
      key: "COMPONENT_TYPE_ANALOG_INPUT",
      val: 14
    },
    {
      key: "COMPONENT_TYPE_O2",
      val: 15
    },
    {
      key: "COMPONENT_TYPE_ETHYLENE",
      val: 16
    },
    {
      key: "COMPONENT_TYPE_ORP",
      val: 17
    },
    {
      key: "COMPONENT_TYPE_PH",
      val: 18
    },
    {
      key: "COMPONENT_TYPE_VOLTAGE",
      val: 19
    },
    {
      key: "COMPONENT_TYPE_CALCIUM",
      val: 20
    },
    {
      key: "COMPONENT_TYPE_NITRATE",
      val: 21
    },
    {
      key: "COMPONENT_TYPE_POTASSIUM",
      val: 22
    },
    {
      key: "COMPONENT_TYPE_CONDUCTIVITY",
      val: 23
    },
    {
      key: "COMPONENT_TYPE_CAPACITANCE",
      val: 24
    },
    {
      key: "COMPONENT_TYPE_VAPOUR_PRESSURE_DEFICIT",
      val: 25
    },
    {
      key: "COMPONENT_TYPE_WEIGHT",
      val: 26
    },
    {
      key: "COMPONENT_TYPE_AIR_QUALITY",
      val: 27
    },
    {
      key: "COMPONENT_TYPE_LIDAR",
      val: 28
    },
    {
      key: "COMPONENT_TYPE_PRESSURE_CABLE",
      val: 29
    },
    {
      key: "COMPONENT_TYPE_INTERNAL_FUNCTION",
      val: 30
    },
    {
      key: "COMPONENT_TYPE_CAPACITOR_CABLE",
      val: 31
    },
    {
      key: "COMPONENT_TYPE_SEN5X",
      val: 32
    },
    {
      key: "COMPONENT_TYPE_VIBRATION_CHAIN",
      val: 33
    },
    {
      key: "COMPONENT_TYPE_DRAGER_GAS_DONGLE",
      val: 34
    }
  ]
};

const deviceActivity: data = {
  name: "DeviceActivity",
  pairs: [
    {
      key: "DEVICE_ACTIVITY_NONE",
      val: 0
    },
    {
      key: "DEVICE_ACTIVITY_PAUSED",
      val: 0
    },
    {
      key: "DEVICE_ACTIVITY_RUNNING",
      val: 0
    }
  ]
};

const devicePlatform: data = {
  name: "DevicePlatform",
  pairs: [
    {
      key: "DEVICE_PLATFORM_INVALID",
      val: 0
    },
    {
      key: "DEVICE_PLATFORM_PHOTON",
      val: 1
    },
    {
      key: "DEVICE_PLATFORM_ELECTRON",
      val: 2
    },
    {
      key: "DEVICE_PLATFORM_V2_CELLULAR",
      val: 3
    },
    {
      key: "DEVICE_PLATFORM_V2_WIFI",
      val: 4
    },
    {
      key: "DEVICE_PLATFORM_V2_WIFI_S3",
      val: 5
    },
    {
      key: "DEVICE_PLATFORM_V2_CELLULAR_S3",
      val: 6
    },
    {
      key: "DEVICE_PLATFORM_CELLULAR_BLACK",
      val: 7
    },
    {
      key: "DEVICE_PLATFORM_CELLULAR_GREEN",
      val: 8
    }
  ]
};

const deviceProduct: data = {
  name: "DeviceProduct",
  pairs: [
    {
      key: "DEVICE_PRODUCT_NONE",
      val: 0
    },
    {
      key: "DEVICE_PRODUCT_BINDAPT_PLUS",
      val: 1
    },
    {
      key: "DEVICE_PRODUCT_BINDAPT_PLUS_PRO",
      val: 2
    },
    {
      key: "DEVICE_PRODUCT_BINDAPT_PLUS_LITE",
      val: 3
    },
    {
      key: "DEVICE_PRODUCT_BINDAPT_MINI",
      val: 4
    },
    {
      key: "DEVICE_PRODUCT_WEATHER_STATION",
      val: 5
    },
    {
      key: "DEVICE_PRODUCT_NEXUS_ST",
      val: 6
    },
    {
      key: "DEVICE_PRODUCT_OMNIAIR",
      val: 7
    },
    {
      key: "DEVICE_PRODUCT_BIN_MONITOR",
      val: 8
    },
    {
      key: "DEVICE_PRODUCT_BIN_HALO",
      val: 9
    },
    {
      key: "DEVICE_PRODUCT_BIN_ULTIMATE",
      val: 10
    },
    {
      key: "DEVICE_PRODUCT_MIPCA_V2",
      val: 11
    },
    {
      key: "DEVICE_PRODUCT_BINDAPT_PLUS_MOD",
      val: 12
    },
    {
      key: "DEVICE_PRODUCT_BINDAPT_PLUS_PRO_MOD",
      val: 13
    },
    {
      key: "DEVICE_PRODUCT_BINDAPT_V2_MONITOR",
      val: 14
    },
    {
      key: "DEVICE_PRODUCT_BINDAPT_V2_AUTOMATE",
      val: 15
    }
  ]
};

const deviceState: data = {
  name: "DeviceState",
  pairs: [
    {
      key: "DEVICE_STATE_UNKNOWN",
      val: 0
    },
    {
      key: "DEVICE_STATE_OK",
      val: 1
    },
    {
      key: "DEVICE_STATE_LOW_POWER",
      val: 2
    },
    {
      key: "DEVICE_STATE_MISSING",
      val: 3
    }
  ]
};

const fieldMarkerType: data = {
  name: "FieldMarkerType",
  pairs: [
    {
      key: "FIELD_MARKER_TYPE_PESTS",
      val: 0
    },
    {
      key: "FIELD_MARKER_TYPE_WEEDS",
      val: 1
    },
    {
      key: "FIELD_MARKER_TYPE_DISEASE",
      val: 2
    },
    {
      key: "FIELD_MARKER_TYPE_OTHER",
      val: 3
    },
    {
      key: "FIELD_MARKER_TYPE_ROCKS",
      val: 4
    }
  ]
};

const interactionsResultType: data = {
  name: "InteractionResultType",
  pairs: [
    {
      key: "INTERACTION_RESULT_TYPE_INVALID",
      val: 0
    },
    {
      key: "INTERACTION_RESULT_TYPE_REPORT",
      val: 1
    },
    {
      key: "INTERACTION_RESULT_TYPE_TOGGLE",
      val: 2
    },
    {
      key: "INTERACTION_RESULT_TYPE_RUN",
      val: 3
    },
    {
      key: "INTERACTION_RESULT_TYPE_SET",
      val: 4
    }
  ]
};

const limitStatus: data = {
  name: "LimitStatus",
  pairs: [
    {
      key: "LIMIT_STATUS_NONE",
      val: 0
    },
    {
      key: "LIMIT_STATUS_UNDER",
      val: 1
    },
    {
      key: "LIMIT_STATUS_OVER",
      val: 2
    }
  ]
};

const measurementType: data = {
  name: "MeasurementType",
  pairs: [
    {
      key: "MEASUREMENT_TYPE_INVALID",
      val: 0
    },
    {
      key: "MEASUREMENT_TYPE_TEMPERATURE",
      val: 1
    },
    {
      key: "MEASUREMENT_TYPE_BOOLEAN",
      val: 2
    },
    {
      key: "MEASUREMENT_TYPE_VOLTAGE",
      val: 3
    },
    {
      key: "MEASUREMENT_TYPE_PERCENT",
      val: 4
    },
    {
      key: "MEASUREMENT_TYPE_PRESSURE",
      val: 5
    },
    {
      key: "MEASUREMENT_TYPE_RSSI",
      val: 6
    },
    {
      key: "MEASUREMENT_TYPE_LIGHT",
      val: 7
    },
    {
      key: "MEASUREMENT_TYPE_PPM",
      val: 8
    },
    {
      key: "MEASUREMENT_TYPE_ANALOG",
      val: 9
    },
    {
      key: "MEASUREMENT_TYPE_ORP",
      val: 10
    },
    {
      key: "MEASUREMENT_TYPE_PH",
      val: 11
    },
    {
      key: "MEASUREMENT_TYPE_PPB",
      val: 12
    },
    {
      key: "MEASUREMENT_TYPE_CONDUCTIVITY",
      val: 13
    },
    {
      key: "MEASUREMENT_TYPE_CAPACITANCE",
      val: 14
    },
    {
      key: "MEASUREMENT_TYPE_WEIGHT",
      val: 15
    },
    {
      key: "MEASUREMENT_TYPE_DISTANCE_CM",
      val: 16
    },
    {
      key: "MEASUREMENT_TYPE_SPEED",
      val: 17
    },
    {
      key: "MEASUREMENT_TYPE_RAIN",
      val: 18
    },
    {
      key: "MEASUREMENT_TYPE_GRAIN_EMC",
      val: 19
    },
    {
      key: "MEASUREMENT_TYPE_EDGE_RISES",
      val: 20
    },
    {
      key: "MEASUREMENT_TYPE_EDGE_FALLS",
      val: 21
    },
    {
      key: "MEASUREMENT_TYPE_STATE_CHANGES",
      val: 22
    },
    {
      key: "MEASUREMENT_TYPE_FULL_SPECTRUM",
      val: 23
    },
    {
      key: "MEASUREMENT_TYPE_INFRARED",
      val: 24
    },
    {
      key: "MEASUREMENT_TYPE_LUX",
      val: 25
    },
    {
      key: "MEASUREMENT_TYPE_VISIBLE_LIGHT",
      val: 26
    },
    {
      key: "MEASUREMENT_TYPE_CAPACITANCE_GND_FF",
      val: 27
    },
    {
      key: "MEASUREMENT_TYPE_CAPACITANCE_SELF_FF",
      val: 28
    },
    {
      key: "MEASUREMENT_TYPE_CFM",
      val: 29
    },
    {
      key: "MEASUREMENT_TYPE_DUST_1UG",
      val: 30
    },
    {
      key: "MEASUREMENT_TYPE_DUST_2UG",
      val: 31
    },
    {
      key: "MEASUREMENT_TYPE_DUST_4UG",
      val: 32
    },
    {
      key: "MEASUREMENT_TYPE_DUST_10UG",
      val: 33
    },
    {
      key: "MEASUREMENT_TYPE_VOC_INDEX",
      val: 34
    },
    {
      key: "MEASUREMENT_TYPE_NOX_INDEX",
      val: 35
    },
    {
      key: "MEASUREMENT_TYPE_FREQUENCY",
      val: 36
    },
    {
      key: "MEASUREMENT_TYPE_SINGLE_ACCELERATION_RMS",
      val: 37
    },
    {
      key: "MEASUREMENT_TYPE_TOTAL_ACCELERATION_RMS",
      val: 38
    },
    {
      key: "MEASUREMENT_TYPE_BUSHEL_CABLE",
      val: 39
    }
  ]
};

const mutator: data = {
  name: "Mutator",
  pairs: [
    {
      key: "MUTATOR_NONE",
      val: 0
    },
    {
      key: "MUTATOR_EMC",
      val: 1
    },
    {
      key: "MUTATOR_CFM",
      val: 2
    },
    {
      key: "MUTATOR_FUEL_LEVEL",
      val: 3
    }
  ]
};

const objectType: data = {
  name: "ObjectType",
  pairs: [
    {
      key: "OBJECT_TYPE_UNKNOWN",
      val: 0
    },
    {
      key: "OBJECT_TYPE_BIN",
      val: 1
    },
    {
      key: "OBJECT_TYPE_SITE",
      val: 2
    },
    {
      key: "OBJECT_TYPE_DEVICE",
      val: 3
    },
    {
      key: "OBJECT_TYPE_COMPONENT",
      val: 4
    },
    {
      key: "OBJECT_TYPE_INTERACTION",
      val: 5
    },
    {
      key: "OBJECT_TYPE_NOTE",
      val: 6
    },
    {
      key: "OBJECT_TYPE_FIELD",
      val: 7
    },
    {
      key: "OBJECT_TYPE_FIELDMARKER",
      val: 8
    },
    {
      key: "OBJECT_TYPE_HOMEMARKER",
      val: 9
    },
    {
      key: "OBJECT_TYPE_HARVESTPLAN",
      val: 10
    },
    {
      key: "OBJECT_TYPE_TASK",
      val: 11
    },
    {
      key: "OBJECT_TYPE_MINE",
      val: 12
    },
    {
      key: "OBJECT_TYPE_BINYARD",
      val: 13
    },
    {
      key: "OBJECT_TYPE_TERMINAL",
      val: 14
    },
    {
      key: "OBJECT_TYPE_GATE",
      val: 15
    },
    {
      key: "OBJECT_TYPE_FIRMWARE",
      val: 16
    },
    {
      key: "OBJECT_TYPE_GROUP",
      val: 17
    },
    {
      key: "OBJECT_TYPE_UPGRADE",
      val: 18
    },
    {
      key: "OBJECT_TYPE_USER",
      val: 19
    },
    {
      key: "OBJECT_TYPE_BACKPACK",
      val: 20
    },
    {
      key: "OBJECT_TYPE_HARVESTYEAR",
      val: 21
    },
    {
      key: "OBJECT_TYPE_TAG",
      val: 22
    },
    {
      key: "OBJECT_TYPE_TEAM",
      val: 23
    },
    {
      key: "OBJECT_TYPE_LINK",
      val: 24
    },
    {
      key: "OBJECT_TYPE_HEATER",
      val: 25
    },
    {
      key: "OBJECT_TYPE_GRAIN_BAG",
      val: 26
    },
    {
      key: "OBJECT_TYPE_CONTRACT",
      val: 27
    },
    {
      key: "OBJECT_TYPE_TRANSACTION",
      val: 28
    },
    {
      key: "OBJECT_TYPE_FILE",
      val: 29
    },
    {
      key: "OBJECT_TYPE_NOTIFICATION_BANNER",
      val: 30
    },
    {
      key: "OBJECT_TYPE_DEVICE_PRESET",
      val: 31
    }
  ]
};

const permission: data = {
  name: "Permission",
  pairs: [
    {
      key: "PERMISSION_INVALID",
      val: 0
    },
    {
      key: "PERMISSION_USERS",
      val: 1
    },
    {
      key: "PERMISSION_READ",
      val: 2
    },
    {
      key: "PERMISSION_WRITE",
      val: 3
    },
    {
      key: "PERMISSION_SHARE",
      val: 4
    },
    {
      key: "PERMISSION_BILLING",
      val: 5
    },
    {
      key: "PERMISSION_FILE_MANAGEMENT",
      val: 6
    }
  ]
};

const powerSubtype: data = {
  name: "PowerSubtype",
  pairs: [
    {
      key: "POWER_SUBTYPE_NONE",
      val: 0
    },
    {
      key: "POWER_SUBTYPE_NO_BATTERY",
      val: 1
    }
  ]
};

const relationalOperator: data = {
  name: "RelationalOperator",
  pairs: [
    {
      key: "RELATIONAL_OPERATOR_INVALID",
      val: 0
    },
    {
      key: "RELATIONAL_OPERATOR_EQUAL_TO",
      val: 1
    },
    {
      key: "RELATIONAL_OPERATOR_LESS_THAN",
      val: 2
    },
    {
      key: "RELATIONAL_OPERATOR_GREATER_THAN",
      val: 3
    }
  ]
};

const sleepType: data = {
  name: "SleepType",
  pairs: [
    {
      key: "SLEEP_TYPE_NONE",
      val: 0
    },
    {
      key: "SLEEP_TYPE_NAP",
      val: 1
    },
    {
      key: "SLEEP_TYPE_DEEP",
      val: 2
    },
    {
      key: "SLEEP_TYPE_HIBERNATE",
      val: 3
    }
  ]
};

const transactionState: data = {
  name: "TransactionState",
  pairs: [
    {
      key: "TRANSACTION_STATE_UNKNOWN",
      val: 0
    },
    {
      key: "TRANSACTION_STATE_VALID",
      val: 1
    },
    {
      key: "TRANSACTION_STATE_CORRECTION",
      val: 2
    },
    {
      key: "TRANSACTION_STATE_REVOKED",
      val: 3
    },
    {
      key: "TRANSACTION_STATE_REVOKED_CORRECTION",
      val: 4
    },
    {
      key: "TRANSACTION_STATE_PENDING",
      val: 5
    },
    {
      key: "TRANSACTION_STATE_REJECTED",
      val: 6
    }
  ]
};

//Ag enums start here
const binMode: data = {
  name: "BinMode",
  pairs: [
    {
      key: "BIN_MODE_NONE",
      val: 0
    },
    {
      key: "BIN_MODE_DRYING",
      val: 1
    },
    {
      key: "BIN_MODE_COOLDOWN",
      val: 2
    },
    {
      key: "BIN_MODE_STORAGE",
      val: 3
    },
    {
      key: "BIN_MODE_HYDRATING",
      val: 4
    }
  ]
};

const binStorage: data = {
  name: "BinStorage",
  pairs: [
    {
      key: "BINS_STORAGE_UNKNOWN",
      val: 0
    },
    {
      key: "BINS_STORAGE_SUPPORTED_GRAIN",
      val: 1
    },
    {
      key: "BINS_STORAGE_FERTILIZER",
      val: 2
    },
    {
      key: "BINS_STORAGE_UNSUPPORTED_GRAIN",
      val: 3
    }
  ]
};

const binShape: data = {
  name: "BinShape",
  pairs: [
    {
      key: "BIN_SHAPE_UNKNOWN",
      val: 0
    },
    {
      key: "BIN_SHAPE_FLAT_BOTTOM",
      val: 1
    },
    {
      key: "BIN_SHAPE_HOPPER_BOTTOM",
      val: 2
    }
  ]
};

const contractType: data = {
  name: "ContractType",
  pairs: [
    {
      key: "CONTRACT_TYPE_UNKNOWN",
      val: 0
    },
    {
      key: "CONTRACT_TYPE_GRAIN",
      val: 1
    }
  ]
};

const grain: data = {
  name: "Grain",
  pairs: [
    {
      key: "GRAIN_INVALID",
      val: 0
    },
    {
      key: "GRAIN_NONE",
      val: 1
    },
    {
      key: "GRAIN_BARLEY",
      val: 2
    },
    {
      key: "GRAIN_BUCKWHEAT",
      val: 3
    },
    {
      key: "GRAIN_CANOLA",
      val: 4
    },
    {
      key: "GRAIN_CORN",
      val: 5
    },
    {
      key: "GRAIN_OATS",
      val: 6
    },
    {
      key: "GRAIN_PEANUTS",
      val: 7
    },
    {
      key: "GRAIN_RAPESEED",
      val: 8
    },
    {
      key: "GRAIN_RICE_LONG",
      val: 9
    },
    {
      key: "GRAIN_RICE_MEDIUM",
      val: 10
    },
    {
      key: "GRAIN_RICE_SHORT",
      val: 11
    },
    {
      key: "GRAIN_SORGHUM",
      val: 12
    },
    {
      key: "GRAIN_SOYBEANS",
      val: 13
    },
    {
      key: "GRAIN_SUNFLOWER",
      val: 14
    },
    {
      key: "GRAIN_WHEAT_DURUM",
      val: 15
    },
    {
      key: "GRAIN_WHEAT_HARD_RED",
      val: 16
    },
    {
      key: "GRAIN_FLAX_UNRETTED",
      val: 17
    },
    {
      key: "GRAIN_FLAX_DEW_RETTED",
      val: 18
    },
    {
      key: "GRAIN_YELLOW_PEAS",
      val: 19
    },
    {
      key: "GRAIN_LENTILS_ROBIN",
      val: 20
    },
    {
      key: "GRAIN_LENTILS_REDBERRY",
      val: 21
    },
    {
      key: "GRAIN_LENTILS_BLAZE",
      val: 22
    },
    {
      key: "GRAIN_CUSTOM",
      val: 23
    }
  ]
};

//aviation enums start here
const gateComponentType: data = {
  name: "gateComponentType",
  pairs: [
    {
      key: "GATE_COMPONENT_TYPE_UNKNOWN",
      val: 0
    },
    {
      key: "GATE_COMPONENT_TYPE_TEMP",
      val: 1
    },
    {
      key: "GATE_COMPONENT_TYPE_PRESSURE",
      val: 2
    },
    {
      key: "GATE_COMPONENT_TYPE_AMBIENT",
      val: 3
    }
  ]
};

const pcaState: data = {
  name: "PCAState",
  pairs: [
    {
      key: "PCA_STATE_UNKNOWN",
      val: 0
    },
    {
      key: "PCA_STATE_OFF",
      val: 1
    },
    {
      key: "PCA_STATE_IN_BOUNDS",
      val: 2
    },
    {
      key: "PCA_STATE_OUT_BOUNDS",
      val: 3
    }
  ]
};

//mining enums start here
const placeableType: data = {
  name: "placeableType",
  pairs: [
    {
      key: "PLACEABLE_TYPE_INVALID",
      val: 0
    },
    {
      key: "PLACEABLE_TYPE_DEFAULT",
      val: 1
    },
    {
      key: "PLACEABLE_TYPE_FAN",
      val: 2
    },
    {
      key: "PLACEABLE_TYPE_SHAFT",
      val: 3
    },
    {
      key: "PLACEABLE_TYPE_SENSOR",
      val: 4
    }
  ]
};

//construction enums start here
const fuelType: data = {
  name: "FuelType",
  pairs: [
    {
      key: "FUEL_TYPE_UNKNOWN",
      val: 0
    },
    {
      key: "FUEL_TYPE_GASOLINE",
      val: 1
    },
    {
      key: "FUEL_TYPE_DIESEL",
      val: 2
    },
    {
      key: "FUEL_TYPE_PROPANE",
      val: 3
    }
  ]
};

export default function GeneralEnums() {
  const enumList = [
    addressType,
    componentType,
    contractType,
    binMode,
    binShape,
    binStorage,
    deviceActivity,
    devicePlatform,
    deviceProduct,
    deviceState,
    fieldMarkerType,
    fuelType,
    gateComponentType,
    grain,
    interactionsResultType,
    limitStatus,
    measurementType,
    mutator,
    objectType,
    pcaState,
    permission,
    placeableType,
    powerSubtype,
    relationalOperator,
    sleepType,
    transactionState
  ];

  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Enumerators
      </Typography>
      <List>
        {enumList.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EnumeratorDisplay data={e} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

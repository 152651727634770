import { cloneDeep } from "lodash";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

//model not used
export class BinYard {
  public settings: pond.BinYardSettings = pond.BinYardSettings.create();
  public permissions: pond.Permission[] = [];

  public static create(pb?: pond.BinYard): BinYard {
    let my = new BinYard();
    if (pb) {
      my.settings = pond.BinYardSettings.fromObject(cloneDeep(or(pb.settings, {})));
      my.permissions = pb.yardPermissions;
    }
    return my;
  }

  public static clone(other?: BinYard): BinYard {
    if (other) {
      return BinYard.create(
        pond.BinYard.fromObject({
          settings: cloneDeep(other.settings),
          binPermissions: cloneDeep(other.permissions)
        })
      );
    }
    return BinYard.create();
  }

  public static any(data: any): BinYard {
    return BinYard.create(pond.BinYard.fromObject(cloneDeep(data)));
  }

  public key(): string {
    return this.settings.key;
  }
}

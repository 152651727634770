import { cloneDeep } from "lodash";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

export class HomeMarker {
  public settings: pond.HomeMarkerSettings = pond.HomeMarkerSettings.create();
  public status: pond.HomeMarkerStatus = pond.HomeMarkerStatus.create();

  public static create(hm?: pond.HomeMarker): HomeMarker {
    let my = new HomeMarker();
    if (hm) {
      my.settings = pond.HomeMarkerSettings.fromObject(cloneDeep(or(hm.settings, {})));
      my.status = pond.HomeMarkerStatus.fromObject(cloneDeep(or(hm.status, {})));
    }
    return my;
  }

  public static clone(other?: HomeMarker): HomeMarker {
    if (other) {
      return HomeMarker.create(
        pond.HomeMarker.fromObject({
          settings: cloneDeep(other.settings),
          status: cloneDeep(other.status)
        })
      );
    }
    return HomeMarker.create();
  }

  public static any(data: any): HomeMarker {
    return HomeMarker.create(pond.HomeMarker.fromObject(cloneDeep(data)));
  }

  public key(): string {
    return this.settings.key;
  }

  public long(): number {
    return this.settings.longitude;
  }

  public lat(): number {
    return this.settings.latitude;
  }
}

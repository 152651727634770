import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import CNHiAccess from "integrations/CNHi/CNHiAccess";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers";
import { useCNHiProxyAPI } from "providers/pond/cnhiProxyAPI";
import React, { useEffect, useState } from "react";
import PageContainer from "./PageContainer";

export default function CNHi() {
  const [currentOrg, setCurrentOrg] = useState("");
  const cnhiAPI = useCNHiProxyAPI();
  const [organizations, setOrganizations] = useState<Map<string, pond.JDAccount>>(new Map());
  const [fieldAccordion, setFieldAccordion] = useState(false);
  const [dataOptions, setDataOptions] = useState<pond.DataOption[]>([]);
  const [{ as }] = useGlobalState();

  //load organizations for the user
  useEffect(() => {
    setCurrentOrg("");
    cnhiAPI
      .listAccounts(0, 0, as)
      .then(resp => {
        let tempOrgs: Map<string, pond.JDAccount> = new Map();
        resp.data.accounts.forEach(org => {
          let organization = pond.JDAccount.fromObject(org);
          tempOrgs.set(organization.key, organization);
        });
        setOrganizations(tempOrgs);
      })
      .catch(err => {});
  }, [cnhiAPI, as]);

  useEffect(() => {
    let organization = organizations.get(currentOrg);
    if (organization) {
      let currentOptions: pond.DataOption[] = organization.options ?? [];
      setDataOptions(currentOptions);
    }
  }, [currentOrg, organizations]);

  const updateOrgData = (checked: boolean, option: pond.DataOption) => {
    let currentOps: pond.DataOption[] = dataOptions;
    if (checked && !currentOps.includes(option)) {
      currentOps.push(option);
    } else if (!checked && currentOps.includes(option)) {
      currentOps.splice(currentOps.indexOf(option), 1);
    }
    setDataOptions([...currentOps]);
  };

  const submit = () => {
    cnhiAPI.updateAccount(currentOrg, dataOptions, as ?? undefined).then(resp => {
      //update the organization in the map to have the correct dataOptions
      let org = organizations.get(currentOrg);
      if (org) {
        org.options = dataOptions;
      }
    });
  };

  const fieldOptions = () => {
    return (
      <React.Fragment>
        <Accordion
          expanded={fieldAccordion}
          onChange={(_, expanded) => {
            setFieldAccordion(expanded);
          }}>
          <AccordionSummary expandIcon={<ExpandMore />}>Field Data</AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" spacing={2} alignItems="center" alignContent="center">
              <Grid item xs={6}>
                <FormControlLabel
                  label="Field Boundaries"
                  control={
                    <Checkbox
                      checked={dataOptions.includes(pond.DataOption.DATA_OPTION_FIELDS)}
                      onChange={(_, checked) => {
                        //setFields(!fields);
                        updateOrgData(checked, pond.DataOption.DATA_OPTION_FIELDS);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                View Field Boundaries from Case New Holland on your visual Farm and set up harvest
                plans and track tasks
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  };
  return (
    <PageContainer>
      <CNHiAccess />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignContent="center"
        alignItems="center"
        style={{ padding: 10 }}>
        <Grid item>
          <Select
            //style={{ maxWidth: 110 }}
            title="John Deer Account"
            displayEmpty
            disableUnderline={true}
            value={currentOrg}
            onChange={(event: any) => {
              setCurrentOrg(event.target.value);
            }}>
            <MenuItem key={""} value={""}>
              Select account to adjust accessable data
            </MenuItem>
            {Array.from(organizations.values()).map(org => {
              return (
                <MenuItem key={org.key} value={org.key}>
                  {org.username}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item>
          <Button onClick={submit} variant="contained" color="primary">
            Update
          </Button>
        </Grid>
      </Grid>
      <Box style={{ padding: 10 }}>{fieldOptions()}</Box>
    </PageContainer>
  );
}

import {
  AppBar,
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Toolbar,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ResponsiveDialog from "common/ResponsiveDialog";
import { useMobile } from "hooks";
import { Bin, Component } from "models";
import { pond } from "protobuf-ts/pond";
import React, { useEffect, useState } from "react";
import BinComponents from "./BinComponents";

type Mode = "add" | "update" | "remove";

interface Props {
  open: boolean;
  onClose: (refresh?: boolean) => void;
  mode?: Mode;
  bin?: Bin;
  canEdit: boolean;
  openedBinYard?: string;
  userID: string;
  coords?: { longitude: number; latitude: number };
  binYards?: pond.BinYardSettings[];
  components?: Map<string, Component>;
  setComponents?: React.Dispatch<React.SetStateAction<Map<string, Component>>>;
  updateBinStatus?: (componentKeys: string[], removed?: boolean) => void;
}

export default function BinSensors(props: Props) {
  const isMobile = useMobile();
  const {
    open,
    bin,
    onClose,
    mode,
    openedBinYard,
    components,
    setComponents,
    updateBinStatus
  } = props;
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (open) {
      setInitialized(true);
    } else {
      setInitialized(false);
    }
  }, [bin, open, mode, openedBinYard]);

  const close = (refresh?: boolean) => {
    onClose(refresh);
  };

  const titleText = () => {
    return "Add Sensors to " + (bin ? bin.name() : "Bin");
  };

  const title = () => {
    if (isMobile) {
      return (
        <AppBar position="relative">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => close()} aria-label="close">
              <Close />
            </IconButton>
            <Typography variant="h6" style={{ flex: 1 }}>
              {titleText()}
            </Typography>
          </Toolbar>
        </AppBar>
      );
    }

    return (
      <React.Fragment>
        <DialogTitle>{titleText()}</DialogTitle>
      </React.Fragment>
    );
  };

  const componentsForm = () => {
    if (bin) {
      return (
        <Box padding={1}>
          <BinComponents
            components={components ? components : undefined}
            setComponents={setComponents}
            bin={bin.key()}
            updateBinStatus={updateBinStatus}
          />
        </Box>
      );
    }

    return null;
  };

  const actions = () => {
    return (
      <DialogActions>
        <Grid container justify="space-between" direction="row">
          <Grid item xs={7} container justify="flex-end">
            {!isMobile && (
              <React.Fragment>
                <Button onClick={() => close()} aria-label="Cancel">
                  Done
                </Button>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    );
  };

  if (!open || !initialized) {
    return null;
  }

  return (
    <ResponsiveDialog
      maxWidth={"xs"}
      fullWidth
      fullScreen={isMobile}
      open={open}
      scroll="paper"
      onClose={() => {
        onClose();
      }}>
      {title()}
      {componentsForm()}
      {actions()}
    </ResponsiveDialog>
  );
}

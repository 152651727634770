import EditWhite from "assets/products/Ag/editWhite.png";
import EditBlack from "assets/products/Ag/editBlack.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function EditIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? EditWhite : EditBlack;
    }

    return themeType === "light" ? EditBlack : EditWhite;
  };

  return <ImgIcon alt="fields" src={src()} />;
}

import FieldListWhite from "assets/products/Ag/fieldListWhite.png";
import FieldListBlack from "assets/products/Ag/fieldListBlack.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function FieldListIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? FieldListWhite : FieldListBlack;
    }

    return themeType === "light" ? FieldListBlack : FieldListWhite;
  };

  return <ImgIcon alt="fields" src={src()} />;
}

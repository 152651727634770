import { cloneDeep } from "lodash";
import { MarkerData } from "Maps/mapMarkers/Markers";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

export class Gate {
  public settings: pond.GateSettings = pond.GateSettings.create();
  public name: string = "Gate";
  public key: string = "";
  public preferences: any = {};
  public gateMutations: any = {};
  public pcaState: pond.PCAState = pond.PCAState.PCA_STATE_UNKNOWN;

  public static create(pb?: pond.Gate): Gate {
    let my = new Gate();
    if (pb) {
      let g = pond.Gate.fromObject(pb);
      my.settings = pond.GateSettings.fromObject(cloneDeep(or(g.settings, {})));
      my.name = g.name;
      my.key = g.key;
      my.preferences = g.componentPreferences;
      my.gateMutations = g.gateMutations;
      my.pcaState = g.pcaState;
    }
    return my;
  }

  public static clone(other?: Gate): Gate {
    if (other) {
      return Gate.create(
        pond.Gate.fromObject({
          name: other.name,
          key: other.key,
          preferences: other.preferences,
          gateMutations: other.gateMutations,
          settings: cloneDeep(other.settings)
        })
      );
    }
    return Gate.create();
  }

  public static any(data: any): Gate {
    return Gate.create(pond.Gate.fromObject(cloneDeep(data)));
  }

  public longitude(): number {
    return this.settings.longitude;
  }

  public latitude(): number {
    return this.settings.latitude;
  }

  public terminal(): string {
    return this.settings.terminal;
  }

  public upperFlow(): number {
    return this.settings.upperFlow;
  }

  public lowerFlow(): number {
    return this.settings.lowerFlow;
  }

  public ductDiameter(): number {
    return this.settings.ductDiameter;
  }

  public gateMarkerColour(): string {
    switch (this.pcaState) {
      case pond.PCAState.PCA_STATE_OFF:
        return "grey";
      case pond.PCAState.PCA_STATE_IN_BOUNDS:
        return "green";
      case pond.PCAState.PCA_STATE_OUT_BOUNDS:
        return "red";
      default:
        return "#337acc";
    }
  }

  public getMarkerData(
    clickFunc?: (event: React.PointerEvent<HTMLElement>, index: number, isMobile: boolean) => void,
    updateFunc?: (location: pond.Location) => void
  ): MarkerData {
    let m: MarkerData = {
      longitude: this.longitude(),
      latitude: this.latitude(),
      title: this.name,
      colour: this.gateMarkerColour(),
      visibleLevels: { min: 15 },
      clickFunc: clickFunc,
      updateFunc: updateFunc
    };
    return m;
  }
}

import AddBinDark from "assets/products/bindapt/addBin.png";
import AddBinLight from "assets/products/bindapt/addBinLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function AddBinIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? AddBinLight : AddBinDark;
    }

    return themeType === "light" ? AddBinDark : AddBinLight;
  };

  return <ImgIcon alt="newBin" src={src()} />;
}

import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ResponsiveDialog from "common/ResponsiveDialog";
import { useDeviceAPI, useSnackbar } from "hooks";
import { getContextKeys, getContextTypes } from "pbHelpers/Context";
import React, { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlight: {
      color: theme.palette.secondary.dark
    }
  })
);

interface Props {
  deviceID: number;
  deviceName: string;
  open: boolean;
  close: (refresh: boolean) => void;
}

export default function Connection(props: Props) {
  const classes = useStyles();
  const deviceAPI = useDeviceAPI();
  const { success, error } = useSnackbar();
  const { deviceID, deviceName, open, close } = props;
  const [gateway, setGateway] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const isGatewayValid = (): boolean => {
    if (!gateway) {
      return true;
    }
    return gateway.length <= 32 && gateway.length > 0;
  };

  const isPasswordValid = (): boolean => {
    if (!password) {
      return true;
    }

    return password.length <= 32;
  };

  const isConnectionValid = (): boolean => {
    return isGatewayValid() && isPasswordValid();
  };

  const handleClose = () => {
    close(false);
    setGateway("");
    setPassword("");
    setPasswordVisible(false);
  };

  const handleSubmit = () => {
    if (isConnectionValid()) {
      let keys = getContextKeys();
      let types = getContextTypes();
      deviceAPI
        .setWifi(deviceID, gateway, password, keys, types)
        .then(() => success("Connection settings sent to " + deviceName))
        .catch(() => error("Failed to send connection settings to " + deviceName))
        .finally(() => handleClose());
    }
  };

  return (
    <ResponsiveDialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="device-connection">
      <DialogTitle id="device-connection-title">
        Connection
        <Typography variant="body2" color="textSecondary">
          {deviceName}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          By default your Wi-Fi enabled device will try to connect to the gateway{" "}
          <span className={classes.highlight}>device</span> with the password{" "}
          <span className={classes.highlight}>configure</span>. Your device must be connected to the
          internet to change its Wi-Fi credentials.
        </Typography>
        <TextField
          id="gateway"
          name="gateway"
          label="Gateway"
          value={gateway}
          onChange={event => setGateway(event.target.value)}
          error={!isGatewayValid()}
          helperText={isGatewayValid() ? "" : "Cannot be more than 32 characters"}
          margin="normal"
          fullWidth
          variant="outlined"
        />
        <TextField
          type={passwordVisible ? "text" : "password"}
          id="password"
          name="password"
          label="Password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          error={!isPasswordValid()}
          helperText={isPasswordValid() ? "" : "Cannot be more than 32 characters"}
          margin="normal"
          fullWidth
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setPasswordVisible(!passwordVisible)}>
                  {passwordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()} color="primary" disabled={!isConnectionValid()}>
          Submit
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

import BindaptCellularDarkIcon from "assets/products/bindapt/bindaptCellularDark.png";
import BindaptCellularLightIcon from "assets/products/bindapt/bindaptCellularLight.png";
import BindaptPlusDarkIcon from "assets/products/bindapt/bindaptPlusDark.png";
import BindaptPlusLightIcon from "assets/products/bindapt/bindaptPlusLight.png";
import BindaptPlusProDarkIcon from "assets/products/bindapt/bindaptPlusProDark.png";
import BindaptPlusProLightIcon from "assets/products/bindapt/bindaptPlusProLight.png";
import BindaptWifiDarkIcon from "assets/products/bindapt/bindaptWifiDark.png";
import BindaptWifiLightIcon from "assets/products/bindapt/bindaptWifiLight.png";
import { DeviceProductDescriber, ProductTab } from "products/DeviceProduct";
import { pond } from "protobuf-ts/pond";
import {
  BindaptPlusAvailability,
  BindaptPlusProAvailability,
  BindaptPlusLiteAvailability,
  BindaptMiniAvailability,
  BindaptUltimateAvailability,
  BinMonitorAvailability,
  BindaptPlusModAvailability,
  BindaptPlusProModAvailability,
  BindaptV2MonitorAvailability,
  BindaptV2AutomateAvailability
} from "./BindaptAvailability";

const getBindaptPlatformIcon = (
  platform: pond.DevicePlatform,
  theme?: "light" | "dark"
): string | undefined => {
  switch (platform) {
    case pond.DevicePlatform.DEVICE_PLATFORM_PHOTON:
      return theme === "light" ? BindaptWifiDarkIcon : BindaptWifiLightIcon;
    case pond.DevicePlatform.DEVICE_PLATFORM_ELECTRON:
      return theme === "light" ? BindaptCellularDarkIcon : BindaptCellularLightIcon;
    default:
      return undefined;
  }
};

const getBindaptTabs = (): ProductTab[] => {
  return ["presets", "components"];
};

export const BindaptPlus: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_PLUS,
  label: "Bindapt+",
  icon: (_, theme?: "light" | "dark") => {
    return theme === "light" ? BindaptPlusDarkIcon : BindaptPlusLightIcon;
  },
  view: "controller",
  tabs: getBindaptTabs(),
  availability: BindaptPlusAvailability,
  defaultAddressMap: new Map<string, string[]>([
    ["plenum", ["9-2-64"]],
    ["pressure", ["6-2-24"]],
    ["heatersFans", ["3-1-512"]] //refers to heaters and fans
  ])
};

export const BindaptPlusMod: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_PLUS_MOD,
  label: "Bindapt+ V1.5",
  icon: (_, theme?: "light" | "dark") => {
    return theme === "light" ? BindaptPlusDarkIcon : BindaptPlusLightIcon;
  },
  view: "controller",
  tabs: getBindaptTabs(),
  availability: BindaptPlusModAvailability
  //TODO not sure what the default address map for the modded products should be yet
  // defaultAddressMap: new Map<string, string[]>([
  //   ["plenum", ["9-2-64"]],
  //   ["heatersFans", ["3-1-512"]] //refers to heaters and fans
  // ])
};

export const BindaptPlusPro: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_PLUS_PRO,
  label: "Bindapt+ Pro",
  icon: (_, theme?: "light" | "dark") => {
    return theme === "light" ? BindaptPlusProDarkIcon : BindaptPlusProLightIcon;
  },
  view: "controller",
  tabs: getBindaptTabs(),
  availability: BindaptPlusProAvailability,
  defaultAddressMap: new Map<string, string[]>([
    ["plenum", ["9-2-64"]],
    ["pressure", ["6-2-24"]],
    ["heatersFans", ["3-1-512", "3-1-1024"]] //refers to heaters and fans
  ])
};

export const BindaptPlusProMod: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_PLUS_PRO_MOD,
  label: "Bindapt+ Pro V1.5",
  icon: (_, theme?: "light" | "dark") => {
    return theme === "light" ? BindaptPlusProDarkIcon : BindaptPlusProLightIcon;
  },
  view: "controller",
  tabs: getBindaptTabs(),
  availability: BindaptPlusProModAvailability
  //TODO not sure what the default address map should be yet
  // defaultAddressMap: new Map<string, string[]>([
  //   ["plenum", ["9-2-64"]],
  //   ["heatersFans", ["3-1-512", "3-1-1024"]] //refers to heaters and fans
  // ])
};

export const BindaptPlusLite: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_PLUS_LITE,
  label: "Bindapt+ Lite",
  icon: getBindaptPlatformIcon,
  tabs: getBindaptTabs(),
  availability: BindaptPlusLiteAvailability
};

export const BindaptMini: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_MINI,
  label: "Bindapt Mini",
  icon: getBindaptPlatformIcon,
  tabs: getBindaptTabs(),
  availability: BindaptMiniAvailability
};

export const BinUltimate: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_BIN_ULTIMATE,
  label: "Bindapt Ultimate",
  icon: getBindaptPlatformIcon,
  tabs: getBindaptTabs(),
  view: "controller",
  availability: BindaptUltimateAvailability,
  defaultAddressMap: new Map<string, string[]>([
    ["headspace", ["9-2-64", "28-2-98"]], //is both the dht and lidar components in the halo
    ["pressure", ["6-2-24"]],
    ["heatersFans", ["3-1-512", "3-1-1024", "3-1-128", "3-1-256"]] //refers to heaters and fans
  ])
};

export const BinMonitor: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_BIN_MONITOR,
  label: "Bin Monitor",
  icon: getBindaptPlatformIcon,
  tabs: getBindaptTabs(),
  availability: BinMonitorAvailability,
  defaultAddressMap: new Map<string, string[]>([
    ["headspace", ["9-2-64", "28-2-98"]], //is both the dht and lidar components in the halo
    ["pressure", ["6-2-24"]]
  ])
};

export const BinHalo: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_BIN_HALO,
  label: "Bin Halo",
  icon: getBindaptPlatformIcon,
  tabs: getBindaptTabs(),
  view: "controller",
  availability: BindaptPlusAvailability,
  defaultAddressMap: new Map<string, string[]>([
    ["headspace", ["9-2-64", "28-2-98"]], //is both the dht and lidar components in the halo
    ["pressure", ["6-2-24"]],
    ["heatersFans", ["3-1-512"]] //refers to heaters and fans
  ])
};

export const BindaptV2Monitor: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_V2_MONITOR,
  label: "Bindapt+ V2 Monitor",
  icon: (_, theme?: "light" | "dark") => {
    return theme === "light" ? BindaptPlusDarkIcon : BindaptPlusLightIcon;
  },
  view: "controller",
  tabs: getBindaptTabs(),
  availability: BindaptV2MonitorAvailability
  //TODO not sure what the default address map for these should be yet
  // defaultAddressMap: new Map<string, string[]>([
  //   ["plenum", ["9-2-64"]],
  //   ["heatersFans", ["3-1-512"]] //refers to heaters and fans
  // ])
};

export const BindaptV2Automate: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_V2_AUTOMATE,
  label: "Bindapt+ V2 Automate",
  icon: (_, theme?: "light" | "dark") => {
    return theme === "light" ? BindaptPlusDarkIcon : BindaptPlusLightIcon;
  },
  view: "controller",
  tabs: getBindaptTabs(),
  availability: BindaptV2AutomateAvailability
  //TODO not sure what the default address map for these should be yet
  // defaultAddressMap: new Map<string, string[]>([
  //   ["plenum", ["9-2-64"]],
  //   ["heatersFans", ["3-1-512"]] //refers to heaters and fans
  // ])
};

export function IsBindaptDevice(product?: pond.DeviceProduct): boolean {
  const bindaptProducts: pond.DeviceProduct[] = [
    pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_MINI,
    pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_PLUS,
    pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_PLUS_PRO,
    pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_PLUS_LITE,
    pond.DeviceProduct.DEVICE_PRODUCT_BIN_ULTIMATE,
    pond.DeviceProduct.DEVICE_PRODUCT_BIN_MONITOR,
    pond.DeviceProduct.DEVICE_PRODUCT_BIN_HALO,
    pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_PLUS_MOD,
    pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_PLUS_PRO_MOD,
    pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_V2_MONITOR,
    pond.DeviceProduct.DEVICE_PRODUCT_BINDAPT_V2_AUTOMATE
  ];

  return product ? bindaptProducts.includes(product) : false;
}

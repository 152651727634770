import Header from "app/Header";
import { useMobile } from "hooks";
import React, { useState } from "react";
import BottomNavigator from "./BottomNavigator";
import Routes from "./Routes";
import SideNavigator from "./SideNavigator";
import { Team } from "models";
import NotificationBanner from "common/NotificationBanner";

interface Props {
  toggleTheme: () => void;
  teams: Team[];
  setTeams: React.Dispatch<React.SetStateAction<Team[]>>;
}

export default function NavigationContainer(props: Props) {
  const { toggleTheme, teams, setTeams } = props;
  const [sideNavigatorOpen, setSideNavigatorOpen] = useState<boolean>(false);
  const isMobile = useMobile();

  const openSideNavigator = () => {
    setSideNavigatorOpen(true);
  };

  const closeSideMenu = () => {
    setSideNavigatorOpen(false);
  };

  return (
    <React.Fragment>
      <NotificationBanner />
      <Header
        sideIsOpen={sideNavigatorOpen}
        openSide={openSideNavigator}
        toggleTheme={toggleTheme}
        teams={teams}
        setTeams={setTeams}
      />
      <SideNavigator open={sideNavigatorOpen} onOpen={openSideNavigator} onClose={closeSideMenu} />
      <Routes />
      {isMobile && <BottomNavigator openSide={openSideNavigator} sideIsOpen={sideNavigatorOpen} />}
    </React.Fragment>
  );
}

import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { cloneDeep } from "lodash";

export class ShareableLink {
  public settings: pond.ShareableLinkSettings = pond.ShareableLinkSettings.create();
  public status: pond.ShareableLinkStatus = pond.ShareableLinkStatus.create();

  public static create(pb?: pond.ShareableLink): ShareableLink {
    let my = new ShareableLink();
    if (pb) {
      my.settings = pond.ShareableLinkSettings.fromObject(cloneDeep(or(pb.settings, {})));
      my.status = pond.ShareableLinkStatus.fromObject(cloneDeep(or(pb.status, {})));
    }
    return my;
  }

  public static any(data: any): ShareableLink {
    return ShareableLink.create(pond.ShareableLink.fromObject(cloneDeep(data)));
  }

  public static clone(link: ShareableLink): ShareableLink {
    let my = new ShareableLink();
    my.settings = pond.ShareableLinkSettings.fromObject(cloneDeep(link.settings));
    my.status = pond.ShareableLinkStatus.fromObject(cloneDeep(link.status));
    return my;
  }

  public key(): string {
    return this.settings.key;
  }
}

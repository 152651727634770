export type ThemeType = "light" | "dark";

export function getThemeType(): ThemeType {
  let theme = localStorage.getItem("theme");
  return theme === "light" ? "light" : "dark";
}

export function setThemeType(theme: ThemeType) {
  localStorage.setItem("theme", theme);
}

import { Auth0Provider } from "@auth0/auth0-react";
import { createStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { LocalizationProvider } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { ErrorBoundary } from "@sentry/react";
import "app/App.css";
import BackgroundTasks from "app/BackgroundTasks";
import { Team, User } from "models";
import NavigationContainer from "navigation/NavigationContainer";
import UnexpectedError from "pages/UnexpectedError";
import { GlobalState, GlobalStateAction, StateProvider } from "providers";
import AuthWrapper from "providers/auth";
import HTTPProvider from "providers/http";
import SnackbarProvider from "providers/Snackbar";
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { getAuth0ClientId } from "services/whiteLabel";
import { CreateTheme, getThemeType, setThemeType } from "theme";
import { or } from "utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appContent: {
      marginTop: 56,
      marginBottom: 56,
      marginLeft: "0px",
      [theme.breakpoints.up("sm")]: {
        marginTop: 64,
        marginBottom: 0
      },
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(9)
      }
    }
  })
);

const reducer = (state: GlobalState, action: GlobalStateAction): GlobalState => {
  return {
    ...state,
    [action.key]: action.value
  };
};

interface Props {}

export default function App(props: Props) {
  const classes = useStyles();
  const global: GlobalState = {
    user: User.create(),
    team: Team.create(),
    as: "",
    tags: [],
    firmware: new Map(),
    //TODO-CS: remove this from the global state once we know that the new structure is fine and does not cause any issue for users
    newStructure: true, //making all users have the new structure
    showErrors: false,
    userTeamPermissions: [],
    backgroundTasksComplete: false
  };
  const [palette, setPalette] = useState<Theme>(CreateTheme("dark"));
  const [teams, setTeams] = useState<Team[]>([]);

  const toggleTheme = () => {
    if (getThemeType() === "light") {
      setPalette(CreateTheme("dark"));
      setThemeType("dark");
    } else {
      setPalette(CreateTheme("light"));
      setThemeType("light");
    }
  };

  //check the url for a code before auth0 causes a login re-direct
  if (window.location.pathname !== "/callback") {
    //set the code into local storage
    let code = new URLSearchParams(window.location.search).get("code");
    localStorage.setItem("code", code || "");
  }

  let url: string | undefined = window.location.origin;
  let audience: string | undefined;
  if (window.location.origin === "https://staging.brandxtech.ca") {
    url = process.env.REACT_APP_AUTH0_CLIENT_STAGING_DOMAIN;
    audience = process.env.REACT_APP_AUTH0_STAGING_AUDIENCE;
  } else {
    url = process.env.REACT_APP_AUTH0_CLIENT_DOMAIN;
    audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  }

  return (
    <MuiThemeProvider theme={palette}>
      <CssBaseline />
      <ErrorBoundary fallback={<UnexpectedError />}>
        <LocalizationProvider dateAdapter={MomentAdapter}>
          <SnackbarProvider>
            <Auth0Provider
              domain={or(url, "")}
              clientId={getAuth0ClientId()}
              redirectUri={window.location.origin + "/callback"}
              audience={or(audience, "")}>
              <AuthWrapper>
                <StateProvider state={global} reducer={reducer}>
                  <HTTPProvider>
                    <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
                      <BackgroundTasks teams={teams} setTeams={setTeams} />
                      <main className={classes.appContent}>
                        <NavigationContainer
                          teams={teams}
                          setTeams={setTeams}
                          toggleTheme={toggleTheme}
                        />
                      </main>
                    </BrowserRouter>
                  </HTTPProvider>
                </StateProvider>
              </AuthWrapper>
            </Auth0Provider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    </MuiThemeProvider>
  );
}

import DeleteWhite from "assets/products/Ag/deleteWhite.png";
import DeleteBlack from "assets/products/Ag/deleteBlack.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function DeleteIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? DeleteWhite : DeleteBlack;
    }

    return themeType === "light" ? DeleteBlack : DeleteWhite;
  };

  return <ImgIcon alt="fields" src={src()} />;
}

import { or } from "utils/types";

function decode(encoded) {
  let bytes = atob(encoded);
  let res = "";
  for (let i = 0; i < bytes.length; i++) {
    var val = bytes.charCodeAt(i).toString(16);
    res += val.length === 2 ? val : "0" + val;
  }
  return res;
}

export function parseGPS(gps) {
  gps = or(gps, {});
  let lat = 0;
  let lng = 0;
  let accuracy = 0;
  let request = undefined;
  if (gps.location) {
    lat = gps.latitude;
    lng = gps.longitude;
    accuracy = gps.uncertainty;
  } else if (gps.wifiAccessPoints) {
    request = { wifiAccessPoints: [] };
    gps.wifiAccessPoints.forEach(function(ap) {
      request.wifiAccessPoints.push({
        macAddress: decode(ap.mac),
        signalStrength: ap.signalStrength,
        channel: ap.channel
      });
    });
  } else if (gps.cellTower) {
    request = { cellTowers: [] };
    request.cellTowers.push(gps.cellTower);
  }

  return { request, lat, lng, accuracy };
}

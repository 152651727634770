import FieldsIconLight from "assets/products/Ag/FieldsIconLight.png";
import FieldsIconDark from "assets/products/Ag/FieldsIconDark.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
  size?: number;
}

export default function FieldsIcon(props: Props) {
  const themeType = useThemeType();
  const { type, size } = props;

  const src = () => {
    if (type) {
      return type === "light" ? FieldsIconLight : FieldsIconDark;
    }

    return themeType === "light" ? FieldsIconDark : FieldsIconLight;
  };

  return size ? (
    <img alt="fields" height={size} width={size} src={src()} />
  ) : (
    <ImgIcon alt="fields" src={src()} />
  );
}

import { get, post } from "services/cache";

function url(api: string): string {
  return "https://www.googleapis.com/" + api + "?key=" + process.env.REACT_APP_GOOGLE_API_KEY;
}

export function geolocate(data: unknown) {
  return post(url("geolocation/v1/geolocate"), data);
}

//gets a YouTube playlist
export function getPlaylist(playlistID: string) {
  return get(
    url("youtube/v3/playlistItems") + "&part=snippet&playlistId=" + playlistID + "&maxResults=50"
  );
}

interface Thumbnail {
  url: string;
  width: number;
  height: number;
}

export interface YouTubePlaylistItem {
  snippet: {
    title: string;
    description: string;
    resourceId: {
      kind: string;
      videoId: string;
    };
    channelTitle: string;
    publishedAt: string;
    position: number;
    thumbnails: {
      default: Thumbnail;
      medium: Thumbnail;
      high: Thumbnail;
      standard: Thumbnail;
      maxres: Thumbnail;
    };
  };
}

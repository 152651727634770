import { createStyles, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import MapGL, { Coordinate } from "./MapGL";

const styles = (theme: Theme) =>
  createStyles({
    card: {
      position: "relative",
      height: "100%",
      minHeight: "350px"
    },
    cardContent: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    currentLocation: {
      width: "100%"
    }
  });

interface Props extends WithStyles<typeof styles> {
  path: Array<Coordinate>;
}

interface State {}

class MapCard extends React.Component<Props, State> {
  render() {
    const { classes, path } = this.props;
    let paths: Map<string, Array<Coordinate>> = new Map().set("path", path);
    let approx = "Approximate location: Unknown";
    if (path.length > 0) {
      let sorted = path.sort((a: Coordinate, b: Coordinate) => {
        if (a.timestamp < b.timestamp) return 1;
        else if (b.timestamp < a.timestamp) return -1;
        return 0;
      });
      let loc = sorted[0].latitude + ", " + sorted[0].longitude;
      approx = "Approximate location: " + loc;
    }
    return (
      // <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card raised={true} className={classes.card}>
        <CardContent className={classes.cardContent}>
          <MapGL paths={paths} />
          <Typography
            className={classes.currentLocation}
            color="textSecondary"
            align="center"
            variant="subtitle1">
            {approx}
          </Typography>
        </CardContent>
      </Card>
      //</Grid>
    );
  }
}

export default withStyles(styles)(MapCard);

import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers/StateContainer";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { or } from "utils";
import { pondURL } from "./pond";

export interface ISiteAPIContext {
  addSite: (site: pond.SiteSettings) => Promise<any>;
  getSite: (siteID: string) => Promise<any>;
  getSitePage: (siteID: string) => Promise<any>;
  listSites: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean
  ) => Promise<AxiosResponse<pond.ListSitesResponse>>;
  listSitesPageData: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean
  ) => Promise<AxiosResponse<pond.ListSitesPageDataResponse>>;
  removeSite: (siteID: string) => Promise<AxiosResponse<pond.RemoveSiteResponse>>;
  updateSite: (
    key: string,
    site: pond.SiteSettings,
    asRoot?: true
  ) => Promise<AxiosResponse<pond.UpdateSiteResponse>>;
  updateLink: (
    parentKey: string,
    parentType: string,
    objectID: string,
    objectType: string,
    permissions: string[]
  ) => Promise<any>;
}

export const SiteAPIContext = createContext<ISiteAPIContext>({} as ISiteAPIContext);

interface Props {}

export default function SiteProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();
  const [{ as }] = useGlobalState();

  const getSitePage = (siteID: string) => {
    if (as) return get(pondURL("/sitePage/" + siteID + "?as=" + as));
    return get(pondURL("/sitePage/" + siteID));
  };

  const addSite = (site: pond.SiteSettings) => {
    if (as) return post(pondURL("/sites?as=" + as), site);
    return post(pondURL("/sites"), site);
  };

  const getSite = (siteID: string) => {
    if (as) return get(pondURL("/site/" + siteID + "?as=" + as));
    return get(pondURL("/site/" + siteID));
  };

  const removeSite = (key: string) => {
    return del<pond.RemoveSiteResponse>(pondURL("/sites/" + key));
  };

  const listSites = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean
  ) => {
    return get<pond.ListSitesResponse>(
      pondURL(
        "/sites" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + or(order, "asc")) +
          ("&by=" + or(orderBy, "key")) +
          (as ? "&as=" + as : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (search ? "&search=" + search : "")
      )
    );
  };

  const listSitesPageData = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean
  ) => {
    return get<pond.ListSitesPageDataResponse>(
      pondURL(
        "/sitesWithData" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + or(order, "asc")) +
          ("&by=" + or(orderBy, "key")) +
          (as ? "&as=" + as : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (search ? "&search=" + search : "")
      )
    );
  };

  const updateSite = (key: string, site: pond.SiteSettings, asRoot?: boolean) => {
    if (as)
      return put<pond.UpdateSiteResponse>(
        pondURL(
          "/sites/" + key + (as ? "?as=" + as : "") + (asRoot ? "&asRoot=" + asRoot.toString() : "")
        ),
        site
      );
    return put<pond.UpdateSiteResponse>(
      pondURL("/sites/" + key + (asRoot ? "?asRoot=" + asRoot.toString() : "")),
      site
    );
  };

  const updateLink = (
    parentID: string,
    parentType: string,
    objectID: string,
    objectType: string,
    permissions: string[]
  ) => {
    if (as)
      return post(pondURL(`/sites/` + parentID + `/link?as=${as}`), {
        Key: objectID,
        Type: objectType,
        Parent: parentID,
        ParentType: parentType,
        Permissions: permissions
      });
    return post(pondURL(`/sites/` + parentID + `/link`), {
      Key: objectID,
      Type: objectType,
      Parent: parentID,
      ParentType: parentType,
      Permissions: permissions
    });
  };

  return (
    <SiteAPIContext.Provider
      value={{
        addSite,
        getSite,
        listSites,
        listSitesPageData,
        removeSite,
        updateSite,
        updateLink,
        getSitePage
      }}>
      {children}
    </SiteAPIContext.Provider>
  );
}

export const useSiteAPI = () => useContext(SiteAPIContext);

import { pond } from "protobuf-ts/pond";

export function getTemperatureUnit(): pond.TemperatureUnit {
  return localStorage.getItem("temperature") === "f"
    ? pond.TemperatureUnit.TEMPERATURE_UNIT_FAHRENHEIT
    : pond.TemperatureUnit.TEMPERATURE_UNIT_CELSIUS;
}

export function setTemperatureUnit(unit: pond.TemperatureUnit) {
  localStorage.setItem(
    "temperature",
    unit === pond.TemperatureUnit.TEMPERATURE_UNIT_FAHRENHEIT ? "f" : "c"
  );
}

/**
 * takes in the celsius value for temperature and returns the fahrenheit value
 * @param celsius celsius value
 * @returns temperature in fahrenheit
 */
export const celsiusToFahrenheit = (celsius: number) => {
  return celsius * (9 / 5) + 32;
};

/**
 * takes in the fahrenheit value for temperature and returns the celsius value
 * @param fahrenheit starting value
 * @returns temperature in celsius
 */
export const fahrenheitToCelsius = (fahrenheit: number) => {
  return (fahrenheit - 32) * (5 / 9);
};

export function getPressureUnit(): pond.PressureUnit {
  return localStorage.getItem("pressure") === "kpa"
    ? pond.PressureUnit.PRESSURE_UNIT_KILOPASCALS
    : pond.PressureUnit.PRESSURE_UNIT_INCHES_OF_WATER;
}

export function setPressureUnit(unit: pond.PressureUnit) {
  localStorage.setItem(
    "pressure",
    unit === pond.PressureUnit.PRESSURE_UNIT_KILOPASCALS ? "kpa" : "iwg"
  );
}

export function getDistanceUnit(): pond.DistanceUnit {
  return localStorage.getItem("distance") === "m"
    ? pond.DistanceUnit.DISTANCE_UNIT_METERS
    : pond.DistanceUnit.DISTANCE_UNIT_FEET;
}

export function setDistanceUnit(unit: pond.DistanceUnit) {
  localStorage.setItem("distance", unit === pond.DistanceUnit.DISTANCE_UNIT_METERS ? "m" : "ft");
}

export function getGrainUnit(): pond.GrainUnit {
  return localStorage.getItem("grainUnit") === "mT"
    ? pond.GrainUnit.GRAIN_UNIT_WEIGHT
    : pond.GrainUnit.GRAIN_UNIT_BUSHELS;
}

export function setGrainUnit(unit: pond.GrainUnit) {
  localStorage.setItem("grainUnit", unit === pond.GrainUnit.GRAIN_UNIT_WEIGHT ? "mT" : "bu");
}

export const distanceConversion = (val: number) => {
  let converted = val;

  if (getDistanceUnit() === pond.DistanceUnit.DISTANCE_UNIT_METERS) {
    converted = val / 3.281;
  }
  return converted;
};

import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { Interaction } from "models";
import { componentIDToString } from "pbHelpers/Component";
import { pond } from "protobuf-ts/pond";
import { quack } from "protobuf-ts/quack";
import { useGlobalState } from "providers/StateContainer";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { has } from "utils/types";
import { pondURL } from "./pond";

export interface IInteractionsAPIContext {
  addInteraction: (device: number, settings: pond.IInteractionSettings) => Promise<any>;
  addMultiInteractions: (device: number, settings: pond.MultiInteractionSettings) => Promise<any>;
  addInteractionToComponents: (
    fullComponentLocations: string[],
    settings: pond.IInteractionSettings
  ) => Promise<AxiosResponse<pond.AddInteractionToComponentsResponse>>;
  updateInteraction: (device: number, settings: pond.IInteractionSettings) => Promise<any>;
  updateInteractionPondSettings: (
    device: number,
    interaction: string,
    settings: pond.IInteractionPondSettings
  ) => Promise<any>;
  removeInteraction: (device: number, interaction: string) => Promise<any>;
  listInteractionsByDevice: (device: number | string, demo?: boolean) => Promise<Interaction[]>;
  listInteractionsByComponent: (
    device: number,
    component: quack.ComponentID,
    demo?: boolean
  ) => Promise<Interaction[]>;
  setAlertInteractions: (
    alerts: pond.AlertData[]
  ) => Promise<AxiosResponse<pond.SetAlertInteractionsResponse>>;
}

export const InteractionsAPIContext = createContext<IInteractionsAPIContext>(
  {} as IInteractionsAPIContext
);

interface Props {}

//used to validate interaction fields before being sent outbound
function sanitizeInteraction(interaction: pond.IInteractionSettings): pond.IInteractionSettings {
  let sanitizedInteraction = interaction;
  if (sanitizedInteraction.conditions) {
    sanitizedInteraction.conditions.forEach((condition, i) => {
      if (condition.value && sanitizedInteraction.conditions) {
        sanitizedInteraction.conditions[i].value = Math.round(condition.value);
      }
    });
  }
  return sanitizedInteraction;
}

export default function InteractionProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, post, put, del } = useHTTP();
  const [{ as }] = useGlobalState();

  const addInteraction = (device: number, settings: pond.IInteractionSettings) => {
    return post(
      pondURL("/devices/" + device + "/interactions" + (as ? "?as=" + as : "")),
      sanitizeInteraction(settings)
    );
  };

  /**
   * this function is intended for use to add a single interaction to multiple components by way of an object that they are linked to (bin, gate etc)
   * @param fullComponentLocations the full id of a component including the device ie: [4:9-5-12, 4:9-5-13, 3:9-5-12]
   * @param settings the settings of the interaction to add
   * @returns
   */
  const addInteractionToComponents = (
    fullComponentLocations: string[],
    settings: pond.IInteractionSettings
  ) => {
    return post<pond.AddInteractionToComponentsResponse>(
      pondURL(
        "/interactions/forComponents/" +
          "?componentIds=" +
          fullComponentLocations.toString() +
          (as ? "&as=" + as : "")
      ),
      sanitizeInteraction(settings)
    );
  };

  const addMultiInteractions = (device: number, settings: pond.MultiInteractionSettings) => {
    return post(
      pondURL("/devices/" + device + "/interactions/multi" + (as ? "?as=" + as : "")),
      settings
    );
  };

  const updateInteraction = (device: number, settings: pond.IInteractionSettings) => {
    return put(
      pondURL(
        "/devices/" +
          device +
          "/interactions?keys=" +
          [device] +
          "&types=" +
          ["device"] +
          (as ? "&as=" + as : "")
      ),
      sanitizeInteraction(settings)
    );
  };

  const updateInteractionPondSettings = (
    device: number,
    interaction: string,
    settings: pond.IInteractionPondSettings
  ) => {
    return put(
      pondURL(
        "/devices/" +
          device +
          "/interactions/" +
          interaction +
          "/interactionPondSettings?keys=" +
          [device] +
          "&types=" +
          ["device"] +
          (as ? "&as=" + as : "")
      ),
      sanitizeInteraction(settings)
    );
  };

  const removeInteraction = (device: number, interaction: string) => {
    return del(
      pondURL("/devices/" + device + "/interactions/" + interaction + (as ? "?as=" + as : ""))
    );
  };

  const listInteractionsByDevice = (
    device: number | string,
    demo: boolean = false
  ): Promise<Interaction[]> => {
    return new Promise((resolve, reject) => {
      get(pondURL("/devices/" + device + "/interactions" + (as ? "?as=" + as : ""), demo))
        .then((response: any) => {
          if (!has(response, "data.interactions")) {
            return resolve([]);
          }
          let interactions: Interaction[] = [];
          response.data.interactions.forEach((interaction: Interaction) => {
            if (
              interaction.settings.nodeOne > interaction.settings.nodeTwo &&
              interaction.settings.nodeTwo !== 0
            ) {
              //flip operator and send negative comparitor to save
              interaction.settings.conditions.forEach(condition => {
                //coming back from the backend as a string for some reason
                if (condition.comparison.toString() === "RELATIONAL_OPERATOR_GREATER_THAN") {
                  condition.comparison = quack.RelationalOperator.RELATIONAL_OPERATOR_LESS_THAN;
                } else {
                  condition.comparison = quack.RelationalOperator.RELATIONAL_OPERATOR_GREATER_THAN;
                }
                condition.value = condition.value * -1;
              });
            }
            interactions.push(Interaction.any(interaction));
          });
          return resolve(interactions);
        })
        .catch((error: any) => {
          return reject(error);
        });
    });
  };

  const listInteractionsByComponent = (
    device: number,
    component: quack.ComponentID,
    demo: boolean = false
  ): Promise<Interaction[]> => {
    return new Promise((resolve, reject) => {
      get(
        pondURL(
          "/devices/" +
            device +
            "/components/" +
            componentIDToString(component) +
            "/interactions" +
            (as ? "?as=" + as : ""),
          demo
        )
      )
        .then((response: any) => {
          if (!has(response, "data.interactions")) {
            return resolve([]);
          }
          let interactions: Interaction[] = [];
          response.data.interactions.forEach((interaction: Interaction) => {
            if (
              interaction.settings.nodeOne > interaction.settings.nodeTwo &&
              interaction.settings.nodeTwo !== 0
            ) {
              //flip operator and send negative comparitor to save
              interaction.settings.conditions.forEach(condition => {
                //coming back from the backend as a string for some reason
                if (condition.comparison.toString() === "RELATIONAL_OPERATOR_GREATER_THAN") {
                  condition.comparison = quack.RelationalOperator.RELATIONAL_OPERATOR_LESS_THAN;
                } else {
                  condition.comparison = quack.RelationalOperator.RELATIONAL_OPERATOR_GREATER_THAN;
                }
                condition.value = condition.value * -1;
              });
            }
            interactions.push(Interaction.any(interaction));
          });
          return resolve(interactions);
        })
        .catch((error: any) => {
          return reject(error);
        });
    });
  };

  const setAlertInteractions = (
    alerts: pond.AlertData[]
  ): Promise<AxiosResponse<pond.SetAlertInteractionsResponse>> => {
    return post<pond.SetAlertInteractionsResponse>(
      pondURL("/interactions/setAlerts" + (as ? "?as=" + as : "")),
      { data: alerts }
    );
  };

  return (
    <InteractionsAPIContext.Provider
      value={{
        addInteraction,
        addMultiInteractions,
        setAlertInteractions,
        addInteractionToComponents,
        updateInteraction,
        updateInteractionPondSettings,
        removeInteraction,
        listInteractionsByDevice,
        listInteractionsByComponent
      }}>
      {children}
    </InteractionsAPIContext.Provider>
  );
}

export const useInteractionsAPI = () => useContext(InteractionsAPIContext);

import { Component } from "models";
import { extension } from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/pond";

export function FindPlenumSensors(components: Component[]): Component[] {
  let plenumSensors: Component[] = [];

  const required = [
    quack.MeasurementType.MEASUREMENT_TYPE_TEMPERATURE,
    quack.MeasurementType.MEASUREMENT_TYPE_PERCENT
  ];
  const blacklist = [quack.ComponentType.COMPONENT_TYPE_GRAIN_CABLE];

  components.forEach(c => {
    if (!blacklist.includes(c.settings.type)) {
      let ext = extension(c.settings.type, c.settings.subtype);
      let has = ext.measurements.map(m => m.measurementType);
      if (required.every(m => has.includes(m))) {
        plenumSensors.push(c);
      }
    }
  });

  return plenumSensors;
}

export function FindGrainCables(components: Component[]): Component[] {
  let grainCables: Component[] = [];

  components.forEach(c => {
    if (c.settings.type === quack.ComponentType.COMPONENT_TYPE_GRAIN_CABLE) {
      grainCables.push(c);
    }
  });

  return grainCables;
}

export function FindHeaters(components: Component[]): Component[] {
  return components.filter(
    c =>
      c.settings.type === quack.ComponentType.COMPONENT_TYPE_BOOLEAN_OUTPUT &&
      c.settings.subtype === quack.BooleanOutputSubtype.BOOLEAN_OUTPUT_SUBTYPE_HEATER
  );
}

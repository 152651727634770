import { AddressTypeExtension } from "pbHelpers/AddressType";
import { quack } from "protobuf-ts/quack";

export const GPS: AddressTypeExtension = {
  addressType: quack.AddressType.ADDRESS_TYPE_GPS,
  friendlyName: "GPS",
  humanReadableAddress: function(componentType: quack.ComponentType, address: number): string {
    return "";
  },
  singular: true
};

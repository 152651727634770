import { cloneDeep } from "lodash";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

export class Site {
  public settings: pond.SiteSettings = pond.SiteSettings.create();
  public status: pond.SiteStatus = pond.SiteStatus.create();

  public static create(ps?: pond.Site): Site {
    let my = new Site();
    if (ps) {
      my.settings = pond.SiteSettings.fromObject(cloneDeep(or(ps.settings, {})));
      my.status = pond.SiteStatus.fromObject(cloneDeep(or(ps.status, {})));
    }
    return my;
  }

  public static clone(other?: Site): Site {
    if (other) {
      return Site.create(
        pond.Site.fromObject({
          settings: cloneDeep(other.settings),
          status: cloneDeep(other.status)
        })
      );
    }
    return Site.create();
  }

  public static any(data: any): Site {
    return Site.create(pond.Site.fromObject(cloneDeep(data)));
  }

  public key(): string {
    return this.settings.key;
  }

  public longitude(): number {
    return this.settings.longitude;
  }

  public latitude(): number {
    return this.settings.latitude;
  }

  public siteName(): string {
    return this.settings.siteName;
  }

  public siteAddress(): string {
    return this.settings.siteAddress;
  }

  public siteDescription(): string {
    return this.settings.siteDescription;
  }

  public clientName(): string {
    return this.settings.clientName;
  }

  public clientPhone(): string {
    return this.settings.clientPhone;
  }

  public jobType(): string {
    return this.settings.jobType;
  }

  public jobDetails(): string {
    return this.settings.jobDetails;
  }

  public location(): pond.Location {
    let loc = pond.Location.create();
    loc.longitude = this.settings.longitude;
    loc.latitude = this.settings.latitude;
    return loc;
  }
}

import TasksDarkIcon from "assets/products/Construction/tasksIconDark.png";
import TasksLightIcon from "assets/products/Construction/tasksIconLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
  size?: number;
}

export default function TasksIcon(props: Props) {
  const themeType = useThemeType();
  const { type, size } = props;

  const src = () => {
    if (type) {
      return type === "light" ? TasksLightIcon : TasksDarkIcon;
    }

    return themeType === "light" ? TasksDarkIcon : TasksLightIcon;
  };

  return size ? (
    <img alt="tasks" height={size} width={size} src={src()} />
  ) : (
    <ImgIcon alt="tasks" src={src()} />
  );
}

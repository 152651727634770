import { Typography } from "@material-ui/core";
import Tour from "common/Tour";
import { random } from "lodash";
import moment from "moment";
import { useAuth, useGlobalState, useSnackbar, useUserAPI } from "providers";
import React, { useEffect, useState } from "react";
import Emoji from "react-emoji-render";
import { Step } from "react-joyride";

interface Props {}

export default function BinTour(props: Props) {
  const { userID } = useAuth();
  const [{ user }, dispatch] = useGlobalState();
  const { error } = useSnackbar();
  const [isTourRunning, setIsTourRunning] = useState(true);
  const [joyKey, setJoyKey] = useState(random());
  const userAPI = useUserAPI();

  useEffect(() => {
    if (user.status.finishedBinIntro.length < 1) {
      setIsTourRunning(true);
      setJoyKey(random(1000000));
    }
  }, [user]);

  const endTour = () => {
    setIsTourRunning(false);
    if (user) {
      user.status.finishedBinIntro = moment().toJSON();
      userAPI
        .updateUser(userID, user.protobuf())
        .then(() => dispatch({ key: "user", value: user }))
        .catch((err: any) => error(err));
    }
  };

  const getTourSteps = (): Step[] => {
    let steps: Step[] = [
      {
        title: (
          <React.Fragment>
            Welcome to Bin Management
            <Emoji text=" 🎉" />
          </React.Fragment>
        ),
        content: (
          <React.Fragment>
            <Typography variant="subtitle2" gutterBottom>
              {"Hello " + user.name()}
              <Emoji text=" 👋" />
            </Typography>
            <Typography variant="body2" paragraph>
              Looks like this is your first time using our bin management system! Let me show you
              around.
            </Typography>
          </React.Fragment>
        ),
        target: "body",
        placement: "center",
        disableBeacon: true
      },
      {
        title: "Sensors",
        content: (
          <React.Fragment>
            <Typography variant="body2" paragraph>
              In the bin sensors menu, you can attach components from your devices to the bin. This
              allows you to view component data right from the bin it's attached to, as well as
              other automation features.
            </Typography>
          </React.Fragment>
        ),
        target: "#tour-bin-sensors",
        placement: "bottom",
        disableBeacon: false
      },
      {
        title: "Settings",
        content: (
          <React.Fragment>
            <Typography variant="body2" paragraph>
              Bin settings lets you rename your bin, and change various bin specifications.
            </Typography>
          </React.Fragment>
        ),
        target: "#tour-bin-settings",
        placement: "bottom",
        disableBeacon: false
      },
      {
        title: "More Options",
        content: (
          <React.Fragment>
            <Typography variant="body2" paragraph>
              Access this menu if you want to leave the bin, share it, or view the bin's associated
              users and teams.
            </Typography>
          </React.Fragment>
        ),
        target: "#tour-bin-kebab",
        placement: "bottom",
        disableBeacon: false
      },
      {
        title: "Graphs",
        content: (
          <React.Fragment>
            <Typography variant="body2" paragraph>
              Bin related analytics are displayed here.
            </Typography>
          </React.Fragment>
        ),
        target: "#tour-graphs",
        placement: "left",
        disableBeacon: false
      },
      {
        title: "Choose your graphs",
        content: (
          <React.Fragment>
            <Typography variant="body2" paragraph>
              Use this tab to view other sets of data. Sensors must be attached to view sensor data.
            </Typography>
          </React.Fragment>
        ),
        target: "#tour-graph-tabs",
        placement: "bottom",
        disableBeacon: false
      },
      {
        title: "Change Mode",
        content: (
          <React.Fragment>
            <Typography variant="body2" paragraph>
              Sensors need to be attached to change bin mode.
            </Typography>
            <ul>
              <li>Storage mode: default</li>
              <li>
                Drying mode: use heat to dry grain
                <Emoji text=" ☀️" />
              </li>
              <li>
                Cooldown mode: use fans to hold bin temperature lower
                <Emoji text="  ❆❅" />
              </li>
            </ul>
          </React.Fragment>
        ),
        target: "#tour-bin-mode",
        placement: "bottom",
        disableBeacon: false
      }
    ];
    return steps;
  };

  // if this intro has been done, return null
  if (user.status.finishedBinIntro.length > 1) return null;

  // if the user hasn't done the first intro, return null
  if (user.status.finishedIntro.length < 1) return null;

  return (
    <div key={joyKey}>
      <Tour run={isTourRunning} steps={getTourSteps()} endTourCallback={endTour} />
    </div>
  );
}

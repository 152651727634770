// @ts-ignore ReactPhoneInput error due to lack of @type definition
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import UnitsIcon from "@material-ui/icons/Category";
import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "@material-ui/icons/Email";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NotificationsIcon from "@material-ui/icons/Notifications";
import TextIcon from "@material-ui/icons/Textsms";
import ResponsiveDialog from "common/ResponsiveDialog";
import SearchSelect, { Option } from "common/SearchSelect";
import { setDistanceUnit, setGrainUnit, setPressureUnit, setTemperatureUnit } from "utils";
import { useAuth, usePrevious, useSnackbar, useUserAPI } from "hooks";
import { User, userScope } from "models";
import moment from "moment-timezone";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers";
import React, { useEffect, useState } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import { AccountBox, Face } from "@material-ui/icons";
import ShareObject from "./ShareObject";
import { blue } from "@material-ui/core/colors";
import ShareIcon from "@material-ui/icons/Share";
import { IconPicker } from "common/IconPicker";
import UserAvatar from "./UserAvatar";
import FieldsIcon from "products/AgIcons/FieldsIcon";
import { IsAdaptiveAgriculture } from "services/whiteLabel";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      flex: 1
    },
    checkboxIcon: {
      minWidth: theme.spacing(5),
      [theme.breakpoints.up("sm")]: {
        minWidth: theme.spacing(6)
      },
      [theme.breakpoints.up("md")]: {
        minWidth: theme.spacing(7)
      }
    },
    shareIcon: {
      color: blue["600"],
      "&:hover": {
        color: blue["700"]
      }
    },
    iconButton: {
      margin: theme.spacing(1),
      position: "absolute",
      right: theme.spacing(6)
    }
  });
});

interface Props {
  isOpen: boolean;
  closeDialogCallback: () => void;
}

export default function UserSettings(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const { userID } = useAuth();
  const { isOpen, closeDialogCallback } = props;
  const prevIsOpen = usePrevious(isOpen);
  const { success } = useSnackbar();
  const [globalState, dispatch] = useGlobalState();
  const userAPI = useUserAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileExpanded, setProfileExpanded] = useState<boolean>(false);
  const [avatarExpanded, setAvatarExpanded] = useState<boolean>(false);
  const [notificationsExpanded, setNotificationsExpanded] = useState<boolean>(false);
  const [unitsExpanded, setUnitsExpanded] = useState<boolean>(false);
  const [mapsExpanded, setMapsExpanded] = useState<boolean>(false);
  const [sliderVal, setSliderVal] = useState(1);
  const [user, setUser] = useState<User>(
    User.create(
      pond.User.fromObject({
        settings: {
          name: "",
          pressureUnit: pond.PressureUnit.PRESSURE_UNIT_KILOPASCALS,
          temperatureUnit: pond.TemperatureUnit.TEMPERATURE_UNIT_CELSIUS,
          distanceUnit: pond.DistanceUnit.DISTANCE_UNIT_METERS
        }
      })
    )
  );
  const [sharing, setSharing] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    function changeIcon(icon: string | undefined) {
      let updatedUser = User.clone(user);
      updatedUser.settings.avatar = icon ? icon : user.settings.name;
      setUser(updatedUser);
    }
    if (url === "") {
      changeIcon(undefined);
    } else {
      changeIcon(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    if (isOpen === true && prevIsOpen !== isOpen) {
      setUser(globalState.user);
      setSliderVal(globalState.user.settings.mapZoom ?? 13);
    }
  }, [globalState.user, isOpen, prevIsOpen]);

  const setDefaultState = () => {
    setIsLoading(false);
    setUser(
      User.create(
        pond.User.fromObject({
          settings: {
            name: "",
            pressureUnit: pond.PressureUnit.PRESSURE_UNIT_INCHES_OF_WATER,
            temperatureUnit: pond.TemperatureUnit.TEMPERATURE_UNIT_CELSIUS,
            distanceUnit: pond.DistanceUnit.DISTANCE_UNIT_METERS
          }
        })
      )
    );
  };

  const closeDialog = () => {
    setDefaultState();
    closeDialogCallback();
  };

  const submit = () => {
    userAPI
      .updateUser(userID, user.protobuf())
      .then(() => {
        dispatch({ key: "user", value: user });
        success("User settings successfully updated!");
        setTemperatureUnit(user.settings.temperatureUnit);
        setPressureUnit(user.settings.pressureUnit);
        setDistanceUnit(user.settings.distanceUnit);
        setGrainUnit(user.settings.grainUnit);
      })
      .catch((error: any) => {
        error("Error occurred while updating your profile");
      })
      .finally(() => {
        setIsLoading(false);
        closeDialogCallback();
      });
  };

  const changePhoneNumber = (newPhoneNumber: string) => {
    let updatedUser = User.clone(user);
    updatedUser.settings.phoneNumber = newPhoneNumber;
    setUser(updatedUser);
  };

  const handleNotificationMethod = (
    checked: boolean,
    notificationMethod: pond.NotificationMethod
  ) => {
    let updatedUser = User.clone(user);
    let notificationMethods = updatedUser.settings.notificationMethods;
    if (checked) {
      if (!notificationMethods.includes(notificationMethod)) {
        notificationMethods.push(notificationMethod);
      }
    } else {
      notificationMethods = notificationMethods.filter(element => element !== notificationMethod);
    }
    updatedUser.settings.notificationMethods = notificationMethods;
    setUser(updatedUser);
  };

  const handleChangeNotifyByDefault = (notifyByDefault: boolean) => {
    let updatedUser = User.clone(user);
    updatedUser.settings.notifyByDefault = notifyByDefault;
    setUser(updatedUser);
  };

  const changeTimezone = (tz: string) => {
    let updatedUser = User.clone(user);
    updatedUser.settings.timezone = tz;

    setUser(updatedUser);
  };

  const changeName = (name: string) => {
    let updatedUser = User.clone(user);
    updatedUser.settings.name = name;
    setUser(updatedUser);
  };

  const changePressureUnit = (value: any) => {
    let updatedUser = User.clone(user);
    let pressureUnit: pond.PressureUnit;
    switch (value) {
      case 1:
        pressureUnit = pond.PressureUnit.PRESSURE_UNIT_KILOPASCALS;
        break;
      case 2:
        pressureUnit = pond.PressureUnit.PRESSURE_UNIT_INCHES_OF_WATER;
        break;
      default:
        pressureUnit = pond.PressureUnit.PRESSURE_UNIT_UNKNOWN;
        break;
    }
    updatedUser.settings.pressureUnit = pressureUnit;
    setUser(updatedUser);
  };

  const changeTemperatureUnit = (value: any) => {
    let updatedUser = User.clone(user);
    let temperatureUnit: pond.TemperatureUnit;
    switch (value) {
      case 1:
        temperatureUnit = pond.TemperatureUnit.TEMPERATURE_UNIT_CELSIUS;
        break;
      case 2:
        temperatureUnit = pond.TemperatureUnit.TEMPERATURE_UNIT_FAHRENHEIT;
        break;
      default:
        temperatureUnit = pond.TemperatureUnit.TEMPERATURE_UNIT_UNKNOWN;
        break;
    }
    updatedUser.settings.temperatureUnit = temperatureUnit;
    setUser(updatedUser);
  };

  const changeDistanceUnit = (value: any) => {
    let updatedUser = User.clone(user);
    let distanceUnit: pond.DistanceUnit;
    switch (value) {
      case 1:
        distanceUnit = pond.DistanceUnit.DISTANCE_UNIT_FEET;
        break;
      case 2:
        distanceUnit = pond.DistanceUnit.DISTANCE_UNIT_METERS;
        break;
      default:
        distanceUnit = pond.DistanceUnit.DISTANCE_UNIT_UNKNOWN;
        break;
    }
    updatedUser.settings.distanceUnit = distanceUnit;
    setUser(updatedUser);
  };

  const changeGrainUnit = (value: any) => {
    let updatedUser = User.clone(user);
    let grainUnit: pond.GrainUnit;
    switch (value) {
      case 1:
        grainUnit = pond.GrainUnit.GRAIN_UNIT_BUSHELS;
        break;
      case 2:
        grainUnit = pond.GrainUnit.GRAIN_UNIT_WEIGHT;
        break;
      default:
        grainUnit = pond.GrainUnit.GRAIN_UNIT_UNKNOWN;
        break;
    }
    updatedUser.settings.grainUnit = grainUnit;
    setUser(updatedUser);
  };

  const changeDefaultZoom = (value: number) => {
    setSliderVal(value);
    user.settings.mapZoom = value;
  };

  const openShareObjectDialog = () => {
    setSharing(true);
  };

  // UI STARTS

  const generalSettings = () => {
    const { name, email, phoneNumber, timezone } = user.settings;

    return (
      <Grid container direction="column" spacing={4} justify="flex-start" alignItems="flex-start">
        <ShareObject
          scope={userScope(userID)}
          label={""}
          permissions={[
            pond.Permission.PERMISSION_READ,
            pond.Permission.PERMISSION_SHARE,
            pond.Permission.PERMISSION_USERS,
            pond.Permission.PERMISSION_WRITE
          ]}
          isDialogOpen={sharing}
          closeDialogCallback={() => setSharing(false)}
        />
        <Tooltip title={"Share control of profile with another user"}>
          <IconButton
            aria-label="Share"
            className={classes.iconButton}
            onClick={openShareObjectDialog}>
            <ShareIcon className={classes.shareIcon} />
          </IconButton>
        </Tooltip>
        <Grid item>
          <TextField
            label="Preferred Name"
            value={name}
            helperText={"Email: " + email}
            onChange={event => changeName(event.target.value.toString())}
          />
        </Grid>
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend" htmlFor="phone-number-input">
              Phone Number
            </FormLabel>
            <MuiPhoneNumber
              onChange={(value: string) => changePhoneNumber(value)}
              value={phoneNumber}
              onlyCountries={["ca", "us"]}
              defaultCountry={"ca"}
              countryCodeEditable={false}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Box minWidth="250px">
            <SearchSelect
              label="Timezone"
              selected={{ label: timezone, value: timezone } as Option}
              options={moment.tz.names().map(tz => ({ label: tz, value: tz } as Option))}
              changeSelection={(selected: Option | null) =>
                changeTimezone(selected ? selected.value : "")
              }
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const notificationsSettings = () => {
    const { notifyByDefault, notificationMethods } = user.settings;

    return (
      <Grid container direction="column" spacing={4} justify="flex-start" alignItems="flex-start">
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Notification Preference</FormLabel>
            <RadioGroup
              aria-label="notification-default"
              name="notificationDefault"
              value={notifyByDefault}
              onChange={event => handleChangeNotifyByDefault(event.target.value === "true")}>
              <FormControlLabel value={true} control={<Radio />} label="Opt-in" />
              <FormControlLabel value={false} control={<Radio />} label="Opt-out" />
            </RadioGroup>
            <FormHelperText>
              {notifyByDefault
                ? "Notifications are enabled by default"
                : "Notifications are disabled by default"}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Notification Methods</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event, checked) =>
                      handleNotificationMethod(
                        checked,
                        pond.NotificationMethod.NOTIFICATION_METHOD_EMAIL
                      )
                    }
                    checked={
                      notificationMethods
                        ? notificationMethods.includes(
                            pond.NotificationMethod.NOTIFICATION_METHOD_EMAIL
                          )
                        : false
                    }
                  />
                }
                label={
                  <ListItem dense disableGutters>
                    <ListItemIcon className={classes.checkboxIcon}>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Email" />
                  </ListItem>
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event, checked) =>
                      handleNotificationMethod(
                        checked,
                        pond.NotificationMethod.NOTIFICATION_METHOD_TEXT
                      )
                    }
                    checked={
                      notificationMethods
                        ? notificationMethods.includes(
                            pond.NotificationMethod.NOTIFICATION_METHOD_TEXT
                          )
                        : false
                    }
                  />
                }
                label={
                  <ListItem dense disableGutters>
                    <ListItemIcon className={classes.checkboxIcon}>
                      <TextIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Text Message (SMS)"
                      secondary="Requires a valid phone number"
                      secondaryTypographyProps={{ variant: "caption" }}
                    />
                  </ListItem>
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event, checked) =>
                      handleNotificationMethod(
                        checked,
                        pond.NotificationMethod.NOTIFICATION_METHOD_APP
                      )
                    }
                    checked={
                      notificationMethods
                        ? notificationMethods.includes(
                            pond.NotificationMethod.NOTIFICATION_METHOD_APP
                          )
                        : false
                    }
                  />
                }
                label={
                  <ListItem dense disableGutters>
                    <ListItemIcon className={classes.checkboxIcon}>
                      <TextIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="In-App Notifications"
                      secondary="No email or phone number required"
                      secondaryTypographyProps={{ variant: "caption" }}
                    />
                  </ListItem>
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const unitPreferences = () => {
    const { pressureUnit, temperatureUnit, distanceUnit, grainUnit } = user.settings;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            id="pressureUnit"
            name="pressureUnit"
            label="Pressure Unit"
            value={pressureUnit ? pressureUnit : pond.PressureUnit.PRESSURE_UNIT_INCHES_OF_WATER}
            onChange={event => changePressureUnit(event.target.value)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}>
            <MenuItem value={pond.PressureUnit.PRESSURE_UNIT_KILOPASCALS}>
              Kilopascals (kPa)
            </MenuItem>
            <MenuItem value={pond.PressureUnit.PRESSURE_UNIT_INCHES_OF_WATER}>
              Inches of Water (iwg)
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            id="temperatureUnit"
            name="temperatureUnit"
            label="Temperature Unit"
            value={
              temperatureUnit ? temperatureUnit : pond.TemperatureUnit.TEMPERATURE_UNIT_CELSIUS
            }
            onChange={event => changeTemperatureUnit(event.target.value)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}>
            <MenuItem value={pond.TemperatureUnit.TEMPERATURE_UNIT_CELSIUS}>Celsius (°C)</MenuItem>
            <MenuItem value={pond.TemperatureUnit.TEMPERATURE_UNIT_FAHRENHEIT}>
              Fahrenheit (°F)
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            id="distanceUnit"
            name="distanceUnit"
            label="Distance Unit"
            value={distanceUnit ? distanceUnit : pond.DistanceUnit.DISTANCE_UNIT_METERS}
            onChange={event => changeDistanceUnit(event.target.value)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}>
            <MenuItem value={pond.DistanceUnit.DISTANCE_UNIT_METERS}>Meters (m)</MenuItem>
            <MenuItem value={pond.DistanceUnit.DISTANCE_UNIT_FEET}>Feet (ft)</MenuItem>
          </TextField>
        </Grid>
        {IsAdaptiveAgriculture() && (
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              id="grainUnit"
              name="grainUnit"
              label="Grain Unit"
              value={grainUnit ? grainUnit : pond.GrainUnit.GRAIN_UNIT_BUSHELS}
              onChange={event => changeGrainUnit(event.target.value)}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}>
              <MenuItem value={pond.GrainUnit.GRAIN_UNIT_BUSHELS}>Bushels (bu)</MenuItem>
              <MenuItem value={pond.GrainUnit.GRAIN_UNIT_WEIGHT}>Tonnes (mT)</MenuItem>
            </TextField>
          </Grid>
        )}
      </Grid>
    );
  };

  const mapSettings = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography>Map Zoom Level</Typography>
          <Slider
            valueLabelDisplay="auto"
            value={sliderVal}
            min={1}
            max={16}
            onChange={(_, val) => {
              changeDefaultZoom(val as number);
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const loading = () => {
    return <CircularProgress variant="indeterminate" color="primary" />;
  };

  const avatarSettings = () => {
    return <IconPicker url={user.settings.avatar} setUrl={setUrl} id={user.settings.id} />;
  };

  const settingsList = () => {
    return (
      <List disablePadding>
        <ListItem button onClick={() => setProfileExpanded(!profileExpanded)}>
          <ListItemIcon>
            <AccountBox />
          </ListItemIcon>
          <ListItemText primary={"Profile"} />
          {profileExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={profileExpanded} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem>
              <ListItemText disableTypography inset>
                {isLoading ? loading() : generalSettings()}
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>

        <Divider />

        <ListItem button onClick={() => setAvatarExpanded(!avatarExpanded)}>
          <ListItemIcon>
            <Face />
          </ListItemIcon>
          <ListItemText primary={"Display Picture"} />
          {avatarExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={avatarExpanded} timeout="auto" unmountOnExit>
          <List
            component="div"
            style={{
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4)
            }}>
            {isLoading ? loading() : avatarSettings()}
          </List>
        </Collapse>

        <Divider />

        <ListItem button onClick={() => setNotificationsExpanded(!notificationsExpanded)}>
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
          {notificationsExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={notificationsExpanded} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem>
              <ListItemText disableTypography inset>
                {isLoading ? loading() : notificationsSettings()}
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>

        <Divider />

        <ListItem button onClick={() => setUnitsExpanded(!unitsExpanded)}>
          <ListItemIcon>
            <UnitsIcon />
          </ListItemIcon>
          <ListItemText primary="Preferred Units" />
          {unitsExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={unitsExpanded} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem>
              <ListItemText disableTypography inset>
                {isLoading ? loading() : unitPreferences()}
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>

        <Divider />

        <ListItem button onClick={() => setMapsExpanded(!mapsExpanded)}>
          <ListItemIcon>
            <FieldsIcon />
          </ListItemIcon>
          <ListItemText primary={"Map Settings"} />
          {mapsExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={mapsExpanded} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem>
              <ListItemText disableTypography inset>
                {isLoading ? loading() : mapSettings()}
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </List>
    );
  };

  return (
    <ResponsiveDialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={closeDialog}
      aria-labelledby="user-settings-dialog">
      <AppBar position="relative">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
          <UserAvatar user={user} />
          <Typography variant="h6" className={classes.title}>
            User Settings
          </Typography>
          <Button color="inherit" onClick={submit}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      {settingsList()}
    </ResponsiveDialog>
  );
}

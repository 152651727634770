import { useHTTP } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";
import { useGlobalState } from "providers";

export interface IObjectAPIContext {
  addObject: (
    name: string,
    type: pond.ObjectType,
    object: pond.ObjectSettings,
    parent: string,
    parentType: string,
    typeString: string
  ) => Promise<AxiosResponse<pond.AddObjectResponse>>;
  listObjectSummary: (objectKey: string, as?: string) => Promise<AxiosResponse<any>>;
  listObjects: (
    limit: number,
    offset: number,
    type: pond.ObjectType,
    typeString: string,
    parentKey: string,
    parentType: string,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string
  ) => Promise<AxiosResponse<any>>;
}

export const ObjectAPIContext = createContext<IObjectAPIContext>({} as IObjectAPIContext);

interface Props {}

export default function ObjectProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, post } = useHTTP();
  const [{ as }] = useGlobalState();

  const addObject = (
    name: string,
    type: pond.ObjectType,
    object: pond.ObjectSettings,
    parent: string,
    parentType: string,
    typeString: string
  ) => {
    if (as)
      return post<pond.AddObjectResponse>(
        pondURL(
          "/objects?as=" +
            as +
            "&name=" +
            name +
            "&type=" +
            type +
            "&parent=" +
            parent +
            "&parentType=" +
            parentType +
            "&typeString=" +
            typeString
        ),
        object
      );
    return post<pond.AddObjectResponse>(
      pondURL(
        "/objects?name=" +
          name +
          "&type=" +
          type +
          "&parent=" +
          parent +
          "&parentType=" +
          parentType +
          "&typeString=" +
          typeString
      ),
      object
    );
  };

  const listObjectSummary = (objectKey: string, as?: string) => {
    return get<any>(pondURL("/objects/" + objectKey + (as ? "?as=" + as : "")));
  };

  const listObjects = (
    limit: number,
    offset: number,
    type: pond.ObjectType,
    typeString: string,
    parentKey: string,
    parentType: string,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string
  ) => {
    return get<any>(
      pondURL(
        "/objects?type=" +
          type +
          "&limit=" +
          limit +
          "&offset=" +
          offset +
          "&parentKey=" +
          parentKey +
          "&parentType=" +
          parentType +
          "&typeString=" +
          typeString +
          ("&order=" + or(order, "asc")) +
          ("&by=" + or(orderBy, "name")) +
          (as ? "&as=" + as : "") +
          (search ? "&search=" + search : "")
      )
    );
  };

  return (
    <ObjectAPIContext.Provider
      value={{
        addObject,
        listObjectSummary,
        listObjects
      }}>
      {children}
    </ObjectAPIContext.Provider>
  );
}

export const useObjectAPI = () => useContext(ObjectAPIContext);

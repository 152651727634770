import { cloneDeep } from "lodash";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

export class Note {
  public settings: pond.NoteSettings = pond.NoteSettings.create();
  public status: pond.NoteStatus = pond.NoteStatus.create();

  public static create(pb?: pond.Note): Note {
    let my = new Note();
    if (pb) {
      my.settings = pond.NoteSettings.fromObject(cloneDeep(or(pb.settings, {})));
      my.status = pond.NoteStatus.fromObject(cloneDeep(or(pb.status, {})));
    }
    return my;
  }

  public static clone(other?: Note): Note {
    if (other) {
      return Note.create(
        pond.Note.fromObject({
          settings: cloneDeep(other.settings),
          status: cloneDeep(other.status)
        })
      );
    }
    return Note.create();
  }

  public static any(data: any): Note {
    return Note.create(pond.Note.fromObject(cloneDeep(data)));
  }

  public key(): string {
    return this.settings.key;
  }

  public date(): number {
    return this.settings.timestamp;
  }

  public content(): string {
    return this.settings.content;
  }
}

import CNHiLogoWhite from "assets/marketplaceImages/CNHiWhite.png";
import CNHiLogoBlack from "assets/marketplaceImages/CNHiBlack.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
}

export default function CNHiIcon(props: Props) {
  const themeType = useThemeType();
  const { type } = props;

  const src = () => {
    if (type) {
      return type === "light" ? CNHiLogoWhite : CNHiLogoBlack;
    }

    return themeType === "light" ? CNHiLogoBlack : CNHiLogoWhite;
  };

  return <ImgIcon alt="case-new-holland" src={src()} />;
}

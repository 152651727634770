import { useHTTP } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";
import { useGlobalState } from "providers";

export interface IBinYardAPIContext {
  addBinYard: (bin: pond.BinYardSettings) => Promise<AxiosResponse<pond.AddBinYardResponse>>;
  updateBinYard: (
    key: string,
    binYard: pond.BinYardSettings,
    asRoot?: true
  ) => Promise<AxiosResponse<pond.UpdateBinYardResponse>>;
  removeBinYard: (key: string) => Promise<AxiosResponse<pond.RemoveBinYardResponse>>;
  getBinYard: (key: string) => Promise<AxiosResponse<pond.BinYard>>;
  listBinYards: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean,
    specificUser?: string
  ) => Promise<AxiosResponse<pond.ListBinYardsResponse>>;
}

export const BinYardAPIContext = createContext<IBinYardAPIContext>({} as IBinYardAPIContext);

interface Props {}

export default function DeviceProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, post, put, del } = useHTTP();
  const [{ as }] = useGlobalState();

  const addBinYard = (binYard: pond.BinYardSettings) => {
    if (as) return post<pond.AddBinYardResponse>(pondURL(`/binyard?as=${as}`), binYard);
    return post<pond.AddBinYardResponse>(pondURL("/binyard"), binYard);
  };

  const updateBinYard = (key: string, binYard: pond.BinYardSettings, asRoot?: boolean) => {
    if (as) {
      if (asRoot) {
        return put<pond.UpdateBinYardResponse>(
          pondURL(
            "/binyards/" + key + (asRoot ? "?asRoot=" + asRoot.toString() : "") + "&as=" + as
          ),
          binYard
        );
      }
      return put<pond.UpdateBinYardResponse>(pondURL("/binyards/" + key + "?as=" + as), binYard);
    }
    return put<pond.UpdateBinYardResponse>(
      pondURL("/binyards/" + key + (asRoot ? "?asRoot=" + asRoot.toString() : "")),
      binYard
    );
  };

  const removeBinYard = (key: string) => {
    if (as) return del<pond.RemoveBinYardResponse>(pondURL("/binyard/" + key + "?as=" + as));
    return del<pond.RemoveBinYardResponse>(pondURL("/binyard/" + key));
  };

  const getBinYard = (key: string) => {
    if (as) return get<pond.BinYard>(pondURL("/binYard/" + key + "?as=" + as));
    return get<pond.BinYard>(pondURL("/binYard/" + key));
  };

  const listBinYards = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean,
    specificUser?: string
  ) => {
    let asText = "";
    if (as) asText = "&as=" + as;
    if (specificUser) asText = "&as=" + specificUser;
    return get<pond.ListBinYardsResponse>(
      pondURL(
        "/binYards" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + or(order, "asc")) +
          ("&by=" + or(orderBy, "key")) +
          (search ? "&search=" + search : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          asText
      )
    );
  };

  return (
    <BinYardAPIContext.Provider
      value={{
        addBinYard,
        removeBinYard,
        getBinYard,
        listBinYards,
        updateBinYard
      }}>
      {children}
    </BinYardAPIContext.Provider>
  );
}

export const useBinYardAPI = () => useContext(BinYardAPIContext);

import {
  Box,
  createStyles,
  darken,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography
} from "@material-ui/core";
import React from "react";
import { getThemeType } from "theme";

export interface FieldInformation {
  fieldName: string;
  fieldType: string;
  fieldDescription: string;
}

export interface data {
  name: string;
  fields: FieldInformation[];
}

interface Props {
  data: data;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cellNarrow: {
      width: "20%",
      padding: 5
    },
    cellWide: {
      width: "80%",
      padding: 5
    },
    dark: {
      backgroundColor: getThemeType() === "light" ? "rgb(245, 245, 245)" : "rgb(50, 50, 50)",
      padding: 0
    },
    light: {
      backgroundColor: getThemeType() === "light" ? "rgb(235, 235, 235)" : "rgb(60, 60, 60)",
      padding: 0
    },
    container: {
      width: "100%",
      border: "2px solid white",
      padding: 5,
      borderRadius: 10
    },
    table: {
      width: "100%"
    },
    header: {
      fontWeight: 650,
      fontSize: 20
    },
    tableHeader: {
      fontSize: 17,
      fontWeight: 650
    },
    tableData: {
      fontSize: 17
    },
    subHeader: {
      fontSize: 14,
      color: darken(theme.palette.text.primary, 0.3)
    }
  })
);

export default function ObjectDefinitionDisplay(props: Props) {
  const { data } = props;
  const classes = useStyles();
  return (
    <Box width="100%">
      <Typography className={classes.header}>{data.name}</Typography>
      <Box className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.cellNarrow}>
                <Typography className={classes.tableHeader}>Field Names</Typography>
              </TableCell>
              <TableCell align="left" className={classes.cellWide}>
                <Typography className={classes.tableHeader}>Description</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.fields.map((field, i) => (
              <TableRow className={i % 2 === 0 ? classes.light : classes.dark} key={i}>
                <TableCell align="left" className={classes.cellNarrow}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.tableData}>{field.fieldName}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.subHeader}>{field.fieldType}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.cellWide}>
                  <Typography className={classes.tableData}>{field.fieldDescription}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

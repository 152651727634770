import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ResponsiveDialog from "common/ResponsiveDialog";
import { useSnackbar, useStripeAPI } from "providers";
import React, { useEffect, useState } from "react";
import StripeCardList from "./StripeCardList";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      marginLeft: theme.spacing(4),
      marginBottom: theme.spacing(3)
    },
    subtitle: {
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(1)
    },
    box: {
      margin: theme.spacing(1),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      display: "flex",
      // justifyContent: "center",
      alignItems: "baseline"
    },
    button: {
      marginRight: theme.spacing(1)
    },
    divider: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    icon: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    invoicesTitle: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  });
});

type InvoiceStatus = "open" | "paid";

interface Invoice {
  amount_due: number;
  last_finalization_error: any | null;
  id: string;
  invoice_pdf: string;
  number: string;
  paid: boolean;
}

interface Props {
  customer: any;
}

export default function StripeInvoices(props: Props) {
  const { customer } = props;

  const stripeAPI = useStripeAPI();

  const classes = useStyles();
  const snackbar = useSnackbar();

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [payingId, setPayingId] = useState("");
  const [loading, setLoading] = useState(false);
  const [payingInvoice, setPayingInvoice] = useState<Invoice | undefined>(undefined);
  const [status, setStatus] = useState<InvoiceStatus>("open");
  const [paymentMethodCheck, setPaymentMethodCheck] = useState(0);
  const [selectedCard, setSelectedCard] = useState("");

  const [loadingCard, setLoadingCard] = useState(false);
  const [defaultCard, setDefaultCard] = useState<any | undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    stripeAPI
      .listInvoices(status)
      .then(resp => {
        setInvoices(resp.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [stripeAPI, status]);

  useEffect(() => {
    setLoadingCard(true);
    stripeAPI
      .getCard(customer.default_source)
      .then(resp => {
        setDefaultCard(resp.data);
      })
      .finally(() => {
        setLoadingCard(false);
      });
  }, [customer, stripeAPI, setLoadingCard]);

  const pay = (id?: string) => {
    if (!id) return;
    setPayingId(id);
    setPayingInvoice(undefined);
    stripeAPI
      .payInvoice(id, selectedCard ? selectedCard : undefined)
      .then(resp => {
        if (resp.data === "success?") {
          snackbar.success("Successfully paid invoice");
          let index = invoices.findIndex(invoice => invoice.id === id);
          invoices[index].paid = true;
        } else {
          snackbar.error("Invoice not paid");
        }
      })
      .finally(() => {
        setPayingId("");
      });
  };

  const toggle = () => {
    return (
      <ToggleButtonGroup
        value={status}
        exclusive
        onChange={(event, value) => {
          if (value) setStatus(value);
        }}
        aria-label="text alignment"
        size="small">
        <ToggleButton value="open">Open</ToggleButton>
        <ToggleButton value="paid">Paid</ToggleButton>
      </ToggleButtonGroup>
    );
  };

  // if (loading)
  //   return (
  //     <Grid container justify="center" alignItems="center">
  //       <CircularProgress />
  //     </Grid>
  //   );

  return (
    <React.Fragment>
      <Box className={classes.invoicesTitle}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography style={{ textTransform: "capitalize" }}>{status} Invoices</Typography>
          </Grid>
          <Grid item>{toggle()}</Grid>
        </Grid>
      </Box>
      <Divider className={classes.divider} />
      {!loading &&
        invoices.map(invoice => {
          if (invoice.last_finalization_error === null)
            return (
              <React.Fragment key={"invoice-grid-" + invoice.id}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Grid item>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                      <Grid item>
                        <Typography className={classes.icon}>Hi</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" spacing={0}>
                          <Grid item>
                            <Typography>${invoice.amount_due / 100}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2" color="textSecondary">
                              {invoice.number}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={1}>
                      <Grid item>
                        <Button href={invoice.invoice_pdf} variant="contained">
                          View PDF
                        </Button>
                      </Grid>
                      <Grid item className={classes.button}>
                        {payingId === invoice.id ? (
                          <CircularProgress />
                        ) : invoice.paid ? (
                          <Button disabled color="primary">
                            Paid
                          </Button>
                        ) : (
                          <Button
                            onClick={() => setPayingInvoice(invoice)}
                            variant="contained"
                            color="primary"
                            disabled={payingId.length > 1}>
                            Pay Invoice
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </React.Fragment>
            );
          return null;
        })}
      {!loading && invoices.length < 1 && (
        <Typography variant="subtitle1" color="textSecondary" className={classes.invoicesTitle}>
          You have no {status} invoices.
        </Typography>
      )}
      {loading && (
        <Grid container justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}
      <ResponsiveDialog open={payingInvoice !== undefined}>
        <DialogTitle>Pay Invoice</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* <Typography> */}
            You are about to pay an invoice of $
            {payingInvoice ? payingInvoice!.amount_due / 100 : 0}
            {/* </Typography> */}
          </DialogContentText>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentMethodCheck === 0}
                  onChange={() => {
                    setSelectedCard("");
                    setPaymentMethodCheck(0);
                  }}
                />
              }
              label="Use default payment method"
            />
            {loadingCard ? (
              <CircularProgress />
            ) : (
              paymentMethodCheck === 0 && (
                <Typography variant="subtitle2" color="textSecondary" style={{ marginLeft: 36 }}>
                  {!defaultCard
                    ? "Default Card undefined"
                    : defaultCard.brand +
                      ", **** " +
                      defaultCard.last4 +
                      ", exp: " +
                      defaultCard.exp_month +
                      " / " +
                      defaultCard.exp_year}
                </Typography>
              )
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentMethodCheck === 1}
                  onChange={() => setPaymentMethodCheck(1)}
                />
              }
              label="Choose payment method"
            />
          </FormGroup>

          {paymentMethodCheck === 1 && (
            <StripeCardList
              selectMode
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              defaultSource={defaultCard}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPayingInvoice(undefined)}>Cancel</Button>
          <Button onClick={() => pay(payingInvoice?.id)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </React.Fragment>
  );
}

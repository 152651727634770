import {
  createStyles,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import MoreIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";
import GateSettings from "./GateSettings";
import { Gate } from "models/Gate";
import ObjectUsersIcon from "@material-ui/icons/AccountCircle";
import ObjectTeamsIcon from "@material-ui/icons/SupervisedUserCircle";
import ObjectUsers from "user/ObjectUsers";
import { pond } from "protobuf-ts/pond";
import { Scope } from "models";
import ObjectTeams from "teams/ObjectTeams";
import RemoveSelfFromObject from "user/RemoveSelfFromObject";
import ShareObject from "user/ShareObject";
import { blue } from "@material-ui/core/colors";
import RemoveSelfIcon from "@material-ui/icons/ExitToApp";
import { Share } from "@material-ui/icons";

const useStyles = makeStyles(() => {
  return createStyles({
    shareIcon: {
      color: blue["500"],
      "&:hover": {
        color: blue["600"]
      }
    },
    removeIcon: {
      color: "var(--status-alert)"
    },
    red: {
      color: "var(--status-alert)"
    },
    blueIcon: {
      color: blue["500"],
      "&:hover": {
        color: blue["600"]
      }
    }
  });
});
interface OpenState {
  users: boolean;
  teams: boolean;
  settings: boolean;
  removeSelf: boolean;
  share: boolean;
}

interface Props {
  gate: Gate;
  refreshCallback: () => void;
  permissions: pond.Permission[];
}

export default function GateActions(props: Props) {
  const classes = useStyles();
  const { gate, refreshCallback, permissions } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openState, setOpenState] = useState<OpenState>({
    users: false,
    teams: false,
    settings: false,
    removeSelf: false,
    share: false
  });

  const groupMenu = () => {
    return (
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        keepMounted
        disableAutoFocusItem>
        {permissions.includes(pond.Permission.PERMISSION_SHARE) && (
          <MenuItem
            onClick={() => {
              setOpenState({ ...openState, share: true });
              setAnchorEl(null);
            }}
            button
            dense>
            <ListItemIcon>
              <Share className={classes.blueIcon} />
            </ListItemIcon>
            <ListItemText secondary="Share" />
          </MenuItem>
        )}
        {permissions.includes(pond.Permission.PERMISSION_USERS) && (
          <MenuItem
            dense
            onClick={() => {
              setOpenState({ ...openState, users: true });
              setAnchorEl(null);
            }}
            button>
            <ListItemIcon>
              <ObjectUsersIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </MenuItem>
        )}
        {permissions.includes(pond.Permission.PERMISSION_USERS) && (
          <MenuItem
            dense
            onClick={() => {
              setOpenState({ ...openState, teams: true });
              setAnchorEl(null);
            }}
            button>
            <ListItemIcon>
              <ObjectTeamsIcon />
            </ListItemIcon>
            <ListItemText primary="Teams" />
          </MenuItem>
        )}
        <MenuItem
          dense
          onClick={() => {
            setOpenState({ ...openState, removeSelf: true });
            setAnchorEl(null);
          }}
          button>
          <ListItemIcon>
            <RemoveSelfIcon className={classes.red} />
          </ListItemIcon>
          <ListItemText primary="Leave" />
        </MenuItem>
      </Menu>
    );
  };

  const dialogs = () => {
    const key = gate.key;
    const label = gate.name;
    return (
      <React.Fragment>
        <GateSettings
          gate={gate}
          open={openState.settings}
          close={newGate => {
            if (newGate) {
            }
            setOpenState({ ...openState, settings: false });
          }}
        />
        <ShareObject
          scope={{ kind: "gate", key: key } as Scope}
          label={label}
          permissions={permissions}
          isDialogOpen={openState.share}
          closeDialogCallback={() => setOpenState({ ...openState, share: false })}
        />
        <ObjectUsers
          scope={{ kind: "gate", key: key } as Scope}
          label={label}
          permissions={permissions}
          isDialogOpen={openState.users}
          closeDialogCallback={() => setOpenState({ ...openState, users: false })}
          refreshCallback={refreshCallback}
        />
        <ObjectTeams
          scope={{ kind: "gate", key: key } as Scope}
          label={label}
          permissions={permissions}
          isDialogOpen={openState.teams}
          closeDialogCallback={() => setOpenState({ ...openState, teams: false })}
          refreshCallback={refreshCallback}
        />
        <RemoveSelfFromObject
          scope={{ kind: "gate", key: key } as Scope}
          path={"terminal"}
          label={label}
          isDialogOpen={openState.removeSelf}
          closeDialogCallback={() => {
            setOpenState({ ...openState, removeSelf: false });
          }}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Tooltip title="Settings">
        <IconButton onClick={() => setOpenState({ ...openState, settings: true })}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <IconButton
        aria-owns={anchorEl ? "groupMenu" : undefined}
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
        <MoreIcon />
      </IconButton>
      {dialogs()}
      {groupMenu()}
    </React.Fragment>
  );
}

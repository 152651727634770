import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";

export interface IPreferenceAPIContext {
  getPreferences: (kind: string, keys: string[]) => Promise<pond.ModelPreferences[]>;
}

export const PreferenceAPIContext = createContext<IPreferenceAPIContext>(
  {} as IPreferenceAPIContext
);

interface Props {}

export default function PreferenceProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get } = useHTTP();

  const getPreferences = (kind: string, keys: string[]): Promise<pond.ModelPreferences[]> => {
    if (keys.length <= 0) {
      return Promise.resolve([]);
    }
    return new Promise((resolve, reject) => {
      get(pondURL("/preferences/?kind=" + kind + "&keys=" + keys.join(",")))
        .then((res: any) => {
          let prefs: pond.ModelPreferences[] = [];
          if (res && res.data && res.data.preferences) {
            res.data.preferences.forEach((raw: any) => {
              prefs.push(pond.ModelPreferences.create(raw));
            });
          }
          resolve(prefs);
        })
        .catch((err: any) => reject(err));
    });
  };

  return (
    <PreferenceAPIContext.Provider
      value={{
        getPreferences
      }}>
      {children}
    </PreferenceAPIContext.Provider>
  );
}

export const usePreferenceAPI = () => useContext(PreferenceAPIContext);

import { createStyles, IconButton, Tooltip } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import NotificationsEnabledIcon from "@material-ui/icons/NotificationsActive";
import NotificationsDisabledIcon from "@material-ui/icons/NotificationsOff";
import React from "react";

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {
  notify: boolean;
  hidden?: boolean;
  onChange: Function;
  disabled?: boolean;
  tooltip: string;
}

interface State {}

class NotificationButton extends React.Component<Props, State> {
  render() {
    const { notify, onChange, hidden, tooltip, disabled } = this.props;

    if (hidden) {
      return null;
    }

    return (
      <Tooltip title={tooltip}>
        <span>
          <IconButton
            aria-label="toggle notifactions"
            onClick={() => onChange()}
            disabled={disabled}>
            {notify ? <NotificationsEnabledIcon /> : <NotificationsDisabledIcon />}
          </IconButton>
        </span>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(NotificationButton);

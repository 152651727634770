import { Box, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import ObjectDefinitionDisplay, { data } from "./objectDefinitionDisplay";

// template
/**
 *     
    {
      name: "",
      fields: [
        {
          fieldName: "",
          fieldType: "",
          fieldDescription: ""
        },
      ]
    },
 */

// construction definitions
const siteData: data[] = [
  {
    name: "Site",
    fields: [
      {
        fieldName: "settings",
        fieldType: "SiteSettings",
        fieldDescription: "The settings of a jobsite"
      },
      {
        fieldName: "status",
        fieldType: "SiteStatus",
        fieldDescription: "The status of a jobsite"
      }
    ]
  },
  {
    name: "SiteSettings",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the site"
      },
      {
        fieldName: "objectKey (deprecated)",
        fieldType: "string",
        fieldDescription:
          "was originally going to be used as the obect the site was linked to before relative objects was implemented, currently will only contain the id of the user who created the site"
      },
      {
        fieldName: "userId",
        fieldType: "string",
        fieldDescription: "The id of the user who created the site"
      },
      {
        fieldName: "siteName",
        fieldType: "string",
        fieldDescription: "The name of the site"
      },
      {
        fieldName: "longitude",
        fieldType: "float",
        fieldDescription: "The longitude of the site"
      },
      {
        fieldName: "latitude",
        fieldType: "float",
        fieldDescription: "The latitude of the site"
      },
      {
        fieldName: "theme",
        fieldType: "ObjectTheme",
        fieldDescription: "Theme for display purposes"
      },
      {
        fieldName: "active",
        fieldType: "bool",
        fieldDescription: "Whether the site is currently active"
      }
      // these were taken out as users felt they were not necessary
      // {
      //   fieldName: "siteId",
      //   fieldType: "string",
      //   fieldDescription: ""
      // },
      // {
      //   fieldName: "siteAddress",
      //   fieldType: "string",
      //   fieldDescription: ""
      // },
      // {
      //   fieldName: "siteDescription",
      //   fieldType: "string",
      //   fieldDescription: ""
      // },
      // {
      //   fieldName: "clientName",
      //   fieldType: "string",
      //   fieldDescription: ""
      // },
      // {
      //   fieldName: "clientPhone",
      //   fieldType: "string",
      //   fieldDescription: ""
      // },
      // {
      //   fieldName: "jobType",
      //   fieldType: "string",
      //   fieldDescription: ""
      // },
      // {
      //   fieldName: "jobDetails",
      //   fieldType: "string",
      //   fieldDescription: ""
      // },
    ]
  },
  {
    name: "SiteStatus",
    fields: [
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for the creation of the site"
      }
    ]
  }
];

const objectHeaterData: data[] = [
  {
    name: "ObjectHeater",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the heater"
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the heater"
      },
      {
        fieldName: "settings",
        fieldType: "ObjectHeaterSettings",
        fieldDescription: "The settigns of the heater"
      }
    ]
  },
  {
    name: "ObjectHeaterSettings",
    fields: [
      {
        fieldName: "siteKey",
        fieldType: "string",
        fieldDescription: "The key for the site"
      },
      {
        fieldName: "make",
        fieldType: "string",
        fieldDescription: "The make of the heater"
      },
      {
        fieldName: "model",
        fieldType: "string",
        fieldDescription: "The model of the heater"
      },
      {
        fieldName: "fuelType",
        fieldType: "FuelType",
        fieldDescription: "The type of fuel the heater runs on"
      },
      {
        fieldName: "tankSize",
        fieldType: "float",
        fieldDescription: "The size of the tank in gallons"
      },
      {
        fieldName: "fuelConsumption",
        fieldType: "float",
        fieldDescription: "The rate fuel is used in gallons per hour (G/hr)"
      },
      {
        fieldName: "airCirculation",
        fieldType: "float",
        fieldDescription: "The rate that the heater circulates air in CFM"
      },
      {
        fieldName: "mutations",
        fieldType: "LinearMutation (ARRAY)",
        fieldDescription:
          "Any mutations to perform using multiple components attached to the heater and combining them to convert into other measurements using a linear regression formula"
      }
    ]
  }
];

export default function ConstructionDefinitions() {
  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Site Definitions
      </Typography>
      <List>
        {siteData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Heater Definitions
      </Typography>
      <List>
        {objectHeaterData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

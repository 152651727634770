import JobSiteLightIcon from "assets/products/Construction/jobSiteIconLight.png";
import JobSiteDarkIcon from "assets/products/Construction/jobSiteIconDark.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
  size?: number;
}

export default function JobsiteIcon(props: Props) {
  const themeType = useThemeType();
  const { type, size } = props;

  const src = () => {
    if (type) {
      return type === "light" ? JobSiteLightIcon : JobSiteDarkIcon;
    }

    return themeType === "light" ? JobSiteDarkIcon : JobSiteLightIcon;
  };

  if (size) {
    return <img width={size} height={size} alt="jobsite" src={src()}></img>;
  }
  return <ImgIcon alt="jobsite" src={src()} />;
}

import { Box, List, ListItem, Typography } from "@material-ui/core";
import EndpointDisplay, { EndPointData } from "apiDocs/endpoints/endpointDisplay";
import React from "react";

/**
 * endpoint template
        {
            requestType: "",
            url: "https://api.brandxtech.ca/v1/",
            description: "",
            urlOptions: [],
            urlParams: [],
            requestBody: "",
            responseParams: []
        },
 */

export default function MiningEndpoints() {
  const mineEndpoints: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/mines",
      description: "get a list of mines",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data"
      ],
      responseParams: ["repeated Mine mines", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/mineSimple",
      description: "get a list of just the key and name of your mines",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data"
      ],
      responseParams: ["repeated MineSimple mines", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/mines/:mine",
      description: "get a specified mine",
      urlParams: [":mine - the key of the mine"],
      responseParams: [
        "Mine mine",
        "repeated Device devices",
        "repeated Component components",
        "repeated Permission permissions",
        "map<string, MineComponentPreferences> component_preferences",
        "map<string, uint64> component_devices"
      ]
    }
    //   {
    //       requestType: "POST",
    //       url: "https://api.brandxtech.ca/v1/mines",
    //       description: "add a new mine",
    //       urlOptions: [],
    //       urlParams: [],
    //       requestBody: "",
    //       responseParams: []
    //   },
    //   {
    //       requestType: "POST",
    //       url: "https://api.brandxtech.ca/v1/mines/:mine/addDevice/:device",
    //       description: "attach a device to a mine",
    //       urlOptions: [],
    //       urlParams: [],
    //       requestBody: "",
    //       responseParams: []
    //   },
    //   {
    //       requestType: "POST",
    //       url: "https://api.brandxtech.ca/v1/mines/:mine/addComponent/:device/:component",
    //       description: "attach a component to a mine",
    //       urlOptions: [],
    //       urlParams: [],
    //       requestBody: "",
    //       responseParams: []
    //   },
    //   {
    //       requestType: "PUT",
    //       url: "https://api.brandxtech.ca/v1/mines",
    //       description: "update a mine",
    //       urlOptions: [],
    //       urlParams: [],
    //       requestBody: "",
    //       responseParams: []
    //   },
    //   {
    //       requestType: "DEL",
    //       url: "https://api.brandxtech.ca/v1/mines/:mine",
    //       description: "remove an existing mine",
    //       urlOptions: [],
    //       urlParams: [],
    //       requestBody: "",
    //       responseParams: []
    //   },
  ];

  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for retrieving mine data
      </Typography>
      <List>
        {mineEndpoints.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

import { Box, List, ListItem, Typography } from "@material-ui/core";
import React from "react";

export default function Information() {
  return (
    <Box>
      <Box>
        <Typography variant="h4">General Information</Typography>
        <Typography>
          In order to use the API you will need to make sure to have a team key set and included in
          the header in the format key: Authorization, value: Apikey aaabbb for example
          "Authorization: Apikey fekssxi1n5gh1"
        </Typography>
      </Box>
      <Box marginTop={5}>
        <Typography variant="h4">Global Variables</Typography>
        <Typography>
          There are two global url parameters that exist for all API calls, keys and types, they are
          both comma seperated strings that are used to add to the context chain for permissions.
          For regular calls the team that the api key you place in the header belongs to is the only
          option in the context and therefore when listing objects such as bins it will consider all
          of the bins accessible to the team. If you add to the keys and types options it will add
          the array into the context of the api call so for example if you want to load only the
          bins in a specified binyard you add the key of the the binyard to the keys and 'binyard'
          to the type like so
        </Typography>
        <Typography style={{ margin: 5 }}>
          'https://api.brandxtech.ca/v1/bins?keys=ST0MQc7_y4aHNA&types=binyard'
        </Typography>
        <Typography>
          Adding this into the context will then cause it to go down the chain checking to make sure
          the team has access to the yard and then loading the bins that yard has access to. Note
          that in the above example adding more than one yard would come back with nothing as it
          would check the team to the yard and then that yard to the next yard which is not
          possible. Also Note that the types defined in the parameter must be a type as defined by
          us and are case sensitive, see below for a list of acceptable type.
        </Typography>
        <Typography variant="h6" style={{ marginTop: 5 }}>
          Acceptable Types
        </Typography>
        <List>
          <ListItem>backpack</ListItem>
          <ListItem>bin</ListItem>
          <ListItem>binyard</ListItem>
          <ListItem>component</ListItem>
          <ListItem>contract</ListItem>
          <ListItem>device</ListItem>
          <ListItem>device_preset</ListItem>
          <ListItem>field</ListItem>
          <ListItem>field_marker</ListItem>
          <ListItem>file</ListItem>
          <ListItem>firmware</ListItem>
          <ListItem>gate</ListItem>
          <ListItem>grainbag</ListItem>
          <ListItem>group</ListItem>
          <ListItem>harvest_plan</ListItem>
          <ListItem>harvest_year</ListItem>
          <ListItem>home_marker</ListItem>
          <ListItem>interaction</ListItem>
          <ListItem>link</ListItem>
          <ListItem>note</ListItem>
          <ListItem>objectHeater</ListItem>
          <ListItem>mine</ListItem>
          <ListItem>notificationBanner</ListItem>
          <ListItem>site</ListItem>
          <ListItem>tag</ListItem>
          <ListItem>terminal</ListItem>
          <ListItem>transaction</ListItem>
          <ListItem>task</ListItem>
          <ListItem>team</ListItem>
          <ListItem>upgrade</ListItem>
          <ListItem>user</ListItem>
        </List>
      </Box>
    </Box>
  );
}

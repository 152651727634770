import { AddressTypeExtension } from "pbHelpers/AddressType";
import { quack } from "protobuf-ts/quack";

export const Invalid: AddressTypeExtension = {
  addressType: quack.AddressType.ADDRESS_TYPE_INVALID,
  friendlyName: "Invalid",
  humanReadableAddress: function(componentType: quack.ComponentType, address: number): string {
    return "";
  },
  singular: false
};

import { cloneDeep } from "lodash";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

export class ObjectHeater {
  public settings: pond.ObjectHeaterSettings = pond.ObjectHeaterSettings.create();
  public name: string = "Heater";
  public key: string = "";

  public static create(pb?: pond.ObjectHeater): ObjectHeater {
    let my = new ObjectHeater();
    if (pb) {
      my.settings = pond.ObjectHeaterSettings.fromObject(cloneDeep(or(pb.settings, {})));
      my.name = pb.name;
      my.key = pb.key;
    }
    return my;
  }

  public static clone(other?: ObjectHeater): ObjectHeater {
    if (other) {
      return ObjectHeater.create(
        pond.ObjectHeater.fromObject({
          settings: cloneDeep(other.settings)
        })
      );
    }
    return ObjectHeater.create();
  }

  public static any(data: any): ObjectHeater {
    return ObjectHeater.create(pond.ObjectHeater.fromObject(cloneDeep(data)));
  }
}

import { Box, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import ObjectDefinitionDisplay, { data } from "./objectDefinitionDisplay";

// template
/**
 *     
    {
      name: "",
      fields: [
        {
          fieldName: "",
          fieldType: "",
          fieldDescription: ""
        },
      ]
    },
 */

const fieldData: data[] = [
  {
    name: "Field",
    fields: [
      {
        fieldName: "settings",
        fieldType: "FieldSettings",
        fieldDescription: "The settings of a field"
      },
      {
        fieldName: "status",
        fieldType: "FieldStatus",
        fieldDescription: "The status of a field"
      },
      {
        fieldName: "fieldPermissions",
        fieldType: "Permission (ARRAY)",
        fieldDescription:
          "Your permissions to the field in relation to the context of how it was loaded"
      }
    ]
  },
  {
    name: "FieldSettings",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The unique key for the field"
      },
      {
        fieldName: "fieldName",
        fieldType: "string",
        fieldDescription: "The name of the field"
      },
      {
        fieldName: "crop",
        fieldType: "Grain (ENUM)",
        fieldDescription: "The grain type assigned to the field"
      },
      {
        fieldName: "landLocation",
        fieldType: "string",
        fieldDescription: "The land location of the field"
      },
      {
        fieldName: "fieldMapping (deprecated)",
        fieldType: "FieldMapping",
        fieldDescription: "Geo data for the location of the field"
      },
      {
        fieldName: "grainSubtype",
        fieldType: "string",
        fieldDescription: "Variant of the grain type"
      },
      {
        fieldName: "customGrain",
        fieldType: "string",
        fieldDescription:
          "Custom grain type the user can set if the desired grain type is not supported"
      },
      {
        fieldName: "fieldGeoData",
        fieldType: "GeoData",
        fieldDescription: "The geometric data of the field in GeoJson format"
      },
      {
        fieldName: "bushelsPerTonne",
        fieldType: "float",
        fieldDescription:
          "The number of bushels in a metric tonne. For supported grain this is determined when the type is selected, for custom grain this value must be entered by the user."
      },
      {
        fieldName: "acres",
        fieldType: "float",
        fieldDescription: "The acres of the field as entered by the user."
      }
    ]
  },
  {
    name: "FieldStatus",
    fields: [
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for when the field was created"
      }
    ]
  }
];

const binData: data[] = [
  {
    name: "Bin",
    fields: [
      {
        fieldName: "settings",
        fieldType: "BinSettings",
        fieldDescription: "The settings of the bin"
      },
      {
        fieldName: "status",
        fieldType: "BinStatus",
        fieldDescription: "The status of the bin"
      },
      {
        fieldName: "binPermissions",
        fieldType: "Permission (ENUM, ARRAY)",
        fieldDescription: "Permission to the bin based on the context it was loaded"
      }
    ]
  },
  {
    name: "BinSettings",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The unique key of the bin"
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the bin"
      },
      {
        fieldName: "yardKey",
        fieldType: "string",
        fieldDescription: "The key of the yard assigned to the bin"
      },
      {
        fieldName: "specs",
        fieldType: "BinSpecs",
        fieldDescription: "Information about the physical structure of the bin"
      },
      {
        fieldName: "mode",
        fieldType: "BinMode (ENUM)",
        fieldDescription: "The current mode the bin is in"
      },
      {
        fieldName: "outdoorTemp",
        fieldType: "float",
        fieldDescription: "The set tempurature outside the bin"
      },
      {
        fieldName: "outdoorHumidity",
        fieldType: "float",
        fieldDescription: "The set realitive humidity outside the bin"
      },
      {
        fieldName: "fan (deprecated)",
        fieldType: "Fan",
        fieldDescription: "The fan set on the bin"
      },
      {
        fieldName: "location",
        fieldType: "Location",
        fieldDescription: "Geographical data about the bin"
      },
      {
        fieldName: "inventory",
        fieldType: "BinInventory",
        fieldDescription: "Inventory data about the bins contents"
      },
      {
        fieldName: "deviceComponents (deprecated)",
        fieldType: "DeviceComponent (ARRAY)",
        fieldDescription: "Components attached to the bin"
      },
      // {
      //   fieldName: "useLidar",
      //   fieldType: "",
      //   fieldDescription: ""
      // },
      {
        fieldName: "theme",
        fieldType: "ObjectTheme",
        fieldDescription: "Theme data for the bin"
      },
      {
        fieldName: "storage",
        fieldType: "BinStorage (ENUM)",
        fieldDescription: "What type of contents currently in the bin"
      },
      {
        fieldName: "highTemp",
        fieldType: "float",
        fieldDescription: "The upper temperature threshold for nodes on bin cables"
      },
      {
        fieldName: "lowTemp",
        fieldType: "float",
        fieldDescription: "The lower temperature threshold for nodes on bin cables"
      },
      {
        fieldName: "fanId",
        fieldType: "int",
        fieldDescription:
          "The id of the fan set on the bin. Used with our fan charts to calculate the CFM"
      },
      {
        fieldName: "autpGrainNode",
        fieldType: "bool",
        fieldDescription:
          "Whether the bin is set to automatically calculate the top nodes of its cables and by extension estimate its inventory at midnight every day."
      }
    ]
  },
  {
    name: "BinStatus",
    fields: [
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format when the bin was created"
      },
      {
        fieldName: "lastModeChange",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format when the last time the bins mode was changed"
      },
      {
        fieldName: "targetMoistureEstimation",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for when the target moisture will be reached"
      },
      {
        fieldName: "fanCfm",
        fieldType: "float",
        fieldDescription:
          "The calculated cfm based on the pressure plenums most recent pressure and the fan id"
      },
      {
        fieldName: "grainMoisture",
        fieldType: "float",
        fieldDescription: "The grain current moisture content"
      },
      // {
      //   fieldName: "grainHeight",
      //   fieldType: "",
      //   fieldDescription: ""
      // },
      {
        fieldName: "grainBushels",
        fieldType: "float",
        fieldDescription: "Current amount of confirmed (manual) inventory in the bin"
      },
      {
        fieldName: "cfmPerBushel",
        fieldType: "float",
        fieldDescription:
          "The derived CFM based on the total CFM and the number of bushels in the bin"
      },
      {
        fieldName: "waterLiters",
        fieldType: "float",
        fieldDescription:
          "Liters of water in the bin based on the grains moisture content and amount of bushels"
      },
      {
        fieldName: "litersPerHourDrying",
        fieldType: "float",
        fieldDescription: "The change per hour in the bins water content"
      },
      // {
      //   fieldName: "lastGrainCable",
      //   fieldType: "",
      //   fieldDescription: ""
      // },
      // {
      //   fieldName: "lastPlenum",
      //   fieldType: "",
      //   fieldDescription: ""
      // },
      // {
      //   fieldName: "lastPressure",
      //   fieldType: "",
      //   fieldDescription: ""
      // },
      {
        fieldName: "grainCables",
        fieldType: "GrainCable (ARRAY)",
        fieldDescription: "The grain cables set in the bin components"
      },
      {
        fieldName: "plenums",
        fieldType: "Plenum (ARRAY)",
        fieldDescription: "The Plenum (temp/humidity) sensors set in the bin components"
      },
      {
        fieldName: "pressures",
        fieldType: "Pressure (ARRAY)",
        fieldDescription: "The pressure sensors set in the bin component"
      },
      {
        fieldName: "fans",
        fieldType: "BinFan",
        fieldDescription: "The fan controllers set in the bin components"
      },
      {
        fieldName: "distance",
        fieldType: "float",
        fieldDescription: "The distance in cm that a connected lidar read"
      },
      {
        fieldName: "grainComposition",
        fieldType: "map<string, float>",
        fieldDescription:
          "The keys of the original sources of the grain currently in the bin and how much from each source"
      },
      {
        fieldName: "objectSourceMap",
        fieldType: "map<string, field>",
        fieldDescription:
          "The keys of the last location the grain was and how much from each location"
      }
    ]
  },
  {
    name: "BinSpecs",
    fields: [
      {
        fieldName: "bushelCapacity",
        fieldType: "float",
        fieldDescription: "The capacity of the bin for bushels"
      },
      {
        fieldName: "shape",
        fieldType: "BinShape (ENUM)",
        fieldDescription: "The shape of the bin"
      },
      {
        fieldName: "heightCm",
        fieldType: "float",
        fieldDescription: "The bin height in centimeters"
      },
      {
        fieldName: "diameterCm",
        fieldType: "float",
        fieldDescription: "The bin diameter in centimeters"
      },
      {
        fieldName: "advancedDimensions",
        fieldType: "BinAdvancedDimensions",
        fieldDescription:
          "The advanced dimensions of the bin for more than just height and diameter"
      },
      {
        fieldName: "modelId",
        fieldType: "float",
        fieldDescription: "The model id of the bin that matches to our chart"
      },
      {
        fieldName: "useAdvanced",
        fieldType: "bool",
        fieldDescription:
          "Whether to use the advanced dimensions for mor accuracy or to simply use height and diameter for simplicity"
      }
    ]
  },
  {
    name: "BinInventory",
    fields: [
      {
        fieldName: "empty",
        fieldType: "bool",
        fieldDescription: "Whether the bin is empty or not"
      },
      {
        fieldName: "grainType",
        fieldType: "Grain (ENUM)",
        fieldDescription: "The type of grain for the bin"
      },
      {
        fieldName: "grainUse",
        fieldType: "GrainUse (ENUM)",
        fieldDescription: "What the grains planned use is for"
      },
      {
        fieldName: "grainBushels",
        fieldType: "float",
        fieldDescription: "The manual bushel amount"
      },
      {
        fieldName: "initialMoisture",
        fieldType: "float",
        fieldDescription: "The inital moisture of the bin when starting drying or hydrating"
      },
      {
        fieldName: "targetMoisture",
        fieldType: "float",
        fieldDescription: "The target moisture to reach when drying or hydrating"
      },
      {
        fieldName: "initialTimestamp",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format of when the initial moisture was set"
      },
      {
        fieldName: "grainSubtype",
        fieldType: "string",
        fieldDescription: "Variant of the grain type"
      },
      {
        fieldName: "customTypeName",
        fieldType: "string",
        fieldDescription: "Name of the grain type when the grainType is custom"
      },
      {
        fieldName: "bushelPerTonne",
        fieldType: "float",
        fieldDescription:
          "Number of bushels per tonne. For supported Grain types this is determined, for custom types must be entered by the user"
      }
    ]
  },
  {
    name: "BinHistory",
    fields: [
      {
        fieldName: "settings",
        fieldType: "BinSettings",
        fieldDescription: "Snapshot of the previous settings of the bin"
      },
      {
        fieldName: "user",
        fieldType: "string",
        fieldDescription: "The id if the user who made the change"
      },
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format of the change"
      },
      {
        fieldName: "progress",
        fieldType: "string",
        fieldDescription: "Current progress of the change"
      }
    ]
  },
  {
    name: "GrainCable",
    fields: [
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the component"
      },
      {
        fieldName: "celcius",
        fieldType: "float (ARRAY)",
        fieldDescription: "The last temperature measurement in celcius for each node"
      },
      {
        fieldName: "relativeHumidity",
        fieldType: "float (ARRAY)",
        fieldDescription: "The last relative humidity measurement for each node"
      },
      {
        fieldName: "moisture",
        fieldType: "float (ARRAY)",
        fieldDescription: "The calculated EMC for the last measurements for each node"
      },
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the component"
      },
      {
        fieldName: "device",
        fieldType: "int",
        fieldDescription: "The id of the device the component belongs to"
      },
      {
        fieldName: "topNode",
        fieldType: "int",
        fieldDescription:
          "The highest node that is still in the grain. Can be set by the user manually or when in storage mode the user can subscribe to automatically have it checked and set midnight each day"
      }
    ]
  },
  {
    name: "Plenum",
    fields: [
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the component"
      },
      {
        fieldName: "celcius",
        fieldType: "float",
        fieldDescription: "The last temperature measurement for the component"
      },
      {
        fieldName: "relativeHumidity",
        fieldType: "float",
        fieldDescription: "The last reltive humidity measurement for the component"
      },
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the component"
      },
      {
        fieldName: "device",
        fieldType: "int",
        fieldDescription: "The id of the device the component belongs to"
      }
    ]
  },
  {
    name: "Pressure",
    fields: [
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the component"
      },
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the component"
      },
      {
        fieldName: "pascals",
        fieldType: "int",
        fieldDescription: "The last pressure measurement the component had"
      },
      {
        fieldName: "fanId",
        fieldType: "int",
        fieldDescription: "The id of the fan set on the component"
      },
      {
        fieldName: "device",
        fieldType: "int",
        fieldDescription: "The id of the device the component belongs to"
      }
    ]
  },
  {
    name: "BinFan",
    fields: [
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the component"
      },
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the component"
      },
      {
        fieldName: "state",
        fieldType: "bool",
        fieldDescription: "The current on/off state of the fan"
      },
      {
        fieldName: "device",
        fieldType: "int",
        fieldDescription: "The id of the device the component belongs to"
      }
    ]
  },
  {
    name: "BinAdvancedDimensions",
    fields: [
      {
        fieldName: "topConeHeight",
        fieldType: "float",
        fieldDescription: "The height of the cone from the top of the sidewall"
      },
      {
        fieldName: "sidewallHeight",
        fieldType: "float",
        fieldDescription: "The height of the sidwall of the bin"
      },
      {
        fieldName: "hopperHeight",
        fieldType: "float",
        fieldDescription: "The height of the hopper from the bottom point to the sidewall"
      },
      {
        fieldName: "roofangle",
        fieldType: "float",
        fieldDescription: "The angle of the roof"
      },
      {
        fieldName: "hooperAngle",
        fieldType: "float",
        fieldDescription: "The angle of the hopper"
      }
    ]
  }
];

const binYardData: data[] = [
  {
    name: "BinYard",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the bin yard"
      },
      {
        fieldName: "settings",
        fieldType: "BinYardSettings",
        fieldDescription: "The settings of the bin yard"
      },
      {
        fieldName: "yardPermissions",
        fieldType: "Permission (ARRAY)",
        fieldDescription:
          "The permissions you have to the yard based on the context it was loaded with"
      }
    ]
  },
  {
    name: "BinYardSettings",
    fields: [
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the bin yard"
      },
      {
        fieldName: "owner",
        fieldType: "string",
        fieldDescription: "The owner of the bin, generally the user or team who created it."
      },
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the bin yard"
      },
      {
        fieldName: "description",
        fieldType: "string",
        fieldDescription: "The description of the bin yard"
      },
      {
        fieldName: "sortPriority",
        fieldType: "int",
        fieldDescription: "The priority to determine the sorting of the yards"
      },
      {
        fieldName: "userSort",
        fieldType: "map<string, int>",
        fieldDescription: "Custom sort of yards based on the user that loaded them"
      },
      {
        fieldName: "longitude",
        fieldType: "float",
        fieldDescription: "The longitude coordinate"
      },
      {
        fieldName: "latitude",
        fieldType: "float",
        fieldDescription: "The latitude coordinate"
      },
      {
        fieldName: "theme",
        fieldType: "ObjectTheme",
        fieldDescription: "Theme information for display"
      }
    ]
  }
];

const grainBagData: data[] = [
  {
    name: "GrainBag",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the grain bag"
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the grain bag"
      },
      {
        fieldName: "settings",
        fieldType: "GrainBagSettings",
        fieldDescription: "The settings of the grain bag"
      }
    ]
  },
  {
    name: "GrainBagSettings",
    fields: [
      {
        fieldName: "length",
        fieldType: "float",
        fieldDescription: "The length in meters of the grain bag"
      },
      {
        fieldName: "diameter",
        fieldType: "float",
        fieldDescription: "The diameter in meters of the grain bag"
      },
      {
        fieldName: "supportedGrain",
        fieldType: "Grain (ENUM)",
        fieldDescription: "The grain type of the grain bag"
      },
      {
        fieldName: "customGrain",
        fieldType: "string",
        fieldDescription: "Name of the grain type when using a custom type"
      },
      {
        fieldName: "grainSubtype",
        fieldType: "string",
        fieldDescription: "Variant of the grain type"
      },
      {
        fieldName: "bushelCapacity",
        fieldType: "float",
        fieldDescription: "The bushel capacity of the grain bag"
      },
      {
        fieldName: "currentBushels",
        fieldType: "float",
        fieldDescription: "The current bushels in the bag"
      },
      {
        fieldName: "startLocation",
        fieldType: "Location",
        fieldDescription: "The coordinates of the start of the grain bag"
      },
      {
        fieldName: "endLocation",
        fieldType: "Location",
        fieldDescription: "The coordinates of the end of the grain bag"
      },
      {
        fieldName: "storageType",
        fieldType: "BinStorage (ENUM)",
        fieldDescription: "The storage type of the grain bag"
      },
      {
        fieldName: "fillDate",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format that the bag was filled"
      },
      {
        fieldName: "initialMoisture",
        fieldType: "float",
        fieldDescription: "The initial moisture of the grain when the bag was filled"
      },
      {
        fieldName: "bushelsPerTonne",
        fieldType: "float",
        fieldDescription: "The amount of bushels in a metric tonne"
      },
      {
        fieldName: "theme",
        fieldType: "ObjectTheme",
        fieldDescription: "The theme for display purposes"
      }
    ]
  }
];

const harvestPlanData: data[] = [
  {
    name: "HarvestPlan",
    fields: [
      {
        fieldName: "settings",
        fieldType: "HarvestPlanSettings",
        fieldDescription: "The settings of the harvest plan"
      },
      {
        fieldName: "status",
        fieldType: "HarvestPlanStatus",
        fieldDescription: "The status of the harvest plan"
      },
      {
        fieldName: "planPermissions",
        fieldType: "Permission (ENUM, ARRAY)",
        fieldDescription: "The permissions tou have to the plan based on the context it was loaded"
      }
    ]
  },
  {
    name: "HarvestPlanSettings",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the harvest plan"
      },
      {
        fieldName: "field",
        fieldType: "string",
        fieldDescription: "The key of the field the harvest plan belongs to"
      },
      {
        fieldName: "harvestYear",
        fieldType: "int",
        fieldDescription: "The year for the harvest plan"
      },
      {
        fieldName: "cropType",
        fieldType: "Grain (ENUM)",
        fieldDescription: "The type of grain being grown"
      },
      {
        fieldName: "grainType",
        fieldType: "string",
        fieldDescription: "The variant of the grain type"
      },
      {
        fieldName: "customGrain",
        fieldType: "string",
        fieldDescription: "The name of the grain type for custom grain"
      },
      {
        fieldName: "yieldTarget",
        fieldType: "int",
        fieldDescription: "The desired yield in bushels for the plan"
      },
      {
        fieldName: "actualYield",
        fieldType: "int",
        fieldDescription: "The actual bushels grown for the plan"
      },
      {
        fieldName: "seedMaterials",
        fieldType: "float",
        fieldDescription: "The cost of materials for seeding"
      },
      {
        fieldName: "seedEquipment",
        fieldType: "float",
        fieldDescription: "The cost for equipment for seeding"
      },
      {
        fieldName: "preSeedMaterials",
        fieldType: "float",
        fieldDescription: "The cost of materials for pre-seeding"
      },
      {
        fieldName: "preSeedEquipment",
        fieldType: "float",
        fieldDescription: "The cost of equipment for pre-seeding"
      },
      {
        fieldName: "postSeedMaterials",
        fieldType: "float",
        fieldDescription: "The cost of materials post seeding"
      },
      {
        fieldName: "postSeedEquipment",
        fieldType: "float",
        fieldDescription: "The cost of equipment post seeding"
      },
      {
        fieldName: "harvestMaterials",
        fieldType: "float",
        fieldDescription: "The cost of materials for harvest"
      },
      {
        fieldName: "harvestEquipment",
        fieldType: "float",
        fieldDescription: "The cost of equipment for harvest"
      },
      {
        fieldName: "fallMaterials",
        fieldType: "float",
        fieldDescription: "The cost of materials used in the fall"
      },
      {
        fieldName: "fallEquipment",
        fieldType: "float",
        fieldDescription: "The cost of equipment used in the fall"
      },
      // {
      //   fieldName: "taskIds (deprecated)",
      //   fieldType: "string (ARRAY)",
      //   fieldDescription: "List of task id's associated with the harvest plan"
      // },
      {
        fieldName: "createDate",
        fieldType: "int",
        fieldDescription: "Unix tim in milliseconds"
      },
      {
        fieldName: "title",
        fieldType: "string",
        fieldDescription: "Title of the harvest plan"
      },
      {
        fieldName: "bushelPrice",
        fieldType: "float",
        fieldDescription: "The desired price to sell each bushel for"
      }
    ]
  },
  {
    name: "HarvestPlanStatus",
    fields: [
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format of when harvest plan was created"
      }
    ]
  },
  {
    name: "HarvestPlanHistory",
    fields: [
      {
        fieldName: "settings",
        fieldType: "HarvestPlanSettings",
        fieldDescription: "Snapshot of the previous settings"
      },
      {
        fieldName: "user",
        fieldType: "string",
        fieldDescription: "The id of the user who created the change"
      },
      {
        fieldName: "timestamp",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format of the change"
      },
      {
        fieldName: "progress",
        fieldType: "string",
        fieldDescription: "State of the change"
      }
    ]
  }
];

const contractData: data[] = [
  {
    name: "Contract",
    fields: [
      {
        fieldName: "key",
        fieldType: "string",
        fieldDescription: "The key of the contract"
      },
      {
        fieldName: "name",
        fieldType: "string",
        fieldDescription: "The name of the contract"
      },
      {
        fieldName: "settings",
        fieldType: "ContractSettings",
        fieldDescription: "The settings of the contract"
      }
    ]
  },
  {
    name: "ContractSettings",
    fields: [
      {
        fieldName: "contractId",
        fieldType: "string",
        fieldDescription: "The id of the contract"
      },
      {
        fieldName: "contractHolder",
        fieldType: "string",
        fieldDescription: "The party in possession of the contract"
      },
      {
        fieldName: "deliveryWindow",
        fieldType: "ContractDelivery",
        fieldDescription: "The start and end dates of the window for delivery on the contract"
      },
      {
        fieldName: "type",
        fieldType: "ContractType",
        fieldDescription: "The type of contract"
      },
      {
        fieldName: "customCommodity",
        fieldType: "string",
        fieldDescription:
          "The name of the commodity for custom if the name is not in the list of commodities to choose from"
      },
      {
        fieldName: "size",
        fieldType: "float",
        fieldDescription: "The promised amount to deliver on the contract"
      },
      {
        fieldName: "delivered",
        fieldType: "float",
        fieldDescription: "The amount that has currently been delivered"
      },
      {
        fieldName: "totalValue",
        fieldType: "float",
        fieldDescription: "The total monetary value of the contract"
      },
      {
        fieldName: "commodity",
        fieldType: "int",
        fieldDescription:
          "Value to be used in conjunction with the contract type to know what Enumerator to use to determine the commodity"
      },
      {
        fieldName: "contractDate",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format of the date of the contract signing"
      },
      {
        fieldName: "commoditySubtype",
        fieldType: "string",
        fieldDescription: "Subtype of the contracts commodity. For example, a variant of grain"
      },
      {
        fieldName: "conversionValue",
        fieldType: "float",
        fieldDescription:
          "A number that can be used to convert the value to another unit. For example, a coeffecient to convert bushels to tonnes"
      }
    ]
  },
  {
    name: "ContractDelivery",
    fields: [
      {
        fieldName: "startDate",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for the opening of the delivery window"
      },
      {
        fieldName: "endDate",
        fieldType: "string",
        fieldDescription: "Timestamp in RFC3339 format for the closing of the delivery window"
      }
    ]
  }
];

export default function AgricultureDefinitions() {
  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Field Definitions
      </Typography>
      <List>
        {fieldData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Bin Definitions
      </Typography>
      <List>
        {binData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Bin Yard Definitions
      </Typography>
      <List>
        {binYardData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Grain Bag Definitions
      </Typography>
      <List>
        {grainBagData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Harvest Plan Definitions
      </Typography>
      <List>
        {harvestPlanData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Contract Definitions
      </Typography>
      <List>
        {contractData.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <ObjectDefinitionDisplay data={e} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

import HumidityDarkIcon from "assets/components/humidityDark.png";
import HumidityLightIcon from "assets/components/humidityLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
  heightWidth?: number;
}

export default function HumidityIcon(props: Props) {
  const themeType = useThemeType();
  const { type, heightWidth } = props;

  const lightIcon = () => {
    return HumidityLightIcon;
  };

  const darkIcon = () => {
    return HumidityDarkIcon;
  };

  const src = () => {
    if (type) {
      return type === "light" ? lightIcon() : darkIcon();
    }

    return themeType === "light" ? darkIcon() : lightIcon();
  };

  if (heightWidth) {
    return <img alt="tempIcon" src={src()} height={heightWidth} width={heightWidth} />;
  }

  return <ImgIcon alt="bins" src={src()} />;
}

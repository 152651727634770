import { cloneDeep } from "lodash";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

export class Task {
  public key: string = "";
  public settings: pond.TaskSettings = pond.TaskSettings.create();

  public static create(pb?: pond.Task): Task {
    let my = new Task();
    if (pb) {
      my.key = pb.key;
      my.settings = pond.TaskSettings.fromObject(cloneDeep(or(pb.settings, {})));
    }
    return my;
  }

  public static clone(other?: Task): Task {
    if (other) {
      return Task.create(
        pond.Task.fromObject({
          settings: cloneDeep(other.settings)
        })
      );
    }
    return Task.create();
  }

  public static any(data: any): Task {
    return Task.create(pond.Task.fromObject(cloneDeep(data)));
  }

  public userId(): string {
    return this.settings.userId;
  }

  public title(): string {
    return this.settings.title;
  }

  public description(): string {
    return this.settings.description;
  }

  public worker(): string {
    return this.settings.worker;
  }

  public start(): string {
    return this.settings.start;
  }

  public startTime(): string {
    return this.settings.startTime;
  }

  public end(): string {
    return this.settings.end;
  }

  public endTime(): string {
    return this.settings.endTime;
  }

  public complete(): boolean {
    return this.settings.complete;
  }

  public cost(): number {
    return this.settings.cost;
  }

  public secondaryCost(): number {
    return this.settings.secondaryCost;
  }
}

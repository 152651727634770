import { useHTTP } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pond } from "protobuf-ts/pond";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";
import { useGlobalState } from "providers";

export interface IContractInterface {
  addContract: (
    name: string,
    settings: pond.ContractSettings
  ) => Promise<AxiosResponse<pond.AddContractResponse>>;
  listContracts: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    keys?: string[],
    types?: string[],
    numerical?: boolean,
    specificUser?: string
  ) => Promise<AxiosResponse<pond.ListContractsResponse>>;
  updateContract: (
    key: string,
    name: string,
    settings: pond.ContractSettings
  ) => Promise<AxiosResponse<pond.UpdateContractResponse>>;
  removeContract: (key: string) => Promise<AxiosResponse<pond.RemoveContractResponse>>;
  listContractsByYear: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    keys?: string[],
    types?: string[],
    numerical?: boolean,
    specificUser?: string,
    contractYear?: string
  ) => Promise<AxiosResponse<pond.ListContractsByYearResponse>>;
  //list contract page data
  getContractPageData: (key: string) => Promise<AxiosResponse<pond.GetContractPageDataResponse>>;
  //for the moment however simply passing the contract from the earlier list is sufficient
}

export const ContractAPIcontext = createContext<IContractInterface>({} as IContractInterface);

interface Props {}

export default function ContractProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();
  const [{ as }] = useGlobalState();

  //add
  const addContract = (name: string, settings: pond.ContractSettings) => {
    if (as) {
      return post<pond.AddContractResponse>(
        pondURL("/contracts?name=" + name + "&as=" + as),
        settings
      );
    }
    return post<pond.AddContractResponse>(pondURL("/contracts?name=" + name), settings);
  };
  //list
  const listContracts = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    keys?: string[],
    types?: string[],
    numerical?: boolean,
    specificUser?: string
  ) => {
    let asText = "";
    if (as) asText = "&as=" + as;
    if (specificUser) asText = "&as=" + specificUser;
    return get<pond.ListContractsResponse>(
      pondURL(
        "/contracts?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + (order ? order : "asc")) +
          ("&by=" + (orderBy ? orderBy : "key")) +
          (numerical ? "&numerical=true" : "") +
          (search ? "&search=" + search : "") +
          (keys ? "&keys=" + keys.toString() : "") +
          (types ? "&types=" + types.toString() : "") +
          asText
      )
    );
  };
  const listContractsByYear = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    keys?: string[],
    types?: string[],
    numerical?: boolean,
    specificUser?: string,
    contractYear?: string
  ) => {
    let asText = "";
    if (as) asText = "&as=" + as;
    if (specificUser) asText = "&as=" + specificUser;
    return get<pond.ListContractsByYearResponse>(
      pondURL(
        "/contractsByYear?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + (order ? order : "asc")) +
          ("&by=" + (orderBy ? orderBy : "key")) +
          (numerical ? "&numerical=true" : "") +
          (search ? "&search=" + search : "") +
          (keys ? "&keys=" + keys.toString() : "") +
          (types ? "&types=" + types.toString() : "") +
          (contractYear ? "&year=" + contractYear : "") +
          asText
      )
    );
  };
  //update
  const updateContract = (key: string, name: string, settings: pond.ContractSettings) => {
    if (as) {
      return put<pond.UpdateContractResponse>(
        pondURL("/contracts/" + key + "?as=" + as + "&name=" + name),
        settings
      );
    }
    return put<pond.UpdateContractResponse>(
      pondURL("/contracts/" + key + "?name=" + name),
      settings
    );
  };
  //remove
  const removeContract = (key: string) => {
    if (as) {
      return del<pond.RemoveContractResponse>(pondURL("/contracts/" + key + "?as=" + as));
    }
    return del<pond.RemoveContractResponse>(pondURL("/contracts/" + key + "?as=" + as));
  };

  const getContractPageData = (key: string) => {
    if (as) {
      return get<pond.GetContractPageDataResponse>(pondURL("/contractsPage/" + key + "?as=" + as));
    }

    return get<pond.GetContractPageDataResponse>(pondURL("/contractsPage/" + key));
  };

  return (
    <ContractAPIcontext.Provider
      value={{
        addContract,
        listContracts,
        listContractsByYear,
        updateContract,
        removeContract,
        getContractPageData
      }}>
      {children}
    </ContractAPIcontext.Provider>
  );
}

export const useContractAPI = () => useContext(ContractAPIcontext);

import * as axios from "axios";

export var defaultOptions = {
  headers: {
    "Content-Type": "application/json"
  }
};

export function get(url, options = defaultOptions) {
  return axios.get(url, options);
}

export function put(url, data, options = defaultOptions) {
  return axios.put(url, data, options);
}

export function post(url, data, options = defaultOptions) {
  return axios.post(url, data, options);
}

export function del(url, options = defaultOptions) {
  return axios.delete(url, options);
}

import { createContext, Dispatch } from "react";

export interface Actions<State> {
  key: keyof State;
  value: State[keyof State];
}

export function newContext<State>(): React.Context<[State, Dispatch<Actions<State>>]> {
  return createContext<[State, Dispatch<Actions<State>>]>([
    {} as State,
    {} as Dispatch<Actions<State>>
  ]);
}

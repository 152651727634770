import React from "react";
import { showReportDialog } from "@sentry/react";
import { Box, Button, Typography } from "@material-ui/core";
import PageContainer from "./PageContainer";
import { Home } from "@material-ui/icons";

export default function UnexpectedError() {
  return (
    <PageContainer fullViewport isCenterCenter>
      <Typography variant="h4" color="textSecondary">
        Error: 500
      </Typography>
      <Typography variant="subtitle1">An unexpected error has occured</Typography>
      <Button color="primary" onClick={() => showReportDialog()}>
        report feedback
      </Button>

      <Box marginTop={4}>
        <Button
          variant="contained"
          color="default"
          onClick={() => window.location.replace("/")}
          size="small"
          startIcon={<Home />}>
          Take me home
        </Button>
      </Box>
    </PageContainer>
  );
}

import { cloneDeep } from "lodash";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";

export class DevicePreset {
  public settings: pond.DevicePresetSettings = pond.DevicePresetSettings.create();
  public name: string = "Preset";
  public key: string = "";

  public static create(pb?: pond.DevicePreset): DevicePreset {
    let my = new DevicePreset();
    if (pb) {
      my.settings = pond.DevicePresetSettings.fromObject(cloneDeep(or(pb.settings, {})));
      my.name = pb.name;
      my.key = pb.key;
    }
    return my;
  }

  public static clone(other?: DevicePreset): DevicePreset {
    if (other) {
      return DevicePreset.create(
        pond.DevicePreset.fromObject({
          title: other.name,
          key: other.key,
          settings: cloneDeep(other.settings)
        })
      );
    }
    return DevicePreset.create();
  }

  public static any(data: any): DevicePreset {
    return DevicePreset.create(pond.DevicePreset.fromObject(cloneDeep(data)));
  }

  public objectType(): pond.ObjectType {
    return pond.ObjectType.OBJECT_TYPE_DEVICE_PRESET;
  }

  public objectTypeString(): string {
    return "Device Preset";
  }

  // public static controllerString(controllerType: pond.ControllerType): string {
  //   switch(controllerType){
  //     case pond.ControllerType.CONTROLLER_TYPE_FAN:
  //       return "Fan"
  //   }
  //   return ""
  // }

  public static presetTypeString(presetType: pond.PresetType): string {
    switch (presetType) {
      case pond.PresetType.PRESET_TYPE_COOLING:
        return "Cooling";
      case pond.PresetType.PRESET_TYPE_DRYING:
        return "Drying";
      case pond.PresetType.PRESET_TYPE_HYDRATING:
        return "Hydrating";
      default:
        return "Unknown";
    }
  }

  public compareToBinMode(binMode: pond.BinMode): boolean {
    switch (this.settings.type) {
      case pond.PresetType.PRESET_TYPE_COOLING:
        return binMode === pond.BinMode.BIN_MODE_COOLDOWN;
      case pond.PresetType.PRESET_TYPE_DRYING:
        return binMode === pond.BinMode.BIN_MODE_DRYING;
      case pond.PresetType.PRESET_TYPE_HYDRATING:
        return binMode === pond.BinMode.BIN_MODE_HYDRATING;
      default:
        return false;
    }
  }

  public type(): pond.PresetType {
    return this.settings.type;
  }

  public controllerType(): pond.ControllerType {
    return this.settings.controller;
  }

  public favorite(): boolean {
    return this.settings.favorite;
  }

  public temp(): number {
    return this.settings.temperature;
  }

  public hum(): number {
    return this.settings.humidity;
  }
}

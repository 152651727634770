import {
  AppBar,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import ResponsiveDialog from "common/ResponsiveDialog";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import TeamSearch from "./TeamSearch";
import { useTeamAPI } from "providers/pond/teamAPI";
import { useGlobalState, useUserAPI } from "providers";
import { Team } from "models/Team";

interface Props {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      flex: 1
    },
    searchBar: {
      margin: theme.spacing(1)
    },
    viewingAs: {
      margin: theme.spacing(2)
    }
  })
);

export default function TeamDialog(props: Props) {
  const { open, setOpen } = props;
  const teamAPI = useTeamAPI();
  const userAPI = useUserAPI();
  const [, dispatch] = useGlobalState();
  const [teamKey, setTeamKey] = useState<string>("");
  const [{ team, user }] = useGlobalState();
  const classes = useStyles();

  const setTeam = () => {
    setOpen(false);
    dispatch({ key: "as", value: teamKey });
    if (teamKey === "" || teamKey.includes("auth")) {
      dispatch({ key: "team", value: Team.create() });
      return;
    }
    teamAPI.getTeam(teamKey).then(team => {
      dispatch({ key: "team", value: team });
      user.settings.defaultTeam = team.key();
      user.settings.useTeam = true; //when selecting a team now set to view as team by default
      userAPI.updateUser(user.id(), user.protobuf());
    });
  };

  return (
    <ResponsiveDialog open={open} onClose={() => setOpen(false)}>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Select a Team to View As
          </Typography>
          <Button color="inherit" onClick={setTeam}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      {team.settings.name && (
        <Typography variant="body1" className={classes.viewingAs}>
          Viewing as: {team.name()}
        </Typography>
      )}
      <TeamSearch
        //style={{ width: theme.spacing(32) }}
        className={classes.searchBar}
        loadUsers={user.hasFeature("admin")}
        setTeamCallback={setTeamKey}
      />
    </ResponsiveDialog>
  );
}

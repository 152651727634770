import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Delete as DeleteIcon } from "@material-ui/icons";
import ColourPicker from "common/ColourPicker";
import { Tag as TagModel } from "models";
import { useGlobalState, useTagAPI, useSnackbar } from "providers";
import React, { useState } from "react";
import { Tag as TagUI } from "./Tag";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gutter: {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(2)
      }
    }
  })
);

interface ConfirmDeleteTagProps {
  open: boolean;
  onClose: (deleted?: boolean) => void;
  tag: TagModel;
}

function ConfirmDeleteTag(props: ConfirmDeleteTagProps) {
  const { tag, open, onClose } = props;
  const { error, success } = useSnackbar();
  const [{ tags }, dispatch] = useGlobalState();
  const tagAPI = useTagAPI();
  const tagName = tag.name();

  const onSubmit = () => {
    if (tag && tag.settings.key) {
      tagAPI
        .removeTag(tag.settings.key)
        .then(() => {
          dispatch({ key: "tags", value: tags.filter(t => t.settings.key !== tag.settings.key) });
          success("Tag was successfully deleted");
          onClose(true);
        })
        .catch((err: Error) => {
          error(err && err.message ? err.message : "Error occured while removing the tag");
          onClose();
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="confirm-delete-tag-title"
      aria-describedby="confirm-delete-tag-description">
      <DialogTitle id="confirm-delete-tag-title">Delete the tag {tagName}?</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-delete-tag-description">
          By clicking 'Submit', the tag {tagName} will be deleted; anything associated with it will
          longer be able to access it.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit()} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  open: boolean;
  onClose: () => void;
  tag?: TagModel;
  mode: "add" | "update";
}

const DeleteButton = withStyles((theme: Theme) => ({
  root: {
    color: "#FFF",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700]
    }
  }
}))(Button);

export default function TagSettings(props: Props) {
  const classes = useStyles();
  const { open, mode, onClose } = props;
  const { error, success } = useSnackbar();
  const tagAPI = useTagAPI();
  const [tag, setTag] = useState<TagModel>(TagModel.clone(props.tag));
  const [confirmDeleteTagOpen, setConfirmDeleteTagOpen] = useState(false);
  const [{ tags }, dispatch] = useGlobalState();

  const submit = () => {
    switch (mode) {
      case "add":
        tagAPI
          .addTag(tag.settings)
          .then((response: any) => {
            let newKey = response && response.data && response.data.key ? response.data.key : null;
            if (newKey) {
              tag.settings.key = newKey;
            }
            dispatch({ key: "tags", value: [...tags, tag] });
            success("Successfully created a new tag");
            handleClose(true);
          })
          .catch((err: Error) => {
            error(err && err.message ? err.message : "Error occured while create a new tag");
            handleClose();
          });
        break;
      default:
        tagAPI
          .updateTag(tag.settings.key, tag.settings)
          .then((response: any) => {
            dispatch({
              key: "tags",
              value: tags.map(t => {
                if (t.settings.key !== tag.settings.key) {
                  return t;
                }
                return tag;
              })
            });
            success("Successfully updated " + tag.name());
            handleClose(true);
          })
          .catch((err: Error) => {
            error(err && err.message ? err.message : "Error occured while updating the tag");
            handleClose();
          });
        break;
    }
  };

  const onConfirmDeleteTag = (tagDeleted?: boolean) => {
    if (tagDeleted) {
      onClose();
    } else {
      setConfirmDeleteTagOpen(false);
    }
  };

  const handleClose = (refresh?: boolean) => {
    if (mode === "add") {
      setTag(new TagModel());
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>
        <TagUI tag={tag} />
      </DialogTitle>
      <DialogContent className={classes.gutter}>
        <TextField
          label="Label"
          value={tag.settings.name}
          onChange={event => {
            let newTag = TagModel.clone(tag);
            newTag.settings.name = event.target.value;
            setTag(newTag);
          }}
          placeholder="Enter a label for your tag"
          variant="outlined"
          className={classes.gutter}
        />

        <ColourPicker
          colour={tag.settings.colour}
          onChange={colour => {
            tag.settings.colour = colour;
            let newTag = TagModel.clone(tag);
            newTag.settings.colour = colour;
            setTag(newTag);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            {mode !== "add" && (
              <DeleteButton
                variant="contained"
                size="small"
                onClick={() => setConfirmDeleteTagOpen(true)}>
                <DeleteIcon fontSize="small" />
              </DeleteButton>
            )}
          </Grid>

          <Grid item>
            <Button onClick={() => handleClose()} color="primary">
              Close
            </Button>
            <Button onClick={() => submit()} color="primary">
              {mode === "add" ? "Create" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      {mode !== "add" && (
        <ConfirmDeleteTag
          open={confirmDeleteTagOpen}
          onClose={tagDeleted => onConfirmDeleteTag(tagDeleted)}
          tag={tag}
        />
      )}
    </Dialog>
  );
}

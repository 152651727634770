import { Avatar } from "@material-ui/core";
import { Team, User } from "models";
import React from "react";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

function stringAvatar(name: string) {
  let letters = "";
  if (name.split(" ").length > 1) {
    letters = name.split(" ")[0][0] + name.split(" ")[1][0];
  } else {
    letters = name.split(" ")[0][0];
  }
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${letters}`
  };
}

interface Props {
  user: User | Team;
  className?: string;
  style?: React.CSSProperties;
}

export default function UserSettings(props: Props) {
  const { user, className, style } = props;

  return (
    <Avatar
      alt={user.name()}
      src={user.settings.avatar}
      className={className}
      style={style}
      {...stringAvatar(user.name())}
    />
  );
}

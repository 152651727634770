import { Card, createStyles, Divider, makeStyles, Theme, Typography } from "@material-ui/core";
import InlineIcon from "assets/editor/inlineCentrifugal.png";
import FullIcon from "assets/editor/fullCentrifugal.png";
import LowIcon from "assets/editor/lowCentrifugal.png";
import Fan1Icon from "assets/editor/fan1.png";
import Fan2Icon from "assets/editor/fan2.png";
import Fan3Icon from "assets/editor/fan3.png";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { Fan } from "ventilation/drawable/Fan";

const fans = [
  {
    name: "In-Line Centrifugal",
    img: InlineIcon,
    mfg: "",
    model: "Unknown",
    hp: "500HP",
    editorImg: Fan1Icon
  },
  {
    name: "Full Centrifugal",
    img: FullIcon,
    mfg: "",
    model: "Unknown",
    hp: "1000HP",
    editorImg: Fan2Icon
  },
  {
    name: "Low Speed Centrifugal",
    img: LowIcon,
    mfg: "",
    model: "Unknown",
    hp: "250HP",
    editorImg: Fan3Icon
  }
];

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    topText: {
      //textAlign: "center",
      //alignContent: "center"
    },
    divider: {
      margin: theme.spacing(2)
    },
    topTextContainer: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      padding: theme.spacing(1),
      marginBottom: 0,
      paddingBottom: 0
    },
    fanCard: {
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      height: theme.spacing(10)
    },
    fanIcon: {
      height: "100%",
      objectFit: "contain"
    },
    fanIconContainer: {
      height: "100%",
      width: theme.spacing(8)
    },
    fanInfo: {
      display: "block",
      marginLeft: theme.spacing(1)
    },
    addIcon: {
      //justifySelf: "flex-end",
      marginLeft: "auto",
      marginTop: "auto",
      marginBottom: "auto",
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: theme.spacing(1),
      "&:hover": {
        cursor: "pointer",
        background: "rgba(200,200,200, 0.25)"
      }
    }
  });
});

interface Props {
  addBlockRef: any;
}

export default function FansDrawer(props: Props) {
  //const isMobile = useMobile();
  //const { isOpen, children } = props;
  const classes = useStyles();
  const addBlockRef = props.addBlockRef;

  const addFan = (subtype: number) => {
    let fan = Fan.create(0, 0, 32, subtype);
    addBlockRef.current(fan);
  };

  return (
    <React.Fragment>
      <div className={classes.topTextContainer}>
        <Typography className={classes.topText} color="textSecondary" variant="caption">
          Select a fan from the list below to place it in the Ventilation Editor
        </Typography>
      </div>
      <Divider className={classes.divider} />
      {fans.map((fan, index) => {
        return (
          <Card key={fan.name} className={classes.fanCard}>
            <div className={classes.fanIconContainer}>
              <img src={fan.img} alt={fan.name} className={classes.fanIcon} />
            </div>
            <div className={classes.fanInfo}>
              <Typography display="block" variant="body2">
                {fan.name}
              </Typography>
              <Divider />
              <Typography display="block" variant="caption">
                Model: {fan.model}
              </Typography>
              <Typography display="block" variant="caption">
                Power: {fan.hp}
              </Typography>
            </div>
            <AddIcon
              className={classes.addIcon}
              onClick={() => (addBlockRef.current ? addFan(index + 1) : undefined)}
            />
          </Card>
        );
      })}
    </React.Fragment>
  );
}

import { Box, CircularProgress } from "@material-ui/core";
import { useGlobalState, useNoteAPI, useTeamAPI } from "providers";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ChatInput from "./ChatInput";
import ChatOutput from "./ChatOutput";
import { Note } from "models";
import { pond } from "protobuf-ts/pond";
import moment from "moment-timezone";

interface Props {
  objectKey: string;
  type?: pond.NoteType;
}

export default function Chat(props: Props) {
  const [notes, setNotes] = useState<Note[]>([]);
  const { objectKey, type } = props;
  const noteAPI = useNoteAPI();
  const [notesLoaded, setNotesLoaded] = useState<boolean>(false);
  const [totalMessages, setTotalMessages] = useState(0);
  const [scrollPos, setScrollPos] = useState(0);
  const [{ team }] = useGlobalState();
  const [attachmentMap, setAttachmentMap] = useState<Map<string, pond.FileReference[]>>(new Map());

  const teamAPI = useTeamAPI();

  const loadNotes = useCallback(() => {
    let currentNotes: Note[] = notes;
    noteAPI
      .listNotes(15, currentNotes.length, "desc", "timestamp", objectKey)
      .then(resp => {
        setNotesLoaded(true);
        if (resp.data.notes.length < 1) {
          return;
        }

        setTotalMessages(resp.data.total);
        let tempNotes: Note[] = [];
        let tempAttachmentMap: Map<string, pond.FileReference[]> = attachmentMap;
        resp.data.notes.forEach(note => {
          let n = Note.any(note);
          tempNotes.push(n);
          let refList = resp.data.attachments[n.key()];
          if (refList) {
            tempAttachmentMap.set(n.key(), refList.refs);
          }
        });
        setScrollPos(tempNotes.length);
        currentNotes = currentNotes.concat(tempNotes);
        currentNotes.reverse();
        setNotes(currentNotes);
        setAttachmentMap(tempAttachmentMap);

        if (type) {
          // Setting the note type for future use
          currentNotes.forEach(note => {
            if (note.settings.objectType === pond.NoteType.NOTE_TYPE_UNKNOWN && type) {
              note.settings.objectType = type;
              noteAPI.updateNote(note.settings);
            }
          });

          // Update new chat viewed timestamp
          if (type === pond.NoteType.NOTE_TYPE_TEAM) {
            team.preferences.chatViewedTimestamp = moment().toISOString();
            teamAPI.updatePreferences(team.key(), team.preferences);
          }
        }
      })
      .catch(err => {});
  }, [noteAPI, objectKey, notes, type, team, teamAPI, attachmentMap]);

  const removeNote = (index: number) => {
    let n = notes;
    setTotalMessages(totalMessages - 1);
    n.splice(index, 1);
    setNotes([...n]);
  };

  const showNewNotes = (newNote: Note) => {
    let n = notes;
    n.push(newNote);
    setScrollPos(n.length);
    setNotes([...n]);
  };

  useEffect(() => {
    if (!notesLoaded) {
      loadNotes();
    }
    // setNotesLoaded(true);
  }, [notesLoaded, loadNotes]);

  const loadMore = () => {
    let n = notes;
    n.reverse();
    setNotes(n);
    loadNotes();
  };

  return (
    <React.Fragment>
      {!notesLoaded ? (
        <Box marginTop={"51%"} marginLeft={"44%"}>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ maxHeight: "100%", display: "flex", flexDirection: "column-reverse" }}>
          <ChatInput newNoteMethod={showNewNotes} objectKey={objectKey} type={type} />
          <ChatOutput
            totalMessages={totalMessages}
            attachmentMap={attachmentMap}
            messages={notes}
            scrollPos={scrollPos}
            removeNoteMethod={removeNote}
            loadMore={loadMore}
          />
        </div>
      )}
    </React.Fragment>
  );
}
